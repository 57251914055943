/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

interface Param {
  phone?: string
  email?: string
}

export const useInviteUser = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.INVITE_USER],
    mutationFn: async (data: Param) => {
      const response = await axios.api.post('ajax/connections', {
        requestType: 'connInvite',
        'cliqueIDs[]': [1],
        invitePhone: data.phone,
        inviteEmail: data.email,
        format: 'json',
      })
      return response.data
    },
  })
}
