import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ContentPanel, Panel2, Panel3, Panel4 } from '../__styled'
import { useGetContainers } from '@/hooks/api/dashboard'
import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { useNavigate, useLocation } from 'react-router-dom'
import SelectTopics from './SelectTopics'
import { shallow } from 'zustand/shallow'

import { ScrollableCapsulesTabBar } from '@/components/ScrollableCapsulesTabBar'
import { SIDE_PANEL_WIDTH } from '@/utils/constants'
import LoadingAnimatedView from '@/components/LoadingAnimatedView'
import dayjs from 'dayjs'
import UploadPhotos from './Photos'
import colors from '@/styles/colors'
import { EgoObject } from '@/types'
import { TransitionProps } from '@mui/material/transitions'
import { ArrowBackIos } from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />
})

export default function UploadsPage() {
  const [selectedEgoList, setSelectedEgoList] = useState<EgoObject[]>([])
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false)
  const { pathname } = useLocation()
  const getContainers = useGetContainers()
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const sidePanelWidth = lessThanMedium ? 0 : SIDE_PANEL_WIDTH

  const query = useBrowserQuery()
  const containerID = query.get('container')
  const navigate = useNavigate()

  const [myContainers] = useAppStore((state) => [state.user.myContainers], shallow)

  useEffect(() => {
    getContainers.mutate(1)
  }, [])

  useEffect(() => {
    if (myContainers.length > 0 && !containerID) {
      onSelectContainer(myContainers[0].containerID)
    }
  }, [myContainers])

  const selectedContainerIndex = useMemo(() => {
    return myContainers.findIndex((e) => e.containerID == containerID)
  }, [myContainers, containerID])

  const onSelectContainer = (id: string) => {
    if (id === containerID) return
    query.set('container', id)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickBack = () => {
    //setShowDetails(false)
  }

  const onClickNext = () => {
    setSelectedEgoList([])
  }

  const onClickCloseInteractions = () => {
    setSelectedEgoList([])
  }

  const onSelectContainerAtIndex = (index: number) => {
    onSelectContainer(myContainers[index].containerID)
  }

  const onClickShowDrawer = () => {
    setShowDetailsDrawer(true)
  }
  const onClickHideDrawer = () => {
    setShowDetailsDrawer(false)
  }

  const containerOptions = useMemo(() => {
    if (getContainers.isSuccess && myContainers.length > 0) {
      return myContainers.map((container, i) => dayjs(container.datePurchased).format('MMMM YYYY'))
    }
    return []
  }, [getContainers.isSuccess, myContainers])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ overflow: 'auto' }}>
        {getContainers.isLoading ? (
          <LoadingAnimatedView showGif={true} />
        ) : (
          <>
            <Box sx={{ px: '16px', my: '1px' }}>
              <ScrollableCapsulesTabBar
                titles={containerOptions}
                selectedIndex={selectedContainerIndex}
                onSelectAtIndex={onSelectContainerAtIndex}
                showScrollButtons
              />
            </Box>
            <Box
              flexDirection={'row'}
              display={'flex'}
              flex={1}
              p={1}
              bgcolor={colors.pageBackground}
              sx={{ overflow: 'hidden' }}
            >
              <Box
                sx={{
                  width: selectedEgoList.length > 0 ? `calc(100% - ${sidePanelWidth})` : '100%',
                }}
              >
                <UploadPhotos
                  onClickShowDrawer={onClickShowDrawer}
                  selectedEgoList={selectedEgoList}
                  onSelectEgos={setSelectedEgoList}
                />
              </Box>

              {!lessThanMedium && selectedEgoList.length > 0 && (
                <Box
                  sx={{
                    width: sidePanelWidth,
                    ml: 1,
                  }}
                >
                  <SelectTopics
                    selectedEgoObjects={selectedEgoList}
                    onClickBack={onClickCloseInteractions}
                    showClose
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
      <Dialog
        open={showDetailsDrawer && lessThanMedium}
        TransitionComponent={Transition}
        onClose={onClickHideDrawer}
        aria-describedby='alert-dialog-slide-description'
        fullWidth
        fullScreen
      >
        <Box bgcolor={'white'} sx={{ flex: 1 }}>
          <IconButton sx={{ m: '16px' }} onClick={onClickHideDrawer}>
            <ArrowBackIos sx={{ color: colors.themeBlack }} />
          </IconButton>
          <SelectTopics
            selectedEgoObjects={selectedEgoList}
            onClickBack={() => {
              onClickCloseInteractions()
              onClickHideDrawer()
            }}
            showClose={true}
          />
        </Box>
      </Dialog>
    </Box>
  )
}
