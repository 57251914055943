import colors from '@/styles/colors';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import SelectTitlePrivacy from './SelectTitlePrivacy';
import SelectTopicType from './SelectTopicType';
import { SVGS } from '@/assets/svgs';
import { GroupUsersContainer, GroupUsersContent, TitlePrivacyTypeContainer, TopicDetailsContainer } from './__styled';
import SelectConnections from './SelectConnections';
import SelectGroups from './SelectGroups';
import BottomStatus from './SubmitBar';
import { useCreateTopic } from '@/hooks/api/dashboard';
import { useBrowserQuery, useLocalStorage } from '@/hooks/common';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import useAppStore from '@/store/useAppStore';
import { isBabylonType } from '@/utils/helper';

export default function CreateTopic() {
  const [title, setTitle] = useState('')
  const [isPrivacyShown, setIsPrivacyShown] = useState(false);
  const [isConnectionsShown, setIsConnectionsShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [privacyType, setPrivacyType] = useState(3)
  const [topicType, setTopicType] = useState(2)
  const [connections, setConnections] = useState<string[]>([])
  const [groupIDs, setGroupIDs] = useState<string[]>([])
  const [groupTypes, setGroupTypes] = useState<string[]>([])

  const [ewUser] = useLocalStorage('ewUser', null);
  const createTopic = useCreateTopic(ewUser?.userID)
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useBrowserQuery();
  const type = query.get('type');
  const privacy = query.get('privacy');
  const connIDs = query.get('connIDs');
  const { resetTopicList } = useAppStore();

  useEffect(() => {
    setTopicType(Number(type) || 2); //  set Album by default
    setPrivacyType(Number(privacy) || 3); // set Private by default
    setConnections(connIDs?.split(',') || []);
  }, [])

  useEffect(() => {
    if (connections.length > 0) query.set('connIDs', connections.join(','));
    else query.delete('connIDs');
    navigate(`${pathname}?${query.toString()}`, { replace: true });
  }, [connections]);

  useEffect(() => {
    if (privacyType) {
      setIsConnectionsShown(true);
      query.set('privacy', String(privacyType));
      navigate(`${pathname}?${query.toString()}`, { replace: true });
    }
  }, [privacyType])

  useEffect(() => {
    if (topicType) {
      setIsPrivacyShown(true);
      query.set('type', String(topicType));
      navigate(`${pathname}?${query.toString()}`, { replace: true });
    }
  }, [topicType])

  const handlePrivacyTypeChange = (type: number) => {
    if (privacyType !== type && type === 6 && connections.length > 1) {
      setConnections([])
    }
    setPrivacyType(type);
  }

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const result = await createTopic.mutateAsync({
        topicName: title,
        topicType: String(topicType),
        allowContribution: 0,
        topicXData: 'X Data Here',
        privacyType,
        cliques: privacyType === 5 ? groupIDs : [],
        connections: (privacyType === 4 || privacyType === 6) ? connections : [],
      });
      if (result.isError) {
        toast.error('Create failed. Please try again!')
      } else if (isBabylonType(topicType)) {
        navigate(`/pattern?topicID=${result.topicID}&mode=edit`)
      } else {
        resetTopicList();
        navigate(`/topic`)
      }
    } catch (e) {
      toast.error('Create failed. Please try again!')
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Grid container height={'100%'} position="relative">
      <Grid item xs={12} md={4} lg={3}>
        <SelectTopicType topicType={topicType} onChangeType={setTopicType} />
      </Grid>
      <TopicDetailsContainer item view={isPrivacyShown}>
        <Grid container height={'100%'} padding={3}>
          <Grid container height={'100%'} spacing={3} position="relative">
            <TitlePrivacyTypeContainer item xs={12} lg={8} height="100%">
              <SelectTitlePrivacy
                title={title}
                privacyType={privacyType}
                onChangeTitle={setTitle}
                onChangePrivacy={handlePrivacyTypeChange}
                hasBack={isPrivacyShown}
                onClickBack={() => setIsPrivacyShown(false)}
              />
            </TitlePrivacyTypeContainer>
            <GroupUsersContainer item xs={12} lg={4} height="100%" position={'relative'} view={isConnectionsShown}>
              <GroupUsersContent
                className="content"
                borderRadius={2}
                bgcolor={'white'}
                height="100%"
                position={'relative'}
              >
                {isConnectionsShown && (
                  <Box px={1} pt={1} className="close-connection">
                    <IconButton sx={{ color: colors.text }} onClick={() => setIsConnectionsShown(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                )}
                {privacyType === 3 && (
                  <Box display="flex" flexDirection={'column'} alignItems={'center'} pt={4} px='15%' height='100%'>
                    <SVG className='empty-icon' src={SVGS.FilterPrivacyPrivate} strokeWidth={0.1} width={60} height={60} color={colors.yellow} />
                    <Typography variant="h2" mt={2}>Keep All Private</Typography>
                    <Typography variant="p" mt={1} textAlign={'center'}>Keep all content private. No one can view your private content</Typography>
                  </Box>
                )}
                {privacyType === 1 && (
                  <Box display="flex" flexDirection={'column'} alignItems={'center'} pt={4} px='15%' height='100%'>
                    <SVG className='empty-icon' src={SVGS.FilterPrivacyPublic} width={60} height={60} color={colors.yellow} />
                    <Typography variant="h2" mt={2}>Keep All Public</Typography>
                    <Typography variant="p" mt={1} textAlign="center">Your content will remain visible for all Oddly users and guests</Typography>
                  </Box>
                )}
                {(privacyType === 4 || privacyType === 6) && (
                  <SelectConnections
                    isMultiple={privacyType === 4}
                    connections={connections}
                    onChangeConnections={setConnections}
                  />
                )}
                {privacyType === 5 && (
                  <SelectGroups
                    groupIDs={groupIDs}
                    groupTypes={groupTypes}
                    onChangeGroupIDs={setGroupIDs}
                    onChangeGroupTypes={setGroupTypes}
                  />
                )}
              </GroupUsersContent>
            </GroupUsersContainer>
          </Grid>
        </Grid>
        <BottomStatus
          title={title}
          connections={connections}
          groupIDs={groupIDs}
          topicType={topicType}
          privacyType={privacyType}
          submitText={isBabylonType(topicType) ? "Next" : "Create"}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      </TopicDetailsContainer>
    </Grid >
  )
}