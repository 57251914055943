export default {
  blue: '#475496',
  yellow: '#FF9D2E',
  darkYellow: '#CBA624',
  grey: '#C8D0E5',
  lightGrey: '#E1E5F0',
  veryLightGrey: '#F9F9F9',
  white: '#FFFFFF',
  darkGrey: '#575353',
  veryDarkGray: '#525B7A',
  // headingText: '#373947',
  headingText: '#211E1E',
  // text: '#4A5165',
  text: '#211E1E',
  lightText: '#8892AD',
  buttonHover: '#1D8FC0',
  error: '#FF4B4B',
  green: '#26A74A',
  lightGreen: '#36C55E',
  purple: '#AF4ED1',
  darkRed: '#f26666',
  meshdomBlue: '#2884FF',
  themeBlack: '#211E1E',
  cardBorderColor: '#E7EDF3',
  divider: '#E7EDF3',
  themePurpleTextColor: '#525E9C',
  meshdomBlueLight: '#2884FF12',
  themePink: '#FF2C85',
  themeOrange: '#FF9D2E',
  themePinkLight: '#FF2C8512',
  inputPlaceholder: '#A49E9E',
  backArrowColor: '#475496',
  pageBackground: '#F4F6F8',
  // pageBackground: 'red',
}
