import { Outlet, useNavigate } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material'
import { useAuth } from '@/hooks/common'
import { useEffect } from 'react'
import { AxiosProvider } from '@/context/Axios'

export default function TopicDetailsLayout() {
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.userID) {
      navigate('/login')
    }
  }, [user?.userID])

  return (
    <AxiosProvider>
      <Box
        sx={{ display: 'flex', height: '100%', background: 'linear-gradient(#161719, #1D1E21)' }}
      >
        <Box component='main' sx={{ flexGrow: 1 }}>
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            height={'100%'}
            sx={{ transition: 'margin 0.3s', overflow: 'hidden' }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </AxiosProvider>
  )
}
