import { UserAvatar } from "@/components";
import { useBrowserQuery } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, IconButton, Radio, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import { SelectGroupContainer } from "./__styled";

interface Props {
  onSubmit: (circleID: string) => void;
  onClickClose: () => void;
}

export default function ConnectionSelectGroup({ onSubmit, onClickClose }: Props) {
  const [selectedCircleID, setSelectedCircleID] = useState('');

  const query = useBrowserQuery();
  const category = query.get("category");
  const requestID = query.get("requestID");
  const userID = query.get("userID");
  const theme = useTheme()
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const [connRequests, recommendations, myGruops] = useAppStore(
    state => [
      state.user.connRequests,
      state.user.recommendations,
      state.user.myGroups
    ],
    shallow
  )

  const systemGroups = useMemo(() => {
    return myGruops.filter(i => i.isSystemProvided === '1') || [];
  }, [myGruops])

  const userInfo = useMemo(() => {
    if (category === 'requests') {
      const user = connRequests.find(i => i.connReqID === requestID);
      if (!user) return null;
      return {
        photo: user?.profileAlbumPic,
        name: `${user.rFname} ${user.rLname}`
      }
    } else if (category === 'recommendations') {
      const user = recommendations.find(i => i.userID === userID);
      if (!user) return null;
      return {
        photo: user?.profilePicURL,
        name: `${user.firstName} ${user.lastName}`
      }
    }
    return null;
  }, [requestID, userID, category, connRequests, recommendations]);

  if (!userInfo) return null;

  const handleCircleSelection = (circleID: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedCircleID(circleID)
    }
  }

  return (
    <SelectGroupContainer p={2}>
      <Box display="flex" alignItems={'center'}>
        {lessThanLarge && (
          <IconButton onClick={onClickClose}>
            <ChevronLeft sx={{ color: colors.text }} />
          </IconButton>
        )}
        <Typography variant="labelLarge">Who is {userInfo.name.split(' ')[0]} to you?</Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={2} mb={4}>
        <UserAvatar
          url={userInfo.photo}
          name={userInfo.name}
          size={46}
        />
        <Typography variant='label' flex={1} sx={{ paddingLeft: 1 }}>
          {userInfo.name}
        </Typography>
      </Box>
      {systemGroups.map(group => (
        <Box display="flex" alignItems="center" key={group.circleID} py={2} borderBottom={`1px solid ${colors.veryLightGrey}`}>
          <UserAvatar
            url={group.badgeThumbURL}
            name={group.circleName}
            size={46}
          />
          <Box pl={1} flex={1}>
            <Typography variant="label">{group.circleName}</Typography>
            <Typography variant="ps">{group.connCount} Member(s)</Typography>
          </Box>
          <Radio
            checked={group.circleID === selectedCircleID}
            onChange={handleCircleSelection(group.circleID)}
          />
        </Box>
      ))}
      <Box display="flex" justifyContent={'flex-end'}>
        <Button
          variant="contained"
          onClick={() => onSubmit(selectedCircleID)}
          sx={{ marginTop: 2 }}
          disabled={!selectedCircleID}
        >
          {category === 'requests' ? 'Accept' : 'Send request'}
        </Button>
      </Box>
    </SelectGroupContainer>
  )
}