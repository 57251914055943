/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { EgoBasicInfo, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { useLocalStorage } from '@/hooks/common'

interface Param {
  isUserProfile?: boolean
}

export const useGetBasicInfo = (userID: string) => {
  const axios = useContext(AxiosContext)
  const [ewUser] = useLocalStorage('ewUser', null)
  const [, setUserBasicInfo] = useLocalStorage('ewBasicInfo', null)
  const { setProfile, setSelectedUserProfile } = useAppStore()
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_BASIC_INFO, userID],
    mutationFn: async ({ isUserProfile = false }: Param): Promise<EgoBasicInfo> => {
      const response = await axios.api.post('ajax/users', {
        requestType: 'basicInfoLoad',
        format: 'json',
        userID,
      })
      if (ewUser.userID === userID) {
        setProfile(response.data.users)
        setUserBasicInfo(response.data.users)
      } else if (isUserProfile) {
        setSelectedUserProfile(response.data.users)
      }
      return response.data.users
    },
  })
}
