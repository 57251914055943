import { Topic } from '@/types'
import { PrivacyType, TopicType } from '@/utils/constants'
import { createContext, Context, ReactNode, useState } from 'react'

interface CreateTopicModalContextType {
  isModalOpen: boolean
  contextTopicType: TopicType
  contextPrivacyType: PrivacyType
  isDiscover: boolean
  openModal: (topicType: TopicType, privacyType: PrivacyType) => void
  closeModal: () => void
  onClickTopic: (topic: Topic) => void
}

export const CreateTopicModalContext = createContext({} as CreateTopicModalContextType)
