import colors from '@/styles/colors'
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import SelectTitlePrivacy from './SelectTitlePrivacy'
import { SVGS } from '@/assets/svgs'
import {
  GroupUsersContainer,
  GroupUsersContent,
  TitlePrivacyTypeContainer,
  TopicDetailsContainer,
} from './__styled'
import SelectConnections from './SelectConnections'
import SelectGroups from './SelectGroups'
import { Clique, Connection, Note } from '@/types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  useGetTopicDetails,
  useUpdateTopic,
  useGetPermissions,
  useUpdateVisibility,
  useUpdateNote,
} from '@/hooks/api/dashboard'
import { toast } from 'react-hot-toast'
import BottomStatus from './SubmitBar'
import { Close } from '@mui/icons-material'
import { useBrowserQuery } from '@/hooks/common'
import htmlToDraft from 'html-to-draftjs'

export default function EditTopic() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useBrowserQuery()
  const [isConnectionsShown, setIsConnectionsShown] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [title, setTitle] = useState('')
  // const [firstNote, setFirstNote] = useState<Note | null>(null)
  const [privacyType, setPrivacyType] = useState(0)
  const [topicType, setTopicType] = useState<number>(0)
  const [connections, setConnections] = useState<string[]>([])
  const [groupIDs, setGroupIDs] = useState<string[]>([])
  const [groupTypes, setGroupTypes] = useState<string[]>([])

  const getTopicDetails = useGetTopicDetails()
  const getPermissions = useGetPermissions()
  const updatePrivacy = useUpdateVisibility()
  const updateNote = useUpdateNote()
  const updateTopic = useUpdateTopic(id || '')

  useEffect(() => {
    if (getTopicDetails.isSuccess) {
      const topic = getTopicDetails.data
      const {
        notes,
        topicName,
        visibility,
        topicType,
        connections: conns,
        cliqueTypes,
        cliques,
      } = getTopicDetails.data

      if (!topic.firstNote) {
        setTitle(topicName)
      } else {
        try {
          const parsed = JSON.parse(topic.firstNote.noteText)
          const draft = htmlToDraft(parsed.text)
          const value = draft.contentBlocks.map((block) => block.getText()).join('\n')
          setTitle(value)
        } catch (e) {
          setTitle(topic.firstNote.noteText)
        }
      }
      setPrivacyType(Number(visibility) || 3)
      setTopicType(Number(topicType) || 0)
      if (conns) setConnections(conns.map((i: Connection) => i.connID))
      if (cliqueTypes) setGroupTypes(cliqueTypes)
      if (cliques) setGroupIDs(cliques.map((i: Clique) => i.cliqueID))
    }
  }, [getTopicDetails.isSuccess])

  useEffect(() => {
    query.set('privacy', String(privacyType))
    if (connections.length > 0) query.set('connIDs', connections.join(','))
    else query.delete('connIDs')
    if (groupIDs.length > 0) query.set('groupIDs', groupIDs.join(','))
    else query.delete('groupIDs')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
    if (title && privacyType) {
      setIsConnectionsShown(true)
    }
  }, [privacyType, connections, groupIDs])

  useEffect(() => {
    if (id) getTopicDetails.mutate({ topicID: id })
  }, [id])

  const handlePrivacyTypeChange = (type: number) => {
    if (privacyType !== type && type === 6 && connections.length > 1) {
      setConnections([])
    }
    setPrivacyType(type)
  }

  const onSubmit = async (goNext?: boolean) => {
    const topic = getTopicDetails.data
    if (!id || !topic) return
    try {
      setIsSubmitting(true)
      const { firstNote } = topic
      if (firstNote) {
        const noteText = {
          text: `<p>${title}</p>`,
          attachments: [],
        }
        updateNote.mutateAsync({
          topicID: id,
          noteID: firstNote.noteID,
          noteText: JSON.stringify(noteText),
          egoList: [...firstNote.egoList],
        })
      }
      updateTopic.mutateAsync(title)
      /*
      const p = await getPermissions.mutateAsync(id || '')
      if (p?.permissionID) {
        await updatePrivacy.mutateAsync({
          resourceID: id || '',
          cliqueTypes: privacyType === 5 ? groupTypes : [],
          cliques: privacyType === 5 ? groupIDs : [],
          connections: privacyType === 4 || privacyType === 6 ? connections : [],
          accessTypeID: String(privacyType),
          permissionID: p.permissionID,
          resourceType: 6,
        })
      }
      */

      // navigate(`/topic?topicID=${id}`)

      /*
      if (goNext) {
        navigate(`/pattern?topicID=${id}&mode=edit`)
      } else {
        navigate(`/topic?topicID=${id}`)
      }
      */
    } catch (e) {
      toast.error('Update failed. Please try again!')
    } finally {
      setIsSubmitting(false)
    }
  }

  if (getTopicDetails.isLoading) {
    return (
      <Box display={'flex'} justifyContent='center'>
        <CircularProgress />
      </Box>
    )
  }

  if (!getTopicDetails.data?.notes) {
    return (
      <Box display={'flex'} justifyContent='center'>
        <Typography variant='h2' mt={2}>
          Invalid topic or server error
        </Typography>
      </Box>
    )
  }

  return (
    <Grid container height={'100%'} position='relative'>
      <TopicDetailsContainer item view={true}>
        <Grid container height={'100%'} padding={3}>
          <Grid container height={'100%'} spacing={3} position='relative'>
            <TitlePrivacyTypeContainer item xs={12} lg={8} height='100%'>
              <SelectTitlePrivacy
                title={title}
                privacyType={privacyType}
                onChangeTitle={setTitle}
                onChangePrivacy={handlePrivacyTypeChange}
              />
            </TitlePrivacyTypeContainer>
            <GroupUsersContainer
              item
              xs={12}
              lg={4}
              height='100%'
              position={'relative'}
              view={isConnectionsShown}
            >
              <GroupUsersContent
                className='content'
                borderRadius={2}
                bgcolor={'white'}
                height='100%'
                position={'relative'}
              >
                {isConnectionsShown && (
                  <Box px={1} pt={1} className='close-connection'>
                    <IconButton
                      sx={{ color: colors.text }}
                      onClick={() => setIsConnectionsShown(false)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                )}
                {privacyType === 3 && (
                  <Box
                    display='flex'
                    flexDirection={'column'}
                    alignItems={'center'}
                    pt={4}
                    px='15%'
                    height='100%'
                  >
                    <SVG
                      className='empty-icon'
                      src={SVGS.FilterPrivacyPrivate}
                      strokeWidth={0.1}
                      width={60}
                      height={60}
                      color={colors.yellow}
                    />
                    <Typography variant='h2' mt={2}>
                      Keep All Private
                    </Typography>
                    <Typography variant='p' mt={1} textAlign={'center'}>
                      Keep all content private. No one can view your private content
                    </Typography>
                  </Box>
                )}
                {privacyType === 1 && (
                  <Box
                    display='flex'
                    flexDirection={'column'}
                    alignItems={'center'}
                    pt={4}
                    px='15%'
                    height='100%'
                  >
                    <SVG
                      className='empty-icon'
                      src={SVGS.FilterPrivacyPublic}
                      width={60}
                      height={60}
                      color={colors.yellow}
                    />
                    <Typography variant='h2' mt={2}>
                      Keep All Public
                    </Typography>
                    <Typography variant='p' mt={1} textAlign='center'>
                      Your content will remain visible for all Oddly users and guests
                    </Typography>
                  </Box>
                )}
                {(privacyType === 4 || privacyType === 6) && (
                  <SelectConnections
                    isMultiple={privacyType === 4}
                    connections={connections}
                    onChangeConnections={setConnections}
                  />
                )}
                {privacyType === 5 && (
                  <SelectGroups
                    groupIDs={groupIDs}
                    groupTypes={groupTypes}
                    onChangeGroupIDs={setGroupIDs}
                    onChangeGroupTypes={setGroupTypes}
                  />
                )}
              </GroupUsersContent>
            </GroupUsersContainer>
          </Grid>
        </Grid>
        <BottomStatus
          title={title}
          connections={connections}
          groupIDs={groupIDs}
          topicType={topicType}
          privacyType={privacyType}
          submitText={'Save'}
          nextText={'Next to edit pattern'}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onPressNext={() => onSubmit(true)}
        />
      </TopicDetailsContainer>
    </Grid>
  )
}
