import { ActionMenu, RModal, TagPrivacy, TopicStates, UserAvatar } from "@/components";
import SVG from 'react-inlinesvg';
import { Box, Button, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import colors from "@/styles/colors";
import { DMConnection } from "@/types";
import { PrivacyTypes } from "@/utils/constants";
import { ChevronLeft, DeleteOutline, EditOutlined, MoreVert } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useDeleteTopic } from "@/hooks/api/dashboard";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useBrowserQuery, useLocalStorage } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import { SVGS } from "@/assets/svgs";
import ReactTimeago from "react-timeago";
import { convertToGMT } from "@/utils/helper";

interface HeaderProps {
  topic: DMConnection
  onClickBack: () => void;
  onClickPrivacy: () => void;
  onDeletedTopic?: () => void;
}

export default function DMTopicDetailsHeader({ topic, onClickBack, onClickPrivacy, onDeletedTopic }: HeaderProps) {
  const [modalOpened, setModalOpened] = useState(false)
  const theme = useTheme();
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const privacyData = PrivacyTypes[Number(topic?.visibility)]

  const [ewUser] = useLocalStorage('ewUser', null)
  const navigate = useNavigate();
  const query = useBrowserQuery();
  const dmTopicID = query.get("dmTopicID") || '';

  const deleteTopic = useDeleteTopic(dmTopicID);
  const { removeTopic } = useAppStore();

  useEffect(() => {
    if (deleteTopic.isSuccess) {
      removeTopic(dmTopicID)
      onDeletedTopic && onDeletedTopic();
    }
  }, [deleteTopic.isSuccess])

  const onCloseModal = () => {
    setModalOpened(false);
  }

  const onDeleteTopic = () => {
    toast.promise(
      deleteTopic.mutateAsync(),
      {
        loading: 'Deleting...',
        success: <Typography variant="p">Successfully deleted</Typography>,
        error: <Typography variant="p">Failed to delete</Typography>
      }
    )
  }

  const topicActions = useMemo(() => {
    return [
      {
        icon: <EditOutlined />,
        name: 'Edit',
        onClick: () => { }
      },
      {
        icon: <DeleteOutline />,
        name: 'Delete',
        onClick: () => { } // setModalOpened(true)
      },
    ]
  }, [])

  const onClickProfile = () => {
    navigate(`/profile?id=${topic.userID}`)
  }

  if (!privacyData) return null;

  return (
    <>
      <Box padding="20px" borderBottom={`1px solid ${colors.lightGrey}`}>
        {lessThanMedium && (
          <Box ml={-2} mb={1}>
            <Button variant="text" fullWidth={false} onClick={onClickBack} sx={{ padding: 0 }}>
              <ChevronLeft />
              <Typography variant="p">Back</Typography>
            </Button>
          </Box>
        )}
        <Box position="absolute" top={10} right={5} display="flex" justifyContent={'flex-end'}>
          {topic.userID === ewUser?.userID && (
            <ActionMenu
              id="topic-item-menu"
              parent={<MoreVert />}
              actions={topicActions}
            />
          )}
        </Box>
        <RModal
          open={modalOpened}
          onClose={onCloseModal}
          title="Delete Topic"
          description="Are you sure you want to delete this topic?"
          submitText="Delete"
          onSubmit={onDeleteTopic}
        />
        <Box display="flex" alignItems="center">
          <Typography variant="h1" flex={1}>{topic.topicName}</Typography>
          <Button variant="outlined" onClick={onClickProfile}>View Profile</Button>
          <ActionMenu
            id="topic-item-menu"
            parent={<MoreVert />}
            actions={topicActions}
          />
        </Box>
        <Box display="flex" mt={2}>
          <UserAvatar name={topic.userName} url={topic.profileThumbURL} size={56} />
          <Box flex={1} display="flex" flexDirection={'column'} ml={1} height={lessThanSmall ? 'auto' : 56} justifyContent='space-between'>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="pt" mr={1}>Started By </Typography>
              <Link href={`/profile?id=${topic.userID}`}>
                <Typography variant="labelLarge">{topic.userName}</Typography>
              </Link>
              {!lessThanSmall && <Typography variant="pt" fontSize={10} ml={3}>
                <ReactTimeago date={convertToGMT(topic.dateAdded)} />
              </Typography>}
            </Box>
            {lessThanSmall && (
              <Box>
                <Typography variant="pt" fontSize={10} mb={1}>
                  <ReactTimeago date={convertToGMT(topic.dateAdded)} />
                </Typography>
              </Box>
            )}
            <Box display="flex" alignItems='center'>
              <Box display="flex" alignItems="center" p={1} bgcolor="grey.800" borderRadius={'4px'}>
                <SVG src={SVGS.ActionComment} width={12} height={12} />
                <Typography
                  variant="labelSmall"
                  fontSize={11}
                  sx={{ marginLeft: 1 }}
                >
                  Direct Message
                </Typography>
              </Box>
              <TagPrivacy topic={topic} onClick={onClickPrivacy} />
              {!lessThanSmall && (
                <Box flex={1} display="flex" justifyContent={'flex-end'} alignItems={'center'}>
                  <TopicStates topic={topic} type={'dm'} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {lessThanSmall && (
        <Box
          display="flex"
          justifyContent={'space-around'}
          alignItems={'center'}
          borderBottom={`1px solid ${colors.lightGrey}`}
          px={5}
        >
          <TopicStates topic={topic} type={'dm'} />
        </Box>
      )}
    </>
  )
}