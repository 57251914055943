import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import OtpInput from 'react-otp-input'
import { AuthFormBox } from './__styled'
import { Input } from '@/components'
import { useTheme } from '@mui/material'
import { validateEmail } from '@/utils/helper'

interface FormProps {
  error: string
  loading: boolean
  user: string
  onSubmit: (code: string) => void
  onResend: () => void
}

export default function SignUpForm3({ error, loading, user, onSubmit, onResend }: FormProps) {
  const [code, setCode] = useState('') // Email or Phone number
  const [time, setTime] = useState(60)
  const theme = useTheme()

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => time - 1)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const onAgree = () => {
    onSubmit(code)
  }

  const onResendCode = () => {
    setTime(60)
    onResend()
  }

  return (
    <AuthFormBox>
      <Typography fontWeight={400} fontSize={28}>
        Verification Code
      </Typography>
      <Typography
        variant='p'
        fontSize={15}
        mt={'12px'}
        style={{ whiteSpace: 'pre-line' }}
        mr={'80px'}
        sx={{
          [theme.breakpoints.down('md')]: {
            mr: '40px',
          },
        }}
      >
        We have sent verification code on your{' '}
        {validateEmail(user) ? 'email address' : 'phone number'}{' '}
        <Typography component='span' style={{ fontWeight: 'bold' }}>
          {user}
        </Typography>{' '}
        to continue
      </Typography>
      <Box width='100%'>
        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={6}
          inputStyle={{
            width: '46px',
            height: '46px',
            borderRadius: '8px',
            fontSize: 30,
            border: '1px #A0A0A0 solid',
          }}
          containerStyle={{ justifyContent: 'space-between', marginTop: 24 }}
          renderInput={(props) => <input {...props} />}
        />
      </Box>
      {!loading && !!error && (
        <Box mt={1}>
          <Typography variant='labelLarge' color={'error.main'}>
            {error}
          </Typography>
        </Box>
      )}
      <Box mt={2} display='flex' justifyContent='center'>
        {time > 0 ? (
          <Typography fontSize={14} fontWeight={'400'}>
            Resend code in {time} sec
          </Typography>
        ) : (
          <Button variant='text' onClick={onResendCode}>
            Resend code
          </Button>
        )}
      </Box>
      <LoadingButton
        variant='contained'
        size='large'
        fullWidth
        className='loginButton'
        disabled={code.length < 6}
        onClick={onAgree}
        loading={loading}
      >
        Continue
      </LoadingButton>
      <Box mt={2} display='flex' justifyContent='center' alignItems={'center'} gap={'3px'}>
        <Typography
          variant='labelLarge'
          fontSize={14}
          color={'customText.main'}
          fontFamily={'Inter'}
        >
          Got an account?
        </Typography>
        <Link to='/login'>
          <Typography variant='labelLarge' fontSize={14} color={'brand.main'}>
            Sign In
          </Typography>
        </Link>
      </Box>
    </AuthFormBox>
  )
}
