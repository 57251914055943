/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { CircleConnection, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useGetContainers = () => {
  const axios = useContext(AxiosContext)
  const { setMyContainers } = useAppStore()
  const [myContainers] = useAppStore((state) => [state.user.myContainers], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_CONTAINERS],
    mutationFn: async (page: number): Promise<CircleConnection[]> => {
      const response = await axios.api.post('ajax/containers', {
        requestType: 'containerList',
        catID: 1,
        pageNo: page,
        format: 'json',
      })
      if (page === 1) setMyContainers(response.data?.containers || [])
      else setMyContainers(myContainers.concat(response.data?.containers || []))
      return response.data?.containers || []
    },
  })
}
