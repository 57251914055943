import { SVGS } from '@/assets/svgs'
import {
  Input,
  InteractionComment,
  NoInteractions,
  UserInteraction,
  UserInteractionProps,
} from '@/components'
import {
  useDeleteComment,
  useGetDMTopicDetails,
  useGetTopicDetails,
  useGetTopicNoteLikes,
  useInteractToNote,
  userAddNoteComment,
  useNoteLoad,
} from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Comment, Ego, NoteDetail } from '@/types'
import { ArrowBackIos, ChevronLeft, ChevronRight, Close, Favorite, Send } from '@mui/icons-material'
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { shallow } from 'zustand/shallow'
import { useLocation, useNavigate } from 'react-router-dom'
import { getNowInUTC, scrollToBottom } from '@/utils/helper'

interface Props {
  trackKey: string
}

export default function NoteComments({ trackKey }: Props) {
  const [noteData, setNoteData] = useState<NoteDetail | null>(null)
  const [isViewMode, setIsViewMode] = useState(false)
  const [newComment, setNewComment] = useState('')

  const [ewUser] = useLocalStorage('ewUser', null)
  const [basicInfo] = useLocalStorage('ewBasicInfo', null)
  const query = useBrowserQuery()
  const topicID = query.get(trackKey) || ''
  const noteID = query.get('noteID') || ''

  const noteLoad = useNoteLoad(noteID)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const addNoteComment = userAddNoteComment()
  const deleteComment = useDeleteComment(topicID, noteID)
  const interactToNote = useInteractToNote()
  const [topicSelectedAt] = useAppStore((state) => [state.home.topicSelectedAt], shallow)
  const [comments, setComments] = useState<Comment[]>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>(null)
  const listRef = useRef<any>(null)

  useEffect(() => {
    if (noteID) noteLoad.mutate()
  }, [topicSelectedAt])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0)
    }
  }, [sliderRef.current, noteID])

  useEffect(() => {
    if (noteID) {
      setIsViewMode(false)
      setNewComment('')
      setNoteData(null)
      noteLoad.mutate()
    }
  }, [noteID])

  useEffect(() => {
    if (noteLoad.isSuccess) {
      setNoteData(noteLoad.data)
      setComments(noteLoad.data.comments)
      scrollToBottom(listRef, 10)
    }
  }, [noteLoad.isSuccess])

  useEffect(() => {
    if (addNoteComment.isSuccess && !addNoteComment.data?.isError) {
      setNewComment('')
      const { comment: commentText, commentID } = addNoteComment.data
      const comment: Comment = {
        comment: commentText,
        dateAdded: getNowInUTC(),
        feedbackID: commentID,
        noteID,
        topicID,
        senderName: `${basicInfo.firstName} ${basicInfo.lastName}`,
        senderID: ewUser.userID,
        userID: ewUser.userID,
        profilePicURL: basicInfo.profilePicThumbURL,
      }
      const updated = [...comments, comment]
      setComments(updated)
      scrollToBottom(listRef, 10)
    }
  }, [addNoteComment.isSuccess])

  if (noteLoad.isError) {
    return <Typography>Failed to load</Typography>
  }

  //if (!noteData || !noteID) return null

  const onClickDelete = (comment: Comment) => {
    let filtered = comments.filter((e) => e.feedbackID != comment.feedbackID)
    setComments(filtered)
    toast.promise(deleteComment.mutateAsync(comment.feedbackID), {
      loading: 'Deleting a comment...',
      success: <Typography variant='p'>Successfully deleted</Typography>,
      error: <Typography variant='p'>Failed to delete</Typography>,
    })
  }

  const onChangeNewComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value)
  }

  const onClickSendNewComment = () => {
    if (newComment.trim()) {
      addNoteComment.mutate({ topicID, noteID, comment: newComment })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onClickSendNewComment()
    }
  }

  const handleBack = () => {
    query.delete('noteID')
    query.delete('step')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const renderComments = () => {
    return (
      <>
        {!noteData || noteLoad.isLoading ? (
          <Box mt={1} display={'flex'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {comments.length === 0 && <Typography>No comments</Typography>}
            {comments.map((comment) => (
              <InteractionComment
                key={comment.feedbackID}
                comment={comment}
                onClickDelete={() => onClickDelete(comment)}
                deletable={comment.userID === ewUser.userID && !deleteComment.isLoading}
              />
            ))}
          </>
        )}
      </>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: '16px 16px 32px 16px',
      }}
    >
      <Box mx={'-8px'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <IconButton
          onClick={handleBack}
          sx={{
            backgroundColor: 'transparent',
            px: 0,
          }}
        >
          <ArrowBackIos sx={{ ml: '8px', color: colors.backArrowColor }} />
        </IconButton>

        <Typography fontSize={'20px'} fontWeight={700} color={colors.themeBlack}>
          {`Comments (${comments.length || 0})`}
        </Typography>
      </Box>
      <Box ref={listRef} flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        {renderComments()}
      </Box>
      <Input
        value={newComment}
        placeholder='Share your thought'
        onChange={onChangeNewComment}
        disabled={addNoteComment.isLoading}
        rightIcon={
          addNoteComment.isLoading ? (
            <CircularProgress size={20} />
          ) : newComment.length > 0 ? (
            <Send />
          ) : null
        }
        onToggleIcon={onClickSendNewComment}
        onKeyDown={handleKeyDown}
        sx={{ borderRadius: '50px', backgroundColor: 'white', mt: 1 }}
      />
    </Box>
  )
}
