// import { ImageList, TopicStates, UserAvatar } from "@/components"
// import { useBrowserQuery } from "@/hooks/common"
// import useAppStore from "@/store/useAppStore"
// import colors from "@/styles/colors"
// import { Ego, Topic } from "@/types"
// import { PrivacyTypes, TopicTypes } from "@/utils/constants"
// import { convertToGMT } from "@/utils/helper"
// import { Box, Typography } from "@mui/material"
// import ReactTimeago from "react-timeago"

// interface ItemProps {
//   topic: Topic
//   onClick: () => void;
// }

// export default function SharedTopicItem({ topic, onClick }: ItemProps) {
//   // const [isShownReaction, setIsShownReaction] = useState(false);
//   const { setIsPrivacySelected } = useAppStore();
//   const query = useBrowserQuery();
//   const topicID = query.get("topicID") || '';

//   const onClickItem = () => {
//     setIsPrivacySelected(false)
//     onClick();
//   }

//   const topicData = TopicTypes[Number(topic.topicType)]
//   const privacyData = PrivacyTypes[Number(topic.visibility)]
//   if (!topicData || !privacyData) return null;

//   // const onClickReactionButton = (event: React.MouseEvent<HTMLButtonElement>) => {
//   //   event.stopPropagation();
//   //   setIsShownReaction(!isShownReaction);
//   // }

//   return (
//     <Box
//       px={2}
//       py={1}
//       border={`1px solid ${topicID === topic.topicID ? colors.yellow : colors.lightGrey}`}
//       borderRadius={2}
//       boxShadow={topicID === topic.topicID ? '0 1px 2px rgba(0,0,0,.1)' : 'none'}
//       boxSizing={"border-box"}
//       height={"100%"}
//       onClick={onClickItem}
//       sx={{ cursor: 'pointer' }}
//     >
//       <Box display="flex" alignItems="center">
//         <Typography variant="h2" mr={1} flex={1} textOverflow="ellipsis" whiteSpace={'nowrap'} overflow="hidden" py={1}>
//           {topic.topicName}
//         </Typography>
//         {/* <Button variant='outlined'
//           sx={{ height: 25, borderColor: colors.lightGrey, paddingRight: 1 }}
//           onClick={onClickReactionButton}
//         >
//           <Typography>Reactions</Typography>
//           {!isShownReaction && <KeyboardArrowDown sx={{ color: colors.lightGrey }} />}
//           {isShownReaction && <KeyboardArrowUp sx={{ color: colors.lightGrey }} />}
//         </Button> */}
//       </Box>
//       <Box display="flex">
//         <UserAvatar url={topic.profilePicThumbURL} size={46} name={`${topic.userName}`} />
//         <Box pl={2} mt={-0.5}>
//           <Typography>
//             Created By
//             <Typography variant="labelSmall" fontSize={11} sx={{ marginLeft: 1, marginRight: 1 }}>{topic.userName}</Typography>
//             <Typography variant="labelSmall" fontFamily={'Inter'} fontSize={10}>
//               <ReactTimeago date={convertToGMT(topic.dateUpdated)} />
//             </Typography>
//           </Typography>
//         </Box>
//       </Box>
//       {topic.latestNote?.egoList?.length > 0 ? (
//         <ImageList images={topic.latestNote?.egoList} />
//       ) : (
//         <ImageList images={[{
//           photoAlbumURL: "/placeholder.png"
//         } as Ego]} />
//       )}
//       {/* {topic.firstNote?.egoList?.length > 0 && (
//         <Box display="flex" overflow="auto" py={2}>
//           {topic.firstNote?.egoList?.map(ego => (
//             <img
//               key={ego.egoID}
//               src={ego.photoAlbumURL}
//               className="preview-image"
//               style={{ width: `${100 / topic.firstNote?.egoList.length}%` }}
//             />
//           ))}
//         </Box>
//       )} */}
//       <Typography pt={1} className="topic-description">{topic.topicDescription}</Typography>
//       {/* {isShownReaction && (
//         <Typography variant="label">Reactions view is coming soon.</Typography>
//       )} */}
//       <Box display="flex" p={1} mt={1}>
//         <TopicStates topic={topic} />
//       </Box>
//     </Box>
//   )
// }

import { IMAGES } from '@/assets/images'
import { ImageList, PreviewNote, TagTopic, UserAvatar } from '@/components'

import { useDeleteTopic } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { BackgroundImage } from '@/pages/__styled'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Topic } from '@/types'
import { PrivacyTypes, TopicTypes } from '@/utils/constants'
import { getTopicStates, isBabylonType } from '@/utils/helper'
import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'

import SVG from 'react-inlinesvg'

interface ItemProps {
  topic: Topic
  onClick: () => void
  onRemoved?: () => void
}

export default function TopicItem({ topic, onClick, onRemoved }: ItemProps) {
  const { removeTopic } = useAppStore()

  const deleteTopic = useDeleteTopic(topic.topicID)
  const [ewUser] = useLocalStorage('ewUser', null)
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''

  useEffect(() => {
    if (deleteTopic.isSuccess) {
      removeTopic(topic.topicID)
      onRemoved && onRemoved()
    }
  }, [deleteTopic.isSuccess])

  const topicData = TopicTypes[Number(topic.topicType)]
  const privacyData = PrivacyTypes[Number(topic.visibility)]
  if (!topicData || !privacyData) return null

  const renderTopicPreviewImage = () => {
    if (topic.topicType === '2') {
      return (
        <Box sx={{ borderRadius: 2 }}>
          <ImageList images={topic.latestNote?.egoList || []} />
        </Box>
      )
    }
    if (isBabylonType(topic.topicType) && topic.firstNote.egoList.length) {
      return (
        <Box sx={{ borderRadius: 2, height: '100%' }}>
          <BackgroundImage
            sx={{ borderRadius: 2 }}
            url={topic.firstNote.egoList[0].photoAlbumURL}
          />
        </Box>
      )
    }
    return (
      <Box sx={{ borderRadius: 2, height: '100%' }}>
        <BackgroundImage sx={{ borderRadius: 2 }} url={IMAGES.topicPreviewPlaceholder} />
      </Box>
    )
  }

  return (
    <Box
      p={'15px'}
      border={`1px solid ${topicID === topic.topicID ? colors.yellow : colors.lightGrey}`}
      borderRadius={2}
      boxShadow={topicID === topic.topicID ? '0 1px 2px rgba(0,0,0,.1)' : 'none'}
      boxSizing={'border-box'}
      maxHeight={277}
      minHeight={277}
      height={'100%'}
      width={268}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box height={'150px'} mb={1}>
        {renderTopicPreviewImage()}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UserAvatar
          borderRadius={50}
          name={topic.userName}
          url={topic.profilePicThumbURL}
          size={32}
        />
        <Typography variant='p' marginLeft={1} color={'#525E9C'}>
          {ewUser?.userID === topic.userID ? 'You' : topic.userName?.split(' ')[0]}
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' height={40}>
        <PreviewNote
          variant='h2'
          mr={1}
          flex={1}
          textOverflow='ellipsis'
          whiteSpace={'nowrap'}
          overflow='hidden'
          note={topic.firstNote}
          titleOnly
        />
      </Box>
      <Box display='flex' alignItems='center' justifyContent={'space-between'} marginTop={'auto'}>
        <Box display={'flex'}>
          <TagTopic type={topicData.key} />
        </Box>
        <Box flex={1} display='flex' alignItems='center' justifyContent='flex-end' gap='15px'>
          {getTopicStates(topic)
            .filter((item) => item.key === 'visit' || item.key === 'like')
            .map((item) => {
              return (
                <Box display='flex' alignItems='center' key={item.key}>
                  <SVG src={item.icon} width={14} height={14} color={'#475496'} />
                  <Typography fontWeight={500} fontSize={12} color={'#475496'} ml={'4px'}>
                    {item.value}
                  </Typography>
                </Box>
              )
            })}
        </Box>
      </Box>
    </Box>
  )
}
