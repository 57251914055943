import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import UserDetails from './details'

export default function UserProfile() {
  const [showDetails, setShowDetails] = useState(false)
  const [showInteractions, setShowInteractions] = useState(false)

  const [userConnections, topicList] = useAppStore(
    (state) => [state.user.userConnections, state.home.topicList],
    shallow,
  )

  const navigate = useNavigate()
  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const connID = query.get('connID') || ''
  const userID = query.get('id') || ''

  useEffect(() => {
    setShowDetails(false)
    setShowInteractions(false)
  }, [userID])

  const onClickTopic = () => {
    setShowDetails(true)
  }

  const onClickBack = () => {
    setShowDetails(false)
    setShowInteractions(false)
  }

  const onShowInteractions = () => {
    setShowInteractions(true)
  }

  const onClickCloseInteractions = () => {
    setShowInteractions(false)
  }

  const onClickCloseConnectRequest = () => {
    query.delete('requestID')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
    setShowInteractions(false)
  }

  return (
    <Box flexDirection={'column'} display={'flex'} sx={{ width: '100%' }}>
      <UserDetails />
    </Box>
  )
}
