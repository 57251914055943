import colors from '@/styles/colors'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CategoryContainer = styled(Box)({
  '& .item': {
    borderLeft: `4px solid white`,
    borderBottom: `1px solid ${colors.veryLightGrey}`,
    cursor: 'pointer',
    '&.active': {
      borderLeft: `4px solid ${colors.yellow}`,
      boxShadow: '0 1px 2px rgba(0,0,0,.1)',
    },
  },
})

export const ConnectionsContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: 4,
  height: '-webkit-fill-available',
  display: 'flex',
  flexDirection: 'column',
  '& .connection-list': {
    marginRight: '-16px',
    paddingRight: '16px',
    overflow: 'auto',
  },
  '& .request-item': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: `1px solid ${colors.veryLightGrey}`,
    '&:hover': {
      border: `1px solid ${colors.yellow}`,
    },
    '&.active': {
      border: `1px solid ${colors.yellow}`,
    },
  },
})

export const SelectGroupContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: 4,
})
