import colors from '@/styles/colors'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SearchContent = styled(Box)(({ theme }) => ({
  '& .topic-search-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.palette.grey[800]}`,
    backgroundColor: 'white',
    zIndex: 10,
  },
  '& .preview-image': {
    minWidth: 130,
    height: 130,
    marginRight: 10,
    borderRadius: 8,
    objectFit: 'cover',
  },
}))

export const DetailsContent = styled(Box)({
  backgroundColor:
    'linear-gradient(90.64deg, rgba(29, 26, 27, 0.05) 0.46%, rgba(231, 237, 243, 0) 99.37%)',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  '& .username': {
    overflowWrap: 'break-word',
  },
  '& .motto': {
    overflowWrap: 'break-word',
  },
  '& .dm-content': {
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: 16,
    overflowY: 'auto',
    backgroundColor: '#FCFCFC',
    boxSizing: 'border-box',
  },
})

export const ListOverlayGradient = styled(Box)({
  background: 'linear-gradient(180deg, #211E1E0D, #211E1E00 5%)',
  display: 'flex',
  flexDirection: 'column',
  height: '100px',
  width: '100%',
})

export const GradientContainer = styled(Box)({
  background: 'linear-gradient(180deg, #211E1E00, #211E1E0D 75%)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
})

export const InteractionsContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  '& .dm-interaction': {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    '& .view-tab': {
      display: 'flex',
      margin: '0 -15px',
      borderBottom: `1px solid ${colors.lightGrey}`,
      borderTop: `1px solid ${colors.lightGrey}`,
      '& button': {
        flex: 1,
        color: colors.darkGrey,
        borderRadius: 0,
        fontSize: 13,
        '&.active': {
          borderBottom: `1px solid ${colors.blue}`,
          color: colors.blue,
        },
      },
    },
  },
}))

export const NoteWrapper = styled(Grid)({
  cursor: 'pointer',
  borderRadius: 6,
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
  border: `2px solid transparent`,
  '& .action': {
    display: 'none',
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: 40,
    padding: '8px 0',
  },
  '&.active': {
    border: `2px solid ${colors.yellow}`,
    color: colors.blue,
  },
  '&:hover': {
    '& .action': {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#00000088',
    },
  },
  '& .more': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000088',
  },
})

export const AlbumPhotoItem = styled(Box)(({ photo }: { photo: string }) => ({
  backgroundImage: `url(${photo})`,
  backgroundSize: 'cover',
  height: 0,
  paddingBottom: '100%',
  margin: '3px',
  cursor: 'pointer',
}))

export const AlbumSliderWrapper = styled(Box)(({ mode }: { mode: string }) => ({
  position: mode === 'full' ? 'fixed' : 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '5px',
  overflow: 'hidden',
  backgroundColor: 'black',
  zIndex: mode === 'full' ? 2000 : 10,
  '& .slick-slider': {
    height: '100%',
  },
  '& .slick-list': {
    height: '100%',
  },
  '& .slick-track': {
    height: '100%',
  },
  '& .slick-slide': {
    position: 'relative',
  },
  '& .navButton': {
    backgroundColor: '#FFFFFFBB',
    borderRadius: '50%',
  },
  '& .top-section': {
    backgroundColor: '#00000022',
  },
  '& .bottom-section': {
    backgroundColor: '#00000022',
  },
}))

export const AlbumGalleryPhoto = styled(Box)(
  ({ photo, mode }: { photo: string; mode: string }) => ({
    backgroundImage: `url(${photo})`,
    backgroundSize: mode === 'full' ? 'contain' : 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: mode === 'full' ? '80%' : '100%',
    top: mode === 'full' ? '10%' : 0,
    width: '100%',
    position: 'absolute',
  }),
)

export const AlbumInteractionContainer = styled(Box)({
  padding: '15px 15px 15px 15px',
  height: '-webkit-fill-available',
  display: 'flex',
  flexDirection: 'column',
  '& .image': {
    maxHeight: 285,
    width: '100%',
    objectFit: 'cover',
    borderRadius: 8,
  },
  '& .view-tab': {
    display: 'flex',
    margin: '0 -15px',
    borderBottom: `1px solid ${colors.lightGrey}`,
    borderTop: `1px solid ${colors.lightGrey}`,
    '& button': {
      flex: 1,
      color: colors.darkGrey,
      borderRadius: 0,
      fontSize: 13,
      '&.active': {
        borderBottom: `1px solid ${colors.blue}`,
        color: colors.blue,
      },
    },
  },
})

export const AddAlbumPreviewItem = styled(Box)({
  margin: '8px 8px 4px 0px',
  '& img': {
    width: 90,
    height: 90,
    objectFit: 'cover',
    borderRadius: '8px',
    border: `1px solid ${colors.lightGrey}`,
  },
  '& button': {
    position: 'absolute',
    top: -8,
    right: -8,
    background: 'white',
    padding: 0,
    border: `1px solid ${colors.meshdomBlue}`,
  },
})

export const DMNoteWrapper = styled(Box)({
  marginLeft: 18,
  // '13px', pb: '20px'
  padding: '13px 17px 20px 23px',
  flex: 1,
  borderRadius: 8,
  backgroundColor: colors.veryLightGrey,
  border: `2px solid transparent`,
  '&.active': {
    border: `2px solid ${colors.yellow}`,
    backgroundColor: 'white',
  },
  '& .action-button': {
    padding: 0,
  },
  '& .interaction': {
    cursor: 'pointer',
    marginRight: 20,
    fontSize: 14,
  },
})
