/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable spaced-comment */
import * as BABYLON from '@babylonjs/core'
const EPSILON = 0.001
const OVERLAY_TEST_REGEX = /overlay/i
let scene
let textTexture = null
let screenShotCamera
let highlightLayers = []
let showOverlay = true
let engine
let addNote
const defaultTexture = {
  materialBase: null,
}
const defaultMaterial = {
  standard1: null,
  standard2: null,
  standard3: null,
  nodeMaterial: null,
  overlay: null,
}

function initializeHelpers(initObject) {
  scene = initObject.scene
  engine = initObject.engine
  addNote = initObject.addNote
  defaultMaterial.nodeMaterial = null
  textTexture = null
  highlightLayers.length = 0

  createPresetTextures()
  loadMaterial()
  screenShotCamera = new BABYLON.FreeCamera('screenshotCamera', new BABYLON.Vector3(0, 5, 0), scene)
  screenShotCamera.setTarget(new BABYLON.Vector3(0, 0, 0))
  screenShotCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA
}

/**
 * Returns boundary lines around the mesh ignoring lines
 * shared by two faces. This can still result in lines appearing
 * inside the polygon mesh if the faces use duplicated points resulting
 * in unique indices. To clean this out use remove duplicate segments
 * @param {BABYLON.Mesh} mesh
 * @returns {Segment[]}
 */
function findBoundaryLines(mesh) {
  const indices = mesh.getIndices()
  const unsharedLineIndices = []
  for (let i = 0; i < indices.length; i += 3) {
    const candidates = [
      [indices[i], indices[i + 1]],
      [indices[i + 1], indices[i + 2]],
      [indices[i + 2], indices[i]],
    ]
    for (let j = 0; j < unsharedLineIndices.length; j++) {
      for (let k = 0; k < candidates.length; k++) {
        if (areLinesSame(unsharedLineIndices[j], candidates[k])) {
          candidates.splice(k, 1)
          k--
          unsharedLineIndices.splice(j, 1)
          j--
          if (j === -1) break
        }
      }
    }
    Array.prototype.push.apply(unsharedLineIndices, candidates)
  }
  const boundaryLines = []
  const positionVertices = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
  const matrix = mesh.getWorldMatrix()
  unsharedLineIndices.forEach((line) => {
    const point1 = BABYLON.Vector3.TransformCoordinates(
      pointFromIndex(line[0], positionVertices),
      matrix,
    )
    const point2 = BABYLON.Vector3.TransformCoordinates(
      pointFromIndex(line[1], positionVertices),
      matrix,
    )
    point1.y += 0.005
    point2.y += 0.005
    boundaryLines.push({
      point1: point1,
      point2: point2,
      length: BABYLON.Vector3.Distance(point1, point2),
      parentMesh: mesh,
    })
  })
  return boundaryLines
}

function createOutline(meshes, scene) {
  if (!meshes || !meshes.length) return
  const allBoundaries = []
  meshes.forEach((mesh) => Array.prototype.push.apply(allBoundaries, findBoundaryLines(mesh)))
  return createLinedMesh(
    removeDuplicateSegments(allBoundaries),
    scene,
    meshes[0].name.split('_')[0],
  )
}

function removeDuplicateSegments(lines) {
  const cleanedLines = []
  lines.forEach((candidateLine) => {
    let existenceDetected = false
    for (let i = 0; i < cleanedLines.length; i++) {
      const line = cleanedLines[i]
      if (areLinesCollinear(line, candidateLine)) {
        /**
         * check if two lines have some overlap or are same
         */
        if (candidateLine.length > line.length) {
          const point1Inside = checkPointInLine(line.point1, candidateLine)
          const point2Inside = checkPointInLine(line.point2, candidateLine)
          if (point1Inside && point2Inside) {
            if (candidateLine.parentMesh === line.parentMesh) {
              existenceDetected = true
            }
            cleanedLines.splice(i, 1)
            i--
          } else if (point1Inside || point2Inside) {
            arrangeContinuousLine(line, candidateLine)
          }
        } else {
          const point1Inside = checkPointInLine(candidateLine.point1, line)
          const point2Inside = checkPointInLine(candidateLine.point2, line)
          if (point1Inside && point2Inside) {
            if (candidateLine.parentMesh === line.parentMesh) {
              cleanedLines.splice(i, 1)
              i--
            }
            existenceDetected = true
          } else if (point1Inside || point2Inside) {
            arrangeContinuousLine(line, candidateLine)
          }
        }
      }
    }
    if (!existenceDetected) cleanedLines.push(candidateLine)
  })
  return cleanedLines
}

/**
 * This already assumes that lines are collinear.
 * It alters the line informations such that there is no overlap
 * @param {Segment} line1
 * @param {Segment} line2
 */
function arrangeContinuousLine(line1, line2) {
  if (areSegmentsConnected(line1, line2)) return
  //first find unit vector
  const unitVector = line1.point1.subtract(line1.point2).normalize()
  const points = [line1.point1, line1.point2, line2.point1, line2.point2]
  if (Math.abs(unitVector.x) < 1e-7) points.sort((a, b) => a.z / unitVector.z - b.z / unitVector.z)
  else points.sort((a, b) => a.x / unitVector.x - b.x / unitVector.x)
  line1.point1 = points[0].clone()
  line1.point2 = points[2].clone()
  line1.length = BABYLON.Vector3.Distance(line1.point1, line1.point2)
  line2.point1 = points[2].clone()
  line2.point2 = points[3].clone()
  line2.length = BABYLON.Vector3.Distance(line2.point1, line2.point2)
}

function createLinedMesh(boundary, scene, suffix) {
  const lines = []
  boundary.forEach((line, i) => {
    lines.push([line.point1, line.point2])
  })
  const mesh = BABYLON.MeshBuilder.CreateLineSystem(
    'custom_overlay_' + suffix,
    {
      lines: lines,
    },
    scene,
  )
  mesh.enableEdgesRendering()
  mesh.edgesWidth = 0.5
  mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1)
  mesh.isPickable = false
  return mesh
}

/**
 * @typedef Segment
 * @type {object}
 * @property {BABYLON.Vector3} point1
 * @property {BABYLON.Vector3} point2
 * @property {BABYLON.Mesh} parentMesh
 * @property {number} length
 */

/**
 *
 * @param {number[]} segment1
 * @param {number[]} segment2
 * @returns {boolean}
 */
function areLinesSame(segment1, segment2) {
  if (segment1[0] === segment2[0] && segment1[1] === segment2[1]) return true
  else if (segment1[0] === segment2[1] && segment1[1] === segment2[0]) return true
  return false
}

/**
 * @param {number} index
 * @param {number[]} vertices
 * @returns {BABYLON.Vector3}
 */
function pointFromIndex(index, vertices) {
  const positionIndex = index * 3
  const point1 = new BABYLON.Vector3(
    vertices[positionIndex],
    vertices[positionIndex + 1],
    vertices[positionIndex + 2],
  )
  return point1
}

/**
 * @param {BABYLON.Vector3} point
 * @param {Segment} line
 * @returns
 */
function checkPointInLine(point, line) {
  const l1 = distance(point, line.point1)
  const l2 = distance(point, line.point2)
  if (Math.abs(l1 + l2 - line.length) <= EPSILON) {
    return true
  }
}

function distance(point1, point2) {
  return BABYLON.Vector3.Distance(point1, point2)
}

function areLinesCollinear(line1, line2) {
  return (
    arePointsCollinear(line1.point1, line1.point2, line2.point1) &&
    arePointsCollinear(line1.point1, line1.point2, line2.point2)
  )
}

function arePointsCollinear(a, b, c) {
  return Math.abs((b.x - a.x) * (c.z - a.z) - (c.x - a.x) * (b.z - a.z)) < EPSILON
}

/**
 * 1 divided by width should return whole number. The number of meshes this will generate
 * 1.) 4 meshes at the corner of the template meshes
 * 2.) 1/width number of meshes on each side
 * All meshes have uv continuing from the template edges
 * All meshes have position at zero similar to template meshes
 * @param {number} width
 * @param {"square"|"triangle"|"triangle 2"} type
 */
function createBoarder(width, type, perMesh) {
  emptySceneOfMeshes('boarder')
  if (!width || isNaN(width)) return

  if (perMesh) {
    scene.meshes.forEach((mesh) => {
      if (/template_Shape_/.test(mesh.name) && !/accent_/.test(mesh.name)) {
        createBoarderForMesh(width, type, mesh)
      }
    })
    return
  }

  const meshesOnEachSide = Math.round(2 / width)

  for (let i = 0; i < 4; i++) {
    let xStart = 1
    let yStart = 1
    switch (i) {
      case 1:
        xStart = 1
        yStart = -1 - width
        break
      case 2:
        xStart = -1 - width
        yStart = 1
        break
      case 3:
        xStart = 1
        yStart = 1
        break
      default:
        break
    }
    let cornerMeshX = i === 3 ? -xStart - width : xStart
    let cornerMeshY = i === 2 ? -yStart - width : yStart

    const cornerMeshes = createUnitBoarderMesh(
      'corner_' + i,
      [cornerMeshX, cornerMeshY],
      type,
      width,
    )
    cornerMeshes.forEach((cornerMesh, i) => processMesh(cornerMesh, 'boarder', i))
    if (type === 'square')
      cornerMeshes[0].material = cornerMeshes[0].metadata.defaultMaterial =
        defaultMaterial.standard3

    for (let j = 0; j < meshesOnEachSide; j++) {
      i % 2 === 0 ? (yStart -= width) : (xStart -= width)
      const meshes = createUnitBoarderMesh('side_' + i + '_' + j, [xStart, yStart], type, width)
      meshes.forEach((mesh, k) => processMesh(mesh, 'boarder', type === 'square' ? j : k))
    }
  }
}

function createBoarderForMesh(borderWidth, type, mesh) {
  const box = mesh.getBoundingInfo().boundingBox
  const w = box.maximumWorld.x - box.minimumWorld.x
  const h = box.maximumWorld.z - box.minimumWorld.z
  const d = Math.max(w, h)
  const meshesOnEachSide = Math.round(2 / borderWidth)
  const width = (d / (meshesOnEachSide - 1)) * 2

  const startPoints = [
    [box.maximumWorld.x * 2, box.maximumWorld.z * 2],
    [box.maximumWorld.x * 2, box.minimumWorld.z * 2 - width],
    [box.minimumWorld.x * 2 - width, box.minimumWorld.z * 2 - width],
    [box.minimumWorld.x * 2 - width, box.maximumWorld.z * 2],
  ]
  const movePoints = [
    [0, -width],
    [-width, 0],
    [0, width],
    [width, 0],
  ]

  for (let i = 0; i < 4; i++) {
    let [xStart, yStart] = startPoints[i]

    // const cornerMeshes = createUnitBoarderMesh(
    //   mesh.id + '_corner_' + i,
    //   [xStart, yStart],
    //   type,
    //   width,
    // )
    // cornerMeshes.forEach((cornerMesh, i) => processMesh(cornerMesh, 'boarder', i))
    // if (type === 'square')
    //   cornerMeshes[0].material = cornerMeshes[0].metadata.defaultMaterial =
    //     defaultMaterial.standard3

    for (let j = 0; j < meshesOnEachSide; j++) {
      const [mx, mz] = movePoints[i]
      xStart += mx
      yStart += mz
      const meshes = createUnitBoarderMesh(
        mesh.id + '_side_' + i + '_' + j,
        [xStart, yStart],
        type,
        width,
      )
      meshes.forEach((mesh, k) => processMesh(mesh, 'boarder', type === 'square' ? j : k))
    }
  }
}

function createUnitBoarderMesh(name, startPosition, type, width) {
  let x = startPosition[0]
  let y = startPosition[1]
  let uvValues = [x, y, x + width, y, x + width, y + width, x, y + width]
  let positionValues = []
  let positionValues2 = []
  let uvValues2
  if (type === 'square') {
    const mesh = new BABYLON.Mesh(name, scene)
    mesh.setIndices([0, 1, 3, 1, 2, 3], 4, true)
    mesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, [0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0])
    uvValues.forEach((value, i) =>
      i % 2 === 0 ? positionValues.push(value / 2) : positionValues.push(0, value / 2),
    )
    mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, positionValues)
    mesh.setVerticesData(BABYLON.VertexBuffer.UVKind, uvValues)
    return [mesh]
  } else if (type === 'triangle') {
    const mesh1 = new BABYLON.Mesh(name + '_1', scene)
    mesh1.setIndices([0, 1, 2], 3, true)
    mesh1.setVerticesData(BABYLON.VertexBuffer.NormalKind, [0, 1, 0, 0, 1, 0, 0, 1, 0])
    uvValues = [x, y, x + width, y, x, y + width]
    uvValues.forEach((value, i) =>
      i % 2 === 0 ? positionValues.push(value / 2) : positionValues.push(0, value / 2),
    )
    mesh1.setVerticesData(BABYLON.VertexBuffer.PositionKind, positionValues)
    mesh1.setVerticesData(BABYLON.VertexBuffer.UVKind, uvValues)

    const mesh2 = new BABYLON.Mesh(name + '_2', scene)
    mesh2.setIndices([0, 1, 2], 3, true)
    mesh2.setVerticesData(BABYLON.VertexBuffer.NormalKind, [0, 1, 0, 0, 1, 0, 0, 1, 0])
    uvValues2 = [x + width, y, x + width, y + width, x, y + width]
    uvValues2.forEach((value, i) =>
      i % 2 === 0 ? positionValues2.push(value / 2) : positionValues2.push(0, value / 2),
    )
    mesh2.setVerticesData(BABYLON.VertexBuffer.PositionKind, positionValues2)
    mesh2.setVerticesData(BABYLON.VertexBuffer.UVKind, uvValues2)
    return [mesh1, mesh2]
  } else if (type === 'triangle 2') {
    const mesh1 = new BABYLON.Mesh(name + '_1', scene)
    mesh1.setIndices([0, 1, 2], 3, true)
    mesh1.setVerticesData(BABYLON.VertexBuffer.NormalKind, [0, 1, 0, 0, 1, 0, 0, 1, 0])
    uvValues = [x, y, x + width, y + width, x, y + width]
    uvValues.forEach((value, i) =>
      i % 2 === 0 ? positionValues.push(value / 2) : positionValues.push(0, value / 2),
    )
    mesh1.setVerticesData(BABYLON.VertexBuffer.PositionKind, positionValues)
    mesh1.setVerticesData(BABYLON.VertexBuffer.UVKind, uvValues)

    const mesh2 = new BABYLON.Mesh(name + '_2', scene)
    mesh2.setIndices([0, 1, 2], 3, true)
    mesh2.setVerticesData(BABYLON.VertexBuffer.NormalKind, [0, 1, 0, 0, 1, 0, 0, 1, 0])
    uvValues2 = [x, y, x + width, y, x + width, y + width]
    uvValues2.forEach((value, i) =>
      i % 2 === 0 ? positionValues2.push(value / 2) : positionValues2.push(0, value / 2),
    )
    mesh2.setVerticesData(BABYLON.VertexBuffer.PositionKind, positionValues2)
    mesh2.setVerticesData(BABYLON.VertexBuffer.UVKind, uvValues2)
    return [mesh1, mesh2]
  }
}

/**
 * @param {string[]} items
 */
var clearScene = function (items) {
  if (!items || items.indexOf('scene') + 1) emptySceneOfMeshes('scene')
  if (!items || items.indexOf('accent') + 1) emptySceneOfMeshes('accent')
  if (!items || items.indexOf('template') + 1) emptySceneOfMeshes('template')
  if (!items || items.indexOf('boarder') + 1) emptySceneOfMeshes('boarder')
  if (!items || items.indexOf('lights') + 1) while (scene.lights.length) scene.lights[0].dispose()
  if (!items || items.indexOf('cameras') + 1)
    for (var i = 0; i < scene.cameras.length; i++) {
      if (
        !(
          scene.cameras[i].name === 'patternEditorCamera' ||
          scene.cameras[i].name === 'screenshotCamera'
        )
      ) {
        scene.cameras[i].dispose()
        i--
        scene.setActiveCameraByName('patternEditorCamera')
      }
    }
  if (!items || items.indexOf('materials') + 1)
    while (scene.materials.length) scene.materials[0].dispose()
}

function emptySceneOfMeshes(prefix) {
  if (!prefix) {
    while (scene.meshes.length) {
      scene.removeMesh(scene.meshes[0])
    }
  } else {
    let regex = new RegExp('^' + prefix + '_')
    for (let i = 0; i < scene.meshes.length; i++) {
      if (regex.test(scene.meshes[i].name)) {
        scene.removeMesh(scene.meshes[i])
        i--
      }
    }
  }
}

function processMeshes(meshes, prefix, forEachMesh) {
  for (let i = 0; i < meshes.length; i++) {
    const mesh = meshes[i]
    forEachMesh && forEachMesh(mesh)
    processMesh(mesh, prefix, i)
  }
}

/**
 * @param {BABYLON.Mesh}
 * @param {string} prefix
 * @param {index} number
 */
function processMesh(mesh, prefix, index) {
  if (prefix) mesh.name = prefix + '_' + mesh.name

  if (OVERLAY_TEST_REGEX.test(mesh.name)) {
    mesh.dispose()
  } else {
    mesh.metadata = {}
    mesh.isPickable = true
    mesh.material = mesh.metadata.defaultMaterial =
      index % 2 === 0 ? defaultMaterial.standard1 : defaultMaterial.standard2
  }
}

const workingVector1 = new BABYLON.Vector3()
const workingVector2 = new BABYLON.Vector3()
const wTwoDPoint1 = new BABYLON.Vector2()
const wTwoDPoint2 = new BABYLON.Vector2()

/**
 * @param {BABYLON.mesh} mesh
 * @returns {number}
 */
function getUVScale(mesh) {
  var indices = mesh.getIndices()
  var positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
  var uvs = mesh.getVerticesData(BABYLON.VertexBuffer.UVKind)

  if (!uvs) return 1

  const indexStart = mesh.subMeshes[0].indexStart
  var index = indices[indexStart]
  workingVector1.copyFromFloats(
    positions[index * 3],
    positions[index * 3 + 1],
    positions[index * 3 + 2],
  )
  wTwoDPoint1.set(uvs[index * 2], uvs[index * 2 + 1])
  wTwoDPoint1.set(uvs[index * 2], uvs[index * 2 + 1])

  index = indices[indexStart + 2]
  workingVector2.copyFromFloats(
    positions[index * 3],
    positions[index * 3 + 1],
    positions[index * 3 + 2],
  )
  wTwoDPoint2.set(uvs[index * 2], uvs[index * 2 + 1])

  var worldMatrix = mesh.getWorldMatrix()
  BABYLON.Vector3.TransformCoordinatesToRef(workingVector1, worldMatrix, workingVector1)
  BABYLON.Vector3.TransformCoordinatesToRef(workingVector2, worldMatrix, workingVector2)

  return Math.sqrt(
    BABYLON.Vector3.DistanceSquared(workingVector1, workingVector2) /
      BABYLON.Vector2.DistanceSquared(wTwoDPoint1, wTwoDPoint2),
  )
}

function CheckReadyBeforeUpdate(cb) {
  const unpreparedMesh = scene.meshes.find((mesh) => {
    if (mesh.material) {
      if (mesh.material.albedoTexture && !mesh.material.albedoTexture.isReady()) return true
      else if (mesh.material.bumpTexture && !mesh.material.bumpTexture.isReady()) return true
      else return false
    } else {
      return false
    }
  })

  if (unpreparedMesh) {
    setTimeout(CheckReadyBeforeUpdate, 200, cb)
  } else {
    setTimeout(() => {
      cb()
    }, 200)
  }
}

/**
 * @param {Document} doc
 * @param {string} name
 * @param {node|string} value
 */
function createXMLElement(doc, name, value, parent) {
  const node = doc.createElement(name)
  if (typeof value == 'string' || typeof value == 'number') node.innerHTML = value
  else if (value instanceof Node) node.appendChild(value)
  parent && parent.appendChild(node)
  return node
}

function takeScreenShot(cb, size) {
  const areOutlinesVisible = showOverlay
  if (areOutlinesVisible) {
    toggleOverlay(false)
  }
  computeBoundsOfPattern()

  BABYLON.Tools.CreateScreenshotUsingRenderTarget(
    engine,
    screenShotCamera,
    {
      width: size,
      height: size,
    },
    (base64String) => {
      cb(base64String)
      toggleOverlay(areOutlinesVisible)
    },
  )
}

/**
 *
 * @param {BABYLON.Mesh} mesh
 * @returns {BABYLON.NodeMaterial}
 */
var getMeshMaterial = function (mesh) {
  if (!mesh.material || mesh.material.getClassName() != 'NodeMaterial')
    mesh.material = defaultMaterial.nodeMaterial.clone('mat_' + mesh.name)
  else if (mesh.material.name == 'sharedMaterial')
    mesh.material = mesh.material.clone('mat_' + mesh.name)
  return mesh.material
}

window.showDebugLayer = function () {
  scene.debugLayer.show()
}

/**
 * @param {boolean} newState
 */
function toggleOverlay(newState) {
  showOverlay = newState
  scene.meshes.forEach((mesh) => {
    if (/(boarder|Shape_)/.test(mesh.name)) {
      if (!showOverlay) mesh.disableEdgesRendering()
      else mesh.enableEdgesRendering()
      mesh.edgesWidth = showOverlay ? 0.5 : 0
      mesh.edgesColor = new BABYLON.Color4(255, 255, 255, showOverlay ? 1 : 0)
    }
  })
}

function createTextPlane() {
  const textMesh = BABYLON.MeshBuilder.CreatePlane('textPlane', {
    width: 1,
    height: 1,
  })
  textMesh.position.y = 0.03
  textMesh.rotation.x = Math.PI / 2
  textMesh.rotation.y = Math.PI
  textMesh.isPickable = false

  textMesh.material = new BABYLON.StandardMaterial('textPlaneMaterial', scene)
  textMesh.material.useAlphaFromDiffuseTexture = true
  textMesh.material.specularColor.set(0.2, 0.2, 0.2)

  textTexture = new BABYLON.DynamicTexture('textTexture', 1024)
  textMesh.material.diffuseTexture = textTexture
  textMesh.material.opacityTexture = textTexture
}

function setTextOn3DRenderer(imageData) {
  const context = textTexture.getContext()
  context.clearRect(0, 0, 1024, 1024)
  const image = new Image()
  image.addEventListener('load', () => {
    context.drawImage(image, 0, 0)
    textTexture.update()
  })
  image.src = imageData
}

/**
 * Flickers highlight on a mesh
 * @param {BABYLON.Mesh} mesh
 */
function superHighlight(mesh) {
  let alpha = 0
  const hl = new BABYLON.HighlightLayer('highlight_' + mesh.name, scene)
  hl.addMesh(mesh, BABYLON.Color3.FromHexString('#ffcccc'))
  //hl.innerGlow = false
  let timeStarted = new Date().getTime()
  let timePassed = 0
  const registeredFunction = () => {
    alpha += 0.12
    timePassed = new Date().getTime() - timeStarted
    hl.blurHorizontalSize = 0.3 + Math.cos(alpha) * 0.6 + 0.6
    hl.blurVerticalSize = 0.3 + Math.sin(alpha / 3) * 0.6 + 0.6
    if (timePassed > 500) {
      hl.dispose()
      scene.unregisterBeforeRender(registeredFunction)
    }
  }
  scene.registerBeforeRender(registeredFunction)
}

function computeBoundsOfPattern() {
  //to make sure bounding infos are updated
  scene.render()
  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity
  const padding = 0

  scene.meshes.forEach((mesh) => {
    //consider only template meshes for computing orthocamera limits
    if (!/(^template_|boarder_)/.test(mesh.name) || OVERLAY_TEST_REGEX.test(mesh.name)) return
    const bb = mesh.getBoundingInfo().boundingBox
    minX = Math.min(minX, bb.minimumWorld.x)
    minY = Math.min(minY, bb.minimumWorld.z)
    maxX = Math.max(maxX, bb.maximumWorld.x)
    maxY = Math.max(maxY, bb.maximumWorld.z)
  })

  const r = Math.max(maxX, maxY)
  screenShotCamera.orthoBottom = 0 - r - padding
  screenShotCamera.orthoTop = r + padding
  screenShotCamera.orthoLeft = 0 - r - padding
  screenShotCamera.orthoRight = r + padding
}

/**
 * @param {BABYLON.Mesh} mesh
 * @param {boolean} force
 */
function resetMesh(mesh, force) {
  if (force) {
    delete mesh.metadata.material
    delete mesh.metadata.colorR
    delete mesh.metadata.print
  }
  if (!mesh.metadata.material && !mesh.metadata.print && !mesh.metadata.colorR) {
    if (mesh.material && mesh.material.getClassName() === 'NodeMaterial') mesh.material.dispose()
    mesh.material = mesh.metadata.defaultMaterial
  }
}

/**
 * Shows highlight on passed meshes. If a mesh is already highlighted
 * and a new call is made without that mesh containing in the list, its
 * highlight will be removed. Basically this can be used a remove highlight too
 * @param {Set<BABYLON.Mesh>} meshes
 */
function showHighlightOn(meshes) {
  const highlightLayerNames = []
  for (let i = 0; i < highlightLayers.length; i++) {
    const mesh = scene.getMeshByName(highlightLayers[i].name.replace(/^highlight_/i, ''))
    if (!meshes.has(mesh)) {
      highlightLayers[i].dispose()
      highlightLayers.splice(i, 1)
      i--
      continue
    }
    highlightLayerNames.push(highlightLayers[i].name)
  }

  meshes.forEach((mesh) => {
    const layerName = 'highlight_' + mesh.name
    if (highlightLayerNames.indexOf(layerName) === -1) {
      const hl = new BABYLON.HighlightLayer(layerName, scene)
      //hl.innerGlow = false
      hl.addMesh(mesh, BABYLON.Color3.Green())
      highlightLayers.push(hl)
    }
  })
}

/**
 * Also updates outlines on boarder meshes
 */
function updateTemplateOutline() {
  emptySceneOfMeshes('custom_overlay')
  const templateMeshes = []
  const accentMeshes = []
  scene.meshes.forEach((mesh) => {
    if (mesh.isEnabled()) {
      if (/^boarder_/.test(mesh.name) || /^template_/.test(mesh.name)) templateMeshes.push(mesh)
      else if (/^accent_/.test(mesh.name)) accentMeshes.push(mesh)
    }
  })
  // createOutline(templateMeshes, scene)
  // createOutline(accentMeshes, scene)
  toggleOverlay(showOverlay)
}

/**
 *
 */
function findUniqueSurfaces() {
  const uniqueSurfaces = {}
  const uniqueColors = []
  const uniquePrints = []
  scene.meshes.forEach((mesh) => {
    if (mesh.metadata && /^(template|boarder|accent)_/.test(mesh.name)) {
      let uniqueString = ''
      if (mesh.metadata.colorR) {
        const colorHex = mesh.material
          .getInputBlocks()
          .find((block) => block.name === 'colorR')
          .value.toHexString()
        uniqueString += '_colorR_' + colorHex
        if (!uniqueColors.find((color) => color.hex[0] == colorHex)) {
          uniqueColors.push({
            color: colorHex,
          })
        }
      }
      if (mesh.metadata.print) {
        if (mesh.metadata.print.egoID) {
          uniqueString += '_printEgoID_' + mesh.metadata.print.egoID
          if (
            !uniquePrints.find(
              (print) =>
                print.url == mesh.metadata.print.egoFileURL ||
                print.url == mesh.metadata.print.egoImageFullURL,
            )
          ) {
            uniquePrints.push({
              url: mesh.metadata.print.egoFileURL || mesh.metadata.print.egoImageFullURL,
              eogID: mesh.metadata.print.egoID,
            })
          }
        } else {
          uniqueString += '_printID_' + mesh.metadata.print.id
          if (!uniquePrints.find((print) => print.id == mesh.metadata.print.id)) {
            uniquePrints.push({
              id: mesh.metadata.print.id,
              url: mesh.metadata.print.thumb,
            })
          }
        }
      }
      if (mesh.metadata.material) uniqueString += '_material_' + mesh.metadata.material.id
      if (uniqueString !== '') {
        if (!uniqueSurfaces[uniqueString]) uniqueSurfaces[uniqueString] = []
        uniqueSurfaces[uniqueString].push(mesh)
      }
    }
  })
  return {
    uniqueSurfaces: uniqueSurfaces,
    uniqueColors: uniqueColors,
    uniquePrints: uniquePrints,
  }
}

function computeRequirement(uniqueSurfaces) {
  let materialIndex = 0
  const requirement = []
  Object.keys(uniqueSurfaces).forEach((key) => {
    materialIndex++
    const meshes = uniqueSurfaces[key]
    const allBoundaries = []
    meshes.forEach((mesh) => Array.prototype.push.apply(allBoundaries, findBoundaryLines(mesh)))
    const totalArea = findClosedPaths(removeDuplicateSegments(allBoundaries)).reduce(
      (previousValue, sum, i, arr) => {
        return previousValue + polygonArea(verticesFromPath(arr[i]))
      },
      0,
    )
    requirement.push({
      uniqueKey: key,
      friendlayName: 'Material ' + materialIndex,
      area: totalArea,
      //previewURL: TODO
    })
  })
  return requirement
}

/**
 * Returns all line segments that form closed loops as array of array of line segments.
 * Each array of line segments is a cycle (or a potential room)
 * @param {Segment[]} lineSegments
 * @returns {Array<Segment[]>}
 */
function findClosedPaths(lineSegments) {
  var cycles = []
  for (var i = 0; i < lineSegments.length; i++) {
    var lineSegment = lineSegments[i]
    if (!isLineSegmentPartOfCycle(lineSegment, cycles)) {
      var cycle = findClosedPath(lineSegment, lineSegments, cycles)
      if (cycle) {
        cycles.push(cycle)
      }
    }
  }
  return cycles
}

/**
 * Returns a closed line segement loop by finding consecutive line segment connections.
 * If the line segments found are less than 2 , a null is returned as room cannot be formed.
 * @param {Segment} segment
 * @param {Segment[]} segments
 * @param {Array<Segment[]>} cycles existing cycles object
 * @returns {Segment[]}
 */
function findClosedPath(segment, segments, cycles) {
  var cycle = [segment]
  var currentSegment = segment
  while (currentSegment) {
    var foundSegment = null
    for (var i = 0; i < segments.length; i++) {
      var toCompareWithSegment = segments[i]

      if (cycle.indexOf(toCompareWithSegment) == -1) {
        if (!isLineSegmentPartOfCycle(toCompareWithSegment, cycles, cycle)) {
          if (areSegmentsConnected(currentSegment, toCompareWithSegment)) {
            cycle.push(toCompareWithSegment)
            currentSegment = toCompareWithSegment
            foundSegment = currentSegment
            if (cycle.length > 2 && areSegmentsConnected(cycle[0], toCompareWithSegment)) {
              return cycle
            }
            break
          }
        }
      }
    }

    if (!foundSegment) {
      currentSegment = null
    }
  }

  if (cycle.length <= 2) {
    return null
  } else {
    //cycle is not closed. do something about it.
    return cycle
  }
}

/**
 *
 * @param {lineSegment[]} path
 * @returns {BABYLON.Vector3[]}
 */
function verticesFromPath(path) {
  const vertices = []
  //if first line segment is connected to second line segment via point1
  if (
    arePointsSame(path[0].point1, path[1].point1) ||
    arePointsSame(path[0].point1, path[1].point2)
  ) {
    vertices.push(path[0].point2)
    vertices.push(path[0].point1)
  } else {
    //implicilty implies it is connected via point2
    vertices.push(path[0].point1)
    vertices.push(path[0].point2)
  }
  for (var i = 1; i < path.length; i++) {
    var found = false
    for (var j = 0; j < vertices.length; j++) {
      if (arePointsSame(vertices[j], path[i].point1)) {
        found = true
      }
    }
    if (!found) {
      vertices.push(path[i].point1)
    } else {
      found = false
      for (j = 0; j < vertices.length; j++) {
        if (arePointsSame(vertices[j], path[i].point2)) {
          found = true
        }
      }
      if (!found) {
        vertices.push(path[i].point2)
      }
    }
  }
  //forcefully close the path
  vertices.push(vertices[0])
  return vertices
}

/**
 * Calculates the area of the polygon
 * @returns {number} area of the polygon
 */
function polygonArea(vertices) {
  var numPoints = vertices.length - 1
  var area = 0 // Accumulates area in the loop
  var j = numPoints - 1 // The last vertex is the 'previous' one to the first

  for (var i = 0; i < numPoints; i++) {
    area += (vertices[j].x + vertices[i].x) * (vertices[j].z - vertices[i].z)
    j = i //j is previous vertex to i
  }
  return Math.abs(area / 2)
}

/**
 * Determines if a line segment is already a part of existing cycle
 * @param {Segment} segment
 * @param {Array<Segment[]>} cycles
 * @param {Segment[]} [excludeCycle]
 * @returns {boolean}
 */
function isLineSegmentPartOfCycle(segment, cycles, excludeCycle) {
  for (var i = 0; i < cycles.length; i++) {
    for (var j = 0; j < cycles[i].length; j++) {
      if (!excludeCycle || cycles[i] !== excludeCycle) {
        if (cycles[i][j] === segment) {
          return true
        }
      }
    }
  }
  return false
}

/**
 * Determines if two line segments are connected
 * @param {Segment} segment1
 * @param {Segment} segment2
 * @returns {boolean}
 */
function areSegmentsConnected(segment1, segment2) {
  if (
    arePointsSame(segment1.point1, segment2.point1) ||
    arePointsSame(segment1.point2, segment2.point1) ||
    arePointsSame(segment1.point1, segment2.point2) ||
    arePointsSame(segment1.point2, segment2.point2)
  ) {
    return true
  }
  return false
}

function arePointsSame(point1, point2) {
  if (BABYLON.Vector3.DistanceSquared(point1, point2) < EPSILON) return true
  return false
}

function savePrintRequirement() {
  const fabricInfo = findUniqueSurfaces()
  addNote('fabric-requirement', JSON.stringify(computeRequirement(fabricInfo.uniqueSurfaces)))
  addNote('color-swatches', JSON.stringify(fabricInfo.uniqueColors))
  addNote('print-list', JSON.stringify(fabricInfo.uniquePrints))
}

function getSurfaceType(mesh) {
  let surfaceType = 0
  if (/^scene_/i.test(mesh.name)) surfaceType = 2
  else if (/^accent_/i.test(mesh.name)) surfaceType = 1
  else if (/^boarder_/i.test(mesh.name)) surfaceType = 3
  return surfaceType
}

/**
 * @param {BABYLON.Mesh} mesh
 * @returns {boolean} if any kind of application was made on the mesh
 */
function surfaceApplied(mesh) {
  if (mesh.metadata) {
    if (mesh.metadata.colorR || mesh.metadata.print || mesh.metadata.material) return true
  }
  return false
}

function loadMaterial(cb) {
  BABYLON.NodeMaterial.ParseFromFileAsync(
    'patternMaterial',
    './assets/patterns/node-materials/PatternMaterial2.json',
    scene,
  ).then((mat) => {
    defaultMaterial.nodeMaterial = mat
    cb && cb(mat)
    applyDefaultMaterial(defaultMaterial.nodeMaterial)
    applyDefaultPrint(defaultMaterial.nodeMaterial)
    //applyDefaultColor(nodeMaterial)
  })
  const material1 = new BABYLON.StandardMaterial('starter1', scene)
  material1.diffuseColor = new BABYLON.Color3(0.05, 0.05, 0.05)
  material1.specularColor.set(0.4, 0.4, 0.4)
  defaultMaterial.standard1 = material1
  const material2 = new BABYLON.StandardMaterial('starter2', scene)
  material2.diffuseColor = new BABYLON.Color3(0.07, 0.07, 0.07)
  material2.specularColor.set(0.4, 0.4, 0.4)
  defaultMaterial.standard2 = material2
  const material5 = new BABYLON.StandardMaterial('starter3', scene)
  material5.diffuseColor = new BABYLON.Color3(0.03, 0.03, 0.03)
  material5.specularColor.set(0.4, 0.4, 0.4)
  defaultMaterial.standard3 = material5
  const material3 = new BABYLON.StandardMaterial('overlayMaterial', scene)
  material3.diffuseColor = new BABYLON.Color3(0, 0, 0)
  material3.specularColor.set(0, 0, 0)
  defaultMaterial.overlay = material3
  const material4 = new BABYLON.StandardMaterial('textMaterial', scene)
  material4.diffuseColor = new BABYLON.Color3(0, 0, 0)
  material4.specularColor.set(0, 0, 0)
  material4.backFaceCulling = false
  defaultMaterial.text = material4
}

function applyDefaultMaterial(material) {
  const textureBlocks = material.getTextureBlocks()
  const materialBaseBlock = textureBlocks.find((block) => block.name === 'yBaseColorTex')
  materialBaseBlock.texture = new BABYLON.Texture(
    'data:material_base',
    scene,
    false,
    false,
    null,
    null,
    (r) => {
      console.log(r)
    },
    defaultTexture.materialBase,
    true,
  )
  const materialNormalBlock = textureBlocks.find((block) => block.name === 'yNormalTex')
  materialNormalBlock.texture = new BABYLON.Texture(
    'data:material_normal',
    scene,
    false,
    false,
    null,
    null,
    (r) => {
      console.log(r)
    },
    defaultTexture.materialNormal,
    true,
  )
  const inputBlocks = material.getInputBlocks()
  inputBlocks.forEach((block) => {
    switch (block.name) {
      case 'metallic':
        block.value = 0
        break
      case 'normalStrength':
        block.value = 0.5
        break
      case 'roughness':
        block.value = 0.5
        break
      default:
        break
    }
  })
}

function applyDefaultPrint(material) {
  const textureBlocks = material.getTextureBlocks()
  textureBlocks.find((block) => block.name === 'yPrintTex').texture = new BABYLON.Texture(
    'data:material_print',
    scene,
    false,
    false,
    null,
    null,
    (r) => {
      console.log(r)
    },
    defaultTexture.print,
    true,
  )
  const inputBlocks = material.getInputBlocks()
  inputBlocks.forEach((block) => {
    switch (block.name) {
      case 'useCustomPrint':
        block.value = 0
        break
      case 'uPrintOffset':
      case 'vPrintOffset':
        block.value = 0
        break
      default:
        break
    }
  })
  applyDefaultColor(material)
}

function applyDefaultColor(material) {
  material.getInputBlocks().forEach((block) => {
    if (block.name == 'useG' || block.name == 'useB' || block.name == 'useA') block.value = 0
    if (block.name == 'useR') block.value = 1
    if (block.name == 'colorR') block.value.set(0.5, 0.5, 0.5)
  })
}

function createPresetTextures() {
  const canvas = document.createElement('canvas')
  canvas.width = 32
  canvas.height = 32
  const context = canvas.getContext('2d')
  context.fillStyle = 'white'
  context.fillRect(0, 0, 32, 32)
  defaultTexture.materialBase = canvas.toDataURL('image/png')
  context.fillStyle = 'blue'
  context.fillRect(0, 0, 32, 32)
  defaultTexture.materialNormal = canvas.toDataURL('image/png')
  context.fillStyle = 'red'
  context.fillRect(0, 0, 32, 32)
  defaultTexture.print = canvas.toDataURL('image/png')
}

export const RenderHelper = {
  CreateBoarder: createBoarder,
  CreateOutline: createOutline,
  Initialize: initializeHelpers,
  EmptySceneOfMeshes: emptySceneOfMeshes,
  ClearScene: clearScene,
  ProcessMeshes: processMeshes,
  ProcessMesh: processMesh,
  GetUVScale: getUVScale,
  CreateTextPlane: createTextPlane,
  ComputeBoundsOfPattern: computeBoundsOfPattern,
  ShowHighlightOn: showHighlightOn,
  CreateXMLElement: createXMLElement,
  GetMeshMaterial: getMeshMaterial,
  CheckReadyBeforeUpdate: CheckReadyBeforeUpdate,
  ToggleOverlay: toggleOverlay,
  UpdateTemplateOutline: updateTemplateOutline,
  TakeScreenShot: takeScreenShot,
  SetTextOn3DRenderer: setTextOn3DRenderer,
  SuperHighlight: superHighlight,
  ResetMesh: resetMesh,
  ComputeRequirement: computeRequirement,
  SavePrintRequirement: savePrintRequirement,
  GetSurfaceType: getSurfaceType,
  SurfaceApplied: surfaceApplied,
  LoadNodeMaterial: loadMaterial,
  ApplyDefaultColor: applyDefaultColor,
  ApplyDefaultMaterial: applyDefaultMaterial,
  ApplyDefaultPrint: applyDefaultPrint,
  DEFAULT_MATERIAL: defaultMaterial,
}
