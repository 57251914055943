import { SVGS } from '@/assets/svgs'
import { ButtonCreateNew } from '@/pages/__styled'
import colors from '@/styles/colors'
import { theme } from '@/styles/theme'
import { Box, Button, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'
import toast from 'react-hot-toast'

interface ShareLinkProps {
  onClose: () => void
  link?: string | null
}
export const ShareLink = ({ onClose, link = null }: ShareLinkProps) => {
  const linkText = link || window.location.href
  const handleCopy = () => {
    navigator.clipboard
      .writeText(linkText)
      .then(() => {
        toast.success('Copied!', {
          duration: 2000,
          position: 'top-right',
        })
      })
      .catch((err) => {
        toast.error('Copy Failed!', {
          duration: 2000,
          position: 'top-right',
        })
      })
    onClose()
  }

  return (
    <Box sx={{ borderRadius: '8px', backgroundColor: colors.lightGrey, p: 2 }}>
      <Typography fontSize={'13px'} fontWeight={500} color={colors.themePurpleTextColor}>
        Share via Link
      </Typography>

      <Box
        sx={{
          mt: 2,
          background: 'white',
          borderRadius: 50,
          border: '1px solid #E7EDF3',
          minHeight: '50px',
          display: 'flex',
          alignItems: 'center',
          px: 1,
        }}
      >
        <Typography
          fontSize={'12px'}
          fontWeight={400}
          color={colors.themePurpleTextColor}
          sx={{ flex: 1 }}
          variant='p'
        >{`${linkText}`}</Typography>
        <Button
          onClick={handleCopy}
          sx={{
            height: '100%',
            backgroundColor: theme.palette.brand.light,
            color: 'white',
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: theme.palette.brand.light,
            },
          }}
        >
          Copy
        </Button>
      </Box>
      <Box position={'absolute'} sx={{ top: '12px', right: '12px' }}>
        <SVG src={SVGS.CrossIcon} width={16} height={16} onClick={onClose} />
      </Box>
    </Box>
  )
}
