import { useGetConnectRecommendations, useGetConnectRequests } from "@/hooks/api/dashboard";
import { useBrowserQuery } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { CategoryContainer } from "./__styled";

interface Props {
  onClickCategory: () => void;
}

export default function NotificationCategoryList({ onClickCategory }: Props) {
  const getRecommends = useGetConnectRecommendations();
  const getRequests = useGetConnectRequests();

  const query = useBrowserQuery();
  const { pathname } = useLocation();
  const category = query.get("category");
  const navigate = useNavigate();

  const [connRequests, recommendations] = useAppStore(
    state => [
      state.user.connRequests,
      state.user.recommendations,
    ],
    shallow
  )

  useEffect(() => {
    getRecommends.mutate();
    getRequests.mutate();
  }, [])

  if (getRecommends.isLoading || getRequests.isLoading) {
    return <CircularProgress />
  }

  const onClickItem = (category: string) => {
    query.set('category', category);
    navigate(`${pathname}?${query.toString()}`, { replace: true });
    onClickCategory();
  }

  return (
    <CategoryContainer>
      <Box p={2} borderBottom={`1px solid ${colors.veryLightGrey}`}>
        <Typography variant="label">
          {recommendations.length + connRequests.length} NEW NOTIFICATIONS
        </Typography>
      </Box>
      <Box
        p={2}
        className={`item ${category === 'recommendations' ? 'active' : ''}`}
        onClick={() => onClickItem('recommendations')}
      >
        <Typography variant="label">Recommendations</Typography>
        <Typography>{recommendations.length} New recommendations</Typography>
      </Box>
      <Box
        p={2}
        className={`item ${category === 'requests' ? 'active' : ''}`}
        onClick={() => onClickItem('requests')}
      >
        <Typography variant="label">Requests</Typography>
        <Typography>{connRequests.length} requests</Typography>
      </Box>
    </CategoryContainer>
  )
}