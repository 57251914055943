import { useEffect, useMemo, useState } from 'react'
import { Box, IconButton, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import SVG from 'react-inlinesvg'
import { useLocation, useNavigate } from 'react-router-dom'
import { DiscoverTabPaths, WorkspaceTabPaths, ProjectTabPaths, HOME_PATH } from '@/utils/constants'
import Logo from '@/assets/images/Logo.png'
import TabLogo from '@/assets/svgs/tab-logo.svg'
import TabArrow from '@/assets/svgs/tab-arrow.svg'
import TabArrowBlack from '@/assets/svgs/tab-arrow-black.svg'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import { CreateNewPatternModal } from '@/components'
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material'
import colors from '@/styles/colors'
import { SVGS } from '@/assets/svgs'

const sidebarWidths = {
  collapsed: '90px',
  expanded: '266px',
}

const SideBarContainer = styled(Box)(() => ({
  position: 'fixed',
  width: sidebarWidths.expanded,
  height: '100%',
  transition: 'width 0.3s',
  boxSizing: 'border-box',
  background: 'linear-gradient(180deg, #161719 0%, #1D1E21 100%)',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 2,
}))

export const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
}))

export const BottomButtonContainer = styled(Box)(() => ({
  marginTop: 'auto',
}))

export const StyledMenu = styled('div')(() => ({
  textDecoration: 'none',
  width: '100%',
}))

export const CollapseButton = styled('div')(() => ({
  position: 'absolute',
  top: 17,
  zIndex: 20,
  height: 40,
  width: 20,
  display: 'flex',
  backgroundColor: '#FFFFFF4D',
  borderRadius: '12px',
  alignItems: 'center',
  paddingLeft: 2,
  transition: 'left 0.3s',
  '&.expanded': {
    left: 254,
  },
  '&.collapsed': {
    left: 80,
  },
}))

export const TabButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  position: 'relative',
  height: 49,
  fontSize: 15,
  borderRadius: 6,
  display: 'flex',
  gap: 12,
  justifyContent: 'flex-start',
  width: '100%',
  '&.highlighted': {
    backgroundColor: theme.palette.brand.light,
  },
}))

export const AddWorkspaceButton = styled(IconButton)(() => ({
  color: 'white',
  height: 94,
  fontSize: 15,
  borderRadius: 8,
  display: 'flex',
  gap: 12,
  justifyContent: 'flex-start',
  width: 226,
  borderStyle: 'dashed',
  borderWidth: 2,
  borderColor: '#FFFFFF40',
  padding: 20,
  fontWeight: 'bold',
  '&.collapsed': {
    border: 0,
    padding: 0,
    width: 50,
  },
}))

export default function DashboardSideBar() {
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'))

  const {
    setSideTabKey,
    setSidebarCollapsed,
    setSideTitle,
    setSideTabPath,
    setShowSideMenuDrawer,
  } = useAppStore()
  const [sideTabKey, sideTabPath, sideTitle, sidebarCollapsed, showSideMenuDrawer] = useAppStore(
    (state) => [
      state.app.sideTabKey,
      state.app.sideTabPath,
      state.app.sideTitle,
      state.home.sidebarCollapsed,
      state.app.showSideMenuDrawer,
    ],
    shallow,
  )
  const navigate = useNavigate()
  const [createNewModal, setCreateNewModal] = useState(false)
  const { length, [length - 1]: lastWorkspaceTab } = WorkspaceTabPaths

  const toggleModal = () => {
    setCreateNewModal(!createNewModal)
    // setShowSideMenuDrawer(false)
    // setTimeout(() => {
    //   setCreateNewModal(true)
    // }, 1000)
    // if (showSideMenuDrawer) {
    //   setTimeout(() => {
    //     setCreateNewModal(!createNewModal)
    //   }, 3000)
    // } else {
    //   setCreateNewModal(!createNewModal)
    // }
  }

  useEffect(() => {
    if (lessThanSmall) {
      setSidebarCollapsed(false)
    }
  }, [lessThanSmall])

  const handleNavigate = (path: string, key: string, title: string) => {
    if (key != sideTabKey) {
      setSideTabKey(key)
      setSideTitle(title)
      setSideTabPath(path)
    }
    setShowSideMenuDrawer(false)
    navigate(path)
  }

  const onActiveTabChanged = (path: string, key: string, title: string) => {
    if (key != sideTabKey) {
      setSideTabKey(key)
      setSideTitle(title)
      setSideTabPath(path)
    }
  }

  const onClickLogo = () => {
    // Navigate to Home
    navigate(HOME_PATH)
  }

  return (
    <>
      <SideBarContainer
        flex={1}
        style={{ width: sidebarCollapsed ? sidebarWidths.collapsed : sidebarWidths.expanded }}
      >
        <Box p='20px' display='flex' flexDirection='column' height='100%'>
          <Box
            flex={1}
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {!lessThanSmall && (
              <IconButton
                onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                sx={{
                  position: 'absolute',
                  top: 17,
                  right: sidebarCollapsed ? '-20px' : '0px',
                  zIndex: 20,
                  backgroundColor: sidebarCollapsed ? '#1617191A' : '#FFFFFF4D',
                  width: '20px',
                  height: '40px',
                  borderTopLeftRadius: sidebarCollapsed ? '0px' : '12px',
                  borderTopRightRadius: sidebarCollapsed ? '12px' : '0px',
                  borderBottomLeftRadius: sidebarCollapsed ? '0px' : '12px',
                  borderBottomRightRadius: sidebarCollapsed ? '12px' : '0px',
                }}
              >
                <SVG src={sidebarCollapsed ? TabArrowBlack : TabArrow} width={10} height={14} />
              </IconButton>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: sidebarCollapsed ? 'center' : 'flex-start',
              }}
            >
              <Box sx={{ mb: 3, cursor: 'pointer' }}>
                {sidebarCollapsed ? (
                  <SVG
                    src={TabLogo}
                    width={34}
                    height={34}
                    style={{ margin: 'auto' }}
                    onClick={onClickLogo}
                  />
                ) : (
                  <img src={Logo} alt='Logo' width='151' height='34' onClick={onClickLogo} />
                )}
              </Box>
              <Section
                title='DISCOVER'
                tabPaths={DiscoverTabPaths}
                onNavigate={handleNavigate}
                onActiveTabChanged={onActiveTabChanged}
                isCollapsed={sidebarCollapsed}
              />
              <Box sx={{ my: 2, width: '100%', height: '1px', backgroundColor: '#66696E' }}></Box>

              <Section
                title='WORKSPACE'
                tabPaths={WorkspaceTabPaths.slice(0, -1)}
                onNavigate={handleNavigate}
                onActiveTabChanged={onActiveTabChanged}
                isCollapsed={sidebarCollapsed}
              />
              <Box sx={{ my: 1, backgroundColor: 'transparent' }}></Box>
              <Section
                title='PROJECTS'
                tabPaths={ProjectTabPaths}
                onNavigate={handleNavigate}
                onActiveTabChanged={onActiveTabChanged}
                isCollapsed={sidebarCollapsed}
                hasSubItems={false}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: 'auto' }}>
            <StyledMenu onClick={toggleModal}>
              <AddWorkspaceButton className={sidebarCollapsed ? 'collapsed' : ''}>
                <SVG src={lastWorkspaceTab.icon} width={50} height={50} />
                {!sidebarCollapsed && <span>{lastWorkspaceTab.title}</span>}
              </AddWorkspaceButton>
            </StyledMenu>
          </Box>
        </Box>
      </SideBarContainer>
      <CreateNewPatternModal
        open={createNewModal}
        onClose={() => {
          setCreateNewModal(false)
        }}
      />
    </>
  )
}

function Section({
  title,
  tabPaths,
  onActiveTabChanged,
  onNavigate,
  isCollapsed,
  hasSubItems = false,
}: {
  title: string
  tabPaths: typeof DiscoverTabPaths
  onActiveTabChanged: (path: string, key: string, title: string) => void
  onNavigate: (path: string, key: string, title: string) => void
  isCollapsed: boolean
  hasSubItems?: boolean
}) {
  const location = useLocation()
  const [isSubMenuExpanded, setIsSubMenuExpanded] = useState(true)
  const isActiveRoute = (tabPath: string, tabKey: string, tabTitle: string) => {
    const flag = location.pathname.indexOf(tabPath) > -1
    if (flag) {
      onActiveTabChanged(tabPath, tabKey, tabTitle)
    }
    return flag
  }

  const renderMenuItems = () => (
    <>
      {tabPaths.map((tab) => (
        <StyledMenu key={tab.key} onClick={() => onNavigate(tab.path, tab.key, tab.title)}>
          <TabButton
            sx={{ justifyContent: isCollapsed ? 'center' : 'flex-start' }}
            className={isActiveRoute(tab.path, tab.key, tab.title) ? 'highlighted' : ''}
          >
            <SVG src={tab.icon} width={20} height={20} />
            {!isCollapsed && <span>{tab.title}</span>}
          </TabButton>
        </StyledMenu>
      ))}
    </>
  )

  const isProjectSubMenuSelected = useMemo(() => {
    return location.pathname.indexOf('projects/') > -1
  }, [location.pathname])

  return (
    <>
      {!hasSubItems ? (
        <>
          {title.length > 0 && (
            <Typography variant='subtitle1' color='#66696E' sx={{ fontSize: 12, mt: 1 }}>
              {!isCollapsed ? title : '‎'}
            </Typography>
          )}
          {renderMenuItems()}
        </>
      ) : (
        <>
          <StyledMenu
            className={true ? 'highlighted' : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '8px',
              backgroundColor:
                isProjectSubMenuSelected && !isSubMenuExpanded ? colors.meshdomBlue : 'transparent',
            }}
            onClick={() => setIsSubMenuExpanded(!isSubMenuExpanded)}
          >
            <TabButton
              sx={{ justifyContent: isCollapsed ? 'center' : 'flex-start' }}
              className={false ? 'highlighted' : ''}
            >
              <SVG src={SVGS.TabPattern} width={20} height={20} />
              {!isCollapsed && <span>{title}</span>}
            </TabButton>
            {isSubMenuExpanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </StyledMenu>

          {isSubMenuExpanded && <Box sx={{ ml: '16px', width: '90%' }}>{renderMenuItems()}</Box>}
        </>
      )}
    </>
  )
}
