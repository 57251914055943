import { AddAlbumPreviewItem } from '@/pages/panels/__styled';
import colors from '@/styles/colors';
import { Ego } from '@/types';
import { Base64ForRTE } from '@/utils/constants';
import { getFileUniqueKey } from '@/utils/helper';
import { Add, Close } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { toast } from 'react-hot-toast';
import { FileContent, FileErrors, SelectedFiles, useFilePicker } from 'use-file-picker';
import { EditorWrapper, ImageAttachButton } from './RichEditor.styled';

interface EditProps extends EditorProps {
  isEditing?: boolean;
  files: FileContent[];
  pFiles: File[];
  egos: Ego[];
  onAddFile: (files: FileContent[], pFiles: File[]) => void;
  onRemoveFile: (index: number) => void;
  onRemoveEgo: (ego: Ego) => void;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export function RichEditor({
  isEditing = false,
  files,
  pFiles,
  egos,
  onAddFile,
  onRemoveFile,
  onRemoveEgo,
  loading,
  onSubmit,
  onCancel,
  ...props
}: EditProps) {
  const onFilesSelected = ({ plainFiles, filesContent }: SelectedFiles) => {
    const duplicated = plainFiles.some((i: File) => pFiles.some((j: File) => getFileUniqueKey(i) === getFileUniqueKey(j)))
    if (duplicated) {
      // To-Do: use toast message
      toast.error('You selected one or more duplicated file(s).')
      return;
    } else if (files.length + filesContent.length > 5) {
      toast.error('You can upload 5 files at maximum.')
      return;
    }
    if (filesContent.length > 0) {
      onAddFile(filesContent, plainFiles);
    }
  }

  const onFilePickError = (error: FileErrors) => {
    const { errors } = error;
    errors.forEach(e => {
      if (e.maxLimitExceeded) {
        toast.error('You can select 5 files at maximum.')
      } else if (e.fileSizeToolarge) {
        toast.error('The file size should be 5MB at maximum.')
      }
    })
  }

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    maxFileSize: 5,
    limitFilesConfig: { max: 5 },
    onFilesSuccessfulySelected: onFilesSelected,
    onFilesRejected: onFilePickError
  });

  const renderImageAttachIcon = () => {
    return (
      <Tooltip title="Select images">
        <ImageAttachButton
          onClick={openFileSelector}
        >
          <Add sx={{ width: 18, height: 18, color: colors.darkGrey }} />
        </ImageAttachButton>
      </Tooltip>
    )
  }

  return (
    <EditorWrapper>
      <Editor
        wrapperClassName="wrapper"
        editorClassName="editor"
        toolbarClassName="toolbar"

        toolbar={{
          options: ['image', 'blockType', 'inline', 'textAlign', 'list', 'link', 'emoji'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.bold}`,
            },
            italic: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.italic}`,
            },
            underline: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.underline}`,
              className: 'underline'
            },
          },
          textAlign: {
            options: ['left', 'center', 'right', 'justify'],
            left: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.alignLeft}`,
            },
            center: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.alignCenter}`,
            },
            right: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.alignRight}`,
            },
            justify: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.alignJustify}`,
            },
          },
          image: {
            component: renderImageAttachIcon,
          },
          list: {
            options: ['unordered', 'ordered'],
            unordered: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.listUnordered}`,
            },
            ordered: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.listOrdered}`,
            },
          },
          link: {
            link: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.link}`,
            },
            unlink: {
              icon: `data:image/svg+xml;base64,${Base64ForRTE.unlink}`,
            },
          },
          emoji: {
            icon: `data:image/svg+xml;base64,${Base64ForRTE.emoji}`,
          }
        }}
        placeholder="Enter your conversation"
        {...props}
      />
      <Box display="flex" px={2} justifyContent="flex-end">
        <Box display={'flex'} overflow="auto" flex={1}>
          {egos?.length > 0 && egos.map((ego: Ego) => (
            <AddAlbumPreviewItem position="relative" key={ego.egoID}>
              <img src={ego.photoAlbumURL} />
              <IconButton onClick={() => onRemoveEgo(ego)}>
                <Close />
              </IconButton>
            </AddAlbumPreviewItem>
          ))}
          {files?.length > 0 && files.map((file: FileContent, index: number) => (
            <AddAlbumPreviewItem position="relative" key={`${index}-${file.name}`}>
              <img src={file.content} />
              <IconButton onClick={() => onRemoveFile(index)}>
                <Close />
              </IconButton>
            </AddAlbumPreviewItem>
          ))}
        </Box>
        <Button variant='contained' onClick={onSubmit} size='small' sx={{ marginTop: -6.5 }}>
          {loading ? (isEditing ? 'Updating...' : 'Adding...') : (isEditing ? 'Update' : 'Add')}
        </Button>
        {isEditing && (
          <Button variant='outlined' onClick={onCancel} size='small' sx={{ marginLeft: 1, marginTop: -6.5 }}>
            Cancel
          </Button>
        )}
      </Box>
    </EditorWrapper>
  )
}