/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import { useUpdateNote } from './useUpdateNote'

interface UpdateTopicParams {
  topicID: string
  topicName: string
}

export const useUpdatePatternTopic = () => {
  const axios = useContext(AxiosContext)
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)
  const updateTopicNote = useUpdateNote()
  return useMutation({
    mutationKey: [ReactQueryKeys.UPDATE_TOPIC],
    mutationFn: async ({ topicID, topicName }: UpdateTopicParams) => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicUpdate',
        'topicData[action]': 'update',
        'topicData[topicApplication]': 2,
        'topicData[topicName]': topicName,
        'topicData[topicDescription]': '',
        'topicData[topicID]': topicID,
        format: 'json',
      })

      const topic = topicList[topicID]
      if (topic) {
        updateTopicList({
          [topicID]: {
            ...topic,
            topicName,
          },
        })

        const { firstNote } = topic
        if (firstNote) {
          const noteText = {
            text: `<p>${topicName}</p>`,
            attachments: [],
          }
          await updateTopicNote.mutateAsync({
            noteID: firstNote.noteID,
            topicID,
            noteText: JSON.stringify(noteText),
            egoList: firstNote.egoList,
          })
        }
      }

      return response.data
    },
  })
}
