import { Box, useMediaQuery, useTheme } from '@mui/material';
import MyBasicInfo from './components/MyBasicInfo';
import MyProfileDetails from './components/MyProfileDetails';

export default function MyProfile() {
  const theme = useTheme();
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));
  if (lessThanLarge) {
    return (
      <Box height="100%" overflow={'auto'}>
        <MyBasicInfo />
        <MyProfileDetails />
      </Box>
    )
  }
  return (
    <Box display="flex" height="100%">
      <Box width={275} height="100%" overflow={'auto'}>
        <MyBasicInfo />
      </Box>
      <Box flex={1}>
        <MyProfileDetails />
      </Box>
    </Box>
  )
}