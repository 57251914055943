/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'

export const useGetMyCircles = () => {
  const axios = useContext(AxiosContext)
  const { setMyGroups } = useAppStore()
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_MY_GRUOPS],
    mutationFn: async () => {
      const response = await axios.api.post('ajax/circles', {
        requestType: 'circleList',
        format: 'json',
      })
      setMyGroups(response.data.circles)
      return response.data
    },
  })
}
