/* eslint-disable */
import Box from '@mui/material/Box'
import '../App.css'
import { useAuth, useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { useEffect, useMemo, useState } from 'react'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'
import { styled } from '@mui/material/styles'
import { shallow } from 'zustand/shallow'
import { CreateNewPatternModal, Input, UserAvatar } from '@/components'
import useAppStore from '@/store/useAppStore'
import { MoreVert, Menu as MenuIcon } from '@mui/icons-material'

import {
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useGetBasicInfo, useGetMyCircles, useGetMyConnections } from '@/hooks/api/dashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import NotificationBadge from '@/assets/svgs/notification.svg'
import colors from '@/styles/colors'
import { RBox } from './__styled'
import { unix } from 'moment'

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  padding: '0px 16px 0px 24px',
  backgroundColor: 'white',
  borderBottom: '1px solid #E7EDF3',
  '& .avatar': {
    borderRadius: '50px',
  },
}))

export const NotificationButton = styled(IconButton)(() => ({
  backgroundColor: 'transparent',
  padding: 0,
  width: 20,
  marginRight: 20,
}))

export const ButtonCreateNew = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.light,
  color: 'white',
  fontSize: 12,
  fontWeight: 'bold',
  borderRadius: 50,
  height: '38px',
  alignItems: 'center',
  width: '124px',
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
  },
}))

const pages = ['Products', 'Pricing', 'Blog']

export const Divider = styled(Box)(() => ({
  height: '36px',
  width: '1px',
  backgroundColor: '#E7EDF3',
  marginLeft: '20px',
  marginRight: '20px',
}))

export default function ResponsiveNavBar() {
  const [ewUser] = useLocalStorage('ewUser', null)
  const location = useLocation()
  const query = useBrowserQuery()
  const searchKey = query.get('key') || ''
  const [searchText, setSearchText] = useState(searchKey)
  const { logout } = useAuth()
  const theme = useTheme()
  const navigate = useNavigate()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'))
  const [createNewModal, setCreateNewModal] = useState(false)

  // const { setSearchConnections, setUserSearchKeyword } = useAppStore()
  const { setShowSideMenuDrawer, resetTopicSearchResult, setSearchConnections } = useAppStore()

  const [sideTitle, profile, showSideMenuDrawer] = useAppStore(
    (state) => [state.app.sideTitle, state.user.profile, state.app.showSideMenuDrawer],
    shallow,
  )
  // const [profile] = useAppStore((state) => [state.user.profile], shallow)
  const getProfileData = useGetBasicInfo(ewUser?.userID)
  const getAllGroups = useGetMyCircles()
  const getAllConnections = useGetMyConnections()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useEffect(() => {
    getProfileData.mutate({ isUserProfile: false })
  }, [])

  const profileName = useMemo(() => {
    if (profile) {
      return `${profile?.firstName} ${profile?.lastName}`
    }
    return 'Anonymous'
  }, [profile])

  const onClickNotification = () => {
    setAnchorEl(null)
    navigate('/notification')
  }

  const onClickProfile = () => {
    setAnchorElUser(null)
    navigate(`/profile/me`)
  }

  const onClickLogout = () => {
    setAnchorElUser(null)
    logout()
  }

  const navTitle = useMemo(() => {
    let result = sideTitle
    switch (location.pathname) {
      case '/profile/user':
        result = 'Profile'
        break
      case '/notification':
        result = 'Notifications'
        break
      case '/profile/me':
        result = 'My Profile'
        break

      default:
        result = sideTitle
    }

    return result
  }, [sideTitle, location.pathname])

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const onSearch = () => {
    navigate(`/search?key=${searchText}`)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      resetTopicSearchResult()
      setSearchConnections({})
      navigate(`/search?key=${searchText}&q=${Date.now().toString()}`)
    }
  }

  const toggleModal = () => {
    setCreateNewModal((prev) => !prev)
  }

  const renderAvatarButton = () => {
    return (
      <>
        <UserAvatar
          size={34}
          borderRadius={50}
          url={profile?.profilePicURL}
          name={profileName}
          onClick={handleOpenUserMenu}
        />

        <Menu
          id='menu-user'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            key={'profile'}
            onClick={() => {
              handleCloseUserMenu()
              onClickProfile()
            }}
          >
            <Typography variant={'p'} textAlign='center'>
              {'My Profile'}
            </Typography>
          </MenuItem>

          <MenuItem
            key={'logout'}
            onClick={() => {
              handleCloseUserMenu()
              onClickLogout()
            }}
          >
            <Typography variant={'p'} textAlign='center'>
              {'Logout'}
            </Typography>
          </MenuItem>
        </Menu>
      </>
    )
  }

  const renderTitle = () => {
    // navTitle
    const isSearchPage = location.pathname == '/search'
    return (
      <>
        <Typography fontSize={19} fontWeight={isSearchPage ? 500 : 700} color={colors.themeBlack}>
          {isSearchPage ? 'Search Result for ' : navTitle}
        </Typography>
        {isSearchPage && searchKey.length > 0 && (
          <Typography variant={'large'} ml={'8px'}>{`“${searchKey}”`}</Typography>
        )}
      </>
    )
  }

  return (
    <Header sx={lessThanSmall ? { pl: '0px' } : {}}>
      <Toolbar disableGutters sx={{ flex: 1 }}>
        <Box
          sx={{
            flex: 1,
            display: lessThanSmall ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            onClick={() => setShowSideMenuDrawer(!showSideMenuDrawer)}
            sx={{ color: colors.themeBlack }}
          >
            <MenuIcon />
          </IconButton>

          {renderTitle()}

          <Box flex={1} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 1 }}>
            <Input
              value={searchText}
              placeholder='Search here'
              leftIcon={<SVG src={SVGS.SearchIcon} />}
              onChange={onChangeSearchText}
              name='search'
              sx={{ display: 'flex', maxWidth: '150px', borderRadius: '30px' }}
              size='small'
              onToggleIcon={onSearch}
              onKeyDown={handleKeyDown}
            />
          </Box>
          {renderAvatarButton()}
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleOpenNavMenu}
            color='inherit'
          >
            <MoreVert sx={{ color: colors.themeBlack }} />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem
              key={'notification'}
              onClick={() => {
                handleCloseNavMenu()
                onClickNotification()
              }}
            >
              <Typography variant={'p'} textAlign='center'>
                {'Notifications'}
              </Typography>
            </MenuItem>

            <MenuItem
              key={'create-new'}
              onClick={() => {
                handleCloseNavMenu()
                toggleModal()
              }}
            >
              <Typography variant={'p'} textAlign='center'>
                {'Create New'}
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Box
          sx={{
            display: lessThanSmall ? 'none' : 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {renderTitle()}

          <RBox className='right-items' sx={{ flex: 1 }}>
            <Box
              className='hide-on-mobile'
              flex={1}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mr: '30px',
              }}
            >
              <Input
                value={searchText}
                placeholder='Search here what you are looking for'
                leftIcon={<SVG src={SVGS.SearchIcon} />}
                onChange={onChangeSearchText}
                name='search'
                sx={{ display: 'flex', maxWidth: '400px', minWidth: '150px', borderRadius: '30px' }}
                size='small'
                onToggleIcon={onSearch}
                onKeyDown={handleKeyDown}
              />
            </Box>
            <ButtonCreateNew onClick={toggleModal}>+ Create New</ButtonCreateNew>
            <Divider />
            <NotificationButton onClick={onClickNotification}>
              <SVG src={NotificationBadge} width={34} height={34} style={{ margin: 'auto' }} />
            </NotificationButton>
            {renderAvatarButton()}
          </RBox>
        </Box>
      </Toolbar>
      <CreateNewPatternModal
        open={createNewModal}
        onClose={() => {
          setCreateNewModal(false)
        }}
      />
    </Header>
  )
}
