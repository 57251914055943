/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useConnectionBlock = () => {
  const axios = useContext(AxiosContext)
  const [ewUser] = useLocalStorage('ewUser', null)
  const { setMyConnections } = useAppStore()
  const [myConnections] = useAppStore((state) => [state.user.myConnections], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.BLOCK_CONNECTION],
    mutationFn: async (userID: string) => {
      // userID: user to be blocked
      const response = await axios.api.post('ajax/users', {
        requestType: 'addUserBlock',
        onUserID: userID,
        fromUserID: ewUser?.userID,
        reason: 'I want to report this user',
        reportUserID: ewUser?.userID,
        format: 'json',
      })
      const index = myConnections.findIndex((i) => i.userID === userID)
      if (index >= 0) {
        myConnections.splice(index, 1)
        setMyConnections([...myConnections])
      }
      return response.data
    },
  })
}
