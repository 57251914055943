import { Ego } from "@/types"
import { Box, Typography } from "@mui/material";

interface Props {
  images?: Ego[]
  maxLen?: number
}

export function ImageList({ images = [], maxLen = 3 }: Props) {
  // Ensure images is an array before proceeding
  if (!Array.isArray(images) || images.length === 0) return null;

  return (
    <>
      {images.length > 0 && (
        <Box display="flex" width="100%">
          {images.slice(0, maxLen).map((ego, index) => (
            <Box width={`${100 / Math.min(images.length, maxLen)}%`} key={ego.egoID}>
              <Box
                paddingBottom={images.length === 1 ? '50%' : '100%'}
                borderRadius={1}
                overflow="hidden"
                position="relative"
                sx={{
                  backgroundImage: `url(${ego.photoAlbumURL})`,
                  backgroundSize: 'cover',
                }}
              >
                {index === maxLen - 1 && images.length > maxLen && (
                  <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    bgcolor={`#00000088`}
                    display="flex"
                    justifyContent={'center'}
                    alignItems="center"
                  >
                    <Typography variant="labelLarge" color="white">+{images.length - maxLen}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
