/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, Thumbs } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useGetTopicNoteLikes = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_NOTE_LIKES],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (noteID: string): Promise<Thumbs[]> => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteLikes',
        format: 'json',
        noteID,
      })
      return response.data?.thumbs?.filter((i: Thumbs) => i.thumb) || []
    },
  })
}
