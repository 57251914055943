import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { ReactQueryKeys } from '@/types'

export const useLoginCognito = (username: string) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.LOGIN_COGNITO, username],
    mutationFn: async () => {
      const response = await axios.api.post('ajax/logincognito', {
        token: username,
        format: 'json',
      })
      return response.data
    },
  })
}
