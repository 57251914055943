import { RoundedOutlineButton } from '@/components/StyledComponents'
import { Topic } from '@/types'
import {
  Box,
  Button,
  IconButton,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'
import { theme } from '@/styles/theme'
import { ShareLink } from '@/components/ShareLink'
import { useState } from 'react'
import colors from '@/styles/colors'

interface PatternNotesInteractionViewProps {
  topic: Topic
  isLiked: boolean
  onClickLike: () => void
  onClickComment: () => void
  onClickPreview: () => void
  onClickLikesCount: () => void
}

function PatternNotesInteractionView(props: PatternNotesInteractionViewProps) {
  const [showShareLink, setShowShareLink] = useState(false)
  const styles = makeStyles()

  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))

  const totalViews = Number(props.topic.visitorVisits)
  const totalLikes = Number(props.topic.totalLikes)
  const totalComments = Number(props.topic.totalComments)
  const totalShares = 0

  const onClickShare = () => {
    setShowShareLink(true)
  }
  const onCloseShare = () => {
    setShowShareLink(false)
  }

  return (
    <Box sx={styles.container}>
      {totalViews + totalLikes + totalComments + totalShares > 0 && (
        <Box sx={styles.interactionBar}>
          <Box sx={styles.viewsDiv}>
            {totalViews > 0 && (
              <>
                <SVG src={SVGS.EyeIconDark} width={14} height={14} />
                <Typography>{`${totalViews} Views`}</Typography>
              </>
            )}
          </Box>

          <Box sx={{ ...styles.row, gap: 1, alignItems: 'center' }}>
            {totalLikes > 0 && (
              <>
                <Button variant='text' onClick={props.onClickLikesCount}>
                  <Typography>{`${props.topic.totalLikes} Likes`}</Typography>
                </Button>
                {(totalComments > 0 || totalShares > 0) && <Typography>•</Typography>}
              </>
            )}

            {totalComments > 0 && (
              <>
                <Button variant='text' onClick={props.onClickComment}>
                  <Typography>{`${props.topic.totalComments}`} Comments</Typography>
                </Button>

                {totalShares > 0 && <Typography>•</Typography>}
              </>
            )}

            {totalShares > 0 && (
              <>
                <Button variant='text'>
                  <Typography>{`${totalShares} Shares`}</Typography>
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Box sx={styles.iconsContainer}>
        <RoundedOutlineButton
          onClick={props.onClickLike}
          aria-selected={props.isLiked}
          sx={styles.outlineButton}
          startIcon={<SVG src={props.isLiked ? SVGS.LikeFilledIcon : SVGS.LikeOutlineIcon} />}
        >
          Like
        </RoundedOutlineButton>
        <RoundedOutlineButton
          onClick={props.onClickComment}
          sx={styles.outlineButton}
          startIcon={<SVG src={SVGS.CommentIcon} />}
        >
          Comment
        </RoundedOutlineButton>
        <RoundedOutlineButton
          onClick={onClickShare}
          sx={styles.outlineButton}
          startIcon={<SVG src={SVGS.ShareIcon} />}
        >
          Share
        </RoundedOutlineButton>
        <IconButton
          onClick={props.onClickPreview}
          sx={{
            backgroundColor: theme.palette.brand.light,
            width: '40px',
            height: '40px',
            borderRadius: '40px',
            '&:hover': {
              backgroundColor: theme.palette.brand.light,
            },
          }}
        >
          <SVG src={SVGS.SendArrowIcon} />
        </IconButton>
      </Box>
      {showShareLink && (
        <Box position={'absolute'} sx={{ left: 0, right: 0, bottom: '16px', mx: 1 }}>
          <ShareLink onClose={onCloseShare} />
        </Box>
      )}
    </Box>
  )
}

function makeStyles(): Record<string, SxProps> {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      py: '16px',
      px: '20px',
    },

    interactionBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      py: '12px',
      borderTop: '1px solid #CCD4DC',
    },

    iconsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      pt: '16px',
      pb: '4px',
      borderTop: '1px solid #CCD4DC',
    },

    outlineButton: { px: '20px', py: '6px' },

    row: { display: 'flex', flexDirection: 'row' },

    viewsDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
    },
  }
}

export default PatternNotesInteractionView
