import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import SVG from 'react-inlinesvg'
import { AuthFormLogo, AuthFormView } from './__styled'
import { getCopyRightText } from '@/utils/helper'
import ForgotForm1 from './ForgotForm1'
import { useForgotPassword, useConfirmNewPassword } from '@/hooks/api/auth'
import ForgotForm2 from './ForgotForm2'
import ForgotForm3 from './ForgotForm3'
import { SVGS } from '@/assets/svgs'

export default function ForgotPassword() {
  const [step, setStep] = useState(1)
  const [user, setUser] = useState('')

  const resetPassword = useForgotPassword()
  const confirmPassword = useConfirmNewPassword()

  useEffect(() => {
    if (resetPassword.isSuccess) setStep(2)
  }, [resetPassword.isSuccess])

  useEffect(() => {
    if (confirmPassword.isSuccess) setStep(3)
  }, [confirmPassword.isSuccess])

  const onSubmitForm1 = (user: string) => {
    resetPassword.mutate(user)
    setUser(user)
  }

  const onSubmitForm2 = (code: string, newPassword: string) => {
    confirmPassword.mutate({ user, code, newPassword })
  }

  return (
    <AuthFormView item xs={12} md={6} position='relative'>
      {step === 1 && (
        <ForgotForm1
          onSubmit={onSubmitForm1}
          loading={resetPassword.isLoading}
          error={resetPassword.error?.toString() || ''}
        />
      )}
      {step === 2 && (
        <ForgotForm2
          onSubmit={onSubmitForm2}
          user={user}
          loading={confirmPassword.isLoading}
          error={confirmPassword.error?.toString() || ''}
        />
      )}
      {step === 3 && <ForgotForm3 />}
      <Typography
        variant='p'
        textAlign={'center'}
        position='absolute'
        mb={4.5}
        bottom={0}
        left={0}
        right={0}
      >
        {getCopyRightText()}
      </Typography>
    </AuthFormView>
  )
}
