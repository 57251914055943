import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { Box } from '@mui/material'
import { useEffect, useState, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import { useLocation, useNavigate } from 'react-router-dom'
import SearchTopicResults from './results/TopicResults'
import { SearchConnection, Topic } from '@/types'
import { ScrollableCapsulesTabBar } from '@/components/ScrollableCapsulesTabBar'
import { PrivacyType, TopicType } from '@/utils/constants'
import SearchConnectionResults from './results/ConnectionResults'

const tabs = ['All', 'Patterns', 'Quilts', 'Galleries', 'Albums', 'People']
export default function SearchResult() {
  const navigate = useNavigate()
  const query = useBrowserQuery()
  const searchKey = query.get('key') || ''
  const forceUpdateKey = query.get('q') || '123'

  const { setSelectedTabIndex } = useAppStore()
  const [selectedTabIndex] = useAppStore((state) => [state.search.selectedTabIndex], shallow)

  const [index, setIndex] = useState(selectedTabIndex || 0)

  const onClickTopic = (topic: Topic) => {
    const { topicID, topicType } = topic

    let previewQuery = new URLSearchParams()
    previewQuery.set('topicID', topicID)
    previewQuery.set('topicType', topicType)
    navigate(`/preview?${previewQuery.toString()}`)
  }

  const onClickUser = (user: SearchConnection) => {
    let previewQuery = new URLSearchParams()
    previewQuery.set('id', user.userID)
    navigate(`/profile/user?${previewQuery.toString()}`)
  }

  const onSelectTabAtIndex = (index: number) => {
    setIndex(index)
    setSelectedTabIndex(index)
  }

  useEffect(() => {
    console.log('SelectedTabIndex', selectedTabIndex)
    if (selectedTabIndex) {
      setIndex(selectedTabIndex)
    }
  }, [selectedTabIndex])

  const selectedTopicType = useMemo(() => {
    let type: TopicType = TopicType.ALL
    switch (index) {
      case 0:
        type = TopicType.ALL
        break
      case 1:
        type = TopicType.PATTERN
        break
      case 2:
        type = TopicType.QUILT
        break
      case 3:
        type = TopicType.GALLERY
        break
      case 4:
        type = TopicType.ALBUM
        break
      default:
        type = TopicType.ALL
    }
    return type
  }, [index])

  return (
    <Box flexDirection={'column'} display={'flex'} sx={{ width: '100%' }}>
      <Box sx={{ px: '16px', my: '16px' }}>
        <ScrollableCapsulesTabBar
          titles={tabs}
          selectedIndex={index}
          onSelectAtIndex={onSelectTabAtIndex}
          showScrollButtons={false}
        />
      </Box>
      <Box sx={{ width: '100%', height: '1px', backgroundColor: '#E7EDF3' }} />

      {selectedTabIndex != tabs.length - 1 ? (
        <SearchTopicResults
          key={forceUpdateKey}
          searchKey={searchKey}
          onClickTopic={onClickTopic}
          filter={{ topicType: selectedTopicType, privacyType: PrivacyType.ALL }}
        />
      ) : (
        <SearchConnectionResults
          key={forceUpdateKey}
          searchKey={searchKey}
          onClickUser={onClickUser}
        />
      )}
    </Box>
  )
}
