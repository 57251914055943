import colors from '@/styles/colors'
import { Box, Grid, styled } from '@mui/material'

export const TitlePrivacyTypeContainer = styled(Grid)({})

export const GroupUsersContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'view',
})<{ view: boolean }>(({ theme, view }) => ({
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: view ? 0 : '-100vw',
    width: '40vw',
    transitionDuration: '.2s',
    '& .content': {
      boxShadow: `-3px 0 6px ${theme.palette.grey[500]}`,
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '50vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '70vw',
  },
}))

export const GroupUsersContent = styled(Box)({
  overflow: 'hidden',
  '& .empty-icon': {
    '& path': {
      strokeWidth: 0.3,
    },
  },
  '& .connections': {
    display: 'flex',
    borderBottom: `1px solid ${colors.lightGrey}`,
    '& button': {
      flex: 1,
      color: colors.darkGrey,
      borderRadius: 0,
      fontSize: 13,
      '&.active': {
        borderBottom: `1px solid ${colors.blue}`,
        color: colors.blue,
      },
    },
  },
})

export const TopicDetailsContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'view',
})<{ view: boolean }>(({ theme, view }) => ({
  flex: 1,
  backgroundColor: colors.lightGrey,
  position: 'relative',
  paddingBottom: 70,
  '& .close-connection': {
    display: 'none',
  },
  '& .close-privacy': {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    '& .close-connection': {
      display: 'block',
    },
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: view ? 0 : '-100vw',
    width: '100%',
    transitionDuration: '.2s',
    '& .close-privacy': {
      display: 'block',
    },
  },
}))

export const ConnectionsContainer = styled(Box)({
  height: '-webkit-fill-available',
  position: 'absolute',
  display: 'grid',
  gridTemplateRows: '50px 72px auto',
  width: '100%',
})

export const SubmitForm = styled(Grid)({
  backgroundColor: 'yellow',
  height: 70,
})
