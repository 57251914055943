/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { ReactQueryKeys } from '@/types'

export const useConfirmSignup = (user: string, code: string) => {
  return useMutation({
    mutationKey: [ReactQueryKeys.CONFIRM_SIGNUP, user, code],
    mutationFn: async (): Promise<boolean> => {
      await Auth.confirmSignUp(user, code)
      return true
    },
  })
}
