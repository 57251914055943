import { CheckBox } from "@/components";
import { PrivacyTypes, TopicTypes } from "@/utils/constants";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import SVG from 'react-inlinesvg';

interface FilterProps {
  interaction: number,
  topic: number,
  onApply: (privacyType: number, topicType: number) => void;
  onClose: () => void;
}

export default function HomeFilter({ interaction, topic, onApply, onClose }: FilterProps) {
  const [privacyType, setPrivacyType] = useState(interaction);
  const [topicType, setTopicType] = useState(topic);
  const { palette } = useTheme();

  const handlePrivacyTypeChange = (type: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setPrivacyType(type)
    }
  }

  const handleTopicTypeChange = (type: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setTopicType(type)
    }
  }

  const onClickApply = () => {
    onApply(privacyType, topicType);
  }

  return (
    <Box position="absolute" top={0} left={0} bottom={0} right={0} px={3} py={2} bgcolor="white" zIndex={11} overflow="auto">
      <Box display={'flex'} justifyContent="space-between" alignItems={'center'} mb={1}>
        <Typography variant='h2'>Filter</Typography>
        <IconButton size={'medium'} onClick={onClose} sx={{ marginRight: '-10px' }}>
          <Close sx={{ color: palette.brand.main }} />
        </IconButton>
      </Box>
      <Box p={2} mb={3} border={`1px solid ${palette.grey[800]}`} borderRadius={1}>
        <Typography variant='h3' mb={1} fontSize={12}>PRIVACY TYPE</Typography>
        {PrivacyTypes.map((item) => {
          if (item.label === 'Removed') return null;
          const color = privacyType === item.type ? palette.brand.light : palette.customText.main;
          return (
            <Box display={'flex'} alignItems={'center'} key={item.label}>
              <SVG src={item.icon} color={color} width={20} height={20} opacity={0.6} />
              <Typography variant='p' flex={1} pl={1} color={color}>{item.label}</Typography>
              <CheckBox
                checked={privacyType === item.type}
                onChange={handlePrivacyTypeChange(item.type)}
              />
            </Box>
          )
        })}
      </Box>
      <Box p={2} mb={3} border={`1px solid ${palette.grey[800]}`} borderRadius={1}>
        <Typography variant='h3' mb={1} fontSize={12}>TOPIC TYPE</Typography>
        {Object.values(TopicTypes).map((item) => {
          const color = topicType === item.type ? palette.brand.light : palette.customText.main;
          return (
            <Box display={'flex'} justifyContent="space-between" alignItems={'center'} key={item.label}>
              <SVG src={item.icon} color={color} width={20} height={20} opacity={0.6} />
              <Typography variant='p' flex={1} pl={1} color={color}>{item.label}</Typography>
              <CheckBox
                checked={topicType === item.type}
                onChange={handleTopicTypeChange(item.type)}
              />
            </Box>
          )
        })}
      </Box>
      <Button fullWidth variant='contained' onClick={onClickApply} sx={{ marginBottom: 3 }}>Apply Filter</Button>
    </Box>
  )
}