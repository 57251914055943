import {
  ActionMenu,
  PreviewNote,
  RInputModal,
  RModal,
  TagPrivacy,
  TagTopic,
  TopicStates,
  UserAvatar,
} from '@/components'
import { Box, Button, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import colors from '@/styles/colors'
import { Topic } from '@/types'
import { PrivacyType, PrivacyTypes, ResourceType, TopicTypes } from '@/utils/constants'
import {
  ArrowBackIos,
  ChevronLeft,
  CommentOutlined,
  DeleteOutline,
  EditOutlined,
  MoreVert,
} from '@mui/icons-material'
import { useEffect, useMemo, useState } from 'react'
import {
  useDeleteTopic,
  useGetPermissions,
  useUpdatePatternTopic,
  useUpdateVisibility,
} from '@/hooks/api/dashboard'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import ReactTimeago from 'react-timeago'
import { convertToGMT, getPlainNoteTextFromNote } from '@/utils/helper'
import { RBox } from '@/pages/__styled'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'

interface HeaderProps {
  topic: Topic
  onClickBack: () => void
  onClickComment: () => void
  onClickPrivacy: () => void
  onDeletedTopic?: () => void
}

export default function TopicDetailsHeader({
  topic: incomingTopic,
  onClickBack,
  onClickComment,
  onClickPrivacy,
  onDeletedTopic,
}: HeaderProps) {
  const [modalOpened, setModalOpened] = useState(false)
  const updateTopic = useUpdatePatternTopic()
  const updateVisibility = useUpdateVisibility()
  const getPermissions = useGetPermissions()
  const theme = useTheme()
  const [renameModalOpened, setRenameModalOpened] = useState(false)
  const lessThanLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const topicData = TopicTypes[Number(incomingTopic.topicType)]
  const privacyData = PrivacyTypes[Number(incomingTopic.visibility)]

  const [ewUser] = useLocalStorage('ewUser', null)
  const deleteTopic = useDeleteTopic(incomingTopic.topicID)
  const navigate = useNavigate()
  const { removeTopic } = useAppStore()
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''
  const noteID = query.get('noteID') || ''

  const [topic, setTopic] = useState(incomingTopic)

  useEffect(() => {
    setTopic(incomingTopic)
  }, [incomingTopic])

  useEffect(() => {
    if (deleteTopic.isSuccess) {
      removeTopic(topicID)
      onDeletedTopic && onDeletedTopic()
    }
  }, [deleteTopic.isSuccess])

  if (!topicData || !privacyData) return null

  const onCloseModal = () => {
    setModalOpened(false)
  }

  const onDeleteTopic = () => {
    toast.promise(deleteTopic.mutateAsync(), {
      loading: 'Deleting...',
      success: <Typography variant='p'>Successfully deleted</Typography>,
      error: <Typography variant='p'>Failed to delete</Typography>,
    })
  }

  const topicActions = useMemo(() => {
    let items = [
      {
        icon: <EditOutlined />,
        name: 'Rename',
        onClick: () => setRenameModalOpened(true),
      },
    ]

    if (topic.visibility == PrivacyType.PRIVATE.toString()) {
      items.push({
        icon: <SVG src={SVGS.FilterPrivacyPublic} />,
        name: 'Save as Public',
        onClick: () => changeVisibility(PrivacyType.PUBLIC),
      })
    } else {
      items.push({
        icon: <SVG src={SVGS.FilterPrivacyPrivate} />,
        name: 'Make Private',
        onClick: () => changeVisibility(PrivacyType.PRIVATE),
      })
    }
    items.push({
      icon: <DeleteOutline />,
      name: 'Delete',
      onClick: () => setModalOpened(true),
    })

    return items
  }, [topic])

  // const changeVisibility = (newPrivacy: PrivacyType) => {}

  const changeVisibility = (newPrivacy: PrivacyType) => {
    if (topic != null) {
      // Get Permission ID

      const updatePerm = (permID: string) => {
        updateVisibility
          .mutateAsync({
            resourceID: topic.topicID,
            cliqueTypes: topic.cliqueTypes,
            cliques: [],
            connections: [],
            accessTypeID: newPrivacy.toString(),
            permissionID: permID,
            resourceType: ResourceType.TOPIC,
          })
          .then((value) => {
            toast.remove('perm-load')
            toast.success('Updated')

            setTopic({
              ...topic,
              visibility: newPrivacy.toString(),
            })
          })
          .catch((err) => {
            toast.remove('perm-load')
            toast.error(err.toString())
          })
      }

      toast.loading('Updating...', { id: 'perm-load' })
      getPermissions
        .mutateAsync(topic.topicID)
        .then((permData) => {
          const { permissionID } = permData
          updatePerm(permissionID)
        })
        .catch((err) => {
          toast.remove('perm-load')
        })
    }
  }

  const onConfirmRenameTopic = (text: string) => {
    if (topic) {
      toast.loading('Updating...', { id: 'update-topic' })
      updateTopic
        .mutateAsync({ topicID: topic.topicID, topicName: text })
        .then((value) => {
          toast.remove('update-topic')
          toast.success('Updated Successfully.')

          const noteText = {
            text: `<p>${text}</p>`,
            attachments: [],
          }

          let updatedTopic = {
            ...topic,
            topicName: text,
            firstNote: {
              ...topic.firstNote,
              noteText: JSON.stringify(noteText),
            },
          }
          setTopic((prev) => ({ ...updatedTopic }))
        })
        .catch((e) => {
          toast.remove('update-topic')
          toast.error(e.toString())
        })
    }
  }

  return (
    <Box padding='20px'>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
          <IconButton
            onClick={onClickBack}
            sx={{
              backgroundColor: 'transparent',
              px: 0,
            }}
          >
            <ArrowBackIos sx={{ color: colors.themeBlack }} />
          </IconButton>
          <Box display={'flex'} alignItems={'center'}>
            <UserAvatar
              borderRadius={35}
              name={topic.userName}
              url={topic.profilePicThumbURL}
              size={35}
            />
            <Box ml={'8px'} sx={{ alignItems: 'center' }}>
              <Link href={`/profile?id=${topic.userID}`}>
                <Typography variant='labelLarge' color={colors.themePurpleTextColor}>
                  {topic.userName}
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
        <RBox gap={2} justifyContent={'center'}>
          <TagTopic type={topicData.key} />
          <TagTopic
            type={topic.visibility == PrivacyType.PRIVATE.toString() ? 'Private' : 'Public'}
          />

          {topic.dateAdded && (
            <Typography
              variant='p'
              fontSize={10}
              lineHeight={'10px'}
              sx={{ color: colors.themeBlack }}
            >
              {'Created '}
              <ReactTimeago date={convertToGMT(topic.dateAdded)} />
            </Typography>
          )}

          {topic.userID === ewUser?.userID && (
            <ActionMenu id='topic-item-menu' parent={<MoreVert />} actions={topicActions} />
          )}
        </RBox>
      </Box>
      <RBox gap={2} mt={4}>
        <Typography variant='h3'>{`${getPlainNoteTextFromNote(topic.firstNote)}`}</Typography>
        {!lessThanSmall && (
          <Box flex={1} display='flex' justifyContent={'flex-end'} alignItems={'center'} mr={-2}>
            <TopicStates topic={topic} />
          </Box>
        )}
      </RBox>

      <RModal
        open={modalOpened}
        onClose={onCloseModal}
        title='Delete Topic'
        description='Are you sure you want to delete this topic?'
        submitText='Delete'
        onSubmit={onDeleteTopic}
      />
      <RInputModal
        open={renameModalOpened}
        maxLength={30}
        onClose={() => setRenameModalOpened(false)}
        title='Change Topic Name'
        text={topic.topicName}
        placeholder={'Enter Topic Description'}
        description=''
        submitText='Save'
        onSubmit={onConfirmRenameTopic}
      />
    </Box>
  )
}
