import { Box, Grid, List, ListItem, Paper, Typography } from '@mui/material'
import logoImage from '@/assets/images/logo-image.svg'
import { CBox } from '../__styled'
import SVG from 'react-inlinesvg'

import colors from '@/styles/colors'
import InfoSlideView from './InfoSlideView'
import InfoSliderView from './InfoSliderView'

const AuthDialogLeftView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        overflow: 'auto',
        flexDirection: 'column',
        backgroundColor: '#F2F4F8',
        borderRadius: '20px',
        m: '30px',
        p: '40px',
      }}
    >
      <Box>
        <SVG src={logoImage} width={'34px'} height={'34px'} />
      </Box>
      <CBox mt={'72px'} gap={1}>
        <Typography sx={{ fontSize: '28px', fontWeight: 600, lineHeight: '38px' }}>
          {'Give Life to Your Ideas and Creative Vision with Our Design Platform'}
        </Typography>
        <Typography variant='description' lineHeight={'26px'}>
          {
            'Quality patterns design offers a dynamic platform where your imagination becomes reality.'
          }
        </Typography>
      </CBox>

      <CBox sx={{ flex: 1, justifyContent: 'flex-end', mt: '40px' }}>
        <InfoSliderView />
      </CBox>
    </Box>
  )
}

export default AuthDialogLeftView
