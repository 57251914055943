/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, DMConnection } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useGetMyDirectMessages = () => {
  const axios = useContext(AxiosContext)
  const { setMyDMConnections } = useAppStore()
  const [myDMConnections] = useAppStore((state) => [state.user.myDMConnections], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_MY_DIRECT_MESSAGES],
    mutationFn: async (page: number) => {
      const response = await axios.api.post('ajax/circles', {
        requestType: 'connListDm',
        format: 'json',
        pageNo: page,
      })
      response.data?.connections?.forEach((conn: DMConnection) => {
        const index = myDMConnections.findIndex((i) => i.userID === conn.userID)
        if (index < 0) myDMConnections.push(conn)
        else {
          myDMConnections.splice(index, 1, {
            ...myDMConnections[index],
            ...conn,
          })
        }
      })
      setMyDMConnections([...myDMConnections])
      return response.data?.connections || []
    },
  })
}
