import { useAddNoteTopic, useGetTopics } from '@/hooks/api/dashboard'
import useAppStore from '@/store/useAppStore'
import { EgoObject, Topic } from '@/types'
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { shallow } from 'zustand/shallow'
import { LoadingButton } from '@mui/lab'
import { CheckBox, NoResults, TagPrivacy, TagTopic } from '@/components'
import { useNavigate } from 'react-router-dom'
import colors from '@/styles/colors'
import { ChevronLeft, Close, DeleteOutline } from '@mui/icons-material'
import { SelectTopicContainer } from './__styled'
import { PrivacyTypes, TopicType, TopicTypes } from '@/utils/constants'
import { toast } from 'react-hot-toast'
import { useBrowserQuery } from '@/hooks/common'

interface Props {
  selectedEgoObjects: EgoObject[]
  onClickBack: () => void
  showClose?: boolean
}

export default function SelectTopics({
  selectedEgoObjects,
  onClickBack,
  showClose = false,
}: Props) {
  const [page, setPage] = useState(1)
  const [endReached, setEndReached] = useState(false)
  const [selectedTopicIDs, setSelectedTopicIDs] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)
  const getTopics = useGetTopics()
  const addNoteToTopic = useAddNoteTopic()
  const navigate = useNavigate()
  const theme = useTheme()
  const query = useBrowserQuery()
  const containerID = query.get('container')
  const lessThanLarge = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    setEndReached(false)
    getTopics.mutate({
      pageNo: 1,
      interaction: 3,
      topicType: TopicType.ALBUM,
    })
  }, [])

  useEffect(() => {
    if (getTopics.isSuccess && getTopics.data && !getTopics.isError) {
      if (getTopics.data.length < 20) {
        setEndReached(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTopics.isSuccess, getTopics.data, getTopics.isError])

  useEffect(() => {
    setSelectedTopicIDs([])
  }, [containerID])

  const topics = useMemo(() => {
    const list = Object.values(topicList)
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1
      if (a.dateUpdated < b.dateUpdated) return 1
      return 0
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicList])

  if (selectedEgoObjects.length === 0) return <SelectTopicContainer />

  const onClickNew = () => {
    navigate(`/topic/create`)
  }

  if (getTopics.isSuccess && getTopics.data.length == 0 && page === 1) {
    return (
      <Box p={5} display='flex' justifyContent={'center'}>
        <NoResults interaction={0} topicType={2} onClick={onClickNew} />
      </Box>
    )
  }

  const onClickShowMore = () => {
    if (endReached || getTopics.isLoading || !topics.length) {
      return
    }
    getTopics.mutate({
      pageNo: page + 1,
      interaction: 3,
      topicType: TopicType.ALBUM,
    })
    setPage(page + 1)
  }

  const onToggleTopic = (topicID: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectedTopicIDs.push(topicID)
    } else {
      const index = selectedTopicIDs.findIndex((i) => i === topicID)
      selectedTopicIDs.splice(index, 1)
    }
    setSelectedTopicIDs([...selectedTopicIDs])
  }

  const onClickDelete = () => {}

  const onClickAdd = async () => {
    setIsSubmitting(true)

    const egoItems = selectedEgoObjects.map((e, i) => ({
      sequenceID: `${i}`,
      egoID: e.egoID,
      photoFullURL: e.egoFileURL,
      photoAlbumURL: e.egoAlbumURL,
    }))

    for (const topicID of selectedTopicIDs) {
      addNoteToTopic.mutate({
        topicID: topicID,
        egoItems: egoItems,
        noteName: 'untitled',
        noteText: 'placeholder',
      })
    }
    setIsSubmitting(false)
    toast.success('Image has been added successfully')
    setTimeout(() => {
      setSelectedTopicIDs([])
    }, 100)
  }

  return (
    <SelectTopicContainer overflow='auto'>
      <Box
        className='header'
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {/* {lessThanLarge && (
          <IconButton onClick={onClickBack} sx={{ marginLeft: -4, color: colors.text }}>
            <ChevronLeft />
          </IconButton>
        )} */}
        <Typography variant='label'>Select Topics to Add Images</Typography>
        {showClose && (
          <Button onClick={onClickBack} sx={{ minWidth: 50 }}>
            <Close />
          </Button>
        )}
      </Box>
      {getTopics.isLoading && page === 1 && (
        <Box mt={1} display='flex' justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      {topics.map((item: Topic) => {
        const topicData = TopicTypes[Number(item.topicType)]
        const privacyData = PrivacyTypes[Number(item.visibility)]
        if (!topicData || !privacyData) return null
        return (
          <Box key={item.topicID} p={2} borderBottom={`1px solid ${colors.veryLightGrey}`}>
            <Box display='flex' alignItems='center' height={40}>
              <Tooltip arrow title={item.topicName}>
                <Typography
                  variant='h2'
                  mr={1}
                  flex={1}
                  textOverflow='ellipsis'
                  whiteSpace={'nowrap'}
                  overflow='hidden'
                >
                  {item.topicName}
                </Typography>
              </Tooltip>
              <CheckBox
                checked={selectedTopicIDs.includes(item.topicID)}
                onChange={onToggleTopic(item.topicID)}
              />
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <TagTopic type={topicData.key} />
              <TagPrivacy topic={item} />
            </Box>
          </Box>
        )
      })}
      {(page > 1 || !getTopics.isLoading) && (
        <Box m={3} display='flex' justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={onClickShowMore}
            loading={getTopics.isLoading && page > 1}
            disabled={endReached}
          >
            {endReached ? 'No more results' : 'Show More'}
          </LoadingButton>
        </Box>
      )}
      {topics.length > 0 && (
        <Box className='footer' mt={1} display='flex' justifyContent={'space-between'}>
          <IconButton onClick={onClickDelete} disabled={!selectedEgoObjects.length}>
            <DeleteOutline
              sx={{ color: selectedEgoObjects.length ? colors.error : colors.lightGrey }}
            />
          </IconButton>
          <LoadingButton
            variant='contained'
            onClick={onClickAdd}
            disabled={!selectedEgoObjects.length || !selectedTopicIDs.length}
            loading={isSubmitting}
          >
            Add to {selectedTopicIDs.length} Topic{selectedTopicIDs.length > 1 ? 's' : ''}
          </LoadingButton>
        </Box>
      )}
    </SelectTopicContainer>
  )
}
