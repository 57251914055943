import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

interface DeleteTopicParams {
  topicID: string
}

export const useDeletePatternTopic = () => {
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.DELETE_TOPIC],
    mutationFn: async ({ topicID }: DeleteTopicParams) => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicUpdate',
        'topicData[action]': 'delete',
        'topicData[topicID]': topicID,
        format: 'json',
      })
      const updatedList = { ...topicList }
      delete updatedList[topicID]
      updateTopicList(updatedList)
      return response.data
    },
  })
}
