import { useGetSharedTopics } from "@/hooks/api/dashboard";
import useAppStore from "@/store/useAppStore";
import { Topic } from "@/types";
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { shallow } from "zustand/shallow";
import TopicItem from "./SharedTopicItem";
import { LoadingButton } from "@mui/lab";
import { NoResults } from "@/components";
import colors from "@/styles/colors";

interface Props {
  showDetails?: boolean;
  onClickTopic: (id: string) => void;
}

export default function ShareInteractions({ onClickTopic }: Props) {
  const [page, setPage] = useState(1);
  const [endReached, setEndReached] = useState(false);

  const { setTopicType, updateTopicSelectedAt } = useAppStore();
  const [topicList, topicFilterType] = useAppStore(
    state => [
      state.home.topicList,
      state.home.topicFilterType,
    ],
    shallow
  )

  const getSharedTopics = useGetSharedTopics();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null)

  useEffect(() => {
    getSharedTopics.mutate({ page, topicType: topicFilterType });
  }, [page])

  useEffect(() => {
    if (page === 1 && getSharedTopics.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, getSharedTopics.isSuccess])

  useEffect(() => {
    if (getSharedTopics.isSuccess && getSharedTopics.data && !getSharedTopics.isError) {
      if (getSharedTopics.data.length < 20) {
        setEndReached(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSharedTopics.isSuccess, getSharedTopics.data, getSharedTopics.isError]);

  const sharedTopics = useMemo(() => {
    const list = Object.values(topicList);
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1;
      if (a.dateUpdated < b.dateUpdated) return 1;
      return 0;
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicList]);

  useEffect(() => {
    if (getSharedTopics.isSuccess && page === 1) {
      if (getSharedTopics.data.length == 0) onClickTopic('');
      updateTopicSelectedAt();
    }
  }, [getSharedTopics.isSuccess, getSharedTopics.data, page])

  const onClickShowMore = () => {
    if (endReached || getSharedTopics.isLoading || !sharedTopics.length) {
      return;
    }
    setPage(page + 1);
  }

  const handleActivityChange = (event: SelectChangeEvent) => {
    setTopicType(Number(event.target.value));
  };

  if (getSharedTopics.isSuccess && getSharedTopics.data.length === 0 && page === 1) {
    return (
      <Box p={5} display="flex" justifyContent={'center'}>
        <NoResults type="shared" iconColor={colors.text} />
      </Box>
    )
  }

  return (
    <Box flex={1} overflow='auto' ref={scrollRef}>
      <Box display={'flex'} alignItems='center' justifyContent={'space-between'} px={2} pt={2}>
        <Typography fontWeight={400} fontSize={14}><span style={{ color: 'black', fontWeight: 500 }}> {sharedTopics.length}</span> Creations recently added</Typography>
        <Select
          value={String(topicFilterType)}
          onChange={handleActivityChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ height: 34, borderRadius: 50, color: '#525E9C', border: '1px solid #E7EDF3', width: 176, display: 'flex', alignItems: 'center' }}
        >
          <MenuItem value={'0'}>All creation type</MenuItem>
          <MenuItem value={'1'}>List</MenuItem>
          <MenuItem value={'2'}>Album</MenuItem>
          <MenuItem value={'3'}>Task List</MenuItem>
          <MenuItem value={'4'}>Conversation</MenuItem>
          <MenuItem value={'5'}>Document</MenuItem>
        </Select>
      </Box>
      {getSharedTopics.isLoading && page === 1 && (
        <Box mt={1} display="flex" justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={2} px={'4px'} margin={0}>
        {sharedTopics.map((item: Topic, index: number) => (
          <Grid item xs={'auto'} key={`${item.topicID}-${index}`}>
            <TopicItem topic={item} key={item.topicID} onClick={() => onClickTopic(item.topicID)} />
          </Grid>
        ))}
      </Grid>

      {(page > 1 || !getSharedTopics.isLoading) && (
        <Box m={3} display="flex" justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={onClickShowMore}
            loading={getSharedTopics.isLoading && page > 1}
            disabled={endReached}
          >
            {endReached ? 'No more results' : 'Show More'}
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}