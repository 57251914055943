/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { resizeFile } from '@/utils/helper'
import { useLocalStorage } from '@/hooks/common'

interface param {
  file: File
  fileType?: number
}

export const useUploadProfilePicture = () => {
  const axios = useContext(AxiosContext)
  const [basicInfo] = useLocalStorage('ewBasicInfo', null)
  const containerID = basicInfo.mobileContID
  return useMutation({
    mutationKey: [ReactQueryKeys.UPLOAD_PROFILE_PICTURE, containerID],
    mutationFn: async ({ file, fileType = 3 }: param) => {
      const image = await resizeFile(file)

      const formData = new FormData()
      formData.append('egowallFileUpload', image as string)
      formData.append('fileUploadName', 'egowallFileUpload')
      formData.append('fileType', String(fileType))
      formData.append('uploadContainerID', containerID)
      formData.append('format', 'json')
      const response = await axios.api.post('ego/upload-object', formData)
      return response.data
    },
  })
}
