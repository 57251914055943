import { DMConnection, Topic } from "@/types";
import { PrivacyTypes } from "@/utils/constants";
import { getTooltipTitleForPrivacyUsers } from "@/utils/helper";
import { Box, Tooltip, Typography } from "@mui/material"
// import SVG from 'react-inlinesvg';

interface Props {
  topic: Topic | DMConnection
  onClick?: () => void;
}

export function TagPrivacy({ topic, onClick }: Props) {
  const privacyData = PrivacyTypes[Number(topic.visibility)]
  const onClickPrivacy = () => {
    if (onClick) onClick();
  }
  return (
    <Tooltip arrow title={getTooltipTitleForPrivacyUsers(topic)}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={"6px"}
        px={"15px"}
        border={"1px solid #E7EDF3"}
        borderRadius={'50px'}
        onClick={onClickPrivacy}
        sx={{ cursor: onClick ? 'pointer' : 'auto' }}
      >
        {/* <SVG src={privacyData.icon} width={12} height={12} color={"#525E9C"} /> */}
        <Typography
          fontSize={'10px'}
          lineHeight={'12px'}
          sx={{ color: "#525E9C", fontWeight: '500' }}
        >
          {privacyData.label}
          {topic.visibility === '4' && ` (${topic.connections?.length})`}
          {topic.visibility === '5' && ` (${(topic as Topic).cliques?.length})`}
        </Typography>
      </Box>
    </Tooltip>
  )
}