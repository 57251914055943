import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { MyConnection } from "@/types";
import { PrivacyTypes, TopicTypes } from "@/utils/constants";
import { isPatternTopicType } from "@/utils/helper";
import { ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import SVG from 'react-inlinesvg';
import { shallow } from "zustand/shallow";

interface StatusProps {
  title: string
  connections: string[]
  groupIDs: string[]
  topicType: number
  privacyType: number
  submitText: string
  nextText?: string
  isSubmitting: boolean
  onSubmit: () => void
  onPressNext?: () => void
}

export default function BottomStatus({
  title,
  connections,
  groupIDs,
  topicType,
  privacyType,
  submitText,
  nextText,
  isSubmitting,
  onSubmit,
  onPressNext
}: StatusProps) {
  const [myConnections] = useAppStore((state) => [state.user.myConnections], shallow)

  const oneToOneUser = useMemo((): MyConnection => {
    if (connections.length > 0) {
      return myConnections.find(i => i.userID === connections[0]) || {} as MyConnection
    }
    return {} as MyConnection
  }, [myConnections, connections])

  const isSubmitReady = useMemo(() => {
    if (title?.trim() === '') return false
    if (topicType === 0) return false;
    if (privacyType === 0) return false;
    if (privacyType === 4 && connections.length === 0) return false;
    if (privacyType === 5 && groupIDs.length === 0) return false;
    if (privacyType === 6 && connections.length === 0) return false;
    return true;
  }, [title, privacyType, topicType, groupIDs, connections])

  return (
    <Grid item xs={12} height="70px" bgcolor={'white'} position="absolute" left={24} right={24} bottom={24} borderRadius={2}>
      <Box display={'flex'} alignItems="center" height="100%" px={2}>
        {topicType > 0 && (
          <Box
            display={'flex'}
            alignItems="center"
            height={32}
            borderRadius={4}
            bgcolor={`${colors.lightGreen}25`}
            px={1.5}
          >
            <SVG
              src={TopicTypes[topicType].icon}
              width={20}
              height={20}
              color={colors.lightGreen}
            />
            <Typography variant="labelSmall" color={colors.lightGreen} sx={{ marginLeft: 0.5 }}>{TopicTypes[topicType].title}</Typography>
          </Box>
        )}
        {privacyType > 0 && (
          <>
            <ArrowForwardIos width={8} height={16} sx={{ margin: '0 10px', color: colors.darkGrey }} />
            <Box
              display={'flex'}
              alignItems="center"
              height={32}
              borderRadius={4}
              bgcolor={`${colors.lightGrey}`}
              px={1.5}
            >
              <SVG src={PrivacyTypes[privacyType].icon} color={PrivacyTypes[privacyType].color} />
              <Typography variant="labelSmall" color={PrivacyTypes[privacyType].color} sx={{ marginLeft: 0.5 }}>{PrivacyTypes[privacyType].label}</Typography>
            </Box>
          </>
        )}
        {privacyType === 4 && connections.length > 0 && (
          <>
            <ArrowForwardIos width={8} height={16} sx={{ margin: '0 10px', color: colors.darkGrey }} />
            <Typography variant="labelSmall">{connections.length} connection{connections.length > 1 ? 's' : ''}</Typography>
          </>
        )}
        {privacyType === 5 && groupIDs.length > 0 && (
          <>
            <ArrowForwardIos width={8} height={16} sx={{ margin: '0 10px', color: colors.darkGrey }} />
            <Typography variant="labelSmall">{groupIDs.length} circle{groupIDs.length > 1 ? 's' : ''}</Typography>
          </>
        )}
        {privacyType === 6 && connections.length > 0 && (
          <>
            <ArrowForwardIos width={8} height={16} sx={{ margin: '0 10px', color: colors.darkGrey }} />
            <Typography variant="labelSmall">{oneToOneUser.firstName} {oneToOneUser.lastName}</Typography>
          </>
        )}
        <Box flex={1} display="flex" justifyContent={'flex-end'}>
          <LoadingButton
            variant="contained"
            disabled={!isSubmitReady}
            onClick={onSubmit}
            loading={isSubmitting}
          >
            {submitText}
          </LoadingButton>
          {nextText && isPatternTopicType(topicType) && (
            <LoadingButton
              variant="contained"
              disabled={!isSubmitReady}
              onClick={onPressNext}
              loading={isSubmitting}
              sx={{ marginLeft: 2 }}
            >
              {nextText}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Grid>
  )
}