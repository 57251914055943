import { useGetTopics } from '@/hooks/api/dashboard'
import useAppStore from '@/store/useAppStore'
import { Topic, TopicListFilter } from '@/types'
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { shallow } from 'zustand/shallow'
import TopicItem from './TopicItem'
import { LoadingButton } from '@mui/lab'
import { NoResults } from '@/components'
import { useNavigate } from 'react-router-dom'
import { PrivacyType, TopicType, TopicTypes } from '@/utils/constants'
import DiscussionTopicItem from './DiscussionTopicItem'
import { CreateTopicModalContext } from '@/context/CreateTopicModalContext'

const filterTopicTypes: TopicType[] = [TopicType.PATTERN, TopicType.QUILT, TopicType.GALLERY]

export default function HomeSearchResult() {
  const [page, setPage] = useState(1)
  const [endReached, setEndReached] = useState(false)
  const { setIsPrivacySelected, updateTopicSelectedAt } = useAppStore()
  const [interactionType, topicType, topicList] = useAppStore(
    (state) => [state.home.interactionFilterType, state.home.topicFilterType, state.home.topicList],
    shallow,
  )
  const { contextTopicType, contextPrivacyType, onClickTopic, openModal, isDiscover } =
    useContext(CreateTopicModalContext)
  const [selectedTopicType, setSelectedTopicType] = useState<TopicType>(contextTopicType!)

  const getTopics = useGetTopics()
  const navigate = useNavigate()

  const handleFilterChange = (event: SelectChangeEvent) => {
    const value = event.target.value
    const type = TopicTypes[value].type
    setSelectedTopicType(type)
  }

  const scrollRef = useRef<any>(null)

  useEffect(() => {
    setEndReached(false)
    loadTopics(1)
  }, [selectedTopicType])

  useEffect(() => {
    if (page === 1 && getTopics.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, getTopics.isSuccess])

  useEffect(() => {
    if (getTopics.isSuccess && getTopics.data && !getTopics.isError) {
      if (getTopics.data.length < 20) {
        setEndReached(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTopics.isSuccess, getTopics.data, getTopics.isError])

  const topics = useMemo(() => {
    const list = Object.values(topicList)
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1
      if (a.dateUpdated < b.dateUpdated) return 1
      return 0
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicList])

  useEffect(() => {
    if (getTopics.isSuccess && page === 1) {
      updateTopicSelectedAt()
    }
  }, [getTopics.isSuccess, getTopics.data, page])

  const onClickShowMore = () => {
    if (endReached || getTopics.isLoading || !topics.length) {
      return
    }
    loadTopics(page + 1)
    setPage(page + 1)
  }

  const loadTopics = (pageNo: number = 1) => {
    getTopics.mutate({
      pageNo,
      interaction: Number(contextPrivacyType),
      topicType: Number(selectedTopicType),
    })
  }

  const onRemovedTopic = () => {
    // navigate('/topic')
  }

  const onClickTopicItem = (topic: Topic) => {
    setIsPrivacySelected(false)
    onClickTopic(topic)
  }

  const onClickNew = () => {
    openModal(selectedTopicType, contextPrivacyType)
  }

  const showNoResultView = useMemo(() => {
    return getTopics.isSuccess && getTopics.data.length == 0 && page === 1
  }, [getTopics.isSuccess, getTopics.data])

  return (
    <Box flex={1} flexDirection={'column'} ref={scrollRef}>
      <Box className='topicListWrapper' p={2} display={'flex'} gap={2} flexDirection={'column'}>
        <Box display={'flex'} alignItems='center' justifyContent={'space-between'}>
          {getTopics.isLoading ? (
            <Typography fontWeight={400} fontSize={14}>
              {`Loading...`}
            </Typography>
          ) : (
            <Typography fontWeight={400} fontSize={14}>
              <span style={{ color: 'black', fontWeight: 500 }}> {topics.length}</span>{' '}
              {`${selectedTopicType == TopicType.ALL ? 'Creations' : `${TopicTypes[selectedTopicType].title}(s)`}
            recently added`}
            </Typography>
          )}

          {contextTopicType == TopicType.ALL && (
            <Select
              value={`${selectedTopicType}`}
              onChange={handleFilterChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                height: 34,
                borderRadius: 100,
                color: '#525E9C',
                border: '1px solid #E7EDF3',
                width: 176,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
              }}
            >
              <MenuItem value={'0'}>All creation type</MenuItem>
              {filterTopicTypes.map((item, index) => {
                return (
                  <MenuItem key={`${index}`} value={`${item}`}>
                    {TopicTypes[item].title}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        </Box>

        {getTopics.isLoading && page === 1 && (
          <Box mt={1} display='flex' justifyContent={'center'}>
            <CircularProgress />
          </Box>
        )}

        {showNoResultView ? (
          <Box p={5} display='flex' justifyContent={'center'}>
            <NoResults interaction={interactionType} topicType={topicType} onClick={onClickNew} />
          </Box>
        ) : (
          <Grid container spacing={2} flex={1}>
            {topics.map((item: Topic, index: number) => (
              <Grid item xs={'auto'} key={`${item.topicID}-${index}`}>
                {item.topicType == TopicType.DISCUSSION.toString() ? (
                  <DiscussionTopicItem
                    topic={item}
                    key={item.topicID}
                    onClick={() => onClickTopicItem(item)}
                    onRemoved={onRemovedTopic}
                  />
                ) : (
                  <TopicItem
                    topic={item}
                    key={item.topicID}
                    onClick={() => onClickTopicItem(item)}
                    onRemoved={onRemovedTopic}
                    showAuthorForPublicOnly={isDiscover}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      {(page > 1 || !getTopics.isLoading) && (
        <Box m={3} display='flex' justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={onClickShowMore}
            loading={getTopics.isLoading && page > 1}
            disabled={endReached}
          >
            {endReached ? '' : 'Show More'}
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}
