import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import SVG from 'react-inlinesvg'
import { Dayjs } from 'dayjs'
import { AuthFormLogo, AuthFormView } from './__styled'
import { getCopyRightText } from '@/utils/helper'
import SignUpForm1 from './SignUpForm1'
import SignUpForm2 from './SignUpForm2'
import SignUpForm3 from './SignUpForm3'
import { AuthUser } from '@/types'
import {
  useSignup,
  useConfirmSignup,
  useRegisterCognito,
  useResendSignupCode,
} from '@/hooks/api/auth'
import { SVGS } from '@/assets/svgs'

export default function SignUp() {
  const [userData, setUserData] = useState<AuthUser>({
    user: '',
    password: '',
    firstName: '',
    lastName: '',
    dob: null,
    code: '',
  })
  const [step, setStep] = useState(1)

  const signup = useSignup(userData.user, userData.password)
  const confirm = useConfirmSignup(userData.user, userData.code)
  const registerCognito = useRegisterCognito(userData)
  const resendCode = useResendSignupCode(userData.user)

  useEffect(() => {
    if (signup.isSuccess) {
      setStep(2)
    }
  }, [signup.isSuccess])

  useEffect(() => {
    if (confirm.isSuccess) {
      registerCognito.mutate()
    }
  }, [confirm.isSuccess])

  const onSubmitForm1 = async (user: string, password: string) => {
    setUserData({ ...userData, user, password })
    signup.mutate()
  }

  const onSubmitForm2 = (firstName: string, lastName: string, dob: Dayjs) => {
    setUserData({ ...userData, firstName, lastName, dob })
    setStep(3)
  }

  const onSubmitForm3 = (code: string) => {
    setUserData({ ...userData, code })
    setTimeout(() => {
      confirm.mutate()
    })
  }

  const onResendVerificationCode = () => {
    resendCode.mutate()
  }

  return (
    <AuthFormView item xs={12} md={6} position='relative'>
      {step === 1 && (
        <SignUpForm1
          onSubmit={onSubmitForm1}
          loading={signup.isLoading}
          error={signup.error?.toString() || ''}
        />
      )}
      {step === 2 && <SignUpForm2 onSubmit={onSubmitForm2} />}
      {step === 3 && (
        <SignUpForm3
          user={userData.user}
          onSubmit={onSubmitForm3}
          onResend={onResendVerificationCode}
          loading={confirm.isLoading || resendCode.isLoading}
          error={confirm.error?.toString() || ''}
        />
      )}
      <Typography
        variant='p'
        textAlign={'center'}
        position='absolute'
        mb={4.5}
        bottom={0}
        left={0}
        right={0}
      >
        {getCopyRightText()}
      </Typography>
    </AuthFormView>
  )
}
