import { Box, Dialog, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Login from './Login'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import AuthDialogLeftView from './AuthDialogLeftView'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'
import closeIcon from '@/assets/svgs/close-round-colored.svg'

interface AuthDialogProps {
  authStep: string
  open: boolean
  onClose: () => void
}
const AuthDialog = ({ authStep, open, onClose }: AuthDialogProps) => {
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: 'red',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            overflow: 'hidden',
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{ color: '#475496', position: 'absolute', right: '16px', top: '16px', zIndex: 10 }}
          >
            <SVG src={closeIcon} width={30} height={30} />
          </IconButton>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              flex: 1,
              display: lessThanMedium ? 'none' : 'flex',
              overflowY: 'auto',
            }}
          >
            <AuthDialogLeftView />
          </Box>
          <Box sx={{ width: '100%', height: '100%', backgroundColor: 'blue', flex: 1 }}>
            {authStep == 'login' ? (
              <Login />
            ) : authStep == 'signup' ? (
              <SignUp />
            ) : (
              authStep == 'forgot-password' && <ForgotPassword />
            )}
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  )
}

export default AuthDialog
