import { SVGS } from '@/assets/svgs'
import { AlbumPhoto, Ego, Note } from '@/types'
import { DeleteOutline, EditOutlined, Favorite, MoreVert } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, List, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'
import { AlbumPhotoItem, NoteWrapper } from '../__styled'
import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react'
import { useDeleteNote, useInteractToNote } from '@/hooks/api/dashboard'
import { toast } from 'react-hot-toast'
import { ActionMenu, RModal } from '@/components'
import colors from '@/styles/colors'
import ImageSlider from '../components/ImageSlider'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { useLocation, useNavigate } from 'react-router-dom'
import AlbumCollage from '@/components/AlbumCollage/AlbumCollage'
import { scrollToBottom } from '@/utils/helper'
import { BackgroundImage } from '@/pages/__styled'
import { IMAGES } from '@/assets/images'
import { COLLAGE_ITEMS_LIMIT } from '@/components/AlbumCollage/Config'
import { TopicDetailsContext } from '@/context/TopicDetails'
import LoadingAnimatedView from '@/components/LoadingAnimatedView'
import { RoundedFilledButton } from '@/components/StyledComponents'

interface ContentProps {
  photos: Record<string, AlbumPhoto>
  notes?: Note[]
  onClickAlbumNote?: (note: Note) => void
}

export default function AlbumContent({
  notes: topicNotes = [],
  photos,
  onClickAlbumNote,
}: ContentProps) {
  const [slideMode, setSlideMode] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [deleteNoteID, setDeleteNoteID] = useState('')

  const interactToNote = useInteractToNote()
  const navigate = useNavigate()
  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const [ewUser] = useLocalStorage('ewUser', null)
  const topicID = query.get('topicID') || ''
  const noteID = query.get('noteID') || ''
  const [loadingMore, setLoadingMore] = useState(false)

  const currentPageNumber = useRef<Number | null>(null)
  const deleteNote = useDeleteNote(false)
  const { topic, canLoadNextPage, loadNextPage } = useContext(TopicDetailsContext)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null)
  let notesOldSize = useRef<number | null>(null)

  const notes: Note[] = useMemo(() => {
    if (topic && topic.notes.length > 0) {
      return topic.notes.filter((e) => e.noteID !== topic.firstNote.noteID)
    } else {
      return []
    }
  }, [topic])

  const selectedNote = useMemo(() => {
    const find = notes.find((i) => i.noteID === noteID)
    return find
  }, [notes, noteID])

  const onClickNoteLike = (pNote: Note) => {
    toast.promise(
      interactToNote.mutateAsync({
        interactionType: '3',
        interaction: pNote?.isThumbsUp == 1 ? '0' : '1',
        comment: 'test',
        noteID: pNote.noteID,
        topicID,
      }),
      {
        loading: `${pNote?.isThumbsUp == 1 ? 'Disliking' : 'Liking'} a message...`,
        success: <Typography variant='p'>Success!</Typography>,
        error: (
          <Typography variant='p'>
            Failed to {pNote?.isThumbsUp == 1 ? 'dislike' : 'like'}
          </Typography>
        ),
      },
    )
  }

  useEffect(() => {
    if (notes.length === 0) {
      query.delete('noteID')
      navigate(`${pathname}?${query.toString()}`, { replace: true })
    } else if (noteID.length === 0 || !notes.find((i) => i.noteID === noteID)) {
      // query.set('noteID', notes[notes.length - 1].noteID)
      // navigate(`${pathname}?${query.toString()}`, { replace: true })
    }

    let shouldScrollToBottom =
      currentPageNumber.current == null ||
      currentPageNumber.current == topic.pagination?.currentPage
    currentPageNumber.current = topic.pagination?.currentPage || 1

    if (shouldScrollToBottom) {
      // First time,
      if (!notesOldSize.current) {
        scrollToBottom(scrollRef, 500)
      } else if (notesOldSize.current < notes.length) {
        // When note added
        scrollToBottom(scrollRef, 50)
      }
    }

    notesOldSize.current = notes.length
  }, [notes])

  const onClickNote = (nID: string) => {
    if (noteID != nID) {
      query.set('noteID', nID)
      navigate(`${pathname}?${query.toString()}`, { replace: true })
    }
  }

  const onCloseSlider = () => {
    setSlideMode(false)
  }

  const onCloseModal = () => {
    setModalOpened(false)
  }

  const onClickEditNote = (nID: string) => {
    query.set('status', 'edit')
    query.set('noteID', nID)
    navigate(`${pathname}?${query.toString()}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickDeleteNote = (noteID: string) => {
    setDeleteNoteID(noteID)
    setModalOpened(true)
  }

  const noteActions = useCallback((note: Note) => {
    return [
      {
        icon: <EditOutlined />,
        name: 'Edit',
        onClick: () => onClickEditNote(note.noteID),
      },
      {
        icon: <DeleteOutline />,
        name: 'Delete',
        onClick: () => onClickDeleteNote(note.noteID),
      },
    ]
  }, [])

  const onDeletePhoto = () => {
    if (!deleteNoteID) return
    toast.promise(
      deleteNote.mutateAsync({
        noteID: deleteNoteID,
        topicID: topicID,
      }),
      {
        loading: 'Deleting...',
        success: <Typography variant='p'>Successfully deleted</Typography>,
        error: <Typography variant='p'>Failed to delete</Typography>,
      },
    )
  }

  if (notes.length === 0) {
    return (
      <Box
        display='flex'
        flexDirection={'column'}
        height='100%'
        justifyContent={'center'}
        alignItems='center'
      >
        <SVG src={SVGS.NoAlbum} />
        <Typography variant='large' mt={3} width={200} textAlign={'center'}>
          Add some photos to your new album!
        </Typography>
      </Box>
    )
  }

  const onClickLoadMore = () => {
    setLoadingMore(true)
    loadNextPage().then((value) => {
      setLoadingMore(false)
    })
  }

  const renderStateView = (note: Note) => {
    return (
      <Box
        className='action'
        sx={{ cursor: 'pointer' }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          display='flex'
          color={'white'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={2}
          pl={2}
        >
          <Box
            onClick={(e) => {
              e.stopPropagation()
              onClickNoteLike(note)
            }}
          >
            <Typography display='flex' alignItems={'center'} color={colors.white} gap={'6px'}>
              <SVG
                src={note.isThumbsUp ? SVGS.LikeFilledIcon : SVGS.LikeOutlineIcon}
                width={15}
                height={15}
                color={note.isThumbsUp ? colors.yellow : colors.white}
              />
              {note.thumbsUp}
            </Typography>
          </Box>
          <Box>
            <Typography display='flex' alignItems={'center'} color={colors.white} gap={'6px'}>
              <SVG src={SVGS.CommentIcon} width={17} height={17} color={colors.white} />
              {note.comments}
            </Typography>
          </Box>
        </Box>

        {!deleteNote.isLoading && note.authorID === ewUser?.userID && (
          <ActionMenu
            id='topic-item-menu'
            parent={<MoreVert sx={{ color: 'white' }} />}
            actions={noteActions(note)}
          />
        )}
      </Box>
    )
  }

  return (
    <Box
      ref={scrollRef}
      position='relative'
      flex={1}
      sx={{ overflowX: 'hidden', overflowY: 'auto', p: 2 }}
    >
      <Grid container spacing={2} flex={1}>
        {canLoadNextPage() == true && (
          <Grid item xs={12} key={'header'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              {loadingMore ? (
                <LoadingAnimatedView showGif={false} />
              ) : (
                <RoundedFilledButton onClick={onClickLoadMore}>Load More</RoundedFilledButton>
              )}
            </Box>
          </Grid>
        )}

        {notes.map((note: Note, index: number) => {
          const isSelected = note.noteID === noteID

          const links = note.egoList.map((e) => e.photoAlbumURL)
          return (
            <Grid item xs={'auto'} key={`${note.noteID}-${index}`}>
              <NoteWrapper
                my={0}
                width={'267px'}
                height={'218px'}
                borderRadius={6}
                key={note.noteID}
                onClick={() => onClickNote(note.noteID)}
                className={isSelected ? 'active' : ''}
              >
                {note.egoList.length > 0 ? (
                  <AlbumCollage
                    links={links}
                    rowHeight={Math.min(links.length, COLLAGE_ITEMS_LIMIT) <= 2 ? 218 : 0.5 * 218}
                    applyLimit
                  />
                ) : (
                  <BackgroundImage
                    sx={{ minHeight: '218px' }}
                    url={
                      note.egoList.length > 0
                        ? note.egoList[note.egoList.length - 1].photoAlbumURL
                        : IMAGES.topicPreviewPlaceholder
                    }
                    onClick={() => onClickNote(note.noteID)}
                  />
                )}

                {renderStateView(note)}
              </NoteWrapper>
            </Grid>
          )
        })}
      </Grid>
      <RModal
        open={modalOpened}
        onClose={onCloseModal}
        title='Delete Photo'
        description='Are you sure you want to delete this photo?'
        submitText='Delete'
        onSubmit={onDeletePhoto}
      />
      {slideMode && selectedNote && selectedNote.egoList.length > 0 && (
        <ImageSlider photos={photos} note={selectedNote} onClose={onCloseSlider} initialIndex={0} />
      )}
    </Box>
  )
}
