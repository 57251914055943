/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPatternStore, IPatternSlice } from '@/types'

const initialState: IPatternStore = {
  initialScreenShot: null,
}

export const createPatternSlice = (set: any): IPatternSlice => ({
  pattern: initialState,
  setInitialScreenShot: (imageData: any) =>
    set((state: any) => ({
      pattern: {
        ...state.pattern,
        initialScreenShot: imageData,
      },
    })),
})

export default createPatternSlice
