import { SVGS } from '@/assets/svgs'
import { ActionMenu, RModal, UserAvatar } from '@/components'
import { useDeleteNote, useInteractToNote } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import colors from '@/styles/colors'
import { AlbumPhoto, Ego, Note } from '@/types'
import { convertToGMT, scrollToBottom } from '@/utils/helper'
import { ContentCopy, DeleteOutline, EditOutlined, Favorite, MoreVert } from '@mui/icons-material'
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCopyToClipboard } from 'usehooks-ts'
import ImageSlider from '../components/ImageSlider'
import { AlbumPhotoItem, DMNoteWrapper } from '../__styled'
import { TopicType } from '@/utils/constants'
import DMNoteCard from './DMNoteCard'
import { TopicDetailsContext } from '@/context/TopicDetails'
import LoadingAnimatedView from '@/components/LoadingAnimatedView'
import { RoundedFilledButton } from '@/components/StyledComponents'

interface Props {
  topicID: string
  topicType?: string
  topicTypeID?: TopicType
  photos: Record<string, AlbumPhoto>
  onClickInteraction: () => void
}

export default function DMContent({
  topicID,
  topicType = 'dm',
  photos,
  onClickInteraction,
}: Props) {
  const [selectedNote, setSelectedNote] = useState<Note | null>(null)
  const [sliderIndex, setSliderIndex] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [deleteNoteID, setDeleteNoteID] = useState('')
  const [, copy] = useCopyToClipboard()

  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const noteID = query.get('noteID') || ''
  const navigate = useNavigate()
  const interactToNote = useInteractToNote()
  const deleteNote = useDeleteNote(topicType === 'dm')
  const [ewUser] = useLocalStorage('ewUser', null)
  const { topic, canLoadNextPage, loadNextPage } = useContext(TopicDetailsContext)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null)
  let notesOldSize = useRef<number | null>(null)
  const currentPageNumber = useRef<Number | null>(null)

  const notes: Note[] = useMemo(() => {
    if (topic && topic.notes.length > 0) {
      return topic.notes.filter((e) => e.noteID !== topic.firstNote.noteID)
    } else {
      return []
    }
  }, [topic])

  useEffect(() => {
    if (notes.length === 0) {
      query.delete('noteID')
      navigate(`${pathname}?${query.toString()}`, { replace: true })
    } else if (noteID.length === 0 || !notes.find((i) => i.noteID === noteID)) {
      query.set('noteID', notes[notes.length - 1].noteID)
      navigate(`${pathname}?${query.toString()}`, { replace: true })
    }

    let shouldScrollToBottom =
      currentPageNumber.current == null ||
      currentPageNumber.current == topic.pagination?.currentPage
    currentPageNumber.current = topic.pagination?.currentPage || 1

    if (shouldScrollToBottom) {
      if (!notesOldSize.current) {
        scrollToBottom(scrollRef, 500)
      } else if (notesOldSize.current < notes.length) {
        scrollToBottom(scrollRef, 50)
      }
    }

    notesOldSize.current = notes.length
  }, [notes])

  const onCopyNoteLink = (nID: string) => {
    query.set('noteID', nID)
    copy(`${window.location.href.split('?')[0]}?${query.toString()}`)
    toast.success('Link has been copied')
  }

  const onClickDeleteDM = (nID: string) => {
    setDeleteNoteID(nID)
    setDeleteModalOpened(true)
  }

  const onClickNoteLike = (pNote: Note) => {
    toast.promise(
      interactToNote.mutateAsync({
        interactionType: '3',
        interaction: pNote?.isThumbsUp == 1 ? '0' : '1',
        comment: 'test',
        noteID: pNote.noteID,
        topicID,
      }),
      {
        loading: `${pNote?.isThumbsUp == 1 ? 'Disliking' : 'Liking'} a message...`,
        success: <Typography variant='p'>Success!</Typography>,
        error: (
          <Typography variant='p'>
            Failed to {pNote?.isThumbsUp == 1 ? 'dislike' : 'like'}
          </Typography>
        ),
      },
    )
  }

  const onClickEditDM = (nID: string) => {
    query.set('status', 'edit')
    query.set('noteID', nID)
    navigate(`${pathname}?${query.toString()}`)
  }

  const onClickImage = (note: Note, index: number) => {
    setSelectedNote(note)
    setSliderIndex(index)
  }

  const onCloseSlider = () => {
    setSelectedNote(null)
  }

  const onClickNote = (nID: string) => {
    query.set('noteID', nID)
    navigate(`${pathname}?${query.toString()}`)
    onClickInteraction()
  }

  const onClickLoadMore = () => {
    setLoadingMore(true)
    loadNextPage().then((value) => {
      setLoadingMore(false)
    })
  }

  const onCloseModal = () => {
    setDeleteModalOpened(false)
  }

  const onDeleteNote = () => {
    if (!deleteNoteID) return
    toast.promise(
      deleteNote.mutateAsync({
        noteID: deleteNoteID,
        topicID,
      }),
      {
        loading: 'Deleting...',
        success: <Typography variant='p'>Successfully deleted</Typography>,
        error: <Typography variant='p'>Failed to delete</Typography>,
      },
    )
  }

  return (
    <Box
      ref={scrollRef}
      position='relative'
      flex={1}
      sx={{ overflowX: 'hidden', overflowY: 'auto' }}
    >
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
        }}
      >
        {canLoadNextPage() == true && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} py={'8px'}>
            {loadingMore ? (
              <LoadingAnimatedView showGif={false} />
            ) : (
              <RoundedFilledButton onClick={onClickLoadMore}>Load More</RoundedFilledButton>
            )}
          </Box>
        )}

        {notes.map((note) => (
          <DMNoteCard
            key={`${note.noteID}`}
            note={note}
            isSelected={note.noteID === noteID}
            isMyNote={note.authorID == ewUser.userID}
            onClickNote={onClickNote}
            onCopyNoteLink={onCopyNoteLink}
            onClickEditDM={onClickEditDM}
            onClickDeleteDM={onClickDeleteDM}
            onClickLike={onClickNoteLike}
          />
        ))}
      </List>

      {selectedNote && (
        <ImageSlider
          photos={photos}
          note={selectedNote}
          onClose={onCloseSlider}
          initialIndex={sliderIndex}
        />
      )}
      <RModal
        open={deleteModalOpened}
        onClose={onCloseModal}
        title='Delete Message'
        description='Are you sure you want to delete this message?'
        submitText='Delete'
        onSubmit={onDeleteNote}
      />
    </Box>
  )
}
