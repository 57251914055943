/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useConnectionDelete = () => {
  const axios = useContext(AxiosContext)
  const { setMyConnections } = useAppStore()
  const [myConnections] = useAppStore((state) => [state.user.myConnections], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.DELETE_CONNECTION],
    mutationFn: async (connID: string) => {
      const response = await axios.api.post('ajax/circles', {
        requestType: 'delConn',
        connID,
        format: 'json',
      })
      const index = myConnections.findIndex((i) => i.userID === connID)
      if (index >= 0) {
        myConnections.splice(index, 1)
        setMyConnections([...myConnections])
      }
      return response.data
    },
  })
}
