/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ContainerDetails, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useGetContainerDetails = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_CONTAINER_DETAIL],
    mutationFn: async (containerID: string): Promise<ContainerDetails> => {
      const response = await axios.api.post('ajax/containers', {
        requestType: 'containerDetails',
        containerID,
        format: 'json',
      })
      return response.data.containers[0]
    },
  })
}
