import Logo from '@/assets/svgs/oddlyou.svg'
import LogoRound from '@/assets/svgs/oddlyou-round.svg'
import wLogo from '@/assets/svgs/oddlyou-white.svg'
import TabHome from '@/assets/svgs/tab-home.svg'
import TabShare from '@/assets/svgs/tab-share.svg'
import TabAlbum from '@/assets/svgs/tab-album.svg'
import TabConnect from '@/assets/svgs/tab-connect.svg'
import TabCircle from '@/assets/svgs/tab-circle.svg'
import TabGallery from '@/assets/svgs/tab-gallery.svg'
import TabUpload from '@/assets/svgs/tab-upload.svg'
import TabPlus from '@/assets/svgs/tab-plus.svg'
import TabPattern from '@/assets/svgs/tab-pattern.svg'
import TabQuilt from '@/assets/svgs/tab-quilt.svg'
import TabQuestion from '@/assets/svgs/tab-question.svg'
import FilterPrivacyAll from '@/assets/svgs/filter-privacy-all.svg'
import FilterPrivacyPublic from '@/assets/svgs/filter-privacy-public.svg'
import FilterPrivacyPrivate from '@/assets/svgs/filter-privacy-private.svg'
import FilterPrivacyCircle from '@/assets/svgs/filter-privacy-circle.svg'
import FilterPrivacyConnection from '@/assets/svgs/filter-privacy-connection.svg'
import FilterPrivacyOneToOne from '@/assets/svgs/filter-privacy-one-to-one.svg'
import FilterTopicAll from '@/assets/svgs/filter-topic-all.svg'
import FilterTopicList from '@/assets/svgs/filter-topic-list.svg'
import FilterTopicAlbum from '@/assets/svgs/filter-topic-album.svg'
import FilterTopicTaskList from '@/assets/svgs/filter-topic-task-list.svg'
import FilterTopicDiscussion from '@/assets/svgs/filter-topic-discussion.svg'
import FilterTopicDocument from '@/assets/svgs/filter-topic-document.svg'
import ActionLike from '@/assets/svgs/action-like.svg'
import ActionComment from '@/assets/svgs/action-comment.svg'
import ActionVisit from '@/assets/svgs/action-visit.svg'
import ActionMedia from '@/assets/svgs/action-media.svg'
import Send from '@/assets/svgs/send.svg'
import Phone from '@/assets/svgs/phone.svg'
import Calendar from '@/assets/svgs/calendar.svg'
import CalendarColoredIcon from '@/assets/svgs/calendar-colored.svg'
import Bag from '@/assets/svgs/bag.svg'
import Pencil from '@/assets/svgs/edit.svg'
import Deactivate from '@/assets/svgs/deactivate.svg'
import Delete from '@/assets/svgs/delete.svg'
import Camera from '@/assets/svgs/camera.svg'
import Public from '@/assets/svgs/public.svg'
import Group from '@/assets/svgs/group.svg'
import Reactions from '@/assets/svgs/reactions-and-shares.svg'
import ProfileTopic from '@/assets/svgs/profile-topic.svg'
import ProfileMemberSince from '@/assets/svgs/profile-member-since.svg'
import ProfileConnection from '@/assets/svgs/profile-connection.svg'
import ProfileCircle from '@/assets/svgs/profile-circle.svg'
import Reply from '@/assets/svgs/reply.svg'
import NoList from '@/assets/svgs/no-list.svg'
import NoTaskList from '@/assets/svgs/no-task-list.svg'
import NoConnection from '@/assets/svgs/no-connection.svg'
import NoConversation from '@/assets/svgs/no-conversation.svg'
import NoDiscussion from '@/assets/svgs/no-discussion.svg'
import NoDocument from '@/assets/svgs/no-document.svg'
import NoComments from '@/assets/svgs/no-comments.svg'
import NoLikes from '@/assets/svgs/no-likes.svg'
import NoAlbum from '@/assets/svgs/no-album.svg'
import NoAlbumSingle from '@/assets/svgs/no-album-single.svg'
import BlockUser from '@/assets/svgs/action-block.svg'
import RemoveUser from '@/assets/svgs/action-remove-user.svg'
import UploadIcon from '@/assets/svgs/upload.svg'
import UploadFilledIcon from '@/assets/svgs/icn-upload-filled.svg'
import Quilt from '@/assets/svgs/quilt.svg'
import PatternIcon from '@/assets/svgs/pattern-icon-create.svg'
import PatternSelectedIcon from '@/assets/svgs/pattern-icon-selected.svg'
import QuiltIcon from '@/assets/svgs/quilt-icon-create.svg'
import QuiltSelectedIcon from '@/assets/svgs/quilt-icon-selected.svg'
import AlbumIcon from '@/assets/svgs/album-icon-create.svg'
import AlbumSelectedIcon from '@/assets/svgs/album-icon-selected.svg'
import GalleryIcon from '@/assets/svgs/gallery-icon-create.svg'
import GallerySelectedIcon from '@/assets/svgs/gallery-icon-selected.svg'
import DiscussionIcon from '@/assets/svgs/discussion-icon-create.svg'
import DiscussionSelectedIcon from '@/assets/svgs/discussion-icon-selected.svg'
import DesignPatterns from '@/assets/svgs/icn-design-patterns.svg'
import DesignMeasure from '@/assets/svgs/icn-design-measure.svg'
import DesignMultiple from '@/assets/svgs/icn-design-multiple.svg'
import SocialFacebook from '@/assets/svgs/social-icn-fb.svg'
import SocialTwitter from '@/assets/svgs/social-icn-x.svg'
import SocialYoutube from '@/assets/svgs/social-icn-yt.svg'
import SocialInstagram from '@/assets/svgs/social-icn-ig.svg'
import ThumbIcon from '@/assets/svgs/icn-thumb.svg'
import LikeOutlineIcon from '@/assets/svgs/icn-like-outline.svg'
import LikeFilledIcon from '@/assets/svgs/icn-like-filled.svg'
import LikeWhiteIcon from '@/assets/svgs/icn-like-white.svg'
import CommentIcon from '@/assets/svgs/icn-comment.svg'
import CommentWhiteIcon from '@/assets/svgs/icn-comment-white.svg'
import ShareIcon from '@/assets/svgs/icn-share.svg'
import ShareWhiteIcon from '@/assets/svgs/icn-share-white.svg'
import SendArrowIcon from '@/assets/svgs/icn-send-arrow.svg'
import EyeIcon from '@/assets/svgs/eye.svg'
import EyeIconDark from '@/assets/svgs/icn-eye-dark.svg'
import EyeIconWhite from '@/assets/svgs/icn-eye-white.svg'
import CloseIcon from '@/assets/svgs/close-round.svg'
import CloseIconColored from '@/assets/svgs/close-round-colored.svg'
import CrossIcon from '@/assets/svgs/close-icon.svg'
import ReplyIcon from '@/assets/svgs/icn_reply.svg'
import UncheckedIcon from '@/assets/svgs/icn_cb_unchecked.svg'
import CheckedIcon from '@/assets/svgs/icn_cb-checked.svg'
import PatternMeshIcon from '@/assets/svgs/icn-pattern.svg'
import MaterialIcon from '@/assets/svgs/icn-material.svg'
import PrintIcon from '@/assets/svgs/icn-print.svg'
import ColorsIcon from '@/assets/svgs/icn-colors.svg'
import AccentIcon from '@/assets/svgs/icn-accent.svg'
import SceneIcon from '@/assets/svgs/icn-scene.svg'
import AlbumOutlineIcon from '@/assets/svgs/icn-album.svg'
import TakeScreenshotIcon from '@/assets/svgs/icn-screenshot.svg'
import SearchIcon from '@/assets/svgs/icn-search.svg'
import VisibilityIcon from '@/assets/svgs/icn-visibility.svg'
import VisibilityOffIcon from '@/assets/svgs/icn-visibility-off.svg'

export const SVGS = {
  Logo,
  wLogo,
  LogoRound,
  TabHome,
  TabShare,
  TabAlbum,
  TabConnect,
  TabCircle,
  TabGallery,
  TabUpload,
  TabPlus,
  TabPattern,
  TabQuilt,
  TabQuestion,
  FilterPrivacyAll,
  FilterPrivacyPublic,
  FilterPrivacyPrivate,
  FilterPrivacyCircle,
  FilterPrivacyConnection,
  FilterPrivacyOneToOne,
  FilterTopicAll,
  FilterTopicList,
  FilterTopicAlbum,
  FilterTopicTaskList,
  FilterTopicDiscussion,
  FilterTopicDocument,
  ActionLike,
  ActionComment,
  ActionVisit,
  ActionMedia,
  Send,
  Phone,
  Calendar,
  CalendarColoredIcon,
  Bag,
  Pencil,
  Deactivate,
  Delete,
  Camera,
  Public,
  Group,
  Reactions,
  ProfileTopic,
  ProfileMemberSince,
  ProfileConnection,
  ProfileCircle,
  Reply,
  BlockUser,
  RemoveUser,
  NoComments,
  NoLikes,
  NoConnection,
  NoList,
  NoTaskList,
  NoDiscussion,
  NoAlbum,
  NoAlbumSingle,
  NoConversation,
  NoDocument,
  UploadIcon,
  UploadFilledIcon,
  Quilt,
  DesignPatterns,
  DesignMeasure,
  DesignMultiple,
  SocialFacebook,
  SocialTwitter,
  SocialYoutube,
  SocialInstagram,
  ThumbIcon,
  LikeOutlineIcon,
  LikeFilledIcon,
  LikeWhiteIcon,
  CommentIcon,
  CommentWhiteIcon,
  ShareIcon,
  ShareWhiteIcon,
  SendArrowIcon,
  EyeIcon,
  EyeIconWhite,
  EyeIconDark,
  CloseIcon,
  PatternIcon,
  PatternSelectedIcon,
  QuiltIcon,
  QuiltSelectedIcon,
  AlbumIcon,
  AlbumSelectedIcon,
  GalleryIcon,
  GallerySelectedIcon,
  DiscussionIcon,
  DiscussionSelectedIcon,
  CrossIcon,
  ReplyIcon,
  UncheckedIcon,
  CheckedIcon,
  PatternMeshIcon,
  MaterialIcon,
  PrintIcon,
  ColorsIcon,
  AccentIcon,
  SceneIcon,
  AlbumOutlineIcon,
  TakeScreenshotIcon,
  SearchIcon,
  VisibilityIcon,
  VisibilityOffIcon,
}
