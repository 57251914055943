import { ActionMenu, UserAvatar } from '@/components'
import { Ego, Note } from '@/types'
import { Box, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { AlbumPhotoItem, DMNoteWrapper } from '../__styled'
import { ContentCopy, DeleteOutline, EditOutlined, Favorite, MoreVert } from '@mui/icons-material'
import colors from '@/styles/colors'
import ReactTimeago from 'react-timeago'
import { convertToGMT } from '@/utils/helper'
import { SVGS } from '@/assets/svgs'
import SVG from 'react-inlinesvg'
import AlbumCollage from '@/components/AlbumCollage/AlbumCollage'

interface DMNoteCardProps {
  note: Note
  isSelected: boolean
  isMyNote: boolean
  onClickNote: (noteID: string) => void
  onCopyNoteLink: (noteID: string) => void
  onClickEditDM: (noteID: string) => void
  onClickDeleteDM: (noteID: string) => void
  onClickLike: (note: Note) => void
}

const DMNoteCard = ({
  note,
  isSelected,
  isMyNote,
  onClickNote,
  onCopyNoteLink,
  onClickEditDM,
  onClickDeleteDM,
  onClickLike,
}: DMNoteCardProps) => {
  const topicActions = useMemo(() => {
    let actions = [
      {
        icon: <ContentCopy fontSize='small' />,
        help: 'Copy link',
        onClick: () => onCopyNoteLink(note.noteID),
      },
    ]
    if (isMyNote) {
      actions = actions.concat([
        {
          icon: <EditOutlined />,
          help: 'Edit',
          onClick: () => onClickEditDM(note.noteID),
        },
        {
          icon: <DeleteOutline />,
          help: 'Delete',
          onClick: () => onClickDeleteDM(note.noteID),
        },
      ])
    }
    return actions
  }, [note])

  const renderNoteText = (noteText: string) => {
    try {
      const parsed = JSON.parse(noteText)
      return (
        <div
          style={{ marginTop: '8px' }}
          className='draft-section'
          dangerouslySetInnerHTML={{
            __html: parsed.text,
          }}
        />
      )
    } catch (error) {
      return (
        <Typography mt={'8px'} variant='ps'>
          {noteText}
        </Typography>
      )
    }
  }

  const onClickImage = (egoIndex: number) => {}

  const renderPhotos = () => {
    const links = note.egoList.map((e) => e.photoAlbumURL)
    return (
      <Box sx={{ my: '12px' }}>
        <AlbumCollage rowHeight={window.innerHeight * 0.25} links={links} />
      </Box>
    )
  }

  return (
    <Box
      display='flex'
      key={note.noteID}
      mb={2}
      px={'24px'}
      onClick={() => onClickNote(note.noteID)}
      sx={{ cursor: 'pointer' }}
    >
      <UserAvatar
        name={note.authProfileName}
        url={note.authProfileThumbURL}
        width={61.74}
        height={44.8}
      />
      <DMNoteWrapper className={isSelected ? 'active' : ''}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='labelLarge'>{isMyNote ? 'You' : note.authProfileName}</Typography>
          <ActionMenu
            id='topic-item-menu'
            parent={<MoreVert sx={{ color: colors.darkGrey }} />}
            actions={topicActions}
            direction='row'
          />
        </Box>
        {renderNoteText(note.noteText)}
        {note.egoList.length > 0 && renderPhotos()}
        <Box
          display='flex'
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={1}
        >
          <Box display='flex' flexDirection={'row'} alignItems={'center'} gap={2}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                onClickLike(note)
              }}
            >
              <Typography
                display='flex'
                alignItems={'center'}
                color={colors.themeBlack}
                gap={'6px'}
              >
                <SVG
                  src={note.isThumbsUp ? SVGS.LikeFilledIcon : SVGS.LikeOutlineIcon}
                  width={15}
                  height={15}
                  color={note.isThumbsUp ? colors.yellow : colors.themePurpleTextColor}
                />
                {note.thumbsUp}
              </Typography>
            </Box>

            <Box>
              <Typography
                display='flex'
                alignItems={'center'}
                color={colors.themeBlack}
                gap={'6px'}
              >
                <SVG
                  src={SVGS.CommentIcon}
                  width={17}
                  height={17}
                  color={colors.themePurpleTextColor}
                />
                {note.comments}
              </Typography>
            </Box>

            <Box>
              <Typography
                display='flex'
                alignItems={'center'}
                color={colors.themeBlack}
                gap={'6px'}
              >
                <SVG
                  src={SVGS.ReplyIcon}
                  width={17}
                  height={17}
                  color={colors.themePurpleTextColor}
                />
                {'Reply'}
              </Typography>
            </Box>
          </Box>

          <Typography variant='ps' flex={1} textAlign='right' color={colors.lightText}>
            <ReactTimeago date={convertToGMT(note.dateCreated)} />
          </Typography>
        </Box>
      </DMNoteWrapper>
    </Box>
  )
}

export default DMNoteCard
