import { createTheme } from '@mui/material/styles'
import colors from './colors'

declare module '@mui/material/styles' {
  interface Palette {
    customText: Palette['primary']
    brand: Palette['primary']
  }

  interface PaletteOptions {
    customText?: PaletteOptions['primary']
    brand?: PaletteOptions['primary']
  }

  interface TypographyVariants {
    headingLarge: React.CSSProperties
    headingMedium: React.CSSProperties
    headingSmall: React.CSSProperties
    description: React.CSSProperties
    large: React.CSSProperties
    p: React.CSSProperties
    ps: React.CSSProperties
    pt: React.CSSProperties
    label: React.CSSProperties
    labelLarge: React.CSSProperties
    labelSmall: React.CSSProperties
    fontWeightSemiBold: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headingLarge?: React.CSSProperties
    headingMedium?: React.CSSProperties
    headingSmall?: React.CSSProperties
    description?: React.CSSProperties
    large?: React.CSSProperties
    p: React.CSSProperties
    ps: React.CSSProperties
    pt: React.CSSProperties
    label: React.CSSProperties
    labelLarge: React.CSSProperties
    labelSmall: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headingLarge: true
    headingMedium: true
    headingSmall: true
    description: true
    large: true
    p: true
    ps: true
    pt: true
    label: true
    labelLarge: true
    labelSmall: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true
  }
}

const ButtonStyleContained = {
  backgroundColor: colors.meshdomBlue,
  color: colors.white,
  ':disabled': {
    backgroundColor: colors.grey,
    color: colors.white,
  },
  ':hover': {
    backgroundColor: colors.buttonHover,
  },
}

const ButtonStyleOutlined = {
  borderColor: colors.meshdomBlue,
  color: colors.meshdomBlue,
  ':disabled': {
    borderColor: colors.grey,
    color: colors.grey,
  },
  ':hover': {
    borderColor: colors.buttonHover,
    color: colors.buttonHover,
  },
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.meshdomBlue,
      dark: colors.meshdomBlue,
      light: colors.meshdomBlueLight,
    },
    grey: {
      400: colors.veryDarkGray,
      500: colors.darkGrey,
      600: colors.grey,
      700: colors.lightGrey,
      800: colors.veryLightGrey,
    },
    customText: {
      main: colors.text,
      dark: colors.headingText,
      light: colors.lightText,
    },
    brand: {
      main: colors.meshdomBlue,
      light: colors.meshdomBlue,
    },
    action: {
      active: colors.meshdomBlue,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    headingLarge: {
      fontFamily: 'Inter-Regular',
      fontWeight: 800,
      fontSize: 50,
      lineHeight: '50px',
      color: colors.headingText,
    },
    headingMedium: {
      fontFamily: 'Inter-Bold',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '40px',
      color: colors.headingText,
    },

    headingSmall: {
      fontFamily: 'Inter-Medium',
      fontWeight: 400,
      fontSize: 25,
      lineHeight: '40px',
      color: colors.headingText,
    },
    description: {
      fontFamily: 'Inter-Regular',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '25px',
      color: colors.headingText,
    },

    large: {
      fontFamily: 'Inter-Bold',
      fontSize: 20,
      color: colors.headingText,
    },
    h1: {
      fontFamily: 'Inter-SemiBold',
      fontSize: 18,
      color: colors.headingText,
    },
    h2: {
      fontFamily: 'Inter-SemiBold',
      fontSize: 15,
      lineHeight: '22px',
      color: colors.headingText,
    },
    h3: {
      fontFamily: 'Inter-SemiBold',
      fontSize: 14,
      color: colors.headingText,
    },
    p: {
      fontFamily: 'Inter-Regular',
      fontSize: 13,
      color: colors.text,
      lineHeight: '22px',
      fontWeight: 400,
    },
    ps: {
      fontFamily: 'Inter',
      fontSize: 12,
      color: colors.text,
      lineHeight: '17px',
      fontWeight: 400,
    },
    pt: {
      fontFamily: 'Inter',
      fontSize: 11,
      color: colors.text,
      lineHeight: '16px',
      fontWeight: 300,
    },
    label: {
      fontFamily: 'Inter-SemiBold',
      fontSize: 12,
      color: colors.headingText,
      lineHeight: '15px',
    },
    labelLarge: {
      fontFamily: 'Inter-SemiBold',
      fontSize: 13,
      color: colors.headingText,
      lineHeight: '16px',
    },
    labelSmall: {
      fontFamily: 'Inter-Medium',
      fontSize: 12,
      color: colors.headingText,
      lineHeight: '15px',
    },

    fontFamily: 'Inter-Regular',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'p',
        variantMapping: {
          headingLarge: 'h1',
          headingMedium: 'h2',
          headingSmall: 'h3',
          description: 'p',
          large: 'h1',
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          p: 'p',
          ps: 'p',
          pt: 'p',
          label: 'span',
          labelLarge: 'span',
          labelSmall: 'span',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: colors.divider,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained', size: 'large' },
          style: {
            ...ButtonStyleContained,
            fontFamily: 'Inter-Bold',
            height: 50,
          },
        },
        {
          props: { variant: 'contained', size: 'medium' },
          style: {
            ...ButtonStyleContained,
            fontFamily: 'Inter-SemiBold',
            height: 40,
          },
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: {
            ...ButtonStyleContained,
            fontFamily: 'Inter-SemiBold',
            height: 34,
          },
        },
        {
          props: { variant: 'contained', size: 'tiny' },
          style: {
            ...ButtonStyleContained,
            fontFamily: 'Inter-Bold',
            height: 30,
          },
        },
        {
          props: { variant: 'outlined', size: 'large' },
          style: {
            ...ButtonStyleOutlined,
            fontFamily: 'Inter-Bold',
            height: 50,
          },
        },
        {
          props: { variant: 'outlined', size: 'medium' },
          style: {
            ...ButtonStyleOutlined,
            fontFamily: 'Inter-SemiBold',
            height: 40,
          },
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: {
            ...ButtonStyleOutlined,
            fontFamily: 'Inter-SemiBold',
            height: 34,
          },
        },
        {
          props: { variant: 'outlined', size: 'tiny' },
          style: {
            ...ButtonStyleOutlined,
            fontFamily: 'Inter-Bold',
            height: 30,
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 50,
          border: `1px solid ${colors.lightGrey}`,
          borderRadius: 100,
          overflow: 'hidden',
          ':before': {
            borderBottom: 'none !important',
          },
          ':after': {
            borderBottom: 'none !important',
          },
          ':focus-within': {
            border: `1px solid ${colors.meshdomBlue}`,
          },
        },
        input: {
          padding: 15,
          fontSize: 15,
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: 40,
          },
        },
        {
          props: { error: true },
          style: {
            border: `1px solid ${colors.error}`,
          },
        },
      ],
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          width: 60,
          '& svg': {
            color: colors.darkGrey,
          },
        },
        positionStart: {
          width: 50,
          display: 'flex',
          justifyContent: 'center',
          marginRight: -10,
          '& svg': {
            color: colors.darkGrey,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: colors.veryDarkGray,
        },
        arrow: {
          '&:before': {
            background: colors.veryDarkGray,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecorationLine: 'none',
        },
      },
    },
    MuiRadio: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: colors.yellow,
            '&.Mui-checked': {
              color: colors.yellow,
            },
          },
        },
      ],
    },
  },
})
