import React from 'react'
import PropTypes from 'prop-types'
import { COLLAGE_ITEMS_LIMIT, layoutConfigMap } from './Config'
import { Box, Typography } from '@mui/material'
import { AlbumPhotoItem } from '@/pages/panels/__styled'

interface AlbumCollageProps {
  rowHeight: number
  links: string[]
  limit?: number
  applyLimit?: boolean
  showLastItemOverlay?: boolean
}

const AlbumCollage = ({
  links,
  rowHeight,
  applyLimit = false,
  limit = COLLAGE_ITEMS_LIMIT,
  showLastItemOverlay = true,
}: AlbumCollageProps) => {
  const linksToShow = applyLimit ? links.slice(0, limit) : links.slice(0, 25)
  const { matrix, direction, heightMultiplier } = layoutConfigMap[linksToShow.length]

  const height = rowHeight * heightMultiplier

  const renderOverlay = (text: string) => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          backgroundColor: '#00000080',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography color={'white'} fontSize={18} fontWeight={700}>
          {text}
        </Typography>
      </Box>
    )
  }

  const renderMatrix = () => {
    const sectionDirection = direction === 'row' ? 'column' : 'row'
    let currentImageIndex = 0

    return matrix.map((element, index, array) => {
      const startIndex = index
        ? array.slice(0, index).reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0

      const photosGrid = linksToShow.slice(startIndex, startIndex + element).map((imageURL, i) => {
        currentImageIndex = currentImageIndex + 1

        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              backgroundImage: `url(${imageURL})`,
              backgroundSize: 'cover',
            }}
            key={i}
          >
            {showLastItemOverlay &&
              currentImageIndex == linksToShow.length &&
              linksToShow.length < links.length &&
              renderOverlay(`+${links.length - linksToShow.length}`)}
          </Box>
        )
      })

      return (
        <Box
          key={`${index}`}
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: sectionDirection,
            gap: '4px',
            borderColor: 'transparent',
          }}
        >
          {photosGrid}
        </Box>
      )
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: height,
        flexDirection: direction,
        borderRadius: '5px',
        overflow: 'hidden',
        gap: '4px',
      }}
    >
      {renderMatrix()}
    </Box>
  )
}
export default AlbumCollage
