import { ISearchSlice, ISearchStore, Topic } from '@/types'

const initialState: ISearchStore = {
  searchKeyword: '',
  selectedTabIndex: 0,
  topicSearchResult: {},
}

export const createSearchSlice = (set: any): ISearchSlice => ({
  search: initialState,

  setTopicSearchResult: (data: Record<string, Topic>) =>
    set((state: any) => {
      return {
        search: {
          ...state.search,
          topicSearchResult: { ...data },
        },
      }
    }),

  resetTopicSearchResult: () =>
    set((state: any) => ({
      search: {
        ...state.search,
        topicSearchResult: {},
      },
    })),

  updateTopicSearchResult: (data: Record<string, Topic>) =>
    set((state: any) => {
      const prev = state.search.topicList
      Object.keys(data).forEach((key) => {
        prev[key] = {
          ...(prev[key] || {}),
          ...data[key],
        }
      })
      return {
        search: {
          ...state.search,
          topicList: { ...prev },
        },
      }
    }),

  setSearchKeyword: (keyword) =>
    set((state: any) => {
      return {
        search: {
          ...state.search,
          searchKeyword: keyword,
        },
      }
    }),

  setSelectedTabIndex: (index) =>
    set((state: any) => {
      return {
        search: {
          ...state.search,
          selectedTabIndex: index,
        },
      }
    }),
})

export default createSearchSlice
