/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { ReactQueryKeys } from '@/types'

export const useResendSignupCode = (user: string) => {
  return useMutation({
    mutationKey: [ReactQueryKeys.RESEND_SIGNUP_CODE, user],
    mutationFn: async (): Promise<boolean> => {
      await Auth.resendSignUp(user)
      return true
    },
  })
}
