import { UserAvatar } from "@/components"
import { EgoBasicInfo } from "@/types"
import { UserBasicInfoItems } from "@/utils/constants"
import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import SVG from 'react-inlinesvg';
import colors from "@/styles/colors"
import dayjs from "dayjs"
import { ChevronLeft, Person3Outlined } from "@mui/icons-material"

interface Props {
  user: EgoBasicInfo
  onClickBack: () => void;
  onClickComment?: () => void;
}

export default function UserContent({ user, onClickBack, onClickComment }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const onClickViewProfile = () => {
    navigate(`/profile?id=${user.userID}`);
  }

  return (
    <Box p={2} display="flex" flexDirection={'column'} height="100%" boxSizing={'border-box'}>
      {lessThanLarge && (
        <Box display="flex" justifyContent={'flex-end'} alignItems="center">
          {lessThanMedium && (
            <Box ml={-2} flex={1}>
              <Button variant="text" fullWidth={false} onClick={onClickBack} sx={{ padding: 0 }}>
                <ChevronLeft />
                <Typography variant="p">Back</Typography>
              </Button>
            </Box>
          )}
          <IconButton onClick={onClickComment}>
            <Person3Outlined />
          </IconButton>
        </Box>
      )}
      <Box display="flex" mb={2} >
        <UserAvatar size={66} name={`${user.firstName} ${user.lastName}`} url={user.profilePicThumbURL} />
        <Box flex={1} px={1} overflow="hidden">
          <Typography variant="h1" className="username">{`${user.firstName} ${user.lastName}`}</Typography>
          <Typography className="motto">{user.motto || 'Be the oddly you'}</Typography>
        </Box>
        {!lessThanSmall && <Button variant="outlined" onClick={onClickViewProfile}>View Profile</Button>}
      </Box>
      {lessThanSmall && <Button variant="outlined" sx={{ marginBottom: 2 }} onClick={onClickViewProfile}>View Profile</Button>}
      <Box overflow="auto" flex={1}>
        <Grid container spacing={2}>
          {UserBasicInfoItems.map(item => (
            <Grid item xs={6} key={item.key}>
              <Box
                display="flex"
                flexDirection={'column'}
                alignItems="center"
                justifyContent={'center'}
                borderRadius={2}
                bgcolor={colors.lightGrey}
                height={239}
              >
                <SVG src={item.icon} color={colors.yellow} width={30} height={30} />
                <Typography mt={1}>{item.label}</Typography>
                <Typography variant="labelLarge">
                  {item.key === 'joinDate' ?
                    dayjs(user[item.key] as string).format('MMM YYYY')
                    : user[item.key as keyof EgoBasicInfo]
                  }
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}