import { getAvatarColorWithName, isValidAvatarUrl } from '@/utils/helper'
import { Box, Typography, useTheme } from '@mui/material'
import { useMemo } from 'react'

interface AvatarProps {
  url?: string
  name: string
  size?: number
  width?: number
  height?: number
  borderRadius?: number
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export function UserAvatar({
  url,
  name,
  size = 36,
  borderRadius = 5,
  className = 'avatar',
  width,
  height,
  onClick = () => {},
}: AvatarProps) {
  const { palette } = useTheme()
  const getName = useMemo(() => {
    const [firstName = '', lastName = ''] = name.split(' ')
    return `${firstName.toUpperCase()[0] || ''}${lastName.toUpperCase()[0] || ''}`
  }, [name])

  if (isValidAvatarUrl(url || '')) {
    return (
      <img
        className={className}
        src={url}
        style={{
          width: width || size,
          height: height || size,
          borderRadius,
          cursor: 'pointer',
          backgroundColor: palette.brand.main,
          objectFit: 'cover',
        }}
        onClick={onClick}
      />
    )
  } else {
    return (
      <Box
        className={className}
        width={width || size}
        height={height || size}
        borderRadius={`${borderRadius}px`}
        display='flex'
        justifyContent={'center'}
        alignItems={'center'}
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
        bgcolor={getAvatarColorWithName(name)}
      >
        <Typography fontSize={size / 3} fontWeight={600} color='white'>
          {getName}
        </Typography>
      </Box>
    )
  }
}
