import { TopicTypes } from '@/utils/constants'
import { Box, capitalize, Typography } from '@mui/material'
import { useMemo } from 'react'

interface Props {
  type: string
  border?: string
  textColor?: string
}

export function TagTopic({ type, border = '1px solid #E7EDF3', textColor = '#525E9C' }: Props) {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      p={'6px 15px'}
      border={border}
      borderRadius={'50px'}
    >
      {type === 'album'}
      <Typography
        fontSize={'10px'}
        fontFamily={'Inter-Medium'}
        lineHeight={'12px'}
        sx={{ color: textColor }}
      >
        {capitalize(type)}
      </Typography>
    </Box>
  )
}
