import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Visibility from '@mui/icons-material/VisibilityOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import SVG from 'react-inlinesvg'

import { AuthFormBox } from './__styled'
import { Input } from '@/components'
import { validateEmail, validatePhoneNumber } from '@/utils/helper'
import { useLogin, useUpdateOnlineStatus, useLoginCognito } from '@/hooks/api/auth'
import { useAuth } from '@/hooks/common/useAuth'
import { toast } from 'react-hot-toast'
import { useTheme } from '@mui/material'
import { SVGS } from '@/assets/svgs'

export default function Login() {
  const [user, setUser] = useState('') // Email or Phone number
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [editing, setEditing] = useState(false)
  const theme = useTheme()

  const login = useLogin(user, password)
  const loginCognito = useLoginCognito(login.data || '')
  const updateOnlineStatus = useUpdateOnlineStatus(1)
  const { login: localLogin } = useAuth()

  useEffect(() => {
    if (login.isSuccess) {
      loginCognito.mutate()
    }
  }, [login.isSuccess, login.data])

  useEffect(() => {
    if (loginCognito.isSuccess) {
      if (loginCognito.data.accountStatus === '0') {
        toast.error('User is inactive. Activate user and retry.')
        return
      } else if (loginCognito.data.errorCat === 4) {
        toast.error('User is deleted. Create new one.')
        return
      }
      updateOnlineStatus.mutate()
      const { requestID, userID, userName } = loginCognito.data
      localLogin({ requestID, userID, userName })
    }
  }, [loginCognito.isSuccess, loginCognito.data])

  const formError = useMemo(() => {
    const error: Record<string, string> = {}
    if (!user.length) {
      error.user = 'Email or Phone Number is required'
    } else if (!validateEmail(user) && !validatePhoneNumber(user)) {
      error.user = 'Enter Valid Phone/Email'
    }
    return error
  }, [user, password])

  const onChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser(e.target.value)
    setEditing(true)
  }

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const onSubmitLogin = () => {
    if (formError.user || !password) return
    login.mutate()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onSubmitLogin()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      {/* <AuthFormLogo>
        <SVG src={SVGS.wLogo} />
      </AuthFormLogo> */}
      <AuthFormBox>
        <Typography fontWeight={400} fontSize={28}>
          {'Welcome Back!'}
        </Typography>
        <Typography
          variant='p'
          fontSize={15}
          mt={'12px'}
          style={{ whiteSpace: 'pre-line' }}
          mr={'80px'}
          sx={{
            [theme.breakpoints.down('md')]: {
              mr: '40px',
            },
          }}
        >
          {'Login to create pattern design intricate quilt patterns with our intuitive tools'}
        </Typography>
        <Input
          value={user}
          placeholder='Email or Phone Number'
          onChange={onChangeUser}
          errorText={formError.user}
          isError={editing && !!formError.user}
          name='email'
        />
        <Input
          value={password}
          placeholder='Password*'
          onChange={onChangePassword}
          rightIcon={<SVG src={showPassword ? SVGS.VisibilityOffIcon : SVGS.VisibilityIcon} />}
          onToggleIcon={() => setShowPassword(!showPassword)}
          type={showPassword ? 'text' : 'password'}
          errorText={formError.password}
          isError={!!formError.password}
          name='password'
          onKeyDown={handleKeyDown}
        />
        {!login.isLoading && !!login.error && (
          <Box mt={1}>
            <Typography variant='labelLarge' color={'error.main'}>
              Incorrect username or password.
            </Typography>
          </Box>
        )}
        <Box mt={1} display='flex' justifyContent='flex-end'>
          <Link to='/forgot-password'>
            <Typography variant='labelLarge' color={'brand.light'}>
              Forgot password?
            </Typography>
          </Link>
        </Box>
        <LoadingButton
          variant='contained'
          size='large'
          fullWidth
          className='loginButton'
          disabled={!!formError.user || !password}
          onClick={onSubmitLogin}
          loading={loginCognito.isLoading || login.isLoading}
        >
          Sign in
        </LoadingButton>
        <Box mt={2} display='flex' justifyContent='center' alignItems={'center'} gap={'3px'}>
          <Typography
            variant='labelLarge'
            fontSize={14}
            color={'customText.main'}
            fontFamily={'Inter'}
          >
            {'Don’t have an account?'}
          </Typography>
          <Link to='/signup'>
            <Typography variant='labelLarge' fontSize={14} color={'brand.main'}>
              {'Sign Up'}
            </Typography>
          </Link>
        </Box>
      </AuthFormBox>

      {/* <Typography
        variant='p'
        textAlign={'center'}
        position='absolute'
        mb={4.5}
        bottom={0}
        left={0}
        right={0}
      >
        {getCopyRightText()}
      </Typography> */}
    </Box>
  )
}
