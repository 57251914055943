import { useDeactivateAccount, useDeleteAccount } from '@/hooks/api/dashboard'
import { useAuth, useLocalStorage } from '@/hooks/common'
import colors from '@/styles/colors'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { MyProfileDetailsContainer } from '../__styled'
import AccountManagement from './AccountManagement'
import ProfilePrivacy from './Privacy'
import Subscription from './Subscription'

export default function MyProfileDetails() {
  const [ewUser] = useLocalStorage('ewUser', null)
  const deactivate = useDeactivateAccount(ewUser?.userID)
  const deleteAccount = useDeleteAccount(ewUser?.userID)
  const { logout } = useAuth()

  useEffect(() => {
    if (deactivate.isSuccess || deleteAccount.isSuccess) {
      logout()
    }
  }, [deactivate.isSuccess, deleteAccount.isSuccess])

  const onSubmitDeactivateAccount = () => {
    toast.promise(deactivate.mutateAsync(), {
      loading: 'Deactivating your account',
      success: <Typography variant='p'>Profile has been deactivated.</Typography>,
      error: <Typography variant='p'>Failed to deactivate your account.</Typography>,
    })
  }

  const onSubmitDeleteAccount = () => {
    toast.promise(deleteAccount.mutateAsync(), {
      loading: 'Deleting your account',
      success: <Typography variant='p'>Profile has been deleted.</Typography>,
      error: <Typography variant='p'>Failed to delete your account.</Typography>,
    })
  }

  return (
    <MyProfileDetailsContainer bgcolor={colors.veryLightGrey} p={3}>
      <Subscription />
      {/* <ProfilePrivacy /> */}
      <AccountManagement
        onSubmitDeactivateAccount={onSubmitDeactivateAccount}
        onSubmitDeleteAccount={onSubmitDeleteAccount}
      />
    </MyProfileDetailsContainer>
  )
}
