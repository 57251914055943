import colors from "@/styles/colors";
import { Box, Button, Grid, Typography } from "@mui/material";

export default function Subscription() {
  return (
    <Box borderRadius={2} bgcolor="white" p={3}>
      <Typography variant="h2" mb={1}>Purchase</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} xl={2} mt={1}>
          <Box borderRight={`1px solid ${colors.lightGrey}`}>
            <Typography variant='ps'>Current Suscription</Typography>
            <Typography variant='large' color={colors.blue}>Go Premium</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} xl={2} mt={1}>
          <Box borderRight={`1px solid ${colors.lightGrey}`} className="subscription-date">
            <Typography variant='ps'>Subscription Date</Typography>
            <Typography variant='labelSmall'>20 December 2021</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} xl={2} mt={1}>
          <Box borderRight={`1px solid ${colors.lightGrey}`}>
            <Typography variant='ps'>Suscription Period</Typography>
            <Typography variant='labelSmall'>1 Year</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} xl={2} mt={1}>
          <Box>
            <Typography variant='ps'>Next Renewal</Typography>
            <Typography variant='labelSmall'>19 December 2022</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} xl={2} mt={1}></Grid>
        <Grid item xs={6} xl={2} my={1} display="flex" justifyContent={'flex-end'}>
          <Button variant="contained">Subscribed</Button>
        </Grid>
      </Grid>
    </Box>
  )
}