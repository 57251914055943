import { useGetContainerDetails, useGetContainers } from '@/hooks/api/dashboard'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { EgoObject, IPatternAsset, IPatternAssetPrint } from '@/types'
import { Assets } from '@/utils/pattern-assets'
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Typography } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { addUploadAssets } from '../renderer/main'
import { AssetItem, PatternAssetList, PrintContainer, PrintTabButton } from '../__styled'

interface Props {
  selectedId: string
  onSelect: (id: number | string) => void
}

export default function PrintTabView({ selectedId, onSelect }: Props) {
  const [printTabIndex, setPrintTabIndex] = useState(0)
  const [page, setPage] = useState(1)
  const [endReached, setEndReached] = useState(false)
  const [toggleStatus, setToggleStatus] = useState<Record<string, boolean>>({})
  const [containerData, setContainerData] = useState<Record<string, EgoObject[]>>({})
  const [loadingContainerID, setLoadingContainerID] = useState('')

  const getContainers = useGetContainers()
  const getContainerDetails = useGetContainerDetails()
  const [myContainers] = useAppStore((state) => [state.user.myContainers], shallow)

  useEffect(() => {
    getContainers.mutate(1)
  }, [])

  useEffect(() => {
    if (getContainerDetails.isSuccess) {
      setContainerData((prev) => ({
        ...prev,
        [loadingContainerID]: getContainerDetails.data.egoObjects || [],
      }))
      addUploadAssets(getContainerDetails.data.egoObjects)
    }
  }, [getContainerDetails.isSuccess, loadingContainerID])

  useEffect(() => {
    if (getContainers.isSuccess) {
      if (getContainers.data.length < 20) {
        setEndReached(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContainers.isSuccess])

  const onClickShowMore = () => {
    if (endReached || getContainers.isLoading || !myContainers.length) {
      return
    }
    getContainers.mutate(page + 1)
    setPage(page + 1)
  }

  const onToggleContainer = (containerID: string) => {
    if (!containerData[containerID]) {
      setLoadingContainerID(containerID)
      getContainerDetails.mutate(containerID)
    }
    setToggleStatus({
      ...toggleStatus,
      [containerID]: !toggleStatus[containerID],
    })
  }

  return (
    <PrintContainer>
      {/* <Box display="flex" width="100%">
        <PrintTabButton
          variant="text"
          onClick={() => setPrintTabIndex(0)}
          className={printTabIndex === 0 ? 'active' : ''}
        >
          Standard
        </PrintTabButton>
        <PrintTabButton
          variant="text"
          onClick={() => setPrintTabIndex(1)}
          className={printTabIndex === 1 ? 'active' : ''}
        >
          Uploads
        </PrintTabButton>
      </Box> */}
      {printTabIndex === 0 && (
        <PatternAssetList category={'print'}>
          {Assets.prints.standard.map((item: IPatternAsset) => {
            const asset = item as IPatternAssetPrint
            return (
              <AssetItem
                className={selectedId === String(asset.id) ? 'active' : ''}
                onClick={() => onSelect(asset.id)}
                key={asset.id}
              >
                <img
                  style={{ width: '40px', height: '40px', borderRadius: 8 }}
                  src={`/assets/patterns/${asset.thumb}`}
                  alt={item.name}
                />
              </AssetItem>
            )
          })}
        </PatternAssetList>
      )}
      {printTabIndex === 1 && (
        <Box flex={1} sx={{ overflowY: 'auto' }}>
          {myContainers?.map((container) => {
            if (container.photoSnippet.length === 0) return null
            const cID = container.containerID
            return (
              <Box key={cID}>
                <Box
                  onClick={() => onToggleContainer(cID)}
                  display='flex'
                  alignItems='center'
                  py={2}
                  mx={2}
                  borderBottom={`1px solid white`}
                  sx={{ cursor: 'pointer' }}
                >
                  <CalendarIcon sx={{ color: colors.white }} />
                  <Typography pl={1} flex={1} color={colors.white}>
                    {dayjs(container.datePurchased).format('MMMM YYYY')}
                  </Typography>
                  <Typography variant='ps' color={colors.white}>
                    {container.photoCount} photo{Number(container.photoCount) > 1 ? 's' : ''}
                  </Typography>
                  {!toggleStatus[cID] && <KeyboardArrowDownOutlined sx={{ color: colors.white }} />}
                  {toggleStatus[cID] && <KeyboardArrowUpOutlined sx={{ color: colors.white }} />}
                </Box>
                {toggleStatus[cID] && (
                  <PatternAssetList category={'print'}>
                    {loadingContainerID === cID && getContainerDetails.isLoading && (
                      <CircularProgress />
                    )}
                    {containerData[cID]?.map((ego: EgoObject) => (
                      <AssetItem
                        className={selectedId === `egoID-${ego.egoID}` ? 'active' : ''}
                        onClick={() => onSelect(`egoID-${ego.egoID}`)}
                        key={ego.egoID}
                      >
                        <img
                          style={{ width: '120px', height: '120px', borderRadius: 8 }}
                          src={ego.egoAlbumURL}
                          alt={ego.egoName}
                        />
                      </AssetItem>
                    ))}
                  </PatternAssetList>
                )}
              </Box>
            )
          })}
          {(page > 1 || !getContainers.isLoading) && (
            <Box m={3} display='flex' justifyContent={'center'}>
              <LoadingButton
                variant='text'
                onClick={onClickShowMore}
                loading={getContainers.isLoading && page > 1}
                disabled={endReached}
              >
                {endReached ? 'No more results' : 'Show More'}
              </LoadingButton>
            </Box>
          )}
        </Box>
      )}
    </PrintContainer>
  )
}
