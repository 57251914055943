import colors from '@/styles/colors'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const UserDetailsContent = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .username': {
    overflowWrap: 'break-word',
  },
  '& .motto': {
    overflowWrap: 'break-word',
  },
  '& .preview-image': {
    minWidth: 130,
    height: 130,
    marginRight: 10,
    borderRadius: 8,
    objectFit: 'cover',
  },
  '& .view-tab': {
    borderBottom: `1px solid ${colors.divider}`,
    borderTop: `1px solid ${colors.divider}`,
  },
  '& .accept-icon-btn': {
    backgroundColor: colors.green,
    color: 'white',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: `${colors.green}AA`,
    },
  },
  '& .reject-icon-btn': {
    backgroundColor: `${colors.error}AA`,
    color: colors.error,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: `${colors.error}66`,
    },
  },
})
