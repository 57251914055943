import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  AppBar,
  Typography,
  Toolbar,
  Button,
  styled,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAuth, useBrowserQuery } from '@/hooks/common'
import { useEffect, useState } from 'react'
import { AxiosProvider } from '@/context/Axios'
import GuestLandingHeader from './GuestLandingHeader'
import { IMAGES } from '@/assets/images'
import { SVGS } from '@/assets/svgs'
import SVG from 'react-inlinesvg'
import { GuestTabs, HOME_PATH } from '@/utils/constants'
import './styles.css'
import colors from '@/styles/colors'
import PatternItemCard from './PatternItemCard'
import Footer from '@/components/Footer'
import { RoundedFilledButton } from '@/components/StyledComponents'
import AuthDialog from '../auth/AuthDialog'

const useStyles = () => ({
  square: {
    position: 'relative',
    '&::before': {
      display: 'block',
      content: "''",
      paddingBottom: '100%',
    },
  },
})

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: colors.themeBlack,
  fontFamily: 'Inter-Regular',
  fontSize: 14,
  fontWeight: '600',
  borderRadius: 50,

  alignItems: 'center',
  padding: '10px 40px',
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
    color: 'white',
    fontSize: 14,
    fontWeight: '700',
  },
}))

export default function GuestLandingLayout() {
  const classes = useStyles()
  const { user } = useAuth()
  const navigate = useNavigate()
  const query = useBrowserQuery()
  const location = useLocation()
  const authStep = query.get('step') || ''
  const showAuthDialog = location.pathname == '/auth' && authStep.length > 0
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'))
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  useEffect(() => {
    if (user?.userID) {
      navigate(HOME_PATH)
    }
  }, [user?.userID])

  const onClickLogin = () => {
    navigate('/login')
  }

  return (
    <AxiosProvider>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <AppBar elevation={0} position='fixed' sx={{ width: '100%' }}>
          <GuestLandingHeader
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        </AppBar>
        <Toolbar />
        <Box component='main' sx={{ width: '100%', bgcolor: 'background.default' }}>
          <Box
            display='flex'
            flexDirection='row'
            width={'100%'}
            sx={{
              transition: 'margin 0.3s',
              padding: '20px 40px',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'linear-gradient(180deg, rgba(0,0,0,0.02),rgba(255,255,255,0));',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
              }}
            >
              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
                <Typography variant='headingLarge'>{`Stitching Dreams`}</Typography>
                <Typography variant='headingSmall' mt={1}>
                  Your Destination for Unique Quilts & Patterns
                </Typography>
                <Typography variant='description' mt={1}>
                  Discover a world of creativity with our curated collection of quilt patterns. From
                  traditional designs to modern twists, find the perfect pattern to inspire your
                  next masterpiece. Start stitching your dreams today!
                </Typography>
                {lessThanSmall && (
                  <Box display='flex' sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <SVG src={SVGS.Quilt} color='white' width={'100%'} height={'100%'} />
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <RoundedFilledButton> {"Let's Get Started"} </RoundedFilledButton>
                </Box>
              </Box>
              {!lessThanSmall && (
                <Box
                  display='flex'
                  flexDirection='row'
                  sx={{ justifyContent: 'flex-end', flex: 1 }}
                >
                  <SVG src={SVGS.Quilt} color='white' />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection={lessThanSmall ? 'column' : 'row'}
            width={'100%'}
            sx={{
              padding: '50px 32px',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'linear-gradient(0deg, rgba(22,23,25,1),rgba(29,30,33,1));',
            }}
          >
            <Box className='black-bar-item'>
              <SVG src={SVGS.DesignPatterns} color='white' />
              <Typography variant='description' color={'white'}>
                Design patterns efficiently by streamline design platform
              </Typography>
            </Box>
            <div className={lessThanSmall ? 'divider-line-hr' : 'divider-line'} />
            <Box className='black-bar-item'>
              <SVG src={SVGS.DesignMeasure} color='white' />
              <Typography variant='description' color={'white'}>
                Features that ensure accurate measurements & consistent Design
              </Typography>
            </Box>
            <div className={lessThanSmall ? 'divider-line-hr' : 'divider-line'} />

            <Box className='black-bar-item'>
              <SVG src={SVGS.DesignMultiple} color='white' />
              <Typography variant='description' color={'white'}>
                Find multiple design and edit that created by other user and edit it
              </Typography>
            </Box>
          </Box>
          <div className='gradient-container'>
            <Box
              display='flex'
              width={'100%'}
              flexDirection={'column'}
              sx={{
                padding: '50px 0px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className='ellipse-blue-blur' />
              <Typography variant='headingMedium' mt={1}>
                How it work?
              </Typography>

              <div className='how-works-container'>
                <div className='how-it-works-item'>
                  <div className='how-works-circle'>
                    <Typography variant='h1' color={'white'}>
                      1
                    </Typography>
                  </div>
                  <Typography variant='h1' color={colors.themeBlack}>
                    Register
                  </Typography>
                  <Typography variant='description' color={colors.themeBlack} textAlign={'center'}>
                    Register your self on Meshdom to get start your journey
                  </Typography>
                </div>
                <div className='dashed-line-div' />
                <div className='how-it-works-item'>
                  <div className='how-works-circle'>
                    <Typography variant='h1' color={'white'}>
                      2
                    </Typography>
                  </div>

                  <Typography variant='h1' color={colors.themeBlack}>
                    Subscribe
                  </Typography>
                  <Typography variant='description' color={colors.themeBlack} textAlign={'center'}>
                    You can use Meshdom app to create quilts and patterns
                  </Typography>
                </div>

                <div className='dashed-line-div' />
                <div className='how-it-works-item'>
                  <div className='how-works-circle'>
                    <Typography variant='h1' color={'white'}>
                      3
                    </Typography>
                  </div>
                  <Typography variant='h1' color={colors.themeBlack}>
                    Start Design
                  </Typography>
                  <Typography variant='description' color={colors.themeBlack} textAlign={'center'}>
                    Register your self on Meshdom to get start your journey
                  </Typography>
                </div>
              </div>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              sx={{
                transition: 'margin 0.3s',
                alignItems: 'center',
                justifyContent: 'space-between',
                pl: '10%',
                pr: lessThanSmall ? '10%' : null,
                gap: '10%',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant='headingMedium'>
                  Give Life to Your Ideas and Creative Vision with Our Design Platform
                </Typography>
                <Typography variant='description' mt={1}>
                  Quiltify offers a dynamic platform where your imagination becomes reality.
                  Seamlessly design intricate quilt patterns with our intuitive tools, whether
                  you're a seasoned quilter or just starting out. Explore endless possibilities,
                  experiment with colors and shapes, and bring your unique vision to life with ease.
                  Join our vibrant community of quilting enthusiasts and elevate your craft to new
                  heights. Start designing your masterpiece today with Quiltify.
                </Typography>
                {lessThanSmall && (
                  <Box flex={1} display='flex' sx={{ mt: '16px', justifyContent: 'flex-end' }}>
                    <img src={IMAGES.guestPatterns} width={'100%'} height={'100%'} />
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <RoundedFilledButton onClick={onClickLogin}>
                    {'Login to Create'}
                  </RoundedFilledButton>
                </Box>
              </Box>

              {!lessThanSmall && (
                <Box display='flex' sx={{ width: '100%', justifyContent: 'flex-end' }}>
                  <img src={IMAGES.guestPatterns} width={'100%'} height={'100%'} />
                </Box>
              )}
            </Box>
            <Box className='popular-creations'>
              <Box className='popular-creations-inner'>
                <Typography variant='headingMedium' mt={1}>
                  Popular Creations
                </Typography>
                <span>From Classic to Contemporary : Explore Our Most Popular Creations!</span>
                <Box sx={{ display: 'flex', gap: '12px', mt: '12px' }}>
                  {GuestTabs.map((item, index) => (
                    <Box key={`${index}`}>
                      {index == selectedTabIndex ? (
                        <RoundedFilledButton onClick={() => setSelectedTabIndex(index)}>
                          {item}
                        </RoundedFilledButton>
                      ) : (
                        <PlainButton
                          onClick={() => {
                            setSelectedTabIndex(index)
                          }}
                        >
                          {item}
                        </PlainButton>
                      )}
                    </Box>
                  ))}
                </Box>
                <Grid container sx={{ marginTop: 2, px: 4, alignSelf: 'center' }}>
                  {Array.from(Array(10)).map((_, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3} sx={classes.square}>
                      <PatternItemCard
                        title={`Title - ${index + 1}`}
                        authorName={`Author ${index + 1}`}
                        authorProfileImageURL={''}
                        patternImageSource={IMAGES.samplePattern}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                sx={{
                  my: 6,
                  display: 'flex',
                  flexDirection: 'column',

                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <SVG src={SVGS.ThumbIcon} color='white' />
                <Typography variant='h2' textAlign={'center'} style={{ whiteSpace: 'pre-line' }}>
                  {
                    'All these designs were made with a Meshdom platform. \nStart making your own design in under a minute:'
                  }
                </Typography>
                <Box>
                  <RoundedFilledButton>Make Your Own - It’s Really Amazing</RoundedFilledButton>
                </Box>
              </Box>
            </Box>
          </div>
        </Box>
        <Footer />
        <AuthDialog authStep={authStep} open={showAuthDialog} onClose={() => navigate('/')} />
      </Box>
    </AxiosProvider>
  )
}
