// import colors from '@/styles/colors'
import colors from '@/styles/colors'
import { Box, Button, Grid, IconButton, Tab } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DashboardContentContainer = styled(Box)({
  left: '240px',
  top: '70px',
  bottom: 0,
  right: 0,
  flex: 1,
  overflow: 'hidden',
  '& .draft-section': {
    color: '#211E1E',
    wordWrap: 'break-word',
    fontSize: 14,
  },
})

export const ContentPanel = styled(Box)({
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
})

export const RBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})
export const CBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const CrossButton = styled(Button)(() => ({
  width: '140px',
  height: '72px',
  gap: '6px',
  '&.hover': {
    backgroundColor: 'linear-gradient(233.5deg, #D8E9FF 0%, rgba(255, 255, 255, 0) 64.67%)',
  },
}))

export const CustomTab = styled(Tab)(({ theme }) => ({
  color: colors.themePurpleTextColor,
  fontSize: 15,
  fontFamily: 'Inter-Medium',
  '&.Mui-selected': {
    color: colors.meshdomBlue,
  },
  '&:hover': {
    color: colors.meshdomBlue,
  },
}))

export const Panel2 = styled(Grid)({
  backgroundColor: 'white',
  height: '100%',
})

export const Panel3 = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'view',
})<{ view: boolean }>(({ theme, view }) => ({
  backgroundColor: theme.palette.grey[800],
  height: '100%',
  padding: '20px',
  zIndex: 11,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: view ? 0 : '100vw',
    width: 'calc(100vw - 70px)',
    transitionDuration: '.2s',
    zIndex: 200,
  },
}))

export const Panel4 = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'view',
})<{ view: boolean }>(({ theme, view }) => ({
  backgroundColor: theme.palette.grey[800],
  height: '100%',
  padding: '20px 20px 20px 0',
  [theme.breakpoints.down('lg')]: {
    right: view ? 0 : '-100vw',
    transitionDuration: '.2s',
    width: '50vw',
    position: 'absolute',
    zIndex: 300,
    padding: 0,
    boxShadow: `-3px 0 6px ${theme.palette.grey[500]}`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '80vw',
  },
}))

export const GridPanel = styled(Grid)({
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
})

export const SidePanel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  height: '100%',
  width: '100%',
  maxWidth: 392,
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  zIndex: 10,
  boxShadow: '4px 0px 4px 6px rgba(0,0,0,.1)',
}))

export const BackgroundImage = styled(Box)<{ url: string }>(({ url }) => ({
  height: '100%',
  backgroundImage: `url(${url})`,
  backgroundSize: 'cover',
}))

export const ButtonSelect = styled(IconButton)(({ theme }) => ({
  flexDirection: 'column',
  width: '105px',
  height: '105px',
  marginRight: '15px',
  marginBottom: '15px',
  borderRadius: '8px',
  border: '1px solid #E7EDF3',
  fontSize: '13px',
  fontWeight: 500,
  color: '#211E1E',
  '&[aria-selected = true]': {
    border: `1.5px solid ${colors.meshdomBlue}`,
  },
}))

export const ButtonCreateNew = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.light,
  color: 'white',
  fontSize: 12,
  fontWeight: 'bold',
  borderRadius: 50,
  height: '38px',
  alignItems: 'center',
  width: '124px',
  '&:disabled': { backgroundColor: 'lightGray' },
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
  },
}))
