import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import dayjs, { Dayjs } from 'dayjs'
import { AuthFormBox } from './__styled'
import { Input, MyDatePicker } from '@/components'
import { validateFirstName, validateLastName } from '@/utils/helper'

interface FormProps {
  onSubmit: (firstName: string, lastName: string, dob: Dayjs) => void
}

export default function SignUpForm2({ onSubmit }: FormProps) {
  const [firstName, setFirstName] = useState('') // Email or Phone number
  const [lastName, setLastName] = useState('')
  const [dob, setDOB] = useState<Dayjs | null>(dayjs().subtract(13, 'year'))
  const [editing, setEditing] = useState(false)

  const formError = useMemo(() => {
    const error: Record<string, string> = {}
    if (!firstName.length) {
      error.firstName = 'First Name is required'
    } else if (!validateFirstName(firstName)) {
      error.firstName = 'Enter Valid First Name'
    }
    if (!lastName.length) {
      error.lastName = 'Last Name is required'
    } else if (!validateLastName(lastName)) {
      error.lastName = 'Enter Valid Last Name'
    }
    if (!dayjs(dob).isValid()) {
      error.dob = 'Date of Birth is invalid'
    }
    return error
  }, [firstName, lastName, dob])

  const isFormValid = !formError.firstName && !formError.lastName && !formError.dob

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditing(true)
    setFirstName(e.target.value)
  }

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditing(true)
    setLastName(e.target.value)
  }

  const onChangeDOB = (date: Dayjs | null) => {
    setEditing(true)
    setDOB(date)
  }

  const onContinue = () => {
    if (isFormValid && dob) onSubmit(firstName, lastName, dob)
  }

  return (
    <AuthFormBox>
      <Typography variant='large' fontSize={38}>
        Sign Up
      </Typography>
      <Typography variant='p' fontSize={15} mt={1}>
        Sign up to see shared topics and media from your connections
      </Typography>
      <Input
        value={firstName}
        placeholder='First Name'
        onChange={onChangeFirstName}
        errorText={formError.firstName}
        isError={editing && !!formError.firstName}
        name='first name'
      />
      <Input
        value={lastName}
        placeholder='Last Name'
        onChange={onChangeLastName}
        errorText={formError.lastName}
        isError={editing && !!formError.lastName}
        name='last name'
      />
      <MyDatePicker
        errorText={formError.dob}
        isError={editing && !!formError.dob}
        onChange={onChangeDOB}
        value={dob}
      />
      <Box mt={2} display='flex' justifyContent='flex-end'>
        <Typography variant='labelLarge'>
          By continuing, you agree to our{' '}
          <Link to='/terms'>
            <Typography variant='labelLarge' color={'brand.light'}>
              Terms of Services, Privacy Policy & Content Policy.
            </Typography>
          </Link>
        </Typography>
      </Box>
      <Button
        variant='contained'
        size='large'
        fullWidth
        className='loginButton'
        disabled={!isFormValid}
        onClick={onContinue}
      >
        Continue
      </Button>
      <Box mt={2} display='flex' justifyContent='center' alignItems={'center'} gap={'3px'}>
        <Typography
          variant='labelLarge'
          fontSize={14}
          color={'customText.main'}
          fontFamily={'Inter'}
        >
          Got an account?
        </Typography>
        <Link to='/login'>
          <Typography variant='labelLarge' fontSize={14} color={'brand.main'}>
            Sign In
          </Typography>
        </Link>
      </Box>
    </AuthFormBox>
  )
}
