import { Outlet, useNavigate } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  Divider,
  List,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAuth } from '@/hooks/common'
import { useEffect } from 'react'
import { AxiosProvider } from '@/context/Axios'
import DashboardHeader from './Header'
import DashboardSideBar from './SideBar'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import ResponsiveNavBar from './ResponsiveNavBar'

export default function DashboardLayout() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'))
  const { resetTopicList, setMyDMConnections } = useAppStore()
  const [sidebarCollapsed, showSideMenuDrawer] = useAppStore(
    (state) => [state.home.sidebarCollapsed, state.app.showSideMenuDrawer],
    shallow,
  )
  const drawerWidth = sidebarCollapsed ? '90px' : '266px'
  const { setShowSideMenuDrawer } = useAppStore()

  useEffect(() => {
    resetTopicList()
    setMyDMConnections([])
  }, [])

  useEffect(() => {
    if (!user?.userID) {
      navigate('/login')
    }
  }, [user?.userID])

  return (
    <AxiosProvider>
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        {/* Left Drawer */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              ml: 0,
              boxSizing: 'border-box',
            },
          }}
          open={lessThanSmall ? showSideMenuDrawer : true}
          variant={lessThanSmall ? 'temporary' : 'permanent'}
          onClose={() => setShowSideMenuDrawer(false)}
          anchor='left'
        >
          <DashboardSideBar />
        </Drawer>
        {/* Right Panel */}
        <Box flex={1}>
          <AppBar
            elevation={0}
            position='fixed'
            sx={{
              width: lessThanSmall ? '100%' : `calc(100% - ${drawerWidth})`,
              ml: `${drawerWidth}`,
            }}
          >
            <ResponsiveNavBar />
          </AppBar>
          <Box
            display={'flex'}
            width={`calc(100%) - ${drawerWidth}`}
            mt={'77px'}
            height={`calc(100% - 77px)`}
            sx={{ overflow: 'hidden' }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </AxiosProvider>
  )
}
