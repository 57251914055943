import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { ReactQueryKeys } from '@/types'

export const useConfirmNewPassword = () => {
  return useMutation({
    mutationKey: [ReactQueryKeys.CONFIRM_NEW_PASSWORD],
    mutationFn: async ({ user, code, newPassword }: Record<string, string>): Promise<boolean> => {
      await Auth.forgotPasswordSubmit(user, code, newPassword)
      return true
    },
  })
}
