import { useConnectionAccept, useConnectionAdd, useConnectionDecline, useGetConnectRecommendations, useGetConnectRequests, useGetMyCircles } from '@/hooks/api/dashboard';
import { useBrowserQuery } from '@/hooks/common';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Panel2, Panel3, Panel4 } from '../__styled';
import NotificationCategoryList from './CategoryList';
import RequestAndRecommendations from './RequestAndRecommendations';
import ConnectionSelectGroup from './SelectGroup';

export default function NotificationPage() {
  const [showDetails, setShowDetails] = useState(false);
  const [showInteractions, setShowInteractions] = useState(false);

  const acceptRequest = useConnectionAccept();
  const declineRequest = useConnectionDecline();
  const getRequests = useGetConnectRequests();
  const connectRequest = useConnectionAdd();
  const getRecommends = useGetConnectRecommendations();
  const getMyGroups = useGetMyCircles();

  const query = useBrowserQuery();
  const { pathname } = useLocation();
  const requestID = query.get("requestID");
  const userID = query.get("userID");
  const category = query.get("category");
  const navigate = useNavigate();

  useEffect(() => {
    if (category) return;
    setTimeout(() => {
      navigate(`${pathname}?category=requests`, { replace: true });
    }, 1000)
  }, [category])

  useEffect(() => {
    if (acceptRequest.isSuccess) {
      getRequests.mutate();
      getMyGroups.mutate();
    } else if (declineRequest.isSuccess) {
      getRequests.mutate();
    }
    query.delete('requestID');
    navigate(`${pathname}?${query.toString()}`);
  }, [acceptRequest.isSuccess, declineRequest.isSuccess])

  useEffect(() => {
    if (connectRequest.isSuccess) {
      getRecommends.mutate();
      query.delete('userID');
      navigate(`${pathname}?${query.toString()}`);
    }
  }, [connectRequest.isSuccess])

  const onClickCategory = () => {
    setShowDetails(true);
  }

  const onClickBack = () => {
    setShowDetails(false);
  }

  const onClickAccept = () => {
    setShowInteractions(true);
  }

  const onClickConnectUser = () => {
    setShowInteractions(true);
  }

  const onClickCloseInteractions = () => {
    setShowInteractions(false);
  }

  const onSubmit = (circleID: string) => {
    if (category === 'requests') {
      if (!requestID || acceptRequest.isLoading) return;
      toast.promise(
        acceptRequest.mutateAsync({
          connReqID: requestID,
          cliques: [circleID]
        }),
        {
          loading: 'Processing the acceptance...',
          success: <Typography variant="p">{`Success!`}</Typography>,
          error: <Typography variant="p">Failed to accept the request.</Typography>
        }
      )
    } else if (category === 'recommendations') {
      if (!userID || connectRequest.isLoading) return;
      toast.promise(
        connectRequest.mutateAsync({
          connID: userID,
          cliques: [circleID]
        }),
        {
          loading: 'Requesting connect...',
          success: <Typography variant="p">{`Connection request has been sent!`}</Typography>,
          error: <Typography variant="p">Failed to request.</Typography>
        }
      )
    }
  }

  const onDeclineRequet = (requestID: string) => {
    if (!requestID || declineRequest.isLoading) return;
    toast.promise(
      declineRequest.mutateAsync(requestID),
      {
        loading: 'Processing the decline...',
        success: <Typography variant="p">{`Success!`}</Typography>,
        error: <Typography variant="p">Failed to decline the request.</Typography>
      }
    )
  }

  return (
    <Grid container height={'100%'} position="relative">
      <Panel2 item xs={12} md={4} lg={3}>
        <NotificationCategoryList onClickCategory={onClickCategory} />
      </Panel2>
      <Panel3 item xs={12} md={8} lg={6} view={showDetails}>
        <RequestAndRecommendations
          onClickDeclineRequet={onDeclineRequet}
          onClickConnectUser={onClickConnectUser}
          onClickBack={onClickBack}
          onClickAccept={onClickAccept}
        />
      </Panel3>
      <Panel4 item xs={10} md={6} lg={3} view={showInteractions}>
        <ConnectionSelectGroup onSubmit={onSubmit} onClickClose={onClickCloseInteractions} />
      </Panel4>
    </Grid>
  )
}