import useAppStore from '@/store/useAppStore';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useBrowserQuery } from '@/hooks/common';
import TopicDetails from '../panels/details';
import { GridPanel, SidePanel } from '../__styled';
import ShareSearchList from './search';


export default function ShareInteractionsPage() {
  const [showDetails, setShowDetails] = useState(false);

  const { pathname } = useLocation();
  const query = useBrowserQuery();
  const topicID = query.get("topicID") || '';

  const [topicList] = useAppStore(state => [state.home.topicList], shallow);

  const navigate = useNavigate();

  const onClickTopic = () => {
    setShowDetails(true);
  }

  const onClickBack = () => {
    query.delete('topicID');
    query.delete('noteID');
    navigate(`${pathname}?${query.toString()}`, { replace: true });
    setShowDetails(false);
  }

  const onDeletedTopic = () => {
    navigate('/share')
  }

  useEffect(() => {
    if (topicID) {
      setShowDetails(true);
    }
  }, [topicID])

  return (
    <Grid container height={'100%'} position="relative">
      <GridPanel item style={{maxWidth: showDetails ? 'calc(100% - 392px)' : '100%'}}>
        <ShareSearchList showDetails={showDetails} onClickTopic={onClickTopic} />
      </GridPanel>
      {showDetails && (
        <SidePanel>
          {Object.keys(topicList).length > 0 && (
            <TopicDetails
              isShowAction={true}
              onClickBack={onClickBack}
              onShowInteractions={() => { }}
              onDeletedTopic={onDeletedTopic}
            />
          )}
        </SidePanel>
      )}
    </Grid>
  )
}