import useAppStore from '@/store/useAppStore'
// import { PrivacyTypes, TopicTypes } from "@/utils/constants";
// import { SortOutlined } from "@mui/icons-material";
// import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
// import { PrivacyTypes, TopicTypes } from "@/utils/constants";
// import { SortOutlined } from "@mui/icons-material";
// import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { SearchContent } from '../__styled'
import HomeFilter from './Filter'
import HomeSearchResult from './Results'
import { Topic, TopicListFilter } from '@/types'

export default function HomeSearchList() {
  const [filterShown, setFilterShown] = useState(false)
  const { setInteractionType, setTopicType } = useAppStore()
  const [interactionType, topicType] = useAppStore(
    (state) => [state.home.interactionFilterType, state.home.topicFilterType],
    shallow,
  )

  const onApplyFilters = (interactionType: number, topicType: number) => {
    setInteractionType(interactionType)
    setTopicType(topicType)
    setFilterShown(false)
  }

  const onCloseFilter = () => {
    setFilterShown(false)
  }

  return (
    <SearchContent display='flex' flex={1} flexDirection={'column'}>
      <HomeSearchResult />
      {filterShown && (
        <HomeFilter
          onApply={onApplyFilters}
          onClose={onCloseFilter}
          interaction={interactionType}
          topic={topicType}
        />
      )}
    </SearchContent>
  )
}
