import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import colors from '@/styles/colors'
import ReactTimeago from 'react-timeago'
import { convertToGMT } from '@/utils/helper'
import { UserAvatar } from './Avatar'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import React from 'react'

export interface UserInteractionProps {
  date: string
  avatar: string
  name: string
}

export function UserInteraction({ date, avatar, name }: UserInteractionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box py={1}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <UserAvatar size={35} borderRadius={35} name={name} />
          <Typography variant='label'>{name}</Typography>
        </Box>
        <Box display='flex' justifyContent={'space-between'} alignItems='center'>
          <Box width={40}>
            <IconButton
              onClick={handleClick}
              id='demo-positioned-button'
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <MoreHorizIcon width={24} height={24} sx={{ color: colors.darkGrey }} />
            </IconButton>
            <Menu
              id='options-menu'
              aria-labelledby='demo-positioned-button'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleClose}>View Profile</MenuItem>
              <MenuItem onClick={handleClose}>Connect</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
