import colors from '@/styles/colors'
import { LoadingButton } from '@mui/lab'
import { Box, styled } from '@mui/material'

export const MyBasicInfoContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {},
}))

export const MyProfileDetailsContainer = styled(Box)(({ theme }) => ({
  height: '-webkit-fill-available',
  overflow: 'auto',
  [theme.breakpoints.down('xl')]: {
    '& .subscription-date': {
      borderWidth: 0,
    },
  },
  [theme.breakpoints.down('lg')]: {
    height: 'fit-content',
    overflow: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    '& .deactivate-account': {
      width: '100%',
    },
    '& .delete-account': {
      width: '100%',
    },
  },
}))

export const DeleteAccountButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  margin: 8,
  backgroundColor: 'white',
}))

export const ProfilePrivacyContent = styled(Box)(({ theme }) => ({
  margin: '24px -24px 0px -24px',
  '& .empty-icon': {
    '& path': {
      strokeWidth: 0.3,
    },
  },
  '& .connections': {
    display: 'flex',
    borderBottom: `1px solid ${colors.lightGrey}`,
    borderTop: `1px solid ${colors.lightGrey}`,
    padding: '0 24px',
    alignItems: 'center',
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 10,
    '& button': {
      color: colors.darkGrey,
      borderRadius: 0,
      fontSize: 13,
      height: 50,
      '&.active': {
        borderBottom: `1px solid ${colors.blue}`,
        color: colors.blue,
      },
      [theme.breakpoints.down('md')]: {
        flex: 1,
      },
    },
  },
  '& .search-bar': {
    zIndex: 10,
    backgroundColor: 'white',
    position: 'relative',
    boxShadow: '0 1px 2px rgba(0,0,0,.1)',
  },
  '& .add-circle': {
    border: `2px solid ${colors.blue}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
    height: 40,
    borderRadius: 6,
    color: colors.blue,
    boxSizing: 'border-box',
    marginLeft: 12,
    cursor: 'pointer',
  },
  '& .group-menu': {
    backgroundColor: colors.grey,
    padding: '5px 10px',
    width: '100%',
    borderRadius: 4,
    height: 40,
    boxSizing: 'border-box',
  },
}))
