/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useDeleteEgo = (topicID: string) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.DELETE_EGO, topicID],
    mutationFn: async (egoIDs: string[]) => {
      const response = await axios.api.post('ajax/objects', {
        requestType: 'egoDelete',
        egoID: egoIDs,
        format: 'json',
      })
      return response.data
    },
  })
}
