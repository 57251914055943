import { SVGS } from "@/assets/svgs";
import { CModal, Input } from "@/components";
import colors from "@/styles/colors";
import { Patterns } from "@/utils/helper";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import SVG from 'react-inlinesvg';
import { useForm, Controller } from "react-hook-form";
import { DeleteAccountButton } from "../__styled";
import { toast } from "react-hot-toast";
import { Auth } from "aws-amplify";

interface PasswordForm {
  current: string;
  new: string;
  confirm: string;
}

interface Props {
  onSubmitDeactivateAccount: () => void;
  onSubmitDeleteAccount: () => void;
}

export default function AccountManagement({
  onSubmitDeactivateAccount,
  onSubmitDeleteAccount
}: Props) {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);

  const { control, handleSubmit, watch, reset, formState: { errors, isDirty } } = useForm<PasswordForm>();

  const onClickUpdate = async (data: PasswordForm) => {
    if (changingPassword) return;
    try {
      setChangingPassword(true);
      const user = await Auth.currentAuthenticatedUser();
      const res = await toast.promise(
        Auth.changePassword(user, data.current, data.new),
        {
          loading: 'Changing password...',
          success: <Typography variant="p">Password has been updated.</Typography>,
          error: <Typography variant="p">Wrong current password or failed to update your password.</Typography>
        }
      )
      if (res === 'SUCCESS') {
        reset({ current: '', new: '', confirm: '' });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(err.toString())
    } finally {
      setChangingPassword(false);
    }
  }

  const onClickDeactivate = () => {
    setShowDeactivateModal(true);
  }

  const onClickDelete = () => {
    setShowDeleteModal(true);
  }

  return (
    <>
      <Box borderRadius={2} bgcolor="white" p={3} mt={3}>
        <Typography variant="h2" mb={1}>Account Management</Typography>
        <Typography variant="label">Change Password</Typography>
        <Grid container spacing={3} mt={-1}>
          <Grid item xs={12} lg={3}>
            <Controller
              control={control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  placeholder='Current password*'
                  onChange={field.onChange}
                  rightIcon={showCurrentPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  onToggleIcon={() => setShowCurrentPassword(!showCurrentPassword)}
                  type={showCurrentPassword ? 'text' : 'password'}
                  errorText={errors.current?.message}
                  isError={!!errors.current}
                  name="old-password"
                />
              )}
              name="current"
              rules={{
                required: 'Current password is required',
                pattern: {
                  value: Patterns.password,
                  message: 'Password can only contain Latin Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Controller
              control={control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  placeholder='New password*'
                  onChange={field.onChange}
                  rightIcon={showNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  onToggleIcon={() => setShowNewPassword(!showNewPassword)}
                  type={showNewPassword ? 'text' : 'password'}
                  errorText={errors.new?.message}
                  isError={!!errors.new}
                  name="new-password"
                />
              )}
              name="new"
              rules={{
                required: 'New password is required',
                pattern: {
                  value: Patterns.password,
                  message: 'Password can only contain Latin Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>

            <Controller
              control={control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  placeholder='Confirm password*'
                  onChange={field.onChange}
                  rightIcon={showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  onToggleIcon={() => setShowConfirmPassword(!showConfirmPassword)}
                  type={showConfirmPassword ? 'text' : 'password'}
                  errorText={'Confirm new password doesn`t match.'}
                  isError={!!errors.confirm}
                  name="confirm-password"
                />
              )}
              name="confirm"
              rules={{
                validate: (value: string) => value === watch('new')
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <LoadingButton
              variant="contained"
              size={'large'}
              onClick={handleSubmit(onClickUpdate)}
              disabled={!isDirty}
              loading={changingPassword}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" mt={2} flexWrap="wrap" mx={-1}>
        <DeleteAccountButton
          variant="outlined"
          color="info"
          onClick={onClickDeactivate}
          className="deactivate-account"
        >
          Deactivate My Account
        </DeleteAccountButton>
        <DeleteAccountButton
          variant="outlined"
          onClick={onClickDelete}
          className="delete-account"
        >
          Delete My Account
        </DeleteAccountButton>
      </Box>
      <CModal
        open={showDeactivateModal}
        onClose={() => setShowDeactivateModal(false)}
        title={'Deactivate Account?'}
        description={'Are you sure you want to temporarily deactivate your account?'}
        cancelText="No, Cancel"
        submitText="Yes, Deactivate"
        icon={<SVG src={SVGS.Deactivate} color={colors.error} width={60} height={60} />}
        onSubmit={onSubmitDeactivateAccount}
      />
      <CModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={'Delete Account?'}
        description={'Are you sure you want to permanently delete your account? This can not be undone.'}
        cancelText="No, Cancel"
        submitText="Yes, Delete"
        icon={<SVG src={SVGS.Delete} color={colors.error} width={60} height={60} />}
        onSubmit={onSubmitDeleteAccount}
      />
    </>
  )
}