import { SVGS } from '@/assets/svgs'
import colors from '@/styles/colors'
import { PrivacyTypes, TopicType, TopicTypes } from '@/utils/constants'
import { Button, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import SVG from 'react-inlinesvg'

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

interface Props {
  type?: 'topic' | 'shared' | 'connection' | 'custom'
  interaction?: number
  topicType?: number
  iconSize?: number
  iconColor?: string
  btnColor?: string
  icon?: string
  text?: string
  buttonTitle?: string
  onClick?: () => void
}

export function NoResults({
  type = 'topic',
  interaction = 0,
  topicType = 0,
  iconSize = 76,
  iconColor = colors.grey,
  btnColor = colors.yellow,
  icon,
  text,
  buttonTitle,
  onClick = () => {},
}: Props) {
  if (type === 'topic') {
    return (
      <Container>
        <SVG
          src={TopicTypes[topicType].icon}
          width={iconSize}
          height={iconSize}
          className={'icon'}
          color={iconColor}
        />
        <Typography variant='h3' mt={2} mb={1}>
          Results Not Found
        </Typography>
        <Typography variant='ps'>You have not created any</Typography>
        <Box display='flex' mb={2} gap={'2px'}>
          {interaction > 0 && (
            <Typography variant='ps'>{PrivacyTypes[interaction]?.label}</Typography>
          )}
          <Typography variant='ps'>{TopicTypes[topicType]?.title}</Typography>
        </Box>
        <Button variant='contained' onClick={onClick} sx={{ backgroundColor: btnColor }}>
          {`Create ${topicType == TopicType.ALBUM ? 'an' : 'a'} ${TopicTypes[topicType].title}`}
        </Button>
      </Container>
    )
  }

  if (type === 'shared') {
    return (
      <Container>
        <SVG
          src={SVGS.Reactions}
          width={iconSize}
          height={iconSize}
          className={'icon'}
          color={iconColor}
        />
        <Typography variant='h3' mt={2} mb={1}>
          No Reactions & Shares
        </Typography>
        <Typography variant='ps' mb={2} textAlign='center'>
          There is no any reactions & shares yet for you. Create new topic to get reactions from
          other
        </Typography>
        <Button variant='contained' onClick={onClick} sx={{ backgroundColor: btnColor }}>
          + Create New Topic
        </Button>
      </Container>
    )
  }
  if (type === 'connection') {
    return (
      <Container>
        <SVG
          src={SVGS.NoConnection}
          width={iconSize}
          height={iconSize}
          className={'icon'}
          color={iconColor}
        />
        <Typography variant='h3' mt={2} mb={1}>
          No connections yet!
        </Typography>
        <Typography variant='ps' mb={2} textAlign='center'>
          Invite friends and family to start sharing your world with.
        </Typography>
        <Button variant='contained' onClick={onClick} sx={{ backgroundColor: btnColor }}>
          Invite Friends
        </Button>
      </Container>
    )
  }
  if (type === 'custom') {
    return (
      <Container>
        <SVG
          src={icon || ''}
          width={iconSize}
          height={iconSize}
          className={'icon'}
          color={iconColor}
        />
        <Typography variant='h3' mt={2} mb={1}>
          {text}
        </Typography>
        <Button variant='contained' onClick={onClick} sx={{ backgroundColor: btnColor }}>
          {buttonTitle}
        </Button>
      </Container>
    )
  }
  return null
}
