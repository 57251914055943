/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

interface Param {
  noteID: string
  topicID: string
}

export const useDeleteNote = (isDM: boolean) => {
  const axios = useContext(AxiosContext)
  const { updateTopicList, setMyDMConnections } = useAppStore()
  const [topicList, myDMConnections] = useAppStore(
    (state) => [state.home.topicList, state.user.myDMConnections],
    shallow,
  )

  const removeNoteFromList = (topicID: string, noteID: string) => {
    if (isDM) {
      const index = myDMConnections.findIndex((i) => i.topicID === topicID)
      if (index > -1) {
        const updatedNotes = myDMConnections[index]?.notes?.filter((i) => i.noteID !== noteID)
        myDMConnections.splice(index, 1, {
          ...myDMConnections[index],
          notes: updatedNotes,
        })
        setMyDMConnections([...myDMConnections])
      }
    } else {
      const topic = topicList[topicID]
      const updatedNotes = topic?.notes?.filter((i) => i.noteID !== noteID)
      updateTopicList({
        [topicID]: {
          ...topic,
          noteCount: `${Number(topic.noteCount) - 1}`,
          notes: updatedNotes,
        },
      })
    }
  }

  return useMutation({
    mutationKey: [ReactQueryKeys.NOTE_DELETE],
    mutationFn: async (data: Param) => {
      removeNoteFromList(data.topicID, data.noteID)
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteUpdate',
        'noteData[action]': 'delete',
        'noteData[noteID]': data.noteID,
        'noteData[topicID]': data.topicID,
        format: 'json',
      })

      return response.data
    },
  })
}
