import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import { toast, Toaster, ToastBar } from 'react-hot-toast'

import { RootRouter } from './router/RootRouter'
import { theme } from './styles/theme'
import { awsAmplifyConfig } from './config'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-modal/styles.css'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

const ENV = process.env.REACT_APP_DEV_ENV || 'dev'
Amplify.configure(awsAmplifyConfig[ENV])

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={RootRouter} />
        <Toaster position='top-right' toastOptions={{ duration: 10000 }}>
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== 'loading' && (
                    <IconButton onClick={() => toast.dismiss(t.id)}>
                      <Close />
                    </IconButton>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
