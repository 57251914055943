import colors from "@/styles/colors";
import { NoInteractionType } from "@/utils/constants";
import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SVG from 'react-inlinesvg';

const Container = styled(Box)(({ strokeWidth }: { strokeWidth: number }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .icon': {
    '& path': {
      strokeWidth: 60 / strokeWidth,
    }
  }
}))

interface Props {
  type: number
  on: string
  iconSize?: number
  iconColor?: string
}

export function NoInteractions({ type, on, iconSize = 60, iconColor = colors.yellow }: Props) {
  const data = NoInteractionType[type];
  return (
    <Container strokeWidth={iconSize}>
      <SVG
        src={data.icon}
        width={iconSize}
        height={iconSize}
        className={'icon'}
        color={iconColor}
      />
      <Typography variant="h3" mt={2} mb={1}>{data.title}</Typography>
      <Typography variant="ps">{data.subTitle}</Typography>
      <Typography variant="ps">this {on}</Typography>
    </Container>
  )
  return null
}