import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AuthFormBox } from './__styled';
import { IMAGES } from '@/assets/images';
import { Box } from '@mui/system';

export default function ForgotForm3() {
  const navigate = useNavigate();
  const onSignIn = () => {
    navigate('/login');
  }
  return (
    <AuthFormBox>
      <Box mb={2}>
        <img src={IMAGES.success} className="forgot-success" />
      </Box>
      <Typography variant='large' fontSize={38}>
        Password Successfully Reset
      </Typography>
      <Typography variant='p' fontSize={15} mt={1}>
        Your password has been successfully reset. Click below to Sign In magically.
      </Typography>
      <Button
        variant='contained'
        size="large"
        fullWidth
        className="loginButton"
        onClick={onSignIn}
      >
        Sign In
      </Button>
    </AuthFormBox>
  )
}