import { PreviewNote, TagPrivacy, TagTopic } from '@/components'
import { useGetTopicDetails, useGetTopics } from '@/hooks/api/dashboard'
import colors from '@/styles/colors'
import { Topic, AlbumPhoto } from '@/types'
import { TopicType, TopicTypes } from '@/utils/constants'
import { convertToGMT, getPlainNoteTextFromNote } from '@/utils/helper'
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ReactTimeago from 'react-timeago'
import { addUploadAssets } from '../renderer/main'
import { AssetItem, PatternAssetList } from '../__styled'
import LoadingAnimatedView from '@/components/LoadingAnimatedView'
import { CBox, RBox } from '@/pages/__styled'

interface Props {
  onSelectAsset: (id: number | string) => void
  selectedAssetID: string
}

export default function AlbumList({ selectedAssetID, onSelectAsset }: Props) {
  const [page, setPage] = useState(1)
  const [endReached, setEndReached] = useState(false)
  // const [selectedEgoID, setSelectedEgoID] = useState<string | null>(null)
  const [topicList, setTopicList] = useState<Topic[]>([])
  const [photos, setPhotos] = useState<Record<string, AlbumPhoto[]>>({})
  const [toggleStatus, setToggleStatus] = useState<Record<string, boolean>>({})
  const [loadingTopicID, setLoadingTopicID] = useState('')
  const [expandedTopic, setExpandedTopic] = useState<Topic | null>(null)
  const getTopics = useGetTopics()
  const getTopicDetails = useGetTopicDetails()

  useEffect(() => {
    getTopics.mutate({
      pageNo: page,
      interaction: 3,
      topicType: TopicType.ALBUM,
    })
  }, [page])

  useEffect(() => {
    if (getTopics.isSuccess) {
      if (page === 1) {
        setTopicList(getTopics.data)
      } else {
        setTopicList([...topicList, ...getTopics.data])
      }
      if (getTopics.data.length < 20) {
        setEndReached(true)
      } else {
        setEndReached(false)
      }
    }
  }, [page, getTopics.isSuccess])

  useEffect(() => {
    if (getTopicDetails.isSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let data: any[] = []
      getTopicDetails.data?.notes?.forEach((i) => {
        data = data.concat(i.egoList || [])
      })
      setPhotos((prev) => ({
        ...prev,
        [loadingTopicID]: data,
      }))
      setLoadingTopicID('')
      addUploadAssets(
        data.map((i) => ({
          ...i,
          egoFileURL: i.photoAlbumURL,
          egoImageFullURL: i.photoAlbumURL,
        })),
      )
    }
  }, [getTopicDetails.isSuccess, loadingTopicID])

  const onClickShowMore = () => {
    if (endReached || getTopics.isLoading) {
      return
    }
    getTopics.mutate({
      pageNo: page + 1,
      interaction: 3,
      topicType: TopicType.ALBUM,
    })
    setPage(page + 1)
  }

  const onPressTopic = async (topic: Topic) => {
    const { topicID } = topic
    /*
    onSelectTopicID(topicID).then((value) => {
      const updated = topicList.map((e) => {
        if (e.topicID == topicID) {
          return {
            ...e,
            noteCount: `${Number(e.noteCount) + 1}`,
          }
        } else {
          return e
        }
      })
      setTopicList(updated)

      // Increment
    })
    */

    if (!photos[topicID]) {
      setLoadingTopicID(topicID)
      getTopicDetails.mutateAsync({ topicID })
    }
    setExpandedTopic(topic)
    // setToggleStatus({
    //   ...toggleStatus,
    //   [topicID]: !toggleStatus[topicID],
    // })
  }

  const HeaderView = (topic: Topic) => {
    let { topicID } = topic
    let isExpanded = false
    if (expandedTopic != null) {
      isExpanded = expandedTopic.topicID == topicID
    }

    return (
      <Box
        onClick={() => {
          if (!isExpanded) {
            onPressTopic(topic)
          }
        }}
        display='flex'
        alignItems='center'
        justifyContent={'space-between'}
        flex={1}
        sx={{
          cursor: 'pointer',
          paddingBottom: isExpanded ? null : '13px',
          borderBottom: isExpanded ? null : '1px solid #E7EDF3',
        }}
      >
        {isExpanded && (
          <KeyboardArrowLeftOutlined
            onClick={() => {
              setExpandedTopic(null)
            }}
            sx={{ color: colors.themePurpleTextColor, mr: '4px' }}
          />
        )}
        <RBox sx={{ flex: 1 }}>
          <CBox sx={{ flex: 1 }}>
            <Typography
              variant={'label'}
              flex={1}
              color={colors.themeBlack}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                textOverflow: 'ellipsis',
              }}
            >
              {getPlainNoteTextFromNote(topic.firstNote)}
            </Typography>
            {!isExpanded && (
              <Typography variant='pt' color={colors.themePurpleTextColor}>
                {topic.noteCount} photo{Number(topic.noteCount) > 1 ? 's' : ''}
              </Typography>
            )}
          </CBox>
          {!isExpanded && (
            <KeyboardArrowRightOutlined
              sx={{ color: colors.themePurpleTextColor, marginRight: '-8px' }}
            />
          )}
        </RBox>
      </Box>
    )
  }

  const renderPhotos = (topic: Topic) => {
    const { topicID } = topic
    if (loadingTopicID === topicID) {
      return <LoadingAnimatedView showGif={false} styles={{ mt: '4px' }} />
    }
    if (photos[topicID]?.length === 0) {
      return <Typography py={1}>No photos</Typography>
    }
    return (
      <PatternAssetList category={'print'} sx={{ backgroundColor: colors.white }}>
        {photos[topicID]?.map((ego: AlbumPhoto) => (
          <AssetItem
            className={selectedAssetID === `egoID-${ego.egoID}` ? 'active' : ''}
            key={ego.egoID}
            onClick={() => onSelectAsset(`egoID-${ego.egoID}`)}
          >
            <img
              style={{ width: '61px', height: '61px', borderRadius: 8 }}
              src={ego.photoAlbumURL}
              alt={ego.photoName}
            />
          </AssetItem>
        ))}
      </PatternAssetList>
    )
  }

  return (
    <Box flex={1} sx={{ overflowY: 'auto', width: '100%' }} bgcolor={colors.white}>
      <>
        {getTopics.isLoading && <LoadingAnimatedView showGif={false} />}
        {(expandedTopic ? [expandedTopic] : topicList).map((topic: Topic) => {
          const { topicID } = topic
          return (
            <Box key={topicID} m={2}>
              {HeaderView(topic)}
              {expandedTopic != null && expandedTopic.topicID == topicID && (
                <>{renderPhotos(topic)}</>
              )}
            </Box>
          )
        })}
      </>
    </Box>
  )
}
