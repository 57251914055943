import { PrivacyType, TopicType } from '@/utils/constants'
import { Connection } from './connection'
import { Ego } from './user'

export interface Note {
  authProfileName: string
  authProfileThumbURL: string
  authorID: string
  backgroundID: string
  color: {
    r: string
    g: string
    b: string
  }
  comments: number
  likes: Like[]
  dateCreated: string
  dateUpdated: string
  egoList: Ego[]
  fontID: string
  isThumbsDown: number
  isThumbsUp: number
  noteID: string
  noteName: string
  noteText: string // json string
  noteType: string
  scale: string
  thumbsDown: string
  thumbsUp: string
}

export interface NoteDetail extends Omit<Note, 'comments'> {
  comments: Comment[]
  commentsCount: number
}

export interface Clique {
  cliqueID: string
  cliqueName: string
}

export interface Circle {
  badgeThumbURL: string
  circleID: string
  circleName: string
  circleTypeID: string
  connCount: string
  isSystemProvided: string
}

export interface CircleConnection {
  albumImgURL: string
  firstName: string
  isConnection: true
  lastName: string
  location: string
  profession: string
  thumbImgURL: string
  userID: string
}

export interface AlbumPhoto {
  comments: number
  containerID: string
  dateCreated: string
  dateUpdated: string
  egoID: string
  isThumbsDown: number
  isThumbsUp: number
  photoAlbumURL: string
  photoDesc: string
  photoFullURL: string
  photoName: string
  photoThumbURL: string
  sequenceID: string
  thumbsDown: number
  thumbsUp: number
  userID: string
}

export interface FileInfo {
  egoAlbumURL: string
  egoHash: string
  egoID: string
  egoImageURL: string
  egoName: string
}

export interface Comment {
  comment: string
  dateAdded: string
  feedbackID: string
  noteID: string
  profilePicURL: string
  senderID: string
  senderName: string
  topicID: string
  userID: string
}

export interface Like {
  dateCreated: string
  likes: string
  profilePicURL: string
  userID: string
  userName: string
}

export interface Thumbs {
  dateCreated: string
  noteID: string
  profilePicURL: string
  senderID: string
  thumb: number
  topicID: string
  userName: string
}

export interface Interaction {
  commentCount: number
  comments: Comment[]
  isThumbsDown: number
  isTHumbsUp: number
  thumbsDown: number
  thumbsUp: number
}

export interface EgoObject {
  comments: number
  dateAdded: string
  displayed: string
  egoAlbumURL: string
  egoDesc: string
  egoFileURL: string
  egoHash: string
  egoID: string
  egoName: string
  egoTags: string
  egoThumbURL: string
  thumbsDown: number
  thumbsUp: number
}

export interface Pagination {
  currentPage: number
  totalPages: number
  pageSize: number
  notesCount: number
}

export interface Topic {
  cliqueTypes: string[]
  cliques: Clique[]
  connections: Connection[]
  dateAdded: string
  dateCreated: string
  dateLastInteracted: string
  dateUpdated: string
  latestEgo: Ego
  latestNote: Note
  firstNote: Note
  mediaCount: number
  noteCount: string
  notes: Note[]
  ownerVisits: string
  photos: AlbumPhoto[]
  photoCount: string
  profilePicThumbURL: string
  topicDescription: string
  topicID: string
  topicName: string
  topicType: string
  topicXData: string
  totalComments: string
  totalDislikes: string
  totalLikes: string
  userID: string
  userName: string
  visibility: string
  visitorVisits: string
  pagination?: Pagination
}

export interface TopicsListResponse {
  topics: Topic[]
  pagination: Pagination
  message: string
  hasData: boolean
}

export interface DMTopic {
  connections: Connection[]
  dateAdded: string
  dateLastInteracted: string
  dateUpdated: string
  mediaCount: number
  notes: Note[]
  ownerVisits: string
  photos: AlbumPhoto[]
  profileThumbURL: string
  topicApplication: string
  topicDescription: string
  topicID: string
  topicName: string
  topicType: string
  topicXData: string
  totalComments: string
  totalDislikes: string
  totalLikes: string
  userID: string
  userName: string
  visibility: string
  visitorVisits: string
}

export interface Permission {
  accessTypeID: number
  accessTypeName: string
  isSelected: string
  accessList?: {
    cliqueTypes: string[]
    cliques: string[]
    connections: string[]
  }
}

export interface ResourcePermission {
  permissionData: Permission[]
  permissionID: string
}

export interface TopicListFilter {
  topicType?: TopicType
  privacyType?: PrivacyType
}

export enum SearchType {
  loggedInUser = 1,
  OtherUser = 2,
  byLoggedInWithOther = 3,
  ByOtherWithLoggedIn = 4,
  ByLoggedInAndOtherCombined = 5,
  circle = 6,
  Broadcast = 7,
}
