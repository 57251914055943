import { Input, MyDatePicker, PhotoEditModal, UserAvatar } from '@/components'
import colors from '@/styles/colors'
import { ChevronLeft, Close } from '@mui/icons-material'
import SVG from 'react-inlinesvg'
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'

import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import { SVGS } from '@/assets/svgs'
import dayjs, { Dayjs } from 'dayjs'
import { MyBasicInfoContainer } from '../__styled'
import { useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { Patterns } from '@/utils/helper'
import { useUpdateProfile, useGetBasicInfo, useUploadProfilePicture } from '@/hooks/api/dashboard'
import { toast } from 'react-hot-toast'
import { useAuth, useLocalStorage } from '@/hooks/common'
import { useNavigate } from 'react-router-dom'

interface ProfileForm {
  firstName: string
  lastName: string
  email: string
  phone: string
  dob: Dayjs
  profession: string
  motto: string
}

export default function MyBasicInfo() {
  const { logout } = useAuth()
  const [editing, setEditing] = useState(false)
  const [showAvatarModal, setShowAvatarModal] = useState(false)
  const [profile] = useAppStore((state) => [state.user.profile], shallow)
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ProfileForm>({
    defaultValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.userEmail,
      phone: profile?.phoneNumber,
      dob: dayjs(profile?.dob, 'MMMM D, YYYY'),
      profession: profile?.profession,
      motto: profile?.motto,
    },
  })

  const [ewUser] = useLocalStorage('ewUser', null)
  const updateProfile = useUpdateProfile()
  const getProfileData = useGetBasicInfo(ewUser?.userID)
  const uploadAvatar = useUploadProfilePicture()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const avatarRef = useRef<any>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (updateProfile.isSuccess || uploadAvatar.isSuccess) {
      setEditing(false)
      setShowAvatarModal(false)
      getProfileData.mutate({ isUserProfile: false })
    }
  }, [updateProfile.isSuccess, uploadAvatar.isSuccess])

  const onSubmit = (data: ProfileForm) => {
    toast.promise(
      updateProfile.mutateAsync({
        day: dayjs(data.dob).date().toString(),
        month: (dayjs(data.dob).month() + 1).toString(),
        year: dayjs(data.dob).year().toString(),
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        motto: data.motto,
        phone: data.phone,
        profession: data.profession,
      }),
      {
        loading: 'Updating your profile...',
        success: <Typography variant='p'>Profile has been updated.</Typography>,
        error: <Typography variant='p'>Failed to update</Typography>,
      },
    )
  }

  const onClickBack = () => {
    navigate(-1)
  }

  const onClickLogout = () => {
    logout()
  }

  const onSaveAvatar = () => {
    if (uploadAvatar.isLoading) return
    const url = avatarRef.current.getImageScaledToCanvas().toDataURL()
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `profilePicture.png`, {
          type: 'image/png',
        })
        toast.promise(uploadAvatar.mutateAsync({ file }), {
          loading: 'Updating your avatar...',
          success: <Typography variant='p'>Avatar has been updated.</Typography>,
          error: <Typography variant='p'>Failed to update</Typography>,
        })
      })
  }

  if (editing) {
    return (
      <MyBasicInfoContainer py={2} px={3}>
        <Box display={'flex'} justifyContent='flex-end'>
          <IconButton onClick={() => setEditing(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box display={'flex'} flexDirection='column' alignItems='center' py={2}>
          <Box position={'relative'}>
            <UserAvatar
              name={`${profile?.firstName} ${profile?.lastName}`}
              url={profile?.profilePicURL}
              size={85}
            />
            <IconButton
              onClick={() => setShowAvatarModal(true)}
              sx={{
                borderRadius: 2,
                backgroundColor: colors.yellow,
                border: `2px solid white`,
                position: 'absolute',
                bottom: -10,
                right: -10,
                '&:hover': { backgroundColor: colors.yellow },
              }}
            >
              <SVG src={SVGS.Camera} color='white' />
            </IconButton>
          </Box>
        </Box>
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          First Name
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              errorText={errors.firstName?.message}
              isError={!!errors.firstName}
              name='first-name'
              size='small'
            />
          )}
          name='firstName'
          rules={{ required: 'First name is required' }}
        />
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          Last Name
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              errorText={errors.lastName?.message}
              isError={!!errors.lastName}
              name='last-name'
              size='small'
            />
          )}
          name='lastName'
          rules={{ required: 'Last name is required' }}
        />
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          Email
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              errorText={errors.email?.message}
              isError={!!errors.email}
              name='email-address'
              size='small'
            />
          )}
          name='email'
          rules={{
            required: 'Email address is required',
            pattern: { value: Patterns.email, message: 'Email address is invalid' },
          }}
        />
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          Phone Number
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              errorText={errors.phone?.message}
              isError={!!errors.phone}
              name='phone-number'
              size='small'
            />
          )}
          name='phone'
          rules={{
            required: 'Phone number is required',
          }}
        />
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          Date of Birth
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <MyDatePicker
              value={field.value}
              errorText={errors.dob?.type === 'validate' ? 'Invalid date' : errors.dob?.message}
              isError={!!errors.dob}
              onChange={field.onChange}
            />
          )}
          name='dob'
          rules={{
            required: 'Date of Birth is required',
            validate: (value: Dayjs) => dayjs(value).isValid(),
          }}
        />
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          Profession
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <Input value={field.value} onChange={field.onChange} size='small' />
          )}
          name='profession'
        />
        <Typography variant='h3' fontSize={12} mt={2} mb={1}>
          Motto
        </Typography>
        <Controller
          control={control}
          render={({ field }) => (
            <Input value={field.value} onChange={field.onChange} size='small' />
          )}
          name='motto'
        />

        <LoadingButton
          variant='outlined'
          onClick={handleSubmit(onSubmit)}
          fullWidth
          sx={{ marginTop: 2 }}
          disabled={!isDirty}
          loading={updateProfile.isLoading}
        >
          Update
        </LoadingButton>
        <PhotoEditModal
          editorRef={avatarRef}
          title='Add your avatar'
          open={showAvatarModal}
          onClose={() => setShowAvatarModal(false)}
          onSubmit={onSaveAvatar}
        />
      </MyBasicInfoContainer>
    )
  }
  return (
    <MyBasicInfoContainer py={2} px={3}>
      <Button sx={{ color: colors.blue, marginLeft: -2 }} onClick={onClickBack}>
        <ChevronLeft />
        <Typography variant='label' color={colors.blue}>
          Back
        </Typography>
      </Button>
      <Box display={'flex'} flexDirection='column' alignItems='center'>
        <UserAvatar
          name={`${profile?.firstName} ${profile?.lastName}`}
          url={profile?.profilePicURL}
          size={85}
        />
        <Typography variant='pt' textAlign={'center'} mt={1} color={colors.grey}>
          Member since {dayjs(profile?.joinDate).format('MMM YYYY')}
        </Typography>
        <Typography
          variant='h1'
          textAlign={'center'}
          mt={1}
        >{`${profile?.firstName} ${profile?.lastName}`}</Typography>
        <Typography textAlign={'center'}>{profile?.motto || 'No description'}</Typography>
      </Box>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} sm={6} lg={12} mt={1}>
          <Box display='flex' alignItems='center'>
            <Box
              p={2}
              borderRadius={'50%'}
              border={`1px solid ${colors.lightGrey}`}
              display='flex'
              alignItems={'center'}
              justifyContent='center'
            >
              <SVG src={SVGS.Send} color={colors.blue} />
            </Box>
            <Box ml={2} flex={1} overflow='hidden'>
              <Typography variant='ps'>Email</Typography>
              <Tooltip arrow title={profile?.userEmail}>
                <Typography
                  variant='ps'
                  fontFamily={'Inter-SemiBold'}
                  textOverflow='ellipsis'
                  whiteSpace={'nowrap'}
                  overflow='hidden'
                >
                  {profile?.userEmail}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={12} mt={1}>
          <Box display='flex' alignItems='center'>
            <Box
              p={2}
              borderRadius={'50%'}
              border={`1px solid ${colors.lightGrey}`}
              display='flex'
              alignItems={'center'}
              justifyContent='center'
            >
              <SVG src={SVGS.Phone} color={colors.blue} />
            </Box>
            <Box ml={2}>
              <Typography variant='ps'>Phone Number</Typography>
              <Typography variant='labelSmall'>{profile?.phoneNumber}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={12} mt={1}>
          <Box display='flex' alignItems='center'>
            <Box
              p={2}
              borderRadius={'50%'}
              border={`1px solid ${colors.lightGrey}`}
              display='flex'
              alignItems={'center'}
              justifyContent='center'
            >
              <SVG src={SVGS.Calendar} color={colors.blue} />
            </Box>
            <Box ml={2}>
              <Typography variant='ps'>Date of Birth</Typography>
              <Typography variant='labelSmall'>{profile?.dob}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={12} mt={1}>
          <Box display='flex' alignItems='center'>
            <Box
              p={2}
              borderRadius={'50%'}
              border={`1px solid ${colors.lightGrey}`}
              display='flex'
              alignItems={'center'}
              justifyContent='center'
            >
              <SVG src={SVGS.Bag} color={colors.blue} />
            </Box>
            <Box ml={2}>
              <Typography variant='ps'>Profession</Typography>
              <Typography variant='labelSmall'>{profile?.profession}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box display='flex' flexDirection={'column'} justifyContent={'center'} my={3} gap={1}>
        <Button
          variant='outlined'
          className='editButton'
          fullWidth
          onClick={() => setEditing(true)}
        >
          <SVG src={SVGS.Pencil} color={colors.blue} />
          <Typography variant='labelSmall' color={colors.blue} sx={{ marginLeft: 1 }}>
            Edit My Profile
          </Typography>
        </Button>

        <Button variant='contained' className='editButton' fullWidth onClick={onClickLogout}>
          <Typography variant='labelSmall' color={colors.white} sx={{ marginLeft: 1 }}>
            Logout
          </Typography>
        </Button>
      </Box>
    </MyBasicInfoContainer>
  )
}
