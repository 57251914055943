/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useDeleteAccount = (userID: string) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.DELETE_ACCOUNT, userID],
    mutationFn: async () => {
      const response = await axios.api.post('ajax/users', {
        requestType: 'setAccountStatus',
        userID: userID,
        accountStatus: 2,
        format: 'json',
      })
      return response.data
    },
  })
}
