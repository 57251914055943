import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { AlbumPhoto, Note, PatternDraft, Topic } from '@/types'
import { Box, Typography, useTheme } from '@mui/material'
import { useMemo } from 'react'

import { getFullImageURL, isBabylonType } from '@/utils/helper'
import { NoteType, TopicType } from '@/utils/constants'
import TopicDetailsHeader from '@/pages/panels/details/TopicHeader'
import PatternNotesInteractionView from '@/pages/panels/details/PatternNotesInteractionView'
import PatternNotesGroupList from '@/pages/panels/details/PatternNotesGroupList'
import AlbumContent from '@/pages/panels/details/AlbumContent'
import { useMediaQuery } from 'usehooks-ts'
interface Props {
  isShowAction?: boolean
  topic: Topic
  firstNote?: Note
  previewImageURL?: string
  showPreviewImage: boolean
  showNotesOnly?: boolean
  onClickBack: () => void
  onShowInteractions: () => void
  onDeletedTopic?: () => void
  onClickLike: () => void
  onClickComment: () => void
  onClickPreview: () => void
  onClickLikesCount: () => void
  onClickAlbumNote?: (note: Note) => void
  onClickEditTopic?: () => void
}

export default function PreviewDetailsPanel({
  topic,
  firstNote,
  previewImageURL,
  showPreviewImage = false,
  showNotesOnly = false,
  onClickBack,
  onShowInteractions,
  onDeletedTopic,
  onClickLike,
  onClickComment,
  onClickPreview,
  onClickLikesCount,
  onClickAlbumNote,
  onClickEditTopic,
}: Props) {
  const [ewUser] = useLocalStorage('ewUser', null)
  const { setIsPrivacySelected, setInitialScreenShot } = useAppStore()
  const query = useBrowserQuery()
  const theme = useTheme()
  const dmTopicID = query.get('dmTopicID') || ''

  const patternDraft = useMemo(() => {
    if (!dmTopicID && topic && isBabylonType(topic.topicType) && topic?.notes?.length > 0) {
      const draft: PatternDraft = { templateID: 1 }
      topic.notes.forEach((note: Note) => {
        if (note.noteType === '8') {
          // setup initial text overlay
        } else if (note.noteType === '9') {
          // preview
        } else if (note.noteType !== '1' && note.noteText !== 'null' && note.noteText != null) {
          draft[note.noteName as keyof PatternDraft] = JSON.parse(note.noteText)
        }
      })
      return draft
    }
  }, [topic])

  const patternNoteGroups = useMemo(() => {
    let result = null
    if (patternDraft) {
      result = {}
      let templateNotes = topic.notes.filter((e) => e.noteType == NoteType.TEMPLATE.toString())
      let materialNotes = topic.notes.filter((e) => e.noteType == NoteType.MATERIAL.toString())
      let accentNotes = topic.notes.filter((e) => e.noteType == NoteType.ACCENT.toString())
      let printNotes = topic.notes.filter((e) => e.noteType == NoteType.PRINT.toString())
      let colorNotes = topic.notes.filter((e) => e.noteType == NoteType.COLOR.toString())
      result = {
        [NoteType.TEMPLATE]: templateNotes || [],
        [NoteType.MATERIAL]: materialNotes || [],
        [NoteType.ACCENT]: accentNotes || [],
        [NoteType.PRINT]: printNotes || [],
        [NoteType.COLOR]: colorNotes || [],
        patternDetails: patternDraft,
      }
    }
    return result
  }, [topic, patternDraft])

  const materialIDs = useMemo(() => {
    if (patternDraft) {
      const values = Object.values(patternNoteGroups?.patternDetails.materialID || [])
      const unique = new Set(values)
      return Array.from(unique)
    }
    return []
  }, [patternDraft])

  const photos = useMemo(() => {
    const res: Record<string, AlbumPhoto> = {}
    topic?.photos?.forEach((p) => (res[p.egoID] = p))
    return res
  }, [topic])

  const onClickPrivacy = () => {
    setIsPrivacySelected(true)
    onShowInteractions()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <TopicDetailsHeader
          showEditForOwner={!showNotesOnly}
          topic={topic as Topic}
          onClickBack={onClickBack}
          onClickComment={onShowInteractions}
          onClickPrivacy={onClickPrivacy}
          onDeletedTopic={onDeletedTopic}
          onClickEdit={onClickEditTopic}
        />

        <Box sx={{ px: '20px', mb: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontWeight={700} fontSize={20} sx={{ color: '#211E1E' }}>
            {topic.topicName}
          </Typography>
        </Box>

        {showPreviewImage && previewImageURL && (
          <Box
            borderRadius={'8px'}
            sx={{
              display: 'flex',
              flex: 1,
              mx: 2,
              width: '60%',
            }}
          >
            <Box
              borderRadius={'8px'}
              sx={{
                width: '100%',
                aspectRatio: 1,
                backgroundImage: `url(${getFullImageURL(previewImageURL)})`,
                backgroundSize: 'cover',
                '&::before': {
                  content: '""',
                  backgroundImage:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
                  borderRadius: '8px 8px 0 0',
                },
              }}
            ></Box>
          </Box>
        )}

        {isBabylonType(topic.topicType) && (
          <Box sx={{ mx: '20px', mt: '8px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <PatternNotesGroupList
                title={'Template'}
                patternNoteType={NoteType.TEMPLATE}
                data={patternNoteGroups?.[NoteType.TEMPLATE] || []}
                patternDetails={patternNoteGroups?.patternDetails}
              />
              {materialIDs.length == 1 && (
                <PatternNotesGroupList
                  title={'Material'}
                  patternNoteType={NoteType.MATERIAL}
                  data={patternNoteGroups?.[NoteType.MATERIAL] || []}
                  patternDetails={patternNoteGroups?.patternDetails}
                />
              )}
            </Box>

            {materialIDs.length > 1 && (
              <PatternNotesGroupList
                title={'Material'}
                patternNoteType={NoteType.MATERIAL}
                data={patternNoteGroups?.[NoteType.MATERIAL] || []}
                patternDetails={patternNoteGroups?.patternDetails}
              />
            )}

            {patternNoteGroups?.[NoteType.ACCENT].length! > 0 &&
              patternNoteGroups?.[NoteType.ACCENT][0].noteText != 'null' && (
                <PatternNotesGroupList
                  title={'Accent'}
                  patternNoteType={NoteType.ACCENT}
                  data={patternNoteGroups?.[NoteType.ACCENT] || []}
                  patternDetails={patternNoteGroups?.patternDetails}
                />
              )}
            <PatternNotesGroupList
              title={'Print'}
              patternNoteType={NoteType.PRINT}
              data={patternNoteGroups?.[NoteType.PRINT] || []}
              patternDetails={patternNoteGroups?.patternDetails}
            />
            <PatternNotesGroupList
              title={'Colors Used'}
              patternNoteType={NoteType.COLOR}
              data={patternNoteGroups?.[NoteType.COLOR] || []}
              patternDetails={patternNoteGroups?.patternDetails}
            />
          </Box>
        )}
        {topic.topicType == TopicType.ALBUM.toString() && (
          <AlbumContent
            notes={topic?.notes?.slice(0, -1) || []}
            photos={photos}
            onClickAlbumNote={onClickAlbumNote}
          />
        )}
      </Box>
      <Box>
        {!showNotesOnly && isBabylonType(topic.topicType) && (
          <PatternNotesInteractionView
            topic={topic}
            isLiked={firstNote?.isThumbsUp == 1}
            onClickLike={onClickLike}
            onClickComment={onClickComment}
            onClickPreview={onClickPreview}
            onClickLikesCount={onClickLikesCount}
          />
        )}
      </Box>
    </Box>
  )
}
