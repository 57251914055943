import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'
import ForgotPassword from '@/pages/auth/ForgotPassword'
import AuthLayout from '@/pages/auth/Layout'
import Login from '@/pages/auth/Login'
import SignUp from '@/pages/auth/SignUp'
import DashboardLayout from '@/pages/Layout'
import TopicListPage from '@/pages/topic'
import ConnectionsPage from '@/pages/connections'
import CirclesPage from '@/pages/circles/Circles'
import UserProfile from '@/pages/user-profile'
import MyProfile from '@/pages/my-profile'
import EditTopic from '@/pages/topic-management/Edit'
import CreateTopic from '@/pages/topic-management/Create'
import ShareInteractionsPage from '@/pages/share'
import SearchResult from '@/pages/search'
import NotificationPage from '@/pages/notifications'
import UploadsPage from '@/pages/uploads'
import PatternLayout from '@/pages/pattern/Layout'
import PatternPage from '@/pages/pattern'
import withAxios from '@/hoc/withAxios'
import GuestLandingLayout from '@/pages/guest-landing/Layout'
import TopicDetailsLayout from '@/pages/topic-details/Layout'
import { TopicPreviewBase } from '@/pages/topic-details/TopicPreviewBase'
import Home from '@/pages/home'
import { PrivacyType, TopicType } from '@/utils/constants'

export const RootRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<GuestLandingLayout />}>
        <Route path='auth' />
      </Route>

      <Route path='/login' element={<Navigate to='/auth?step=login' replace />} />
      <Route path='/signup' element={<Navigate to='/auth?step=signup' replace />} />
      <Route
        path='/forgot-password'
        element={<Navigate to='/auth?step=forgot-password' replace />}
      />

      <Route path='/' element={<DashboardLayout />}>
        <Route element={<TopicListPage />} />
        <Route path='home' element={<TopicListPage />} />
        <Route path='topic' element={<TopicListPage />} />
        <Route path='topic/edit/:id' element={<EditTopic />} />
        <Route path='share' element={<ShareInteractionsPage />} />
        <Route path='connections' element={<ConnectionsPage />} />
        <Route path='topic/create' element={<CreateTopic />} />
        <Route path='circles' element={<CirclesPage />} />
        <Route path='uploads' element={<UploadsPage />} />

        <Route path='profile'>
          <Route path='me' element={<MyProfile />} />
          <Route path='user' element={<UserProfile />} />
        </Route>

        <Route path='search' element={<SearchResult />} />
        <Route path='notification' element={<NotificationPage />} />
      </Route>

      <Route path='/discover' element={<DashboardLayout />}>
        <Route
          path='home'
          element={<TopicListPage topicType={TopicType.ALL} privacyType={PrivacyType.PUBLIC} />}
        />

        <Route
          path='pattern'
          element={<TopicListPage topicType={TopicType.PATTERN} privacyType={PrivacyType.PUBLIC} />}
        />

        <Route
          path='quilt'
          element={<TopicListPage topicType={TopicType.QUILT} privacyType={PrivacyType.PUBLIC} />}
        />
        <Route
          path='album'
          element={<TopicListPage topicType={TopicType.ALBUM} privacyType={PrivacyType.PUBLIC} />}
        />

        <Route
          path='gallery'
          element={<TopicListPage topicType={TopicType.GALLERY} privacyType={PrivacyType.PUBLIC} />}
        />
      </Route>

      <Route path='/workspace' element={<DashboardLayout />}>
        <Route path='all' element={<Home />} />
        <Route
          path='album'
          element={<TopicListPage topicType={TopicType.ALBUM} privacyType={PrivacyType.PRIVATE} />}
        />
        <Route path='upload' element={<UploadsPage />} />
        <Route
          path='pattern'
          element={
            <TopicListPage topicType={TopicType.PATTERN} privacyType={PrivacyType.PRIVATE} />
          }
        />
      </Route>

      <Route path='/projects' element={<DashboardLayout />}>
        <Route
          path='quilt'
          element={<TopicListPage topicType={TopicType.QUILT} privacyType={PrivacyType.PRIVATE} />}
        />
        <Route
          path='gallery'
          element={
            <TopicListPage topicType={TopicType.GALLERY} privacyType={PrivacyType.PRIVATE} />
          }
        />
      </Route>

      <Route path='/' element={<TopicDetailsLayout />}>
        <Route path='preview' element={<TopicPreviewBase />} />
      </Route>

      <Route path='/' element={<PatternLayout />}>
        <Route path='pattern' element={<PatternPage />} />
      </Route>
    </>,
  ),
)
