import React from 'react'
import { styled } from '@mui/material/styles'
import { Paper, Box, Typography } from '@mui/material'
import './styles.css'
import { Height } from '@mui/icons-material'
import colors from '@/styles/colors'

interface PatternItemCardProps {
  title: string
  authorName: string
  authorProfileImageURL: string
  patternImageSource: string
}

const useStyles = () => ({})

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}))

const PatternItemCard = (props: PatternItemCardProps) => {
  const styles = useStyles()
  return (
    <Item
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: 8,
        left: 8,
        right: 8,
        bottom: 8,
        overflow: 'hidden',
      }}
    >
      <img
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}
        src={props.patternImageSource}
      ></img>
      <Typography zIndex={1} color={colors.white}>
        {props.title}
      </Typography>
      <Typography zIndex={1} color={colors.white}>
        {props.authorName}
      </Typography>
    </Item>
  )
}

export default PatternItemCard
