import { ConnectionItem, UserAvatar } from "@/components";
import { useGetGroupMembersByID } from "@/hooks/api/dashboard";
import { useBrowserQuery } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { CircleConnection } from "@/types";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";

interface Props {
  onClosePrivacy: () => void
}

export default function CircleInteraction({ onClosePrivacy }: Props) {
  const [selectedID, setSelectedID] = useState('')
  const [topicList, myGroups] = useAppStore(
    state => [
      state.home.topicList,
      state.user.myGroups,
    ],
    shallow
  )
  const query = useBrowserQuery();
  const topicID = query.get("topicID") || '';

  const getGroupMembers = useGetGroupMembersByID();

  useEffect(() => {
    if (selectedID) {
      getGroupMembers.mutate(selectedID);
    }
  }, [selectedID])

  const cliques = topicList[topicID]?.cliques || [];

  const cliqueData = useMemo(() => {
    const ids = cliques?.map(i => i.cliqueID) || [];
    return myGroups.filter(i => ids.includes(i.circleID));
  }, [cliques, myGroups])

  const onToggleID = (id: string) => {
    if (selectedID === id) setSelectedID('');
    else setSelectedID(id)
  }

  const renderExpandedCircle = () => {
    if (getGroupMembers.isLoading) {
      return <CircularProgress />
    }
    return (
      <>
        {getGroupMembers.data?.map((m: CircleConnection) => (
          <ConnectionItem
            url={m.thumbImgURL}
            connID={m.userID}
            name={`${m.firstName} ${m.lastName}`}
            key={m.userID}
          />
        ))}
      </>
    )
  }

  return (
    <Box p={2}>
      <Box
        display={'flex'}
        alignItems="center"
        justifyContent="space-between"
        mt={-1}
        borderBottom={`1px solid ${colors.lightGrey}`}
      >
        <Typography variant='labelLarge'>
          {cliques.length} Circle{cliques.length > 1 ? 's' : ''} included
        </Typography>
        <IconButton onClick={onClosePrivacy} sx={{ marginRight: -1 }}>
          <Close />
        </IconButton>
      </Box>
      <Box flex={1} overflow="auto" height="100%">
        {cliqueData?.map(c => (
          <Box borderBottom={`1px solid ${colors.lightGrey}`} key={c.circleID}>
            <Box
              display={'flex'}
              alignItems="center"
              py={1}
              onClick={() => onToggleID(c.circleID)}
              sx={{ cursor: 'pointer' }}
            >
              <UserAvatar url={c.badgeThumbURL} name={c.circleName} />
              <Box ml={1} flex={1}>
                <Typography variant='label'>{c.circleName}</Typography>
                <Typography variant='pt'>{c.connCount} Member{Number(c.connCount) > 1 ? 's' : ''}</Typography>
              </Box>
              {selectedID === c.circleID
                ? <KeyboardArrowUp sx={{ color: colors.text }} />
                : <KeyboardArrowDown sx={{ color: colors.text }} />
              }
            </Box>
            <Box ml={1}>
              {selectedID === c.circleID && renderExpandedCircle()}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}