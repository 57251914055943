import { useGetTopicDetails, useInteractToNote } from '@/hooks/api/dashboard'
import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { NoteType, SIDE_PANEL_WIDTH, TopicType } from '@/utils/constants'
import { Box, CircularProgress, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { GradientContainer } from '@/pages/panels/__styled'

import { PatternNotesFooterPanel } from './molecules/PatternNotesFooterPanel'
import { Note, PatternDraft } from '@/types'
import PreviewDetailsPanel from './panels/DetailsPanel'
import { PreviewTopBar } from './molecules/PreviewTopBar'
import NoteLikes from '@/pages/panels/interactions/NoteLikes'
import NoteComments from '@/pages/panels/interactions/NoteComments'
import { getFullImageURL, isBabylonType } from '@/utils/helper'
import { TopicDetailsContext } from '@/context/TopicDetails'
import colors from '@/styles/colors'
import { RBox } from '@/pages/__styled'

export enum ViewStep {
  PREVIEW = '0',
  DETAILS = '1',
  COMMENTS = '2',
  LIKES = '3',
}

const PatternTopicPreview = () => {
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)

  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))

  const topicID = query.get('topicID') || ''
  const viewStep: ViewStep = (query.get('step') || '1') as ViewStep
  const interactToNote = useInteractToNote()
  const showDrawer: boolean = viewStep != ViewStep.PREVIEW
  const { topic, onClickBack } = useContext(TopicDetailsContext)
  const [showPatternNotesDrawer, setShowPatternNotesDrawer] = useState(false)

  const firstNote = useMemo(() => {
    const currentTopic = topicList[topicID]
    if (currentTopic) {
      if (currentTopic.firstNote && currentTopic?.notes?.length > 0) {
        const note = topic.notes.find((e) => e.noteID == topic.firstNote.noteID)
        return note
      } else {
        return currentTopic.firstNote
      }
    }
  }, [topicID, topicList])

  const lastNote = useMemo(() => {
    const currentTopic = topicList[topicID]
    if (currentTopic) {
      if (currentTopic.latestNote && currentTopic?.notes?.length > 0) {
        const note = topic.notes.find((e) => e.noteID == topic.latestNote.noteID)
        return note
      } else {
        return currentTopic.latestNote
      }
    }
  }, [topicID, topicList])

  const previewImageURL = useMemo(() => {
    if (topic) {
      if (isBabylonType(topic.topicType)) {
        return firstNote?.egoList[0]?.photoAlbumURL
      } else if (topic.topicType == TopicType.ALBUM.toString()) {
        return lastNote?.egoList[0]?.photoAlbumURL
      }
    }
  }, [firstNote, lastNote, topic])

  const patternNoteGroups = useMemo(() => {
    let result = null
    if (topic && topic?.notes?.length > 0 && isBabylonType(topic.topicType)) {
      const draft: PatternDraft = { templateID: 1 }
      topic.notes.forEach((note: Note) => {
        if (note.noteType === '8') {
          // setup initial text overlay
        } else if (note.noteType === '9') {
          // preview
        } else if (note.noteType !== '1' && note.noteText !== 'null' && note.noteText != null) {
          draft[note.noteName as keyof PatternDraft] = JSON.parse(note.noteText)
        }
      })

      result = {}
      let templateNotes = topic.notes.filter((e) => e.noteType == NoteType.TEMPLATE.toString())
      let materialNotes = topic.notes.filter((e) => e.noteType == NoteType.MATERIAL.toString())
      let accentNotes = topic.notes.filter((e) => e.noteType == NoteType.ACCENT.toString())
      let printNotes = topic.notes.filter((e) => e.noteType == NoteType.PRINT.toString())
      let colorNotes = topic.notes.filter((e) => e.noteType == NoteType.COLOR.toString())

      result = {
        [NoteType.TEMPLATE]: templateNotes || [],
        [NoteType.MATERIAL]: materialNotes || [],
        [NoteType.ACCENT]: accentNotes || [],
        [NoteType.PRINT]: printNotes || [],
        [NoteType.COLOR]: colorNotes || [],
        patternDetails: draft,
      }
    }
    return result
  }, [topic])

  const onClickEditTopic = () => {
    navigate(`/pattern?topicID=${topicID}`)
  }

  const onFooterCollapse = () => {
    query.delete('noteID')
    query.set('step', ViewStep.DETAILS)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickComment = () => {
    query.set('noteID', `${topic.firstNote.noteID}`)
    query.set('step', ViewStep.COMMENTS)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }
  const onClickLikesCount = () => {
    query.set('noteID', `${topic.firstNote.noteID}`)
    query.set('step', ViewStep.LIKES)

    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickExitPreview = () => {
    query.delete('noteID')
    query.set('step', ViewStep.DETAILS)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickPreview = () => {
    query.delete('noteID')
    query.set('step', ViewStep.PREVIEW)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }
  const onClickAlbumNote = (note: Note) => {
    query.set('noteID', `${note.noteID}`)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickLike = () => {
    if (firstNote) {
      interactToNote.mutateAsync({
        interactionType: '3',
        interaction: firstNote.isThumbsUp == 1 ? '0' : '1',
        comment: 'test',
        noteID: firstNote.noteID,
        topicID,
      })
    }
  }

  const onClickMenuIcon = () => {
    setShowPatternNotesDrawer(true)
  }

  const renderDetailsPanel = () => (
    <GradientContainer sx={{ backgroundColor: colors.pageBackground }}>
      {viewStep == ViewStep.DETAILS ? (
        <PreviewDetailsPanel
          topic={topic}
          showPreviewImage={lessThanMedium}
          previewImageURL={previewImageURL}
          firstNote={firstNote}
          onClickBack={onClickBack}
          onDeletedTopic={() => {}}
          onShowInteractions={() => {}}
          onClickLike={onClickLike}
          onClickComment={onClickComment}
          onClickPreview={onClickPreview}
          onClickLikesCount={onClickLikesCount}
          onClickAlbumNote={onClickAlbumNote}
          onClickEditTopic={onClickEditTopic}
        />
      ) : viewStep == ViewStep.LIKES ? (
        <NoteLikes trackKey='topicID' />
      ) : (
        <NoteComments trackKey='topicID' />
      )}
    </GradientContainer>
  )

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          display: 'flex',
          height: '100%',
          width: lessThanMedium ? '100%' : showDrawer ? `calc(100% - ${SIDE_PANEL_WIDTH})` : '100%',
        }}
      >
        {(!lessThanMedium || (lessThanMedium && viewStep != ViewStep.DETAILS)) && (
          <PreviewTopBar
            topic={topic}
            onClickBack={lessThanMedium ? onClickExitPreview : onClickBack}
            showingDetailsInPanel={viewStep == ViewStep.DETAILS}
            showTopicInfo={!showDrawer}
            isLiked={firstNote?.isThumbsUp == 1}
            onClickMenuIcon={onClickMenuIcon}
            onClickEdit={onClickEditTopic}
            onClickExitPreview={onClickExitPreview}
            onClickLike={onClickLike}
            onClickComment={onClickComment}
            onClickPreview={onClickPreview}
            onClickLikesCount={onClickLikesCount}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <>
            {(!lessThanMedium || (lessThanMedium && viewStep != ViewStep.DETAILS)) &&
              previewImageURL && (
                <Box
                  borderRadius={'8px'}
                  sx={{
                    aspectRatio: 1,
                    width: '35%',
                    backgroundImage: `url(${getFullImageURL(previewImageURL)})`,
                    backgroundSize: 'cover',
                    '&::before': {
                      content: '""',
                      height: '150px',
                      backgroundImage:
                        'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
                      borderRadius: '8px 8px 0 0',
                    },
                  }}
                ></Box>
              )}
          </>

          {showDrawer && !lessThanMedium && (
            <Drawer
              sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: SIDE_PANEL_WIDTH,
                  height: '100%',
                  borderWidth: 0,
                },
              }}
              variant='permanent'
              anchor='right'
              open={showDrawer}
            >
              {renderDetailsPanel()}
            </Drawer>
          )}

          {viewStep == ViewStep.DETAILS && lessThanMedium && (
            <Drawer
              sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: '100%',
                  height: '100%',
                  borderWidth: 0,
                },
              }}
              variant='permanent'
              open={true}
            >
              {renderDetailsPanel()}
            </Drawer>
          )}

          {viewStep != ViewStep.DETAILS && viewStep != ViewStep.PREVIEW && lessThanMedium && (
            <Drawer
              sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: '100%',
                  height: '100%',
                  borderWidth: 0,
                },
              }}
              variant='permanent'
              anchor='right'
              open={showDrawer}
            >
              {renderDetailsPanel()}
            </Drawer>
          )}
        </Box>
        {!lessThanMedium && viewStep != ViewStep.DETAILS && isBabylonType(topic.topicType) && (
          <PatternNotesFooterPanel
            patternNoteGroups={patternNoteGroups}
            onCollapse={onFooterCollapse}
          />
        )}
      </Box>

      {lessThanMedium && (
        <Drawer
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: SIDE_PANEL_WIDTH,
              height: '100%',
              borderWidth: 0,
            },
          }}
          variant='temporary'
          anchor='left'
          open={showPatternNotesDrawer}
          onClose={() => setShowPatternNotesDrawer(false)}
        >
          <PreviewDetailsPanel
            topic={topic}
            showPreviewImage={false}
            previewImageURL={previewImageURL}
            firstNote={firstNote}
            showNotesOnly={true}
            onClickBack={() => setShowPatternNotesDrawer(false)}
            onDeletedTopic={() => {}}
            onShowInteractions={() => {}}
            onClickLike={onClickLike}
            onClickComment={onClickComment}
            onClickPreview={onClickPreview}
            onClickLikesCount={onClickLikesCount}
            onClickAlbumNote={onClickAlbumNote}
            onClickEditTopic={onClickEditTopic}
          />
        </Drawer>
      )}
    </>
  )
}

export default PatternTopicPreview
