import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { UserAvatar } from "./Avatar";

interface Props {
  url: string
  connID: string
  name: string
}

const Container = styled(Box)({
  '& a': {
    textDecoration: 'none',
    marginLeft: 10
  }
})

export function ConnectionItem({ url, connID, name }: Props) {
  return (
    <Container display={'flex'} alignItems="center" my={1}>
      <UserAvatar url={url} name={name} />
      <Link to={`/profile?id=${connID}`}>
        <Typography variant='label'>
          {name}
        </Typography>
      </Link>
    </Container>
  )
}