import { IMAGES } from '@/assets/images'
import { ImageList, PreviewNote, TagTopic, UserAvatar } from '@/components'

import { useDeleteTopic } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { BackgroundImage } from '@/pages/__styled'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Topic } from '@/types'
import { PrivacyTypes, TopicTypes } from '@/utils/constants'
import {
  convertToGMT,
  getPlainNoteTextFromNote,
  getTopicStates,
  isBabylonType,
} from '@/utils/helper'
import { Box, Container, styled, Typography } from '@mui/material'
import { useEffect } from 'react'
import SVG from 'react-inlinesvg'
import ReactTimeago from 'react-timeago'

interface ItemProps {
  topic: Topic
  onClick: () => void
  onRemoved?: () => void
}

const TopicCard = styled(Box)({
  boxSizing: 'border-box',
  boxShadow: 'none',
  borderRadius: 2,
  sx: {
    p: 2,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '268px',
    height: '277px',
  },
  border: `1px solid ${colors.cardBorderColor}`,
  '&[aria-selected = true]': {
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    border: `1px solid ${colors.yellow}`,
  },
})

export default function DiscussionTopicItem({ topic, onClick, onRemoved }: ItemProps) {
  // const [modalOpened, setModalOpened] = useState(false)
  const { removeTopic } = useAppStore()

  const deleteTopic = useDeleteTopic(topic.topicID)
  const [ewUser] = useLocalStorage('ewUser', null)
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''

  useEffect(() => {
    if (deleteTopic.isSuccess) {
      removeTopic(topic.topicID)
      onRemoved && onRemoved()
    }
  }, [deleteTopic.isSuccess])

  const topicData = TopicTypes[Number(topic.topicType)]
  const privacyData = PrivacyTypes[Number(topic.visibility)]
  if (!topicData || !privacyData) return null

  const renderTopicPreviewImage = () => {
    const images = !!topic.latestNote?.egoList
      ? topic.latestNote?.egoList
      : IMAGES.topicPreviewPlaceholder

    if (!isBabylonType(topic.topicType)) {
      return (
        <Box sx={{ borderRadius: 2, mb: 1, maxHeight: '138px' }}>
          {/* eslint-disable-next-line */}
          <ImageList images={images.length > 0 ? images : [IMAGES.topicPreviewPlaceholder]} />
        </Box>
      )
    }
  }

  return (
    <Box
      py={'18px'}
      px={'20px'}
      border={`1px solid ${topicID === topic.topicID ? '#CCD4DC' : colors.cardBorderColor}`}
      borderRadius={2}
      boxShadow={topicID === topic.topicID ? '0 5px 15px 0px #0000000F' : 'none'}
      boxSizing={'border-box'}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '365px',
        height: '114px',
        '&:hover': {
          boxShadow: '0 5px 15px 0px #0000000F',
          border: `1px solid #CCD4DC`,
        },
      }}
    >
      <Box display='flex' flexDirection={'column'} gap={1}>
        {topic.dateUpdated && (
          <Typography
            variant='p'
            fontSize={10}
            lineHeight={'10px'}
            sx={{ color: colors.themePurpleTextColor }}
          >
            Created <ReactTimeago date={convertToGMT(topic.dateUpdated)} />
          </Typography>
        )}
        <Typography className='draft-section last-note-content' variant='h2'>
          {getPlainNoteTextFromNote(topic.firstNote)}
        </Typography>
        <Box flex={1} display='flex' alignItems='center' gap='15px'>
          {getTopicStates(topic).map((item) => {
            return (
              <Box display='flex' alignItems='center' key={item.key}>
                <SVG src={item.icon} width={14} height={14} color={colors.themePurpleTextColor} />
                <Typography
                  variant='labelSmall'
                  sx={{ marginLeft: 1, color: colors.themePurpleTextColor }}
                >
                  {item.value}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>

      {/* <RModal
        open={modalOpened}
        onClose={onCloseModal}
        title="Delete Topic"
        description="Are you sure you want to delete this topic?"
        submitText="Delete"
        onSubmit={onClickDelete}
      /> */}
    </Box>
  )
}
