import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Dayjs } from 'dayjs'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'

interface PickerProps {
  value?: Dayjs | null
  isError?: boolean
  errorText?: string
  onChange: (date: Dayjs | null) => void
}

const CustomCalendarIcon: React.FC<any> = (props) => <SVG src={SVGS.CalendarColoredIcon} />

export function MyDatePicker({ isError, errorText, onChange, value }: PickerProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={onChange}
        sx={{ width: '100%' }}
        value={value}
        slots={{ openPickerIcon: CustomCalendarIcon }}
      />
      {errorText && isError && (
        <Box mt={0.5}>
          <Typography variant='label' color={'error.main'}>
            {errorText}
          </Typography>
        </Box>
      )}
    </LocalizationProvider>
  )
}
