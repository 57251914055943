/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, MyConnection } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useGetMyConnections = () => {
  const axios = useContext(AxiosContext)
  const { setMyConnections } = useAppStore()
  const [myConnections] = useAppStore((state) => [state.user.myConnections], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_MY_CONNECTIONS],
    mutationFn: async (page: number) => {
      const response = await axios.api.post('ajax/circles', {
        requestType: 'connList',
        format: 'json',
        full: 1,
        pageNo: page,
      })
      if (page === 1) {
        setMyConnections(response.data?.connections || [])
      } else if (response.data?.connections) {
        response.data?.connections?.forEach((conn: MyConnection) => {
          const index = myConnections.findIndex((i) => i.userID === conn.userID)
          if (index < 0) myConnections.push(conn)
          else {
            myConnections.splice(index, 1, conn)
          }
        })
        setMyConnections([...myConnections])
      }
      return response.data?.connections || []
    },
  })
}
