import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, SearchType, Topic, TopicListFilter } from '@/types'
import { useContext, useRef, useState } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { PrivacyType, TopicType } from '@/utils/constants'

interface GetTopicsParams {
  connID?: string
  searchType?: SearchType
  pageNo?: number
  privacyType?: PrivacyType
  topicType?: TopicType
  filter?: TopicListFilter
}

export const useTopicsListViewModel = () => {
  const [pagination, setPagination] = useState()
  const [topicsList, setTopicsList] = useState<Topic[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [canLoadNextPage, setCanLoadNextPage] = useState(false)
  const axios = useContext(AxiosContext)
  let paramsRef = useRef<GetTopicsParams | null>(null)

  const getTopics = async (params: GetTopicsParams) => {
    paramsRef.current = params

    if (params.pageNo == 1) {
      setLoading(true)
    } else {
      setLoadingNextPage(true)
    }

    const response = await axios.api.post('ajax/topics', {
      requestType: 'topicList',
      list: 'mini',
      format: 'json',
      searchType: 1,
      ...params,
    })
    setLoading(false)
    setLoadingNextPage(false)

    let filteredResult = (response.data?.myTopics || []).filter((e: Topic) => e.topicType != '100')
    if (params.pageNo == 1) {
      setTopicsList(filteredResult)
    } else {
      setTopicsList((prev) => [...prev, ...filteredResult])
    }
    setPagination(response.data.pagination)

    if (response.data.pagination?.currentPage == response.data.pagination?.totalPages) {
      setCanLoadNextPage(false)
    } else {
      setCanLoadNextPage(true)
    }
  }

  const loadNextPageData = () => {
    if (paramsRef.current != null && canLoadNextPage) {
      getTopics({ ...paramsRef.current, pageNo: (paramsRef.current.pageNo || 1) + 1 })
    }
  }

  return {
    topicsList,
    pagination,
    loading,
    loadingNextPage,
    getTopics,
    canLoadNextPage,
    loadNextPageData,
  }
}
