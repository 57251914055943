import { useBrowserQuery } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ShareSearchContent } from "../__styled";
import ShareConnections from "./connections";
import ShareInteractions from "./reactions";

interface Props {
  showDetails?: boolean;
  onClickTopic: () => void;
}

export default function ShareSearchList({ showDetails, onClickTopic }: Props) {
  const navigate = useNavigate();
  const query = useBrowserQuery();
  const { pathname } = useLocation();
  const paramTab = query.get("tab");

  const [viewTab, setViewTab] = useState(paramTab === '2' ? 2 : 1);
  const { setTopicList } = useAppStore();

  useEffect(() => {
    setViewTab(paramTab === '2' ? 2 : 1)
  }, [paramTab])

  useEffect(() => {
    query.set('tab', String(viewTab));
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }, [viewTab])

  useEffect(() => {
    setTopicList({})
  }, [viewTab])

  const onClickTopicItem = (id: string) => {
    query.set('topicID', id);
    query.delete('connID');
    navigate(`${pathname}?${query.toString()}`, { replace: true });
    onClickTopic();
  }

  const onClickUser = (id: string) => {
    query.set('connID', id);
    query.delete('topicID')
    navigate(`${pathname}?${query.toString()}`, { replace: true });
  }

  return (
    <ShareSearchContent height="100%" position={'relative'} display="flex" flexDirection={'column'}>
      <Box className="tab" display="none">
        <Button className={viewTab === 1 ? 'active' : ''} onClick={() => setViewTab(1)}>
          Reactions & Shares
        </Button>
        <Button className={viewTab === 2 ? 'active' : ''} onClick={() => setViewTab(2)}>
          Connections
        </Button>
      </Box>
      {viewTab === 1 && <ShareInteractions showDetails={showDetails} onClickTopic={onClickTopicItem} />}
      {viewTab === 2 && <ShareConnections onClickTopic={onClickTopicItem} onClickUser={onClickUser} />}
    </ShareSearchContent>
  )
}