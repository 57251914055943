/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useDeleteComment = (topicID: string, noteID: string) => {
  const axios = useContext(AxiosContext)
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)

  const deleteNoteComment = () => {
    const topic = topicList[topicID]
    if (topic) {
      const commentsCount = `${Number(topic.totalComments) - 1}`
      const updatedNotes = topic?.notes?.map((i) => {
        if (i.noteID == noteID) {
          return {
            ...i,
            comments: i.comments - 1,
          }
        } else {
          return i
        }
      })

      updateTopicList({
        [topicID]: {
          ...topic,
          notes: updatedNotes,
          totalComments: commentsCount,
        },
      })
    }
  }

  return useMutation({
    mutationKey: [ReactQueryKeys.DELETE_PHOTO_COMMENT, noteID],
    mutationFn: async (commentID: string) => {
      deleteNoteComment()
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteDeleteComment',
        commentID: commentID,
        noteID,
        format: 'json',
      })
      return response.data
    },
  })
}
