/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { DMConnection, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useGetDMTopicDetails = (topicID: string) => {
  const [ewUser] = useLocalStorage('ewUser', null)
  const axios = useContext(AxiosContext)
  const { setMyDMConnections } = useAppStore()
  const [myDMConnections] = useAppStore((state) => [state.user.myDMConnections], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_DM_TOPIC_DETAILS, topicID, ewUser?.userID],
    mutationFn: async () => {
      const index = myDMConnections.findIndex((i) => i.dmTopicID === topicID)
      if (index < 0) return
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicLoad',
        topicID,
        userID: ewUser?.userID,
        format: 'json',
      })
      const topicData: DMConnection = response.data as DMConnection
      myDMConnections[index] = {
        ...myDMConnections[index],
        ...topicData,
        userID: myDMConnections[index].userID,
        lastNote: topicData.notes[0],
        topicStats: {
          ...myDMConnections[index].topicStats,
          noteThumbsUp: topicData.totalLikes,
          noteComments: topicData.totalComments,
          visitorVisits: topicData.visitorVisits,
          egoCount: String(topicData.photos?.length || 0),
        },
      }
      setMyDMConnections([...myDMConnections])
      return topicData
    },
  })
}
