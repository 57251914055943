import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { CognitoUser } from '@/types'
import { useLocalStorage } from './useLocalStorage'
import { HOME_PATH } from '@/utils/constants'

export const useAuth = () => {
  const [user, setUser] = useLocalStorage('ewUser', null)
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  const login = (data: CognitoUser) => {
    setUser(data)
    navigate(HOME_PATH)
  }

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null)
    navigate('/login', { replace: true })
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  )
  return value
}
