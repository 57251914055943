/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

interface Param {
  connReqID: string
  cliques: string[] // clique ids
}

export const useConnectionAccept = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.ADD_CONNECTION],
    mutationFn: async (data: Param) => {
      const response = await axios.api.post('ajax/connections', {
        requestType: 'connReqUpdate',
        connReqID: data.connReqID,
        'cliqueIDs[]': data.cliques,
        connReqStatus: 1,
        format: 'json',
      })
      return response.data
    },
  })
}
