export enum ReactQueryKeys {
  LOGIN = 'LOGIN',
  LOGIN_COGNITO = 'LOGIN_COGNITO',
  UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS',
  SIGNUP = 'SIGNUP',
  CONFIRM_SIGNUP = 'CONFIRM_SIGNUP',
  REGISTER_COGNITO = 'REGISTER_COGNITO',
  RESEND_SIGNUP_CODE = 'RESEND_SIGNUP_CODE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD',
  GET_BASIC_INFO = 'GET_BASIC_INFO',
  GET_TOPICS = 'GET_TOPICS',
  GET_NOTE_LIKES = 'GET_NOTE_LIKES',
  GET_TOPIC_DETAILS = 'GET_TOPIC_DETAILS',
  GET_DM_TOPIC_DETAILS = 'GET_DM_TOPIC_DETAILS',
  UPLOAD_MEDIA = 'UPLOAD_MEDIA',
  ADD_MEDIA_TOPIC = 'ADD_MEDIA_TOPIC',
  ADD_NOTE_TOPIC = 'ADD_NOTE_TOPIC',
  NOTE_LOAD = 'NOTE_LOAD',
  INTERACT_ALBUM = 'INTERACT_ALBUM',
  DELETE_TOPIC = 'DELETE_TOPIC',
  DELETE_EGO = 'DELETE_EGO',
  NOTE_DELETE = 'NOTE_DELETE',
  NOTE_UPDATE = 'NOTE_UPDATE',
  GET_GROUP_DETAILS = 'GET_GROUP_DETAILS',
  GET_MY_GRUOPS = 'GET_MY_GRUOPS',
  GET_MY_CONNECTIONS = 'GET_MY_CONNECTIONS',
  GET_MY_DIRECT_MESSAGES = 'GET_MY_DIRECT_MESSAGES',
  GET_USER_CONNECTIONS = 'GET_USER_CONNECTIONS',
  UPDATE_TOPIC = 'UPDATE_TOPIC',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  GET_PERMISSIONS_PROFILE = 'GET_PERMISSIONS_PROFILE',
  UPDATE_VISIBILITY = 'UPDATE_VISIBILITY',
  CREATE_TOPIC = 'CREATE_TOPIC',
  DELETE_PHOTO_COMMENT = 'DELETE_PHOTO_COMMENT',
  INVITE_USER = 'INVITE_USER',
  UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE',
  DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_CONNECTION = 'DELETE_CONNECTION',
  BLOCK_CONNECTION = 'BLOCK_CONNECTION',
  ADD_CONNECTION = 'ADD_CONNECTION',
  ACCEPT_CONNECTION = 'ACCEPT_CONNECTION',
  DECLINE_CONNECTION = 'DECLINE_CONNECTION',
  LOGOUT = 'LOGOUT',
  CREATE_GROUP = 'CREATE_GROUP',
  SEARCH_USERS = 'SEARCH_USERS',
  SEARCH_TOPICS = 'SEARCH_TOPICS',
  CONNECTION_RECOMMENDS = 'CONNECTION_RECOMMENDS',
  CONNECTION_REQUESTS = 'CONNECTION_REQUESTS',
  GET_CONTAINERS = 'GET_CONTAINERS',
  GET_CONTAINER_DETAIL = 'GET_CONTAINER_DETAIL',
}
