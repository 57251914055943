import colors from '@/styles/colors'
import { Padding } from '@mui/icons-material'
import { Box, Button, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  height: '69px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  boxShadow: '0 1px 2px rgba(0,0,0,.1)',
  zIndex: 10,
  [theme.breakpoints.down('md')]: {
    padding: '0 0 0 10px',
  },
  '& .logo': {
    width: 136,
    height: 30,
    [theme.breakpoints.down('sm')]: {
      width: 30,
    },
  },
  '& .avatar': {
    margin: '0 15px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}))

export const HeaderTitleSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-end',
  },
}))

export const PatternEditContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  bottom: 0,
})

export const PatternContainer = styled(Box)({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  height: '100%',
  '.gallery-template': {
    cursor: 'pointer',
    '&.active': {
      border: `3px solid ${colors.yellow}`,
    },
  },
})

export const HelperTextWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 10,
  height: 40,
  display: 'flex',
  alignItems: 'center',
})

export const ToolBarWrapper = styled(Box)({
  position: 'absolute',
  right: 0,
  left: 0,
  top: 0,
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
})

export const ToolBarContainer = styled(Box)({
  padding: 6,
  display: 'flex',
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  height: 'fit-content',
})

export const ToolBarIcon = styled(IconButton)({
  color: '#C7C7C7',
  margin: 6,
})

export const PatternAssetWrapper = styled(Box)({
  position: 'relative',
  '& .content': {
    display: 'flex',
    overflowY: 'auto',
  },
  '& .tab': {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    '& button': {
      border: `1px solid ${colors.meshdomBlue}`,
      borderRadius: 5,
      color: colors.meshdomBlue,
      width: '100%',
      '& svg': {},
      '&.active': {
        color: 'white',
        border: `1px solid ${colors.meshdomBlue}`,
        backgroundColor: colors.meshdomBlue,
      },
      '&.hover': {
        '& svg': {
          color: 'white',
        },
      },
    },
  },
})

export const PatternAssetList = styled(Box)<{ category: string }>(({ category }) => ({
  flex: 1,
  color: 'black',
  display: 'flex',
  flexWrap: 'wrap',
  gridTemplateColumns: `repeat(auto-fill, ${category === 'color' ? 70 : 140}px)`,
  placeContent: 'flex-start center',
  gap: '15px',
  overflowY: 'auto',
  backgroundColor: '#cfcfcf',
  paddingTop: '12px',
  paddingBottom: '12px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
}))

export const PatternAssetButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px',
  '&.active': {
    color: '#2884FF',
  },
})

export const ButtonDivider = styled(Box)({
  width: '21px',
  height: '1px',
  backgroundColor: '#CAD1D9',
})

export const AssetToggleButtonWrapper = styled(Box)({
  position: 'absolute',
  top: 20,
  right: 36,
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const AssetToggleButton = styled(IconButton)({
  backgroundColor: colors.grey,
  '&:hover': {
    backgroundColor: colors.lightGrey,
  },
})

export const AssetItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: 10,
  boxSizing: 'border-box',
  border: `3px solid transparent`,
  '&.active': {
    border: `3px solid ${colors.themeOrange}`,
  },
})

export const AssetColorItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '40px',
  height: '40px',
  borderRadius: '5px',
  border: `2px solid transparent`,
  '&.active': {
    border: `2px solid ${colors.themeOrange}`,
  },
})

export const PatternProgressLoader = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#000000AA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const PrintContainer = styled(Box)({
  display: 'flex',
  height: '100%',
  flex: 1,
  width: 'inherit',
  flexDirection: 'column',
  backgroundColor: 'white',
})

export const PrintTabButton = styled(Button)({
  height: '30px',
  width: '50%',
  color: '#211E1E',
  borderRadius: 0,
  fontSize: '10px',
  '&.active': {
    color: `${colors.blue}`,
    borderBottom: `2px solid ${colors.blue}`,
  },
})

export const SettingButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  height: '69px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'flex-end',
  padding: '0 10px',
  zIndex: 10,
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    top: 10,
    right: 10,
    height: '45px',
  },
}))

export const AlbumAndUploads = styled(Box)({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: 120,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& button': {
    borderRadius: 4,
    border: `2px solid transparent`,
    margin: '0 4px',
    '&.selected': {
      border: `2px solid ${colors.yellow}`,
    },
  },
})
