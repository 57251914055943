import { Box, CircularProgress, SxProps } from '@mui/material'
import React from 'react'
import loadingGif from '@/assets/images/loading.gif'

interface LoadingAnimatedViewProps {
  showGif: boolean
  styles?: SxProps
}

const LoadingAnimatedView = ({ showGif = false, styles }: LoadingAnimatedViewProps) => {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={styles}>
      {showGif ? <img src={loadingGif} alt='loading...' /> : <CircularProgress />}
    </Box>
  )
}

export default LoadingAnimatedView
