/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { ReactQueryKeys } from '@/types'
import { validateEmail, validatePhoneNumber } from '@/utils/helper'

export const useSignup = (user: string, password: string) => {
  return useMutation({
    mutationKey: [ReactQueryKeys.SIGNUP, user, password],
    mutationFn: async (): Promise<boolean> => {
      await Auth.signUp({
        username: user,
        password: password,
        attributes: {
          email: validateEmail(user) ? user : '',
          given_name: '',
          family_name: '',
          phone_number: validatePhoneNumber(user) ? user : '',
        },
      })
      return true
    },
  })
}
