import { useGetUserConnections } from '@/hooks/api/dashboard'
import { MyConnection } from '@/types'
import { Box, Grid, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingButton } from '@mui/lab'
import SVG from 'react-inlinesvg'
import { TopicTypes } from '@/utils/constants'
import colors from '@/styles/colors'
import ConnectionGridItem from '@/pages/search/results/ConnectionGridItem'
import { ConnectionsListContext } from '@/context/ConnectionsListContext'

export default function UserConnections() {
  const { items, loading, loadNextPageData, canLoadNextPage, loadingNextPage } =
    useContext(ConnectionsListContext)
  /*
  const users = useMemo(() => {
    const list = Object.values(userConnections || {})
    // sort by date updated
    list.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`
      const nameB = `${b.firstName} ${b.lastName}`
      if (nameA > nameB) return 1
      if (nameA < nameB) return -1
      return 0
    })
    return list
    
  }, [userConnections])
  */

  if (!loading && items && items.length == 0) {
    return (
      <Box p={5} display='flex' flexDirection={'column'} alignItems={'center'}>
        <SVG
          src={TopicTypes[2].icon}
          width={76}
          height={76}
          className={'icon'}
          color={colors.grey}
        />
        <Typography variant='h3' textAlign={'center'} mt={2} mb={1}>
          User has no connections.
        </Typography>
      </Box>
    )
  }

  return (
    <Box flexDirection={'column'} display={'flex'} flex={1}>
      {loading && (
        <Box mt={1} display='flex' justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      <Box
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        p={2}
        sx={{ overflow: 'auto' }}
      >
        <Grid container spacing={2} flex={1}>
          {(items || []).map((item, index) => (
            <Grid item xs={'auto'} key={item.userID}>
              <ConnectionGridItem
                key={item.userID}
                user={{ ...item }}
                onClickViewProfile={() => {}}
                onClickAccept={(user) => {}}
                onClickFollow={(user) => {}}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {items && items.length > 0 && (
        <Box m={3} display='flex' justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={loadNextPageData}
            loading={loadingNextPage}
            disabled={!canLoadNextPage}
          >
            {!canLoadNextPage ? 'No more results' : 'Show More'}
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}
