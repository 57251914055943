/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'

export const useGetConnectRequests = () => {
  const axios = useContext(AxiosContext);
  const { setConnRequests } = useAppStore();
  return useMutation({
    mutationKey: [ReactQueryKeys.CONNECTION_REQUESTS],
    mutationFn: async () => {
      const response = await axios.api.post('ajax/connections', {
        requestType: "connReqsLoad",
        format: "json",
      })
      setConnRequests(response.data?.connRequests || [])
      return response.data?.connRequests || []
    },
  })
}
