import { Comment } from '@/types'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import colors from '@/styles/colors'
import { DeleteOutline, MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import ReactTimeago from 'react-timeago'
import { convertToGMT } from '@/utils/helper'
import { UserAvatar } from './Avatar'
import React from 'react'

interface CommentProps {
  comment: Comment
  deletable: boolean
  onClickDelete?: () => void
}

export function InteractionComment({ comment, deletable, onClickDelete }: CommentProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDelete = () => {
    handleClose()
    if (onClickDelete) onClickDelete()
  }

  return (
    <Box py={1}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <UserAvatar
            size={35}
            borderRadius={35}
            name={comment.senderName}
            url={comment.profilePicURL}
          />
          <Typography variant='label'>{comment.senderName}</Typography>
        </Box>
        <Box display='flex' justifyContent={'space-between'} alignItems='center'>
          <Typography variant='pt' fontSize={10} color={colors.darkGrey} ml={1}>
            <ReactTimeago date={convertToGMT(comment.dateAdded)} />
          </Typography>
          <Box width={40}>
            {deletable && (
              <IconButton
                onClick={handleClick}
                id='demo-positioned-button'
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreHorizIcon width={24} height={24} sx={{ color: colors.darkGrey }} />
              </IconButton>
            )}
            <Menu
              id='options-menu'
              aria-labelledby='demo-positioned-button'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>

      <Box flex={1} mx={'44px'} mt={'-8px'}>
        <Typography
          fontSize={'13px'}
          fontWeight={400}
          lineHeight={'18px'}
          className='comment'
          color={colors.darkGrey}
        >
          {comment.comment}
        </Typography>
      </Box>
    </Box>
  )
}
