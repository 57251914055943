/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Circle,
  ConnectionRecommendation,
  ConnectionRequest,
  Container,
  DMConnection,
  EgoBasicInfo,
  IUserSlice,
  IUserStore,
  MyConnection,
  ResourcePermission,
  SearchConnection,
} from '@/types'

const initialState: IUserStore = {
  profile: null,
  myGroups: [],
  myConnections: [],
  myDMConnections: [],
  myContainers: [],
  connRequests: [],
  recommendations: [],
  myPermission: null,
  userConnections: {},
  searchConnections: {},
  selectedUserProfile: null,
  connectAddUserID: '',
  connectAcceptRequestID: '', // from-reqID-userID
  connectionBlockUserID: '',
  connectionDeclineRequestID: '',
  connectionDeleteUserID: '',
  userSearchKeyword: '',
}

export const createUserSlice = (set: any): IUserSlice => ({
  user: initialState,
  setProfile: (data: EgoBasicInfo) =>
    set((state: any) => ({
      user: {
        ...state.user,
        profile: data,
      },
    })),
  setMyGroups: (circles: Circle[]) =>
    set((state: any) => ({
      user: {
        ...state.user,
        myGroups: circles,
      },
    })),
  setMyConnections: (connections: MyConnection[]) =>
    set((state: any) => ({
      user: {
        ...state.user,
        myConnections: connections,
      },
    })),
  setMyDMConnections: (connections: DMConnection[]) =>
    set((state: any) => ({
      user: {
        ...state.user,
        myDMConnections: connections,
      },
    })),
  setMyContainers: (containers: Container[]) =>
    set((state: any) => ({
      user: {
        ...state.user,
        myContainers: containers,
      },
    })),
  setMyPermission: (permission: ResourcePermission) =>
    set((state: any) => ({
      user: {
        ...state.user,
        myPermission: permission,
      },
    })),
  setSelectedUserProfile: (data: EgoBasicInfo) =>
    set((state: any) => ({
      user: {
        ...state.user,
        selectedUserProfile: data,
      },
    })),
  setSearchConnections: (data: Record<string, SearchConnection>) =>
    set((state: any) => ({
      user: {
        ...state.user,
        searchConnections: data,
      },
    })),
  setUserConnections: (data: Record<string, MyConnection>) =>
    set((state: any) => ({
      user: {
        ...state.user,
        userConnections: data,
      },
    })),
  setConnRequests: (requests: ConnectionRequest[]) =>
    set((state: any) => ({
      user: {
        ...state.user,
        connRequests: requests,
      },
    })),
  setRecommendations: (recommendations: ConnectionRecommendation[]) =>
    set((state: any) => ({
      user: {
        ...state.user,
        recommendations: recommendations,
      },
    })),

  setUserSearchKeyword: (keyword: string) =>
    set((state: any) => ({
      user: {
        ...state.user,
        userSearchKeyword: keyword,
      },
    })),
})

export default createUserSlice
