import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

export const AuthSideView = styled(Grid)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  backgroundColor: theme.palette.brand.main,
  padding: '70px',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const LogoView = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& img': {
    width: '100%',
    objectFit: 'contain',
    maxHeight: 417,
  },
})

export const AuthFormView = styled(Grid)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  backgroundColor: 'white',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const AuthFormLogo = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  backgroundColor: theme.palette.brand.main,
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const AuthFormBox = styled(Box)(({ theme }) => ({
  height: 'auto',
  padding: '80px',
  width: '100%',
  boxSizing: 'border-box',

  // [theme.breakpoints.down('lg')]: {
  //   padding: '40px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   maxWidth: '600px',
  // },
  [theme.breakpoints.down('sm')]: {
    padding: '40px',
  },
  '& .MuiInputBase-root': {
    marginTop: '25px',
  },

  '& .loginButton': {
    marginTop: '25px',
    borderRadius: '100px',
  },
  '& a': {
    textDecoration: 'none',
  },
}))
