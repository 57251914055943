import { RBox } from '@/pages/__styled'
import { AssetItem } from '@/pages/pattern/__styled'
import colors from '@/styles/colors'
import { theme } from '@/styles/theme'
import { Note, PatternDraft, PatternMeshColor } from '@/types'
import { NoteType } from '@/utils/constants'
import { getAssetThumb } from '@/utils/helper'
import { Box, List, Typography } from '@mui/material'
import { styled } from '@mui/system'

interface PatternNotesGroupListProps {
  title: string
  patternNoteType: NoteType
  data: Note[] // sectioned data, notes inside groups
  patternDetails?: PatternDraft
}

export const NoteItemBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '62px',
  height: '62px',
  borderRadius: '5px',
  borderWidth: 1,
  border: '1px solid #e5e5e5',
  overflow: 'hidden',
  '&.large': {
    width: '100px',
    height: '100px',
  },
})

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'hidden',
    overflowX: 'auto',
    gap: 2,
  },

  colorItemDiv: {
    width: '35px',
    height: '35px',
    borderRadius: '5px',
    borderWidth: 1,
    border: '1px solid #e5e5e5',
    overflow: 'hidden',
  },
})

interface DataValue {
  imagePath?: string
  color?: PatternMeshColor
}

const PatternNotesGroupList = (props: PatternNotesGroupListProps) => {
  const styles = useStyles()
  let colors: Set<string> = new Set()
  let imagePaths: Set<string> = new Set()

  if (props.patternNoteType == NoteType.TEMPLATE) {
    imagePaths.add(getAssetThumb(NoteType.TEMPLATE, props.patternDetails?.templateID || 0) || '')
  } else if (props.patternNoteType == NoteType.MATERIAL) {
    Object.values(props.patternDetails?.materialID || []).forEach((e) => {
      if (e) {
        let path = getAssetThumb(NoteType.MATERIAL, e || 0)
        if (path) {
          imagePaths.add(path)
        }
      }
    })
  }

  if (props.patternNoteType == NoteType.ACCENT) {
    imagePaths.add(getAssetThumb(NoteType.ACCENT, props.patternDetails?.accentID || 0) || '')
  } else if (props.patternNoteType == NoteType.PRINT) {
    Object.values(props.patternDetails?.print || [])
      .map((e) => e?.id)
      .forEach((x) => {
        if (x) {
          let path = getAssetThumb(NoteType.PRINT, x)
          if (path) {
            imagePaths.add(path)
          }
        }
      })
  } else if (props.patternNoteType == NoteType.COLOR) {
    Object.values(props.patternDetails?.color || {}).forEach((e) => {
      if (Array.isArray(e) && e.length > 0) {
        e.forEach((color) => {
          colors.add(`rgb(${color!.r * 255},${color!.g * 255},${color!.g * 255})`)
        })
      }
    })
  }

  return (
    <Box>
      {colors.size > 0 || imagePaths.size > 0 ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            background: 'white',
            borderRadius: '5px',
            my: '15px',
            padding: '7px 10px 10px 10px',
          }}
        >
          <Typography fontWeight={400} fontSize={11} sx={{ color: '#211E1E', mb: '6px' }}>
            {props.title}
          </Typography>
          {props.data?.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                overflowX: 'auto',
              }}
            >
              {props.patternNoteType == NoteType.COLOR ? (
                <RBox gap={'10px'}>
                  {Array.from(colors).map((e, i) => (
                    <Box
                      key={i}
                      sx={{
                        ...styles.colorItemDiv,
                        backgroundColor: e,
                      }}
                    />
                  ))}
                </RBox>
              ) : (
                <RBox gap={'10px'}>
                  {Array.from(imagePaths).map((e, i) => (
                    <NoteItemBox
                      className={
                        props.patternNoteType == NoteType.TEMPLATE ||
                        props.patternNoteType == NoteType.MATERIAL
                          ? 'large'
                          : ''
                      }
                      sx={{
                        backgroundColor:
                          props.patternNoteType == NoteType.TEMPLATE ||
                          props.patternNoteType == NoteType.ACCENT
                            ? theme.palette.brand.light
                            : 'transparent',
                      }}
                      key={i}
                    >
                      <img style={{ width: '100%', height: '100%' }} src={e} alt='image' />
                    </NoteItemBox>
                  ))}
                </RBox>
              )}
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export default PatternNotesGroupList
