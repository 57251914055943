import { Checkbox, CheckboxProps, useTheme } from '@mui/material'
import { SVGS } from '@/assets/svgs'
import SVG from 'react-inlinesvg'
import colors from '@/styles/colors'

export function CheckBox(props: CheckboxProps) {
  return (
    <Checkbox
      icon={<SVG src={SVGS.UncheckedIcon} width={18} height={18} color={colors.grey} />}
      checkedIcon={<SVG src={SVGS.CheckedIcon} width={18} height={18} color={colors.themeOrange} />}
      {...props}
    />
  )
}
