import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SearchContent = styled(Box)(({ theme }) => ({
  '& .topic-search-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.palette.grey[800]}`,
    backgroundColor: 'white',
    zIndex: 10,
  },
  '& .preview-image': {
    minWidth: 130,
    height: 130,
    marginRight: 10,
    borderRadius: 8,
    objectFit: 'cover',
  },
  '& .topicListWrapper': {
    width: '100%',
    // maxWidth: 1000,
  },
}))
