/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, ResourcePermission } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useGetPermissions = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_PERMISSIONS],
    mutationFn: async (resourceID: string): Promise<ResourcePermission> => {
      const response = await axios.api.post('ajax/permissions', {
        requestType: 'resourcePermLoad',
        resourceType: 6,
        uresourceID: resourceID,
        format: 'json',
      })
      return response.data.permission
    },
  })
}
