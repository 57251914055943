/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, Topic } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'

export interface GetTopicParam {
  circleID?: string
  connID?: string
  searchType?: number
  pageNo: number
  interaction: number
  topicType: number
}

export const useGetTopics = () => {
  const axios = useContext(AxiosContext)
  const { resetTopicList, setTopicList, updateTopicList } = useAppStore()
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_TOPICS, new Date().getTime()],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (data: GetTopicParam) => {
      if (data.pageNo === 1) resetTopicList()
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicList',
        list: 'mini',
        format: 'json',
        searchType: 1,
        ...data,
      })
      const topicData: Record<string, Topic> = {}
      response.data?.myTopics.forEach((topic: Topic) => {
        if (topic.topicType === '100') return
        topicData[topic.topicID] = topic
      })
      if (data.pageNo === 1) setTopicList(topicData)
      else updateTopicList(topicData)
      return response.data?.myTopics || []
    },
  })
}
