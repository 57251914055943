import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { Topic, TopicListFilter } from '@/types'
import { Box, Grid, Typography, styled, Button, Tabs, Tab, IconButton, Drawer } from '@mui/material'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useGetTopics } from '@/hooks/api/dashboard'
import { PrivacyType, TopicType } from '@/utils/constants'
import HomeSearchList from './search'
import { CreateTopicModalContext } from '@/context/CreateTopicModalContext'
import { CreateNewPatternModal } from '@/components'

export const SidePanel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: 10,
}))

export default function TopicListPage(params: TopicListFilter) {
  const page = 1

  const navigate = useNavigate()
  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const getTopics = useGetTopics()
  const { setInteractionType, setTopicType, updateTopicSelectedAt } = useAppStore()
  const { updateTopicList } = useAppStore()
  const selectedTopicType = useRef<TopicType>(TopicType.PATTERN)
  const selectedPrivacyType = useRef<PrivacyType>(PrivacyType.PRIVATE)
  const isDiscoverMode = pathname.startsWith('/discover/')
  // pathname

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [interactionType, topicType, topicList] = useAppStore(
    (state) => [
      state.home.interactionFilterType,
      state.home.topicFilterType,
      state.home.topicList,
      state.user.profile,
      state.user,
    ],
    shallow,
  )

  const scrollRef = useRef<any>(null)
  useEffect(() => {
    if (page === 1 && getTopics.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, getTopics.isSuccess])

  useEffect(() => {
    if (getTopics.isSuccess && page === 1) {
      updateTopicSelectedAt()
    }
  }, [getTopics.isSuccess, getTopics.data, page])

  useEffect(() => {
    if (params.privacyType != null) {
      setInteractionType(Number(params.privacyType))
    }
    if (params.topicType != null) {
      setTopicType(Number(params.topicType))
    }
  }, [params, interactionType, topicType])

  const onClickTopic = (id: string, type: string) => {
    let previewQuery = new URLSearchParams()
    previewQuery.set('topicID', id)
    previewQuery.set('topicType', type)
    navigate(`/preview?${previewQuery.toString()}`)
  }

  const onClickTopicItem = (topic: Topic) => {
    onClickTopic(topic.topicID, topic.topicType)
  }

  const openModal = (topicType: TopicType, privacyType: PrivacyType) => {
    selectedTopicType.current = topicType
    selectedPrivacyType.current = privacyType
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <CreateTopicModalContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        onClickTopic: onClickTopicItem,
        contextPrivacyType: params.privacyType!,
        contextTopicType: params.topicType!,
        isDiscover: isDiscoverMode,
      }}
    >
      <Box flexDirection={'column'} display={'flex'} sx={{ width: '100%' }}>
        <Box
          flex={1}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          sx={{ overflow: 'auto' }}
        >
          <HomeSearchList key={`${params.topicType}-${params.privacyType}-${true}`} />
        </Box>
      </Box>
      <CreateNewPatternModal
        open={isModalOpen}
        onClose={closeModal}
        defaultTopicType={selectedTopicType.current}
        defaultPrivacyType={selectedPrivacyType.current}
      />
    </CreateTopicModalContext.Provider>
  )
}
