/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { CircleConnection, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useGetGroupMembersByID = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_GROUP_DETAILS],
    mutationFn: async (groupID: string): Promise<CircleConnection[]> => {
      const response = await axios.api.post('ajax/circles', {
        requestType: 'circleConnList',
        circleID: groupID,
        pageNo: 1,
        format: 'json',
      })
      return response.data.connections
    },
  })
}
