/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, Topic } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'

interface Param {
  page: number
  topicType: number
}

export const useGetSharedTopics = () => {
  const axios = useContext(AxiosContext)
  const { setTopicList, updateTopicList } = useAppStore()
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_TOPICS],
    mutationFn: async ({ page, topicType = 0 }: Param): Promise<Topic[]> => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicSharedList',
        format: 'json',
        pageNo: page,
        topicType,
        topicApplication: 2,
        list: 'full',
      })
      const topicData: Record<string, Topic> = {}
      response.data?.topics.forEach((topic: Topic) => {
        topicData[topic.topicID] = topic
      })
      if (page === 1) setTopicList(topicData)
      else updateTopicList(topicData)
      return response.data?.topics || []
    },
  })
}
