import { UserAvatar } from "@/components";
import { useBrowserQuery } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { ConnectionRecommendation, ConnectionRequest } from "@/types";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { ConnectionsContainer } from "./__styled";

interface Props {
  onClickDeclineRequet: (requestID: string) => void
  onClickConnectUser: (userID: string) => void
  onClickBack: () => void;
  onClickAccept: () => void;
}

export default function RequestAndRecommendations({
  onClickDeclineRequet,
  onClickConnectUser,
  onClickBack,
  onClickAccept
}: Props) {
  const query = useBrowserQuery();
  const category = query.get("category");
  const requestID = query.get("requestID");
  const userID = query.get("userID");
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));

  const [connRequests, recommendations] = useAppStore(
    state => [
      state.user.connRequests,
      state.user.recommendations,
    ],
    shallow
  )

  const onClickUserProfile = (userId: string) => {
    navigate(`/profile?id=${userId}`);
  }

  const onClickConnect = (id: string) => {
    navigate(`/notification?category=recommendations&userID=${id}`, { replace: true });
    onClickConnectUser(id);
  }

  const onClickAcceptRequet = (requestID: string) => {
    navigate(`/notification?category=requests&requestID=${requestID}`, { replace: true });
    onClickAccept();
  }

  if (category === 'recommendations') {
    return (
      <ConnectionsContainer p={2}>
        <Typography variant='h2' mb={2}>Recommendations</Typography>
        <Box className="connection-list">
          {recommendations.map((rec: ConnectionRecommendation) => (
            <Grid
              container
              key={rec.userID}
              p={2}
              mb={1}
              className={`request-item ${userID === rec.userID ? 'active' : ''}`}
            >
              <Grid item xs={12} sm={6} display="flex" alignItems="center">
                <UserAvatar
                  url={rec.profilePicURL}
                  name={`${rec.firstName} ${rec.lastName}`}
                  onClick={() => onClickUserProfile(rec.userID)}
                  size={46}
                />
                <Typography variant='label' flex={1} sx={{ paddingLeft: 1 }}>
                  {`${rec.firstName} ${rec.lastName}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" py={1}>
                <Button
                  variant="outlined"
                  onClick={() => onClickConnect(rec.userID)}>
                  Connect
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>
      </ConnectionsContainer>
    )
  }
  return (
    <ConnectionsContainer p={2}>
      <Box display="flex" alignItems="center">
        {lessThanMedium && (
          <IconButton onClick={onClickBack}>
            <ChevronLeft sx={{ color: colors.text }} />
          </IconButton>
        )}
        {connRequests.length === 0 ? (
          <Typography>No requests</Typography>
        ) : (
          <Typography variant='h2'>Requests</Typography>
        )}
      </Box>
      <Box className="connection-list" mt={2}>
        {connRequests.map((request: ConnectionRequest) => (
          <Grid
            container
            key={request.requestorID}
            p={2}
            mb={1}
            className={`request-item ${requestID === request.connReqID ? 'active' : ''}`}
          >
            <Grid item xs={12} sm={6} display="flex" alignItems="center">
              <UserAvatar
                url={request.profileAlbumPic}
                name={`${request.rFname} ${request.rLname}`}
                onClick={() => onClickUserProfile(request.requestorID)}
                size={46}
              />
              <Typography variant='label' flex={1} sx={{ paddingLeft: 1 }}>
                {`${request.rFname} ${request.rLname}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" py={1}>
              <Button
                variant="contained"
                onClick={() => onClickAcceptRequet(request.connReqID)}
                sx={{ marginRight: 1 }}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                onClick={() => onClickDeclineRequet(request.connReqID)}>
                Reject
              </Button>
            </Grid>
          </Grid>
        ))}
      </Box>
    </ConnectionsContainer>
  )
}