/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys, Topic } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useSearchTopics = (query: string) => {
  const axios = useContext(AxiosContext)
  const {
    resetTopicSearchResult,
    setTopicSearchResult,
    setSearchKeyword,
    updateTopicSearchResult,
  } = useAppStore()
  const [searchKeyword, topicSearchResult] = useAppStore(
    (state) => [state.search.searchKeyword, state.search.topicSearchResult],
    shallow,
  )
  return useMutation({
    mutationKey: [ReactQueryKeys.SEARCH_TOPICS, query],
    mutationFn: async (page: number) => {
      const skipSearch =
        query == searchKeyword && Object.keys(topicSearchResult).length > 0 && page == 1

      if (skipSearch) {
        return Object.values(topicSearchResult)
      }

      if (page === 1) {
        resetTopicSearchResult()
      }
      setSearchKeyword(query)
      const response = await axios.api.post('search/search', {
        requestType: 'topicSearch',
        query: query,
        queryType: 1,
        pageNo: page,
        format: 'json',
      })
      const topicData: Record<string, Topic> = {}
      response.data?.myTopics?.forEach((topic: Topic) => {
        topicData[topic.topicID] = topic
      })
      if (page === 1) {
        setTopicSearchResult(topicData)
      } else {
        updateTopicSearchResult(topicData)
      }
      return response.data?.myTopics || []
    },
  })
}
