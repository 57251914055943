import { useSearchTopics } from '@/hooks/api/dashboard'
import useAppStore from '@/store/useAppStore'
import { Topic, TopicListFilter } from '@/types'
import { Box, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { shallow } from 'zustand/shallow'
import { LoadingButton } from '@mui/lab'
import SVG from 'react-inlinesvg'
import { PrivacyType, PrivacyTypes, TopicType, TopicTypes } from '@/utils/constants'
import colors from '@/styles/colors'
import TopicItemsGrid from '@/pages/home/molecules/TopicItemsGrid'

interface Props {
  filter: TopicListFilter
  searchKey: string
  onClickTopic: (topic: Topic) => void
}

export default function SearchTopicResults({ filter, searchKey, onClickTopic }: Props) {
  const [page, setPage] = useState(1)
  const [endReached, setEndReached] = useState(false)
  const [topicSearchResult, searchKeyword] = useAppStore(
    (state) => [state.search.topicSearchResult, state.search.searchKeyword],
    shallow,
  )

  const searchTopics = useSearchTopics(searchKey)
  const scrollRef = useRef<any>(null)

  useEffect(() => {
    if (searchKey.length > 0) {
      getData(1)
    }
  }, [searchKey])

  const getData = (pageNo: number = 1) => {
    searchTopics.mutate(pageNo)
    setPage(pageNo)
  }

  useEffect(() => {
    if (page === 1 && searchTopics.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, searchTopics.isSuccess])

  useEffect(() => {
    if (searchTopics.isSuccess && searchTopics.data && !searchTopics.isError) {
      if (searchTopics.data.length < 20) {
        setEndReached(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTopics.isSuccess, searchTopics.data, searchTopics.isError, page])

  const topics = useMemo(() => {
    let list = Object.values(topicSearchResult)
    list = list.filter((i) => {
      // const topicData = TopicTypes[Number(i.topicType)]
      // const privacyData = PrivacyTypes[Number(i.visibility)]
      // return topicData && privacyData
      return filter.topicType != TopicType.ALL ? i.topicType == filter.topicType?.toString()! : true
    })
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1
      if (a.dateUpdated < b.dateUpdated) return 1
      return 0
    })
    return list
  }, [topicSearchResult, filter])

  const onClickShowMore = () => {
    if (endReached || searchTopics.isLoading || !topics.length) {
      return
    }
    getData(page + 1)
  }

  if (searchTopics.isSuccess && topics.length == 0 && page === 1) {
    return (
      <Box p={5} display='flex' flexDirection={'column'} alignItems={'center'}>
        <SVG
          src={TopicTypes[2].icon}
          width={76}
          height={76}
          className={'icon'}
          color={colors.grey}
        />
        <Typography variant='h3' textAlign={'center'} mt={2} mb={1}>
          No topic results.
        </Typography>
      </Box>
    )
  }

  return (
    <Box flex={1} overflow='auto' ref={scrollRef}>
      {searchTopics.isLoading && page === 1 && (
        <Box mt={1} display='flex' justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      <TopicItemsGrid
        showAuthorForPublic={false}
        showAuthor={true}
        topics={topics || []}
        onClickTopicItem={onClickTopic}
        onRemoved={(item) => {}}
        filter={{ topicType: TopicType.ALL, privacyType: PrivacyType.ALL }}
      />
      {(page > 1 || !searchTopics.isLoading) && (
        <Box m={3} display='flex' justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={onClickShowMore}
            loading={searchTopics.isLoading && page > 1}
            disabled={endReached}
          >
            {endReached ? 'No more results' : 'Show More'}
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}
