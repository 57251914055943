import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { ReactQueryKeys } from '@/types'

export const useLogin = (user: string, password: string) => {
  return useMutation({
    mutationKey: [ReactQueryKeys.LOGIN, user, password],
    mutationFn: async (): Promise<string> => {
      const authData = await Auth.signIn(user, password)
      return authData.username
    },
  })
}
