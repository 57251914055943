import { useGetTopicDetails, useInteractToNote } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { NoteType, SIDE_PANEL_WIDTH, TopicType, TopicTypes } from '@/utils/constants'
import { Box, CircularProgress, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { DetailsContent, GradientContainer, ListOverlayGradient } from '@/pages/panels/__styled'

import { AlbumPhoto, Note, PatternDraft } from '@/types'
import { TopicDetailsContext } from '@/context/TopicDetails'
import TopicDetailsHeader from './TopicDetailsHeader'
import AlbumContent from '@/pages/panels/details/AlbumContent'
import DMContent from '@/pages/panels/details/DMContent'
import RichInput from '@/pages/panels/details/RichInput'
import AlbumInput from '@/pages/panels/details/AlbumInput'
import TopicInteractions from '@/pages/panels/interactions'
import LoadingAnimatedView from '@/components/LoadingAnimatedView'
import { ContentPanel } from '@/pages/__styled'
import colors from '@/styles/colors'

export enum ViewStep {
  PREVIEW = '0',
  DETAILS = '1',
  COMMENTS = '2',
  LIKES = '3',
}

const TopicDetailsView = () => {
  const query = useBrowserQuery()
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const [ewUser] = useLocalStorage('ewUser', null)
  const { topic, onClickBack } = useContext(TopicDetailsContext)
  const topicID = query.get('topicID') || ''
  const noteID = query.get('noteID') || ''

  const photos = useMemo(() => {
    const res: Record<string, AlbumPhoto> = {}
    topic?.photos?.forEach((p) => (res[p.egoID] = p))
    return res
  }, [topic])

  const onClickCloseInteraction = () => {}

  const onClickBackInner = () => {
    if (lessThanMedium && noteID.length > 0) {
      // remove noteID and navigate
      query.delete('noteID')
      navigate(`${pathname}?${query.toString()}`, { replace: true })
    } else {
      onClickBack()
    }
  }

  const renderNotesListView = () => (
    <ListOverlayGradient flex={1}>
      {topic.notes != null ? (
        <>
          {topic.topicType == TopicType.ALBUM.toString() ? (
            <AlbumContent photos={photos} />
          ) : (
            <DMContent
              topicID={topicID}
              topicType={`${TopicTypes[Number(topic.topicType)].title.toLowerCase()}`}
              photos={photos}
              onClickInteraction={() => {}}
            />
          )}
          <Box p={2}>
            {topic.topicType === '2' && topic.userID === ewUser.userID && <AlbumInput />}
            {topic.topicType === '4' && (
              <RichInput topicID={topicID} notes={topic?.notes || []} onSubmit={() => {}} />
            )}
            {topic.topicType === '1' && (
              <RichInput topicID={topicID} notes={topic?.notes || []} onSubmit={() => {}} />
            )}
          </Box>
        </>
      ) : (
        <Box display={'flex'} justifyContent={'center'} pt={4}>
          <LoadingAnimatedView showGif />
        </Box>
      )}
    </ListOverlayGradient>
  )

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        backgroundColor: colors.pageBackground,
        gap: '6px',
        p: '6px',
      }}
    >
      <ContentPanel
        sx={{
          display: lessThanMedium ? 'none' : 'flex',
          height: '100%',
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <TopicDetailsHeader
          topic={topic}
          onClickBack={onClickBackInner}
          onClickComment={() => {}}
          onClickPrivacy={() => {}}
          onDeletedTopic={() => {}}
        />

        {renderNotesListView()}
      </ContentPanel>
      {!lessThanMedium && noteID && (
        <ContentPanel sx={{ height: '100%', width: SIDE_PANEL_WIDTH }}>
          <TopicInteractions onClickClose={onClickCloseInteraction} />
        </ContentPanel>
      )}

      {/* Smaller screen layout */}
      <ContentPanel
        sx={{
          display: lessThanMedium ? 'flex' : 'none',
          height: '100%',
          flex: 1,
          backgroundColor: 'white',
          overflow: 'auto',
        }}
      >
        <TopicDetailsHeader
          topic={topic}
          onClickBack={onClickBackInner}
          onClickComment={() => {}}
          onClickPrivacy={() => {}}
          onDeletedTopic={() => {}}
        />
        {noteID.length > 0 ? (
          <TopicInteractions onClickClose={onClickCloseInteraction} />
        ) : (
          <>{renderNotesListView()}</>
        )}
      </ContentPanel>
    </Box>
  )
}

export default TopicDetailsView
