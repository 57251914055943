import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { ReactQueryKeys } from '@/types'

export const useUpdateOnlineStatus = (status: number) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.UPDATE_ONLINE_STATUS, status],
    mutationFn: async (): Promise<boolean> => {
      await axios.api.post('ajax/users', {
        requestType: 'userOnlineStatus',
        status: status,
        format: 'json',
      })
      return true
    },
  })
}
