/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

interface Param {
  day?: string
  month?: string
  year?: string
  email?: string
  firstName?: string
  lastName?: string
  motto?: string
  phone?: string
  profession?: string
  showConnCount?: 1 | 0
  // notifyConnReq?: 1 | 0
}

export const useUpdateProfile = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.UPDATE_PROFILE],
    mutationFn: async (data: Param) => {
      const response = await axios.api.post('ajax/users', {
        requestType: 'accSettUpdate',
        'settings[dob][day]': data.day,
        'settings[dob][month]': data.month,
        'settings[dob][year]': data.year,
        'settings[email]': data.email,
        'settings[fname]': data.firstName,
        'settings[lname]': data.lastName,
        'settings[location]': '',
        'settings[motto]': data.motto ? data.motto : '',
        'settings[phoneNumber]': data.phone,
        'settings[profession]': data.profession ? data.profession : '',
        'settings[showConnCount]': data.showConnCount,
        // 'settings[notifyConnReq]': data.notifyConnReq,
        format: 'json',
      })
      return response.data
    },
  })
}
