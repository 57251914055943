import colors from '@/styles/colors'
import { Box, IconButton, styled } from '@mui/material'

export const EditorWrapper = styled(Box)({
  boxShadow: '0px -1px 6px rgba(0,0,0,.1)',
  '& .wrapper': {
    backgroundColor: 'white',
    padding: 12,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  '& .editor': {
    maxHeight: 200,
    overflow: 'auto',
  },
  '& .toolbar': {
    padding: 0,
    border: 'none',
    margin: '0 -5px',
    paddingRight: 150,
    paddingLeft: 4,
    '& .rdw-option-wrapper': {
      width: 30,
      height: 30,
      boxSizing: 'border-box',
      border: 'none',
      margin: '0 4px',
      '&:hover': {
        border: `1px solid ${colors.lightGrey}`,
        boxShadow: 'none',
      },
    },
    '& .rdw-image-modal': {
      bottom: 35,
      top: 'auto',
    },
    '& .rdw-emoji-modal': {
      bottom: 35,
      top: 'auto',
    },
    '& .rdw-link-modal': {
      bottom: 35,
      top: 'auto',
    },
    '& .rdw-option-active': {
      backgroundColor: `${colors.blue}30`,
      boxShadow: 'none',
    },
    '& .rdw-block-wrapper, .rdw-text': {
      padding: '0 10px',
      borderLeft: '1px solid #DCE0EA',
      borderRight: '1px solid #DCE0EA',
    },
    '& .rdw-inline-wrapper': {
      borderRight: '1px solid #DCE0EA',
      '& img': {
        width: 14,
        height: 14,
      },
      '& .underline': {
        '& img': {
          width: 25,
          height: 25,
        },
      },
    },
    '& .rdw-text-align-wrapper': {
      borderRight: '1px solid #DCE0EA',
      '& img': {
        width: 20,
        height: 20,
      },
    },
    '& .rdw-list-wrapper': {
      borderRight: '1px solid #DCE0EA',
      '& img': {
        width: 20,
        height: 20,
      },
    },
    '& .rdw-link-wrapper': {
      borderRight: '1px solid #DCE0EA',
      '& img': {
        width: 20,
        height: 20,
      },
    },
    '& .rdw-emoji-wrapper': {
      '& img': {
        width: 16,
        height: 16,
      },
    },
    '& .rdw-dropdown-wrapper': {
      backgroundColor: 'black',
      borderRadius: 6,
      '& span': {
        color: 'white',
      },
      '& .rdw-dropdown-optionwrapper': {
        marginTop: -258,
        overflow: 'auto',
      },
      '& .rdw-dropdown-carettoclose': {
        borderTop: '2px solid white',
        borderRight: '2px solid white',
        width: 8,
        height: 8,
        transform: 'rotate(135deg)',
        top: '22%',
        borderLeft: 'none',
        borderBottom: 'none',
      },
      '& .rdw-dropdown-carettoopen': {
        borderTop: '2px solid white',
        borderRight: '2px solid white',
        width: 8,
        height: 8,
        transform: 'rotate(-45deg)',
        top: '39%',
        borderBottom: 'none',
        borderLeft: 'none',
      },
    },
    '& .rdw-text-align-dropdown': {
      '& .rdw-dropdown-optionwrapper': {
        marginTop: -193,
        overflow: 'auto',
      },
    },
  },
})

export const ImageAttachButton = styled(IconButton)({
  width: 30,
  height: 30,
  backgroundColor: `${colors.darkGrey}30`,
  marginRight: 12,
  '&:hover': {
    backgroundColor: `${colors.darkGrey}60`,
  },
})
