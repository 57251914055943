import DiscussionTopicItem from '@/pages/topic/search/DiscussionTopicItem'
import TopicItem from '@/pages/topic/search/TopicItem'
import { Topic, TopicListFilter } from '@/types'
import { PrivacyType, TopicType } from '@/utils/constants'
import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface TopicItemsGridProps {
  topics: Topic[]
  onClickTopicItem: (topic: Topic) => void
  onRemoved: (topic: Topic) => void
  filter?: TopicListFilter
  showAuthorForPublic?: boolean
  showAuthor?: boolean
}

const TopicItemsGrid = ({
  topics,
  onClickTopicItem,
  onRemoved,
  filter,
  showAuthor = false,
  showAuthorForPublic = false,
}: TopicItemsGridProps) => {
  const [filteredData, setFilteredData] = useState<Topic[]>([])

  useEffect(() => {
    if (filter) {
      const filtered = topics.filter((e) => {
        let result = true
        if (filter.topicType != null && filter.topicType != TopicType.ALL) {
          result = result && e.topicType == filter.topicType?.toString()
        }

        if (filter.privacyType != null && filter.privacyType != PrivacyType.ALL) {
          result = result && e.visibility == filter.privacyType.toString()
        }
        return result
      })

      setFilteredData(filtered)
    } else {
      setFilteredData(topics)
    }
  }, [filter])

  return (
    <Box
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      p={2}
      sx={{ overflow: 'auto' }}
    >
      <Grid container spacing={2} flex={1}>
        {filteredData.map((item: Topic, index: number) => (
          <Grid item xs={'auto'} key={item.topicID}>
            {item.topicType == TopicType.DISCUSSION.toString() ? (
              <DiscussionTopicItem
                topic={item}
                key={item.topicID}
                onClick={() => onClickTopicItem(item)}
                onRemoved={() => onRemoved(item)}
              />
            ) : (
              <TopicItem
                showAuthorForPublicOnly={showAuthorForPublic}
                showAuthor={showAuthor}
                topic={item}
                key={item.topicID}
                onClick={() => onClickTopicItem(item)}
                onRemoved={() => onRemoved(item)}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default TopicItemsGrid
