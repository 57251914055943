import PatternNotesGroupList from '@/pages/panels/details/PatternNotesGroupList'
import PatternNotesGroupRowList from '@/pages/panels/details/PatternNotesGroupRowList'
import { Note } from '@/types'
import { NoteType } from '@/utils/constants'
import { Box, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import TabArrow from '@/assets/svgs/tab-arrow.svg'
import SVG from 'react-inlinesvg'

interface PatternNotesFooterPanelProps {
  patternNoteGroups: any
  onCollapse: () => void
}
export const PatternNotesFooterPanel = ({
  onCollapse,
  patternNoteGroups,
}: PatternNotesFooterPanelProps) => {
  const [footerExpanded, setFooterExpanded] = useState(true)
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box>
        <IconButton
          onClick={onCollapse}
          sx={{
            zIndex: 20,
            backgroundColor: '#FFFFFF4D',
            width: '60px',
            height: '20px',
            borderRadius: '12px 12px 0px 0px',
            color: 'white',
            '&:hover': { backgroundColor: '#FFFFFF', color: 'black' },
          }}
        >
          {!footerExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: footerExpanded ? null : 0,
          minHeight: footerExpanded ? '112px' : 0,
          py: 1,
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {footerExpanded && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              width: '75%',
              justifyContent: 'center',
            }}
          >
            <PatternNotesGroupRowList
              title={'Template'}
              patternNoteType={NoteType.TEMPLATE}
              data={patternNoteGroups?.[NoteType.TEMPLATE] || []}
              patternDetails={patternNoteGroups?.patternDetails}
            />
            <PatternNotesGroupRowList
              title={'Material'}
              patternNoteType={NoteType.MATERIAL}
              data={patternNoteGroups?.[NoteType.MATERIAL] || []}
              patternDetails={patternNoteGroups?.patternDetails}
            />
            {patternNoteGroups?.[NoteType.ACCENT][0].noteText != 'null' && (
              <PatternNotesGroupRowList
                title={'Accent'}
                patternNoteType={NoteType.ACCENT}
                data={patternNoteGroups?.[NoteType.ACCENT] || []}
                patternDetails={patternNoteGroups?.patternDetails}
              />
            )}

            <PatternNotesGroupRowList
              title={'Print'}
              patternNoteType={NoteType.PRINT}
              data={patternNoteGroups?.[NoteType.PRINT] || []}
              patternDetails={patternNoteGroups?.patternDetails}
            />
            <PatternNotesGroupRowList
              title={'Colors Used'}
              patternNoteType={NoteType.COLOR}
              data={patternNoteGroups?.[NoteType.COLOR] || []}
              patternDetails={patternNoteGroups?.patternDetails}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
