import colors from '@/styles/colors'
import { Close, Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Modal from 'react-responsive-modal'
import AvatarEditor from 'react-avatar-editor'
import React, { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'
import { FileContent, SelectedFiles, useFilePicker } from 'use-file-picker'
import { ButtonCreateNew, ButtonSelect } from '@/pages/__styled'
import { Input } from '@/components'
import { PrivacyType, TopicType, TopicTypeOptionsForCreate, TopicTypes } from '@/utils/constants'
import { useCreateTopic } from '@/hooks/api/dashboard'
import { useLocalStorage } from '@/hooks/common'
import toast from 'react-hot-toast'
import { isBabylonType } from '@/utils/helper'
import { useNavigate } from 'react-router-dom'
import useAppStore from '@/store/useAppStore'
import { TransitionProps } from '@mui/material/transitions'
import { Topic } from '@/types'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='down' ref={ref} {...props} />
})

interface RModalProps {
  open: boolean
  onClose: () => void
  title: string
  description: string
  cancelText?: string
  submitText?: string
  onSubmit: () => void
}

export function RModal({
  open,
  onClose,
  title,
  description,
  cancelText = 'Cancel',
  submitText = 'Yes',
  onSubmit,
}: RModalProps) {
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const onClickSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onSubmit()
    onClose()
  }

  const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} center showCloseIcon={false}>
      <Box maxWidth={500} minWidth={lessThanSmall ? 200 : 300}>
        <Typography variant='labelLarge' fontSize={15}>
          {title}
        </Typography>
        <Typography variant='p' mt={2}>
          {description}
        </Typography>
        <Box display='flex' justifyContent={'flex-end'} mt={2}>
          <Button variant='text' onClick={onClickClose} sx={{ marginRight: 1 }}>
            {cancelText}
          </Button>
          <Button variant='contained' size='small' onClick={onClickSubmit}>
            {submitText}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

interface RInputModal extends Omit<RModalProps, 'onSubmit'> {
  onSubmit: (text: string) => void
  text: string
  placeholder: string
  maxLength?: number
}

export function RInputModal({
  open,
  onClose,
  title,
  description,
  text,
  placeholder,
  cancelText = 'Cancel',
  submitText = 'Save',
  maxLength = 30,
  onSubmit,
}: RInputModal) {
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [inputValue, setInputValue] = useState(text)

  const onClickSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onSubmit(inputValue)
    onClose()
  }

  const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} center showCloseIcon={false}>
      <Box maxWidth={500} minWidth={lessThanSmall ? 200 : 300}>
        <Typography variant='labelLarge' fontSize={15}>
          {title}
        </Typography>
        {description.length > 0 && (
          <Typography variant='p' mt={2}>
            {description}
          </Typography>
        )}

        <Input
          value={inputValue}
          placeholder={placeholder}
          maxRows={'2'}
          inputProps={{ maxLength }}
          onChange={(e) => setInputValue(e.target.value)}
          sx={{
            marginTop: '21px',
            background: 'white',
            borderRadius: '8px',
            border: '1px solid #eee',
          }}
        />
        <Box display='flex' justifyContent={'flex-end'} mt={2}>
          <Button variant='text' onClick={onClickClose} sx={{ marginRight: 1 }}>
            {cancelText}
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={onClickSubmit}
            disabled={inputValue.trim().length == 0}
          >
            {submitText}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

interface CModalProps {
  open: boolean
  onClose: () => void
  icon: React.ReactNode
  title: string
  description: string
  cancelText?: string
  submitText?: string
  onSubmit: () => void
}

export function CModal({
  open,
  onClose,
  icon,
  title,
  description,
  cancelText = 'Cancel',
  submitText = 'Yes',
  onSubmit,
}: CModalProps) {
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const onClickSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onSubmit()
    onClose()
  }

  const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} center showCloseIcon={false}>
      <Box maxWidth={400} p={2} minWidth={lessThanSmall ? 200 : 300}>
        <Box display='flex'>
          {icon}
          <Box pl={1}>
            <Typography variant='labelLarge' fontSize={15}>
              {title}
            </Typography>
            <Typography variant='p' mt={1}>
              {description}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent={'flex-end'} mt={2}>
          <Button
            fullWidth
            variant='outlined'
            onClick={onClickClose}
            sx={{ color: colors.blue, borderColor: colors.blue, marginRight: 2 }}
          >
            {cancelText}
          </Button>
          <Button
            fullWidth
            variant='outlined'
            onClick={onClickSubmit}
            sx={{ color: colors.error, borderColor: colors.error }}
          >
            {submitText}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

interface CreateNewPatternModalProps {
  defaultTopicType?: TopicType
  defaultPrivacyType?: PrivacyType
  open: boolean
  onClose: () => void
  onSuccess?: (topic: Topic) => void
}

export const CreateNewPatternModal = ({
  open,
  onClose,
  defaultPrivacyType = PrivacyType.PRIVATE,
  defaultTopicType = TopicType.PATTERN,
  onSuccess,
}: CreateNewPatternModalProps) => {
  const [ewUser] = useLocalStorage('ewUser', null)
  const [selectedTopicType, setSelectedTopicType] = useState<TopicType>(defaultTopicType)
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [patternTitle, setPatternTitle] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const createTopic = useCreateTopic(ewUser?.userID)
  const navigate = useNavigate()

  useEffect(() => {
    setSelectedTopicType(defaultTopicType)
  }, [defaultTopicType])

  const onSubmit = async () => {
    setIsSubmitting(true)
    try {
      const result = await createTopic.mutateAsync({
        topicName: patternTitle,
        topicType: selectedTopicType.toString(),
        allowContribution: 0,
        topicXData: 'X Data Here',
        privacyType: 3,
        cliques: [],
        connections: [],
      })

      setPatternTitle('')
      setSelectedTopicType(defaultTopicType)
      if (result.isError) {
        toast.error('Create failed. Please try again!')
      } else if (onSuccess) {
        onSuccess(result)
      } else if (isBabylonType(selectedTopicType)) {
        navigate(`/pattern?topicID=${result.topicID}&mode=edit`)
      } else {
        navigate(`/preview?topicID=${result.topicID}&topicType=${selectedTopicType}`)
      }
    } catch (e) {
      toast.error('Create failed. Please try again!')
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={onClose}
        aria-describedby='alert-dialog-slide-description'
        fullWidth
        maxWidth={'md'}
      >
        <Box display={'flex'}>
          <Box
            bgcolor={'white'}
            display={'flex'}
            flexDirection={'column'}
            margin={'auto'}
            borderRadius={'8px'}
            p={'20px'}
            sx={{ flex: 1, gap: '16px' }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              flex={1}
              sx={{ justifyContent: 'space-between' }}
            >
              <Typography
                fontWeight={700}
                fontSize={'17px'}
                lineHeight={'20px'}
                color={colors.themeBlack}
              >
                What would you like to create?
              </Typography>
              <IconButton
                onClick={onClose}
                sx={{
                  padding: 0,
                  ml: 'auto',
                  maxWidth: '30px',
                }}
              >
                <SVG src={SVGS.CloseIcon} width={30} height={30} />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: lessThanSmall ? 'column' : 'row',
                minHeight: '320px',
                py: '16px',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    overflowX: 'none',
                    overflowY: 'auto',
                    flexWrap: 'wrap',
                  }}
                >
                  {TopicTypeOptionsForCreate.map((item, index) => {
                    const topicTypeInfo = TopicTypes[Number(item)]
                    const selected = topicTypeInfo.type == selectedTopicType
                    return (
                      <ButtonSelect
                        key={`${item}`}
                        onClick={() => setSelectedTopicType(item)}
                        aria-selected={selected == true}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          sx={{ gap: '6px' }}
                        >
                          <SVG
                            src={selected ? topicTypeInfo.gridSelectedIcon : topicTypeInfo.gridIcon}
                            width={32}
                            height={32}
                            style={{ marginBottom: '2px' }}
                          />
                          <Typography
                            color={selected ? colors.meshdomBlue : colors.themePurpleTextColor}
                            fontSize={'13px'}
                            fontWeight={500}
                          >
                            {topicTypeInfo.title}
                          </Typography>
                        </Box>
                      </ButtonSelect>
                    )
                  })}
                </Box>
              </Box>
              <Box
                bgcolor={'#F7F7F7'}
                borderRadius={'8px'}
                sx={{ display: 'flex', flex: 1, alignItems: 'center', padding: '16px 0px' }}
              >
                <Box px={'24px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'14px'} color={colors.themeBlack}>
                    {TopicTypes[selectedTopicType].label}
                  </Typography>
                  <Typography fontWeight={400} fontSize={'13px'} color={colors.themeBlack}>
                    Create a {TopicTypes[selectedTopicType].title.toLowerCase()} design
                  </Typography>
                  <Input
                    value={patternTitle}
                    placeholder={`Enter ${TopicTypes[selectedTopicType].title.toLowerCase()} title`}
                    onChange={(e) => setPatternTitle(e.target.value)}
                    sx={{ marginTop: '21px', background: 'white', borderRadius: 50, border: 0 }}
                  />

                  {isSubmitting ? (
                    <Box mt={1} display='flex' justifyContent={'center'}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ButtonCreateNew
                      onClick={onSubmit}
                      disabled={isSubmitting || patternTitle.length == 0}
                      sx={{ mt: '15px', width: '100%' }}
                    >
                      Create now
                    </ButtonCreateNew>
                  )}

                  <Typography
                    mt={'16px'}
                    fontWeight={400}
                    fontSize={'12px'}
                    lineHeight={'14px'}
                    color={'#525E9C'}
                    textAlign={'center'}
                  >
                    Your all new creation remain private by default. You can change it later on.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  )
}

interface PhotoModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorRef: any
  open: boolean
  onClose: () => void
  title: string
  round?: boolean
  onSubmit: () => void
}

interface Position {
  x: number
  y: number
}

export function PhotoEditModal({
  editorRef,
  open,
  onClose,
  title,
  round = true,
  onSubmit,
}: PhotoModalProps) {
  const [scale, setScale] = useState(1)
  const [file, setFile] = useState<FileContent>()
  const [position, setPosition] = useState<Position>({ x: 0.5, y: 0.5 })
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const onFilesSelected = ({ filesContent }: SelectedFiles) => {
    if (filesContent.length > 0) {
      setFile(filesContent[0])
    }
  }

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    maxFileSize: 5,
    onFilesSuccessfulySelected: onFilesSelected,
  })

  const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onClose()
  }

  const handlePositionChange = (pos: Position) => {
    setPosition(pos)
  }

  const onChangeScale = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number)
  }

  const onClickDeletePhoto = () => {
    setFile(undefined)
  }

  return (
    <Modal open={open} onClose={onClose} center showCloseIcon={false}>
      <Box width={450} m={'-1.2rem'}>
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems='center'
          bgcolor={colors.blue}
          px={2}
        >
          <Typography variant='h1' fontSize={15} color='white'>
            {title}
          </Typography>
          <IconButton onClick={onClickClose}>
            <Close sx={{ color: 'white', marginRight: -2 }} />
          </IconButton>
        </Box>
        <Box p={2}>
          <Box display='flex' justifyContent={'center'} mt={1}>
            {file ? (
              <Box
                border={`3px solid ${colors.text}`}
                width={lessThanSmall ? 300 : 400}
                height={lessThanSmall ? 300 : 400}
              >
                <AvatarEditor
                  ref={editorRef}
                  scale={scale}
                  width={lessThanSmall ? 250 : 350}
                  height={lessThanSmall ? 250 : 350}
                  position={position}
                  onPositionChange={handlePositionChange}
                  borderRadius={round ? 300 : 0}
                  className='editor-canvas'
                  backgroundColor='transparent'
                  color={[255, 255, 255, 0.5]}
                  image={file.content}
                />
              </Box>
            ) : (
              <Box
                border={`3px solid ${colors.text}`}
                width={lessThanSmall ? 300 : 400}
                height={lessThanSmall ? 300 : 400}
                display='flex'
                justifyContent={'center'}
                alignItems='center'
              >
                <Button variant='contained' onClick={openFileSelector}>
                  Upload
                </Button>
              </Box>
            )}
          </Box>

          <Box display='flex' alignItems={'center'} mt={1}>
            <SVG src={SVGS.Public} width={20} height={20} />
            <Slider
              min={1}
              max={2}
              step={0.05}
              onChange={onChangeScale}
              defaultValue={1}
              aria-label='Default'
              valueLabelDisplay='auto'
              sx={{ margin: '0 15px', color: colors.blue }}
            />
            <SVG src={SVGS.Public} width={40} height={40} />
            <Box width={'40%'} display='flex' justifyContent={'flex-end'}>
              <IconButton onClick={onClickDeletePhoto}>
                <Delete sx={{ color: colors.error }} />
              </IconButton>
            </Box>
          </Box>
          <Box display='flex' justifyContent={'flex-end'} mt={2}>
            <Button variant='text' onClick={onClickClose} sx={{ marginRight: 2 }}>
              Cancel
            </Button>
            <Button variant='contained' onClick={onSubmit} sx={{ padding: '0 30px' }}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
