/* eslint-disable */
import Box from '@mui/material/Box'
import { useLocalStorage } from '@/hooks/common'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Input } from '@/components'
import { AccountCircleOutlined, MoreVert, SearchOutlined } from '@mui/icons-material'
import {
  useTheme,
  Button,
  Tabs,
  Tab,
  Toolbar,
  useMediaQuery,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
  Menu,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { IMAGES } from '@/assets/images'
import { GuestTabs } from '@/utils/constants'
import colors from '@/styles/colors'

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  borderBottom: '1px solid #E7EDF3',
}))

export const ButtonSignUp = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.brand.light,
  color: 'white',
  fontSize: 12,
  fontWeight: 'bold',
  borderRadius: 50,
  height: '38px',
  alignItems: 'center',
  padding: '0px 20px',
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
  },
}))

export const ButtonLogin = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.brand.light,
  fontSize: 12,
  fontWeight: 'bold',
  borderWidth: 1,
  borderColor: theme.palette.brand.light,
  borderRadius: 50,
  height: '38px',
  alignItems: 'center',
  padding: '0px 20px',
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
    color: 'white',
  },
}))

export const Divider = styled(Box)(() => ({
  height: '100%',
  width: '1px',
  backgroundColor: '#E7EDF3',
}))

interface GuestLandingHeaderProps {
  selectedTabIndex: number
  setSelectedTabIndex: (value: number) => void
}

export default function GuestLandingHeader({
  selectedTabIndex,
  setSelectedTabIndex,
}: GuestLandingHeaderProps) {
  const [searchText, setSearchText] = useState('')
  const [ewUser] = useLocalStorage('ewUser', null)
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()

  const onClickLogin = () => {
    navigate('/login')
  }

  const onClickSignUp = () => {
    navigate(`/signup`)
  }

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const onSearch = () => {
    navigate(`/search?key=${searchText}`)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      navigate(`/search?key=${searchText}`)
    }
  }

  const onTabChange = (event: React.SyntheticEvent, value: any) => {
    setSelectedTabIndex(value)
  }

  const onDropdownSelect = (event: SelectChangeEvent) => {
    const value = event.target.value
    setSelectedTabIndex(GuestTabs.indexOf(value))
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <Header>
        <Toolbar sx={{ flex: 1 }}>
          <Box>
            <img src={IMAGES.logoBlack} alt='Logo' width='151' height='34' color='transparent' />
          </Box>

          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} flex={1}>
            {lessThanMedium && (
              <Select
                value={`${GuestTabs[selectedTabIndex]}`}
                onChange={onDropdownSelect}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  height: 34,
                  borderRadius: 100,
                  color: '#525E9C',
                  border: '1px solid #E7EDF3',
                  width: '120px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {GuestTabs.map((item, index) => (
                  <MenuItem key={`${index}`} value={`${item}`}>
                    {GuestTabs[index]}
                  </MenuItem>
                ))}
              </Select>
            )}
            {!lessThanMedium && (
              <Tabs
                value={selectedTabIndex}
                onChange={onTabChange}
                TabIndicatorProps={{
                  style: {
                    color: theme.palette.brand.light,
                    backgroundColor: theme.palette.brand.light,
                  },
                }}
              >
                {GuestTabs.map((item, index) => (
                  <Tab
                    key={`${index}`}
                    label={item}
                    sx={{
                      textTransform: 'none',
                      color: 'black',
                      pt: 4,
                      pb: 4,
                    }}
                  />
                ))}
              </Tabs>
            )}
          </Box>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              pr: 1,
            }}
          >
            <Input
              value={searchText}
              placeholder='Search here what you are looking for'
              leftIcon={<SearchOutlined />}
              onChange={onChangeSearchText}
              name='search'
              sx={{ borderRadius: '30px', maxWidth: '450px' }}
              size='small'
              onToggleIcon={onSearch}
              onKeyDown={handleKeyDown}
            />
          </Box>
          {lessThanMedium && (
            <>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <AccountCircleOutlined sx={{ color: colors.themeBlack }} />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem key={'login'} onClick={onClickSignUp}>
                  <Typography variant={'p'} textAlign='center'>
                    {'Sign Up'}
                  </Typography>
                </MenuItem>

                <MenuItem key={'login'} onClick={onClickLogin}>
                  <Typography variant={'p'} textAlign='center'>
                    {'Login'}
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          )}

          {!lessThanMedium && (
            <Box display='flex' flexDirection={'row'} justifyContent={'center'} gap={1}>
              <ButtonLogin variant={'outlined'} onClick={onClickLogin}>
                Login
              </ButtonLogin>
              <ButtonSignUp onClick={onClickSignUp}>Sign Up </ButtonSignUp>
            </Box>
          )}
        </Toolbar>
      </Header>
    </>
  )
}
