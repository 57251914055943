import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface Props extends InputBaseProps {
  label?: string
  isError?: boolean
  errorText?: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onToggleIcon?: () => void
}

export function Input({
  label,
  isError,
  errorText,
  leftIcon,
  rightIcon,
  onToggleIcon,
  ...props
}: Props) {
  return (
    <>
      {label && <Typography variant='label'>{label}</Typography>}
      <InputBase
        fullWidth
        startAdornment={
          leftIcon ? <InputAdornment position='start'>{leftIcon}</InputAdornment> : null
        }
        endAdornment={
          rightIcon ? (
            <InputAdornment position='end'>
              <IconButton onClick={onToggleIcon} edge='end'>
                {rightIcon}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        error={isError}
        {...props}
      />
      {errorText && isError && (
        <Box mt={0.5}>
          <Typography variant='label' color={'error.main'}>
            {errorText}
          </Typography>
        </Box>
      )}
    </>
  )
}
