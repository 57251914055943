import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { ReactQueryKeys } from '@/types'

export const useForgotPassword = () => {
  return useMutation({
    mutationKey: [ReactQueryKeys.RESET_PASSWORD],
    mutationFn: async (user: string): Promise<boolean> => {
      await Auth.forgotPassword(user)
      return true
    },
  })
}
