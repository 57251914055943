import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '@/hooks/common'
import { useEffect } from 'react'
import { PatternEditContainer } from './__styled'
import { AxiosProvider } from '@/context/Axios'

export default function PatternLayout() {
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.userID) {
      navigate('/login')
    }
  }, [user?.userID])

  return (
    <AxiosProvider>
      <PatternEditContainer>
        <Outlet />
      </PatternEditContainer>
    </AxiosProvider>
  )
}
