import { Note } from '@/types'
import { Typography, TypographyProps } from '@mui/material'
import htmlToDraft from 'html-to-draftjs'
import { useCallback } from 'react'
import { ImageList } from '.'
import ReactTimeago from 'react-timeago'
import { convertToGMT } from '@/utils/helper'

interface Props extends TypographyProps {
  note: Note
  titleOnly?: boolean
  showDateTime?: boolean
}

export function PreviewNote({ note, showDateTime = true, titleOnly = false, ...textProps }: Props) {
  const renderContent = useCallback(() => {
    const { noteText = 'Untitled', egoList = [] } = note || {}
    try {
      const parsed = JSON.parse(noteText)
      const draft = htmlToDraft(parsed.text)
      const value = draft.contentBlocks.map((block) => block.getText()).join('\n')
      return (
        <>
          <Typography className='draft-section last-note-content' {...textProps}>
            {value}
          </Typography>
          {showDateTime == true && note.dateCreated && (
            <Typography
              variant='p'
              fontSize={10}
              lineHeight={'10px'}
              mt={'3px'}
              sx={{ color: '#525E9C' }}
            >
              <ReactTimeago date={convertToGMT(note.dateCreated)} />
            </Typography>
          )}
          {!titleOnly && <ImageList images={egoList} />}
        </>
      )
    } catch (error) {
      return (
        <Typography className='draft-section last-note-content' {...textProps}>
          {noteText}
        </Typography>
      )
    }
  }, [note])

  return renderContent()
}
