/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

interface Param {
  connID: string
  cliques: string[] // clique ids
}

export const useConnectionAdd = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.ADD_CONNECTION],
    mutationFn: async (data: Param) => {
      const response = await axios.api.post('ajax/connections', {
        requestType: 'connReqAdd',
        connID: data.connID,
        'cliqueIDs[]': data.cliques,
        format: 'json',
      })
      return response.data
    },
  })
}
