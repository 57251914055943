import { CheckBox, ConnectionItem, Input, UserAvatar } from "@/components";
import { useGetGroupMembersByID } from "@/hooks/api/dashboard";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { Circle, CircleConnection } from "@/types";
import { KeyboardArrowDown, KeyboardArrowUp, SearchOutlined } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { ConnectionsContainer } from "./__styled";

interface Props {
  groupIDs: string[];
  groupTypes: string[];
  onChangeGroupIDs: (list: string[]) => void;
  onChangeGroupTypes: (list: string[]) => void;
}

export default function SelectGroups({ groupIDs, groupTypes, onChangeGroupIDs, onChangeGroupTypes }: Props) {
  const [searchText, setSearchText] = useState('');
  const [toggledID, setToggledID] = useState('');
  const [myGroups] = useAppStore(state => [state.user.myGroups], shallow)

  const getGroupMembers = useGetGroupMembersByID();

  useEffect(() => {
    if (toggledID) {
      getGroupMembers.mutate(toggledID);
    }
  }, [toggledID])

  const onToggleID = (id: string) => {
    if (toggledID === id) setToggledID('');
    else setToggledID(id)
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }

  const handleCheckBoxChanges = (group: Circle) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const find = groupIDs.indexOf(group.circleID);
    if (find < 0) {
      groupIDs.push(group.circleID);
      groupTypes.push(group.circleTypeID);
    }
    else {
      groupIDs.splice(find, 1);
      groupTypes.splice(find, 1);
    }
    onChangeGroupIDs([...groupIDs])
    onChangeGroupTypes([...groupTypes])
  }

  const renderExpandedCircle = () => {
    if (getGroupMembers.isLoading) {
      return <CircularProgress />
    }
    if ((getGroupMembers.data || []).length === 0) {
      return <Typography variant='label'>No members</Typography>
    }
    return (
      <>
        {getGroupMembers.data?.map((m: CircleConnection) => (
          <ConnectionItem
            url={m.thumbImgURL}
            connID={m.userID}
            name={`${m.firstName} ${m.lastName}`}
            key={m.userID}
          />
        ))}
      </>
    )
  }

  return (
    <ConnectionsContainer>
      <Box p={2} borderBottom={`1px solid ${colors.lightGrey}`}>
        <Typography variant='label'>{myGroups.length || 0} Circle{myGroups.length > 1 ? 's' : ''}</Typography>
      </Box>
      <Box p={2} borderBottom={`1px solid ${colors.lightGrey}`}>
        <Input
          value={searchText}
          placeholder='Search Circles'
          onChange={onChangeSearch}
          rightIcon={<SearchOutlined />}
          name="search-connect"
          size="small"
        />
      </Box>
      <Box overflow="auto" px={2} flex={1} pb={4}>
        {myGroups.map(group => (
          <Box
            key={group.circleID}
            py={1}
            borderBottom={`1px solid ${colors.lightGrey}`}
            display={group.circleName.toLowerCase().includes(searchText.toLowerCase()) ? 'block' : 'none'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{ cursor: 'pointer' }}
            >
              <UserAvatar name={group.circleName} url={group.badgeThumbURL} />
              <Typography variant='label' flex={1} pl={1}>{group.circleName}</Typography>
              <CheckBox
                checked={groupIDs.includes(group.circleID)}
                onChange={handleCheckBoxChanges(group)}
              />
              <IconButton onClick={() => onToggleID(group.circleID)}>
                {toggledID === group.circleID
                  ? <KeyboardArrowUp sx={{ color: colors.text }} />
                  : <KeyboardArrowDown sx={{ color: colors.text }} />
                }
              </IconButton>
            </Box>
            <Box ml={1}>
              {toggledID === group.circleID && renderExpandedCircle()}
            </Box>
          </Box>
        ))}
      </Box>
    </ConnectionsContainer>
  )
}