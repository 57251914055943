/* eslint-disable @typescript-eslint/no-explicit-any */
import { useUploadMedia, useAddNoteTopic, useUpdateNote } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import colors from '@/styles/colors'
import { getFileUniqueKey, getThumbImageURL, uploadMedia } from '@/utils/helper'
import { CheckCircle, Close } from '@mui/icons-material'
import { Button, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FileContent, FileErrors, SelectedFiles, useFilePicker } from 'use-file-picker'
import { toast } from 'react-hot-toast'
import { AddAlbumPreviewItem } from '../__styled'
import { useLocation, useNavigate } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { Ego, Note } from '@/types'
import { CBox, RBox } from '@/pages/__styled'
import { SVGS } from '@/assets/svgs'
import { useDropzone, FileWithPath } from 'react-dropzone'
import { Constants } from '@/utils/constants'
import { TopicDetailsContext } from '@/context/TopicDetails'

export default function AlbumInput() {
  const [files, setFiles] = useState<FileContent[]>([])
  const [pFiles, setPFiles] = useState<File[]>([])
  const [uploadingCount, setUploadingCount] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [noteToEdit, setNoteToEdit] = useState<Note | null>(null)
  const [editEgoObjects, setEditEgoObjects] = useState<Ego[]>([])
  const [ewUser] = useLocalStorage('ewUser', null)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''
  const noteID = query.get('noteID') || ''
  const status = query.get('status')
  const { topic } = useContext(TopicDetailsContext)

  const uploadFile = useUploadMedia()
  const addNoteToTopic = useAddNoteTopic()
  const updateNote = useUpdateNote()
  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    const contents: FileContent[] = []

    let readCount = 0

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => {
        console.log('file reading has aborted')
        readCount += 1
      }
      reader.onerror = () => {
        readCount += 1
        console.log('file reading has failed')
      }
      reader.onload = () => {
        contents.push({
          ...file,
          content: reader.result as string,
          name: file.name,
          lastModified: file.lastModified,
        })
        readCount += 1
        if (readCount == acceptedFiles.length) {
          onFilesSelected({ plainFiles: acceptedFiles, filesContent: contents })
        }
      }
      reader.readAsDataURL(file)
    })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (status === 'edit' && noteID?.length > 0) {
      // Find note and check if i am the owner.
      const note = topic.notes.find((e) => e.noteID == noteID && e.authorID == ewUser?.userID)
      if (note) {
        setNoteToEdit(note)
        setEditEgoObjects([...note.egoList])
      }
    }
  }, [status, noteID])

  useEffect(() => {
    if (addNoteToTopic.isSuccess) {
      query.set('noteID', addNoteToTopic.data.noteID)
      navigate(`${pathname}?${query.toString()}`, { replace: true })
    }
  }, [addNoteToTopic.isSuccess])

  const onFilesSelected = ({ plainFiles, filesContent }: SelectedFiles) => {
    const duplicated = plainFiles.some((i: File) =>
      pFiles.some((j: File) => getFileUniqueKey(i) === getFileUniqueKey(j)),
    )
    if (duplicated) {
      // To-Do: use toast message
      toast.error('You selected one or more duplicated file(s).')
      return
    } else if (files.length + filesContent.length > Constants.imagePickerLimit) {
      toast.error('You can upload 5 files at maximum.')
      return
    }
    if (filesContent.length > 0) {
      setFiles(files.concat(filesContent))
      setPFiles(pFiles.concat(plainFiles))
    }
  }

  const onFilePickError = (error: FileErrors) => {
    const { errors } = error
    errors.forEach((e) => {
      if (e.maxLimitExceeded) {
        toast.error('You can select 5 files at maximum.')
      } else if (e.fileSizeToolarge) {
        toast.error('The file size should be 5MB at maximum.')
      }
    })
  }

  const [openFileSelector, { loading }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    maxFileSize: 5,
    limitFilesConfig: { max: 5 },
    onFilesSuccessfulySelected: onFilesSelected,
    onFilesRejected: onFilePickError,
  })

  const onClickAdd = async () => {
    try {
      const egoItems: Ego[] = [...editEgoObjects]

      if (pFiles.length > 0) {
        setUploading(true)
      }

      for (let i = 0; i < pFiles.length; i++) {
        const result: any = await uploadMedia(pFiles[i])
        if (!result.isError) {
          const photo = await uploadFile.mutateAsync(result)
          egoItems.push({
            sequenceID: `${i}`,
            egoID: photo.egoID,
            photoFullURL: photo.egoImageURL,
            photoAlbumURL: photo.egoAlbumURL,
          })
          setUploadingCount(i + 1)
        }
      }

      if (noteToEdit) {
        updateNote
          .mutateAsync({
            topicID: topicID || '',
            noteID: noteID || '',
            noteText: 'placeholder',
            egoList: egoItems,
          })
          .then((value) => {
            clearEditMode()
            setUploadingCount(0)
            setUploading(false)
          })
      } else {
        addNoteToTopic
          .mutateAsync({
            topicID,
            egoItems,
            noteName: 'untitled',
            noteText: 'placeholder',
          })
          .then((value) => {
            setFiles([])
            setPFiles([])
            setUploadingCount(0)
            setUploading(false)
          })
      }
    } catch (err) {
      // To-Do: show toast message
      setUploading(false)
    }
  }

  const clearEditMode = () => {
    setNoteToEdit(null)
    setFiles([])
    setPFiles([])
    setEditEgoObjects([])

    query.delete('status')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onRemoveEgoObject = (index: number) => {
    editEgoObjects.splice(index, 1)
    const updated = [...editEgoObjects]
    setEditEgoObjects(updated)

    if (files.length == 0 && updated.length == 0) {
      clearEditMode()
    }
  }

  const onRemoveFile = (index: number) => {
    files.splice(index, 1)
    const updatedFiles = [...files]
    pFiles.splice(index, 1)
    setFiles(updatedFiles)
    setPFiles([...pFiles])

    if (files.length == 0 && updatedFiles.length == 0 && noteToEdit != null) {
      clearEditMode()
    }
  }

  return (
    <Box
      p={2}
      onClick={openFileSelector}
      sx={{
        border: `1px dashed ${colors.meshdomBlue}`,
        borderRadius: '8px',
        backgroundColor: colors.meshdomBlueLight,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <RBox justifyContent={'space-between'} alignItems='center'>
        <RBox gap={'16px'} ml={'10'}>
          <SVG src={SVGS.UploadFilledIcon} width={32} height={32} />
          <CBox>
            <RBox gap={'4px'}>
              <Typography fontSize={15} fontWeight={'500'} color={colors.themeBlack}>
                {'Drag & Drop or'}
              </Typography>
              <Typography
                fontSize={15}
                fontWeight={'500'}
                color={colors.meshdomBlue}
              >{`Choose file(s)`}</Typography>
              <Typography fontSize={15} fontWeight={'500'} color={colors.themeBlack}>
                {'to upload'}{' '}
              </Typography>
            </RBox>
            <Typography color={colors.themePurpleTextColor} fontSize={10} fontWeight={'400'}>
              Supported formats : jpg, png gif, tiff
            </Typography>
          </CBox>
        </RBox>
        {(files?.length > 0 || editEgoObjects.length > 0) && (
          <Button
            variant='contained'
            fullWidth={false}
            disabled={false}
            onClick={(event) => {
              event.stopPropagation()
              onClickAdd()
            }}
          >
            {uploading
              ? `Uploading... (${uploadingCount}/${files.length})`
              : noteToEdit
                ? 'Update'
                : 'Add'}
          </Button>
        )}
      </RBox>
      {(files?.length > 0 || editEgoObjects.length > 0) && (
        <Box display={'flex'} overflow='auto' gap={'8px'}>
          {editEgoObjects.map((egoItem: Ego, index: number) => (
            <AddAlbumPreviewItem position='relative' key={`${egoItem.egoID}`}>
              <img src={getThumbImageURL(egoItem.photoAlbumURL)} />
              <IconButton
                sx={{ zIndex: 10 }}
                onClick={(event) => {
                  event.stopPropagation()
                  onRemoveEgoObject(index)
                }}
              >
                <Close />
              </IconButton>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                }}
              >
                <CheckCircle sx={{ opacity: 0.8, color: colors.meshdomBlue }} />
              </Box>
            </AddAlbumPreviewItem>
          ))}
          {files.map((file: FileContent, index: number) => (
            <AddAlbumPreviewItem position='relative' key={`${index}-${file.name}`}>
              <img src={file.content} />
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  onRemoveFile(index)
                }}
              >
                <Close />
              </IconButton>
            </AddAlbumPreviewItem>
          ))}
        </Box>
      )}
    </Box>
  )
}
