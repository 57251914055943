import colors from '@/styles/colors'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const UploadContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .tab': {
    flex: 1,
    color: colors.darkGrey,
    borderRadius: 0,
    fontSize: 13,
    height: 50,
    '&.active': {
      borderBottom: `2px solid ${colors.blue}`,
      color: colors.blue,
    },
  },
  '& .list-wrapper': {
    overflow: 'auto',
    flex: 1,
  },
  '& .helper': {
    padding: 16,
    backgroundColor: `${colors.blue}55`,
  },
  '& .photo-item': {
    borderLeft: `4px solid white`,
    borderBottom: `1px solid ${colors.veryLightGrey}`,
    cursor: 'pointer',
    '&.active': {
      borderLeft: `4px solid ${colors.yellow}`,
      boxShadow: '0 1px 2px rgba(0,0,0,.1)',
    },
  },
  '& .preview': {
    height: 150,
    display: 'flex',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 4,
    },
  },
})

export const EgosContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: 4,
  height: '-webkit-fill-available',
  display: 'flex',
  flexDirection: 'column',
  '& .photo-item': {
    position: 'relative',
  },
  '& .checked': {
    backgroundColor: 'white',
    borderRadius: '50%',
    position: 'absolute',
    top: 14,
    left: 30,
    width: 24,
    height: 24,
  },
})

export const UploadPhotoItem = styled(Box)(({ photo }: { photo: string }) => ({
  backgroundImage: `url(${photo})`,
  backgroundSize: 'cover',
  height: 0,
  paddingBottom: '100%',
  marginTop: -16,
  marginBottom: 16,
  borderRadius: 10,
  overflow: 'hidden',
  cursor: 'pointer',
}))

export const SelectTopicContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 20,
  },
  '& .header': {
    padding: 16,
    borderBottom: `1px solid ${colors.veryLightGrey}`,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 10,
  },
  '& .footer': {
    padding: 16,
    paddingLeft: 0,
    borderTop: `1px solid ${colors.veryLightGrey}`,
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 10,
  },
}))
