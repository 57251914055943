/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useConfirm } from '@/hooks/common'
import colors from '@/styles/colors'
import {
  IPatternAsset,
  IPatternAssetColor,
  IPatternAssetMaterial,
  IPatternAssetTemplate,
  PatternSelections,
} from '@/types'
import {
  BoarderTypes,
  BoarderWidthRange,
  PatternAssetsMap,
  PatternAssetTabs,
  TopicType,
} from '@/utils/constants'
import { Assets, TemplateSets } from '@/utils/pattern-assets'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import AlbumList from './components/AlbumList'
import PrintTabView from './components/PrintTabView'
import UploadsList from './components/UploadsList'
import {
  AssetColorItem,
  AssetItem,
  PatternAssetButton,
  PatternAssetList,
  PatternAssetWrapper,
  ButtonDivider,
} from './__styled'
import SVG from 'react-inlinesvg'

interface Props {
  selectedAssets: PatternSelections
  topicType: string
  onAssetSelected: (category: string, assets: PatternSelections) => void
  onTabSelected: (category: string) => void
  onAddTextElement: (type: number) => void
  onTemplateChanged: () => void
}

export default function AssetSelector({
  selectedAssets,
  topicType,
  onAssetSelected,
  onTabSelected,
  onAddTextElement,
  onTemplateChanged,
}: Props) {
  const [category, setCategory] = useState('template')
  const { dialog: WarningTemplateDialog, confirm: warningTemplate } = useConfirm(
    'Confirmation',
    'Are you sure you want to change your template? You will loose all the changes and so reset entire note details with new template details',
  )

  const onPressCategory = (ctgy: string) => () => {
    setCategory(ctgy)
    onTabSelected(ctgy)
  }

  // const onToggleCategoryBar = () => {
  //   setCategoryShown(!categoryShown);
  // }

  const onSelectAsset = async (id: string | number) => {
    let updated: PatternSelections = {
      ...selectedAssets,
    }

    const c = category === 'album' || category === 'upload' ? 'print' : category
    if (c === 'template' && selectedAssets.template !== id) {
      const ans = await warningTemplate()
      if (!ans) return
      updated['accent'] = undefined
      onTemplateChanged()
    }

    updated = {
      ...updated,
      [c]: (updated as any)[c] === id ? null : id,
    }

    onAssetSelected(c, updated)
  }

  const onPressTabIndex = (key: string, index: number) => {
    if (key === 'album' || key === 'upload') {
      setCategory(key)
    } else {
      setCategory('template')
    }
  }

  const handleBoarderChange = (type: string) => (event: SelectChangeEvent) => {
    onAssetSelected(category, {
      ...selectedAssets,
      [category]: {
        ...(selectedAssets as any)[category],
        [type]: event.target.value,
      },
    })
  }

  const renderPatternAsset = (item: IPatternAsset) => {
    let asset: any
    const selectedId = String((selectedAssets as any)[category])
    if (category === 'template' || category === 'accent' || category === 'scene') {
      asset = item as IPatternAssetTemplate
      return (
        <AssetItem
          sx={{ width: '40px', height: '40px' }}
          className={selectedId === String(asset.id) ? 'active' : ''}
          onClick={() => onSelectAsset(asset.id)}
          key={asset.id}
        >
          <img
            style={{ width: '40px', height: '40px' }}
            src={`/assets/patterns/${asset.thumb}`}
            alt={item.name}
          />
        </AssetItem>
      )
    }
    if (category === 'material') {
      asset = item as IPatternAssetMaterial
      return (
        <AssetItem
          className={selectedId === String(asset.id) ? 'active' : ''}
          onClick={() => onSelectAsset(asset.id)}
          key={asset.id}
        >
          <img
            style={{ width: '85px', height: '85px', borderRadius: 8 }}
            src={`/assets/patterns/${asset.thumb}`}
            alt={item.name}
          />
        </AssetItem>
      )
    }
    if (category === 'color') {
      asset = item as IPatternAssetColor
      return (
        <AssetColorItem
          key={asset.hex}
          onClick={() => onSelectAsset(asset.hex[0])}
          className={selectedId === asset.hex[0] ? 'active' : ''}
          bgcolor={asset.hex[0]}
          title={`${asset.name} - ${asset.hex[0]}`}
        />
      )
    }
  }

  const renderAssets = () => {
    if (category === 'template') {
      if (topicType === TopicType.GALLERY.toString()) {
        return (
          <PatternAssetList category={category} key={category}>
            {TemplateSets.GalleryTemplates.map((item: IPatternAsset) => {
              return renderPatternAsset(item)
            })}
          </PatternAssetList>
        )
      } else if (topicType === TopicType.QUILT.toString()) {
        return (
          <PatternAssetList category={category} key={category}>
            {TemplateSets.QuiltTemplates.map((item: IPatternAsset) => {
              return renderPatternAsset(item)
            })}
          </PatternAssetList>
        )
      }
      return (
        <PatternAssetList category={category} key={category}>
          {TemplateSets.PatternTemplates.map((item: IPatternAsset) => renderPatternAsset(item))}
        </PatternAssetList>
      )
    }
    if (category === 'material') {
      return (
        <PatternAssetList category={category} key={category}>
          {Assets.materials.map((item: IPatternAsset) => renderPatternAsset(item))}
        </PatternAssetList>
      )
    }
    if (category === 'print') {
      const selectedId = String((selectedAssets as any)[category])
      return (
        <PrintTabView
          selectedId={selectedId}
          onSelect={(id: number | string) => onSelectAsset(id)}
        />
      )
    }
    if (category === 'color') {
      return (
        <PatternAssetList category={category} key={category}>
          {Assets.colors.map((item: IPatternAsset) => {
            return renderPatternAsset(item)
          })}
        </PatternAssetList>
      )
    }
    if (category === 'accent') {
      return (
        <PatternAssetList category={category} key={category}>
          {Assets.accents.map((item: IPatternAsset) => {
            return renderPatternAsset(item)
          })}
        </PatternAssetList>
      )
    }
    if (category === 'scene') {
      return (
        <PatternAssetList category={category} key={category}>
          {Assets.scenes.map((item: IPatternAsset) => {
            return renderPatternAsset(item)
          })}
        </PatternAssetList>
      )
    }
    if (category === 'text') {
      return (
        <PatternAssetList category={category} key={category}>
          <Button variant='contained' onClick={() => onAddTextElement(1)}>
            ADD A HEADING
          </Button>
          <Button variant='contained' onClick={() => onAddTextElement(2)}>
            ADD A SUBHEADING
          </Button>
          <Button variant='contained' onClick={() => onAddTextElement(3)}>
            ADD TEXT
          </Button>
        </PatternAssetList>
      )
    }
    if (category === 'boarder') {
      const { width, type } = (selectedAssets as any)[category]
      return (
        <Box flex={1} bgcolor={colors.white} p={3}>
          <Typography color={colors.themePurpleTextColor}>Border Size</Typography>
          <FormControl fullWidth>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={width}
              onChange={handleBoarderChange('width')}
            >
              {BoarderWidthRange.map((w) => (
                <MenuItem value={w} key={w}>
                  {w}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color={colors.themePurpleTextColor} mt={4}>
            Border Type
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={type}
              onChange={handleBoarderChange('type')}
            >
              {BoarderTypes.map((t) => (
                <MenuItem value={t} key={t}>
                  {t.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )
    } else if (category == 'upload') {
      const selectedID = String((selectedAssets as any)['print'])
      return (
        <Box className='content' width={'100%'}>
          <UploadsList
            selectedAssetID={selectedID}
            onSelectAsset={(id: number | string) => onSelectAsset(id)}
          />
        </Box>
      )
    } else if (category == 'album') {
      const selectedID = String((selectedAssets as any)['print'])
      return (
        <Box className='content' width={'100%'}>
          <AlbumList
            selectedAssetID={selectedID}
            onSelectAsset={(id: number | string) => onSelectAsset(id)}
          />
        </Box>
      )
    }
  }

  const renderTabContent = () => (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box
        bgcolor='white'
        display='flex'
        flexDirection='column'
        alignItems='center'
        position='relative'
        borderRadius='8px'
        height={'100%'}
        paddingBottom={'12px'}
        boxShadow='0px -5px 35px 0px #00000026'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '90px' }}>
          {PatternAssetsMap[topicType]?.map((item) => {
            const { categoryName, icon } = item
            return (
              <>
                <PatternAssetButton
                  variant='text'
                  key={item.categoryName}
                  onClick={onPressCategory(categoryName)}
                  className={category === categoryName ? 'active' : ''}
                >
                  <SVG
                    src={icon}
                    color={
                      category === categoryName ? colors.meshdomBlue : colors.themePurpleTextColor
                    }
                    width={20}
                    height={20}
                  />
                  <Typography
                    fontSize={'11px'}
                    fontWeight={category === categoryName ? 600 : 500}
                    sx={{
                      textTransform: 'capitalize',
                      color:
                        category === categoryName
                          ? colors.meshdomBlue
                          : colors.themePurpleTextColor,
                    }}
                  >
                    {categoryName}
                  </Typography>
                </PatternAssetButton>
                <ButtonDivider />
              </>
            )
          })}

          {/* albums and uploads */}
          <Box display='flex' className={'tab'} gap='10px'>
            {PatternAssetTabs[topicType]?.map((tab, index) => (
              <IconButton
                sx={{ p: '10px' }}
                key={tab.key}
                className={category === tab.key ? 'active' : ''}
                onClick={() => onPressTabIndex(tab.key, index)}
              >
                <SVG src={tab.icon} width={20} height={20} />
              </IconButton>
            ))}
          </Box>
        </Box>
        {/* Main Selector */}

        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '32px',
            bottom: '32px',
            left: '90px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            overflowY: 'auto',
          }}
        >
          <Box
            display={'flex'}
            width={
              category === 'album' || category === 'upload'
                ? '198px'
                : category === 'boarder'
                  ? '190px'
                  : '138px'
            }
          >
            {renderAssets()}
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          overflowY: 'auto',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          // bgcolor: '#cfcfcf',
          my: '16px',
          height: '100%',
          backgroundColor: 'blue',
        }}
      >
        <Box
          height={'100%'}
          width={
            category === 'album' || category === 'upload'
              ? '198px'
              : category === 'boarder'
                ? '190px'
                : '138px'
          }
        >
          {renderAssets()}
        </Box>
      </Box> */}
    </Box>
  )

  return (
    <PatternAssetWrapper>
      {renderTabContent()}
      <WarningTemplateDialog />
    </PatternAssetWrapper>
  )
}
