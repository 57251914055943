/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

interface Param {
  resourceID: string
  cliqueTypes: string[]
  cliques: string[]
  connections: string[]
  accessTypeID: string
  permissionID: string
  resourceType: number
}

export const useUpdateVisibility = () => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.UPDATE_VISIBILITY],
    mutationFn: async (param: Param) => {
      const {
        resourceID,
        cliqueTypes,
        cliques,
        connections,
        accessTypeID,
        permissionID,
        resourceType,
      } = param
      const response = await axios.api.post('ajax/permissions', {
        requestType: 'resourcePermUpdate',
        uresourceID: resourceID,
        resourceType: resourceType, // 6 = topic 4 = profile visibility
        'permissionData[accessList][cliqueTypes][]': cliqueTypes.join('","'),
        'permissionData[accessList][cliques][]': cliques.join('","'),
        'permissionData[accessList][connections][]': connections.join('","'),
        'permissionData[accessTypeID]': accessTypeID,
        permissionID: permissionID,
        format: 'json',
      })
      return response.data
    },
  })
}
