import { useBrowserQuery } from '@/hooks/common'
import {
  RestorePattern,
  SetTopicType,
  PerformOperation as Perform3DTask,
  StartRenderer,
} from '@/pages/pattern/renderer/main'
import useAppStore from '@/store/useAppStore'
import { Note, PatternDraft, Topic } from '@/types'
import { Assets } from '@/utils/pattern-assets'
import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
interface Props {
  topic: Topic
}

export default function PatternContent({ topic }: Props) {
  const query = useBrowserQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { setInitialScreenShot } = useAppStore()

  const canvas = useRef(null)

  useEffect(() => {
    if (canvas.current) {
      StartRenderer({
        canvas: canvas.current,
        assets: Assets,
        canGrab: false,
        mode: 'preview',
        onPatternRestore: () => {},
        onEdit: () => {},
      })
      SetTopicType(topic.topicType)
    }
  }, [canvas, topic])

  useEffect(() => {
    if (!topic?.notes) return
    const draft: PatternDraft = { templateID: 1 }
    topic.notes?.forEach((note: Note) => {
      if (note.noteType === '8') {
        // setup initial text overlay
      } else if (note.noteType === '9') {
        // preview
      } else if (note.noteType !== '1' && note.noteText !== 'null' && note.noteText != null) {
        draft[note.noteName as keyof PatternDraft] = JSON.parse(note.noteText)
      }
    })
    if (draft) {
      RestorePattern(draft, true, () => {
        takeScreenShot()
      })
    }
  }, [topic])

  useEffect(() => {
    query.set('noteID', topic?.firstNote?.noteID)
    navigate(`${pathname}?${query.toString()}`)
  }, [topic])

  const takeScreenShot = () => {
    Perform3DTask(
      'screenshot',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => {
        setInitialScreenShot(data)
      },
      512,
    )
  }

  return (
    <Box display='flex' justifyContent={'center'} flex={1} sx={{ overflowY: 'auto' }}>
      <canvas id='renderingCanvas' ref={canvas} style={{ width: '100%', height: '100%' }}></canvas>
    </Box>
  )
}
