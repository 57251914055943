/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, IconButton } from '@mui/material'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import SVG from 'react-inlinesvg'
import PatternToolBar from './ToolBar'
import {
  StartRenderer,
  SetCurrentItem,
  RestorePattern,
  PerformOperation as Perform3DTask,
  APPLICATION_MODES,
  SetCanvasMode,
} from './renderer/main'
import { Stage, Layer, Text, Transformer } from 'react-konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { DoneOutline, EditOutlined, PreviewOutlined } from '@mui/icons-material'
import { IPatternAssets, PatternDraft, PatternProps, PatternSelections } from '@/types'
import { SettingButtonWrapper } from './__styled'
import colors from '@/styles/colors'
import { SVGS } from '@/assets/svgs'
import { useBrowserQuery } from '@/hooks/common'
import { useLocation, useNavigate } from 'react-router-dom'

const a = document.createElement('a')
a.style.display = 'none'

interface Props {
  assets: IPatternAssets
  selection: PatternSelections
  selectedTab: string
  patternData: PatternDraft
  textData: string
  isEditMode: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit: (data: Omit<PatternDraft, 'preview'>) => void
  addScreenshot: (file: File) => void
  onPatternRestore: (patternProps: PatternProps) => void
  onAssetsUpdate: (assets: IPatternAssets) => void
  onToggleFullScreenMode: () => void
}

const PatternPreview = forwardRef(function PatternPreview(
  {
    assets,
    selection,
    selectedTab,
    patternData,
    textData,
    isEditMode,
    onEdit,
    addScreenshot,
    onPatternRestore,
    onAssetsUpdate,
    onToggleFullScreenMode,
  }: Props,
  ref,
) {
  const [patternLoaded, setPatternLoaded] = useState(false)
  const [textLoaded, setTextLoaded] = useState(false)
  const [altKeyPressed, setAltKeyPressed] = useState(false)
  const [showOverlay, setShowOverlay] = useState(true)
  const [isInspectorShown, setIsInspectorShown] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textElements, setTextElements] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [konvaElements, setKonvaElements] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentEditingElement, setCurrentEditingElement] = useState<any>(null)
  const [appMode, setAppMode] = useState(APPLICATION_MODES.FULL_SCREEN)
  const [currentTab, setCurrentTab] = useState('template')

  const canvas = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textContainer = useRef<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stage = useRef<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformer = useRef<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textArea = useRef<any>()
  const konvaImageData = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const query = useBrowserQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  // const mode = query.get('mode') || 'preview'
  const mode = isEditMode ? 'edit' : 'preview'

  useEffect(() => {
    if (canvas.current) {
      StartRenderer({
        canvas: canvas.current,
        assets: assets,
        onEdit: onEdit,
        canGrab: true,
        mode,
        onPatternRestore: onPatternRestore,
        onAssetsUpdate: onAssetsUpdate,
        setMode: (m: number) => {
          setMode(m)
        },
      })
      setAppMode(APPLICATION_MODES.FULL_SCREEN)
      const canvasObj = canvas.current as HTMLCanvasElement
      canvasObj.addEventListener('keydown', onKeyDown)
      canvasObj.addEventListener('keyup', onKeyUp)
    }
  }, [canvas])

  useEffect(() => {
    if (mode === 'preview') {
      SetCurrentItem(null, '')
    }
    SetCanvasMode(mode)
  }, [mode])

  useEffect(() => {
    if (patternData && !patternLoaded) {
      RestorePattern(patternData, true, function () {
        setShowOverlay(true)
        setPatternLoaded(true)
      })
    }
  }, [patternData])

  useEffect(() => {
    if (selection.color && selectedTab === 'color' && currentEditingElement) {
      currentEditingElement.attrs.fill = selection.color
      transformer.current.nodes([currentEditingElement])
    }
  }, [selection, selectedTab, currentEditingElement])

  useEffect(() => {
    if (!textContainer.current) return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    function onTextElementChanged(e: any) {
      onTextUpdated()
    }

    const canvasWidth = textContainer.current.parentElement.clientWidth
    const canvasHeight = textContainer.current.parentElement.clientHeight
    let index = 0
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const temp: any[] = []
    textElements.forEach((elData) => {
      const elProps = Object.assign({}, elData)
      delete elProps.type
      elProps.x += canvasWidth / 2
      elProps.y += canvasHeight / 2
      elProps.draggable = true
      elProps.key = index++
      elProps.onDragEnd = onTextElementChanged
      temp.push(React.createElement(Text, elProps))
    })
    setKonvaElements(temp)
  }, [textContainer, textElements])

  useEffect(() => {
    if (textData && !textLoaded) {
      setTextElements(JSON.parse(textData) || [])
      setTextLoaded(true)
    }
  }, [textData])

  useImperativeHandle(
    ref,
    () => {
      return {
        updateRender() {
          updateRendererState()
        },

        getScreenShotFile(callback: (file: File) => void) {
          return getScreenShotFile(callback)
        },

        saveScreenShot() {
          onClickDone()
        },
        addText(type: number) {
          addElementByType(type)
        },
        setMode(mode: number) {
          setMode(mode)
        },
      }
    },
    [selection, selectedTab, currentTab, textElements],
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyDown = (event: any) => {
    setAltKeyPressed(event.altKey)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyUp = (event: any) => {
    setAltKeyPressed(event.altKey)
  }

  const updateRendererState = () => {
    setAppMode(APPLICATION_MODES.NONE)
    if (currentTab === 'text' && selectedTab !== 'text') {
      Perform3DTask('applyText', konvaImageData.current)
    }
    SetCurrentItem(selection, selectedTab)
    setCurrentTab(selectedTab)
    updateCanvasData()
  }

  const updateCanvasData = () => {
    window.requestAnimationFrame(function () {
      if (selectedTab === 'text') konvaImageData.current = stage.current.toDataURL()
    })
  }

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay)
    Perform3DTask('toggleOverlay', !showOverlay)
  }

  const toggleInspector = () => {
    setIsInspectorShown(!isInspectorShown)
    Perform3DTask('toggleInspector', !isInspectorShown)
  }

  /**
   * Will make browser prompt to download file.
   * Can be used to download in memory image as file etc.
   * @memberof HELPERS
   * @param {(String|Blob)} data string data of the file
   * @param {String} fileName the filename of the browser downloaded file
   * @param {String} [type] the file mime type
   * @param {Boolean} [wantblob=false] Flag to return a blob Object instead of prompting user to
   * download
   * @returns {?Blob}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadData = (data: any, fileName: string, type: string, wantblob: boolean) => {
    let blob
    if (type) {
      if (type !== 'blob') {
        if (type === 'json') {
          blob = createBlob(JSON.stringify(data))
        } else {
          blob = createBlob(data)
        }
      } else {
        blob = data
      }
    } else {
      if (typeof data == 'string') {
        if (data.match(/^data:image\/[^;]/)) {
          // eslint-disable-next-line camelcase
          const image_data = atob(data.split(',')[1])
          // Use typed arrays to convert the binary data to a Blob
          // eslint-disable-next-line camelcase
          const arraybuffer = new ArrayBuffer(image_data.length)
          const view = new Uint8Array(arraybuffer)
          // eslint-disable-next-line camelcase
          for (let i = 0; i < image_data.length; i++) {
            // eslint-disable-next-line camelcase
            view[i] = image_data.charCodeAt(i) & 0xff
          }
          blob = createBlob(arraybuffer, 'image/png')
        } else {
          blob = createBlob(data, 'image/png')
        }
      } else if (data instanceof Blob) {
        blob = /** @type Blob */ data
      } else {
        blob = createBlob(JSON.stringify(data))
      }
    }

    if (wantblob) {
      return /** @type Blob */ blob
    } else {
      const url = window.URL.createObjectURL(blob)
      document.body.appendChild(a)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }

  /**
   * Creates a blob from passed string of data
   * @param {String|Blob|ArrayBuffer|ArrayBufferView} string String data
   * @param {String} [type="octec/stream"] - type of blob to form. Can only be one of the acceptable
   * blob types
   * @returns {Blob} Blob
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createBlob = (name: any, type?: string) => {
    return new Blob([name], {
      type: type || 'octet/stream',
    })
  }

  const onTogglePreview = () => {
    query.set('mode', mode === 'edit' ? 'preview' : 'edit')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickPrivacy = () => {}

  const onClickDone = async () => {
    // addScreenshot
    const file = await takeScreenShotPromise()
    addScreenshot(file)
  }

  const getScreenShotFile = async (callback: (file: File) => void) => {
    const file = await takeScreenShotPromise()
    callback(file)
  }

  const takeScreenShotPromise = () => {
    return new Promise<File>((resolve, reject) => {
      Perform3DTask(
        'screenshot',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
          const imageBlob = downloadData(data, 'screenshot.png', '', true)
          const file = new File([imageBlob], 'screenshot.png', {
            type: 'image/png',
          })
          resolve(file)
        },
        512,
      )
    })
  }

  const handleUndo = () => {
    setAppMode(APPLICATION_MODES.NONE)
    Perform3DTask('undoPatternEdit')
  }

  const handleRedo = () => {
    setAppMode(APPLICATION_MODES.NONE)
    Perform3DTask('redoPatternEdit')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCanvasClick = (e: KonvaEventObject<MouseEvent>) => {
    if (e.target === stage.current) {
      transformer.current.nodes([])
      updateTextObjectProps()
      return
    }

    // do nothing if clicked NOT on our rectangles
    if (e.target.nodeType !== 'Shape') {
      return
    }
    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
    const isSelected = transformer.current.nodes().indexOf(e.target) >= 0

    if (!metaPressed && !isSelected) {
      transformer.current.nodes([e.target])
      overlayTextAreaOverElement(e.target)
    } else if (metaPressed && isSelected) {
      const nodes = transformer.current.nodes().slice() // use slice to have new copy of array
      nodes.splice(nodes.indexOf(e.target), 1)
      transformer.current.nodes(nodes)
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      const nodes = transformer.current.nodes().concat([e.target])
      transformer.current.nodes(nodes)
    }
  }

  const addElementByType = (type: number) => {
    switch (type) {
      case 1:
        addElementWithData({
          type: 'text',
          text: 'Main Heading',
          fontSize: 30,
          fill: 'black',
          name: Math.random() + 'headingText',
          fontFamily: 'Calibri',
          x: 0,
          y: -200,
        })
        break
      case 2:
        addElementWithData({
          type: 'text',
          text: 'Sub Heading',
          fontSize: 20,
          fill: 'black',
          name: Math.random() + 'headingText',
          fontFamily: 'Calibri',
          x: 0,
          y: 0,
        })
        break
      case 3:
        addElementWithData({
          type: 'text',
          text: 'Enter your text here.',
          fontSize: 14,
          fill: 'black',
          name: Math.random() + 'headingText',
          fontFamily: 'Calibri',
          x: 0,
          y: 100,
        })
        break
      default:
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addElementWithData = (data: any) => {
    setTextElements([...textElements, data])
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overlayTextAreaOverElement = (konvaElement: any) => {
    if (textArea.current && konvaElement) {
      const { x, y, rotation, scaleX = 1, scaleY = 1 } = konvaElement.attrs
      setCurrentEditingElement(konvaElement)
      const angle = (rotation / 180) * Math.PI
      const w = Math.round(konvaElement.width() * scaleX) + 7
      const h = Math.round(konvaElement.height() * scaleY)
      const offsetX = Math.cos(angle) * (w / 2 - (Math.tan(angle) * h) / 2) - w / 2
      const offsetY = Math.sin(angle) * (w / 2 - (Math.tan(angle / 2) * h) / 2)
      const fontSize = konvaElement.fontSize()
      textArea.current.style.display = 'block'
      textArea.current.style.left = `${x + offsetX}px`
      textArea.current.style.top = `${y + offsetY}px`
      textArea.current.style.width = w + 'px'
      textArea.current.style.height = h + 'px'
      textArea.current.style.fontSize = fontSize * scaleX + 'px'
      textArea.current.style.fontStyle = konvaElement.fontStyle()
      textArea.current.style.fontFamily = konvaElement.fontFamily()
      textArea.current.style.lineHeight = fontSize * scaleY + 'px'
      textArea.current.style.transform = 'rotate(' + konvaElement.attrs.rotation + 'deg)'
      textArea.current.value = konvaElement.text()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onTextareaInput = () => {
    currentEditingElement.text(textArea.current.value)
    overlayTextAreaOverElement(currentEditingElement)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTextObjectProps = (keepEditing?: boolean) => {
    if (!textContainer.current || !currentEditingElement) return
    textArea.current.style.display = 'none'
    const canvasWidth = textContainer.current.parentElement.clientWidth
    const canvasHeight = textContainer.current.parentElement.clientHeight
    const elementObject = textElements.find((elObj) => elObj.name === currentEditingElement.name())
    Object.assign(elementObject, currentEditingElement.attrs)
    elementObject.x -= canvasWidth / 2
    elementObject.y -= canvasHeight / 2
    setTextElements([...textElements])
    if (!keepEditing) setCurrentEditingElement(null)
  }

  const setMode = (mode: number) => {
    let newMode = mode
    Perform3DTask('updateMergeMode', mode)
    if (mode === appMode) {
      newMode = APPLICATION_MODES.NONE
      // this so that application mode is currently set based on current tab
      SetCurrentItem(selection, selectedTab)
    }
    setAppMode(newMode)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTextUpdated = () => {
    updateTextObjectProps()
    updateCanvasData()
  }

  const onPressMerge = () => {
    setMode(APPLICATION_MODES.MERGE_SURFACES)
  }
  const onPressSplit = () => {
    setMode(APPLICATION_MODES.SPLIT_SURFACES)
  }
  const onPressRotate = () => {
    setMode(APPLICATION_MODES.ROTATE_TILE)
  }
  const onPressColorPick = () => {
    setMode(
      appMode === APPLICATION_MODES.PICKER_PICKED
        ? APPLICATION_MODES.PICKER_PICKED
        : APPLICATION_MODES.PICKER_PICKING,
    )
  }
  const onPressFullScreenMode = () => {
    setAppMode(
      appMode === APPLICATION_MODES.FULL_SCREEN
        ? APPLICATION_MODES.NONE
        : APPLICATION_MODES.FULL_SCREEN,
    )
    onToggleFullScreenMode()
  }

  return (
    <Box position='relative' flex={1}>
      <canvas
        id='renderingCanvas'
        ref={canvas}
        style={{
          width: '100%',
          height: '100%',
          cursor: altKeyPressed ? 'grab' : 'auto',
        }}
      />
      {mode === 'edit' && (
        <PatternToolBar
          mode={appMode}
          bordered={showOverlay}
          debugMode={isInspectorShown}
          onPressBack={handleUndo}
          onPressForward={handleRedo}
          onPressMerge={onPressMerge}
          onPressSplit={onPressSplit}
          onPressRotate={onPressRotate}
          onPressColorPick={onPressColorPick}
          onPressGrid={toggleOverlay}
          onPressInspector={toggleInspector}
          onPressFullMode={onPressFullScreenMode}
        />
      )}
      {/* <SettingButtonWrapper>
        <IconButton sx={{ color: colors.purple }} onClick={onClickDone}>
          <DoneOutline></DoneOutline>
        </IconButton>
        <IconButton sx={{ color: colors.purple }} onClick={onTogglePreview}>
          {mode === 'edit' ? <PreviewOutlined></PreviewOutlined> : <EditOutlined></EditOutlined>}
        </IconButton>
        <IconButton sx={{ color: colors.purple }} onClick={onClickPrivacy}>
          <SVG src={SVGS.FilterPrivacyPrivate} />
        </IconButton>
      </SettingButtonWrapper> */}
    </Box>
  )
})

export default PatternPreview
