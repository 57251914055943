import { RichEditor } from '@/components'
import { useAddNoteTopic, useDeleteEgo, useUpdateNote, useUploadMedia } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { Ego, Note } from '@/types'
import { uploadMedia } from '@/utils/helper'
import { Box } from '@mui/material'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FileContent } from 'use-file-picker'
import { shallow } from 'zustand/shallow'

interface InputProps {
  topicID: string
  notes: Note[]
  onSubmit: () => void
}

export default function RichInput({ topicID, notes, onSubmit }: InputProps) {
  const [content, setContent] = useState<ContentState | null>(null)
  const [editorState, setEditorState] = useState<EditorState | undefined>(undefined)
  const [files, setFiles] = useState<FileContent[]>([])
  const [pFiles, setPFiles] = useState<File[]>([])
  const [egos, setEgos] = useState<Ego[]>([])
  const [removedEgoIDs, setRemovedEgoIDs] = useState<string[]>([])
  const [uploading, setUploading] = useState(false)
  const [CT, setCT] = useState(new Date().getTime())

  const [basicInfo] = useLocalStorage('ewBasicInfo', null)
  const uploadFile = useUploadMedia()
  const addNoteToTopic = useAddNoteTopic()
  const updateNote = useUpdateNote()
  const query = useBrowserQuery()
  const noteID = query.get('noteID')
  const status = query.get('status')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const deleteEgo = useDeleteEgo(topicID || '')
  const { updateTopicSelectedAt } = useAppStore()
  const [myDMConnections] = useAppStore((state) => [state.user.myDMConnections], shallow)

  useEffect(() => {
    if (status === 'edit') {
      const note = notes?.find((i) => i.noteID === noteID)
      if (!note) return
      let html
      try {
        const obj = JSON.parse(note.noteText)
        html = obj.text || ''
      } catch (e) {
        html = note.noteText
      }
      try {
        const blocksFromHtml = htmlToDraft(html)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
        const editorState = EditorState.createWithContent(contentState)
        setContent(contentState)
        setEditorState(editorState)
        setEgos(note.egoList || [])
        setCT(new Date().getTime())
      } catch (e) {
        console.log(e)
      }
    }
  }, [status, noteID, myDMConnections])

  const onChangeEditor = (editorState: EditorState) => {
    setContent(editorState.getCurrentContent())
    setEditorState(editorState)
  }

  const onRemoveEgo = (ego: Ego) => {
    setEgos(egos.filter((i) => i.egoID !== ego.egoID))
    setRemovedEgoIDs(removedEgoIDs.concat([ego.egoID]))
  }

  const resetForm = () => {
    onSubmit()
    setFiles([])
    setPFiles([])
    setCT(new Date().getTime())
    updateTopicSelectedAt()
    setEditorState(undefined)
    setEgos([])
    setRemovedEgoIDs([])
  }

  const onCancelEdit = () => {
    setFiles([])
    setPFiles([])
    setEditorState(undefined)
    setEgos([])
    setRemovedEgoIDs([])
    query.delete('status')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onAddDMNote = async () => {
    if (!content && pFiles.length == 0) return
    setUploading(true)
    if (status === 'edit') {
      // edit DM
      try {
        // const egoList = egos.map((i) => i.egoID)
        const egoList = [...egos]

        // upload attached images
        for (let i = 0; i < pFiles.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: any = await uploadMedia(pFiles[i])
          if (!result.isError) {
            const photo = await uploadFile.mutateAsync(result)
            egoList.push({
              sequenceID: `${i}`,
              egoID: photo.egoID,
              photoFullURL: photo.egoImageURL,
              photoAlbumURL: photo.egoAlbumURL,
            })
          }
        }
        await updateNote.mutateAsync({
          topicID: topicID || '',
          noteID: noteID || '',
          noteText: JSON.stringify({ text: content ? draftToHtml(convertToRaw(content)) : '' }),
          egoList,
        })

        // delete removed images
        await deleteEgo.mutateAsync(removedEgoIDs)
        resetForm()
        query.delete('status')
        navigate(`${pathname}?${query.toString()}`, { replace: true })
      } catch (err) {
        console.debug(err)
      } finally {
        setUploading(false)
      }
    } else {
      // new DM
      try {
        const egoItems: Ego[] = []

        // upload attached images
        for (let i = 0; i < pFiles.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: any = await uploadMedia(pFiles[i])
          if (!result.isError) {
            const photo = await uploadFile.mutateAsync(result)
            egoItems.push({
              sequenceID: `${i}`,
              egoID: photo.egoID,
              photoFullURL: photo.egoImageURL,
              photoAlbumURL: photo.egoAlbumURL,
            })
          }
        }
        const result = await addNoteToTopic.mutateAsync({
          topicID: topicID || '',
          egoItems,
          noteName: `DIRECT_MESSAGE_NOTE-${basicInfo.userID}`,
          noteText: JSON.stringify({ text: content ? draftToHtml(convertToRaw(content)) : '' }),
          noteType: '1',
        })
        resetForm()
        query.delete('status')
        query.set('noteID', result.noteID)
        navigate(`${pathname}?${query.toString()}`, { replace: true })
      } catch (err) {
        console.debug(err)
      } finally {
        setUploading(false)
      }
    }
  }

  const onAddFile = (fileList: FileContent[], pFileList: File[]) => {
    setCT(new Date().getTime())
    setFiles([...files, ...fileList])
    setPFiles([...pFiles, ...pFileList])
  }

  const onRemoveFile = (index: number) => {
    files.splice(index, 1)
    pFiles.splice(index, 1)
    setCT(new Date().getTime())
    setFiles([...files])
    setPFiles([...pFiles])
  }

  return (
    <Box p={2}>
      <RichEditor
        key={CT}
        isEditing={status === 'edit'}
        files={files}
        pFiles={pFiles}
        egos={egos}
        onAddFile={onAddFile}
        onRemoveFile={onRemoveFile}
        onRemoveEgo={onRemoveEgo}
        onSubmit={onAddDMNote}
        onCancel={onCancelEdit}
        loading={uploading}
        editorState={editorState}
        onEditorStateChange={onChangeEditor}
      />
    </Box>
  )
}
