import { useGetContainerDetails, useGetContainers } from '@/hooks/api/dashboard'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Container, EgoObject } from '@/types'
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { addUploadAssets } from '../renderer/main'
import { AssetItem, PatternAssetList } from '../__styled'
import { CBox, RBox } from '@/pages/__styled'

interface Props {
  onSelectAsset: (id: number | string) => void
  selectedAssetID: string
}

export default function UploadsList({ selectedAssetID, onSelectAsset }: Props) {
  const [page, setPage] = useState(1)
  const [selectedID, setSelectedID] = useState<string | null>(null)
  const [endReached, setEndReached] = useState(false)
  const [toggleStatus, setToggleStatus] = useState<Record<string, boolean>>({})
  const [containerData, setContainerData] = useState<Record<string, EgoObject[]>>({})
  const [loadingContainerID, setLoadingContainerID] = useState('')
  const [expandedContainer, setExpandedContainer] = useState<Container | null>(null)

  const getContainers = useGetContainers()
  const getContainerDetails = useGetContainerDetails()
  const { setMyContainers } = useAppStore()
  const [myContainers] = useAppStore((state) => [state.user.myContainers], shallow)

  useEffect(() => {
    getContainers.mutate(1)
  }, [])

  useEffect(() => {
    if (getContainerDetails.isSuccess) {
      setContainerData((prev) => ({
        ...prev,
        [loadingContainerID]: getContainerDetails.data.egoObjects || [],
      }))
      addUploadAssets(getContainerDetails.data.egoObjects)
    }
  }, [getContainerDetails.isSuccess, loadingContainerID])

  useEffect(() => {
    if (getContainers.isSuccess) {
      if (getContainers.data.length < 20) {
        setEndReached(true)
      }
    }
  }, [getContainers.isSuccess])

  const onToggleContainer = (container: Container) => {
    if (!containerData[container.containerID]) {
      setLoadingContainerID(container.containerID)
      getContainerDetails.mutate(container.containerID)
    }
    setExpandedContainer({
      ...container,
    })
  }

  const onClickShowMore = () => {
    if (endReached || getContainers.isLoading || !myContainers.length) {
      return
    }
    getContainers.mutate(page + 1)
    setPage(page + 1)
  }

  const HeaderView = (container: Container) => {
    let { containerID: cID, datePurchased } = container
    let isExpanded = false
    if (expandedContainer != null) {
      isExpanded = expandedContainer.containerID == container.containerID
    }

    return (
      <Box
        onClick={() => {
          if (!isExpanded) {
            onToggleContainer(container)
          }
        }}
        display='flex'
        alignItems='center'
        justifyContent={'space-between'}
        flex={1}
        sx={{
          cursor: 'pointer',
          paddingBottom: isExpanded ? null : '13px',
          borderBottom: isExpanded ? null : '1px solid #E7EDF3',
        }}
      >
        {isExpanded && (
          <KeyboardArrowLeftOutlined
            onClick={() => {
              setExpandedContainer(null)
            }}
            sx={{ color: colors.themePurpleTextColor, mr: '4px' }}
          />
        )}
        <RBox sx={{ flex: 1 }}>
          <CBox sx={{ flex: 1 }}>
            <Typography
              variant={'label'}
              flex={1}
              color={colors.themeBlack}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                textOverflow: 'ellipsis',
              }}
            >
              {dayjs(datePurchased).format('MMMM YYYY')}
            </Typography>
            {!isExpanded && (
              <Typography variant='pt' color={colors.themePurpleTextColor}>
                {container.photoCount} photo{Number(container.photoCount) > 1 ? 's' : ''}
              </Typography>
            )}
          </CBox>
          {!isExpanded && (
            <KeyboardArrowRightOutlined
              sx={{ color: colors.themePurpleTextColor, marginRight: '-8px' }}
            />
          )}
        </RBox>
      </Box>
    )
  }

  const ContainerContentView = (container: Container) => {
    const { containerID: cID } = container
    return (
      <PatternAssetList category={'print'} sx={{ backgroundColor: colors.white }}>
        {loadingContainerID === cID && getContainerDetails.isLoading && <CircularProgress />}
        {containerData[cID]?.map((ego: EgoObject) => (
          <AssetItem
            className={selectedAssetID === `egoID-${ego.egoID}` ? 'active' : ''}
            key={ego.egoID}
            onClick={() => onSelectAsset(`egoID-${ego.egoID}`)}
          >
            <img
              style={{ width: '61px', height: '61px', borderRadius: 8 }}
              src={ego.egoAlbumURL}
              alt={ego.egoName}
            />
          </AssetItem>
        ))}
      </PatternAssetList>
    )
  }

  return (
    <Box flex={1} sx={{ overflowY: 'auto' }} bgcolor={colors.white}>
      <>
        {(expandedContainer ? [expandedContainer] : myContainers)?.map((container) => {
          if (container.photoSnippet.length === 0) return null
          const cID = container.containerID
          return (
            <Box key={cID} m={2}>
              {HeaderView(container)}
              {expandedContainer != null && expandedContainer.containerID == cID && (
                <>{ContainerContentView(container)}</>
              )}
            </Box>
          )
        })}
      </>
    </Box>
  )
}
