/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { Note, ReactQueryKeys, Topic } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

interface Param {
  topicID: string
  pageNo?: number
}

export const useGetTopicDetails = () => {
  const [ewUser] = useLocalStorage('ewUser', null)
  const axios = useContext(AxiosContext)
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)
  return useMutation({
    mutationKey: [ReactQueryKeys.GET_TOPIC_DETAILS, ewUser?.userID],
    mutationFn: async ({ topicID, pageNo = 1 }: Param) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // if (!topicList[topicID]) return {} as any
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteList',
        topicID,
        userID: ewUser?.userID,
        format: 'json',
        pageNo,
      })

      let existingNotes: Note[] = []
      if (pageNo > 1) {
        existingNotes = topicList[topicID]?.notes || []
      }
      const topicData: Topic = response.data
      let reversedNotes = [...topicData.notes].reverse()
      topicData.notes = [...reversedNotes, ...existingNotes]

      const updated = {
        ...topicList[topicID],
        ...topicData,
      }
      updateTopicList({ [topicID]: updated })
      return topicData
    },
  })
}
