import { CrossButton } from '@/pages/__styled'
import { Box, Typography } from '@mui/material'
import React from 'react'
import SVG from 'react-inlinesvg'
import { useNavigate } from 'react-router-dom'

interface TileButtonProps {
  title: string
  icon: string
  path: string
}

const TileButton = ({ title, icon, path }: TileButtonProps) => {
  const navigate = useNavigate()
  return (
    <CrossButton
      onClick={() => {
        navigate(path)
      }}
    >
      <SVG src={icon} width={36} height={36} />
      <Box>
        <Typography fontWeight={400} fontSize={'12px'} lineHeight={'17px'} color={'#373333'}>
          {title}
        </Typography>
      </Box>
    </CrossButton>
  )
}

export default TileButton
