import { NoResults } from '@/components'
import {
  useGetBasicInfo,
  useDeleteComment,
  useGetDMTopicDetails,
  useGetTopicDetails,
  useGetTopicNoteLikes,
  useInteractToNote,
  useNoteLoad,
} from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { AlbumPhoto, DMConnection, Note, PatternDraft, Topic } from '@/types'
import { Box, Button, CircularProgress, Typography, styled } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { DetailsContent } from '../__styled'
import AlbumContent from './AlbumContent'
import AlbumInput from './AlbumInput'
import DMContent from './DMContent'
import RichInput from './RichInput'
import DMTopicDetailsHeader from './DMTopicHeader'
import TopicDetailsHeader from './TopicHeader'
import UserContent from './UserContent'
// import PatternContent from "./PatternContent";
import { getTopicStates, isBabylonType } from '@/utils/helper'
import SVG from 'react-inlinesvg'
import PatternContent from './PatternContent'
import { NoteType, TopicType } from '@/utils/constants'
import PatternNotesGroupList from './PatternNotesGroupList'
import PatternNotesInteractionView from './PatternNotesInteractionView'
import { toast } from 'react-hot-toast'

interface Props {
  isShowAction?: boolean
  onClickBack: () => void
  onShowInteractions: () => void
  onDeletedTopic?: () => void
}

const EditButton = styled(Button)(() => ({
  backgroundColor: 'black',
  borderRadius: 50,
  color: 'white',
  border: 0,
  fontSize: 12,
  fontWeight: 600,
  padding: '10px 20px',
  width: '65px',
  height: '34px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
  },
}))

const LogoutButton = styled(Button)(() => ({
  backgroundColor: 'black',
  borderRadius: 50,
  color: 'white',
  border: 0,
  fontSize: 12,
  fontWeight: 600,
  padding: '10px 20px',
  width: '65px',
  height: '34px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
  },
}))

export default function TopicDetails({
  isShowAction,
  onClickBack,
  onShowInteractions,
  onDeletedTopic,
}: Props) {
  const [interactionType, topicType, topicList, myDMConnections] = useAppStore(
    (state) => [
      state.home.interactionFilterType,
      state.home.topicFilterType,
      state.home.topicList,
      state.user.myDMConnections,
    ],
    shallow,
  )
  const [ewUser] = useLocalStorage('ewUser', null)
  const { setIsPrivacySelected, setInitialScreenShot } = useAppStore()
  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const dmTopicID = query.get('dmTopicID') || ''
  const connID = query.get('connID') || ''
  const topicID = query.get('topicID') || ''
  const noteID = query.get('noteID') || ''

  const getTopicDetails = useGetTopicDetails()
  const interactToNote = useInteractToNote()

  const getDMTopicDetails = useGetDMTopicDetails(dmTopicID)
  const getUserDetails = useGetBasicInfo(connID)
  const previewAvailable = !!getTopicDetails.data?.firstNote.egoList[0]

  const navigate = useNavigate()

  useEffect(() => {
    if (connID) {
      getUserDetails.mutate({ isUserProfile: false })
    } else if (dmTopicID) {
      getDMTopicDetails.mutate()
    }
  }, [dmTopicID, connID])

  const topic = useMemo(() => {
    if (dmTopicID) {
      return myDMConnections.find((i) => i.dmTopicID === dmTopicID)
    } else {
      return topicList[topicID]
    }
  }, [topicID, myDMConnections, topicList, dmTopicID])

  const firstNote = useMemo(() => {
    const currentTopic = topicList[topicID]
    if (!dmTopicID && currentTopic) {
      if (currentTopic.firstNote && currentTopic?.notes?.length > 0) {
        const note = (topic as Topic).notes.find(
          (e) => e.noteID == (topic as Topic).firstNote.noteID,
        )
        return note
      }
    }
    return null
  }, [topicID, topicList, dmTopicID])

  const patternNoteGroups = useMemo(() => {
    let result = null
    if (!dmTopicID && topic && topic?.notes?.length > 0) {
      const draft: PatternDraft = { templateID: 1 }
      topic.notes.forEach((note: Note) => {
        if (note.noteType === '8') {
          // setup initial text overlay
        } else if (note.noteType === '9') {
          // preview
        } else if (note.noteType !== '1' && note.noteText !== 'null' && note.noteText != null) {
          draft[note.noteName as keyof PatternDraft] = JSON.parse(note.noteText)
        }
      })

      result = {}
      let templateNotes = topic.notes.filter((e) => e.noteType == NoteType.TEMPLATE.toString())
      let materialNotes = topic.notes.filter((e) => e.noteType == NoteType.MATERIAL.toString())
      let printNotes = topic.notes.filter((e) => e.noteType == NoteType.PRINT.toString())
      let colorNotes = topic.notes.filter((e) => e.noteType == NoteType.COLOR.toString())
      result = {
        [NoteType.TEMPLATE]: templateNotes || [],
        [NoteType.MATERIAL]: materialNotes || [],
        [NoteType.PRINT]: printNotes || [],
        [NoteType.COLOR]: colorNotes || [],
        patternDetails: draft,
      }
    }
    return result
  }, [topic])

  useEffect(() => {
    if (topicID) {
      getTopicDetails.mutate({ topicID })
      setInitialScreenShot(null)
    }
  }, [topicID])

  const photos = useMemo(() => {
    const res: Record<string, AlbumPhoto> = {}
    topic?.photos?.forEach((p) => (res[p.egoID] = p))
    return res
  }, [topic])

  const onClickPrivacy = () => {
    setIsPrivacySelected(true)
    onShowInteractions()
  }

  const onSubmitDMActions = () => {
    getDMTopicDetails.mutate()
  }

  const onSubmitDiscussionActions = () => {
    getTopicDetails.mutate({ topicID })
  }

  const onClickComment = () => {
    query.set('noteID', `${(topic as Topic).firstNote.noteID}`)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }
  const onClickLikesCount = () => {
    query.set('noteID', `${(topic as Topic).firstNote.noteID}`)
    query.set('showLikes', '1')

    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickPreview = () => {
    navigate(`/pattern?topicID=${topicID}`)
  }

  const onClickLike = () => {
    if (firstNote) {
      interactToNote.mutateAsync({
        interactionType: '3',
        interaction: firstNote.isThumbsUp == 1 ? '0' : '1',
        comment: 'test',
        noteID: firstNote.noteID,
        topicID,
      })
    }
  }

  const handleEdit = () => {
    // navigate(`/topic/edit/${topicID}`)
    navigate(`/pattern?topicID=${topicID}&mode=edit`)
  }

  if (connID) {
    return (
      <DetailsContent>
        {getUserDetails.isLoading ? (
          <Box display={'flex'} justifyContent={'center'} pt={4}>
            <CircularProgress />
          </Box>
        ) : getUserDetails.isSuccess ? (
          <UserContent
            user={getUserDetails.data}
            onClickBack={onClickBack}
            onClickComment={onShowInteractions}
          />
        ) : getUserDetails.isError ? (
          <Typography>Failed to fetch user details.</Typography>
        ) : null}
      </DetailsContent>
    )
  }

  if (dmTopicID && topic) {
    return (
      <DetailsContent>
        {getDMTopicDetails.isLoading && (
          <Box display={'flex'} justifyContent={'center'} pt={4}>
            <CircularProgress />
          </Box>
        )}
        <DMTopicDetailsHeader
          topic={topic as DMConnection}
          onClickBack={onClickBack}
          onClickPrivacy={onClickPrivacy}
          onDeletedTopic={onDeletedTopic}
        />
        <DMContent topicID={dmTopicID} photos={photos} onClickInteraction={onShowInteractions} />
        <RichInput topicID={dmTopicID} notes={topic?.notes || []} onSubmit={onSubmitDMActions} />
      </DetailsContent>
    )
  }

  if (topicID === '') {
    return (
      <DetailsContent justifyContent={'center'} alignItems='center'>
        <NoResults
          iconSize={155}
          interaction={interactionType}
          topicType={topicType}
          btnColor={colors.blue}
        />
      </DetailsContent>
    )
  }

  if (!topic) return <DetailsContent />

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
        <TopicDetailsHeader
          isShowAction={isShowAction}
          topic={topic as Topic}
          onClickBack={onClickBack}
          onClickComment={onShowInteractions}
          onClickPrivacy={onClickPrivacy}
          onDeletedTopic={onDeletedTopic}
        />
        {getTopicDetails.isLoading && (
          <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        )}

        <Box sx={{ px: '20px', mb: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontWeight={700} fontSize={20} sx={{ color: '#211E1E' }}>
            {topic.topicName}
          </Typography>
          {/* {isShowAction && (
            <EditButton variant='outlined' onClick={handleEdit}>
              Edit
            </EditButton>
          )} */}
        </Box>

        {topic.topicType == TopicType.PATTERN.toString() && (
          <Box>
            {previewAvailable && (
              <Box
                mx={'20px'}
                height={'350px'}
                width={`calc(100% - 40px)`}
                borderRadius={'8px'}
                position='relative'
                mb={'17px'}
                sx={{
                  backgroundImage: `url(${getTopicDetails.data?.firstNote.egoList[0].photoAlbumURL})`,
                  backgroundSize: 'cover',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '150px',
                    backgroundImage:
                      'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
                    borderRadius: '8px 8px 0 0',
                  },
                }}
              ></Box>
            )}
            <Box px={'20px'}>
              <Typography fontWeight={500} fontSize={13} mb={'14px'} sx={{ color: '#211E1E' }}>
                Design Description
              </Typography>
            </Box>
            <Box sx={{ mx: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <PatternNotesGroupList
                  title={'Template'}
                  patternNoteType={NoteType.TEMPLATE}
                  data={patternNoteGroups?.[NoteType.TEMPLATE] || []}
                  patternDetails={patternNoteGroups?.patternDetails}
                />
                <PatternNotesGroupList
                  title={'Material'}
                  patternNoteType={NoteType.MATERIAL}
                  data={patternNoteGroups?.[NoteType.MATERIAL] || []}
                  patternDetails={patternNoteGroups?.patternDetails}
                />
              </Box>
              <PatternNotesGroupList
                title={'Print'}
                patternNoteType={NoteType.PRINT}
                data={patternNoteGroups?.[NoteType.PRINT] || []}
                patternDetails={patternNoteGroups?.patternDetails}
              />
              <PatternNotesGroupList
                title={'Colors Used'}
                patternNoteType={NoteType.COLOR}
                data={patternNoteGroups?.[NoteType.COLOR] || []}
                patternDetails={patternNoteGroups?.patternDetails}
              />
            </Box>
          </Box>
        )}

        {topic.topicType == TopicType.ALBUM.toString() && (
          <AlbumContent notes={topic?.notes?.slice(0, -1) || []} photos={photos} />
        )}

        {topic.topicType === '4' && (
          <DMContent
            topicID={topicID}
            topicType='discussion'
            photos={photos}
            onClickInteraction={onShowInteractions}
          />
        )}

        {topic.topicType === '1' && (
          <DMContent
            topicID={topicID}
            topicType='list'
            photos={photos}
            onClickInteraction={onShowInteractions}
          />
        )}

        {isBabylonType(topic.topicType) && !isShowAction && (
          <PatternContent topic={topic as Topic} />
        )}
        {isBabylonType(topic.topicType) && isShowAction && (
          <>
            {(topic as Topic).latestNote && (
              <Box display='flex' overflow='auto' p={2}>
                {(topic as Topic).latestNote?.egoList.map((ego) => (
                  <img
                    key={ego.egoID}
                    src={ego.photoAlbumURL}
                    style={{ width: `${100 / (topic as Topic).latestNote?.egoList.length}%` }}
                  />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
      <Box>
        {topic.topicType == TopicType.PATTERN.toString() && (
          <PatternNotesInteractionView
            topic={topic as Topic}
            isLiked={firstNote?.isThumbsUp == 1}
            onClickLike={onClickLike}
            onClickComment={onClickComment}
            onClickPreview={onClickPreview}
            onClickLikesCount={onClickLikesCount}
          />
        )}
        {topic.topicType === '2' && topic.userID === ewUser.userID && <AlbumInput />}
        {topic.topicType === '4' && (
          <RichInput
            topicID={topicID}
            notes={topic?.notes || []}
            onSubmit={onSubmitDiscussionActions}
          />
        )}
        {topic.topicType === '1' && (
          <RichInput
            topicID={topicID}
            notes={topic?.notes || []}
            onSubmit={onSubmitDiscussionActions}
          />
        )}
      </Box>
    </Box>
  )
}
