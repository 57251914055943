import SVG from 'react-inlinesvg';
import { SVGS } from "@/assets/svgs";
import { AlbumPhoto, Ego, Note } from "@/types";
import { ChevronLeft, ChevronRight, FullscreenExitOutlined, FullscreenOutlined, HighlightOff } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import Slider from "react-slick";
import { AlbumGalleryPhoto, AlbumSliderWrapper } from "../__styled";

interface Props {
  photos: Record<string, AlbumPhoto>;
  note: Note;
  onClose: () => void;
  initialIndex: number;
}

export default function ImageSlider({ photos, note, onClose, initialIndex }: Props) {
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(initialIndex);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>(null)

  const onClickPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }

  const onClickNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }

  const onClickFullScreenMode = () => {
    setFullScreenMode(!fullScreenMode);
  }

  const onClickClose = () => {
    setFullScreenMode(false)
    onClose()
  }

  const onSlideIndexChanged = (index: number) => {
    setSliderIndex(index);
  }

  return (
    <AlbumSliderWrapper mode={fullScreenMode ? 'full' : 'normal'}>
      <Slider ref={sliderRef} swipe={false} afterChange={onSlideIndexChanged} initialSlide={initialIndex}>
        {(note.egoList || []).map((photo: Ego) => (
          <AlbumGalleryPhoto
            key={photo.egoID}
            photo={photos[photo.egoID]?.photoFullURL}
            mode={fullScreenMode ? 'full' : 'normal'}
          />
        ))}
      </Slider>
      <Box position={'absolute'} left={0} top={0} right={0} bottom={0} display="flex" flexDirection={'column'}>
        <Box display="flex" justifyContent={'space-between'} alignItems="center" className="top-section">
          <IconButton onClick={onClickFullScreenMode}>
            {fullScreenMode ? <FullscreenExitOutlined sx={{ color: 'white' }} /> : <FullscreenOutlined sx={{ color: 'white' }} />}
          </IconButton>
          <Typography variant="p" color="white">{sliderIndex + 1} / {note?.egoList.length}</Typography>
          <IconButton onClick={onClickClose}>
            <HighlightOff sx={{ color: 'white' }} />
          </IconButton>
        </Box>
        <Box flex={1} display="flex" justifyContent={'space-between'} alignItems="center" px={1}>
          <IconButton className="navButton" onClick={onClickPrev}>
            <ChevronLeft sx={{ color: 'black' }} />
          </IconButton>
          <IconButton className="navButton" onClick={onClickNext}>
            <ChevronRight sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent={'flex-end'} alignItems="center" p={1} className="bottom-section">
          <SVG src={SVGS.ActionLike} color="white" />
          <Typography variant="p" color="white" mx={0.5} pr={1}>{note.thumbsUp}</Typography>
          <SVG src={SVGS.ActionComment} color="white" />
          <Typography variant="p" color="white" mx={0.5}>{note.comments}</Typography>
        </Box>
      </Box>
    </AlbumSliderWrapper>
  )
}