import React from 'react'
import SVG from 'react-inlinesvg'
import { CBox, RBox } from '../__styled'
import { Box, Typography } from '@mui/material'

interface InfoSlideViewProps {
  icon: string
  title: string
  description: string
}

const InfoSlideView = ({ icon, title, description }: InfoSlideViewProps) => {
  return (
    <CBox gap={2} p={'36px 43px 22px 27px'}>
      <RBox gap={'12px'}>
        <Box>
          <SVG src={icon} width={'34px'} height={'34px'} />
        </Box>
        <Typography fontSize={'18px'} fontWeight={700} color={'white'}>
          {title}
        </Typography>
      </RBox>
      <Typography fontSize={'15px'} fontWeight={400} lineHeight={'26px'} color={'white'}>
        {description}
      </Typography>
    </CBox>
  )
}

export default InfoSlideView
