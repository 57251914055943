import colors from "@/styles/colors";
import { CreateTopicTypes } from "@/utils/constants";
import { Box, Typography } from "@mui/material";
import SVG from 'react-inlinesvg';

interface Props {
  topicType: number;
  onChangeType: (type: number) => void;
}

export default function SelectTopicType({ topicType, onChangeType }: Props) {
  return (
    <Box p={1} height="100%">
      <Typography variant="h1" p={2}>What would you create?</Typography>
      {CreateTopicTypes.map(topic => {
        const selected = topicType === topic.type
        return (
          <Box
            key={topic.type}
            display={'flex'}
            alignItems="center"
            borderRadius={2}
            bgcolor={selected ? colors.yellow : 'transparent'}
            onClick={() => onChangeType(topic.type)}
            sx={{ cursor: 'pointer' }}
            mb={1}
            p={2}
          >
            <SVG src={topic.icon} width={20} height={20} color={selected ? 'white' : colors.text} />
            <Box pl={1}>
              <Typography variant="h2" color={selected ? 'white' : colors.headingText}>{topic.label}</Typography>
              <Typography variant="ps" color={selected ? 'white' : colors.text}>{topic.description}</Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}