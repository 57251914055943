import { SVGS } from '@/assets/svgs'
import { ActionMenu, UserAvatar } from '@/components'
import { RoundedFilledButton, RoundedOutlineButton } from '@/components/StyledComponents'
import { useConnectionBlock, useConnectionDelete } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { CBox, RBox } from '@/pages/__styled'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { MyConnection, SearchConnection } from '@/types'
import {
  Block,
  Check,
  Close,
  MoreVert,
  RemoveCircle,
  RoundedCornerOutlined,
  SmartButtonRounded,
} from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-hot-toast'
import SVG from 'react-inlinesvg'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

interface Props {
  user: SearchConnection | MyConnection
  onClickViewProfile: (user: SearchConnection) => void
  onClickFollow: (user: SearchConnection) => void
  onClickAccept: (user: SearchConnection) => void
}

export default function ConnectionGridItem({
  user,
  onClickViewProfile,
  onClickFollow,
  onClickAccept,
}: Props) {
  const [ewUser] = useLocalStorage('ewUser', null)
  const navigate = useNavigate()
  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const connID = query.get('connID')
  const blockUser = useConnectionBlock()
  const removeUser = useConnectionDelete()
  const [searchConnections] = useAppStore((state) => [state.user.searchConnections], shallow)
  const { setSearchConnections } = useAppStore()

  useEffect(() => {
    if (removeUser.isSuccess || blockUser.isSuccess) {
      if (searchConnections[user.userID]) {
        setSearchConnections({
          ...searchConnections,
          [user.userID]: {
            ...searchConnections[user.userID],
            isConnection: false,
          },
        })
      }
    }
  }, [removeUser.isSuccess, blockUser.isSuccess])

  const onClickRemoveConnect = () => {
    toast.promise(removeUser.mutateAsync(user.userID), {
      loading: 'Removing user...',
      success: <Typography variant='p'>{`User has been removed.`}</Typography>,
      error: <Typography variant='p'>Failed to remove a user.</Typography>,
    })
  }
  const onClickBlockConnect = () => {
    toast.promise(blockUser.mutateAsync(user.userID), {
      loading: 'Blocking user...',
      success: <Typography variant='p'>{`User has been blocked.`}</Typography>,
      error: <Typography variant='p'>Failed to block a user.</Typography>,
    })
  }
  const onClickSharedGroups = () => {}

  const onClickConnect = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    // query.set('userID', user.userID)
    // query.set('connID', user.userID)
    // navigate(`${pathname}?${query.toString()}`, { replace: true })
    // onClickFollow(user)
  }

  const onClickAcceptRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    // onClickAccept(user)
  }

  const onClickDeclineRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    // Decline request
  }

  const topicActions = useMemo(() => {
    return [
      {
        icon: <RemoveCircle />,
        name: 'Disconnect',
        onClick: onClickRemoveConnect,
      },
      {
        icon: <Block />,
        name: 'Block',
        onClick: onClickBlockConnect,
      },
      {
        icon: <SVG src={SVGS.FilterPrivacyConnection} />,
        name: 'Shared Groups',
        onClick: onClickSharedGroups,
      },
    ]
  }, [])

  const openUserProfile = () => {
    let previewQuery = new URLSearchParams()
    previewQuery.set('id', user.userID)
    navigate(`/profile/user?${previewQuery.toString()}`)
  }

  const getProfileImageURL = () =>
    (user as SearchConnection)?.profilePicURL || (user as MyConnection)?.thumbImgURL

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent={'center'}
      flexDirection={'column'}
      p={2}
      onClick={openUserProfile}
      sx={{
        cursor: 'pointer',
        width: '268px',
        height: '230px',
        border: `1px solid ${colors.cardBorderColor}`,
        '&:hover': {
          boxShadow: '0 5px 15px 0px #0000000F',
          border: `1px solid #CCD4DC`,
        },
        borderRadius: '8px',
        py: '31px',
        gap: '17px',
      }}
      boxShadow={connID === user.userID ? '0 1px 2px rgba(0,0,0,.1)' : 'none'}
      pl={connID === user.userID ? '11px' : 2}
    >
      <CBox sx={{ alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
        <UserAvatar
          name={`${user?.firstName} ${user?.lastName}`}
          url={getProfileImageURL()}
          size={70}
          borderRadius={100}
        />
        <CBox sx={{ alignItems: 'center', justifyContent: 'center', gap: '3px' }}>
          <Typography variant='h3' className='username'>
            {user.firstName} {user.lastName} {user.userID === ewUser.userID ? '(You)' : ''}
          </Typography>
          {user.profession?.length > 0 && (
            <Typography color={colors.themeBlack}>{user.profession}</Typography>
          )}
        </CBox>
      </CBox>

      <RBox sx={{ alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        <RoundedOutlineButton sx={{ padding: '6px 22.5px' }}>{'Profile'}</RoundedOutlineButton>

        {user.isConnection && (
          <ActionMenu id='topic-item-menu' parent={<MoreVert />} actions={topicActions} />
        )}
        {!user.isConnection && user.reqReceived && (
          <>
            <IconButton onClick={onClickAcceptRequest} className='accept-icon-btn'>
              <Check width={24} height={24} />
            </IconButton>
            <IconButton
              onClick={onClickDeclineRequest}
              className='reject-icon-btn'
              sx={{ marginLeft: 1 }}
            >
              <Close width={24} height={24} />
            </IconButton>
          </>
        )}
        {!user.isConnection && user.reqSent && (
          <Button variant='text' disabled>
            Pending
          </Button>
        )}
        {!user.isConnection && !user.reqReceived && !user.reqSent && (
          <RoundedFilledButton sx={{ padding: '6px 22.5px' }} onClick={onClickConnect}>
            {'Follow'}
          </RoundedFilledButton>
        )}
      </RBox>
    </Box>
  )
}
