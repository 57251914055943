import { RBox } from '@/pages/__styled'
import colors from '@/styles/colors'
import { DMConnection, Topic } from '@/types'
import { getDMTopicStates, getTopicStates } from '@/utils/helper'
import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

interface Props {
  topic: Topic | DMConnection
  type?: 'album' | 'dm'
}

export function TopicStates({ topic, type = 'album' }: Props) {
  if (topic as Topic) {
    return (
      <RBox gap={'15px'} mr={2}>
        {getTopicStates(topic as Topic).map((item) => (
          <Box display='flex' alignItems='center' key={item.key} gap={'2px'}>
            <SVG src={item.icon} width={14} height={14} color={colors.themePurpleTextColor} />
            <Typography
              variant='labelSmall'
              sx={{ marginLeft: 0.5, color: colors.themePurpleTextColor }}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </RBox>
    )
  } else {
    return (
      <>
        {getDMTopicStates((topic as DMConnection).topicStats).map((item) => (
          <Box display='flex' alignItems='center' key={item.key} mr={2}>
            <SVG src={item.icon} width={14} height={14} color={colors.darkGrey} />
            <Typography variant='labelSmall' sx={{ marginLeft: 0.5, color: colors.darkGrey }}>
              {item.value}
            </Typography>
          </Box>
        ))}
      </>
    )
  }
}
