import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import Visibility from '@mui/icons-material/VisibilityOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined'
import { AuthFormBox } from './__styled'
import { Input } from '@/components'
import { validatePassword } from '@/utils/helper'
import { SVGS } from '@/assets/svgs'
import SVG from 'react-inlinesvg'

interface FormProps {
  error: string
  loading: boolean
  user: string
  onSubmit: (code: string, newPassword: string) => void
}

export default function ForgotForm2({ error, loading, user, onSubmit }: FormProps) {
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [editing, setEditing] = useState(false)

  const formError = useMemo(() => {
    const error: Record<string, string> = {}
    if (!code.length) {
      error.code = 'Verification code is required'
    } else if (code.length !== 6) {
      error.code = 'Password should be 6 chars exactly.'
    }
    if (!password.length) {
      error.password = 'Password is required'
    } else if (password.length < 8) {
      error.password = 'Password is too short - should be 8 chars minimum.'
    } else if (!validatePassword(password)) {
      error.password =
        'Password can only contain Latin Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.'
    }
    if (password !== confirmPassword) {
      error.confirm = 'Passwords must match'
    }
    return error
  }, [code, password, confirmPassword])

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
    setEditing(true)
  }

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setEditing(true)
  }

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
    setEditing(true)
  }

  const isFormValid = !formError.code && !formError.password && !formError.confirm

  const onConfirm = () => {
    if (isFormValid) onSubmit(code, password)
  }

  return (
    <AuthFormBox>
      <Typography variant='large' fontSize={38}>
        Confirmation Code
      </Typography>
      <Typography variant='p' fontSize={15} mt={1}>
        Please enter your Confirmation Code sent to {user}
      </Typography>
      <Input
        value={code}
        placeholder='Confirmation Code*'
        onChange={onChangeCode}
        errorText={formError.code}
        isError={editing && !!formError.code}
        name='email'
      />
      <Input
        value={password}
        placeholder='Create Password*'
        onChange={onChangePassword}
        rightIcon={<SVG src={showPassword ? SVGS.VisibilityOffIcon : SVGS.VisibilityIcon} />}
        onToggleIcon={() => setShowPassword(!showPassword)}
        type={showPassword ? 'text' : 'password'}
        errorText={formError.password}
        isError={editing && !!formError.password}
        name='password'
      />
      <Input
        value={confirmPassword}
        placeholder='Confirm Password*'
        onChange={onChangeConfirmPassword}
        rightIcon={showConfirmPassword ? <VisibilityOff /> : <Visibility />}
        onToggleIcon={() => setShowConfirmPassword(!showConfirmPassword)}
        type={showConfirmPassword ? 'text' : 'password'}
        errorText={formError.confirm}
        isError={editing && !!formError.confirm}
        name='confirm password'
      />
      {!loading && !!error && (
        <Box mt={1}>
          <Typography variant='labelLarge' color={'error.main'}>
            {error}
          </Typography>
        </Box>
      )}
      <LoadingButton
        variant='contained'
        size='large'
        fullWidth
        className='loginButton'
        disabled={!!formError.user}
        onClick={onConfirm}
        loading={loading}
      >
        Confirm
      </LoadingButton>
      <Box mt={2} display='flex' justifyContent='center' alignItems={'center'} gap={'3px'}>
        <Typography
          variant='labelLarge'
          fontSize={14}
          color={'customText.main'}
          fontFamily={'Inter'}
        >
          Got an account?
        </Typography>
        <Link to='/login'>
          <Typography variant='labelLarge' fontSize={14} color={'brand.main'}>
            Sign In
          </Typography>
        </Link>
      </Box>
    </AuthFormBox>
  )
}
