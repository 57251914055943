/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { SearchConnection, ReactQueryKeys } from '@/types'
import { useContext, useRef } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

export const useSearchUsers = (query: string) => {
  const axios = useContext(AxiosContext)

  const [searchConnections] = useAppStore((state) => [state.user.searchConnections], shallow)
  const { setSearchConnections, setUserSearchKeyword } = useAppStore()
  const [userSearchKeyword] = useAppStore((state) => [state.user.userSearchKeyword], shallow)

  return useMutation({
    mutationKey: [ReactQueryKeys.SEARCH_USERS, query],
    mutationFn: async (page: number): Promise<SearchConnection[]> => {
      const skipSearch =
        query == userSearchKeyword && Object.keys(searchConnections).length > 0 && page == 1

      if (skipSearch) {
        return Object.values(searchConnections)
      }
      if (page === 1) {
        setSearchConnections({})
      }
      setUserSearchKeyword(query)

      const response = await axios.api.post('search/search', {
        requestType: 'userSearch',
        query: query,
        queryType: 1,
        pageNo: page,
        format: 'json',
      })
      const data: Record<string, SearchConnection> = {}
      const users =
        response.data?.results?.filter((i: SearchConnection) => !i.userName.includes('@DEL@')) || []
      users.forEach((user: SearchConnection) => {
        data[user.userID] = user
      })
      if (page === 1) setSearchConnections(data)
      else setSearchConnections({ ...searchConnections, ...data })
      return users
    },
  })
}
