import { Box, Typography } from '@mui/material'
import React from 'react'
import loadingGif from '@/assets/images/loading_circular.svg'

const FullScreenLoadingView = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#000000BF',
      zIndex: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <img src={loadingGif} width={'100px'} height={'100px'} />
      <Typography variant='h1' color={'white'}>
        {' Saving Data...'}
      </Typography>
    </Box>
  </Box>
)

export default FullScreenLoadingView
