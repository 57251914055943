import { MyConnection, Topic } from '@/types'
import { createContext, Context } from 'react'

interface ListingGenericContext<T> {
  items: T[] | null
  loadingNextPage: boolean
  canLoadNextPage: boolean
  loading: boolean
  loadNextPageData: () => void
}

interface ConnectionsListContextProps extends ListingGenericContext<MyConnection> {}

export const ConnectionsListContext: Context<ConnectionsListContextProps> = createContext(
  {} as ConnectionsListContextProps,
)
