import { useContext, useRef, useState } from 'react'
import { AxiosContext } from '@/context/Axios'
import { MyConnection } from '@/types'

interface getConnectionsListParams {
  connID: string
  pageNo?: number
}

export const useConnectionsListViewModel = () => {
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState()
  const [connections, setConnections] = useState<MyConnection[] | null>(null)
  const [canLoadNextPage, setCanLoadNextPage] = useState(false)
  const axios = useContext(AxiosContext)
  let paramsRef = useRef<getConnectionsListParams | null>(null)

  const getConnectionsList = async (params: getConnectionsListParams) => {
    const { connID, pageNo = 1 } = params
    paramsRef.current = params
    if (pageNo == 1) {
      setLoading(true)
      setConnections(null)
    } else {
      setLoadingNextPage(true)
    }

    const response = await axios.api.post('ajax/circles', {
      requestType: 'connList',
      connID: connID,
      format: 'json',
      pageNo: pageNo,
    })
    setLoading(false)
    setLoadingNextPage(false)

    const users = response.data?.connections || []
    if (pageNo == 1) {
      setConnections(users)
    } else {
      setConnections((prev) => [...(prev || []), ...users])
    }
    setPagination(response.data.pagination)
    if (response.data.pagination?.currentPage == response.data.pagination?.totalPage) {
      setCanLoadNextPage(false)
    } else {
      setCanLoadNextPage(true)
    }
  }

  const loadNextPageData = () => {
    if (paramsRef.current != null && canLoadNextPage) {
      getConnectionsList({ ...paramsRef.current, pageNo: (paramsRef.current.pageNo || 1) + 1 })
    }
  }

  return {
    loading,
    loadingNextPage,
    canLoadNextPage,
    connections,
    getConnectionsList,
    loadNextPageData,
    pagination,
  }
}
