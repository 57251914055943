import { SVGS } from '@/assets/svgs'
import {
  Input,
  InteractionComment,
  NoInteractions,
  UserInteraction,
  UserInteractionProps,
} from '@/components'
import {
  useDeleteComment,
  useGetDMTopicDetails,
  useGetTopicDetails,
  useGetTopicNoteLikes,
  useInteractToNote,
  userAddNoteComment,
  useNoteLoad,
} from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Comment, Ego, NoteDetail } from '@/types'
import { ChevronLeft, ChevronRight, Close, Favorite, PhotoAlbum, Send } from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import SVG from 'react-inlinesvg'
import Slider from 'react-slick'
import { shallow } from 'zustand/shallow'
import { AlbumGalleryPhoto, AlbumSliderWrapper, InteractionsContent } from '../__styled'
import { TransitionProps } from '@mui/material/transitions'
import { TopicDetailsContext } from '@/context/TopicDetails'
import { getFullImageURL, getNowInUTC, scrollToBottom } from '@/utils/helper'
import { BackgroundImage } from '@/pages/__styled'

interface Props {
  trackKey: string
}

export default function NoteInteractions({ trackKey }: Props) {
  const [noteData, setNoteData] = useState<NoteDetail | null>(null)
  const [isViewMode, setIsViewMode] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [viewTab, setViewTab] = useState(1)

  const [ewUser] = useLocalStorage('ewUser', null)
  const [basicInfo] = useLocalStorage('ewBasicInfo', null)
  const query = useBrowserQuery()
  const topicID = query.get(trackKey) || ''
  const noteID = query.get('noteID') || ''

  const noteLoad = useNoteLoad(noteID)
  const { topic } = useContext(TopicDetailsContext)
  const getNoteLikes = useGetTopicNoteLikes()
  const addNoteComment = userAddNoteComment()
  const deleteComment = useDeleteComment(topicID, noteID)
  const interactToNote = useInteractToNote()
  const [initialScreenShot] = useAppStore((state) => [state.pattern.initialScreenShot], shallow)
  const [topicSelectedAt] = useAppStore((state) => [state.home.topicSelectedAt], shallow)
  const [comments, setComments] = useState<Comment[]>([])
  const [showImageViewer, setShowImageViewer] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>(null)
  const listRef = useRef<any>(null)

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction='down' ref={ref} {...props} />
  })

  useEffect(() => {
    if (noteID) noteLoad.mutate()
  }, [topicSelectedAt])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0)
    }
  }, [sliderRef.current, noteID])

  useEffect(() => {
    if (noteID) {
      setIsViewMode(false)
      setNewComment('')
      setNoteData(null)
      noteLoad.mutate()
      getNoteLikes.mutate(noteID)
    }
  }, [noteID])

  useEffect(() => {
    if (noteLoad.isSuccess) {
      setNoteData(noteLoad.data)
      setComments(noteLoad.data.comments)
      scrollToBottom(listRef, 500)
    }
  }, [noteLoad.isSuccess])

  const selectedNote = useMemo(() => {
    if (topic && noteID.length > 0) {
      const note = topic?.notes?.find((e) => e.noteID == noteID)
      if (note && noteData) {
        setNoteData({
          ...noteData,
          egoList: note.egoList,
        })
      }
      return note
    }
  }, [topic, noteID])

  useEffect(() => {
    if (viewTab == 2 && noteID.length > 0) {
      getNoteLikes.mutate(noteID)
    }
  }, [viewTab, noteID])

  // Like status updated / Commented added
  useEffect(() => {
    if (addNoteComment.isSuccess && !addNoteComment.data?.isError) {
      setNewComment('')
      const { comment: commentText, commentID } = addNoteComment.data
      const comment: Comment = {
        comment: commentText,
        dateAdded: getNowInUTC(),
        feedbackID: commentID,
        noteID,
        topicID,
        senderName: `${basicInfo.firstName} ${basicInfo.lastName}`,
        senderID: ewUser.userID,
        userID: ewUser.userID,
        profilePicURL: basicInfo.profilePicThumbURL,
      }
      const updated = [...comments, comment]
      setComments(updated)
      scrollToBottom(listRef, 10)
    }
  }, [addNoteComment.isSuccess])

  const interactions = useMemo(() => {
    return (selectedNote?.comments || 0) + Number(selectedNote?.thumbsUp || 0)
  }, [noteData])

  const viewData = useMemo(() => {
    if (!noteData) return []
    if (viewTab === 1) {
      return (
        comments?.map((i) => ({
          date: i.dateAdded,
          avatar: i.profilePicURL,
          name: i.senderName,
        })) || []
      )
    } else {
      return (
        getNoteLikes?.data?.map((i) => ({
          date: i.dateCreated,
          avatar: i.profilePicURL,
          name: i.userName,
        })) || []
      )
    }
  }, [viewTab, noteData, getNoteLikes.isSuccess])

  if (!noteData && noteLoad.isLoading) {
    return (
      <InteractionsContent justifyContent={'center'} alignItems='center'>
        <CircularProgress />
      </InteractionsContent>
    )
  }

  if (noteLoad.isError) {
    return <Typography>Failed to load</Typography>
  }

  if (!noteData || !noteID) return null

  const onClickPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const onClickNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const onClickDelete = (comment: Comment) => {
    let filtered = comments.filter((e) => e.feedbackID != comment.feedbackID)
    setComments(filtered)

    toast.promise(deleteComment.mutateAsync(comment.feedbackID), {
      loading: 'Deleting a comment...',
      success: <Typography variant='p'>Successfully deleted</Typography>,
      error: <Typography variant='p'>Failed to delete</Typography>,
    })
  }

  const onChangeNewComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value)
  }

  const onClickSendNewComment = () => {
    if (newComment.trim()) {
      addNoteComment.mutate({ topicID, noteID, comment: newComment })
    }
  }

  const onToggleViewMode = () => {
    setIsViewMode(!isViewMode)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onClickSendNewComment()
    }
  }

  const onToggleLike = () => {
    toast.promise(
      interactToNote.mutateAsync({
        interactionType: '3',
        interaction: selectedNote?.isThumbsUp == 1 ? '0' : '1',
        comment: 'test',
        noteID,
        topicID,
      }),
      {
        loading: `${selectedNote?.isThumbsUp == 1 ? 'Disliking' : 'Liking'} a message...`,
        success: <Typography variant='p'>Success!</Typography>,
        error: (
          <Typography variant='p'>
            Failed to {selectedNote?.isThumbsUp == 1 ? 'dislike' : 'like'}
          </Typography>
        ),
      },
    )
  }

  const renderAlbumSlider = (mode: string = 'normal', allowSwipe: boolean = false) => {
    return (
      <AlbumSliderWrapper
        mode={'normal'}
        onClick={() => {
          setShowImageViewer(true)
        }}
      >
        <Slider ref={sliderRef} swipe={allowSwipe} arrows={false} swipeToSlide={allowSwipe}>
          {(noteData.egoList || []).map((photo: Ego) => (
            <AlbumGalleryPhoto
              key={photo.egoID}
              photo={mode == 'normal' ? photo.photoAlbumURL : getFullImageURL(photo.photoAlbumURL)}
              mode={mode}
            />
          ))}
        </Slider>
        {noteData.egoList.length > 1 && (
          <Box
            position={'absolute'}
            left={0}
            top={0}
            right={0}
            bottom={0}
            display='flex'
            flexDirection={'column'}
          >
            <Box
              flex={1}
              display='flex'
              justifyContent={'space-between'}
              alignItems='center'
              px={1}
            >
              <IconButton className='navButton' onClick={onClickPrev}>
                <ChevronLeft sx={{ color: 'black' }} />
              </IconButton>
              <IconButton className='navButton' onClick={onClickNext}>
                <ChevronRight sx={{ color: 'black' }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </AlbumSliderWrapper>
    )
  }

  const renderPreview = () => {
    if (noteData.egoList.length === 1) {
      return (
        <BackgroundImage
          sx={{ borderRadius: 2, height: 350 }}
          url={noteData.egoList[0].photoAlbumURL}
          onClick={() => {
            setShowImageViewer(true)
          }}
        />
      )
    }
    if (noteData.egoList.length === 0 && initialScreenShot) {
      return <img src={initialScreenShot} />
    }
    if (noteData.egoList.length > 1) {
      return (
        <Box position={'relative'} height={250}>
          {renderAlbumSlider()}
        </Box>
      )
    }
  }

  const renderNoteText = (noteText: string) => {
    try {
      const parsed = JSON.parse(noteText)
      return (
        <div
          className='draft-section'
          dangerouslySetInnerHTML={{
            __html: parsed.text,
          }}
        />
      )
    } catch (error) {
      return <Typography variant='ps'>{noteText}</Typography>
    }
  }

  const renderComments = () => {
    return (
      <>
        {noteLoad.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {comments.length === 0 && <Typography>No comments</Typography>}
            {comments.map((comment) => (
              <InteractionComment
                key={comment.feedbackID}
                comment={comment}
                onClickDelete={() => onClickDelete(comment)}
                deletable={comment.userID === ewUser.userID && !deleteComment.isLoading}
              />
            ))}
          </>
        )}
      </>
    )
  }

  const handleCloseViewer = () => {
    setShowImageViewer(false)
  }

  return (
    <Box className='dm-interaction'>
      {interactions > 0 && (
        <Box display='flex' alignItems='center' justifyContent={'space-between'} mb={1}>
          <Typography variant='labelLarge'>
            {`${interactions} Interaction${interactions > 1 ? 's' : ''}`}
          </Typography>
          <Button onClick={onToggleViewMode} sx={{ minWidth: 50 }}>
            {isViewMode ? (
              <Close />
            ) : (
              <Typography variant='label' color={colors.blue}>
                View
              </Typography>
            )}
          </Button>
        </Box>
      )}
      {isViewMode ? (
        <>
          <Box className='view-tab'>
            <Button className={viewTab === 1 ? 'active' : ''} onClick={() => setViewTab(1)}>
              {`Comments (${comments.length || 0})`}
            </Button>
            <Button className={viewTab === 2 ? 'active' : ''} onClick={() => setViewTab(2)}>
              {`Likes (${selectedNote?.thumbsUp})`}
            </Button>
          </Box>
          <Box className='view-list' flex={1} overflow='auto'>
            {viewTab == 2 && !getNoteLikes.data ? (
              <Box display={'flex'} justifyContent={'center'} pt={4}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {viewData.length === 0 ? (
                  <Box pt={4}>
                    <NoInteractions type={viewTab - 1} on='photo' />
                  </Box>
                ) : (
                  viewData.map((data: UserInteractionProps, index: number) => (
                    <Box key={index}>
                      <UserInteraction {...data} />
                    </Box>
                  ))
                )}
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          {noteData.noteType === '1' && (
            <Box borderRadius={2} px={2} py={1} mb={1} bgcolor={colors.veryLightGrey}>
              {renderNoteText(noteData.noteText)}
            </Box>
          )}
          {renderPreview()}
          <Box
            py={1}
            display='flex'
            alignItems={'center'}
            justifyContent='flex-end'
            borderBottom={`1px solid ${colors.veryLightGrey}`}
          >
            {comments.length > 0 && (
              <Typography flex={1}>
                {`${comments.length} Comment${comments.length > 1 ? 's' : ''}`}
              </Typography>
            )}
            <IconButton onClick={onToggleLike} disabled={interactToNote.isLoading}>
              {selectedNote?.isThumbsUp == 1 ? (
                <Favorite fontSize='small' sx={{ color: colors.yellow, marginRight: 0.5 }} />
              ) : (
                <SVG
                  src={SVGS.ActionLike}
                  width={15}
                  height={15}
                  color={colors.darkGrey}
                  style={{ marginRight: 5 }}
                />
              )}
              <Typography display='flex' alignItems={'center'} mr={2}>
                {Number(selectedNote?.thumbsUp || '0') > 0 ? selectedNote?.thumbsUp : 'Like'}
              </Typography>
            </IconButton>

            {comments.length > 0 && (
              <Typography display='flex' alignItems={'center'} className='interaction'>
                <SVG
                  src={SVGS.ActionComment}
                  width={15}
                  height={15}
                  color={colors.darkGrey}
                  style={{ marginRight: 5 }}
                />
                {comments.length}
              </Typography>
            )}
          </Box>
          <Box ref={listRef} flex={1} sx={{ overflowX: 'hidden', overflowY: 'auto', pb: 1 }}>
            {renderComments()}
          </Box>
          <Input
            value={newComment}
            placeholder='Add your comment'
            onChange={onChangeNewComment}
            disabled={addNoteComment.isLoading}
            rightIcon={addNoteComment.isLoading ? <CircularProgress size={20} /> : <Send />}
            onToggleIcon={onClickSendNewComment}
            onKeyDown={handleKeyDown}
          />
        </>
      )}

      {showImageViewer && (
        <Dialog
          fullScreen
          open={showImageViewer}
          onClose={handleCloseViewer}
          TransitionComponent={Transition}
        >
          <Box position={'relative'} height={'100%'}>
            {renderAlbumSlider('full', true)}
          </Box>

          <IconButton
            sx={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              backgroundColor: colors.meshdomBlue,
              width: '40px',
              height: '40px',
              borderRadius: '40px',
              '&:hover': {
                backgroundColor: colors.meshdomBlue,
              },
              zIndex: 10,
            }}
            onClick={handleCloseViewer}
          >
            <Close sx={{ color: 'white' }} />
          </IconButton>
        </Dialog>
      )}
    </Box>
  )
}
