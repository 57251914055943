import { useGetContainerDetails } from '@/hooks/api/dashboard'
import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import SVG from 'react-inlinesvg'
import { CheckCircle, ChevronLeft } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
// import { useNavigate } from "react-router-dom";
import { shallow } from 'zustand/shallow'
import { EgosContainer, UploadPhotoItem } from './__styled'
import { SVGS } from '@/assets/svgs'
import { EgoObject } from '@/types'
import { RBox } from '../__styled'
import { RoundedOutlineButton } from '@/components/StyledComponents'

interface Props {
  onSelectEgos: (egoObjects: EgoObject[]) => void
  selectedEgoList: EgoObject[]
  onClickShowDrawer: () => void
}

export default function UploadPhotos({ onSelectEgos, selectedEgoList, onClickShowDrawer }: Props) {
  const query = useBrowserQuery()
  const containerID = query.get('container')
  // const navigate = useNavigate();
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const getContainerDetails = useGetContainerDetails()

  const [myContainers] = useAppStore((state) => [state.user.myContainers], shallow)

  const containerInfo = useMemo(() => {
    return myContainers.find((i) => i.containerID === containerID)
  }, [myContainers, containerID])

  useEffect(() => {
    if (containerID && containerInfo) {
      getContainerDetails.mutate(containerID || '')
      onSelectEgos([])
    }
  }, [containerInfo, containerID])

  if (!containerInfo) return null

  const onClickEgo = (egoObject: EgoObject) => {
    const index = selectedEgoList.findIndex((i) => i.egoID === egoObject.egoID)
    if (index < 0) {
      selectedEgoList.push(egoObject)
    } else {
      selectedEgoList.splice(index, 1)
    }
    onSelectEgos([...selectedEgoList])
  }

  return (
    <EgosContainer p={2}>
      <Box display='flex' alignItems='center'>
        <RBox sx={{ justifyContent: 'space-between', flex: 1 }}>
          <RBox gap={'4px'}>
            <Typography variant='h3'>{containerInfo.photoCount}</Typography>
            <Typography
              sx={{
                fontFamily: 'Inter-Regular',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {'photo(s) you uploaded'}
            </Typography>
          </RBox>
          {selectedEgoList.length > 0 && (
            <RBox gap={'8px'}>
              <RBox gap={'4px'}>
                <Typography variant='h3'>{selectedEgoList.length}</Typography>
                <Typography
                  sx={{
                    fontFamily: 'Inter-Regular',
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  {'photo(s) selected'}
                </Typography>
              </RBox>
              {lessThanMedium && (
                <RoundedOutlineButton sx={{ padding: '4px 16px' }} onClick={onClickShowDrawer}>
                  {'Add To Topics'}
                </RoundedOutlineButton>
              )}
            </RBox>
          )}
        </RBox>
      </Box>
      {getContainerDetails.isLoading && (
        <Box display='flex' justifyContent={'center'} pt={2}>
          <CircularProgress />
        </Box>
      )}
      {getContainerDetails.data?.egoObjects.length === 0 && (
        <Box display='flex' flexDirection={'column'} alignItems={'center'} pt={2}>
          <SVG src={SVGS.NoAlbum} width={100} height={100} />
          <Typography pt={1}>No photos</Typography>
        </Box>
      )}
      <Grid
        container
        className='photo-list'
        mx={-2}
        spacing={2}
        marginTop={2}
        sx={{ overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}
      >
        {getContainerDetails.data?.egoObjects?.map((ego) => {
          const selected = selectedEgoList.find((e) => e.egoID == ego.egoID)
          return (
            <Grid item key={ego.egoID} xs={6} md={2} className='photo-item'>
              <UploadPhotoItem
                photo={ego.egoAlbumURL}
                onClick={() => onClickEgo(ego)}
                boxShadow={selected ? `-3px 4px 6px ${colors.blue}` : 'none'}
              />
              {selected && (
                <Box className='checked'>
                  <CheckCircle color='success' />
                </Box>
              )}
            </Grid>
          )
        })}
      </Grid>
    </EgosContainer>
  )
}
