import colors from '@/styles/colors'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ConnectionListItem = styled(Box)({
  padding: '20px 0 20px 20px',
  borderBottom: `1px solid ${colors.lightGrey}`,
  cursor: 'pointer',
  display: 'flex',
  position: 'relative',
  '&.active': {
    borderLeft: `4px solid ${colors.yellow}`,
    boxShadow: '0 1px 2px rgba(0,0,0,.1)',
  },
  '& .last-note-content': {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    marginBottom: 4,
  },
  '& .last-note-image': {
    backgroundSize: 'cover',
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: 2,
    position: 'relative',
  },
})

export const ConnectionListHeader = styled(Box)({
  padding: 16,
  paddingTop: 8,
  borderBottom: `1px solid ${colors.lightGrey}`,
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 10,
})

export const ModalContainer = styled(Box)({
  backgroundColor: 'red',
  '& .select-circle-modal': {
    width: '90%',
    maxWidth: 400,
    height: '70%',
    maxHeight: 400,
  },
})
