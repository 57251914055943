import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import { isBabylonType } from '@/utils/helper'
import { useMediaQuery, useTheme } from '@mui/material'
import { shallow } from 'zustand/shallow'
import { InteractionsContent } from '../__styled'
import CircleInteraction from './Circle'
import ConnectInteraction from './Connection'
import NoteInteractions from './Note'

interface Props {
  onClickClose: () => void
}

export default function TopicInteractions({ onClickClose }: Props) {
  const { setIsPrivacySelected } = useAppStore()
  const [isPrivacySelected, topicList] = useAppStore(
    (state) => [state.home.isPrivacySelected, state.home.topicList],
    shallow,
  )

  // const theme = useTheme()
  // const lessThanLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const query = useBrowserQuery()
  const dmTopicID = query.get('dmTopicID')
  const topicID = query.get('topicID')

  const { topicType, visibility } = topicList[topicID || ''] || {}

  const onClosePrivacy = () => {
    setIsPrivacySelected(false)
  }

  return (
    <InteractionsContent>
      {isPrivacySelected && dmTopicID && <ConnectInteraction onClosePrivacy={onClosePrivacy} />}
      {isPrivacySelected && visibility === '4' && (
        <ConnectInteraction onClosePrivacy={onClosePrivacy} />
      )}
      {isPrivacySelected && visibility === '6' && (
        <ConnectInteraction onClosePrivacy={onClosePrivacy} />
      )}
      {isPrivacySelected && visibility === '5' && (
        <CircleInteraction onClosePrivacy={onClosePrivacy} />
      )}
      {!isPrivacySelected && topicType === '2' && <NoteInteractions trackKey='topicID' />}
      {!isPrivacySelected && topicType === '4' && <NoteInteractions trackKey='topicID' />}
      {!isPrivacySelected && topicType === '1' && <NoteInteractions trackKey='topicID' />}
      {!isPrivacySelected && isBabylonType(topicType) && <NoteInteractions trackKey='topicID' />}
      {!isPrivacySelected && dmTopicID && <NoteInteractions trackKey='dmTopicID' />}
    </InteractionsContent>
  )
}
