/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAppSlice, IAppStore } from '@/types'

const initialState: IAppStore = {
  profileUpdated: 0, // timestamp
  sideTabKey: 'DISCOVER_SHARE',
  sideTitle: 'Home',
  sideTabPath: 'discover/home',
  sideTabHidden: false,
  showSideMenuDrawer: false,
}

export const createAppSlice = (set: any): IAppSlice => ({
  app: initialState,
  setProfileUpdated: () =>
    set((state: any) => ({
      app: {
        ...state.app,
        profileUpdated: new Date().getTime(),
      },
    })),
  setSideTabKey: (key: string) =>
    set((state: any) => ({
      app: {
        ...state.app,
        sideTabKey: key,
      },
    })),
  setSideTitle: (value: string) =>
    set((state: any) => ({
      app: {
        ...state.app,
        sideTitle: value,
      },
    })),

  setSideTabPath: (value: string) =>
    set((state: any) => ({
      app: {
        ...state.app,
        sideTabPath: value,
      },
    })),
  setSideTabHidden: (value: boolean) =>
    set((state: any) => ({
      app: {
        ...state.app,
        sideTabHidden: value,
      },
    })),
  setShowSideMenuDrawer: (value: boolean) =>
    set((state: any) => ({
      app: {
        ...state.app,
        showSideMenuDrawer: value,
      },
    })),
})

export default createAppSlice
