/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { TopicTypeAndApplicationDic } from '@/utils/constants'

interface Params {
  topicName: string
  topicType: string
  topicXData: string
  allowContribution: number
  privacyType: number
  cliques: string[]
  connections: string[]
}

export const useCreateTopic = (userID: string) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.CREATE_TOPIC, userID],
    mutationFn: async (param: Params) => {
      const { topicName, topicType, topicXData, privacyType, cliques, connections } = param
      const noteText = {
        text: `<p>${topicName}</p>`,
        attachments: [],
      }
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicCreateTopicWithNote',
        'topicData[topicName]': topicName,
        'topicData[topicApplication]': TopicTypeAndApplicationDic[topicType] || 2,
        'topicData[topicDescription]': topicName,
        'topicData[topicXData]': topicXData,
        'topicData[topicType]': topicType,
        'permissionData[accessTypeID]': privacyType,
        'permissionData[accessList][connections]': connections,
        'permissionData[accessList][cliques]': cliques,
        'noteData[noteName]': 'First note',
        'noteData[noteText]': JSON.stringify(noteText),
        'noteData[egoList]': [],
        format: 'json',
      })
      return response.data
    },
  })
}
