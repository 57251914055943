import { UserAvatar } from '@/components'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import colors from '@/styles/colors'
import { useConnectionDecline, useGetBasicInfo, useGetTopics } from '@/hooks/api/dashboard'
import { ChevronLeft } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserDetailsContent } from '../__styled'
import dayjs from 'dayjs'
import UserTopics from './UserTopics'
import UserConnections from './Connections'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import { toast } from 'react-hot-toast'
import { RoundedActionButton, RoundedFilledButton } from '@/components/StyledComponents'
import { ScrollableCapsulesTabBar } from '@/components/ScrollableCapsulesTabBar'
import { RBox } from '@/pages/__styled'
import { PrivacyType, PrivacyTypes, TopicType, TopicTypes } from '@/utils/constants'
import TopicItemsGrid from '@/pages/home/molecules/TopicItemsGrid'
import { SearchType, Topic } from '@/types'
import { useTopicsListViewModel } from '@/hooks/api/dashboard/viewModels/useTopicsListViewModel'
import { useConnectionsListViewModel } from '@/hooks/api/dashboard/viewModels/useConnectionsListViewModel'
import { ConnectionsListContext } from '@/context/ConnectionsListContext'

export default function UserDetails() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [ewUser] = useLocalStorage('ewUser', null)

  const topicsViewModel = useTopicsListViewModel()
  const { topicsList, loading, canLoadNextPage, loadingNextPage, loadNextPageData, getTopics } =
    topicsViewModel

  const connectionsViewModel = useConnectionsListViewModel()
  const {
    loading: loadingConnections,
    loadingNextPage: loadingConnNextPage,
    canLoadNextPage: canLoadConnNextPage,
    connections,
    getConnectionsList,
    loadNextPageData: loadNextPageConnData,
    pagination: connPagination,
  } = connectionsViewModel

  const navigate = useNavigate()
  const query = useBrowserQuery()
  const { pathname } = useLocation()
  const userId = query.get('id')
  const getUserInfo = useGetBasicInfo(userId || '')
  const declineRequest = useConnectionDecline()
  const [selectedUserProfile] = useAppStore((state) => [state.user.selectedUserProfile], shallow)

  const topicTypes = [TopicType.PATTERN, TopicType.QUILT, TopicType.GALLERY, TopicType.ALBUM]

  useEffect(() => {
    if (userId) {
      getUserInfo.mutate({ isUserProfile: true })
      //selectedTabIndex(0)
    }
  }, [userId])

  useEffect(() => {
    if (getUserInfo.isSuccess) {
      loadUserTopics()
      loadUserConnections()
    }
  }, [getUserInfo.isSuccess])

  const loadUserTopics = (pageNo: number = 1) => {
    if (userId) {
      getTopics({
        pageNo: pageNo,
        privacyType: PrivacyType.ALL,
        topicType: TopicType.ALL,
        searchType: SearchType.OtherUser,
        connID: userId,
      })
    }
  }

  const loadUserConnections = (pageNo: number = 1) => {
    if (userId) {
      getConnectionsList({ connID: userId })
    }
  }

  const topics = useMemo(() => {
    let list = topicsList
    list = list.filter((i) => {
      const topicData = TopicTypes[Number(i.topicType)]
      const privacyData = PrivacyTypes[Number(i.visibility)]
      return topicData && privacyData
    })
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1
      if (a.dateUpdated < b.dateUpdated) return 1
      return 0
    })
    return list
  }, [topicsList])

  const tabTitles = useMemo(() => {
    const titles = ['Patterns', 'Quilts', 'Galleries', 'Albums']
    let mapped = topicTypes.map((e, i) => {
      if (topicsList.length > 0) {
        let filtered = topicsList.filter((e) => e.topicType == topicTypes[i].toString())
        return `${titles[i]} (${filtered.length})`
      } else {
        return `${titles[i]} (0)`
      }
    })

    mapped.push(`Followers (${connections?.length || 0})`)

    return mapped
  }, [topicsList, connections])

  const onClickTopic = (topic: Topic) => {
    const { topicID, topicType } = topic

    let previewQuery = new URLSearchParams()
    previewQuery.set('topicID', topicID)
    previewQuery.set('topicType', topicType)
    navigate(`/preview?${previewQuery.toString()}`)
  }

  const selectedTopicType = useMemo(() => {
    let type = TopicType.ALL
    if (selectedTabIndex < topicTypes.length) {
      type = topicTypes[selectedTabIndex]
    }
    return type
  }, [selectedTabIndex])

  if (getUserInfo.isLoading) {
    return (
      <UserDetailsContent justifyContent={'center'} alignItems='center'>
        <CircularProgress />
      </UserDetailsContent>
    )
  }

  if (getUserInfo.isError) {
    return (
      <UserDetailsContent justifyContent={'center'} alignItems='center'>
        <Typography variant='label'>User not found</Typography>
      </UserDetailsContent>
    )
  }

  const profile = selectedUserProfile
  if (!profile) return null

  const onClickConnect = () => {
    query.set('connID', profile.userID)
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickAcceptRequet = () => {
    query.set('connID', profile.userID)
    query.set('requestID', String(profile.reqSentConnReqID))
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const onClickDeclineRequet = () => {
    toast.promise(declineRequest.mutateAsync(String(profile.reqSentConnReqID)), {
      loading: 'Processing the decline...',
      success: <Typography variant='p'>{`Success!`}</Typography>,
      error: <Typography variant='p'>Failed to decline the request.</Typography>,
    })
  }

  const renderConnectionStatus = () => {
    if (profile.userID === ewUser.userID || profile.isConnection) return null
    if (!profile.reqSent && !profile.reqReceived) {
      return (
        <RoundedActionButton variant={'contained'} onClick={onClickConnect}>
          {'Follow'}
        </RoundedActionButton>
      )
    } else if (profile.reqReceived) {
      return <Typography>{'Pending'}</Typography>
    } else if (profile.reqSent) {
      return (
        <RBox gap={'4px'}>
          <RoundedActionButton variant={'outlined'} onClick={onClickDeclineRequet}>
            {'Reject'}
          </RoundedActionButton>
          <RoundedActionButton variant={'contained'} onClick={onClickAcceptRequet}>
            {'Accept'}
          </RoundedActionButton>
        </RBox>
      )
    }
    return null
  }

  return (
    <UserDetailsContent sx={{ width: '100%', overflow: 'auto', height: '100%' }}>
      <Box p={'16px 16px 16px 20px '}>
        <Box display='flex' pb={'20px'} sx={{ alignItems: 'center' }}>
          <UserAvatar
            name={`${profile?.firstName} ${profile?.lastName}`}
            url={profile?.profilePicURL}
            borderRadius={100}
            size={100}
          />
          <Box flex={1} pl={2} overflow='hidden'>
            <Typography variant='headingMedium' className='username'>
              {profile.firstName} {profile.lastName}
            </Typography>
            <Typography className='motto' fontSize={14} fontWeight={400} mt={'-4px'}>
              {profile.motto || 'Be the oddly you'}
            </Typography>
            <Typography variant='ps' mt={1} color={colors.themePurpleTextColor}>
              Member since {dayjs(profile?.joinDate).format('MMM YYYY')}
            </Typography>
          </Box>
          <Box> {renderConnectionStatus()}</Box>
        </Box>
      </Box>

      <Box
        sx={{
          px: '16px',
          pt: '20px',
          pb: '10px',
          width: '100%',
          borderBottom: `1px solid ${colors.divider}`,
          borderTop: `1px solid ${colors.divider}`,
        }}
      >
        <ScrollableCapsulesTabBar
          titles={tabTitles}
          selectedIndex={selectedTabIndex}
          onSelectAtIndex={setSelectedTabIndex}
          showScrollButtons={false}
        />
      </Box>

      <Box flexDirection={'column'} display={'flex'} sx={{ width: '100%' }} overflow={'auto'}>
        {selectedTabIndex != 4 && userId && (
          <TopicItemsGrid
            topics={topics}
            onClickTopicItem={onClickTopic}
            onRemoved={() => {}}
            filter={{ topicType: selectedTopicType, privacyType: PrivacyType.ALL }}
            showAuthor={false}
            showAuthorForPublic={false}
          />
        )}
        {selectedTabIndex == 4 && userId && (
          <ConnectionsListContext.Provider
            value={{
              items: connections,
              loading: loadingConnections,
              loadingNextPage: loadingConnNextPage,
              canLoadNextPage: canLoadConnNextPage,
              loadNextPageData: loadNextPageConnData,
            }}
          >
            <UserConnections />
          </ConnectionsListContext.Provider>
        )}
      </Box>
    </UserDetailsContent>
  )
}
