import { TopicStates, UserAvatar } from '@/components'
import { Box, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material'
import colors from '@/styles/colors'
import { Topic } from '@/types'
import { PrivacyTypes, TopicTypes } from '@/utils/constants'
import { useEffect } from 'react'
import { useDeleteTopic } from '@/hooks/api/dashboard'
import { useBrowserQuery } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import ReactTimeago from 'react-timeago'
import { convertToGMT } from '@/utils/helper'
import { RoundedFilledButton } from '@/components/StyledComponents'
import { useLocalStorage } from '@/hooks/common'
import { ArrowBackIos } from '@mui/icons-material'

interface HeaderProps {
  isShowAction?: boolean
  topic: Topic
  showEditForOwner?: boolean
  onClickBack: () => void
  onClickComment: () => void
  onClickPrivacy: () => void
  onDeletedTopic?: () => void
  onClickEdit?: () => void
}

export default function TopicDetailsHeader({
  topic,
  showEditForOwner = true,
  onDeletedTopic,
  onClickEdit,
  onClickBack,
}: HeaderProps) {
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'))
  const topicData = TopicTypes[Number(topic.topicType)]
  const privacyData = PrivacyTypes[Number(topic.visibility)]
  const [ewUser] = useLocalStorage('ewUser', null)
  const deleteTopic = useDeleteTopic(topic.topicID)
  // const navigate = useNavigate();
  const { removeTopic } = useAppStore()
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''
  const isMyTopic = ewUser?.userID == topic.userID
  // const noteID = query.get("noteID") || '';

  useEffect(() => {
    if (deleteTopic.isSuccess) {
      removeTopic(topicID)
      onDeletedTopic && onDeletedTopic()
    }
  }, [deleteTopic.isSuccess])

  if (!topicData || !privacyData) return null

  return (
    <>
      <Box padding='20px 20px 15px 16px'>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {lessThanSmall && (
              <IconButton
                onClick={onClickBack}
                sx={{
                  backgroundColor: 'transparent',
                  px: 0,
                }}
              >
                <ArrowBackIos sx={{ ml: '8px', color: colors.themeBlack }} />
              </IconButton>
            )}

            <UserAvatar
              borderRadius={35}
              name={topic.userName}
              url={topic.profilePicThumbURL}
              size={35}
            />
            <Box ml={'10px'} sx={{ alignItems: 'center' }}>
              <Typography
                fontWeight={600}
                fontSize={13}
                lineHeight={'13px'}
                sx={{ color: '#525E9C' }}
              >
                {topic.userName}
              </Typography>
              {topic.dateAdded && (
                <Typography
                  variant='p'
                  fontSize={10}
                  lineHeight={'10px'}
                  mt={'3px'}
                  sx={{ color: '#525E9C' }}
                >
                  <ReactTimeago date={convertToGMT(topic.dateAdded)} />
                </Typography>
              )}
            </Box>
          </Box>
          {showEditForOwner && isMyTopic && (
            <RoundedFilledButton sx={{ padding: '4px 20px' }} onClick={onClickEdit}>
              Edit
            </RoundedFilledButton>
          )}
        </Box>
        {/* <IconButton onClick={onClickBack} sx={{ padding: 0, ml: 'auto', maxWidth: '30px' }}>
          <SVG src={CloseIcon} width={30} height={30} />
        </IconButton> */}
      </Box>
    </>
  )
}
