/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { NoteDetail, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useNoteLoad = (noteID: string) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.NOTE_LOAD, noteID],
    mutationFn: async (): Promise<NoteDetail> => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteLoad',
        format: 'json',
        noteID,
      })
      return response.data.noteDetails
    },
  })
}
