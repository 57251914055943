/* eslint-disable */
import { IPatternAssets, Note, PatternDraft, PatternProps, PatternSelections, Topic } from '@/types'
import { Assets } from '@/utils/pattern-assets'
import { useEffect, useRef, useState, useMemo } from 'react'
import {
  useAddNoteTopic,
  useDeleteEgo,
  useGetTopicDetails,
  useUpdateNote,
  useUploadMedia,
  useDeletePatternTopic,
  useUpdatePatternTopic,
  useUpdateVisibility,
  useGetPermissions,
  useCreateTopic,
} from '@/hooks/api/dashboard'
import AssetSelector from './AssetSelector'
import PatternPreview from './Preview'
import { PatternContainer } from './__styled'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { toast } from 'react-hot-toast'
import {
  Badge,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Theme,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  HOME_PATH,
  PatternNoteTypes,
  PrivacyType,
  PrivacyTypes,
  ResourceType,
  TopicType,
} from '@/utils/constants'
import { SetTopicType } from './renderer/main'
import { theme } from '@/styles/theme'
import colors from '@/styles/colors'
import { Link } from 'react-router-dom'

import { TopicTypes } from '@/utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import CloseIcon from '@/assets/svgs/close-icon.svg'
import CloseIconRound from '@/assets/svgs/close-round.svg'
import { CBox, RBox } from '../__styled'
import {
  ArrowBackIos,
  Fullscreen,
  KeyboardArrowDownOutlined,
  Menu as MenuIcon,
} from '@mui/icons-material'
import { SVGS } from '@/assets/svgs'
import { ShareLink } from '@/components/ShareLink'
import { RoundedFilledButton } from '@/components/StyledComponents'
import { ActionMenu, RInputModal, RModal, TagTopic } from '@/components'
import { MenuDarkTheme } from '@/styles/MenuDarkTheme'
import SaveScreenshotModal from './components/SaveScreenshotModal'
import FullScreenLoadingView from './components/FullScreenLoadingView'

const Sidebar = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#313338',
  padding: '20px',
  height: '100%',
})

const FooterButton = styled(Button)({
  color: 'white',
  fontSize: '12px',
  fontWeight: 600,
  borderRadius: 50,
  '&.active': {
    backgroundColor: '#2884FF',
  },
  '&.inactive': {
    border: '1px solid #FFFFFF66',
  },
})

const RightSideBar = styled(Box)({
  position: 'relative',
  minWidth: '340px',
  width: '340px',
  backgroundColor: 'white',
  transition: 'width 0.3s',
  '&.hidden': {
    minWidth: '0px',
    width: '0px',
    transition: 'width 0.3s',
  },
})

const ButtonClose = styled(Box)({
  width: '30px',
  height: '30px',
  borderRadius: '30px',
  position: 'absolute',
  top: 20,
  right: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #CCD4DC',
  cursor: 'pointer',
})

export default function PatternPage() {
  const deleteTopic = useDeletePatternTopic()
  const updateTopic = useUpdatePatternTopic()
  const updateVisibility = useUpdateVisibility()
  const getPermissions = useGetPermissions()

  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('md'))

  const [assets, setAssets] = useState<IPatternAssets>(Assets)
  const [topic, setTopic] = useState<Topic | null>(null)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAssetSelectorDrawer, setShowAssetSelectorDrawer] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(true)
  const [selectedDisplayRight, setSelectedDisplayRight] = useState('')
  const [currentTab, setCurrentTab] = useState('template')
  const [patternData, setPatternData] = useState<PatternDraft>({})
  const [textData] = useState('')
  const [draftData, setDraftData] = useState<PatternDraft | null>(null)
  const [showShareLink, setShowShareLink] = useState(false)
  const [changesCount, setChangesCount] = useState(0)
  const [selectedAssets, setSelectedAssets] = useState<PatternSelections>({
    boarder: {
      width: 0,
      type: 'square',
    },
    clicked: false,
  })

  const [ewUser] = useLocalStorage('ewUser', null)
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  // const mode = query.get('mode')

  const uploadPhoto = useUploadMedia()
  const getTopicDetails = useGetTopicDetails()
  const createTopicNote = useAddNoteTopic()
  const updateTopicNote = useUpdateNote()
  const deleteEgos = useDeleteEgo(topicID)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const createTopic = useCreateTopic(ewUser?.userID)
  const saveMode = useRef(0) // 1 for screenshot only, 2 for update topic, 3 for goBack, 4 for duplicate
  const topicStateRef = useRef<Topic | null>(null)
  const draftDataRef = useRef<PatternDraft | null>(null)
  const screenshotFile = useRef<File | null>(null)

  const [isEditMode, setIsEditMode] = useState(true)
  const [showInteractions, setShowInteractions] = useState(!isEditMode)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [renameModalOpened, setRenameModalOpened] = useState(false)
  const [duplicateModalOpened, setDuplicateModalOpened] = useState(false)
  const [showScreenshotModal, setShowSaveScreenshotModal] = useState(false)

  const templateChanged = useRef(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const canvaRef = useRef<any>(null)

  const updateRendererComponent = () => {
    window.requestAnimationFrame(() => {
      if (canvaRef.current) canvaRef.current.updateRender()
    })
  }

  useEffect(() => {
    topicStateRef.current = topic
  }, [topic])

  useEffect(() => {
    draftDataRef.current = draftData
  }, [draftData])

  useEffect(() => {
    initializePatternData()
  }, [])

  useEffect(() => {
    if (createTopicNote.isSuccess && saveMode.current != 4) {
      getTopic()
    }
  }, [createTopicNote.isSuccess])

  const onAssetsUpdate = (updatedAssets: IPatternAssets) => {
    setAssets(updatedAssets)
  }

  const incrementChangesCount = () => {
    setChangesCount((prev) => prev + 1)
  }
  const resetChangesCount = () => {
    setChangesCount(0)
  }

  const menuItems = useMemo(() => {
    if (topic != null) {
      let items = [
        {
          icon: null,
          name: 'Rename',
          onClick: () => setRenameModalOpened(true),
        },
        {
          icon: null,
          name: 'Duplicate',
          onClick: () => setDuplicateModalOpened(true),
        },
      ]

      if (topic.visibility == PrivacyType.PRIVATE.toString()) {
        items.push({
          icon: null,
          name: 'Save as Public',
          onClick: () => changeVisibility(PrivacyType.PUBLIC),
        })
      } else {
        items.push({
          icon: null,
          name: 'Make Private',
          onClick: () => changeVisibility(PrivacyType.PRIVATE),
        })
      }
      items.push({
        icon: null,
        name: 'Delete',
        onClick: () => setDeleteModalOpened(true),
      })
      return items
    }
    return []
  }, [topic])

  useEffect(() => {
    if (!isMyTopic && topic) {
      navigate(HOME_PATH)
    }
  }, [topic])

  /*
  useEffect(() => {
    if (topic != null) {
      if (isEditMode && !isMyTopic) {
        query.delete('mode')
        navigate(`${pathname}?${query.toString()}`, { replace: true })
      }
    }
  }, [topic, ewUser, mode])
  */

  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.dispatchEvent(new Event('resize'))
    })
  }, [showInteractions])

  const isMyTopic = useMemo(() => {
    return ewUser?.userID == topic?.userID
  }, [topic, ewUser])

  const saveDraft = async () => {
    if (!topic) return
    for (const draftKey in draftData) {
      if (!PatternNoteTypes[draftKey]) continue

      const noteText =
        draftData[draftKey as keyof PatternDraft] == undefined
          ? 'null'
          : JSON.stringify(draftData[draftKey as keyof PatternDraft])
      const find = topic.notes.find((note) => note.noteType === PatternNoteTypes[draftKey])
      if (!find) {
        await createNewNote(draftKey, noteText, topic.topicID)
      } else if (find.noteText !== noteText) {
        find.noteText = noteText
        await updateNote({ ...find, noteText })
        setTopic({ ...topic })
      }
    }
  }

  const copyDraftToTopic = async (newTopic: Topic, newDraftData: PatternDraft) => {
    for (const draftKey in newDraftData) {
      if (!PatternNoteTypes[draftKey]) continue
      const noteText = JSON.stringify(newDraftData[draftKey as keyof PatternDraft])
      await createNewNote(draftKey, noteText, newTopic.topicID)
    }
    setTopic({ ...newTopic })
    setDraftData({
      ...newDraftData,
    })
  }

  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.dispatchEvent(new Event('resize'))
    })
  }, [isFullScreen, isEditMode])

  const onConfirmDeleteTopic = () => {
    if (topic) {
      toast.loading('Deleting...', { id: 'delete-topic' })
      deleteTopic
        .mutateAsync({ topicID: topic.topicID })
        .then((value) => {
          toast.remove('delete-topic')
          toast.success('Deleted Successfully.')
          navigate(HOME_PATH)
        })
        .catch((e) => {
          toast.remove('delete-topic')
          toast.error(e.toString())
        })
    }
  }

  const onConfirmRenameTopic = (text: string) => {
    if (topic) {
      toast.loading('Updating...', { id: 'update-topic' })
      updateTopic
        .mutateAsync({ topicID: topic.topicID, topicName: text })
        .then((value) => {
          toast.remove('update-topic')
          toast.success('Updated Successfully.')

          const noteText = {
            text: `<p>${text}</p>`,
            attachments: [],
          }

          let updatedTopic = {
            ...topic,
            topicName: text,
            firstNote: {
              ...topic.firstNote,
              noteText: JSON.stringify(noteText),
            },
          }
          setTopic((prev) => ({ ...updatedTopic }))
        })
        .catch((e) => {
          toast.remove('update-topic')
          toast.error(e.toString())
        })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addDraft = (data: PatternDraft) => {
    setDraftData(data)
  }

  const getTopic = async (): Promise<Topic | undefined> => {
    setLoading(true)
    let topicData
    try {
      topicData = await getTopicDetails.mutateAsync({ topicID })
      setTopic(topicData)
      SetTopicType(topicData.topicType)
      setLoading(false)
      return topicData
    } catch (e) {
      setIsError(true)
      setLoading(false)
    }
  }

  const initializePatternData = async () => {
    const topic = await getTopic()
    if (!topic) return
    const defaultTemplateID = topic.topicType == TopicType.PATTERN.toString() ? 1 : 2
    const draft: PatternDraft = { templateID: defaultTemplateID }
    topic.notes.forEach((note: Note) => {
      if (note.noteType === '8') {
        // setup initial text overlay
      } else if (note.noteType === '9') {
        // preview
      } else if (note.noteType !== '1' && note.noteText !== 'null' && note.noteText != null) {
        draft[note.noteName as keyof PatternDraft] = JSON.parse(note.noteText)
      }
    })
    setPatternData(draft)
  }

  const createNewNote = async (name: string, data: string, topicID: string) => {
    setLoading(true)
    try {
      await createTopicNote.mutateAsync({
        noteName: name,
        topicID: topicID,
        noteText: data,
        egoItems: [],
        noteType: PatternNoteTypes[name],
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(e.toString())
    }
  }

  if (!topic) return null

  const onTemplateChanged = () => {
    templateChanged.current = true
  }
  const onAssetSelected = (category: string, assets: PatternSelections) => {
    setCurrentTab(category)

    setSelectedAssets({
      ...selectedAssets,
      ...assets,
      clicked: true,
    })
    updateRendererComponent()
  }

  const goBack = () => {
    // const params = new URLSearchParams()
    // params.set('topicID', topic.topicID)
    // params.set('topicType', topic.topicType)
    // navigate(`/preview?${params.toString()}`, { replace: true })
    navigate(-1)
  }
  const onBackClick = () => {
    if (changesCount > 0) {
      saveDraft()
      if (loading) return
      saveMode.current = 3
      if (canvaRef.current) {
        canvaRef.current.saveScreenShot()
      }
    } else {
      goBack()
    }
  }

  const onClickDuplicate = () => {
    duplicatePattern()
  }

  const duplicatePattern = async () => {
    // Create Topic, with Name and type
    // Add Notes,
    const newDraftData = { ...draftData }
    if (topic != null) {
      setLoading(true)
      toast.loading('Processing', { id: 'topic-duplicate' })
      const result = await createTopic.mutateAsync({
        topicName: `Copy of ${topic.topicName}`,
        topicType: topic.topicType,
        allowContribution: 0,
        topicXData: 'X Data Here',
        privacyType: 3,
        cliques: [],
        connections: [],
      })
      toast.remove('topic-duplicate')

      saveMode.current = 4
      const newTopic = await getTopicDetails.mutateAsync({ topicID: result.topicID })
      topicStateRef.current = newTopic
      setTopic((prev) => ({ ...newTopic }))
      await copyDraftToTopic(newTopic, newDraftData)
      if (canvaRef.current) {
        canvaRef.current.saveScreenShot()
      }
    }
  }

  const changeVisibility = (newPrivacy: PrivacyType) => {
    if (topic != null) {
      // Get Permission ID

      const updatePerm = (permID: string) => {
        updateVisibility
          .mutateAsync({
            resourceID: topic.topicID,
            cliqueTypes: topic.cliqueTypes,
            cliques: [],
            connections: [],
            accessTypeID: newPrivacy.toString(),
            permissionID: permID,
            resourceType: ResourceType.TOPIC,
          })
          .then((value) => {
            toast.remove('perm-load')
            toast.success('Updated')

            setTopic({
              ...topic,
              visibility: newPrivacy.toString(),
            })
          })
          .catch((err) => {
            toast.remove('perm-load')
            toast.error(err.toString())
          })
      }

      toast.loading('Updating...', { id: 'perm-load' })
      getPermissions
        .mutateAsync(topic.topicID)
        .then((permData) => {
          const { permissionID } = permData
          updatePerm(permissionID)
        })
        .catch((err) => {
          toast.remove('perm-load')
        })
    }
  }

  const onTabSelected = (category: string) => {
    setCurrentTab(category)
    setSelectedAssets({
      ...selectedAssets,
      clicked: false,
    })
    updateRendererComponent()
  }

  // save draft and add screenshot as a note

  const addScreenshot = (file: File) => {
    if (saveMode.current == 1) {
      canvaRef.current.getScreenShotFile(async (file: File) => {
        screenshotFile.current = file
        setShowSaveScreenshotModal(true)
      })
    } else {
      if (topicStateRef.current != null) {
        setTopic({ ...topicStateRef.current })
      }
      if (loading) return
      setLoading(true)
      addPhotoToFirstNote(topicStateRef.current!)
    }
  }

  const deletePhoto = async (egoID: string) => {
    await deleteEgos.mutateAsync([egoID])
  }

  const addPhotoToFirstNote = (obj: Topic) => {
    let { firstNote, topicID } = obj
    uploadScreenShot().then((fileInfo) => {
      if (saveMode.current != 1) {
        if (firstNote) {
          // first check if there was already a screenshot
          if (firstNote.egoList.length) {
            deletePhoto(firstNote.egoList[0].egoID)
          }

          const egoList = [
            {
              sequenceID: '1',
              egoID: fileInfo.egoID,
              photoAlbumURL: fileInfo.egoAlbumURL,
              photoFullURL: fileInfo.egoImageURL,
            },
          ]
          updateTopicNote
            .mutateAsync({
              noteID: firstNote.noteID,
              topicID,
              noteText: firstNote.noteText,
              egoList,
            })
            .then((value) => {
              resetChangesCount()
              let updatedTopic = {
                ...obj,
                firstNote: {
                  ...obj.firstNote,
                  egoList,
                },
              }
              setTopic(updatedTopic)
              if (saveMode.current == 3) {
                goBack()
              }
              // Duplicated, navigate to new topic
              else if (saveMode.current == 4) {
                query.set('topicID', topicID)
                navigate(`${pathname}?${query.toString()}`, { replace: true })
              }

              saveMode.current = 0
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    })
  }

  const handlePatternLoad = (patternProps: PatternProps) => {
    const temp = selectedAssets
    if (patternProps.currentTemplate) temp.template = patternProps.currentTemplate.id
    else temp.template = undefined
    temp.scene = patternProps.currentSceneID
    temp.accent = patternProps.currentAccentID
    temp.boarder.width = patternProps.boarderWidth * 100
    temp.boarder.type = patternProps.boarderType
    temp.clicked = false
    setSelectedAssets(temp)
  }

  const onClickShare = () => {
    setShowShareLink(true)
  }

  const onClickSave = async () => {
    if (loading) return
    setLoading(true)
    saveDraft()
    saveMode.current = 2
    if (canvaRef.current) {
      canvaRef.current.saveScreenShot()
    }
  }
  const toggleFullscreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const onAddTextElement = (type: number) => {
    setCurrentTab('text')
    if (canvaRef.current) canvaRef.current.addText(type)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePatternEdit = (data: Omit<PatternDraft, 'preview'>) => {
    if (!topic) return

    if (draftDataRef.current != null) {
      incrementChangesCount()
    }

    if (templateChanged.current == true) {
      addDraft({
        ...data,
        accentID: undefined,
      })
      templateChanged.current = false
    } else {
      addDraft({
        ...data,
      })
    }
  }

  const onClickTakeScreenShot = () => {
    saveMode.current = 1
    if (canvaRef.current) {
      canvaRef.current.saveScreenShot()
    }
  }

  const updateNote = async (note: Note) => {
    await updateTopicNote.mutateAsync({
      noteID: note.noteID,
      topicID: topicID,
      noteText: note.noteText,
      egoList: note.egoList,
    })
  }

  const getShareLink = () => {
    let searchParam: URLSearchParams = new URLSearchParams()
    searchParam.set('topicID', topic.topicID)
    searchParam.set('topicType', topic.topicType)
    return `${window.location.origin}/preview?${searchParam.toString()}`
  }

  const uploadScreenShot = () => {
    return new Promise<any>((resolve, reject) => {
      if (canvaRef.current) {
        toast.loading('Uploading...', { id: 'upload' })
        canvaRef.current.getScreenShotFile(async (file: File) => {
          const upload = await uploadPhoto.mutateAsync(file)
          toast.dismiss('upload')
          toast.success('Uploaded')
          resolve(upload)
        })
      }
    })
  }

  const onTogglePreview = () => {
    setIsEditMode((prev) => !prev)
    //query.set('mode', mode === 'edit' ? 'preview' : 'edit')
    // navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  if (isError) {
    return (
      <Box display={'flex'} flexDirection='column' alignItems='center' pt={4}>
        <Typography align='center'>Topic not authorized</Typography>
        <Link to={`/`}>
          <Typography variant='label'>Go to Home</Typography>
        </Link>
      </Box>
    )
  }

  const renderSideBar = () => (
    <Sidebar sx={{ justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <RBox gap={2} sx={{ mb: 2 }}>
          <IconButton
            onClick={onBackClick}
            sx={{
              backgroundColor: 'transparent',
              px: 0,
            }}
          >
            <ArrowBackIos sx={{ ml: '8px', color: colors.white }} />
          </IconButton>
          <Box sx={{ flex: 1 }}>
            <ThemeProvider theme={MenuDarkTheme}>
              <ActionMenu
                id='topic-item-menu'
                type={'button'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                parent={
                  <RBox sx={{ flex: 1, justifyContent: 'space-between' }}>
                    <Typography
                      fontWeight={700}
                      fontSize={'20px'}
                      color={'white'}
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        textOverflow: 'ellipsis',
                      }}
                      textAlign={'left'}
                    >
                      {topic.topicName}
                    </Typography>
                    {isMyTopic && <KeyboardArrowDownOutlined sx={{ color: 'white' }} />}
                  </RBox>
                }
                actions={menuItems}
              />
            </ThemeProvider>
          </Box>
          {lessThanSmall && showAssetSelectorDrawer && (
            <RBox sx={{ justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => setShowAssetSelectorDrawer(false)}
                sx={{ padding: 0, ml: 2, maxWidth: '30px' }}
              >
                <SVG src={CloseIconRound} width={30} height={30} />
              </IconButton>
            </RBox>
          )}
        </RBox>

        {isEditMode && (
          <Box flex={1} display={'flex'} alignItems={'center'}>
            <AssetSelector
              selectedAssets={selectedAssets}
              onAssetSelected={onAssetSelected}
              onTemplateChanged={onTemplateChanged}
              onTabSelected={onTabSelected}
              onAddTextElement={onAddTextElement}
              topicType={topic.topicType}
            />
          </Box>
        )}
      </Box>
    </Sidebar>
  )

  return (
    <PatternContainer
      sx={{
        flexDirection: isEditMode ? 'row' : 'column',
      }}
    >
      {!isEditMode && (
        <Box
          sx={{
            position: 'absolute',
            minHeight: '50px',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CBox sx={{ alignItems: 'center', mt: '17px', gap: '4px' }}>
            <Typography variant={'p'} fontWeight={400} fontSize={'13px'} color='white'>
              {TopicTypes[topic.topicType]?.title || 'Unknown'}
            </Typography>
            <Typography
              variant='large'
              color={'white'}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                textOverflow: 'ellipsis',
              }}
              textAlign={'left'}
            >
              {topic.topicName}
            </Typography>
          </CBox>

          <IconButton
            onClick={onTogglePreview}
            sx={{ position: 'absolute', right: '10px', top: '15px' }}
          >
            <SVG src={SVGS.CloseIcon} width={30} height={30} />
          </IconButton>
        </Box>
      )}
      {isEditMode && (
        <>
          {!lessThanSmall ? (
            <Drawer
              sx={{
                width: '330px',
                flexShrink: 0,
                zIndex: 10,
                '& .MuiDrawer-paper': {
                  width: '330px',
                  ml: 0,
                },
              }}
              open={true}
              variant={'permanent'}
              anchor='left'
            >
              {renderSideBar()}
            </Drawer>
          ) : (
            <Drawer
              sx={{
                width: '70%',
                flexShrink: 0,
                zIndex: 10,
                '& .MuiDrawer-paper': {
                  width: '70%',
                  ml: 0,
                  boxSizing: 'border-box',
                },
              }}
              open={showAssetSelectorDrawer}
              variant={lessThanSmall ? 'temporary' : 'permanent'}
              onClose={() => setShowAssetSelectorDrawer(false)}
              anchor='left'
            >
              {renderSideBar()}
            </Drawer>
          )}
        </>
      )}

      {(createTopicNote.isLoading || updateTopicNote.isLoading) && (
        <Box
          position='absolute'
          zIndex={10}
          right={10}
          bottom={10}
          p={1}
          borderRadius={1}
          bgcolor='white'
        >
          <Typography px={1}>Saving data...</Typography>
        </Box>
      )}

      {lessThanSmall && isEditMode && (
        <RBox
          sx={{
            position: 'absolute',
            top: 20,
            left: 20,
            zIndex: 5,
          }}
        >
          <IconButton
            onClick={() => setShowAssetSelectorDrawer(!showAssetSelectorDrawer)}
            sx={{ color: colors.white }}
          >
            <MenuIcon />
          </IconButton>
        </RBox>
      )}

      {/* top bar */}
      {isEditMode && (
        <RBox
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 10,
            gap: '20px',
          }}
        >
          <Badge badgeContent={changesCount} color='error'>
            <RoundedFilledButton onClick={onClickSave} sx={{ ml: '0px', padding: '4px 4px' }}>
              {'Save'}
            </RoundedFilledButton>
          </Badge>

          {isSmallScreen ? (
            <RoundedFilledButton
              sx={{
                padding: '8px 4px',
              }}
              onClick={onClickTakeScreenShot}
            >
              <SVG src={SVGS.TakeScreenshotIcon} />
            </RoundedFilledButton>
          ) : (
            <RoundedFilledButton
              sx={{
                padding: '4px 20px',
              }}
              onClick={onClickTakeScreenShot}
              startIcon={<SVG src={SVGS.TakeScreenshotIcon} />}
            >
              {'Take Screenshot'}
            </RoundedFilledButton>
          )}
        </RBox>
      )}

      {/* pattern preview */}
      <Box display='flex' flex={1} position='relative'>
        <Grid container height={'100%'} position='relative'>
          <Grid item xs={12} md={showInteractions ? 0 : 12} height={'100%'}>
            <Box display='flex' height='100%' position='relative'>
              <PatternPreview
                ref={canvaRef}
                selection={selectedAssets}
                selectedTab={currentTab}
                assets={assets}
                patternData={patternData}
                textData={textData}
                isEditMode={isEditMode}
                addScreenshot={addScreenshot}
                onPatternRestore={handlePatternLoad}
                onAssetsUpdate={onAssetsUpdate}
                onToggleFullScreenMode={toggleFullscreen}
                onEdit={handlePatternEdit}
              />
              {isEditMode && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                  }}
                >
                  <Box
                    display='flex'
                    flex={1}
                    justifyContent='space-between'
                    alignItems={'center'}
                    p={2}
                  >
                    <TagTopic
                      type={`${TopicTypes[topic.topicType]?.title || 'Unknown'}`}
                      textColor='white'
                    />
                    <RBox ml={2} gap={2}>
                      {isMyTopic && (
                        <>
                          {topic.visibility == PrivacyType.PRIVATE.toString() ? (
                            <RoundedFilledButton
                              sx={{
                                padding: '4px 20px',
                              }}
                              onClick={() => {
                                changeVisibility(PrivacyType.PUBLIC)
                              }}
                              startIcon={<SVG src={SVGS.FilterPrivacyPublic} />}
                            >
                              {'Make Public'}
                            </RoundedFilledButton>
                          ) : (
                            <RoundedFilledButton
                              sx={{
                                padding: '4px 20px',
                              }}
                              onClick={() => {
                                changeVisibility(PrivacyType.PRIVATE)
                              }}
                              startIcon={<SVG src={SVGS.FilterPrivacyPrivate} />}
                            >
                              {'Make Private'}
                            </RoundedFilledButton>
                          )}
                        </>
                      )}

                      <IconButton
                        onClick={onTogglePreview}
                        sx={{
                          backgroundColor: theme.palette.brand.light,
                          width: '40px',
                          height: '40px',
                          borderRadius: '40px',
                          '&:hover': {
                            backgroundColor: theme.palette.brand.light,
                          },
                        }}
                      >
                        <SVG src={SVGS.EyeIconWhite} />
                      </IconButton>

                      <IconButton
                        onClick={onClickShare}
                        sx={{
                          backgroundColor: theme.palette.brand.light,
                          width: '40px',
                          height: '40px',
                          borderRadius: '40px',
                          '&:hover': {
                            backgroundColor: theme.palette.brand.light,
                          },
                        }}
                      >
                        <SVG src={SVGS.ShareWhiteIcon} />
                      </IconButton>
                    </RBox>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <RightSideBar className={selectedDisplayRight ? '' : 'hidden'}>
        {selectedDisplayRight && (
          <ButtonClose onClick={() => setSelectedDisplayRight('')}>
            <SVG src={CloseIcon} width={8} height={8} />
          </ButtonClose>
        )}
        {selectedDisplayRight === 'likes' && (
          <Box padding='20px'>
            <Typography fontWeight={700} fontSize='20px' color='#211E1E'>
              Likes ({topic.totalLikes})
            </Typography>
          </Box>
        )}
        {selectedDisplayRight === 'comments' && (
          <Box padding='20px'>
            <Typography fontWeight={700} fontSize='20px' color='#211E1E'>
              Comments ({topic.totalComments})
            </Typography>
          </Box>
        )}
        {selectedDisplayRight === 'shares' && (
          <Box padding='20px'>
            <Typography fontWeight={700} fontSize='20px' color='#211E1E'>
              Shares
            </Typography>
          </Box>
        )}
      </RightSideBar>
      {showShareLink && (
        <Box
          position={'absolute'}
          sx={{ right: 0, width: '400px', bottom: '16px', mx: 1, zIndex: 100 }}
        >
          <ShareLink
            link={getShareLink()}
            onClose={() => {
              setShowShareLink(false)
            }}
          />
        </Box>
      )}

      <RModal
        open={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        title='Delete Pattern?'
        description='Are you sure you want to delete this pattern?'
        submitText='Delete'
        onSubmit={onConfirmDeleteTopic}
      />

      <RModal
        open={duplicateModalOpened}
        onClose={() => setDuplicateModalOpened(false)}
        title='Duplicate Pattern?'
        description='Are you sure you want to create a copy of this pattern?'
        submitText='Duplicate'
        onSubmit={onClickDuplicate}
      />

      <RInputModal
        open={renameModalOpened}
        maxLength={30}
        onClose={() => setRenameModalOpened(false)}
        title='Change Topic Name'
        text={topic.topicName}
        placeholder={'Enter Pattern Description'}
        description=''
        submitText='Save'
        onSubmit={onConfirmRenameTopic}
      />
      {(loading || createTopicNote.isLoading || updateTopicNote.isLoading) && (
        <FullScreenLoadingView />
      )}

      <SaveScreenshotModal
        file={screenshotFile.current!}
        open={showScreenshotModal}
        onClose={() => {
          setShowSaveScreenshotModal(false)
        }}
      />
    </PatternContainer>
  )
}
