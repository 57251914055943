import {
  Box,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slide,
  styled,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import BannerImage from '@/assets/images/hero-mask.png'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import { useNavigate } from 'react-router-dom'
import { useGetTopics } from '@/hooks/api/dashboard'
import { Topic } from '@/types'
import { PreviewNote, TagTopic } from '@/components'
import ReactTimeago from 'react-timeago'
import { PrivacyType, TopicType, TopicTypes } from '@/utils/constants'
import { convertToGMT, isBabylonType } from '@/utils/helper'
import { BackgroundImage, CustomTab } from '../__styled'
import { IMAGES } from '@/assets/images'
import { ViewSidebar as ViewSidebarIcon } from '@mui/icons-material'

import TopicItemsGrid from './molecules/TopicItemsGrid'
import ProfileInfoSideBar from './molecules/ProfileInfoSideBar'
import { TransitionProps } from '@mui/material/transitions'
import colors from '@/styles/colors'
import { ArrowBackIos } from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />
})

const TopBanner = styled(Box)(() => ({
  background: `url(${BannerImage}) center/cover no-repeat, linear-gradient(112.49deg, #1767D3 36.46%, #116FEE 66.46%)`,
  width: '100%',
  borderRadius: '8px',
  display: 'flex',
}))

const TopicItemSmall = styled(Box)(() => ({
  background: 'white',
  borderRadius: '8px',
  padding: '20px',
  cursor: 'pointer',
}))

const tabs: TopicType[] = [
  TopicType.ALL,
  TopicType.PATTERN,
  TopicType.QUILT,
  TopicType.ALBUM,
  TopicType.GALLERY,
]

const Home = () => {
  const getTopics = useGetTopics()
  const navigate = useNavigate()
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const [selectedTopicType, setSelectedTopicType] = useState<TopicType>(TopicType.ALL)
  const [showProfileDrawer, setShowProfileDrawer] = useState(false)

  const [interactionType, topicType, topicList, userProfile] = useAppStore(
    (state) => [
      state.home.interactionFilterType,
      state.home.topicFilterType,
      state.home.topicList,
      state.user.profile,
      state.user,
    ],
    shallow,
  )

  useEffect(() => {
    getTopics.mutate({
      pageNo: 1,
      interaction: 3,
      topicType: 0,
      searchType: 1,
    })
  }, [interactionType, topicType])

  const topics = useMemo(() => {
    const list = Object.values(topicList)
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1
      if (a.dateUpdated < b.dateUpdated) return 1
      return 0
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicList])

  const selectedTabIndex = useMemo(() => {
    return tabs.indexOf(selectedTopicType)
  }, [selectedTopicType])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTopicType(tabs[newValue])
  }

  const onDropdownSelect = (event: SelectChangeEvent) => {
    const value = event.target.value
    const type = TopicTypes[value].type
    setSelectedTopicType(type)
  }

  const onClickTopicItem = (topic: Topic) => {
    onClickTopic(topic.topicID, topic.topicType)
  }

  const onClickTopic = (id: string, type: string) => {
    let previewQuery = new URLSearchParams()
    previewQuery.set('topicID', id)
    previewQuery.set('topicType', type)
    navigate(`/preview?${previewQuery.toString()}`, { replace: true })
  }

  const renderTopicPreviewImage = (topic: {
    topicType: string
    latestNote: { egoList: any }
    firstNote: { egoList: string | any[] }
  }) => {
    const egoList = isBabylonType(topic.topicType)
      ? topic.firstNote.egoList || []
      : topic.latestNote.egoList || []
    if (egoList.length > 0) {
      return (
        <BackgroundImage
          sx={{ width: '100%', borderRadius: '5px' }}
          url={egoList[0].photoAlbumURL}
        />
      )
    }
    return (
      <BackgroundImage
        sx={{ width: '100%', borderRadius: '5px' }}
        url={IMAGES.topicPreviewPlaceholder}
      />
    )
  }

  return (
    <Box
      p={'20px'}
      gap={lessThanMedium ? '8px' : '20px'}
      display={'flex'}
      bgcolor={'#F3F3F3'}
      sx={{ width: '100%', flexDirection: lessThanMedium ? 'column' : 'row' }}
    >
      {lessThanMedium && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => setShowProfileDrawer(true)}>
            <ViewSidebarIcon sx={{ color: colors.themeBlack }} />
          </IconButton>
        </Box>
      )}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'20px'}
        width='100%'
        sx={{ overflowY: 'auto' }}
      >
        <TopBanner>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', p: '50px 50px' }}>
            <Typography fontSize={18} fontWeight={600} color='white'>
              Welcome {userProfile?.firstName}!
            </Typography>
            <Typography mb={'20px'} fontSize={13} fontWeight={500} color='white'>
              On Your Workspace
            </Typography>
            <Typography fontSize={28} fontWeight={600} lineHeight={'33px'} color='white'>
              Unleash Your Creative Potential,
            </Typography>
            <Typography fontSize={28} fontWeight={600} lineHeight={'33px'} color='white'>
              Inspiring Designers to Soar
            </Typography>
          </Box>
        </TopBanner>
        <Box
          sx={{
            display: 'flex',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: 'none',
              flexWrap: 'wrap',
              display: 'flex',
              gap: '16px',
            }}
          >
            {topics &&
              topics
                .sort(
                  (a, b) =>
                    new Date(b.dateLastInteracted).getTime() -
                    new Date(a.dateLastInteracted).getTime(),
                )
                .slice(0, 3)
                .map((item: Topic, index: number) => {
                  const topicData = TopicTypes[Number(item.topicType)]
                  return (
                    <Grid item xs={'auto'} style={{ flexGrow: 1 }} key={`${item.topicID}-${index}`}>
                      <TopicItemSmall
                        display={'flex'}
                        gap={'12px'}
                        onClick={() => onClickTopicItem(item)}
                      >
                        <Box width={'70px'} height={'70px'}>
                          {renderTopicPreviewImage(item)}
                        </Box>
                        <Box>
                          <Box display={'flex'}>
                            <TagTopic type={topicData.key} />
                          </Box>
                          <PreviewNote
                            textOverflow='ellipsis'
                            whiteSpace={'nowrap'}
                            overflow='hidden'
                            note={item.firstNote}
                            titleOnly
                            sx={{
                              fontSize: '13px',
                              fontWeight: 500,
                              lineHeight: '15px',
                              mt: '6px',
                            }}
                          />
                          <Typography
                            variant='p'
                            color={'#525E9C'}
                            mt={'7px'}
                            fontWeight={400}
                            fontSize={'10px'}
                            lineHeight={'12px'}
                          >
                            Last edited <ReactTimeago date={convertToGMT(item.dateUpdated)} />
                          </Typography>
                        </Box>
                      </TopicItemSmall>
                    </Grid>
                  )
                })}
          </Box>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography fontWeight={700} fontSize={'17px'} lineHeight={'20px'} color={'#211E1E'}>
              Recent Creations
            </Typography>
            {lessThanMedium && (
              <Select
                value={`${TopicTypes[selectedTopicType].type}`}
                onChange={onDropdownSelect}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  height: 34,
                  borderRadius: 100,
                  color: '#525E9C',
                  border: '1px solid #E7EDF3',
                  width: '120px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {tabs.map((item, index) => (
                  <MenuItem key={`${index}`} value={`${item}`}>
                    {TopicTypes[item].tabTitle}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>

          <Box bgcolor={'white'} borderRadius={'8px'} mt={'14px'}>
            <Box sx={{ width: '100%' }}>
              {!lessThanMedium && (
                <Box sx={{ px: '20px', borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={selectedTabIndex}
                    onChange={handleTabChange}
                    aria-label='basic tabs example'
                  >
                    {tabs.map((e) => (
                      <CustomTab
                        sx={{ textTransform: 'capitalize' }}
                        label={`${TopicTypes[e].tabTitle}`}
                      />
                    ))}
                  </Tabs>
                </Box>
              )}
              <TopicItemsGrid
                showAuthorForPublic={false}
                topics={topics || []}
                onClickTopicItem={onClickTopicItem}
                onRemoved={(item) => {}}
                filter={{ topicType: selectedTopicType, privacyType: PrivacyType.ALL }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!lessThanMedium && (
        <Box
          maxWidth={'335px'}
          width={'100%'}
          borderRadius={'8px'}
          bgcolor={'white'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{ overflowX: 'hidden', overflowY: 'auto', paddingTop: '40px' }}
        >
          <ProfileInfoSideBar topics={topics} onClickTopicItem={onClickTopicItem} />
        </Box>
      )}

      <Dialog
        open={showProfileDrawer}
        TransitionComponent={Transition}
        onClose={() => setShowProfileDrawer(false)}
        aria-describedby='alert-dialog-slide-description'
        fullWidth
        fullScreen
      >
        <Box bgcolor={'white'} sx={{ flex: 1 }}>
          <IconButton sx={{ m: '16px' }} onClick={() => setShowProfileDrawer(false)}>
            <ArrowBackIos sx={{ color: colors.themeBlack }} />
          </IconButton>
          <ProfileInfoSideBar topics={topics} onClickTopicItem={onClickTopicItem} />
        </Box>
      </Dialog>
    </Box>
  )
}

export default Home
