import { Box, Grid, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { SVGS } from '@/assets/svgs'
import InfoSlideView from './InfoSlideView'
import { RBox } from '../__styled'
import colors from '@/styles/colors'

const SLIDE_DATA = [
  {
    icon: SVGS.TabPattern,
    title: 'Pattern Design',
    description:
      'Pattern - Meshdom provide pattern design tool with many features and facilities. We build tool that sparks creativity and seamless process to design pattern that you are looking for.',
  },
  {
    icon: SVGS.TabQuilt,
    title: 'Quilt Design',
    description:
      'Quilt - Meshdom provide quilt design tool with many features and facilities. We build tool that sparks creativity and seamless process to design pattern that you are looking for.',
  },
  {
    icon: SVGS.TabAlbum,
    title: 'Album Design',
    description:
      'Album - Meshdom provide pattern design tool with many features and facilities. We build tool that sparks creativity and seamless process to design pattern that you are looking for.',
  },
  {
    icon: SVGS.TabGallery,
    title: 'Gallery Design',
    description:
      'Gallery - Meshdom provide pattern design tool with many features and facilities. We build tool that sparks creativity and seamless process to design pattern that you are looking for.',
  },
]

const InfoSliderView = () => {
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPage((prev) => (prev == SLIDE_DATA.length - 1 ? 0 : prev + 1))
    }, 4000) // Adjust the interval time as needed

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '260px',
        backgroundColor: 'black',
        borderRadius: '15px',
        overflowX: 'auto',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',

          top: '24px',
          left: '0px',
          width: '8px',
          height: '57px',
          backgroundColor: colors.meshdomBlue,
          zIndex: 10,
          borderRadius: '0px 8px 8px 0px',
        }}
      />
      <Grid container spacing={2} direction='row' wrap='nowrap'>
        <Grid item sx={{ flex: '0 0 auto', width: '100%' }}>
          <InfoSlideView
            icon={SLIDE_DATA[currentPage].icon}
            title={SLIDE_DATA[currentPage].title}
            description={SLIDE_DATA[currentPage].description}
          />
        </Grid>
      </Grid>

      <RBox sx={{ alignItems: 'center', gap: 1, justifyContent: 'center' }}>
        {SLIDE_DATA.map((item, index) => {
          const selected = index == currentPage
          return (
            <Box
              key={`${index}`}
              sx={{
                borderRadius: '20px',
                width: selected ? '20px' : '8px',
                height: '8px',
                backgroundColor: selected ? colors.meshdomBlue : '#FFFFFF66',
                transition: 'width 0.5s ease',
              }}
            ></Box>
          )
        })}
      </RBox>
    </Box>
  )
}

export default InfoSliderView
