import { CheckBox, Input, NoResults, UserAvatar } from "@/components";
import { useInviteUser } from "@/hooks/api/dashboard";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { validateEmail, validatePhoneNumber } from "@/utils/helper";
import { EmailOutlined, Phone, SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { shallow } from "zustand/shallow";
import { ConnectionsContainer } from "./__styled";

interface Props {
  isMultiple: boolean;
  connections: string[];
  onChangeConnections: (list: string[]) => void;
}

export default function SelectConnections({ isMultiple, connections, onChangeConnections }: Props) {
  const [viewTab, setViewTab] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [editing, setEditing] = useState(false);

  const [myConnections] = useAppStore(state => [state.user.myConnections], shallow)
  const invite = useInviteUser();

  useEffect(() => {
    if (invite.isSuccess) {
      setEmail('');
      setPhoneNumber('');
    }
  }, [invite.isSuccess])

  const formError = useMemo(() => {
    const error: Record<string, string> = {};
    if (email && !validateEmail(email)) {
      error.email = 'Email is invalid';
    } else if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      error.phone = 'Phone number is invalid';
    }
    return error;
  }, [phoneNumber, email])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditing(true);
    setEmail(e.target.value);
  }

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditing(true);
    setPhoneNumber(e.target.value);
  }

  const onClickInvite = () => {
    toast.promise(
      invite.mutateAsync({
        phone: phoneNumber,
        email
      }),
      {
        loading: 'Sending invitation...',
        success: <Typography variant="p">Invitation has been sent.</Typography>,
        error: <Typography variant="p">Failed to invite</Typography>
      }
    )
  }

  const handleCheckBoxChanges = (connID: string) => {
    if (isMultiple) {
      const find = connections.indexOf(connID);
      if (find < 0) connections.push(connID);
      else connections.splice(find, 1);
      onChangeConnections([...connections])
    } else {
      onChangeConnections([connID])
    }
  }

  return (
    <ConnectionsContainer>
      <Box className="connections" width='100%'>
        <Button className={viewTab === 1 ? 'active' : ''} onClick={() => setViewTab(1)}>
          {myConnections.length || 0} Connection{myConnections.length > 1 ? 's' : ''}
        </Button>
        <Button className={viewTab === 2 ? 'active' : ''} onClick={() => setViewTab(2)}>
          Invite Friend
        </Button>
      </Box>
      {viewTab === 1 && (
        <>
          <Box p={2}>
            <Input
              value={searchText}
              placeholder='Search connection'
              onChange={onChangeSearch}
              rightIcon={<SearchOutlined />}
              name="search-connect"
              size="small"
            />
          </Box>
          <Box overflow="auto" p={2} flex={1}>
            {myConnections.length === 0 && (
              <NoResults type="connection" onClick={() => setViewTab(2)} />
            )}
            {myConnections.map(connection => {
              const name = `${connection.firstName} ${connection.lastName}`;
              return (
                <Box
                  key={connection.userID}
                  display={name.toLowerCase().includes(searchText.toLowerCase()) ? 'flex' : 'none'}
                  alignItems={'center'}
                  mb={1}
                >
                  <UserAvatar name={name} url={connection.thumbImgURL} />
                  <Typography
                    variant='label'
                    flex={1}
                    pl={1}
                    textOverflow="ellipsis"
                    whiteSpace={'nowrap'}
                    overflow="hidden">
                    {name}
                  </Typography>
                  <CheckBox
                    checked={connections.includes(connection.userID)}
                    onChange={() => handleCheckBoxChanges(connection.userID)}
                  />
                </Box>
              )
            })}
          </Box>
        </>
      )}
      {viewTab === 2 && (
        <Box p={2}>
          <Box mb={1}>
            <Typography variant="labelSmall" >Invite Via Email</Typography>
          </Box>
          <Input
            value={email}
            placeholder='Enter email address'
            onChange={onChangeEmail}
            errorText={formError.email}
            isError={editing && !!formError.email}
            leftIcon={<EmailOutlined />}
            name="invite-email"
            size="small"
          />
          <Box display="flex" alignItems={'center'} my={2}>
            <Box height={'1px'} flex={1} bgcolor={colors.lightGrey} />
            <Typography variant="ps" bgcolor="white" px={1}>OR</Typography>
            <Box height={'1px'} flex={1} bgcolor={colors.lightGrey} />
          </Box>
          <Box mb={1}>
            <Typography variant="labelSmall" >Invite Via Phone Number</Typography>
          </Box>
          <Input
            value={phoneNumber}
            placeholder='Enter phone number'
            onChange={onChangePhoneNumber}
            errorText={formError.phone}
            isError={editing && !!formError.phone}
            leftIcon={<Phone />}
            name="invite-phone"
            size="small"
          />
          <Box mt={2}>
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={onClickInvite}
              loading={invite.isLoading}
              disabled={!!formError.phone || !!formError.email || (!email && !phoneNumber)}
            >
              Send Invitation
            </LoadingButton>
          </Box>
        </Box>
      )}
    </ConnectionsContainer>
  )
}