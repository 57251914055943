import { UserAvatar } from '@/components'
import { RoundedFilledButton, RoundedOutlineButton } from '@/components/StyledComponents'
import colors from '@/styles/colors'
import { Topic } from '@/types'
import { TopicTypes } from '@/utils/constants'
import { convertToGMT } from '@/utils/helper'
import { ArrowBackIos, EditOutlined, MoreVert } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import { styled, SxProps } from '@mui/system'
import { useLocalStorage } from '@/hooks/common'
import SVG from 'react-inlinesvg'
import { SVGS } from '@/assets/svgs'
import ReactTimeago from 'react-timeago'
import CloseIcon from '@/assets/svgs/close-round.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react'

interface PreviewTopBarProps {
  topic: Topic
  showingDetailsInPanel: boolean
  showTopicInfo: boolean
  onClickMenuIcon: () => void
  onClickBack: () => void
  onClickExitPreview: () => void
  onClickEdit: () => void
  isLiked: boolean
  onClickLike: () => void
  onClickComment: () => void
  onClickPreview: () => void
  onClickLikesCount: () => void
}

export const IconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  pt: '16px',
  pb: '4px',
}))

export const PreviewTopBar = ({
  topic,
  showingDetailsInPanel = false,
  showTopicInfo = true,
  onClickMenuIcon,
  onClickBack,
  onClickExitPreview,
  onClickEdit,
  isLiked,
  onClickLike,
  onClickComment,
  onClickPreview,
  onClickLikesCount,
}: PreviewTopBarProps) => {
  const styles = makeStyles()
  const [ewUser] = useLocalStorage('ewUser', null)
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const totalViews = Number(topic.visitorVisits)
  const totalLikes = Number(topic.totalLikes)
  const totalComments = Number(topic.totalComments)
  const isMyTopic = ewUser?.userID == topic.userID

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <Box p={'20px 20px'} sx={{ backgroundColor: 'transparent' }}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
          <IconButton
            onClick={onClickBack}
            sx={{
              backgroundColor: 'transparent',
              px: 0,
            }}
          >
            <ArrowBackIos sx={{ ml: '8px', color: colors.white }} />
          </IconButton>

          {!showingDetailsInPanel && (
            <>
              {lessThanMedium ? (
                <IconButton onClick={onClickMenuIcon} sx={{ color: 'white' }}>
                  <MenuIcon />
                </IconButton>
              ) : (
                <Box display={'flex'} alignItems={'center'}>
                  <UserAvatar
                    borderRadius={35}
                    name={topic.userName}
                    url={topic.profilePicThumbURL}
                    size={35}
                  />
                  <Box ml={'10px'} sx={{ alignItems: 'center' }}>
                    <Typography
                      fontWeight={600}
                      fontSize={13}
                      lineHeight={'13px'}
                      sx={{ color: 'white' }}
                    >
                      {topic.userName}
                    </Typography>
                    {topic.dateAdded && (
                      <Typography
                        variant='p'
                        fontSize={10}
                        lineHeight={'10px'}
                        mt={'3px'}
                        sx={{ color: 'white' }}
                      >
                        <ReactTimeago date={convertToGMT(topic.dateAdded)} />
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        <>
          {!showingDetailsInPanel && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography
                fontWeight={400}
                fontSize={13}
                lineHeight={'25px'}
                sx={{ color: 'white' }}
              >
                {TopicTypes[topic.topicType].title}
              </Typography>
              <Typography
                fontWeight={700}
                fontSize={20}
                lineHeight={'25px'}
                sx={{ color: 'white' }}
              >
                {topic.topicName}
              </Typography>
            </Box>
          )}

          {/* Interaction buttons for larger screens */}
          <IconsContainer sx={{ display: lessThanMedium ? 'none' : 'flex' }}>
            {!showingDetailsInPanel && (
              <>
                <RoundedOutlineButton
                  onClick={onClickLike}
                  aria-selected={isLiked}
                  sx={styles.outlineButton}
                  startIcon={<SVG src={isLiked ? SVGS.LikeFilledIcon : SVGS.LikeWhiteIcon} />}
                >
                  {`${totalLikes}`}
                </RoundedOutlineButton>
                <RoundedOutlineButton
                  onClick={onClickComment}
                  sx={styles.outlineButton}
                  startIcon={<SVG src={SVGS.CommentWhiteIcon} />}
                >
                  {`${totalComments}`}
                </RoundedOutlineButton>
                <RoundedOutlineButton
                  onClick={() => {}}
                  sx={styles.outlineButton}
                  startIcon={<SVG src={SVGS.EyeIconWhite} />}
                >
                  {`${totalViews}`}
                </RoundedOutlineButton>
                <RoundedOutlineButton
                  onClick={() => {}}
                  sx={styles.outlineButton}
                  startIcon={<SVG src={SVGS.ShareWhiteIcon} />}
                >
                  1
                </RoundedOutlineButton>
              </>
            )}

            {!showingDetailsInPanel && isMyTopic && (
              <RoundedFilledButton sx={{ padding: '8px 20px' }} onClick={onClickEdit}>
                Edit
              </RoundedFilledButton>
            )}

            {showTopicInfo && (
              <IconButton onClick={onClickExitPreview} sx={{ padding: 0, ml: 4, maxWidth: '30px' }}>
                <SVG src={CloseIcon} width={30} height={30} />
              </IconButton>
            )}
          </IconsContainer>

          {/* Interaction menu for smaller screens */}

          {lessThanMedium && (
            <>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MoreVert sx={{ color: 'white' }} />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem
                  sx={{ gap: '4px' }}
                  key={'edit'}
                  onClick={() => {
                    handleCloseNavMenu()
                    onClickEdit()
                  }}
                >
                  <EditOutlined sx={{ width: '20px', height: '20px' }} />
                  {'Edit'}
                </MenuItem>

                <MenuItem
                  key={'like'}
                  onClick={onClickLike}
                  sx={{ gap: '4px', color: isLiked ? colors.themePink : colors.themeBlack }}
                >
                  <SVG src={SVGS.LikeFilledIcon} />
                  {`${totalLikes} Likes`}
                </MenuItem>

                <MenuItem
                  key={'comment'}
                  onClick={() => {
                    handleCloseNavMenu()
                    onClickComment()
                  }}
                  sx={{ gap: '4px' }}
                >
                  <SVG src={SVGS.CommentIcon} />
                  {`${totalLikes} Comments`}
                </MenuItem>

                <MenuItem
                  key={'shares'}
                  onClick={handleCloseNavMenu}
                  sx={{ gap: '4px', color: colors.themeBlack }}
                >
                  <SVG src={SVGS.ShareIcon} />
                  {'1 Shares'}
                </MenuItem>
              </Menu>
            </>
          )}
        </>
      </Box>
    </Box>
  )
}

function makeStyles(): Record<string, SxProps> {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      py: '16px',
      px: '20px',
    },

    interactionBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      py: '12px',
    },

    iconsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      pt: '16px',
      pb: '4px',
    },

    outlineButton: {
      px: '20px',
      py: '6px',
      borderColor: 'white',
      border: `1px solid #FFF`,
      color: 'white',
    },

    row: { display: 'flex', flexDirection: 'row' },

    viewsDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
    },
  }
}
