/* eslint-disable @typescript-eslint/no-explicit-any */
import { IHomeSlice, IHomeStore, Topic } from '@/types'

const initialState: IHomeStore = {
  interactionFilterType: 3,
  topicFilterType: 0,
  topicList: {},
  topicSelectedAt: 0,
  isPrivacySelected: false,
  sidebarCollapsed: false,
}

export const createHomeSlice = (set: any): IHomeSlice => ({
  home: initialState,
  setInteractionType: (type: number) =>
    set((state: any) => ({
      home: {
        ...state.home,
        interactionFilterType: type,
      },
    })),
  setTopicType: (type: number) =>
    set((state: any) => ({
      home: {
        ...state.home,
        topicFilterType: type,
      },
    })),
  updateTopicList: (data: Record<string, Topic>) =>
    set((state: any) => {
      const prev = state.home.topicList
      Object.keys(data).forEach((key) => {
        prev[key] = {
          ...(prev[key] || {}),
          ...data[key],
        }
      })
      return {
        home: {
          ...state.home,
          topicList: { ...prev },
        },
      }
    }),

  setTopicList: (data: Record<string, Topic>) =>
    set((state: any) => {
      return {
        home: {
          ...state.home,
          topicList: { ...data },
        },
      }
    }),
  resetTopicList: () =>
    set((state: any) => ({
      home: {
        ...state.home,
        topicSearchResult: {},
      },
    })),
  removeTopic: (topicID: string) =>
    set((state: any) => {
      delete state.home.topicList[topicID]
      return {
        home: {
          ...state.home,
          topicList: { ...state.home.topicList },
        },
      }
    }),
  updateTopicSelectedAt: () =>
    set((state: any) => ({
      home: {
        ...state.home,
        topicSelectedAt: new Date().getTime(),
      },
    })),
  setIsPrivacySelected: (value: boolean) =>
    set((state: any) => ({
      home: {
        ...state.home,
        isPrivacySelected: value,
      },
    })),
  setSidebarCollapsed: (value: boolean) =>
    set((state: any) => ({
      home: {
        ...state.home,
        sidebarCollapsed: value,
      },
    })),
})

export default createHomeSlice
