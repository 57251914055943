import React from 'react'
import './style.css'
import './bootstrap.min.css'
import { SVGS } from '@/assets/svgs'
import SVG from 'react-inlinesvg'

function Footer() {
  return (
    <div className='text-dark bg-white footer'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-lg-3 col-md-6'>
            <h5 className='mb-4'>Quick Links</h5>
            <a className='btn btn-link' href=''>
              Home
            </a>
            <a className='btn btn-link' href=''>
              Pricing Plans
            </a>
            <a className='btn btn-link' href=''>
              Terms & Privacy
            </a>
          </div>
          <div className='col-lg-3 col-md-6'>
            <h5 className='mb-4'>Explore</h5>
            <a className='btn btn-link' href=''>
              Patterns
            </a>
            <a className='btn btn-link' href=''>
              Quilts
            </a>
            <a className='btn btn-link' href=''>
              Gallery
            </a>
          </div>

          <div className='col-lg-3 col-md-6'>
            <h5 className='mb-4'>Community</h5>
            <a className='btn btn-link' href=''>
              Support
            </a>
            <a className='btn btn-link' href=''>
              Help Center
            </a>
            <a className='btn btn-link' href=''>
              Frequently Asked Questions
            </a>
          </div>

          <div className='col-lg-3 col-md-6'>
            <h5 className='mb-4'>Company</h5>
            <a className='btn btn-link' href=''>
              About Us
            </a>
            <a className='btn btn-link' href=''>
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='copyright'>
          <div className='row justify-content-between'>
            <div className='col-md-6 text-center text-md-start mb-3 mb-md-0'>
              &copy; <a className='border-bottom' href='#'></a>
              {new Date().getFullYear()} All Rights Reserved, MeshDom
            </div>
            <div className='col-md-6 text-md-end mb-3 mb-md-0 d-social'>
              <a href='#'>
                <SVG src={SVGS.SocialFacebook} color='white' />
              </a>
              <a href='#'>
                <SVG src={SVGS.SocialTwitter} color='white' />
              </a>
              <a href='#'>
                <SVG src={SVGS.SocialYoutube} color='white' />
              </a>
              <a href='#'>
                <SVG src={SVGS.SocialInstagram} color='white' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
