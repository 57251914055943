import { NoResults, UserAvatar } from "@/components";
import useAppStore from "@/store/useAppStore";
import colors from "@/styles/colors";
import { PrivacyTypes, TopicTypes } from "@/utils/constants";
import { SortOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import SharedFilter from "./Filter";
import ShareConnectionSearchResult from "./Results";

interface Props {
  onClickTopic: (id: string) => void;
  onClickUser: (id: string) => void;
}

export default function ShareConnections({ onClickTopic, onClickUser }: Props) {
  const [filterShown, setFilterShown] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState('');

  const { palette } = useTheme();
  const { setInteractionType, setTopicType } = useAppStore();
  const [interactionType, topicType, myConnections] = useAppStore(
    state => [
      state.home.interactionFilterType,
      state.home.topicFilterType,
      state.user.myConnections,
    ],
    shallow
  )

  useEffect(() => {
    if (myConnections?.length > 0) {
      setSelectedUserID(myConnections[0].userID);
    }
  }, [myConnections])

  useEffect(() => {
    if (selectedUserID) {
      onClickUser(selectedUserID);
    }
  }, [selectedUserID])

  const onClickFilter = () => {
    setFilterShown(true);
  }

  const onApplyFilters = (interactionType: number, topicType: number) => {
    setInteractionType(interactionType);
    setTopicType(topicType);
    setFilterShown(false);
  }

  const onCloseFilter = () => {
    setFilterShown(false);
  }

  const onClickResetFilter = () => {
    setInteractionType(0);
    setTopicType(0);
  }



  return (
    <Box height="100%" display="flex" flexDirection={'column'}>
      <Box display={'flex'} alignItems='center' justifyContent={'center'} p={2} bgcolor={`${colors.blue}25`}>
        <Typography variant="labelSmall">Connections that are active with you</Typography>
      </Box>
      <Box display="flex" overflow="auto" px={1} py={2} borderBottom={`1px solid ${palette.grey[800]}`}>
        {myConnections.length === 0 && (
          <NoResults type="connection" />
        )}
        {myConnections.map(conn => {
          const selected = selectedUserID === conn.userID;
          return (
            <Box
              key={conn.userID}
              p={1}
              display="flex"
              flexDirection={'column'}
              alignItems="center"
              border={`${selected ? 1 : 0}px solid ${colors.yellow}`}
              borderRadius={2}
            >
              <UserAvatar
                url={conn.thumbImgURL}
                name={`${conn.firstName} ${conn.lastName}`}
                size={66}
                onClick={() => setSelectedUserID(conn.userID)}
              />
              <Typography textAlign={'center'}>{conn.firstName}</Typography>
            </Box>
          )
        })}
      </Box>
      {selectedUserID && (
        <>
          <Box
            display="flex"
            alignItems={'center'}
            justifyContent={'space-between'}
            px={2}
            py={1}
            borderBottom={`1px solid ${palette.grey[800]}`}
            bgcolor="white"
            zIndex={10}
          >
            <Typography variant='h3' flex={1}>{PrivacyTypes[interactionType]?.label}, {TopicTypes[topicType]?.title}</Typography>
            {(interactionType > 0 || topicType > 0) && (
              <Button variant="text" onClick={onClickResetFilter}>
                <Typography variant='pt' color={'brand.light'}>Clear Filter</Typography>
              </Button>
            )}
            <IconButton size={'medium'} onClick={onClickFilter}>
              <SortOutlined sx={{ color: palette.brand.main }} />
            </IconButton>
          </Box>
          <ShareConnectionSearchResult
            onClickTopic={onClickTopic}
            selectedUserID={selectedUserID}
          />
        </>
      )}
      {filterShown && (
        <SharedFilter
          onApply={onApplyFilters}
          onClose={onCloseFilter}
          interaction={interactionType}
          topic={topicType}
        />
      )}
    </Box>
  )
}