import { useBrowserQuery } from '@/hooks/common';
import useAppStore from '@/store/useAppStore';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import TopicDetails from '../panels/details';
import TopicInteractions from '../panels/interactions';
import { Panel2, Panel3, Panel4 } from '../__styled';
import MyConnections from './search';


export default function ConnectionsPage() {
  const [showDetails, setShowDetails] = useState(false);
  const [showInteractions, setShowInteractions] = useState(false);

  const [myDMConnections] = useAppStore(state => [state.user.myDMConnections], shallow);
  const query = useBrowserQuery();
  const dmTopicID = query.get("dmTopicID");
  const noteID = query.get("noteID");

  const onClickTopic = () => {
    setShowDetails(true);
  }

  const onClickBack = () => {
    setShowDetails(false);
  }

  const onShowInteractions = () => {
    setShowInteractions(true);
  }

  const onClickCloseInteractions = () => {
    setShowInteractions(false);
  }

  const onDeletedTopic = () => {
  }

  return (
    <Grid container height={'100%'} position="relative">
      <Panel2 item xs={12} md={4} lg={3}>
        <MyConnections onClickTopic={onClickTopic} />
      </Panel2>
      <Panel3 item xs={12} md={8} lg={6} view={showDetails}>
        {myDMConnections.length > 0 && dmTopicID && (
          <TopicDetails
            onClickBack={onClickBack}
            onShowInteractions={onShowInteractions}
            onDeletedTopic={onDeletedTopic}
          />
        )}
      </Panel3>
      <Panel4 item xs={10} md={6} lg={3} view={showInteractions}>
        {noteID && <TopicInteractions onClickClose={onClickCloseInteractions} />}
      </Panel4>
    </Grid>
  )
}