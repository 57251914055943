import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'

interface MenuAction {
  name?: string
  onClick?: () => void
  icon?: React.ReactNode
  help?: string
  disabled?: boolean
  hidden?: boolean
}

interface Props extends Omit<MenuProps, 'open'> {
  id: string
  parent: React.ReactNode
  type?: 'button' | 'icon'
  actions: MenuAction[]
  direction?: 'row' | 'column'
}

const MenuContainer = styled(Menu)({
  '& li': {
    padding: '5px',
  },
})

export function ActionMenu({
  id,
  parent,
  type = 'icon',
  actions,
  direction = 'column',
  ...props
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buttonRef = useRef<any>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickActionItem = (action: MenuAction) => (event: any) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (action.onClick) action.onClick()
  }

  return (
    <>
      {type === 'button' && (
        <Button ref={buttonRef} size='medium' onClick={handleClick} fullWidth sx={{ padding: 0 }}>
          {parent}
        </Button>
      )}
      {type === 'icon' && (
        <IconButton className='action-button' size='medium' onClick={handleClick}>
          {parent}
        </IconButton>
      )}
      <MenuContainer
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& ul': {
            padding: 0,
            display: 'flex',
            flexDirection: direction,
            alignItems: direction === 'row' ? 'center' : 'flex-start',
            minHeight: 40,
          },
          '& .MuiPaper-root': {
            width: 'auto',
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        {...props}
        open={open}
      >
        {actions.map((action: MenuAction, index: number) => {
          if (action.hidden) return null
          return (
            <Tooltip
              arrow
              title={action.help}
              disableHoverListener={!action.help}
              placement={'top'}
              key={index}
            >
              <MenuItem onClick={onClickActionItem(action)} sx={{ width: '100%' }}>
                <Box display='flex' alignItems='center' sx={{ p: '4px', gap: '8px' }}>
                  {action.icon && (
                    <Box width={24} display='flex' alignItems='center' justifyContent={'center'}>
                      {action.icon && action.icon}
                    </Box>
                  )}

                  {action.name && <Typography variant='labelLarge'>{action.name}</Typography>}
                </Box>
              </MenuItem>
            </Tooltip>
          )
        })}
      </MenuContainer>
    </>
  )
}
