import { IMAGES } from '@/assets/images'
import { ImageList, PreviewNote, TagTopic, UserAvatar } from '@/components'
import AlbumCollage from '@/components/AlbumCollage/AlbumCollage'

import { useDeleteTopic } from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import { BackgroundImage } from '@/pages/__styled'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Topic } from '@/types'
import { PrivacyType, PrivacyTypes, TopicType, TopicTypes } from '@/utils/constants'
import { convertToGMT, getTopicStates, isBabylonType } from '@/utils/helper'
import { Box, capitalize, Container, styled, Typography } from '@mui/material'
import { number } from 'prop-types'
import { useEffect } from 'react'
import SVG from 'react-inlinesvg'
import ReactTimeago from 'react-timeago'

interface ItemProps {
  topic: Topic
  showAuthor?: boolean
  showAuthorForPublicOnly?: boolean
  onClick: () => void
  onRemoved?: () => void
}

export default function TopicItem({
  topic,
  onClick,
  onRemoved,
  showAuthor = false,
  showAuthorForPublicOnly = false,
}: ItemProps) {
  const { removeTopic } = useAppStore()

  const deleteTopic = useDeleteTopic(topic.topicID)
  const [ewUser] = useLocalStorage('ewUser', null)
  const query = useBrowserQuery()
  const topicID = query.get('topicID') || ''

  useEffect(() => {
    if (deleteTopic.isSuccess) {
      removeTopic(topic.topicID)
      onRemoved && onRemoved()
    }
  }, [deleteTopic.isSuccess])

  const topicTypeInfo = TopicTypes[Number(topic.topicType)]
  const privacyData = PrivacyTypes[Number(topic.visibility)]
  if (!topicTypeInfo || !privacyData) return null

  const renderTopicPreviewImage = () => {
    const links = (topic.latestNote?.egoList || []).map((e) => e.photoAlbumURL)

    if (!isBabylonType(topic.topicType)) {
      return (
        <Box sx={{ borderRadius: 2, mb: 1 }}>
          {links?.length > 0 ? (
            <AlbumCollage
              rowHeight={links.length <= 2 ? 200 : 0.5 * 200}
              links={links}
              applyLimit
              limit={5}
            />
          ) : (
            <BackgroundImage
              sx={{ borderRadius: 2, height: '200px' }}
              url={IMAGES.topicPreviewPlaceholder}
            />
          )}
        </Box>
      )
    } else if (isBabylonType(topic.topicType)) {
      return (
        <Box sx={{ borderRadius: 2, mb: 1 }}>
          <BackgroundImage
            sx={{ borderRadius: 2, height: '200px' }}
            url={
              topic.firstNote?.egoList.length
                ? topic.firstNote.egoList[0].photoAlbumURL
                : IMAGES.topicPreviewPlaceholder
            }
          />
        </Box>
      )
    }
    return (
      <Box sx={{ borderRadius: 2, mb: 1, height: '200px' }}>
        <BackgroundImage sx={{ borderRadius: 2 }} url={IMAGES.topicPreviewPlaceholder} />
      </Box>
    )
  }

  return (
    <Box
      p={2}
      border={`1px solid ${topicID === topic.topicID ? '#CCD4DC' : colors.cardBorderColor}`}
      borderRadius={2}
      boxShadow={topicID === topic.topicID ? '0 5px 15px 0px #0000000F' : 'none'}
      boxSizing={'border-box'}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        width: `${330}px`,
        minHeight: '300px',
        '&:hover': {
          boxShadow: '0 5px 15px 0px #0000000F',
          border: `1px solid #CCD4DC`,
        },
      }}
    >
      <Box flex={1}>{renderTopicPreviewImage()}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {(showAuthor ||
          (showAuthorForPublicOnly && topic.visibility == PrivacyType.PUBLIC.toString())) && (
          <Box mt={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <UserAvatar
              borderRadius={50}
              name={topic.userName}
              url={topic.profilePicThumbURL}
              size={28}
            />
            <Typography
              fontSize={'12px'}
              fontWeight={400}
              marginLeft={1}
              color={colors.themePurpleTextColor}
            >
              {ewUser?.userID === topic.userID ? 'You' : topic.userName?.split(' ')[0]}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display='flex' alignItems='center' mt={'11px'}>
        <PreviewNote
          variant='h2'
          mr={1}
          flex={1}
          textOverflow='ellipsis'
          whiteSpace={'nowrap'}
          overflow='hidden'
          note={topic.firstNote}
          titleOnly
          showDateTime={true}
        />
      </Box>
      {/* {topic.topicType == TopicType.ALBUM.toString() && (
        <Box flex={1} display='flex' alignItems='center' mt={'6px'} gap={1}>
          <Typography fontWeight={400} fontSize={10} lineHeight={'13px'} sx={{ color: '#525E9C' }}>
            Added <ReactTimeago date={convertToGMT(topic.dateCreated)} />
          </Typography>

          <Typography fontWeight={400} fontSize={16} lineHeight={'16px'} sx={{ color: '#525E9C' }}>
            {`•`}
          </Typography>

          <Typography fontWeight={400} fontSize={10} lineHeight={'13px'} sx={{ color: '#525E9C' }}>
            {`${Number(topic.noteCount) - 1} ${Number(topic.noteCount) - 1 == 1 ? 'photo' : 'photos'}`}
          </Typography>
        </Box>
      )} */}

      <Box display='flex' alignItems='center' justifyContent={'space-between'} mt={'10px'} gap={1}>
        <TagTopic type={capitalize(topicTypeInfo.title)} />

        {topic.visibility == PrivacyType.PUBLIC.toString() && !showAuthorForPublicOnly && (
          <TagTopic type={'Public'} />
        )}

        {topic.visibility == PrivacyType.PRIVATE.toString() && !showAuthorForPublicOnly && (
          <TagTopic type={'Private'} />
        )}

        <Box flex={1} display='flex' alignItems='center' justifyContent='flex-end' gap='15px'>
          {getTopicStates(topic)
            .filter((item) => item.key === 'visit' || item.key === 'like')
            .map((item) => {
              return (
                <Box display='flex' alignItems='center' key={item.key}>
                  <SVG src={item.icon} width={14} height={14} color={colors.darkGrey} />
                  <Typography
                    variant='labelSmall'
                    sx={{ marginLeft: 1, color: colors.themePurpleTextColor }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              )
            })}
        </Box>
      </Box>

      {/* <RModal
        open={modalOpened}
        onClose={onCloseModal}
        title='Delete Topic'
        description='Are you sure you want to delete this topic?'
        submitText='Delete'
        onSubmit={onClickDelete}
      /> */}
    </Box>
  )
}
