/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

interface Params {
  topicID: string
  noteID: string
  comment: string
}

export const userAddNoteComment = () => {
  const axios = useContext(AxiosContext)
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)

  const addNoteComment = (params: Params) => {
    const topic = topicList[params.topicID]
    const commentsCount = `${Number(topic.totalComments) + 1}`

    if (topic) {
      const updatedNotes = topic.notes?.map((i) => {
        if (i.noteID == params.noteID) {
          return {
            ...i,
            comments: i.comments + 1,
          }
        } else {
          return i
        }
      })

      updateTopicList({
        [params.topicID]: {
          ...topicList[params.topicID],
          notes: updatedNotes,
          totalComments: commentsCount,
        },
      })
    }
  }

  return useMutation({
    mutationKey: [ReactQueryKeys.INTERACT_ALBUM],
    mutationFn: async (params: Params) => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteInteract',
        interactionType: '2',
        interaction: '0',
        ...params,
        format: 'json',
      })
      addNoteComment(params)
      return response.data
    },
  })
}
