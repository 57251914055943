import { Input } from "@/components";
import colors from "@/styles/colors";
import { CreatePrivacyTypes } from "@/utils/constants";
import { ArrowForward, Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import SVG from 'react-inlinesvg';

interface Props {
  title: string
  onChangeTitle: (title: string) => void;
  privacyType: number;
  hasBack?: boolean;
  onClickBack?: () => void;
  onChangePrivacy: (privacy: number) => void;
}

export default function SelectTitlePrivacy({ title, onChangeTitle, privacyType, hasBack, onClickBack, onChangePrivacy }: Props) {
  const onSelectType = (type: number) => {
    onChangePrivacy(type);
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeTitle(e.target.value);
  }

  return (
    <Box borderRadius={2} height="100%" overflow="hidden" bgcolor={'white'}>
      {hasBack && (
        <Box px={1} pt={1} className="close-privacy">
          <IconButton sx={{ color: colors.text }} onClick={onClickBack}>
            <Close />
          </IconButton>
        </Box>
      )}
      <Box p={2} bgcolor="white">
        <Typography variant="p" fontFamily={'Inter-SemiBold'} mb={1}>Topic Title*</Typography>
        <Input placeholder="Enter topic title" value={title} onChange={handleTitleChange} />
      </Box>
      <Box p={2} >
        <Typography variant="h2">Who can see the content of your post?</Typography>
        <Typography variant="ps">Choose from the privacy levels below to allow only those you choose to see this content</Typography>
        {CreatePrivacyTypes.map(privacy => {
          const selected = privacyType === privacy.type
          return (
            <Box
              key={privacy.type}
              p={2}
              borderRadius={2}
              border={`2px solid ${selected ? colors.yellow : colors.lightGrey}`}
              display="flex"
              alignItems={'center'}
              mt={2}
              onClick={() => onSelectType(privacy.type)}
              sx={{ cursor: 'pointer', background: selected ? `linear-gradient(to right, ${colors.yellow}88, white)` : 'none' }}
            >
              <SVG src={privacy.icon} width={16} height={16} />
              <Box pl={1} flex={1}>
                <Typography variant="h3">{privacy.label}</Typography>
                <Typography variant="ps">{privacy.description}</Typography>
              </Box>
              {selected && <ArrowForward sx={{ color: colors.yellow }} />}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}