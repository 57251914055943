/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { useLocalStorage } from '@/hooks/common'

export const useUploadMedia = () => {
  const [basicInfo] = useLocalStorage('ewBasicInfo', null)
  const containerID = basicInfo.mobileContID
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.UPLOAD_MEDIA, containerID],
    mutationFn: async (image: string | Blob) => {
      const formData = new FormData()
      formData.append('egowallFileUpload', image)
      formData.append('fileUploadName', 'egowallFileUpload')
      formData.append('fileType', '1')
      formData.append('uploadContainerID', containerID)
      formData.append('format', 'json')
      const response = await axios.api.post('ego/upload-object', formData)
      return response.data.fileInfo
    },
  })
}
