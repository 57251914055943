import { createTheme } from '@mui/material'
import colors from './colors'

export const MenuDarkTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#474A4F', // Menu background color
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff', // Menu item text color
          '&:hover': {
            backgroundColor: colors.meshdomBlue, // Menu item hover background color
          },
        },
      },
    },
  },
})
