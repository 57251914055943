import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IAppSlice, IEditSlice, IHomeSlice, IPatternSlice, ISearchSlice, IUserSlice } from '@/types'
import {
  createAppSlice,
  createHomeSlice,
  createUserSlice,
  createEditSlice,
  createSearchSlice,
  createPatternSlice,
} from './slices'

type AppSlice = IAppSlice & IHomeSlice & IUserSlice & IEditSlice & ISearchSlice & IPatternSlice

export const useAppStore = create<AppSlice, [['zustand/persist', AppSlice]]>(
  persist(
    (set) => ({
      ...createAppSlice(set),
      ...createHomeSlice(set),
      ...createUserSlice(set),
      ...createEditSlice(set),
      ...createSearchSlice(set),
      ...createPatternSlice(set),
    }),
    {
      name: 'appStore',
    },
  ),
)

export default useAppStore
