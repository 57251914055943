const rowHeight = 140
export const COLLAGE_ITEMS_LIMIT = 5

interface LayoutConfigType {
  direction: string
  matrix: number[]
  heightMultiplier: number
}

export const layoutConfigMap: Record<number, LayoutConfigType> = {
  1: { direction: 'row', matrix: [1], heightMultiplier: 1 },
  2: { direction: 'row', matrix: [1, 1], heightMultiplier: 1 },
  3: { direction: 'row', matrix: [1, 2], heightMultiplier: 2 },
  4: { direction: 'row', matrix: [2, 2], heightMultiplier: 2 },
  5: { direction: 'column', matrix: [2, 3], heightMultiplier: 2 },
  6: { direction: 'column', matrix: [3, 3], heightMultiplier: 2 },
  7: { direction: 'column', matrix: [1, 3, 3], heightMultiplier: 3 },
  8: { direction: 'column', matrix: [2, 3, 3], heightMultiplier: 3 },
  9: { direction: 'column', matrix: [3, 3, 3], heightMultiplier: 3 },
  10: {
    direction: 'column',
    matrix: [1, 2, 3, 4],
    heightMultiplier: 0.7 * 4,
  },
  11: {
    direction: 'column',
    matrix: [2, 3, 3, 3],
    heightMultiplier: 0.7 * 4,
  },
  12: {
    direction: 'column',
    matrix: [3, 3, 3, 3],
    heightMultiplier: 0.7 * 4,
  },
  13: {
    direction: 'column',
    matrix: [1, 4, 4, 4],
    heightMultiplier: 0.7 * 4,
  },
  14: {
    direction: 'column',
    matrix: [2, 4, 4, 4],
    heightMultiplier: 0.7 * 4,
  },

  15: {
    direction: 'column',
    matrix: [3, 4, 4, 4],
    heightMultiplier: 0.7 * 4,
  },

  16: {
    direction: 'column',
    matrix: [4, 4, 4, 4],
    heightMultiplier: 0.7 * 4,
  },

  17: {
    direction: 'column',
    matrix: [2, 3, 4, 4, 4],
    heightMultiplier: 0.7 * 5,
  },

  18: {
    direction: 'column',
    matrix: [2, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 5,
  },

  19: {
    direction: 'column',
    matrix: [3, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 5,
  },
  20: {
    direction: 'column',
    matrix: [4, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 5,
  },
  21: {
    direction: 'column',
    matrix: [1, 4, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 6,
  },
  22: {
    direction: 'column',
    matrix: [2, 4, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 6,
  },
  23: {
    direction: 'column',
    matrix: [3, 4, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 6,
  },
  24: {
    direction: 'column',
    matrix: [4, 4, 4, 4, 4, 4],
    heightMultiplier: 0.7 * 6,
  },
  25: {
    direction: 'column',
    matrix: [2, 3, 4, 4, 4, 4, 4],
    heightMultiplier: 0.5 * 7,
  },
}
