import { IPatternAssets, ITemplateSet } from '@/types'

export const TemplateSets: ITemplateSet = {
  GalleryTemplates: [
    {
      name: '1x1 Square',
      thumb: 'templates/thumbnails/Icon_Template_001.png',
      url: 'templates/meshes/Template_001.babylon',
      id: 2,
    },
    {
      name: '2x2 Square',
      thumb: 'templates/thumbnails/Icon_Template_002.png',
      url: 'templates/meshes/Template_002.babylon',
      id: 4,
    },

    {
      name: '3x3 Square',
      thumb: 'templates/thumbnails/Icon_Template_003.png',
      url: 'templates/meshes/Template_003.babylon',
      id: 6,
    },
    {
      name: '4x4 Square',
      thumb: 'templates/thumbnails/Icon_Template_004.png',
      url: 'templates/meshes/Template_004.babylon',
      id: 8,
    },
  ],
  PatternTemplates: [
    {
      name: '1x1 Half 1',
      thumb: 'templates/thumbnails/Icon_Template_011.png',
      url: 'templates/meshes/Template_011.babylon',
      id: 0,
    },
    {
      name: '1x1 Half 2',
      thumb: 'templates/thumbnails/Icon_Template_012.png',
      url: 'templates/meshes/Template_012.babylon',
      id: 1,
    },
    {
      name: '1x1 Square',
      thumb: 'templates/thumbnails/Icon_Template_001.png',
      url: 'templates/meshes/Template_001.babylon',
      id: 2,
    },
    {
      name: '1x1',
      thumb: 'templates/thumbnails/Icon_Template_013.png',
      url: 'templates/meshes/Template_013.babylon',
      id: 3,
    },
    {
      name: '2x2 Square',
      thumb: 'templates/thumbnails/Icon_Template_002.png',
      url: 'templates/meshes/Template_002.babylon',
      id: 4,
    },
    {
      name: '2x2',
      thumb: 'templates/thumbnails/Icon_Template_014.png',
      url: 'templates/meshes/Template_014.babylon',
      id: 5,
    },
    {
      name: '3x3 Square',
      thumb: 'templates/thumbnails/Icon_Template_003.png',
      url: 'templates/meshes/Template_003.babylon',
      id: 6,
    },
    {
      name: '3x3',
      thumb: 'templates/thumbnails/Icon_Template_015.png',
      url: 'templates/meshes/Template_015.babylon',
      id: 7,
    },
    {
      name: '4x4 Square',
      thumb: 'templates/thumbnails/Icon_Template_004.png',
      url: 'templates/meshes/Template_004.babylon',
      id: 8,
    },
    {
      name: '4x4',
      thumb: 'templates/thumbnails/Icon_Template_016.png',
      url: 'templates/meshes/Template_016.babylon',
      id: 9,
    },
    {
      name: '5x5 Square',
      thumb: 'templates/thumbnails/Icon_Template_005.png',
      url: 'templates/meshes/Template_005.babylon',
      id: 10,
    },
    {
      name: '5x5',
      thumb: 'templates/thumbnails/Icon_Template_017.png',
      url: 'templates/meshes/Template_017.babylon',
      id: 11,
    },
    {
      name: '6x6 Square',
      thumb: 'templates/thumbnails/Icon_Template_006.png',
      url: 'templates/meshes/Template_006.babylon',
      id: 12,
    },
    {
      name: 'Windmill',
      thumb: 'templates/thumbnails/Icon_Template_031.png',
      url: 'templates/meshes/Template_031.babylon',
      id: 13,
      tiling: 0.25,
    },
    {
      name: "Washington's Puzzle",
      thumb: 'templates/thumbnails/Icon_Template_030.png',
      url: 'templates/meshes/Template_030.babylon',
      id: 14,
      tiling: 0.25,
    },
    {
      name: 'Vortex',
      thumb: 'templates/thumbnails/Icon_Template_029.png',
      url: 'templates/meshes/Template_029.babylon',
      id: 15,
      tiling: 0.25,
    },
    {
      name: 'Triangles 2',
      thumb: 'templates/thumbnails/Icon_Template_021.png',
      url: 'templates/meshes/Template_021.babylon',
      id: 16,
      tiling: 1.25,
      materialTiling: 0.2,
      accentTiling: 2,
    },
    {
      name: 'Triangles 1',
      thumb: 'templates/thumbnails/Icon_Template_020.png',
      url: 'templates/meshes/Template_020.babylon',
      id: 17,
      tiling: 1.25,
      materialTiling: 0.2,
      accentTiling: 2,
    },
    {
      name: 'Interlocked Squares 1',
      thumb: 'templates/thumbnails/Icon_Template_028.png',
      url: 'templates/meshes/Template_028.babylon',
      id: 18,
      tiling: 0.25,
    },
    {
      name: 'Diamonds 2',
      thumb: 'templates/thumbnails/Icon_Template_019.png',
      url: 'templates/meshes/Template_019.babylon',
      id: 19,
      tiling: 1.25,
      materialTiling: 0.2,
      accentTiling: 2,
    },
    {
      name: 'Diamonds 1',
      thumb: 'templates/thumbnails/Icon_Template_018.png',
      url: 'templates/meshes/Template_018.babylon',
      id: 20,
      tiling: 1.25,
      materialTiling: 0.2,
      accentTiling: 2,
    },
    {
      name: "Cleopatra's Fan",
      thumb: 'templates/thumbnails/Icon_Template_027.png',
      url: 'templates/meshes/Template_027.babylon',
      id: 21,
      tiling: 0.1,
    },
    {
      name: 'Arabic Lattice',
      thumb: 'templates/thumbnails/Icon_Template_026.png',
      url: 'templates/meshes/Template_026.babylon',
      id: 22,
      uOffset: 0.5,
      vOffset: 0.5,
    },
    {
      name: 'Abstract Triangles 2',
      thumb: 'templates/thumbnails/Icon_Template_025.png',
      url: 'templates/meshes/Template_025.babylon',
      id: 23,
      tiling: 0.5,
      uOffset: 0.5,
      vOffset: 0.5,
      accentTiling: 4,
    },
    {
      name: 'Abstract Triangles 1',
      thumb: 'templates/thumbnails/Icon_Template_024.png',
      url: 'templates/meshes/Template_024.babylon',
      id: 24,
      tiling: 0.25,
    },
    {
      name: '10x10 Square',
      thumb: 'templates/thumbnails/Icon_Template_010.png',
      url: 'templates/meshes/Template_010.babylon',
      id: 25,
      tiling: 0.25,
      materialTiling: 0.2,
      accentTiling: 0.4,
    },
    {
      name: '9x9 Square',
      thumb: 'templates/thumbnails/Icon_Template_009.png',
      url: 'templates/meshes/Template_009.babylon',
      id: 26,
      tiling: 0.2778,
      materialTiling: 0.2,
      accentTiling: 0.44,
    },
    {
      name: 'Pinwheel 1',
      thumb: 'templates/thumbnails/Icon_Template_041.png',
      url: 'templates/meshes/Template_041.babylon',
      id: 27,
    },
    {
      name: '8x8',
      thumb: 'templates/thumbnails/Icon_Template_023.png',
      url: 'templates/meshes/Template_023.babylon',
      id: 28,
    },
    {
      name: '7x7 Square',
      thumb: 'templates/thumbnails/Icon_Template_007.png',
      url: 'templates/meshes/Template_007.babylon',
      id: 29,
    },
    {
      name: '6x6',
      thumb: 'templates/thumbnails/Icon_Template_022.png',
      url: 'templates/meshes/Template_022.babylon',
      id: 30,
    },
    {
      name: '8x8 Square',
      thumb: 'templates/thumbnails/Icon_Template_008.png',
      url: 'templates/meshes/Template_008.babylon',
      id: 31,
    },
    {
      name: '9x9 Grid',
      thumb: 'templates/thumbnails/Icon_Template_032.png',
      url: 'templates/meshes/Template_032.babylon',
      id: 32,
    },
    {
      name: '10x10 Grid',
      thumb: 'templates/thumbnails/Icon_Template_033.png',
      url: 'templates/meshes/Template_033.babylon',
      id: 33,
    },
    {
      name: '11x11 Squares',
      thumb: 'templates/thumbnails/Icon_Template_034.png',
      url: 'templates/meshes/Template_034.babylon',
      id: 34,
    },
    {
      name: '11x11 Grid',
      thumb: 'templates/thumbnails/Icon_Template_035.png',
      url: 'templates/meshes/Template_035.babylon',
      id: 35,
    },
    {
      name: '12x12 Squares',
      thumb: 'templates/thumbnails/Icon_Template_036.png',
      url: 'templates/meshes/Template_036.babylon',
      id: 36,
    },
    {
      name: '12x12 Grid',
      thumb: 'templates/thumbnails/Icon_Template_037.png',
      url: 'templates/meshes/Template_037.babylon',
      id: 37,
    },
    {
      name: 'Triangles 3',
      thumb: 'templates/thumbnails/Icon_Template_038.png',
      url: 'templates/meshes/Template_038.babylon',
      id: 38,
    },
    {
      name: 'Diamonds 3',
      thumb: 'templates/thumbnails/Icon_Template_039.png',
      url: 'templates/meshes/Template_039.babylon',
      id: 39,
    },
    {
      name: 'Sunburst',
      thumb: 'templates/thumbnails/Icon_Template_040.png',
      url: 'templates/meshes/Template_040.babylon',
      id: 40,
    },
    {
      name: 'Starwheel',
      thumb: 'templates/thumbnails/Icon_Template_042.png',
      url: 'templates/meshes/Template_042.babylon',
      id: 41,
    },
    {
      name: 'Blazing Star 1',
      thumb: 'templates/thumbnails/Icon_Template_043.png',
      url: 'templates/meshes/Template_043.babylon',
      id: 42,
    },
    {
      name: 'Blazing Star 2',
      thumb: 'templates/thumbnails/Icon_Template_044.png',
      url: 'templates/meshes/Template_044.babylon',
      id: 43,
    },
    {
      name: 'Blazing Star 3',
      thumb: 'templates/thumbnails/Icon_Template_045.png',
      url: 'templates/meshes/Template_045.babylon',
      id: 44,
    },
    {
      name: 'Blazing Star 4',
      thumb: 'templates/thumbnails/Icon_Template_046.png',
      url: 'templates/meshes/Template_046.babylon',
      id: 45,
    },
    {
      name: 'Circles 1',
      thumb: 'templates/thumbnails/Icon_Template_047.png',
      url: 'templates/meshes/Template_047.babylon',
      id: 46,
    },
    {
      name: 'Circles 2',
      thumb: 'templates/thumbnails/Icon_Template_048.png',
      url: 'templates/meshes/Template_048.babylon',
      id: 47,
    },
    {
      name: '7x7 Template',
      thumb: 'templates/thumbnails/Icon_Template_049.png',
      url: 'templates/meshes/Template_049.babylon',
      id: 48,
    },
    {
      name: 'Diamond Leaf',
      thumb: 'templates/thumbnails/Icon_Template_050.png',
      url: 'templates/meshes/Template_050.babylon',
      id: 49,
    },
    {
      name: 'Spinwheel',
      thumb: 'templates/thumbnails/Icon_Template_051.png',
      url: 'templates/meshes/Template_051.babylon',
      id: 50,
    },
    {
      name: 'Circles 3',
      thumb: 'templates/thumbnails/Icon_Template_052.png',
      url: 'templates/meshes/Template_052.babylon',
      id: 51,
    },
    {
      name: 'Dresden Fan',
      thumb: 'templates/thumbnails/Icon_Template_053.png',
      url: 'templates/meshes/Template_053.babylon',
      id: 52,
    },
    {
      name: 'Wavy Checkers',
      thumb: 'templates/thumbnails/Icon_Template_054.png',
      url: 'templates/meshes/Template_054.babylon',
      id: 53,
    },
    {
      name: 'Fleur De Lis 1',
      thumb: 'templates/thumbnails/Icon_Template_055.png',
      url: 'templates/meshes/Template_055.babylon',
      id: 54,
    },
    {
      name: 'Fleur De Lis 2',
      thumb: 'templates/thumbnails/Icon_Template_056.png',
      url: 'templates/meshes/Template_056.babylon',
      id: 55,
    },
    {
      name: 'Fleur De Lis 3',
      thumb: 'templates/thumbnails/Icon_Template_057.png',
      url: 'templates/meshes/Template_057.babylon',
      id: 56,
    },
    {
      name: 'Fan 1',
      thumb: 'templates/thumbnails/Icon_Template_058.png',
      url: 'templates/meshes/Template_058.babylon',
      id: 57,
    },
    {
      name: 'Circles 4',
      thumb: 'templates/thumbnails/Icon_Template_059.png',
      url: 'templates/meshes/Template_059.babylon',
      id: 58,
    },
    {
      name: 'Fan 2',
      thumb: 'templates/thumbnails/Icon_Template_060.png',
      url: 'templates/meshes/Template_060.babylon',
      id: 59,
    },
    {
      name: 'Cubic 3',
      thumb: 'templates/thumbnails/Icon_Template_061.png',
      url: 'templates/meshes/Template_061.babylon',
      id: 60,
    },
    {
      name: 'Cubic 2',
      thumb: 'templates/thumbnails/Icon_Template_062.png',
      url: 'templates/meshes/Template_062.babylon',
      id: 61,
    },
    {
      name: 'Twist 1',
      thumb: 'templates/thumbnails/Icon_Template_063.png',
      url: 'templates/meshes/Template_063.babylon',
      id: 62,
    },
    {
      name: 'Flower Garden',
      thumb: 'templates/thumbnails/Icon_Template_064.png',
      url: 'templates/meshes/Template_064.babylon',
      id: 63,
    },
    {
      name: 'Twist 2',
      thumb: 'templates/thumbnails/Icon_Template_065.png',
      url: 'templates/meshes/Template_065.babylon',
      id: 64,
    },
    {
      name: 'Ellipse 1',
      thumb: 'templates/thumbnails/Icon_Template_066.png',
      url: 'templates/meshes/Template_066.babylon',
      id: 65,
    },
    {
      name: 'Twist 3',
      thumb: 'templates/thumbnails/Icon_Template_067.png',
      url: 'templates/meshes/Template_067.babylon',
      id: 66,
    },
    {
      name: 'Sun Ray',
      thumb: 'templates/thumbnails/Icon_Template_068.png',
      url: 'templates/meshes/Template_068.babylon',
      id: 67,
    },
    {
      name: 'Cross 4',
      thumb: 'templates/thumbnails/Icon_Template_069.png',
      url: 'templates/meshes/Template_069.babylon',
      id: 68,
    },
    {
      name: 'Accordion',
      thumb: 'templates/thumbnails/Icon_Template_070.png',
      url: 'templates/meshes/Template_070.babylon',
      id: 69,
    },
    {
      name: 'Rings 1',
      thumb: 'templates/thumbnails/Icon_Template_071.png',
      url: 'templates/meshes/Template_071.babylon',
      id: 70,
    },
    {
      name: 'Rings 2',
      thumb: 'templates/thumbnails/Icon_Template_072.png',
      url: 'templates/meshes/Template_072.babylon',
      id: 71,
    },
    {
      name: 'Flying Geese 1',
      thumb: 'templates/thumbnails/Icon_Template_073.png',
      url: 'templates/meshes/Template_073.babylon',
      id: 72,
    },
    {
      name: 'Beach Umbrella',
      thumb: 'templates/thumbnails/Icon_Template_074.png',
      url: 'templates/meshes/Template_074.babylon',
      id: 73,
    },
    {
      name: 'Diagonal 1',
      thumb: 'templates/thumbnails/Icon_Template_075.png',
      url: 'templates/meshes/Template_075.babylon',
      id: 74,
    },
    {
      name: 'Cross 1',
      thumb: 'templates/thumbnails/Icon_Template_076.png',
      url: 'templates/meshes/Template_076.babylon',
      id: 75,
    },
    {
      name: 'Cross 2',
      thumb: 'templates/thumbnails/Icon_Template_077.png',
      url: 'templates/meshes/Template_077.babylon',
      id: 76,
    },
    {
      name: 'Horizontal Bar',
      thumb: 'templates/thumbnails/Icon_Template_078.png',
      url: 'templates/meshes/Template_078.babylon',
      id: 77,
    },
    {
      name: 'Flower 2',
      thumb: 'templates/thumbnails/Icon_Template_079.png',
      url: 'templates/meshes/Template_079.babylon',
      id: 78,
    },
    {
      name: 'Cubic 1',
      thumb: 'templates/thumbnails/Icon_Template_080.png',
      url: 'templates/meshes/Template_080.babylon',
      id: 79,
    },
    {
      name: 'Twist 4',
      thumb: 'templates/thumbnails/Icon_Template_081.png',
      url: 'templates/meshes/Template_081.babylon',
      id: 80,
    },
    {
      name: 'Flower 1',
      thumb: 'templates/thumbnails/Icon_Template_082.png',
      url: 'templates/meshes/Template_082.babylon',
      id: 81,
    },
    {
      name: 'Blazing Star 5',
      thumb: 'templates/thumbnails/Icon_Template_083.png',
      url: 'templates/meshes/Template_083.babylon',
      id: 82,
    },
    {
      name: 'Skyscraper',
      thumb: 'templates/thumbnails/Icon_Template_084.png',
      url: 'templates/meshes/Template_084.babylon',
      id: 83,
    },
    {
      name: 'Interlocked Squares 2',
      thumb: 'templates/thumbnails/Icon_Template_085.png',
      url: 'templates/meshes/Template_085.babylon',
      id: 84,
    },
    {
      name: 'Indian Summer',
      thumb: 'templates/thumbnails/Icon_Template_086.png',
      url: 'templates/meshes/Template_086.babylon',
      id: 85,
    },
    {
      name: 'Interlocked Squares 3',
      thumb: 'templates/thumbnails/Icon_Template_087.png',
      url: 'templates/meshes/Template_087.babylon',
      id: 86,
    },
    {
      name: 'Dual Wheel 1',
      thumb: 'templates/thumbnails/Icon_Template_088.png',
      url: 'templates/meshes/Template_088.babylon',
      id: 87,
    },
    {
      name: 'Circus Tent',
      thumb: 'templates/thumbnails/Icon_Template_089.png',
      url: 'templates/meshes/Template_089.babylon',
      id: 88,
    },
    {
      name: 'Halo 1',
      thumb: 'templates/thumbnails/Icon_Template_090.png',
      url: 'templates/meshes/Template_090.babylon',
      id: 89,
    },
    {
      name: 'Hex 15 Column',
      thumb: 'templates/thumbnails/Icon_Template_091.png',
      url: 'templates/meshes/Template_091.babylon',
      id: 90,
    },
    {
      name: 'Hex 4 Column',
      thumb: 'templates/thumbnails/Icon_Template_092.png',
      url: 'templates/meshes/Template_092.babylon',
      id: 91,
    },
    {
      name: 'Hex 7.5 Column',
      thumb: 'templates/thumbnails/Icon_Template_093.png',
      url: 'templates/meshes/Template_093.babylon',
      id: 92,
    },
    {
      name: 'Hex 8 Column',
      thumb: 'templates/thumbnails/Icon_Template_094.png',
      url: 'templates/meshes/Template_094.babylon',
      id: 93,
    },
    {
      name: 'Hex 2.5 Column',
      thumb: 'templates/thumbnails/Icon_Template_095.png',
      url: 'templates/meshes/Template_095.babylon',
      id: 94,
    },
    {
      name: 'Hex 5 Column',
      thumb: 'templates/thumbnails/Icon_Template_096.png',
      url: 'templates/meshes/Template_096.babylon',
      id: 95,
    },
    {
      name: 'Hex 3 Column',
      thumb: 'templates/thumbnails/Icon_Template_097.png',
      url: 'templates/meshes/Template_097.babylon',
      id: 96,
    },
    {
      name: 'Hex 6 Column',
      thumb: 'templates/thumbnails/Icon_Template_098.png',
      url: 'templates/meshes/Template_098.babylon',
      id: 97,
    },
    {
      name: 'Arkansas Traveler 1',
      thumb: 'templates/thumbnails/Icon_Template_099.png',
      url: 'templates/meshes/Template_099.babylon',
      id: 98,
    },
    {
      name: 'Arkansas Traveler 2',
      thumb: 'templates/thumbnails/Icon_Template_100.png',
      url: 'templates/meshes/Template_100.babylon',
      id: 99,
    },
    {
      name: 'Flying Geese 2',
      thumb: 'templates/thumbnails/Icon_Template_101.png',
      url: 'templates/meshes/Template_101.babylon',
      id: 100,
    },
    {
      name: 'Rounded Star 1',
      thumb: 'templates/thumbnails/Icon_Template_102.png',
      url: 'templates/meshes/Template_102.babylon',
      id: 101,
    },
    {
      name: 'Rounded Star 2',
      thumb: 'templates/thumbnails/Icon_Template_103.png',
      url: 'templates/meshes/Template_103.babylon',
      id: 102,
    },
    {
      name: 'Rounded Star 3',
      thumb: 'templates/thumbnails/Icon_Template_104.png',
      url: 'templates/meshes/Template_104.babylon',
      id: 103,
    },
    {
      name: 'Spherical Crescents',
      thumb: 'templates/thumbnails/Icon_Template_105.png',
      url: 'templates/meshes/Template_105.babylon',
      id: 104,
    },
    {
      name: 'Blazing Star 6',
      thumb: 'templates/thumbnails/Icon_Template_106.png',
      url: 'templates/meshes/Template_106.babylon',
      id: 105,
    },
    {
      name: 'Pinwheel 2',
      thumb: 'templates/thumbnails/Icon_Template_107.png',
      url: 'templates/meshes/Template_107.babylon',
      id: 106,
    },
    {
      name: 'Blazing Star 7',
      thumb: 'templates/thumbnails/Icon_Template_108.png',
      url: 'templates/meshes/Template_108.babylon',
      id: 107,
    },
    {
      name: 'Pinwheel 3',
      thumb: 'templates/thumbnails/Icon_Template_109.png',
      url: 'templates/meshes/Template_109.babylon',
      id: 108,
    },
    {
      name: 'Tile Star 1',
      thumb: 'templates/thumbnails/Icon_Template_110.png',
      url: 'templates/meshes/Template_110.babylon',
      id: 109,
    },
    {
      name: 'Pinwheel 4',
      thumb: 'templates/thumbnails/Icon_Template_111.png',
      url: 'templates/meshes/Template_111.babylon',
      id: 110,
    },
    {
      name: 'Cross 5',
      thumb: 'templates/thumbnails/Icon_Template_112.png',
      url: 'templates/meshes/Template_112.babylon',
      id: 111,
    },
    {
      name: 'Flying Geese 3',
      thumb: 'templates/thumbnails/Icon_Template_113.png',
      url: 'templates/meshes/Template_113.babylon',
      id: 112,
    },
    {
      name: 'Star Knot 1',
      thumb: 'templates/thumbnails/Icon_Template_114.png',
      url: 'templates/meshes/Template_114.babylon',
      id: 113,
    },
    {
      name: 'Star Knot 2',
      thumb: 'templates/thumbnails/Icon_Template_115.png',
      url: 'templates/meshes/Template_115.babylon',
      id: 114,
    },
    {
      name: 'Cross 3',
      thumb: 'templates/thumbnails/Icon_Template_116.png',
      url: 'templates/meshes/Template_116.babylon',
      id: 115,
    },
    {
      name: 'Flower 3',
      thumb: 'templates/thumbnails/Icon_Template_117.png',
      url: 'templates/meshes/Template_117.babylon',
      id: 116,
    },
    {
      name: 'Log Cabin',
      thumb: 'templates/thumbnails/Icon_Template_118.png',
      url: 'templates/meshes/Template_118.babylon',
      id: 117,
    },
    {
      name: 'Pinwheel 5',
      thumb: 'templates/thumbnails/Icon_Template_119.png',
      url: 'templates/meshes/Template_119.babylon',
      id: 118,
    },
  ],
  QuiltTemplates: [
    {
      name: '1x1 Square',
      thumb: 'templates/thumbnails/Icon_Template_001.png',
      url: 'templates/meshes/Template_001.babylon',
      id: 2,
    },
    {
      name: '2x2 Square',
      thumb: 'templates/thumbnails/Icon_Template_002.png',
      url: 'templates/meshes/Template_002.babylon',
      id: 4,
    },

    {
      name: '3x3 Square',
      thumb: 'templates/thumbnails/Icon_Template_003.png',
      url: 'templates/meshes/Template_003.babylon',
      id: 6,
    },
    {
      name: '4x4 Square',
      thumb: 'templates/thumbnails/Icon_Template_004.png',
      url: 'templates/meshes/Template_004.babylon',
      id: 8,
    },
    {
      name: '5x5 Square',
      thumb: 'templates/thumbnails/Icon_Template_005.png',
      url: 'templates/meshes/Template_005.babylon',
      id: 10,
    },

    {
      name: '6x6 Square',
      thumb: 'templates/thumbnails/Icon_Template_006.png',
      url: 'templates/meshes/Template_006.babylon',
      id: 12,
    },

    {
      name: '10x10 Square',
      thumb: 'templates/thumbnails/Icon_Template_010.png',
      url: 'templates/meshes/Template_010.babylon',
      id: 25,
      tiling: 0.25,
      materialTiling: 0.2,
      accentTiling: 0.4,
    },
    {
      name: '9x9 Square',
      thumb: 'templates/thumbnails/Icon_Template_009.png',
      url: 'templates/meshes/Template_009.babylon',
      id: 26,
      tiling: 0.2778,
      materialTiling: 0.2,
      accentTiling: 0.44,
    },

    {
      name: '7x7 Square',
      thumb: 'templates/thumbnails/Icon_Template_007.png',
      url: 'templates/meshes/Template_007.babylon',
      id: 29,
    },
    {
      name: '8x8 Square',
      thumb: 'templates/thumbnails/Icon_Template_008.png',
      url: 'templates/meshes/Template_008.babylon',
      id: 31,
    },
    {
      name: '11x11 Squares',
      thumb: 'templates/thumbnails/Icon_Template_034.png',
      url: 'templates/meshes/Template_034.babylon',
      id: 34,
    },
    {
      name: '12x12 Squares',
      thumb: 'templates/thumbnails/Icon_Template_036.png',
      url: 'templates/meshes/Template_036.babylon',
      id: 36,
    },
  ],
}

export const Assets: IPatternAssets = {
  templates: [...TemplateSets.GalleryTemplates, ...TemplateSets.PatternTemplates],
  prints: {
    standard: [
      {
        name: 'Print_Animal_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Animal_001.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '262626',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Animal_001.png',
        id: 32,
      },
      {
        name: 'Print_Animal_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Animal_002.png',
        channels: 'RG',
        rColor: 'FF7C0B',
        gColor: '000000',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Animal_002.png',
        id: 34,
      },
      {
        name: 'Print_Animal_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Animal_003.png',
        channels: 'RG',
        rColor: 'FFC05D',
        gColor: '431024',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Animal_003.png',
        id: 35,
      },
      {
        name: 'Print_Animal_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Animal_004.png',
        channels: 'RGB',
        rColor: 'FFF7BD',
        gColor: 'FFE01E',
        bColor: '0E0E0E',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Animal_004.png',
        id: 36,
      },
      {
        name: 'Print_Arches_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Arches_001.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: 'BE3328',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Arches_001.png',
        id: 37,
      },
      {
        name: 'Print_Boats_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Boats_001.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '85AAE2',
        bColor: 'B4D3E5',
        aColor: '3F3F4D',
        tiling: 13.8,
        thumb: 'prints/thumbs/Thb_Print_Boats_001.png',
        id: 38,
      },
      {
        name: 'Print_Boxes_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Boxes_001.png',
        channels: 'RGB',
        rColor: 'EE4E4E',
        gColor: '92FF88',
        bColor: '3849D9',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Boxes_001.png',
        id: 39,
      },
      {
        name: 'Print_Brocade_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Brocade_001.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '2B8AA6',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Brocade_001.png',
        id: 40,
      },
      {
        name: 'Print_Brocade_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Brocade_002.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: 'C5122B',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Brocade_002.png',
        id: 41,
      },
      {
        name: 'Print_Brocade_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Brocade_003.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '90248E',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Brocade_003.png',
        id: 42,
      },
      {
        name: 'Print_Checkers_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Checkers_001.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '94E7FF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 36,
        thumb: 'prints/thumbs/Thb_Print_Checkers_001.png',
        id: 0,
      },
      {
        name: 'Print_Checkers_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Checkers_002.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: '94E7FF',
        bColor: '6464A1',
        aColor: 'N/A',
        tiling: 14,
        thumb: 'prints/thumbs/Thb_Print_Checkers_002.png',
        id: 43,
      },
      {
        name: 'Print_Checkers_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Checkers_003.png',
        channels: 'RG',
        rColor: 'D63A3A',
        gColor: '0E1D19',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Checkers_003.png',
        id: 44,
      },
      {
        name: 'Print_Checkers_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Checkers_004.png',
        channels: 'RG',
        rColor: '94E5DF',
        gColor: 'CC6D6D',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Checkers_004.png',
        id: 45,
      },
      {
        name: 'Print_Checkers_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Checkers_005.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '94E7FF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 38,
        thumb: 'prints/thumbs/Thb_Print_Checkers_005.png',
        id: 46,
      },
      {
        name: 'Print_Checkers_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Checkers_006.png',
        channels: 'RGB',
        rColor: '7E9CFF',
        gColor: '94E7FF',
        bColor: '6464A1',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Checkers_006.png',
        id: 47,
      },
      {
        name: 'Print_Chevrons_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_001.png',
        channels: 'RG',
        rColor: 'B9B1A9',
        gColor: 'FFD4D0',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_001.png',
        id: 1,
      },
      {
        name: 'Print_Chevrons_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_002.png',
        channels: 'RG',
        rColor: 'F39FC3',
        gColor: '8FFFE6',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_002.png',
        id: 2,
      },
      {
        name: 'Print_Chevrons_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_003.png',
        channels: 'RGB',
        rColor: '35F1E7',
        gColor: '1EABFF',
        bColor: 'FF5A00',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_003.png',
        id: 48,
      },
      {
        name: 'Print_Chevrons_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_004.png',
        channels: 'RGB',
        rColor: '35F1E7',
        gColor: '1EABFF',
        bColor: 'FF5A00',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_004.png',
        id: 49,
      },
      {
        name: 'Print_Chevrons_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_005.png',
        channels: 'RG',
        rColor: '8EF135',
        gColor: '385E42',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_005.png',
        id: 50,
      },
      {
        name: 'Print_Chevrons_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_006.png',
        channels: 'RGBA',
        rColor: 'F1F077',
        gColor: 'EE6159',
        bColor: '58AAC5',
        aColor: '713B3E',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_006.png',
        id: 51,
      },
      {
        name: 'Print_Chevrons_007',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_007.png',
        channels: 'RGBA',
        rColor: 'F1F077',
        gColor: 'EE6159',
        bColor: '58AAC5',
        aColor: '713B3E',
        tiling: 10.75,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_007.png',
        id: 52,
      },
      {
        name: 'Print_Chevrons_008',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Chevrons_008.png',
        channels: 'RGBA',
        rColor: 'F1F077',
        gColor: 'EE6159',
        bColor: '58AAC5',
        aColor: '713B3E',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Chevrons_008.png',
        id: 53,
      },
      {
        name: 'Print_Damask_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Damask_001.png',
        channels: 'RG',
        rColor: '202041',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 9,
        thumb: 'prints/thumbs/Thb_Print_Damask_001.png',
        id: 54,
      },
      {
        name: 'Print_Damask_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Damask_002.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: 'E5988B',
        bColor: '434343',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Damask_002.png',
        id: 55,
      },
      {
        name: 'Print_Damask_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Damask_003.png',
        channels: 'RGBA',
        rColor: '111180',
        gColor: 'FFFFFF',
        bColor: '457359',
        aColor: '559888',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Damask_003.png',
        id: 56,
      },
      {
        name: 'Print_Damask_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Damask_004.png',
        channels: 'RGBA',
        rColor: '6ECABE',
        gColor: 'FFFFFF',
        bColor: 'DBD7A4',
        aColor: '1A59BC',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Damask_004.png',
        id: 33,
      },
      {
        name: 'Print_Damask_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Damask_005.png',
        channels: 'RG',
        rColor: '6ECABE',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 24,
        thumb: 'prints/thumbs/Thb_Print_Damask_005.png',
        id: 57,
      },
      {
        name: 'Print_Dogs_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dogs_001.png',
        channels: 'RGB',
        rColor: '4EB0EE',
        gColor: 'B7F1EE',
        bColor: '3865D9',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Dogs_001.png',
        id: 58,
      },
      {
        name: 'Print_Dots_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_001.png',
        channels: 'RG',
        rColor: '202041',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Dots_001.png',
        id: 3,
      },
      {
        name: 'Print_Dots_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_002.png',
        channels: 'RG',
        rColor: 'FF003D',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Dots_002.png',
        id: 4,
      },
      {
        name: 'Print_Dots_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_003.png',
        channels: 'RGB',
        rColor: 'FFFCF2',
        gColor: 'C3A75F',
        bColor: '573D3C',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Dots_003.png',
        id: 5,
      },
      {
        name: 'Print_Dots_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_004.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: 'FC75FF',
        bColor: '6DF4FF',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Dots_004.png',
        id: 6,
      },
      {
        name: 'Print_Dots_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_005.png',
        channels: 'RG',
        rColor: '4B734D',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Dots_005.png',
        id: 7,
      },
      {
        name: 'Print_Dots_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_006.png',
        channels: 'RG',
        rColor: 'FFDF86',
        gColor: '7B7B7B',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10.65,
        thumb: 'prints/thumbs/Thb_Print_Dots_006.png',
        id: 59,
      },
      {
        name: 'Print_Dots_007',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_007.png',
        channels: 'RGB',
        rColor: '2E2E2E',
        gColor: '88F3EA',
        bColor: 'FF6D95',
        aColor: 'N/A',
        tiling: 10.65,
        thumb: 'prints/thumbs/Thb_Print_Dots_007.png',
        id: 60,
      },
      {
        name: 'Print_Dots_008',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_008.png',
        channels: 'RGBA',
        rColor: 'FFDF86',
        gColor: '7B7B7B',
        bColor: '6DF4FF',
        aColor: '725598',
        tiling: 11.15,
        thumb: 'prints/thumbs/Thb_Print_Dots_008.png',
        id: 61,
      },
      {
        name: 'Print_Dots_009',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_009.png',
        channels: 'RGBA',
        rColor: 'FFFEFB',
        gColor: '7B7B7B',
        bColor: '6DF4FF',
        aColor: '725598',
        tiling: 9.6,
        thumb: 'prints/thumbs/Thb_Print_Dots_009.png',
        id: 62,
      },
      {
        name: 'Print_Dots_010',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_010.png',
        channels: 'RGB',
        rColor: '42C3EC',
        gColor: '000000',
        bColor: '6DF4FF',
        aColor: 'N/A',
        tiling: 10.7,
        thumb: 'prints/thumbs/Thb_Print_Dots_010.png',
        id: 63,
      },
      {
        name: 'Print_Dots_011',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Dots_011.png',
        channels: 'RGBA',
        rColor: 'AEFFCB',
        gColor: '7B7B7B',
        bColor: 'B452D1',
        aColor: '725598',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Dots_011.png',
        id: 64,
      },
      {
        name: 'Print_Drops_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Drops_001.png',
        channels: 'RGBA',
        rColor: '4EB0EE',
        gColor: 'B7F1EE',
        bColor: 'D438D9',
        aColor: '5B5B5B',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Drops_001.png',
        id: 65,
      },
      {
        name: 'Print_FleurDeLis_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_FleurDeLis_001.png',
        channels: 'RG',
        rColor: '17173F',
        gColor: '44446F',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_FleurDeLis_001.png',
        id: 8,
      },
      {
        name: 'Print_Geometric_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_001.png',
        channels: 'RG',
        rColor: 'FEFFC9',
        gColor: 'FFEAD5',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Geometric_001.png',
        id: 9,
      },
      {
        name: 'Print_Geometric_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_002.png',
        channels: 'RG',
        rColor: 'B75820',
        gColor: 'FFEAD5',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Geometric_002.png',
        id: 66,
      },
      {
        name: 'Print_Geometric_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_003.png',
        channels: 'RG',
        rColor: 'B7202C',
        gColor: 'FFEAD5',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Geometric_003.png',
        id: 67,
      },
      {
        name: 'Print_Geometric_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_004.png',
        channels: 'RG',
        rColor: '20B723',
        gColor: '000000',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Geometric_004.png',
        id: 68,
      },
      {
        name: 'Print_Geometric_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_005.png',
        channels: 'RGB',
        rColor: 'B72093',
        gColor: 'FF7998',
        bColor: '251F78',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Geometric_005.png',
        id: 69,
      },
      {
        name: 'Print_Geometric_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_006.png',
        channels: 'RGB',
        rColor: 'B7202E',
        gColor: 'FFFFFF',
        bColor: '35DDD1',
        aColor: 'N/A',
        tiling: 18,
        thumb: 'prints/thumbs/Thb_Print_Geometric_006.png',
        id: 70,
      },
      {
        name: 'Print_Geometric_007',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_007.png',
        channels: 'RGB',
        rColor: '476C4D',
        gColor: 'FFFFFF',
        bColor: '203133',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Geometric_007.png',
        id: 71,
      },
      {
        name: 'Print_Geometric_008',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_008.png',
        channels: 'RGBA',
        rColor: 'F5F5D8',
        gColor: 'FF86DD',
        bColor: '8CE0CA',
        aColor: 'A254F3',
        tiling: 12.5,
        thumb: 'prints/thumbs/Thb_Print_Geometric_008.png',
        id: 72,
      },
      {
        name: 'Print_Geometric_009',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_009.png',
        channels: 'RG',
        rColor: 'F5F5D8',
        gColor: '6C0C51',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 18,
        thumb: 'prints/thumbs/Thb_Print_Geometric_009.png',
        id: 73,
      },
      {
        name: 'Print_Geometric_010',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_010.png',
        channels: 'RG',
        rColor: 'E0E0E0',
        gColor: '3F002D',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 14,
        thumb: 'prints/thumbs/Thb_Print_Geometric_010.png',
        id: 74,
      },
      {
        name: 'Print_Geometric_011',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_011.png',
        channels: 'RGBA',
        rColor: 'D62941',
        gColor: 'FF869E',
        bColor: 'E2343D',
        aColor: 'E95567',
        tiling: 18,
        thumb: 'prints/thumbs/Thb_Print_Geometric_011.png',
        id: 75,
      },
      {
        name: 'Print_Geometric_012',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_012.png',
        channels: 'RGB',
        rColor: '1F1D1D',
        gColor: 'A2F5FF',
        bColor: '63BDE2',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Geometric_012.png',
        id: 76,
      },
      {
        name: 'Print_Geometric_013',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_013.png',
        channels: 'RGBA',
        rColor: '1F1D1D',
        gColor: 'FCFFD5',
        bColor: 'E29663',
        aColor: 'D42C27',
        tiling: 24,
        thumb: 'prints/thumbs/Thb_Print_Geometric_013.png',
        id: 77,
      },
      {
        name: 'Print_Geometric_014',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_014.png',
        channels: 'RG',
        rColor: '1F1D1D',
        gColor: 'FCFFD5',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 24,
        thumb: 'prints/thumbs/Thb_Print_Geometric_014.png',
        id: 78,
      },
      {
        name: 'Print_Geometric_015',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_015.png',
        channels: 'RGBA',
        rColor: '9EFDFC',
        gColor: '6C6C6C',
        bColor: '63E2A6',
        aColor: 'FFFFFF',
        tiling: 15,
        thumb: 'prints/thumbs/Thb_Print_Geometric_015.png',
        id: 79,
      },
      {
        name: 'Print_Geometric_016',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_016.png',
        channels: 'RGB',
        rColor: '9EFDFC',
        gColor: '5B5B5B',
        bColor: 'E29663',
        aColor: 'N/A',
        tiling: 28,
        thumb: 'prints/thumbs/Thb_Print_Geometric_016.png',
        id: 80,
      },
      {
        name: 'Print_Geometric_017',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_017.png',
        channels: 'RGB',
        rColor: 'B3F6F8',
        gColor: '8C3762',
        bColor: 'CF6E94',
        aColor: 'N/A',
        tiling: 14,
        thumb: 'prints/thumbs/Thb_Print_Geometric_017.png',
        id: 81,
      },
      {
        name: 'Print_Geometric_018',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_018.png',
        channels: 'RGB',
        rColor: 'B3F6F8',
        gColor: '8C3762',
        bColor: 'CF6E94',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Geometric_018.png',
        id: 82,
      },
      {
        name: 'Print_Geometric_019',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_019.png',
        channels: 'RGBA',
        rColor: 'F8B3C4',
        gColor: '8C3762',
        bColor: '776ECF',
        aColor: '000000',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Geometric_019.png',
        id: 83,
      },
      {
        name: 'Print_Geometric_020',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_020.png',
        channels: 'RGBA',
        rColor: '3B529A',
        gColor: 'FFFFFF',
        bColor: '776ECF',
        aColor: '000000',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Geometric_020.png',
        id: 84,
      },
      {
        name: 'Print_Geometric_021',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_021.png',
        channels: 'RGB',
        rColor: '94A5D9',
        gColor: '6477B0',
        bColor: '534C9A',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Geometric_021.png',
        id: 85,
      },
      {
        name: 'Print_Geometric_022',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_022.png',
        channels: 'RGB',
        rColor: '94A5D9',
        gColor: '6477B0',
        bColor: '534C9A',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Geometric_022.png',
        id: 86,
      },
      {
        name: 'Print_Geometric_023',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_023.png',
        channels: 'RG',
        rColor: '000000',
        gColor: 'C84141',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Geometric_023.png',
        id: 87,
      },
      {
        name: 'Print_Geometric_024',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Geometric_024.png',
        channels: 'RGBA',
        rColor: '000000',
        gColor: 'C84141',
        bColor: 'FFFFFF',
        aColor: '163B6F',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Geometric_024.png',
        id: 88,
      },
      {
        name: 'Print_Gingham_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Gingham_001.png',
        channels: 'RGB',
        rColor: 'FFA0A1',
        gColor: ' FF474B',
        bColor: 'FFFFFF',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Gingham_001.png',
        id: 10,
      },
      {
        name: 'Print_Gingham_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Gingham_002.png',
        channels: 'RGB',
        rColor: 'FFA0A1',
        gColor: 'FF474B',
        bColor: 'FFFFFF',
        aColor: 'N/A',
        tiling: 18.5,
        thumb: 'prints/thumbs/Thb_Print_Gingham_002.png',
        id: 89,
      },
      {
        name: 'Print_Gingham_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Gingham_003.png',
        channels: 'RG',
        rColor: 'FFA0A1',
        gColor: 'FF474B',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Gingham_003.png',
        id: 90,
      },
      {
        name: 'Print_Harlequin_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Harlequin_001.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: 'FF0006',
        bColor: '000000',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Harlequin_001.png',
        id: 11,
      },
      {
        name: 'Print_Harlequin_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Harlequin_002.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: 'EDBAFF',
        bColor: 'FFDF92',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Harlequin_002.png',
        id: 12,
      },
      {
        name: 'Print_Harlequin_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Harlequin_003.png',
        channels: 'RGBA',
        rColor: 'F5B900',
        gColor: '01C51C',
        bColor: '725598',
        aColor: '2A0E4F',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Harlequin_003.png',
        id: 91,
      },
      {
        name: 'Print_Harlequin_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Harlequin_004.png',
        channels: 'RGBA',
        rColor: '4EB0EE',
        gColor: 'B7F1EE',
        bColor: 'D438D9',
        aColor: '5B5B5B',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Harlequin_004.png',
        id: 92,
      },
      {
        name: 'Print_Harlequin_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Harlequin_005.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '4179C8',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Harlequin_005.png',
        id: 93,
      },
      {
        name: 'Print_Hearts_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Hearts_001.png',
        channels: 'RGBA',
        rColor: '000000',
        gColor: 'E71B23',
        bColor: '0099D1',
        aColor: 'F5C932',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Hearts_001.png',
        id: 94,
      },
      {
        name: 'Print_Hearts_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Hearts_002.png',
        channels: 'RGBA',
        rColor: '000000',
        gColor: 'E71B23',
        bColor: '0099D1',
        aColor: 'F5C932',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Hearts_002.png',
        id: 95,
      },
      {
        name: 'Print_Knot_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Knot_001.png',
        channels: 'RGB',
        rColor: 'CC1818',
        gColor: '8EAFB9',
        bColor: '0E0E0E',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Knot_001.png',
        id: 96,
      },
      {
        name: 'Print_Leaves_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Leaves_001.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: 'CAC64B',
        bColor: 'BEAA7D',
        aColor: '8C7B54',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Leaves_001.png',
        id: 13,
      },
      {
        name: 'Print_Notes_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Notes_001.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '000000',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Notes_001.png',
        id: 97,
      },
      {
        name: 'Print_Ogee_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Ogee_001.png',
        channels: 'RG',
        rColor: '9197B2',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Ogee_001.png',
        id: 14,
      },
      {
        name: 'Print_Ogee_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Ogee_002.png',
        channels: 'RGBA',
        rColor: '9197B2',
        gColor: 'FFFFFF',
        bColor: 'FFDF92',
        aColor: '725598',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Ogee_002.png',
        id: 98,
      },
      {
        name: 'Print_Ogee_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Ogee_003.png',
        channels: 'RGB',
        rColor: '6F1F3A',
        gColor: 'FFA970',
        bColor: 'BC5669',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Ogee_003.png',
        id: 99,
      },
      {
        name: 'Print_Paisley_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Paisley_002.png',
        channels: 'RG',
        rColor: '901915',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Paisley_002.png',
        id: 16,
      },
      {
        name: 'Print_Paisley_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Paisley_003.png',
        channels: 'RG',
        rColor: '2F186C',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 5,
        thumb: 'prints/thumbs/Thb_Print_Paisley_003.png',
        id: 17,
      },
      {
        name: 'Print_Peacock_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Peacock_001.png',
        channels: 'RGBA',
        rColor: '4EB0EE',
        gColor: 'B7F1EE',
        bColor: 'D438D9',
        aColor: '5B5B5B',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Peacock_001.png',
        id: 100,
      },
      {
        name: 'Print_Plaid_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Plaid_001.png',
        channels: 'RGB',
        rColor: '1F1D1D',
        gColor: 'FCFFD5',
        bColor: 'E29663',
        aColor: 'N/A',
        tiling: 32,
        thumb: 'prints/thumbs/Thb_Print_Plaid_001.png',
        id: 101,
      },
      {
        name: 'Print_Plaid_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Plaid_002.png',
        channels: 'RGBA',
        rColor: '1F1D1D',
        gColor: 'FCFFD5',
        bColor: 'E29663',
        aColor: 'D42C27',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Plaid_002.png',
        id: 102,
      },
      {
        name: 'Print_Plaid_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Plaid_004.png',
        channels: 'RGBA',
        rColor: '878787',
        gColor: 'D5FFD5',
        bColor: '9DE263',
        aColor: '2755D4',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Plaid_004.png',
        id: 104,
      },
      {
        name: 'Print_Plain',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Plain.png',
        channels: 'R',
        rColor: 'FFFFFF',
        gColor: '',
        bColor: '',
        aColor: '',
        tiling: 1,
        thumb: 'prints/thumbs/Thb_Print_Plain.png',
        id: 18,
      },
      {
        name: 'Print_Psychadelic_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Psychadelic_001.png',
        channels: 'RGBA',
        rColor: '23214F',
        gColor: '4697B0',
        bColor: '64D6BB',
        aColor: 'BC4B6C',
        tiling: 14,
        thumb: 'prints/thumbs/Thb_Print_Psychadelic_001.png',
        id: 105,
      },
      {
        name: 'Print_Scales_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Scales_001.png',
        channels: 'RGB',
        rColor: '6DEE7B',
        gColor: '4FB072',
        bColor: '23711B',
        aColor: 'N/A',
        tiling: 22,
        thumb: 'prints/thumbs/Thb_Print_Scales_001.png',
        id: 106,
      },
      {
        name: 'Print_Scales_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Scales_002.png',
        channels: 'RG',
        rColor: 'EE6DA9',
        gColor: '4F8BB0',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Scales_002.png',
        id: 107,
      },
      {
        name: 'Print_Star_Grid_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Star_Grid_001.png',
        channels: 'RG',
        rColor: '46938D',
        gColor: '88FFC2',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Star_Grid_001.png',
        id: 19,
      },
      {
        name: 'Print_Stars_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_001.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '85AAE2',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 18,
        thumb: 'prints/thumbs/Thb_Print_Stars_001.png',
        id: 108,
      },
      {
        name: 'Print_Stars_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_002.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: '24549C',
        bColor: 'C51712',
        aColor: 'N/A',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Stars_002.png',
        id: 109,
      },
      {
        name: 'Print_Stars_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_003.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: '24549C',
        bColor: 'AD2D2A',
        aColor: 'N/A',
        tiling: 18,
        thumb: 'prints/thumbs/Thb_Print_Stars_003.png',
        id: 110,
      },
      {
        name: 'Print_Stars_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_004.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '24549C',
        bColor: 'C51712',
        aColor: '3F3F4D',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Stars_004.png',
        id: 111,
      },
      {
        name: 'Print_Stars_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_005.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '24549C',
        bColor: 'C51712',
        aColor: '3F3F4D',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Stars_005.png',
        id: 112,
      },
      {
        name: 'Print_Stars_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_006.png',
        channels: 'RGBA',
        rColor: 'FFEC2A',
        gColor: '2FC1C3',
        bColor: 'FFFFFF',
        aColor: '364A76',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Stars_006.png',
        id: 113,
      },
      {
        name: 'Print_Stars_007',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stars_007.png',
        channels: 'RGBA',
        rColor: '909090',
        gColor: 'FFFFFF',
        bColor: '2A2D98',
        aColor: 'C30808',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Stars_007.png',
        id: 114,
      },
      {
        name: 'Print_Stripes_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_001.png',
        channels: 'RG',
        rColor: '82BFE5',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Stripes_001.png',
        id: 20,
      },
      {
        name: 'Print_Stripes_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_002.png',
        channels: 'RG',
        rColor: '2F2B5B',
        gColor: 'FFFFFF',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Stripes_002.png',
        id: 21,
      },
      {
        name: 'Print_Stripes_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_003.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: '040054',
        bColor: 'FFAAB9',
        aColor: 'N/A',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Stripes_003.png',
        id: 22,
      },
      {
        name: 'Print_Stripes_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_004.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: 'C9C6FF',
        bColor: 'FF8A9F',
        aColor: '9A0016',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Stripes_004.png',
        id: 23,
      },
      {
        name: 'Print_Stripes_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_005.png',
        channels: 'RGBA',
        rColor: 'B1FFCB',
        gColor: '7EA687',
        bColor: 'F5D5FF',
        aColor: 'FFFFFF',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Stripes_005.png',
        id: 24,
      },
      {
        name: 'Print_Stripes_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_006.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '0E112B',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Stripes_006.png',
        id: 25,
      },
      {
        name: 'Print_Stripes_007',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_007.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '522F4C',
        bColor: 'EEBCD3',
        aColor: 'C796FF',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Stripes_007.png',
        id: 115,
      },
      {
        name: 'Print_Stripes_008',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_008.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '0E112B',
        bColor: 'CA84C3',
        aColor: 'B74646',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Stripes_008.png',
        id: 116,
      },
      {
        name: 'Print_Stripes_009',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_009.png',
        channels: 'RGBA',
        rColor: '88CBFF',
        gColor: '0E0F2B',
        bColor: 'D5EEFF',
        aColor: '4746B7',
        tiling: 20,
        thumb: 'prints/thumbs/Thb_Print_Stripes_009.png',
        id: 117,
      },
      {
        name: 'Print_Stripes_011',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Stripes_011.png',
        channels: 'RGBA',
        rColor: 'D6545C',
        gColor: 'FF99A8',
        bColor: 'FDB6B9',
        aColor: 'C84345',
        tiling: 14,
        thumb: 'prints/thumbs/Thb_Print_Stripes_011.png',
        id: 119,
      },
      {
        name: 'Print_Tile_Flower_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_001.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: '40456F',
        bColor: 'C9CEFF',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_001.png',
        id: 26,
      },
      {
        name: 'Print_Tile_Flower_002',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_002.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '40456F',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_002.png',
        id: 27,
      },
      {
        name: 'Print_Tile_Flower_003',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_003.png',
        channels: 'RGB',
        rColor: 'FFFFFF',
        gColor: 'DB9CBE',
        bColor: '9F4C59',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_003.png',
        id: 28,
      },
      {
        name: 'Print_Tile_Flower_004',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_004.png',
        channels: 'RGB',
        rColor: '4A8DA6',
        gColor: 'FFFFFF',
        bColor: 'B3FCFF',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_004.png',
        id: 29,
      },
      {
        name: 'Print_Tile_Flower_005',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_005.png',
        channels: 'RGB',
        rColor: '25C4FF',
        gColor: '18648C',
        bColor: 'B3FCFF',
        aColor: 'N/A',
        tiling: 6,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_005.png',
        id: 30,
      },
      {
        name: 'Print_Tile_Flower_006',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_006.png',
        channels: 'RG',
        rColor: 'FFFFFF',
        gColor: '188C1A',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_006.png',
        id: 120,
      },
      {
        name: 'Print_Tile_Flower_007',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_007.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '596359',
        bColor: '8C464C',
        aColor: '762930',
        tiling: 16,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_007.png',
        id: 121,
      },
      {
        name: 'Print_Tile_Flower_008',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_008.png',
        channels: 'RGBA',
        rColor: '4B1A1A',
        gColor: '49B24B',
        bColor: 'FFFFFF',
        aColor: 'E21616',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_008.png',
        id: 122,
      },
      {
        name: 'Print_Tile_Flower_009',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_009.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '5EDAE5',
        bColor: '0E891E',
        aColor: 'B93E3E',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_009.png',
        id: 123,
      },
      {
        name: 'Print_Tile_Flower_010',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_010.png',
        channels: 'RGB',
        rColor: '12488E',
        gColor: 'FCFFC2',
        bColor: '17A830',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_010.png',
        id: 124,
      },
      {
        name: 'Print_Tile_Flower_011',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_011.png',
        channels: 'RG',
        rColor: '621E73',
        gColor: 'FFC7C2',
        bColor: 'N/A',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_011.png',
        id: 125,
      },
      {
        name: 'Print_Tile_Flower_012',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_012.png',
        channels: 'RGB',
        rColor: '259389',
        gColor: 'FFC2F7',
        bColor: '2537C0',
        aColor: 'N/A',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_012.png',
        id: 126,
      },
      {
        name: 'Print_Tile_Flower_013',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_013.png',
        channels: 'RGBA',
        rColor: '105927',
        gColor: '77C04D',
        bColor: 'AFE292',
        aColor: 'FFFFFF',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_013.png',
        id: 127,
      },
      {
        name: 'Print_Tile_Flower_014',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_014.png',
        channels: 'RGBA',
        rColor: '22462D',
        gColor: '77C04D',
        bColor: 'ECF354',
        aColor: 'FFA231',
        tiling: 10,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_014.png',
        id: 128,
      },
      {
        name: 'Print_Tile_Flower_015',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_015.png',
        channels: 'RGBA',
        rColor: '081E29',
        gColor: '77C04D',
        bColor: 'FA4627',
        aColor: '599039',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_015.png',
        id: 129,
      },
      {
        name: 'Print_Tile_Flower_016',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_016.png',
        channels: 'RGBA',
        rColor: 'F5F450',
        gColor: '94C04D',
        bColor: '396A1C',
        aColor: '48931C',
        tiling: 12,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_016.png',
        id: 130,
      },
      {
        name: 'Print_Tile_Flower_017',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Tile_Flower_017.png',
        channels: 'RGBA',
        rColor: '959595',
        gColor: 'B04646',
        bColor: 'D66464',
        aColor: '675A56',
        tiling: 8,
        thumb: 'prints/thumbs/Thb_Print_Tile_Flower_017.png',
        id: 131,
      },
      {
        name: 'Print_Whales_001',
        albedoURL: 'N/A',
        maskURL: 'prints/masks/Print_Whales_001.png',
        channels: 'RGBA',
        rColor: 'FFFFFF',
        gColor: '85AAE2',
        bColor: 'B4D3E5',
        aColor: '3F3F4D',
        tiling: 6,
        thumb: 'prints/thumbs/Thb_Print_Whales_001.png',
        id: 31,
      },
    ],
    uploads: [],
  },
  materials: [
    {
      albedo: 'materials/textures/BrushedMetal_001_D.png',
      normal: 'materials/textures/BrushedMetal_001_N.png',
      thumb: 'materials/thumbs/Thb_Mat_Metal_001a.png',
      normalMapStrength: 1,
      tiling: 10,
      metalness: 1,
      smoothness: 0.7,
      name: 'BrushedMetal 001',
      id: 13,
    },
    {
      albedo: 'materials/textures/GlazedClay_D.png',
      normal: 'materials/textures/GlazedClay_N.png',
      r: 'materials/textures/GlazedClay_R.png',
      thumb: 'materials/thumbs/Thb_Mat_Clay_001.png',
      name: 'Glazed Clay',
      id: 1,
    },
    {
      albedo: 'materials/textures/Jean_D.png',
      normal: 'materials/textures/Jean_N.png',
      thumb: 'materials/thumbs/Thb_Mat_Jeans_001.png',
      normalMapStrength: 0.1,
      tiling: 2,
      smoothness: 0.3,
      name: 'Jeans',
      id: 2,
    },
    {
      albedo: 'materials/textures/Leather_D.png',
      normal: 'materials/textures/Leather_N.png',
      r: 'materials/textures/Leather_R.png',
      thumb: 'materials/thumbs/Thb_Mat_Leather_001a.png',
      name: 'Leather',
      id: 3,
    },
    {
      albedo: 'materials/textures/Marble_D.png',
      normal: 'materials/textures/Marble_N.png',
      s: 'materials/textures/Marble_S.png',
      thumb: 'materials/thumbs/Thb_Mat_Marble_001.png',
      name: 'Marble',
      id: 4,
    },
    {
      albedo: 'materials/textures/Muslin_D.png',
      normal: 'materials/textures/Muslin_N.png',
      thumb: 'materials/thumbs/Thb_Mat_Muslin_001.png',
      tiling: 2,
      smoothness: 0.3,
      name: 'Muslin',
      id: 5,
    },
    {
      albedo: 'materials/textures/OldWood_D.png',
      normal: 'materials/textures/OldWood_N.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_001c.png',
      name: 'Old Wood',
      id: 6,
    },
    {
      albedo: 'materials/textures/PolishedWood_D.png',
      normal: 'materials/textures/PolishedWood_N.png',
      r: 'materials/textures/PolishedWood_R.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_001b.png',
      normalMapStrength: 1,
      tiling: 4,
      smoothness: 0.678,
      name: 'Polished Wood',
      id: 7,
    },
    {
      albedo: 'materials/textures/Stone_001_D.png',
      normal: 'materials/textures/Stone_001_N.png',
      thumb: 'materials/thumbs/Thb_Mat_Stone_001b.png',
      normalMapStrength: 1,
      tiling: 1,
      smoothness: 0.57,
      name: 'Stone 001',
      id: 8,
    },
    {
      albedo: 'materials/textures/Stone_D.png',
      normal: 'materials/textures/Stone_N.png',
      r: 'materials/textures/Stone_R.png',
      thumb: 'materials/thumbs/Thb_Mat_Stone_001a.png',
      name: 'Stone',
      id: 9,
    },
    {
      albedo: 'materials/textures/Wood_001_D.png',
      normal: 'materials/textures/Wood_001_N.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_001a.png',
      name: 'Wood 001',
      id: 10,
    },
    {
      albedo: 'materials/textures/Fabric_004_diffuse.png',
      normal: 'materials/textures/Fabric_004_normal.png',
      r: 'materials/textures/Fabric_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_004.png',
      normalMapStrength: 0.868,
      tiling: 1,
      metalness: 0.029,
      smoothness: 0.912,
      name: 'Fabric 004',
      id: 11,
    },
    {
      albedo: 'materials/textures/Leather_005_diffuse.png',
      normal: 'materials/textures/Leather_005_normal.png',
      r: 'materials/textures/Leather_005_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Leather_005.png',
      normalMapStrength: 1,
      tiling: 0.7,
      metalness: 0,
      smoothness: 0.929,
      name: 'Leather 005',
      id: 12,
    },
    {
      albedo: 'materials/textures/Cardboard_001_diffuse.png',
      normal: 'materials/textures/Cardboard_001_normal.png',
      r: 'materials/textures/Cardboard_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Cardboard_001.png',
      normalMapStrength: 1,
      tiling: 0.5,
      metalness: 0.088,
      smoothness: 1,
      name: 'Cardboard 001',
      id: 14,
    },
    {
      albedo: 'materials/textures/Concrete_001_diffuse.png',
      normal: 'materials/textures/Concrete_001_normal.png',
      r: 'materials/textures/Concrete_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Concrete_001.png',
      normalMapStrength: 1,
      tiling: 1,
      metalness: 0.049,
      smoothness: 1,
      name: 'Concrete 001',
      id: 15,
    },
    {
      albedo: 'materials/textures/Concrete_002_diffuse.png',
      normal: 'materials/textures/Concrete_002_normal.png',
      r: 'materials/textures/Concrete_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Concrete_002.png',
      normalMapStrength: 1,
      tiling: 0.5,
      metalness: 0.029,
      smoothness: 1,
      name: 'Concrete 002',
      id: 16,
    },
    {
      albedo: 'materials/textures/Concrete_004_diffuse.png',
      normal: 'materials/textures/Concrete_004_normal.png',
      r: 'materials/textures/Concrete_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Concrete_004.png',
      normalMapStrength: 0.915,
      tiling: 1,
      metalness: 0.029,
      smoothness: 0.833,
      name: 'Concrete 004',
      id: 17,
    },
    {
      albedo: 'materials/textures/Fabric_001_diffuse.png',
      normal: 'materials/textures/Fabric_001_normal.png',
      r: 'materials/textures/Fabric_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_001.png',
      normalMapStrength: 1,
      tiling: 0.75,
      metalness: 0.029,
      smoothness: 0.863,
      name: 'Fabric 001',
      id: 18,
    },
    {
      albedo: 'materials/textures/Fabric_003_diffuse.png',
      normal: 'materials/textures/Fabric_003_normal.png',
      r: 'materials/textures/Fabric_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_003.png',
      normalMapStrength: 1,
      tiling: 0.5,
      metalness: 0.029,
      smoothness: 0.863,
      name: 'Fabric 003',
      id: 19,
    },
    {
      albedo: 'materials/textures/Fabric_010_diffuse.png',
      normal: 'materials/textures/Fabric_010_normal.png',
      r: 'materials/textures/Fabric_010_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_010.png',
      normalMapStrength: 0.519,
      tiling: 0.7,
      metalness: 0.01,
      smoothness: 0.951,
      name: 'Fabric 010',
      id: 20,
    },
    {
      albedo: 'materials/textures/Fabric_011_diffuse.png',
      normal: 'materials/textures/Fabric_011_normal.png',
      r: 'materials/textures/Fabric_011_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_011.png',
      normalMapStrength: 0.95,
      tiling: 0.65,
      metalness: 0.01,
      smoothness: 0.85,
      name: 'Fabric 011',
      id: 21,
    },
    {
      albedo: 'materials/textures/Fabric_012_diffuse.png',
      normal: 'materials/textures/Fabric_012_normal.png',
      r: 'materials/textures/Fabric_012_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_012.png',
      normalMapStrength: 1,
      tiling: 0.6,
      metalness: 0.01,
      smoothness: 0.961,
      name: 'Fabric 012',
      id: 22,
    },
    {
      albedo: 'materials/textures/Fabric_014_diffuse.png',
      normal: 'materials/textures/Fabric_014_normal.png',
      r: 'materials/textures/Fabric_014_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_014.png',
      normalMapStrength: 1,
      tiling: 0.25,
      metalness: 0.01,
      smoothness: 0.795,
      name: 'Fabric 014',
      id: 23,
    },
    {
      albedo: 'materials/textures/Fabric_015_diffuse.png',
      normal: 'materials/textures/Fabric_015_normal.png',
      r: 'materials/textures/Fabric_015_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_015.png',
      normalMapStrength: 1,
      tiling: 0.35,
      metalness: 0.01,
      smoothness: 0.926,
      name: 'Fabric 015',
      id: 24,
    },
    {
      albedo: 'materials/textures/Leather_001_diffuse.png',
      normal: 'materials/textures/Leather_001_normal.png',
      r: 'materials/textures/Leather_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Leather_001.png',
      normalMapStrength: 1,
      tiling: 0.35,
      metalness: 0,
      smoothness: 0.873,
      name: 'Leather 001',
      id: 25,
    },
    {
      albedo: 'materials/textures/Leather_003_diffuse.png',
      normal: 'materials/textures/Leather_003_normal.png',
      r: 'materials/textures/Leather_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Leather_003.png',
      normalMapStrength: 1,
      tiling: 0.35,
      metalness: 0,
      smoothness: 0.879,
      name: 'Leather 003',
      id: 26,
    },
    {
      albedo: 'materials/textures/Leather_004_diffuse.png',
      normal: 'materials/textures/Leather_004_normal.png',
      r: 'materials/textures/Leather_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Leather_004.png',
      normalMapStrength: 1,
      tiling: 0.5,
      metalness: 0,
      smoothness: 0.728,
      name: 'Leather 004',
      id: 27,
    },
    {
      albedo: 'materials/textures/Metal_001_diffuse.png',
      normal: 'materials/textures/Metal_001_normal.png',
      r: 'materials/textures/Metal_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Metal_001.png',
      normalMapStrength: 0.853,
      tiling: 1,
      metalness: 0.8,
      smoothness: 0.751,
      name: 'Metal 001',
      id: 28,
    },
    {
      albedo: 'materials/textures/Metal_003_diffuse.png',
      normal: 'materials/textures/Metal_003_normal.png',
      r: 'materials/textures/Metal_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Metal_003.png',
      normalMapStrength: 0.541,
      tiling: 1.66,
      metalness: 0.45,
      smoothness: 0.435,
      name: 'Metal 003',
      id: 29,
    },
    {
      albedo: 'materials/textures/Plastic_001_diffuse.png',
      normal: 'materials/textures/Plastic_001_normal.png',
      r: 'materials/textures/Plastic_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Plastic_001.png',
      normalMapStrength: 1,
      tiling: 0.75,
      metalness: 0.01,
      smoothness: 0.922,
      name: 'Plastic 001',
      id: 30,
    },
    {
      albedo: 'materials/textures/Stucco_001_diffuse.png',
      normal: 'materials/textures/Stucco_001_normal.png',
      r: 'materials/textures/Stucco_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Stucco_001.png',
      normalMapStrength: 1,
      tiling: 0.65,
      metalness: 0,
      smoothness: 1,
      name: 'Stucco 001',
      id: 31,
    },
    {
      albedo: 'materials/textures/Stucco_002_diffuse.png',
      normal: 'materials/textures/Stucco_002_normal.png',
      r: 'materials/textures/Stucco_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Stucco_002.png',
      normalMapStrength: 0.75,
      tiling: 0.5,
      metalness: 0,
      smoothness: 0.658,
      name: 'Stucco 002',
      id: 32,
    },
    {
      albedo: 'materials/textures/Wood_001_diffuse.png',
      normal: 'materials/textures/Wood_001_normal.png',
      r: 'materials/textures/Wood_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_001.png',
      normalMapStrength: 1,
      tiling: 0.5,
      metalness: 0,
      smoothness: 0.965,
      name: 'Wood 001',
      id: 33,
    },
    {
      albedo: 'materials/textures/Wood_002_diffuse.png',
      normal: 'materials/textures/Wood_002_normal.png',
      r: 'materials/textures/Wood_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_002.png',
      normalMapStrength: 0.837,
      tiling: 1,
      metalness: 0,
      smoothness: 0.833,
      name: 'Wood 002',
      id: 34,
    },
    {
      albedo: 'materials/textures/Wood_003_diffuse.png',
      normal: 'materials/textures/Wood_003_normal.png',
      r: 'materials/textures/Wood_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_003.png',
      normalMapStrength: 0.938,
      tiling: 1,
      metalness: 0,
      smoothness: 1,
      name: 'Wood 003',
      id: 35,
    },
    {
      albedo: 'materials/textures/Concrete_003_diffuse.png',
      normal: 'materials/textures/Concrete_003_normal.png',
      r: 'materials/textures/Concrete_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Concrete_003.png',
      normalMapStrength: 1,
      tiling: 1,
      metalness: 0,
      smoothness: 0.503,
      name: 'Concrete 003',
      id: 36,
    },
    {
      albedo: 'materials/textures/Fabric_002_diffuse.png',
      normal: 'materials/textures/Fabric_002_normal.png',
      r: 'materials/textures/Fabric_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_002.png',
      normalMapStrength: 0.486,
      tiling: 1,
      metalness: 0,
      smoothness: 0.791,
      name: 'Fabric 002',
      id: 37,
    },
    {
      albedo: 'materials/textures/Fabric_005_diffuse.png',
      normal: 'materials/textures/Fabric_005_normal.png',
      r: 'materials/textures/Fabric_005_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_005.png',
      normalMapStrength: 0.721,
      tiling: 1,
      metalness: 0,
      smoothness: 0.549,
      name: 'Fabric 005',
      id: 38,
    },
    {
      albedo: 'materials/textures/Fabric_006_diffuse.png',
      normal: 'materials/textures/Fabric_006_normal.png',
      r: 'materials/textures/Fabric_006_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_006.png',
      normalMapStrength: 0.853,
      tiling: 1,
      metalness: 0,
      smoothness: 0.875,
      name: 'Fabric 006',
      id: 39,
    },
    {
      albedo: 'materials/textures/Fabric_007_diffuse.png',
      normal: 'materials/textures/Fabric_007_Denim_normal.png',
      r: 'materials/textures/Fabric_007_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_007.png',
      normalMapStrength: 0.278,
      tiling: 1,
      metalness: 0,
      smoothness: 0.766,
      name: 'Fabric 007',
      id: 40,
    },
    {
      albedo: 'materials/textures/Fabric_008_diffuse.png',
      normal: 'materials/textures/Fabric_008_normal.png',
      r: 'materials/textures/Fabric_008_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_008.png',
      normalMapStrength: 0.402,
      tiling: 1,
      metalness: 0,
      smoothness: 0.541,
      name: 'Fabric 008',
      id: 41,
    },
    {
      albedo: 'materials/textures/Fabric_009_diffuse.png',
      normal: 'materials/textures/Fabric_009_normal.png',
      r: 'materials/textures/Fabric_009_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_009.png',
      normalMapStrength: 0.457,
      tiling: 1,
      metalness: 0,
      smoothness: 0.588,
      name: 'Fabric 009',
      id: 42,
    },
    {
      albedo: 'materials/textures/Fabric_013_diffuse.png',
      normal: 'materials/textures/Fabric_013_normal.png',
      r: 'materials/textures/Fabric_013_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Fabric_013.png',
      normalMapStrength: 0.758,
      tiling: 0.95,
      metalness: 0,
      smoothness: 0.511,
      name: 'Fabric 013',
      id: 43,
    },
    {
      albedo: 'materials/textures/Leather_002_diffuse.png',
      normal: 'materials/textures/Leather_002_normal.png',
      r: 'materials/textures/Leather_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Leather_002.png',
      normalMapStrength: 0.806,
      tiling: 0.7,
      metalness: 0,
      smoothness: 0.711,
      name: 'Leather 002',
      id: 44,
    },
    {
      albedo: 'materials/textures/Metal_002_diffuse.png',
      normal: 'materials/textures/Metal_002_normal.png',
      r: 'materials/textures/Metal_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Metal_002.png',
      normalMapStrength: 0.402,
      tiling: 1.66,
      metalness: 1,
      smoothness: 0.559,
      name: 'Metal 002',
      id: 45,
    },
    {
      albedo: 'materials/textures/Metal_004_diffuse.png',
      normal: 'materials/textures/Metal_004_normal.png',
      r: 'materials/textures/Metal_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Metal_004.png',
      normalMapStrength: 0.681,
      tiling: 1,
      metalness: 1,
      smoothness: 0.334,
      name: 'Metal 004',
      id: 46,
    },
    {
      albedo: 'materials/textures/Paper_001_diffuse.png',
      normal: 'materials/textures/Paper_001_normal.png',
      r: 'materials/textures/Paper_001_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Paper_001.png',
      normalMapStrength: 0.62,
      tiling: 0.7,
      metalness: 0,
      smoothness: 0.618,
      name: 'Paper 001',
      id: 47,
    },
    {
      albedo: 'materials/textures/Paper_002_diffuse.png',
      normal: 'materials/textures/Paper_002_normal.png',
      r: 'materials/textures/Paper_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Paper_002.png',
      normalMapStrength: 0.62,
      tiling: 0.7,
      metalness: 0,
      smoothness: 0.618,
      name: 'Paper 002',
      id: 48,
    },
    {
      albedo: 'materials/textures/Paper_003_diffuse.png',
      normal: 'materials/textures/Paper_003_normal.png',
      r: 'materials/textures/Paper_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Paper_003.png',
      normalMapStrength: 0.82,
      tiling: 1,
      metalness: 0,
      smoothness: 0.715,
      name: 'Paper 003',
      id: 49,
    },
    {
      albedo: 'materials/textures/Paper_004_diffuse.png',
      normal: 'materials/textures/Paper_004_normal.png',
      r: 'materials/textures/Paper_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Paper_004.png',
      normalMapStrength: 0.673,
      tiling: 1,
      metalness: 0,
      smoothness: 0.358,
      name: 'Paper 004',
      id: 50,
    },
    {
      albedo: 'materials/textures/Paper_005_diffuse.png',
      normal: 'materials/textures/Paper_005_normal.png',
      r: 'materials/textures/Paper_005_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Paper_005.png',
      normalMapStrength: 0.875,
      tiling: 1,
      metalness: 0,
      smoothness: 0.645,
      name: 'Paper 005',
      id: 51,
    },
    {
      albedo: 'materials/textures/Plastic_002_diffuse.png',
      normal: 'materials/textures/Plastic_002_normal.png',
      r: 'materials/textures/Plastic_002_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Plastic_002.png',
      normalMapStrength: 0.673,
      tiling: 1,
      metalness: 0,
      smoothness: 0.607,
      name: 'Plastic 002',
      id: 52,
    },
    {
      albedo: 'materials/textures/Plastic_003_diffuse.png',
      normal: 'materials/textures/Plastic_003_normal.png',
      r: 'materials/textures/Plastic_003_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Plastic_003.png',
      normalMapStrength: 0.844,
      tiling: 1,
      metalness: 0,
      smoothness: 0.467,
      name: 'Plastic 003',
      id: 53,
    },
    {
      albedo: 'materials/textures/Plastic_004_diffuse.png',
      normal: 'materials/textures/Plastic_004_normal.png',
      r: 'materials/textures/Plastic_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Plastic_004.png',
      normalMapStrength: 0.914,
      tiling: 1,
      metalness: 0,
      smoothness: 0.436,
      name: 'Plastic 004',
      id: 54,
    },
    {
      albedo: 'materials/textures/Wood_004_diffuse.png',
      normal: 'materials/textures/Wood_004_normal.png',
      r: 'materials/textures/Wood_004_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_004.png',
      normalMapStrength: 0.837,
      tiling: 1.51,
      metalness: 0,
      smoothness: 0.844,
      name: 'Wood 004',
      id: 55,
    },
    {
      albedo: 'materials/textures/Wood_005_diffuse.png',
      normal: 'materials/textures/Wood_005_normal.png',
      r: 'materials/textures/Wood_005_smoothness.png',
      thumb: 'materials/thumbs/Thb_Mat_Wood_005.png',
      normalMapStrength: 0.984,
      tiling: 1.11,
      metalness: 0,
      smoothness: 0.883,
      name: 'Wood 005',
      id: 56,
    },
  ],
  colors: [
    {
      name: 'Science Book',
      hex: ['#8CB369', '#F4E285', '#F4A259', '#5B8E7D'],
    },
    {
      name: 'Highlighter',
      hex: ['#12FF01', '#FF01FB', '#02A9EA', '#FAFF00'],
    },
    {
      name: 'Mint Fruit',
      hex: ['#CD8FFF', '#B7FDFE', '#5EF3C3', '#2B976C'],
    },
    {
      name: 'Malibu',
      hex: ['#F79256', '#FBD1A2', '#7DCFB6', '#00B2CA'],
    },
    {
      name: 'Burnished',
      hex: ['#6B2D5C', '#F0386B', '#FF5376', '#F8C0C8'],
    },
    {
      name: 'Racer',
      hex: ['#A63446', '#BDDDEF', '#0C6291', '#000004'],
    },
    {
      name: 'Harbor',
      hex: ['#042A2B', '#5EB1BF', '#CDEDF6', '#EF7B45'],
    },
    {
      name: 'Carnival',
      hex: ['#EF476F', '#FFD166', '#06D6A0', '#118AB2'],
    },
    {
      name: 'Veracruz',
      hex: ['#533A71', '#6184D8', '#50C5B7', '#9CEC5B'],
    },
    {
      name: 'Kingly',
      hex: ['#3D348B', '#7678ED', '#F7B801', '#F18701'],
    },
    {
      name: 'Blooming Rubies',
      hex: ['#89023E', '#CC7178', '#FFD9DA', '#F3E1DD'],
    },
    {
      name: 'Scuba',
      hex: ['#A4036F', '#048BA8', '#16DB93', '#EFEA5A'],
    },
    {
      name: 'Candy Lane',
      hex: ['#EAC435', '#345995', '#03CEA4', '#FB4D3D'],
    },
    // { name: 'Green', hex: '#99D1AB' },
    // { name: 'Green', hex: '#B1D570' },
    // { name: 'Green', hex: '#23B07F' },
    // { name: 'Green', hex: '#16964A' },
    // { name: 'Green', hex: '#19603C' },
    // { name: 'Green', hex: '#394824' },
    //
    // { name: 'Blue', hex: '#D8F6FF' },
    // { name: 'Blue', hex: '#B3EFFF' },
    // { name: 'Blue', hex: '#66C9D8' },
    // { name: 'Blue', hex: '#66CCED' },
    // { name: 'Blue', hex: '#62C7CC' },
    // { name: 'Blue', hex: '#73CEE9' },
    // { name: 'Blue', hex: '#187183' },
    // { name: 'Blue', hex: '#165999' },
    // { name: 'Blue', hex: '#18484D' },
    //
    // { name: 'Purple', hex: '#F3E4FF' },
    // { name: 'Purple', hex: '#EBC2F9' },
    // { name: 'Purple', hex: '#D1AEE5' },
    // { name: 'Purple', hex: '#A984BF' },
    // { name: 'Purple', hex: '#B27DD2' },
    // { name: 'Purple', hex: '#916AAE' },
    // { name: 'Purple', hex: '#9634AB' },
    // { name: 'Purple', hex: '#7D3994' },
    // { name: 'Purple', hex: '#AC0CE5' },
    //
    // { name: 'Pink', hex: '#FFDAD9' },
    // { name: 'Pink', hex: '#F8B0D0' },
    // { name: 'Pink', hex: '#F1AEC8' },
    // { name: 'Pink', hex: '#EC9FB3' },
    // { name: 'Pink', hex: '#F0758C' },
    // { name: 'Pink', hex: '#E785B6' },
    // { name: 'Pink', hex: '#EB5FAC' },
    // { name: 'Pink', hex: '#DB798B' },
    // { name: 'Pink', hex: '#EF387D' },
    //
    // { name: 'B&W', hex: '#FFFFFF' },
    // { name: 'B&W', hex: '#DFDFDF' },
    // { name: 'B&W', hex: '#BCBCBC' },
    // { name: 'B&W', hex: '#A0A0A0' },
    // { name: 'B&W', hex: '#808080' },
    // { name: 'B&W', hex: '#606060' },
    // { name: 'B&W', hex: '#404040' },
    // { name: 'B&W', hex: '#202020' },
    // { name: 'B&W', hex: '#000000' },
    //
    // { name: 'Brown', hex: '#DFD6BF' },
    // { name: 'Brown', hex: '#AA9469' },
    // { name: 'Brown', hex: '#D3C7A8' },
    // { name: 'Brown', hex: '#816748' },
    // { name: 'Brown', hex: '#855236' },
    // { name: 'Brown', hex: '#9F8F5E' },
    // { name: 'Brown', hex: '#A1733E' },
    // { name: 'Brown', hex: '#3E2C1C' },
    //
    // { name: 'Skin Tones', hex: '#332317' },
    // { name: 'Skin Tones', hex: '#623D1D' },
    // { name: 'Skin Tones', hex: '#B0684C' },
    // { name: 'Skin Tones', hex: '#543622' },
    // { name: 'Skin Tones', hex: '#B88859' },
    // { name: 'Skin Tones', hex: '#CF9C7C' },
    // { name: 'Skin Tones', hex: '#DCB079' },
    // { name: 'Skin Tones', hex: '#F7E1A9' },
    // { name: 'Skin Tones', hex: '#F7DCC3' },
    //
    // { name: 'Metals', hex: '#F6F6F6' },
    // { name: 'Metals', hex: '#999997' },
    // { name: 'Metals', hex: '#D0CFD2' },
    // { name: 'Metals', hex: '#979497' },
    // { name: 'Metals', hex: '#9C6C52' },
    // { name: 'Metals', hex: '#AAC6C8' },
    // { name: 'Metals', hex: '#D5CB9B' },
    // { name: 'Metals', hex: '#444442' },
    // { name: 'Metals', hex: '#38221B' },
    //
    // { name: 'Earth Stones', hex: '#382D24' },
    // { name: 'Earth Stones', hex: '#695B4F' },
    // { name: 'Earth Stones', hex: '#492B1B' },
    // { name: 'Earth Stones', hex: '#6C4C20' },
    // { name: 'Earth Stones', hex: '#6C3410' },
    // { name: 'Earth Stones', hex: '#C47100' },
    // { name: 'Earth Stones', hex: '#363E20' },
    // { name: 'Earth Stones', hex: '#668741' },
    // { name: 'Earth Stones', hex: '#3E4951' },
    //
    // { name: 'GrassLand', hex: '#B1D8A6' },
    // { name: 'GrassLand', hex: '#C5B183' },
    // { name: 'GrassLand', hex: '#618740' },
    // { name: 'GrassLand', hex: '#A1B72D' },
    // { name: 'GrassLand', hex: '#807F61' },
    // { name: 'GrassLand', hex: '#84A44D' },
    // { name: 'GrassLand', hex: '#8A9360' },
    // { name: 'GrassLand', hex: '#4D503D' },
    // { name: 'GrassLand', hex: '#82A2CC' },
    //
    // { name: 'Forest', hex: '#1C2B0C' },
    // { name: 'Forest', hex: '#2A3C63' },
    // { name: 'Forest', hex: '#394823' },
    // { name: 'Forest', hex: '#35601E' },
    // { name: 'Forest', hex: '#005953' },
    // { name: 'Forest', hex: '#5C612C' },
    // { name: 'Forest', hex: '#33651F' },
    // { name: 'Forest', hex: '#BFF147' },
    // { name: 'Forest', hex: '#CAAB72' },
    //
    // { name: 'Sun Set', hex: '#FFF6EA' },
    // { name: 'Sun Set', hex: '#FFDEBB' },
    // { name: 'Sun Set', hex: '#F5F38A' },
    // { name: 'Sun Set', hex: '#FE8B36' },
    // { name: 'Sun Set', hex: '#FE554D' },
    // { name: 'Sun Set', hex: '#E93F2E' },
    // { name: 'Sun Set', hex: '#80414B' },
    // { name: 'Sun Set', hex: '#4A4566' },
    // { name: 'Sun Set', hex: '#052D3C' },
    //
    // { name: 'Montana', hex: '#BF946B' },
    // { name: 'Montana', hex: '#E09F4A' },
    // { name: 'Montana', hex: '#E8BD2B' },
    // { name: 'Montana', hex: '#516716' },
    // { name: 'Montana', hex: '#353E16' },
    // { name: 'Montana', hex: '#00A6BB' },
    // { name: 'Montana', hex: '#A1C8C4' },
    // { name: 'Montana', hex: '#4A5777' },
    // { name: 'Montana', hex: '#EB725D' },
    //
    // { name: 'Appalachian', hex: '#D9D5AF' },
    // { name: 'Appalachian', hex: '#BDB69E' },
    // { name: 'Appalachian', hex: '#94C25C' },
    // { name: 'Appalachian', hex: '#68974B' },
    // { name: 'Appalachian', hex: '#213325' },
    // { name: 'Appalachian', hex: '#33542B' },
    // { name: 'Appalachian', hex: '#6BBFE4' },
    // { name: 'Appalachian', hex: '#3871BD' },
    //
    // { name: 'Pond Water', hex: '#E7E7E9' },
    // { name: 'Pond Water', hex: '#B6EEF6' },
    // { name: 'Pond Water', hex: '#38A48D' },
    // { name: 'Pond Water', hex: '#4DB697' },
    // { name: 'Pond Water', hex: '#8E9E37' },
    // { name: 'Pond Water', hex: '#4C9755' },
    // { name: 'Pond Water', hex: '#1F5650' },
    // { name: 'Pond Water', hex: '#6784BA' },
    // { name: 'Pond Water', hex: '#4B5E7C' },
    //
    // { name: 'Crazy', hex: '#FF34EC' },
    // { name: 'Crazy', hex: '#B234FF' },
    // { name: 'Crazy', hex: '#7E99FF' },
    // { name: 'Crazy', hex: '#5AC9FF' },
    // { name: 'Crazy', hex: '#44FFED' },
    // { name: 'Crazy', hex: '#74FFC4' },
    // { name: 'Crazy', hex: '#FFC745' },
    // { name: 'Crazy', hex: '#FF709C' },
    //
    // { name: 'Ice', hex: '#DAE2DE' },
    // { name: 'Ice', hex: '#D6EAEB' },
    // { name: 'Ice', hex: '#CAE6EF' },
    // { name: 'Ice', hex: '#CBD8D6' },
    // { name: 'Ice', hex: '#B1DAE5' },
    // { name: 'Ice', hex: '#BFCFD1' },
    // { name: 'Ice', hex: '#A8D0D7' },
    // { name: 'Ice', hex: '#B1C8CD' },
    // { name: 'Ice', hex: '#8ECDDF' },
    //
    // { name: 'Volcano', hex: '#E3E3E3' },
    // { name: 'Volcano', hex: '#97999E' },
    // { name: 'Volcano', hex: '#5C5C5C' },
    // { name: 'Volcano', hex: '#8E8E8E' },
    // { name: 'Volcano', hex: '#B6A494' },
    // { name: 'Volcano', hex: '#452228' },
    // { name: 'Volcano', hex: '#E63905' },
    // { name: 'Volcano', hex: '#F9BB0C' },
  ],
  accents: [
    {
      thumb: 'accents/thumbnails/Icon_Accent_001.png',
      name: 'Circle 001',
      url: 'accents/meshes/Accent_001.babylon',
      id: 1,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_008.png',
      name: 'Corners Round 01',
      url: 'accents/meshes/Accent_008.babylon',
      id: 8,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_007.png',
      name: 'Corners Square 01',
      url: 'accents/meshes/Accent_007.babylon',
      id: 7,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_009.png',
      name: 'Corners Triangle 01',
      url: 'accents/meshes/Accent_009.babylon',
      id: 9,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_002.png',
      name: 'Star 001',
      url: 'accents/meshes/Accent_002.babylon',
      id: 2,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_003.png',
      name: 'StripesD 01',
      url: 'accents/meshes/Accent_003.babylon',
      id: 3,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_004.png',
      name: 'StripesD 02',
      url: 'accents/meshes/Accent_004.babylon',
      id: 4,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_005.png',
      name: 'StripesH 01',
      url: 'accents/meshes/Accent_005.babylon',
      id: 5,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_006.png',
      name: 'StripesV 01',
      url: 'accents/meshes/Accent_006.babylon',
      id: 6,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_010.png',
      name: 'Circle 002',
      url: 'accents/meshes/Accent_010.babylon',
      id: 10,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_011.png',
      name: 'Corners Round 02',
      url: 'accents/meshes/Accent_011.babylon',
      id: 11,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_012.png',
      name: 'Corners Square 02',
      url: 'accents/meshes/Accent_012.babylon',
      id: 12,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_013.png',
      name: 'Corners Triangle 02',
      url: 'accents/meshes/Accent_013.babylon',
      id: 13,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_014.png',
      name: 'Star 002',
      url: 'accents/meshes/Accent_014.babylon',
      id: 14,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_015.png',
      name: 'Star 003',
      url: 'accents/meshes/Accent_015.babylon',
      id: 15,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_016.png',
      name: 'Star 004',
      url: 'accents/meshes/Accent_016.babylon',
      id: 16,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_017.png',
      name: 'Star 005',
      url: 'accents/meshes/Accent_017.babylon',
      id: 17,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_018.png',
      name: 'Star 006',
      url: 'accents/meshes/Accent_018.babylon',
      id: 18,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_019.png',
      name: 'Star 007',
      url: 'accents/meshes/Accent_019.babylon',
      id: 19,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_020.png',
      name: 'Star 008',
      url: 'accents/meshes/Accent_020.babylon',
      id: 20,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_021.png',
      name: 'Star 009',
      url: 'accents/meshes/Accent_021.babylon',
      id: 21,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_022.png',
      name: 'StripesD 03',
      url: 'accents/meshes/Accent_022.babylon',
      id: 22,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_023.png',
      name: 'StripesD 04',
      url: 'accents/meshes/Accent_023.babylon',
      id: 23,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_024.png',
      name: 'StripesH 02',
      url: 'accents/meshes/Accent_024.babylon',
      id: 24,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_025.png',
      name: 'StripesH 03',
      url: 'accents/meshes/Accent_025.babylon',
      id: 25,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_026.png',
      name: 'StripesV 02',
      url: 'accents/meshes/Accent_026.babylon',
      id: 26,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_027.png',
      name: 'StripesV 03',
      url: 'accents/meshes/Accent_027.babylon',
      id: 27,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_028.png',
      name: 'Dots 01',
      url: 'accents/meshes/Accent_028.babylon',
      id: 28,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_029.png',
      name: 'Dots 02',
      url: 'accents/meshes/Accent_029.babylon',
      id: 29,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_030.png',
      name: 'Corners Triangle 03',
      url: 'accents/meshes/Accent_030.babylon',
      id: 30,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_031.png',
      name: 'Dots 03',
      url: 'accents/meshes/Accent_031.babylon',
      id: 31,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_032.png',
      name: 'Floral Stem Border 003',
      url: 'accents/meshes/Accent_032.babylon',
      id: 32,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_033.png',
      name: 'Floral Stem Border 004',
      url: 'accents/meshes/Accent_033.babylon',
      id: 33,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_034.png',
      name: 'Floral Stem Border 001',
      url: 'accents/meshes/Accent_034.babylon',
      id: 34,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_035.png',
      name: 'Floral Stem Border 002',
      url: 'accents/meshes/Accent_035.babylon',
      id: 35,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_036.png',
      name: 'Floral Stem 001',
      url: 'accents/meshes/Accent_036.babylon',
      id: 36,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_037.png',
      name: 'Floral Stem 002',
      url: 'accents/meshes/Accent_037.babylon',
      id: 37,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_038.png',
      name: 'Floral Stem 003',
      url: 'accents/meshes/Accent_038.babylon',
      id: 38,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_039.png',
      name: 'Floral Stem 004',
      url: 'accents/meshes/Accent_039.babylon',
      id: 39,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_040.png',
      name: 'Floral Stem 005',
      url: 'accents/meshes/Accent_040.babylon',
      id: 40,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_041.png',
      name: 'Floral Stem 006',
      url: 'accents/meshes/Accent_041.babylon',
      id: 41,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_042.png',
      name: 'Floral Stem 007',
      url: 'accents/meshes/Accent_042.babylon',
      id: 42,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_043.png',
      name: 'Floral Stem 008',
      url: 'accents/meshes/Accent_043.babylon',
      id: 43,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_044.png',
      name: 'Floral Stem 009',
      url: 'accents/meshes/Accent_044.babylon',
      id: 44,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_045.png',
      name: 'Bird 001',
      url: 'accents/meshes/Accent_045.babylon',
      id: 45,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_046.png',
      name: 'Bird 002',
      url: 'accents/meshes/Accent_046.babylon',
      id: 46,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_047.png',
      name: 'Bird 003',
      url: 'accents/meshes/Accent_047.babylon',
      id: 47,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_048.png',
      name: 'Bird 004',
      url: 'accents/meshes/Accent_048.babylon',
      id: 48,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_049.png',
      name: 'Bird 005',
      url: 'accents/meshes/Accent_049.babylon',
      id: 49,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_050.png',
      name: 'Bird 006',
      url: 'accents/meshes/Accent_050.babylon',
      id: 50,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_051.png',
      name: 'Butterfly 001',
      url: 'accents/meshes/Accent_051.babylon',
      id: 51,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_052.png',
      name: 'Cat 001',
      url: 'accents/meshes/Accent_052.babylon',
      id: 52,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_053.png',
      name: 'Dog 001',
      url: 'accents/meshes/Accent_053.babylon',
      id: 53,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_054.png',
      name: 'Donkey 001',
      url: 'accents/meshes/Accent_054.babylon',
      id: 54,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_055.png',
      name: 'Elephant 001',
      url: 'accents/meshes/Accent_055.babylon',
      id: 55,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_056.png',
      name: 'Fish 001',
      url: 'accents/meshes/Accent_056.babylon',
      id: 56,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_057.png',
      name: 'Fox 001',
      url: 'accents/meshes/Accent_057.babylon',
      id: 57,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_058.png',
      name: 'Frog 001',
      url: 'accents/meshes/Accent_058.babylon',
      id: 58,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_059.png',
      name: 'Hedgehog 001',
      url: 'accents/meshes/Accent_059.babylon',
      id: 59,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_060.png',
      name: 'Hen 001',
      url: 'accents/meshes/Accent_060.babylon',
      id: 60,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_061.png',
      name: 'Ladybug 001',
      url: 'accents/meshes/Accent_061.babylon',
      id: 61,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_062.png',
      name: 'Owl 001',
      url: 'accents/meshes/Accent_062.babylon',
      id: 62,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_063.png',
      name: 'Raccoon 001',
      url: 'accents/meshes/Accent_063.babylon',
      id: 63,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_064.png',
      name: 'Turtle 001',
      url: 'accents/meshes/Accent_064.babylon',
      id: 64,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_065.png',
      name: 'Radial 001',
      url: 'accents/meshes/Accent_065.babylon',
      id: 65,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_066.png',
      name: 'Radial 002',
      url: 'accents/meshes/Accent_066.babylon',
      id: 66,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_067.png',
      name: 'Bear 001',
      url: 'accents/meshes/Accent_067.babylon',
      id: 67,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_068.png',
      name: 'Bee 001',
      url: 'accents/meshes/Accent_068.babylon',
      id: 68,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_069.png',
      name: 'Camel 001',
      url: 'accents/meshes/Accent_069.babylon',
      id: 69,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_070.png',
      name: 'Dolphin 001',
      url: 'accents/meshes/Accent_070.babylon',
      id: 70,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_071.png',
      name: 'Horse 001',
      url: 'accents/meshes/Accent_071.babylon',
      id: 71,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_072.png',
      name: 'Monkey 001',
      url: 'accents/meshes/Accent_072.babylon',
      id: 72,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_073.png',
      name: 'Penguin 001',
      url: 'accents/meshes/Accent_073.babylon',
      id: 73,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_074.png',
      name: 'Pig 001',
      url: 'accents/meshes/Accent_074.babylon',
      id: 74,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_075.png',
      name: 'Rabbit 001',
      url: 'accents/meshes/Accent_075.babylon',
      id: 75,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_076.png',
      name: 'Rabbit 002',
      url: 'accents/meshes/Accent_076.babylon',
      id: 76,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_077.png',
      name: 'Seahorse 001',
      url: 'accents/meshes/Accent_077.babylon',
      id: 77,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_078.png',
      name: 'Squirrel 001',
      url: 'accents/meshes/Accent_078.babylon',
      id: 78,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_079.png',
      name: 'Brontosaurus 001',
      url: 'accents/meshes/Accent_079.babylon',
      id: 79,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_080.png',
      name: 'Stegosaurus 001',
      url: 'accents/meshes/Accent_080.babylon',
      id: 80,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_081.png',
      name: 'T-Rex 001',
      url: 'accents/meshes/Accent_081.babylon',
      id: 81,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_082.png',
      name: 'Triceratops 001',
      url: 'accents/meshes/Accent_082.babylon',
      id: 82,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_083.png',
      name: 'Clover 001',
      url: 'accents/meshes/Accent_083.babylon',
      id: 83,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_084.png',
      name: 'Mushroom 001',
      url: 'accents/meshes/Accent_084.babylon',
      id: 84,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_085.png',
      name: 'Mushroom 002',
      url: 'accents/meshes/Accent_085.babylon',
      id: 85,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_086.png',
      name: 'Carrot 001',
      url: 'accents/meshes/Accent_086.babylon',
      id: 86,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_087.png',
      name: 'Watermelon 001',
      url: 'accents/meshes/Accent_087.babylon',
      id: 87,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_088.png',
      name: 'Apple 001',
      url: 'accents/meshes/Accent_088.babylon',
      id: 88,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_089.png',
      name: 'Cherries 001',
      url: 'accents/meshes/Accent_089.babylon',
      id: 89,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_090.png',
      name: 'Banana 001',
      url: 'accents/meshes/Accent_090.babylon',
      id: 90,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_091.png',
      name: 'Pineapple 001',
      url: 'accents/meshes/Accent_091.babylon',
      id: 91,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_092.png',
      name: 'Lemon 001',
      url: 'accents/meshes/Accent_092.babylon',
      id: 92,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_093.png',
      name: 'Strawberry 001',
      url: 'accents/meshes/Accent_093.babylon',
      id: 93,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_094.png',
      name: 'Grapes 001',
      url: 'accents/meshes/Accent_094.babylon',
      id: 94,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_095.png',
      name: 'Sun 001',
      url: 'accents/meshes/Accent_095.babylon',
      id: 95,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_096.png',
      name: 'Sun 002',
      url: 'accents/meshes/Accent_096.babylon',
      id: 96,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_097.png',
      name: 'Snowflake 001',
      url: 'accents/meshes/Accent_097.babylon',
      id: 97,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_098.png',
      name: 'Snowflake 002',
      url: 'accents/meshes/Accent_098.babylon',
      id: 98,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_099.png',
      name: 'Flower 001',
      url: 'accents/meshes/Accent_099.babylon',
      id: 99,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_100.png',
      name: 'Flower 002',
      url: 'accents/meshes/Accent_100.babylon',
      id: 100,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_101.png',
      name: 'Flower 003',
      url: 'accents/meshes/Accent_101.babylon',
      id: 101,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_102.png',
      name: 'Flower 004',
      url: 'accents/meshes/Accent_102.babylon',
      id: 102,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_103.png',
      name: 'Flower 005',
      url: 'accents/meshes/Accent_103.babylon',
      id: 103,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_104.png',
      name: 'Flower 006',
      url: 'accents/meshes/Accent_104.babylon',
      id: 104,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_105.png',
      name: 'Cheeseburger',
      url: 'accents/meshes/Accent_105.babylon',
      id: 105,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_106.png',
      name: 'Ice Cream Cone',
      url: 'accents/meshes/Accent_106.babylon',
      id: 106,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_107.png',
      name: 'Hot Dog',
      url: 'accents/meshes/Accent_107.babylon',
      id: 107,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_108.png',
      name: 'Pizza',
      url: 'accents/meshes/Accent_108.babylon',
      id: 108,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_109.png',
      name: 'Pancakes',
      url: 'accents/meshes/Accent_109.babylon',
      id: 109,
    },
    {
      thumb: 'accents/thumbnails/Icon_Accent_110.png',
      name: 'Taco',
      url: 'accents/meshes/Accent_110.babylon',
      id: 110,
    },
  ],
  scenes: [
    {
      thumb: 'templates/thumbnails/Icon_Template_1x1_Half1_Grid.png',
      name: 'Background 1',
      url: 'scenes/meshes/ActivityBackground_001.babylon',
      id: 0,
    },
    {
      thumb: 'templates/thumbnails/Icon_Template_1x1_Half1_Grid.png',
      name: 'Background 2',
      url: 'scenes/meshes/ActivityBackground_002.babylon',
      id: 1,
    },
    {
      thumb: 'templates/thumbnails/Icon_Template_1x1_Half1_Grid.png',
      name: 'Floor',
      url: 'scenes/meshes/Floor.babylon',
      id: 2,
    },
  ],
}
