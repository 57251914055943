import { GetTopicParam, useGetTopics } from "@/hooks/api/dashboard";
import useAppStore from "@/store/useAppStore";
import { Topic } from "@/types";
import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { shallow } from "zustand/shallow";
import { LoadingButton } from "@mui/lab";
import { NoResults } from "@/components";
import TopicItem from "@/pages/topic/search/TopicItem";
import { useNavigate } from "react-router-dom";
import { useBrowserQuery } from "@/hooks/common";
import { PrivacyTypes, TopicTypes } from "@/utils/constants";
import { SVGS } from "@/assets/svgs";

interface Props {
  onClickTopic: (id: string) => void;
  selectedUserID?: string;
}

export default function ShareConnectionSearchResult({ onClickTopic, selectedUserID }: Props) {
  const [page, setPage] = useState(1);
  const [endReached, setEndReached] = useState(false);
  const { setIsPrivacySelected } = useAppStore();
  const [interactionType, topicType, topicList] = useAppStore(
    state => [
      state.home.interactionFilterType,
      state.home.topicFilterType,
      state.home.topicList,
    ],
    shallow
  )

  const navigate = useNavigate();
  const query = useBrowserQuery();
  const topicID = query.get("topicID") || '';
  const getTopics = useGetTopics();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null)

  useEffect(() => {
    if (page === 1 && getTopics.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, getTopics.isSuccess])

  useEffect(() => {
    if (getTopics.isSuccess && getTopics.data && !getTopics.isError) {
      if (getTopics.data.length < 20) {
        setEndReached(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTopics.isSuccess, getTopics.data, getTopics.isError]);

  const topics = useMemo(() => {
    let list = Object.values(topicList);
    list = list.filter(i => TopicTypes[Number(i.topicType)] && PrivacyTypes[Number(i.visibility)]);
    // sort by date updated
    list.sort((a, b) => {
      if (a.dateUpdated > b.dateUpdated) return -1;
      if (a.dateUpdated < b.dateUpdated) return 1;
      return 0;
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicList]);

  useEffect(() => {
    if (!topicList[topicID] && topics.length > 0) {
      onClickTopic(topics[0].topicID)
    }
  }, [topics, topicID])

  useEffect(() => {
    setEndReached(false);
    const param: GetTopicParam = {
      pageNo: 1,
      interaction: interactionType,
      topicType,
    }
    if (selectedUserID) {
      param.searchType = 2;
      param.connID = selectedUserID
    }
    getTopics.mutate(param);
  }, [interactionType, topicType, selectedUserID])

  const onClickShowMore = () => {
    if (endReached || getTopics.isLoading || !topics.length) {
      return;
    }
    const param: GetTopicParam = {
      pageNo: page + 1,
      interaction: interactionType,
      topicType,
    }
    if (selectedUserID) {
      param.searchType = 2;
      param.connID = selectedUserID
    }
    getTopics.mutate(param);
    setPage(page + 1);
  }

  const onRemovedTopic = () => {
    navigate('/share')
  }

  const onClickTopicItem = (topic: Topic) => {
    setIsPrivacySelected(false)
    onClickTopic(topic.topicID);
  }

  const onClickCreateNew = () => {
    navigate(`/topic/create?type=2&privacy=6&connIDs=${selectedUserID}`) // go to create topic page for album with one-to-one type
  }

  if (getTopics.isLoading && page === 1) {
    return (
      <Box mt={1} display="flex" justifyContent={'center'}>
        <CircularProgress />
      </Box>
    )
  }

  if (topics.length == 0 && getTopics.isSuccess) {
    return (
      <Box p={5} display="flex" justifyContent={'center'}>
        <NoResults
          type="custom"
          icon={SVGS.FilterTopicAlbum}
          text="The user didn't create any topic for you."
          buttonTitle="Create new Topic"
          onClick={onClickCreateNew}
        />
      </Box>
    )
  }

  return (
    <>
      <Box flex={1} overflow='auto' ref={scrollRef}>
        {topics.map((item: Topic) => (
          <TopicItem topic={item} key={item.topicID} onClick={() => onClickTopicItem(item)} onRemoved={onRemovedTopic} />
        ))}
        {(page > 1 || !getTopics.isLoading) && (
          <Box m={3} mb={8} display="flex" justifyContent={'center'}>
            <LoadingButton
              variant='text'
              onClick={onClickShowMore}
              loading={getTopics.isLoading && page > 1}
              disabled={endReached}
            >
              {endReached ? 'No more results' : 'Show More'}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </>
  )
}