/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'

export const useDeleteTopic = (topicID: string) => {
  const axios = useContext(AxiosContext)
  return useMutation({
    mutationKey: [ReactQueryKeys.DELETE_TOPIC, topicID],
    mutationFn: async () => {
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicUpdate',
        'topicData[action]': 'delete',
        'topicData[topicID]': topicID,
        format: 'json',
      })
      return response.data
    },
  })
}
