/* eslint-disable no-prototype-builtins */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TopicTypes } from '../../../utils/constants'
import * as BABYLON from '@babylonjs/core'
import { Inspector } from '@babylonjs/inspector'
import axios from 'axios'
import qs from 'querystring'
import { RenderHelper as RH } from './renderHelpers.js'
import { TemplateSets } from '@/utils/pattern-assets'

/** @type {BABYLON.Scene} */
let scene
/** @type {BABYLON.Engine} */
let engine
/** @type {BABYLON.ArcRotateCamera} */
let camera
let currentItem = null

let patternProps = {
  currentTemplate: null,
  currentAccentID: null,
  currentSceneID: null,
  boarderWidth: 0,
  boarderType: 'square',
}

let isTemplateLoading = false
let isAccentLoading = false
let isRestoring = false

/** @type {BABYLON.FreeCamera} */
let onEdit
let onPatternRestore
let historyIndex = 0
let onAssetsUpdate
let sharedMaterial = null
let sharedFrom = null
let downPoint = new BABYLON.Vector2(0, 0)
let upPoint = new BABYLON.Vector2(0, 0)
let downTime = 0
let didCanvasDown = false
let isCtrlPointer = false
let previousPickedMesh = null
let mergeMode = false
let mode = 'edit' // edit or preview
let setMode
/** @type {Set<BABYLON.Mesh>} */
const meshesSelectedForMerging = new Set()
/** @type {Array<BABYLON.HighlightLayer>} */

let currentMerges = {}
let outlineNeedsUpdate = false
let sourceMesh = null
let startX, startZ

let topicType = null // pattern, gallery, wallart
let grabbable = false

const MaterialMapping = [
  {
    tagName: 'PatternOverlayID',
    test: /^template_/,
    prefix: 'template',
  },
  {
    tagName: 'PatternOverlayAccentID',
    test: /^accent_/,
    prefix: 'accent',
  },
  {
    tagName: 'PatternOverlaySceneID',
    test: /^scene_/,
    prefix: 'scene',
  },
  {
    tagName: 'PatternOverlayBoarderID',
    test: /^boarder_/,
    prefix: 'boarder',
  },
]
const STATIC_ASSETS_DIRECTORY = 'assets/patterns/'
const OVERLAY_TEST_REGEX = /overlay/i

const patternHistory = []
let Assets
let Uploads = []
let fpsElem

export const addUploadAssets = (data) => {
  Uploads = Uploads.concat(data)
}

export const APPLICATION_MODES = {
  NONE: 0,
  APPLY_PRINT: 1,
  APPLY_PATTERN: 2,
  APPLY_MATERIAL: 3,
  APPLY_COLOR: 4,
  MERGE_SURFACES: 5,
  SPLIT_SURFACES: 6,
  ROTATE_TILE: 7,
  PICKER_PICKING: 8,
  PICKER_PICKED: 9,
  APPLY_ACCENT: 10,
  FULL_SCREEN: 11,
}

const pScale = [0, 0.585, 0.585, 0.585, Math.sqrt(0.4)]
const mScale = [0, 0.85, 1, 1, 1]

let applicationMode = APPLICATION_MODES.NONE

export function ResetRenderer() {
  patternHistory.length = 0
  meshesSelectedForMerging.clear()
  historyIndex = 0
  patternProps.currentTemplate = null
  patternProps.currentAccentID = null
  patternProps.currentSceneID = null
  patternProps.boarderWidth = 0
  patternProps.boarderType = 'square'
  sharedMaterial = null
  outlineNeedsUpdate = false
  sharedFrom = null
  currentMerges = {}
}

/**
 * @param {any} initObject
 */
export function StartRenderer(initObject) {
  ResetRenderer()
  engine = new BABYLON.Engine(initObject.canvas, true, {
    preserveDrawingBuffer: true,
    stencil: true,
  })
  grabbable = initObject.canGrab
  createScene()
  onWindowResize(initObject.canvas)
  createLights()
  createCamera()
  setMode = initObject.setMode
  mode = initObject.mode
  RH.Initialize({
    scene: scene,
    engine: engine,
    addNote: initObject.addNote,
  })
  RH.CreateTextPlane()
  Assets = initObject.assets
  setBindings(initObject.canvas)
  onEdit = initObject.onEdit
  onPatternRestore = initObject.onPatternRestore
  onAssetsUpdate = initObject.onAssetsUpdate
  createFPSElem(initObject.canvas)
}

function createFPSElem(_canvas) {
  fpsElem = document.createElement('div')
  fpsElem.style.position = 'absolute'
  fpsElem.style.top = '15px'
  fpsElem.style.left = '15px'
  fpsElem.style.fontSize = '2em'
  fpsElem.style.textShadow = '1px -1px black'
  // _canvas.parentElement.appendChild(fpsElem)
}

// set canvas mode (edit or preview)
export function SetCanvasMode(value) {
  mode = value === 'preview' ? 'preview' : 'edit'
  if (mode === 'preview') {
    scene.meshes.forEach((mesh) => {
      if (mesh.getBehaviorByName('PointerDrag')) {
        mesh._behaviors[0].enabled = false
      }
    })
  }
}

export function SetTopicType(type) {
  topicType = TopicTypes[type].key
}

export function SetCurrentItem(selection, tab) {
  if (applicationMode === APPLICATION_MODES.FULL_SCREEN) toggleCameraForFullscreen(false)

  applicationMode = APPLICATION_MODES.NONE
  clearPartialActions()
  if (['accent', 'template', 'scene', 'boarder'].indexOf(tab) + 1 && !selection?.clicked) return

  function success(result) {
    if (result) savePattern()
  }

  function updateCurrentItem(item) {
    currentItem = item
    if (!currentItem) applicationMode = APPLICATION_MODES.NONE
  }

  switch (tab) {
    case 'template':
      if (
        selection.template != null &&
        (!patternProps.currentTemplate || selection.template != patternProps.currentTemplate.id)
      ) {
        getTemplate(selection.template)
          .then(applyTemplate)
          .then((result) => {
            savePattern(true, () => {
              // reset boarder after changing template for gallery only
              if (topicType === 'gallery') applyBoarders()
            })
          })
      }
      break
    case 'accent':
      if (topicType === 'gallery') {
        if (selection.accent) applicationMode = APPLICATION_MODES.APPLY_ACCENT
        const find = Assets.accents.find((i) => i.id === selection.accent)
        updateCurrentItem(find)
      } else if (selection.accent != patternProps.currentAccentID) {
        applySingleAccent(selection.accent).then(success)
      } else {
        unsetAccent()
        success(true)
      }
      break
    case 'scene':
      if (selection.scene != patternProps.currentSceneID)
        getScene(selection.scene).then(applyScene).then(success)
      else {
        unsetScene()
        success(true)
      }
      break
    case 'material':
      applicationMode = APPLICATION_MODES.APPLY_MATERIAL
      getMaterial(selection.material)
        .then(updateCurrentItem)
        .catch(() => updateCurrentItem(null))
      break
    case 'pattern':
      applicationMode = APPLICATION_MODES.APPLY_PATTERN
      getPrint(selection.pattern)
        .then(updateCurrentItem)
        .catch(() => updateCurrentItem(null))
      break
    case 'print':
      applicationMode = APPLICATION_MODES.APPLY_PRINT
      getPrint(selection.print)
        .then(updateCurrentItem)
        .catch(() => updateCurrentItem(null))
      break
    case 'color':
      if (selection.color) applicationMode = APPLICATION_MODES.APPLY_COLOR
      // eslint-disable-next-line no-case-declarations
      const find = Assets.colors.find((i) => i.hex[0] === selection.color)
      updateCurrentItem(find)
      break
    case 'text':
      return
    case 'boarder':
      // eslint-disable-next-line no-case-declarations
      const int = parseInt(selection.boarder.width)
      // eslint-disable-next-line no-case-declarations
      const factors = [0, 5, 10, 20, 25, 50, 100]
      // eslint-disable-next-line no-case-declarations
      const newBoarderWidth = parseFloat(factors.find((factor) => int <= factor) / 100)
      if (
        patternProps.boarderType !== selection.boarder.type ||
        patternProps.boarderWidth !== newBoarderWidth
      ) {
        patternProps.boarderType = selection.boarder.type
        patternProps.boarderWidth = newBoarderWidth
        updateBoarder()
        savePattern()
      }
      return
    default:
      break
  }
}

function createLights() {
  const light = new BABYLON.DirectionalLight('light1', new BABYLON.Vector3(0, -0.866, 0.5), scene)
  light.intensity = 3
  light.position.set(0, 300, 0)
}

function createScene() {
  scene = new BABYLON.Scene(engine)
  window.scene = scene
  if (grabbable) {
    scene.onKeyboardObservable.add((kbInfo) => {
      switch (kbInfo.type) {
        case BABYLON.KeyboardEventTypes.KEYDOWN:
          if (kbInfo.event.key === 'Alt') {
            scene.defaultCursor = 'grab'
          }
          break
        case BABYLON.KeyboardEventTypes.KEYUP:
          break
      }
    })
  }
}

function createCamera() {
  camera = new BABYLON.ArcRotateCamera(
    'patternEditorCamera',
    -Math.PI / 2,
    Math.PI / 2,
    5,
    new BABYLON.Vector3(0, 0, 0),
    scene,
  )
  camera.setPosition(new BABYLON.Vector3(0, 5, 0))
  camera.setTarget(new BABYLON.Vector3(0, 0, 0))
  camera.attachControl(null, null, false, false)
  camera.upperBetaLimit = 0.2
  camera.lowerBetaLimit = -0.2
  camera.upperAlphaLimit = Math.PI / 2
  camera.lowerAlphaLimit = Math.PI / 2
  camera.upperRadiusLimit = 15
  camera.lowerRadiusLimit = 1.2
  camera.wheelPrecision = 100
  camera.angularSensibilityY = 10000
  camera.radius = 2
}

/**
 * @param {HTMLCanvasElement} canvas
 */
function setBindings(canvas) {
  engine.runRenderLoop(() => {
    if (outlineNeedsUpdate) {
      RH.UpdateTemplateOutline()
      outlineNeedsUpdate = false
    }
    scene.render()
    fpsElem.innerHTML = scene._engine.getFps().toFixed() + ' fps'
  })
  window.addEventListener('resize', () => onWindowResize(canvas))
  canvas.addEventListener('pointerdown', onCanvasDown)
  canvas.addEventListener('pointermove', onCanvasMove)
  canvas.addEventListener('pointerup', onCanvasUp)
}

function onCanvasDown(event) {
  didCanvasDown = true
  downPoint.set(event.clientX, event.clientY)
  downTime = new Date().getTime()
  previousPickedMesh = null
  if (event.ctrlKey && applicationMode !== APPLICATION_MODES.FULL_SCREEN) {
    isCtrlPointer = true
    camera.detachControl()
  }
  startX = event.offsetX
  startZ = event.offsetY
}

function onCanvasMove(event) {
  if (!event.altKey) {
    scene.defaultCursor = 'auto'
  }
  if (event.altKey && didCanvasDown && grabbable) {
    scene.meshes.forEach((mesh) => {
      if (mesh.getBehaviorByName('PointerDrag')) {
        mesh._behaviors[0].enabled = false
      }
    })
    const offsetX = event.offsetX - startX
    const offsetY = event.offsetY - startZ
    const { width, height } = event.srcElement.getBoundingClientRect() // canvas width and height
    const radius = camera.radius
    camera.setTarget(
      new BABYLON.Vector3(
        camera.target._x + (offsetX / width) * radius,
        0,
        camera.target._z - (offsetY / height) * radius,
      ),
    )
    camera.radius = radius
    startX = event.offsetX
    startZ = event.offsetY
    return
  }

  if (mode === 'edit') {
    scene.meshes.forEach((mesh) => {
      if (mesh.getBehaviorByName('PointerDrag')) {
        mesh._behaviors[0].enabled = true
      }
    })
  }

  if (!(didCanvasDown && isCtrlPointer)) return

  const pickingInfo = scene.pick(scene.pointerX, scene.pointerY)
  const pickedMesh = pickingInfo.pickedMesh
  if (!pickedMesh || previousPickedMesh == pickedMesh) return
  switch (applicationMode) {
    case APPLICATION_MODES.APPLY_PRINT:
    case APPLICATION_MODES.APPLY_PATTERN:
      applyPrint(pickedMesh, currentItem)
      break
    case APPLICATION_MODES.APPLY_COLOR:
      // applyColor(pickedMesh, currentItem.hex)
      applyColorArray(pickedMesh, currentItem.hex)
      break
    case APPLICATION_MODES.MERGE_SURFACES:
    case APPLICATION_MODES.SPLIT_SURFACES:
      if (meshesSelectedForMerging.has(pickedMesh)) meshesSelectedForMerging.delete(pickedMesh)
      else {
        if (applicationMode === APPLICATION_MODES.MERGE_SURFACES) {
          if (/^(boarder|template)_/.test(pickedMesh.name)) meshesSelectedForMerging.add(pickedMesh)
        } else if (/_Merge$/.test(pickedMesh.name)) meshesSelectedForMerging.add(pickedMesh)
      }
      RH.ShowHighlightOn(meshesSelectedForMerging)
      break
    case APPLICATION_MODES.PICKER_PICKED:
      copyStyle(pickedMesh, sourceMesh)
      break
    default:
      break
  }

  previousPickedMesh = pickedMesh
}

function onCanvasUp(event) {
  didCanvasDown = false
  upPoint.set(event.clientX, event.clientY)
  //detach camera so that during the ctrl pick event camera doesn't rotate
  if (!isCtrlPointer && applicationMode !== APPLICATION_MODES.FULL_SCREEN)
    camera.attachControl(null, null, false, false)

  if (isCtrlPointer && !mergeMode) {
    isCtrlPointer = false
    savePattern(true, () => {
      applySurfaceProperties()
    })
  }
  isCtrlPointer = false

  //check if this was a clicky type pointer up
  const distSq = BABYLON.Vector2.DistanceSquared(upPoint, downPoint)
  const timeDiff = new Date().getTime() - downTime
  if (distSq > 17 || timeDiff > 400) {
    return
  }

  const pickingInfo = scene.pick(scene.pointerX, scene.pointerY)
  const pickedMesh = pickingInfo.pickedMesh
  if (!pickedMesh) return

  switch (applicationMode) {
    case APPLICATION_MODES.APPLY_MATERIAL:
      applyMaterialOnSameSurfaces(pickedMesh, currentItem)
      break
    case APPLICATION_MODES.APPLY_PRINT:
    case APPLICATION_MODES.APPLY_PATTERN:
      applyPrint(pickedMesh, currentItem)
      break
    case APPLICATION_MODES.APPLY_ACCENT:
      if (/(boarder|accent)/.test(pickedMesh.name)) return
      console.log(`applying accent to ${pickedMesh.name}`)
      if (pickedMesh.metadata.accentID === currentItem.id) {
        RH.EmptySceneOfMeshes(`${pickedMesh.name}_accent`)
        pickedMesh.metadata.accentID = null
      } else {
        applyAccentForMesh(pickedMesh, currentItem, true)
      }
      break
    case APPLICATION_MODES.APPLY_COLOR:
      // applyColor(pickedMesh, currentItem.hex)
      applyColorArray(pickedMesh, currentItem.hex)
      break
    case APPLICATION_MODES.MERGE_SURFACES:
    case APPLICATION_MODES.SPLIT_SURFACES:
      if (meshesSelectedForMerging.has(pickedMesh)) meshesSelectedForMerging.delete(pickedMesh)
      else {
        if (applicationMode === APPLICATION_MODES.MERGE_SURFACES) {
          if (/^(boarder|template)_/.test(pickedMesh.name)) meshesSelectedForMerging.add(pickedMesh)
        } else if (/_Merge$/.test(pickedMesh.name)) meshesSelectedForMerging.add(pickedMesh)
      }
      RH.ShowHighlightOn(meshesSelectedForMerging)
      break
    case APPLICATION_MODES.ROTATE_TILE:
      if (!pickedMesh.metadata.print) return
      if (!pickedMesh.metadata.print.rotation) pickedMesh.metadata.print.rotation = Math.PI / 4
      else {
        pickedMesh.metadata.print.rotation += Math.PI / 4
        pickedMesh.metadata.print.rotation %= Math.PI * 2
      }
      updateTileRotation(pickedMesh)
      break
    case APPLICATION_MODES.PICKER_PICKING:
      sourceMesh = pickedMesh
      setMode(APPLICATION_MODES.PICKER_PICKED)
      break
    case APPLICATION_MODES.PICKER_PICKED:
      copyStyle(pickedMesh, sourceMesh)
      break
    default:
      return
  }

  savePattern(true, () => {
    applySurfaceProperties()
  })
}

/**
 * @param {HTMLCanvasElement} canvas
 */
function onWindowResize(canvas) {
  if (canvas.parentElement) {
    canvas.width = canvas.parentElement.clientWidth
    canvas.height = canvas.parentElement.clientHeight
    engine.resize()
  }
}

function serializePattern(isNew) {
  let data = {
    templateID: patternProps.currentTemplate?.id || 0,
    accentID: patternProps.currentAccentID,
    sceneID: patternProps.currentSceneID,
    boarder: {
      width: patternProps.boarderWidth,
      type: patternProps.boarderType,
    },
  }

  // save min/max bounding box values for all meshes
  // scene.meshes.forEach((mesh) => {
  //   console.log(camera.isCompletelyInFrustum(mesh))
  // })

  // save material IDs and accent IDs for all pattern overlay type (template, scene, accent)
  const materialID = {}
  const accentID = {}

  scene.meshes.forEach((mesh) => {
    if (!OVERLAY_TEST_REGEX.test(mesh.name)) {
      materialID[mesh.name] = mesh.metadata?.material?.id
    }
    if (topicType === 'gallery' && /accent_/.test(mesh.name)) {
      const ns = mesh.name.split('_accent_')
      accentID[ns[0]] = ns[1].split('_')[0]
    }
  })
  data.materialID = materialID
  if (topicType === 'gallery') data.accentID = accentID

  // save merge data
  const merge = []
  const mergedMeshesNames = Object.keys(currentMerges)
  if (mergedMeshesNames.length) {
    mergedMeshesNames.forEach((mergedMeshName) => {
      merge.push(currentMerges[mergedMeshName].join(','))
    })
  }
  data.merge = merge

  // save colors and prints
  const color = {}
  const print = {}
  const position = {}
  scene.meshes.forEach((mesh) => {
    if (!mesh.metadata && mesh.isEnabled()) {
      //this means that this mesh doesn't need to be saved just skip it
      return
    }
    if (/template_Shape_/.test(mesh.name) && !/accent_/.test(mesh.name)) {
      position[mesh.name] = {
        x: mesh.position._x,
        z: mesh.position._z,
      }
    }
    if (mesh.material) {
      if (
        !mesh.material ||
        mesh.material.name === 'default material' ||
        mesh.material === RH.DEFAULT_MATERIAL.standard1 ||
        mesh.material === RH.DEFAULT_MATERIAL.standard2
      )
        return

      // color[mesh.name] = mesh.metadata.colorR
      color[mesh.name] = [
        mesh.metadata.colorR,
        mesh.metadata.colorG,
        mesh.metadata.colorB,
        mesh.metadata.colorA,
      ].filter((value) => value && typeof value === 'object')

      const p = mesh.metadata.print
      const printData = {}
      if (p != null) {
        let printID = p.egoID || p.id
        if (p.hasOwnProperty('egoID')) {
          printID = 'egoID-' + printID
          let imageURL = p.egoFileURL || p.egoImageFullURL
          printData.url = imageURL
        }
        printData.id = printID
        if (p.rotation) printData.rotation = p.rotation
      }
      print[mesh.name] = printData
    }
  })
  data.color = color
  data.print = print
  data.position = position

  // update history
  if (isNew) {
    if (historyIndex < patternHistory.length)
      patternHistory.splice(historyIndex, patternHistory.length)

    if (patternHistory.length > 99) patternHistory.shift()
    patternHistory.push(data)
    historyIndex++
  }

  // afterSavingPattern()
  return data
}

function afterSavingPattern() {
  RH.SavePrintRequirement()
}

/**
 *
 * @param {import('@/types/pattern.js').PatternDraft} patternData
 * @param {boolean} resetHistory
 */
export function RestorePattern(patternData, resetHistory, callback) {
  if (isRestoring || !RH.DEFAULT_MATERIAL.nodeMaterial)
    setTimeout(RestorePattern, 100, patternData, resetHistory, callback)
  else {
    isRestoring = true
    if (!patternData || Object.keys(patternData).length === 0) {
      isRestoring = false
      return
    }

    scene.meshes.forEach((mesh) => {
      if (
        mesh.material &&
        patternData.print &&
        patternData.print[mesh.name] &&
        mesh.material.getTextureBlocks
      ) {
        const block = mesh.material
          .getTextureBlocks()
          .find((b) => b.name === 'yPrintTex' || b.name === 'yCustomPrintTex')
        if (block && block.texture) {
          block.texture.wAng = patternData.print[mesh.name].rotation || 0
        }
      }
      if (mesh.material) {
        mesh.material.markAsDirty(BABYLON.Material.TextureDirtyFlag)
      }
    })

    const templateID = patternData.templateID
    getTemplate(templateID)
      .then((template) => applyTemplate(template))
      .then(() => {
        const sceneID = patternData.sceneID
        return getScene(sceneID)
      })
      .then((sceneObj) => applyScene(sceneObj))
      .then(async () => {
        isRestoring = false
        if (resetHistory) {
          onWindowResize(scene._engine._renderingCanvas)
          patternHistory.length = 0
          patternHistory.push(patternData)
          historyIndex = patternHistory.length
        }
        await applyPatternData(patternData, true)
        SetCanvasMode(mode)
        savePattern(false, () => {
          applySurfaceProperties()
          callback && callback()
        })
      })
      .catch((err) => {
        isRestoring = false
        console.log(err)
      })
  }
}

var getTemplate = function (templateID) {
  return new Promise(function (resolve, reject) {
    if (templateID == null || templateID == undefined) {
      resolve()
      return
    }
    if (topicType === 'gallery') {
      const templateObj = TemplateSets.GalleryTemplates.find(
        (template) => template.id === templateID,
      )
      templateObj ? resolve(templateObj) : reject('No template Found')
    } else {
      const templateObj = TemplateSets.PatternTemplates.find(
        (template) => template.id == templateID,
      )
      templateObj ? resolve(templateObj) : reject('No template Found')
    }
  })
}

var getAccent = function (accentID) {
  return new Promise(function (resolve, reject) {
    if (accentID == null || accentID == undefined) {
      resolve()
      return
    }
    const accentObj = Assets.accents.find((accent) => accent.id == accentID)
    accentObj ? resolve(accentObj) : reject('No accent Found')
  })
}

var getScene = function (sceneID) {
  return new Promise(function (resolve, reject) {
    if (sceneID == null || sceneID == undefined) {
      resolve()
      return
    }
    const accentObj = Assets.scenes.find((scene) => scene.id == sceneID)
    accentObj ? resolve(accentObj) : reject('No scene Found')
  })
}

var getPrint = function (printID) {
  return new Promise(function (resolve, reject) {
    function searchArray(id, arr) {
      for (let i = 0; i < arr.length; i++) {
        if (print[1] == arr[i].egoID) {
          resolve(arr[i])
          return true
        }
      }
    }
    let print = null
    if (typeof printID == 'string') {
      print = printID.split('-')
      if (print[0] === 'egoID') {
        if (searchArray(print[1], Uploads)) return

        const data = {
          format: 'json',
          egoID: print[1],
          requestType: 'egoLoad',
        }
        axios.post('/ajax/objects', qs.stringify(data)).then((res) => {
          res.data.isError ? reject('Ego Item not found') : resolve(res.data.egoObjects)
        })
        return
      }
    }

    let printItem = null
    printItem = Assets.prints.standard.find((printItem) => printItem.id == printID)
    printItem ? resolve(printItem) : reject('Print not found')
  })
}

var getMaterial = function (matID) {
  return new Promise(function (resolve, reject) {
    const material = Assets.materials.find((mat) => mat.id == matID)
    material ? resolve(material) : reject('Material not found')
  })
}

function readAndApplyColor(colorData, mesh) {
  delete mesh.metadata.colorR
  if (colorData && Array.isArray(colorData) && colorData.length > 0) {
    const hexColors = colorData.map(({ r, g, b }) => new BABYLON.Color3(r, g, b).toHexString())

    // Applying colors to the mesh if needed (optional)
    // hexColors.forEach((hexColor) => applyColor(mesh, hexColor));

    applyColorArray(mesh, hexColors)
    // console.log('Hex Colors:', hexColors);

    return true
  } else if (mesh.metadata.print) {
    applyColorFromPrint(mesh, mesh.metadata.print)
    return false
  } else {
    mesh.material &&
      mesh.material.getClassName() === 'NodeMaterial' &&
      RH.ApplyDefaultColor(mesh.material)
    return false
  }
}

function applyPrintAndColor(mesh, print, colorData) {
  applyPrint(mesh, print)
  if (colorData) readAndApplyColor(colorData, mesh)
}

function applyMerges() {
  const mergedMeshNames = []
  const patternData = patternHistory[historyIndex - 1]
  if (patternData.merge) {
    for (let i = 0; i < patternData.merge.length; i++) {
      const mergeOf = patternData.merge[i].split(',')
      const templateRegex = /^template_/i
      let mergedMeshName
      if (templateRegex.test(mergeOf[0])) {
        mergedMeshName = 'template_' + mergeOf[0].replace(/^template_/i, '') + '_Merge'
      } else {
        mergedMeshName = 'boarder_' + mergeOf[0].replace(/^boarder_/i, '') + '_Merge'
      }
      if (
        !currentMerges[mergedMeshName] ||
        currentMerges[mergedMeshName].join(',') !== patternData.merge[i]
      ) {
        disposeMerge(mergedMeshName)
        mergeOf.forEach((meshName) => {
          meshesSelectedForMerging.add(scene.getMeshByName(meshName))
        })
        mergeSelectedMeshes(true)
      }
      mergedMeshNames.push(mergedMeshName)
    }
  }
  Object.keys(currentMerges).forEach((mergedMeshName) => {
    if (mergedMeshNames.indexOf(mergedMeshName) == -1) {
      disposeMerge(mergedMeshName)
    }
  })
  RH.UpdateTemplateOutline()
}

function applySurfaceProperties() {
  sharedMaterial = null
  sharedFrom = null
  const patternData = patternHistory[historyIndex - 1]
  if (!patternData) return
  scene.meshes.forEach(async (mesh) => {
    if (!mesh.metadata) return
    if (patternData.materialID && patternData.materialID[mesh.name]) {
      if (
        !mesh.metadata.material ||
        patternData.materialID[mesh.name] != mesh.metadata.material.id
      ) {
        const material = await getMaterial(patternData.materialID[mesh.name])
        applyMaterial(mesh, material)
      }
    } else {
      unsetMaterial(mesh)
    }

    const printData = (patternData.print || {})[mesh.name]
    const colorData = (patternData.color || {})[mesh.name]

    if (printData && printData.id !== undefined) {
      const id = printData.id
      const possibleEgoID = String(id).split('-')[1]
      if (
        mesh.metadata.print &&
        ((mesh.metadata.print.egoID && mesh.metadata.print.egoID == possibleEgoID) ||
          (!mesh.metadata.print.egoID && mesh.metadata.print.id == id))
      ) {
        readAndApplyColor(colorData, mesh)
      } else if (possibleEgoID) {
        applyPrintAndColor(
          mesh,
          {
            egoID: possibleEgoID,
            egoFileURL: printData.url,
            rotation: printData.rotation || 0,
          },
          colorData,
        )
      } else {
        getPrint(id).then((print) => {
          applyPrintAndColor(
            mesh,
            {
              ...print,
              rotation: printData.rotation || 0,
            },
            colorData,
          )
        })
      }
    } else {
      unsetPrint(mesh)
      readAndApplyColor(colorData, mesh)
    }
  })

  onPatternRestore(patternProps)
}

function applyBoarders() {
  const patternData = patternHistory[historyIndex - 1]
  patternProps.boarderType = patternData.boarder?.type || 'square'
  patternProps.boarderWidth = patternData.boarder?.width || 0
  updateBoarder()
}

function applyPositions() {
  const patternData = patternHistory[historyIndex - 1]
  scene.meshes.forEach(async (mesh) => {
    const position = (patternData.position || {})[mesh.name]
    if (position) {
      mesh.position.x = position.x || 0
      mesh.position.z = position.z || 0
    }
  })
  scene.render()
}

var addPatternData = function (patternData) {
  patternHistory.push(patternData)
  historyIndex++
}
/**
 * @param {import('@/types/pattern.js').PatternDraft} patternData
 */
var applyPatternData = async function (patternData, boarderShouldBeUpdated = false) {
  // addPatternData(patternData)
  applyPositions()

  if (
    patternProps.boarderType !== patternData.boarder?.type ||
    patternProps.boarderWidth !== patternData.boarder?.width ||
    boarderShouldBeUpdated
  ) {
    applyBoarders()
  }

  await applyAccent()

  applyMerges()
  applySurfaceProperties()
}

/**
 *
 * @param {{url: string, name: string}} template
 */
function applyTemplate(template) {
  return new Promise(function (resolve, reject) {
    function changeTemplate() {
      isTemplateLoading = true
      if (patternProps.currentTemplate && template.id == patternProps.currentTemplate.id) {
        isTemplateLoading = false
        resolve(false)
      } else {
        const pathArr = template.url.split('/')
        const fileName = pathArr.pop()
        RH.ClearScene(['template', 'boarder', 'accent'])

        patternProps.currentTemplate = template
        BABYLON.SceneLoader.ImportMesh(
          null,
          STATIC_ASSETS_DIRECTORY + pathArr.join('/') + '/',
          fileName,
          scene,
          (meshes) => {
            const templateMerges = Object.keys(currentMerges)
            for (let i = 0; i < templateMerges.length; i++) {
              if (/^template_/.test(templateMerges[i])) {
                delete currentMerges[templateMerges[i]]
              }
            }
            meshesSelectedForMerging.clear()
            RH.ShowHighlightOn(meshesSelectedForMerging)
            scene.render()

            RH.ProcessMeshes(meshes, `template`, (mesh) => {
              if (topicType === 'gallery' && !OVERLAY_TEST_REGEX.test(mesh.name)) {
                const pointerDragBehavior = new BABYLON.PointerDragBehavior({
                  dragPlaneNormal: new BABYLON.Vector3(0, 0, 0),
                })
                pointerDragBehavior.onDragStartObservable.add((event) => {})
                pointerDragBehavior.onDragObservable.add((event) => {
                  // reset asset item status to prevent double saving in patternHistory after ending drag
                  currentItem = null
                  applicationMode = APPLICATION_MODES.NONE

                  const shapeIndex = mesh.name.split('_')[2]
                  scene.meshes.forEach((i) => {
                    let boarderRegex = new RegExp('^boarder_Shape_' + shapeIndex)
                    let accentRegex = new RegExp('Shape_' + shapeIndex + '_accent')
                    if (boarderRegex.test(i.name)) {
                      i.position.x += event.delta._x
                      i.position.z += event.delta._z
                    }
                    if (accentRegex.test(i.name)) {
                      const ps =
                        mesh.metadata.accentID === 4 ? 1 : pScale[patternProps.currentTemplate.id]
                      const ms =
                        mesh.metadata.accentID === 4 ? 1 : mScale[patternProps.currentTemplate.id]
                      i.position.x += event.delta._x * ps * ms
                      i.position.z += event.delta._z * ps * ms
                    }
                  })
                })
                pointerDragBehavior.onDragEndObservable.add(async (event) => {
                  if (!currentItem) savePattern(true)
                })
                pointerDragBehavior.attach(mesh)
                mesh.addBehavior(pointerDragBehavior)
              }
            })
            let needAssetsUpdate = false
            if (!patternProps.currentTemplate.tiling) {
              const bb = meshes
                .find((mesh) => !OVERLAY_TEST_REGEX.test(mesh.name))
                .getBoundingInfo().boundingBox
              patternProps.currentTemplate.tiling = Math.max(
                bb.extendSizeWorld.z,
                bb.extendSizeWorld.x,
              )
              needAssetsUpdate = true
            }
            if (!patternProps.currentTemplate.hasOwnProperty('uOffset')) {
              const number = parseInt(patternProps.currentTemplate.name.split('x')[0])
              let offset = 0
              if (!isNaN(number)) offset = number % 2 === 0 ? 0 : 0.5
              patternProps.currentTemplate.vOffset = patternProps.currentTemplate.uOffset = offset
              needAssetsUpdate = true
            }
            outlineNeedsUpdate = true
            scene.meshes.forEach((mesh) => {
              //this so that offsets and scales are updated on accents
              if (/^accent_/.test(mesh.name) && !OVERLAY_TEST_REGEX.test(mesh.name)) {
                const printObj = mesh.metadata.print
                if (mesh.metadata.print) {
                  delete mesh.metadata.print
                  applyPrint(mesh, printObj)
                }
              }
            })
            RH.ComputeBoundsOfPattern()
            isTemplateLoading = false
            resolve(true)
            needAssetsUpdate && onAssetsUpdate(Assets)
          },
          null,
          (scene, message, e) => {
            isTemplateLoading = false
            reject(message, e)
          },
        )
      }
    }

    function queueChecker() {
      setTimeout(() => {
        isTemplateLoading ? queueChecker() : changeTemplate()
      }, 100)
    }
    if (template) queueChecker()
    else {
      RH.ClearScene(['template'])
      resolve(false)
    }
  })
}

function applyAccentForMesh(mesh, accentData, clicked = false) {
  return new Promise(function (resolve, reject) {
    RH.EmptySceneOfMeshes(`${mesh.name}_accent`)
    if (!accentData.id || isAccentLoading) resolve(false)
    else if (mesh.metadata.accentID === accentData.id && clicked) {
      mesh.metadata.accentID = null
      resolve(false)
    } else {
      // apply accent to mesh
      isAccentLoading = true
      const pathArr = accentData.url.split('/')
      const fileName = pathArr.pop()
      BABYLON.SceneLoader.ImportMesh(
        null,
        STATIC_ASSETS_DIRECTORY + pathArr.join('/') + '/',
        fileName,
        scene,
        (meshes) => {
          const ps = accentData.id === 4 ? 1 : pScale[patternProps.currentTemplate.id]
          const ms = accentData.id === 4 ? 1 : mScale[patternProps.currentTemplate.id]
          const box = mesh.getBoundingInfo().boundingBox
          const cx = (box.maximumWorld.x + box.minimumWorld.x) / 2
          const cz = (box.maximumWorld.z + box.minimumWorld.z) / 2
          const scale = Math.sqrt(1 / Math.round(1 / (box.maximumWorld.z - box.minimumWorld.z)), 2)
          RH.ProcessMeshes(meshes, `${mesh.name}_accent_${accentData.id}`, (m) => {
            m.position.y = mesh.position._y + 0.01
            m.position.x = cx * ps * ms
            m.position.z = cz * ps * ms
            m.scaling.x = accentData.id === 4 ? scale / 2 : scale
            m.scaling.y = 1
            m.scaling.z = accentData.id === 4 ? scale / 2 : scale
          })
          isAccentLoading = false
          mesh.metadata.accentID = accentData.id
          resolve(true)
          window.requestAnimationFrame(() => (outlineNeedsUpdate = true))
        },
        null,
        (err) => {
          console.log(err)
          isAccentLoading = false
          reject(err)
        },
      )
    }
  })
}

async function applySingleAccent(accentID) {
  return new Promise(function (resolve, reject) {
    isAccentLoading = true
    patternProps.currentAccentID = accentID
    RH.ClearScene(['accent'])
    getAccent(accentID).then((accent) => {
      if (!accent) {
        resolve(true)
        return
      }
      const pathArr = accent.url.split('/')
      const fileName = pathArr.pop()
      patternProps.currentAccentID = accent.id
      RH.ClearScene(['accent'])
      BABYLON.SceneLoader.ImportMesh(
        null,
        STATIC_ASSETS_DIRECTORY + pathArr.join('/') + '/',
        fileName,
        scene,
        (meshes) => {
          RH.ProcessMeshes(meshes, 'accent', (mesh) => (mesh.position.y += 0.05))
          isAccentLoading = false
          window.requestAnimationFrame(() => (outlineNeedsUpdate = true))
          resolve(true)
        },
        null,
        (err) => {
          console.log(err)
          isAccentLoading = false
          reject(err)
        },
      )
    })
  })
}

/**
 * @param {{name: string, url: string, thumb: string}} accent
 */
function applyAccent() {
  const { accentID } = patternHistory[historyIndex - 1]
  return new Promise(function (resolve, reject) {
    async function changeGalleryAccent() {
      // reset all accents before applying accent data
      scene.meshes.forEach((mesh) => {
        if (/^template_/.test(mesh.name)) {
          RH.EmptySceneOfMeshes(`${mesh.name}_accent`)
          mesh.metadata.accentID = null
        }
      })
      for (var key in accentID) {
        const mesh = scene.getMeshByName(key)
        if (!mesh) continue
        if (!accentID[key]) continue
        const accent = await getAccent(accentID[key])
        await applyAccentForMesh(mesh, accent)
      }
      resolve(true)
    }

    async function queueChecker() {
      if (isAccentLoading) resolve(false)
      else if (topicType === 'gallery') {
        await changeGalleryAccent()
      } else {
        applySingleAccent(accentID).then(() => resolve(true))
      }
    }

    if (!accentID || (typeof accentID !== 'number' && Object.keys(accentID).length === 0)) {
      RH.ClearScene(['accent'])
      outlineNeedsUpdate = true
      patternProps.currentAccentID = null
      resolve(false)
    } else {
      queueChecker()
    }
  })
}

/**
 * @param {{name: string, url: string, thumb: string}} sceneObj
 */
function applyScene(sceneObj) {
  return new Promise(function (resolve, reject) {
    function changeAccent() {
      isAccentLoading = true
      if (sceneObj.id == patternProps.currentSceneID) {
        isAccentLoading = false
        resolve(false)
      } else {
        const pathArr = sceneObj.url.split('/')
        const fileName = pathArr.pop()
        patternProps.currentSceneID = sceneObj.id
        RH.ClearScene(['scene'])
        BABYLON.SceneLoader.ImportMesh(
          null,
          STATIC_ASSETS_DIRECTORY + pathArr.join('/') + '/',
          fileName,
          scene,
          (meshes) => {
            RH.ProcessMeshes(meshes, 'scene', (mesh) => (mesh.position.y -= 0.05))
            isAccentLoading = false
            resolve(true)
          },
          null,
          (err) => {
            console.log(err)
            isAccentLoading = false
            reject()
          },
        )
      }
    }

    function queueChecker() {
      setTimeout(() => {
        isAccentLoading ? queueChecker() : changeAccent()
      }, 100)
    }
    if (sceneObj) queueChecker()
    else {
      RH.ClearScene(['scene'])
      patternProps.currentSceneID = null
      resolve(false)
    }
  })
}

/**
 * @param {BABYLON.Mesh} mesh
 * @param {any} printObject
 */
var applyPrint = function (mesh, printObject_) {
  if (/^scene_/.test(mesh.name)) return

  const material = RH.GetMeshMaterial(mesh)
  const inputBlocks = material.getInputBlocks()
  const printObject = JSON.parse(JSON.stringify(printObject_))
  let id = printObject.id
  let url = printObject.url
  let target = 'yPrintTex'
  let useCustomPrint = 0
  if (printObject.egoFileURL || printObject.egoImageFullURL) {
    url = printObject.egoImageFullURL || printObject.egoFileURL
    if (!printObject.tiling) printObject.tiling = 4
    if (!printObject.channels) printObject.channels = 'R'
    if (!printObject.rColor) printObject.rColor = 'FFFFFF'

    if (mesh.metadata.print && mesh.metadata.print.egoID == printObject.egoID) {
      unsetPrint(mesh)
      return
    }
    RH.ApplyDefaultPrint(mesh.material)
    target = 'yCustomPrintTex'
    useCustomPrint = 1
  } else {
    url = STATIC_ASSETS_DIRECTORY + printObject['maskURL']
    if (mesh.metadata.print && mesh.metadata.print.id == id) {
      unsetPrint(mesh)
      return
    }
  }

  // let factor = -printObject.tiling / (patternProps.currentTemplate.tiling * 8)
  var defaultTiling = 0.5
  let factor = -printObject.tiling / (defaultTiling * 8)
  if (/accent_/i.test(mesh.name) && patternProps.currentTemplate.hasOwnProperty('accentTiling')) {
    factor = -printObject.tiling / patternProps.currentTemplate.accentTiling
  }

  inputBlocks.forEach((block) => {
    switch (block.name) {
      case 'uPrintTile':
        block.value = factor
        break
      case 'vPrintTile':
        block.value = factor
        break
      case 'useCustomPrint':
        block.value = useCustomPrint
        break
      case 'uPrintOffset':
        block.value = patternProps.currentTemplate.uOffset
        break
      case 'vPrintOffset':
        block.value = patternProps.currentTemplate.vOffset
        break
      default:
        break
    }
  })

  const targetBlock = material.attachedBlocks.find((block) => block.name === target)
  targetBlock.texture = new BABYLON.Texture(url, scene)

  mesh.metadata.print = printObject
  updateTileRotation(mesh)
  if (!mesh.metadata.material?.id) RH.ApplyDefaultMaterial(mesh.material)
  mesh.material = material
  applyColorFromPrint(mesh, printObject)
}

var applyMaterial = function (mesh, materialObj) {
  if (mesh.metadata.material && mesh.metadata.material.id == materialObj.id) {
    unsetMaterial(mesh)
    return
  }
  console.log(`Apply material : ${materialObj.name}`)

  mesh.metadata.material = materialObj
  if (
    /template/i.test(mesh.name) &&
    sharedMaterial &&
    (!mesh.material ||
      mesh.material.getClassName() == 'StandardMaterial' ||
      mesh.material.name == 'sharedMaterial') &&
    !sharedFrom.metadata.print &&
    !sharedFrom.metadata.colorR
  ) {
    mesh.material = sharedMaterial
    sharedMaterial.name = 'sharedMaterial'
    return
  }

  const material = RH.GetMeshMaterial(mesh)
  const smoothness = materialObj.hasOwnProperty('smoothness') ? materialObj['smoothness'] : 0.5
  const normalMapStrength = materialObj.hasOwnProperty('normalMapStrength')
    ? materialObj['normalMapStrength']
    : 1
  const metalness = materialObj.hasOwnProperty('metalness') ? materialObj['metalness'] : 0

  const inputBlocks = material.getInputBlocks()
  inputBlocks.forEach((block) => {
    switch (block.name) {
      case 'roughness':
        block.value = 1 - smoothness
        break
      case 'normalStrength':
        block.value = normalMapStrength
        break
      case 'metallic':
        block.value = metalness
        break
      case 'uTile':
      case 'vTile':
        block.value = materialObj.hasOwnProperty('tiling') ? materialObj.tiling : 1
        if (
          /^(template|boarder)_/.test(mesh.name) &&
          patternProps.currentTemplate.hasOwnProperty('materialTiling')
        )
          block.value *= patternProps.currentTemplate.materialTiling
        break
      default:
        break
    }
  })

  if (materialObj.albedo) {
    const baseTextureBlock = material.attachedBlocks.find((block) => block.name === 'yBaseColorTex')
    baseTextureBlock.texture = new BABYLON.Texture(
      STATIC_ASSETS_DIRECTORY + materialObj.albedo,
      scene,
    )
  }
  if (materialObj.normal) {
    const normalBlock = material.attachedBlocks.find((block) => block.name === 'yNormalTex')
    normalBlock.texture = new BABYLON.Texture(STATIC_ASSETS_DIRECTORY + materialObj.normal, scene)
  }

  mesh.material = material
  if (
    /^template_/.test(mesh.name) &&
    !mesh.metadata.print &&
    !mesh.metadata.colorR &&
    !/_Merge$/.test(mesh.name)
  ) {
    sharedMaterial = material
    sharedFrom = mesh
  }
}

var applyMaterialOnSameSurfaces = function (mesh2, materialObj) {
  sharedMaterial = null
  sharedFrom = null

  // set material to each mesh for gallery pattern only
  if (topicType === 'gallery') {
    applyMaterial(mesh2, materialObj)
    return
  }

  const matches = mesh2.name.match(/^(scene|template|accent|boarder)_/i)
  if (matches[1]) {
    scene.meshes
      .filter((mesh) => {
        const regExp = new RegExp('^' + matches[1] + '_', 'i')
        return regExp.test(mesh.name) && !OVERLAY_TEST_REGEX.test(mesh.name)
      })
      .forEach((mesh) => applyMaterial(mesh, materialObj))
  }
}

var applyColor = function (mesh, string) {
  const material = RH.GetMeshMaterial(mesh)
  RH.ApplyDefaultColor(material)
  if (!mesh.metadata.material?.id) RH.ApplyDefaultMaterial(material)
  if (!mesh.metadata.print?.id) RH.ApplyDefaultPrint(material)
  const color = BABYLON.Color3.FromHexString(string)
  material.getInputBlocks().find((block) => block.name === 'colorR').value = color
  mesh.metadata.colorR = {
    r: color.r,
    g: color.g,
    b: color.b,
  }
  mesh.material = material
}

var applyColorArray = function (mesh, colorArray) {
  // console.log(`Apply color array ${mesh.name}`)
  const material = RH.GetMeshMaterial(mesh)
  RH.ApplyDefaultColor(material)
  if (!mesh.metadata.material?.id) RH.ApplyDefaultMaterial(material)
  if (!mesh.metadata.print?.id) RH.ApplyDefaultPrint(material)

  const blockNames = ['colorR', 'colorG', 'colorB', 'colorA']
  const useNames = ['useR', 'useG', 'useB', 'useA']

  colorArray.forEach((colorString, index) => {
    const color = BABYLON.Color3.FromHexString(colorString)
    const blockName = blockNames[index]
    const useName = useNames[index]
    material.getInputBlocks().find((block) => block.name === blockName).value = color
    material.getInputBlocks().find((block) => block.name === useName).value = 1
    mesh.metadata[blockName] = {
      r: color.r,
      g: color.g,
      b: color.b,
    }
  })

  mesh.material = material
}

var applyColorFromPrint = function (mesh, printObject) {
  // console.log(`Apply color from print : ${mesh.name}`)
  const colorChannels = ['r', 'g', 'b', 'a']

  if (mesh.metadata.colorR) delete mesh.metadata.colorR
  if (mesh.metadata.colorG) delete mesh.metadata.colorG
  if (mesh.metadata.colorB) delete mesh.metadata.colorB
  if (mesh.metadata.colorA) delete mesh.metadata.colorA

  const inputBlocks = mesh.material.getInputBlocks()
  inputBlocks
    .filter((block) => /^use[RGBA]$/.test(block.name))
    .forEach((block) => {
      block.value = 0
    })
  colorChannels.forEach((channel) => {
    const upperCaseChannelCode = channel.toUpperCase()
    if (printObject.channels?.indexOf(channel.toUpperCase()) + 1) {
      const color = BABYLON.Color3.FromHexString('#' + printObject[channel + 'Color'])
      inputBlocks.find((block) => block.name === 'color' + upperCaseChannelCode).value = color
      inputBlocks.find((block) => block.name === 'use' + upperCaseChannelCode).value = 1
      // mesh.metadata['color'+upperCaseChannelCode] = {
      //   r: color.r,
      //   g: color.g,
      //   b: color.b,
      // };
    }
  })
}

function unsetPrint(mesh) {
  mesh.material &&
    mesh.material.getClassName() === 'NodeMaterial' &&
    RH.ApplyDefaultPrint(mesh.material)
  mesh.metadata && delete mesh.metadata.print
  RH.ResetMesh(mesh)
}

function unsetMaterial(mesh) {
  if (mesh.material && mesh.material.getClassName() === 'NodeMaterial') {
    RH.ApplyDefaultMaterial(mesh.material)
  }
  mesh.metadata && delete mesh.metadata.material
  sharedMaterial = null
  sharedFrom = null
  RH.ResetMesh(mesh)
}

function unsetAccent() {
  RH.EmptySceneOfMeshes('accent')
  outlineNeedsUpdate = true
  patternProps.currentAccentID = null
  onPatternRestore(patternProps)
}

function unsetScene() {
  RH.EmptySceneOfMeshes('scene')
  patternProps.currentSceneID = null
  onPatternRestore(patternProps)
}

function undoPatternEdit() {
  if (isRestoring) return
  //historyIndex--
  if (historyIndex <= 1) {
    historyIndex = 1
  } else {
    historyIndex--
    const previousPatternData = patternHistory[historyIndex - 1]
    RestorePattern(previousPatternData, null, () => {
      RH.CheckReadyBeforeUpdate(() => onEdit(previousPatternData))
    })
  }
}

function redoPatternEdit() {
  if (isRestoring) return

  if (historyIndex >= patternHistory.length) {
    historyIndex = patternHistory.length
  } else {
    historyIndex++
    const nextPatternData = patternHistory[historyIndex - 1]
    RestorePattern(nextPatternData, null, () => {
      RH.CheckReadyBeforeUpdate(() => onEdit(nextPatternData))
    })
  }
}

function savePattern(isNew = true, cb) {
  RH.CheckReadyBeforeUpdate(() => {
    onEdit(serializePattern(isNew))
    cb && cb()
  })
}

export function PerformOperation(operation, argument1, argument2) {
  switch (operation) {
    case 'toggleOverlay':
      RH.ToggleOverlay(argument1)
      break
    case 'toggleInspector':
      argument1 ? Inspector.Show(scene, { enableClose: false }) : Inspector.Hide()
      break
    case 'screenshot':
      RH.TakeScreenShot(argument1, 512)
      break
    case 'undoPatternEdit':
      clearPartialActions()
      undoPatternEdit()
      break
    case 'redoPatternEdit':
      clearPartialActions()
      redoPatternEdit()
      break
    case 'applyText':
      RH.SetTextOn3DRenderer(argument1)
      break
    case 'updateMergeMode':
      if (applicationMode != argument1) {
        clearPartialActions()
        applicationMode = argument1
        if (applicationMode === APPLICATION_MODES.FULL_SCREEN)
          toggleCameraForFullscreen(applicationMode)
      } else {
        if (applicationMode === APPLICATION_MODES.SPLIT_SURFACES) unmergeSelectedMeshes()
        else if (applicationMode === APPLICATION_MODES.MERGE_SURFACES) mergeSelectedMeshes()
        else if (applicationMode === APPLICATION_MODES.FULL_SCREEN) toggleCameraForFullscreen(false)
        applicationMode = APPLICATION_MODES.NONE
      }
      break
    default:
      break
  }
}

function clearPartialActions() {
  meshesSelectedForMerging.clear()
  RH.ShowHighlightOn(meshesSelectedForMerging)
}

function mergeSelectedMeshes(skipSave) {
  if (meshesSelectedForMerging.size > 1) {
    //check already merged mesh is being merged
    let selectedMeshesArray = Array.from(meshesSelectedForMerging)
    let previousMeshType = selectedMeshesArray[0].name.split('_')[0]
    let differentMeshTypes = false
    for (let i = 0; i < selectedMeshesArray.length; i++) {
      if (!selectedMeshesArray[i]) continue
      const currentMeshType = selectedMeshesArray[i].name.split('_')[0]
      if (topicType === 'gallery' && currentMeshType === 'template') {
        alert('You can not merge individual meshes on gallery.')
        return
      }
      if (previousMeshType !== currentMeshType) {
        differentMeshTypes = true
        alert('Different mesh types selected. Cannot Merge.')
        break
      }
      previousMeshType = currentMeshType
    }

    if (!differentMeshTypes) {
      selectedMeshesArray.forEach((mesh) => {
        if (currentMerges[mesh.name]) {
          meshesSelectedForMerging.delete(mesh)
          currentMerges[mesh.name].forEach((meshName) => {
            meshesSelectedForMerging.add(scene.meshes.find((mesh) => mesh.name === meshName))
          })
          disposeMerge(mesh.name)
        }
      })

      const mergedMeshNames = []
      selectedMeshesArray = Array.from(meshesSelectedForMerging)
      const newMesh = BABYLON.Mesh.MergeMeshes(selectedMeshesArray, false)
      outlineNeedsUpdate = true
      selectedMeshesArray.forEach((mesh) => {
        mergedMeshNames.push(mesh.name)
        mesh.setEnabled(false)
      })
      newMesh.name = selectedMeshesArray[0].id + '_Merge'
      newMesh.id = selectedMeshesArray[0].id + '_Merge'
      RH.ProcessMesh(newMesh, previousMeshType, 1)

      //apply some material here.
      const meshes = Array.from(meshesSelectedForMerging)
      if (meshes[0].metadata.material) {
        applyMaterial(newMesh, meshes[0].metadata.material)
      }
      const printedMesh = meshes.find((mesh) => mesh.metadata.print)
      printedMesh && applyPrint(newMesh, printedMesh.metadata.print)

      const hexColors = []
      const coloredMesh = meshes.find((mesh) => mesh.metadata.colorR)
      // applyColorsFromMesh(coloredMesh)
      if (coloredMesh) {
        const r = coloredMesh.metadata.colorR.r
        const g = coloredMesh.metadata.colorR.g
        const b = coloredMesh.metadata.colorR.b
        hexColors.push(new BABYLON.Color3(r, g, b).toHexString())

        if (coloredMesh.metadata.colorG) {
          hexColors.push(
            new BABYLON.Color3(
              coloredMesh.metadata.colorG.r,
              coloredMesh.metadata.colorG.g,
              coloredMesh.metadata.colorG.b,
            ).toHexString(),
          )
        }
        if (coloredMesh.metadata.colorB) {
          hexColors.push(
            new BABYLON.Color3(
              coloredMesh.metadata.colorB.r,
              coloredMesh.metadata.colorB.g,
              coloredMesh.metadata.colorB.b,
            ).toHexString(),
          )
        }
        if (coloredMesh.metadata.colorA) {
          hexColors.push(
            new BABYLON.Color3(
              coloredMesh.metadata.colorA.r,
              coloredMesh.metadata.colorA.g,
              coloredMesh.metadata.colorA.b,
            ).toHexString(),
          )
        }
        // hexColors.push(new BABYLON.Color3(r, g, b).toHexString())
        // applyColor(newMesh, new BABYLON.Color3(r, g, b).toHexString())
        applyColorArray(newMesh, hexColors)
      }

      currentMerges[newMesh.name] = mergedMeshNames

      if (!skipSave) {
        RH.SuperHighlight(newMesh)
        savePattern(true, () => {
          applySurfaceProperties()
        })
      }
    }
  }

  meshesSelectedForMerging.clear()
  RH.ShowHighlightOn(meshesSelectedForMerging)
}

function unmergeSelectedMeshes(skipSave) {
  if (meshesSelectedForMerging.size >= 1) {
    meshesSelectedForMerging.forEach((mesh) => {
      disposeMerge(mesh.name)
    })
    meshesSelectedForMerging.clear()
    RH.ShowHighlightOn(meshesSelectedForMerging)
    savePattern()
  }
}

function disposeMerge(mergedMeshName) {
  const mergedMesh = scene.getMeshByName(mergedMeshName)
  if (mergedMesh) {
    mergedMesh.dispose()
    outlineNeedsUpdate = true
  }
  currentMerges[mergedMeshName] &&
    currentMerges[mergedMeshName].forEach((meshName) => {
      scene.meshes.find((mesh) => mesh.name === meshName).setEnabled(true)
    })
  delete currentMerges[mergedMeshName]
}

function updateBoarder() {
  const templateMerges = Object.keys(currentMerges)
  for (let i = 0; i < templateMerges.length; i++) {
    if (/^boarder_/.test(templateMerges[i])) {
      delete currentMerges[templateMerges[i]]
    }
  }
  RH.CreateBoarder(patternProps.boarderWidth, patternProps.boarderType, topicType === 'gallery')
  outlineNeedsUpdate = true
}

function updateTileRotation(mesh) {
  if (!mesh.metadata.print) return
  let target = 'yPrintTex'
  if (mesh.metadata.print.egoFileURL || mesh.metadata.print.egoImageFullURL) {
    target = 'yCustomPrintTex'
  }
  const textureBlock = mesh.material.getTextureBlocks().find((block) => block.name === target)
  textureBlock.texture.wAng = mesh.metadata.print.rotation || 0
}

function copyStyle(targetMesh, sourceMesh) {
  if (targetMesh !== sourceMesh) {
    let possibleMaterialObj = targetMesh.metadata.material
    RH.ResetMesh(targetMesh, true)
    if (RH.SurfaceApplied(sourceMesh)) {
      const targetSurfaceType = RH.GetSurfaceType(targetMesh)
      if (sourceMesh.metadata.material) {
        if (
          (targetSurfaceType !== RH.GetSurfaceType(sourceMesh) && !possibleMaterialObj) ||
          possibleMaterialObj.id != sourceMesh.metadata.material.id
        ) {
          applyMaterialOnSameSurfaces(targetMesh, sourceMesh.metadata.material)
        } else {
          applyMaterial(targetMesh, sourceMesh.metadata.material)
        }
      }
      if (targetSurfaceType !== 2 && sourceMesh.metadata.print)
        applyPrint(targetMesh, sourceMesh.metadata.print)
      const hexColors = []
      if (sourceMesh.metadata.colorR) {
        hexColors.push(
          sourceMesh.material
            .getInputBlocks()
            .find((block) => block.name === 'colorR')
            .value.toHexString(),
        )
      }
      if (sourceMesh.metadata.colorG) {
        hexColors.push(
          sourceMesh.material
            .getInputBlocks()
            .find((block) => block.name === 'colorG')
            .value.toHexString(),
        )
      }
      if (sourceMesh.metadata.colorB) {
        hexColors.push(
          sourceMesh.material
            .getInputBlocks()
            .find((block) => block.name === 'colorB')
            .value.toHexString(),
        )
      }
      if (sourceMesh.metadata.colorA) {
        hexColors.push(
          sourceMesh.material
            .getInputBlocks()
            .find((block) => block.name === 'colorA')
            .value.toHexString(),
        )
      }
      applyColorArray(targetMesh, hexColors)
    }
  }
}

function toggleCameraForFullscreen(applicationMode) {
  if (applicationMode) {
    camera.detachControl()
    camera.attachControl(null, null, true, 1)
  } else {
    camera.detachControl()
    camera.attachControl(null, null, false, false)
    camera.setTarget(new BABYLON.Vector3(0, 0, 0))
  }
}
