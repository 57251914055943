/* eslint-disable @typescript-eslint/no-explicit-any */
import { IEditStore, IEditSlice, Topic } from '@/types'

const initialState: IEditStore = {
  selectedTopic: null,
}

export const createEditSlice = (set: any): IEditSlice => ({
  edit: initialState,
  selectEditTopic: (topic: Topic) =>
    set((state: any) => ({
      edit: {
        ...state.edit,
        selectedTopic: topic,
      },
    })),
})

export default createEditSlice
