import { UserAvatar } from '@/components'
import useAppStore from '@/store/useAppStore'
import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { shallow } from 'zustand/shallow'
import PatternIcon from '@/assets/svgs/pattern-icon.svg'
import QuiltIcon from '@/assets/svgs/quilt-icon.svg'
import AlbumIcon from '@/assets/svgs/albums-icon.svg'
import GalleryIcon from '@/assets/svgs/gallery-icon.svg'
import { useNavigate } from 'react-router-dom'
import TileButton from './TileButton'
import { Topic } from '@/types'
import colors from '@/styles/colors'

interface ProfileInfoSideBarProps {
  topics: Topic[]
  onClickTopicItem: (topic: Topic) => void
}

const ProfileInfoSideBar = ({ topics, onClickTopicItem }: ProfileInfoSideBarProps) => {
  const navigate = useNavigate()

  const [interactionType, topicType, topicList, userProfile] = useAppStore(
    (state) => [
      state.home.interactionFilterType,
      state.home.topicFilterType,
      state.home.topicList,
      state.user.profile,
      state.user,
    ],
    shallow,
  )

  const onClickProfile = () => {
    navigate(`/profile/me`)
  }

  const onClickAllUploads = () => {
    navigate('/uploads')
  }

  const ProfileButton = styled(Button)(() => ({
    backgroundColor: 'black',
    borderRadius: 50,
    color: 'white',
    border: 0,
    fontSize: 12,
    fontWeight: 600,
    padding: '10px 20px',
    height: '34px',
    marginTop: '15px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
      border: '1px solid black',
    },
  }))

  const ViewAllButton = styled(Button)(() => ({
    width: '144px',
    height: '34px',
    margin: 'auto',
    padding: '10px 20px',
    color: '#2884FF',
    border: '1px solid #2884FF',
    borderRadius: '50px',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: '12px',
  }))

  const VerticalDivider = styled(Box)(() => ({
    backgroundColor: '#E7EDF3',
    width: '1px',
  }))

  const HorizontalDivider = styled(Box)(() => ({
    height: '1px',
    backgroundColor: '#E7EDF3',
    width: '100%',
  }))

  const LatestUploadsWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F7F7F7',
    borderRadius: '8px',
    padding: '20px',
    marginTop: '25px',
  }))

  const PreviewUpload = styled(Box)(() => ({
    height: '115px',
    width: '115px',
    borderRadius: '5px',
    backgroundSize: 'cover',
    cursor: 'pointer',
  }))

  return (
    <Box
      width={'100%'}
      borderRadius={'8px'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      sx={{ overflowX: 'hidden', overflowY: 'auto' }}
    >
      <Box>
        <UserAvatar
          borderRadius={100}
          name={`${userProfile?.firstName} ${userProfile?.lastName}`}
          url={userProfile?.profilePicURL}
          size={100}
        />
      </Box>
      <Typography mt={'12px'} fontWeight={700} fontSize={'17px'} color={'#211E1E'}>
        {userProfile?.firstName} {userProfile?.lastName}
      </Typography>

      <Typography fontWeight={400} fontSize={'13px'} color={'#858DB9'}>
        {userProfile?.profession ? userProfile?.profession : ''}
      </Typography>
      {userProfile && userProfile.profession?.length > 0 && (
        <Typography
          fontWeight={400}
          fontSize={'13px'}
          lineHeight={'20px'}
          px={'64px'}
          textAlign={'center'}
        >
          {`${userProfile?.profession!}`}
        </Typography>
      )}

      <ProfileButton onClick={onClickProfile}>View Profile</ProfileButton>

      <Box p={'30px'}>
        <Box display={'flex'}>
          <TileButton title='Patterns' icon={PatternIcon} path='/workspace/pattern' />
          <VerticalDivider />
          <TileButton title='Quilts' icon={QuiltIcon} path='/projects/quilt' />
        </Box>
        <HorizontalDivider />
        <Box display={'flex'}>
          <TileButton title='Albums' icon={AlbumIcon} path='/workspace/album' />
          <VerticalDivider />
          <TileButton title='Galleries' icon={GalleryIcon} path='/projects/gallery' />
        </Box>

        <LatestUploadsWrapper>
          <Typography fontWeight={600} fontSize={'14px'} color={'#211E1E'}>
            Latest Uploads
          </Typography>

          <Box display={'flex'} gap={'15px'} mb={'15px'} mt={'20px'}>
            {topics
              .filter((topic) => topic.userID === userProfile?.userID)
              .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
              .slice(0, 2)
              .map((item: Topic, index: number) => (
                <PreviewUpload
                  key={`${item.topicID}-${index}-prev`}
                  onClick={() => onClickTopicItem(item)}
                  sx={{ backgroundImage: `url(${item.latestEgo?.photoFullURL})` }}
                />
              ))}
          </Box>
          <Box display={'flex'} gap={'15px'} mb={'15px'}>
            {topics
              .filter((topic) => topic.userID === userProfile?.userID)
              .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
              .slice(2, 4)
              .map((item: Topic, index: number) => (
                <PreviewUpload
                  key={`${item.topicID}-${index}-prev`}
                  onClick={() => onClickTopicItem(item)}
                  sx={{ backgroundImage: `url(${item.latestEgo?.photoFullURL})` }}
                />
              ))}
          </Box>
          <ViewAllButton onClick={onClickAllUploads}>View All Uploads</ViewAllButton>
        </LatestUploadsWrapper>
      </Box>
    </Box>
  )
}

export default ProfileInfoSideBar
