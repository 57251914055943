import colors from '@/styles/colors'
import { Button, styled } from '@mui/material'

export const RoundedFilledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.brand.light,
  color: 'white',
  fontSize: 14,
  fontWeight: '700',
  borderRadius: 50,
  alignItems: 'center',
  padding: '10px 40px',
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: 'lightGray',
  },
}))

export const RoundedActionButton = styled(Button)(({ theme, variant }) => ({
  backgroundColor: theme.palette.brand.light,
  color: 'white',
  fontSize: 14,
  fontWeight: '700',
  borderRadius: 50,
  alignItems: 'center',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.brand.light,
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: 'lightGray',
  },

  ...(variant === 'contained' && {
    backgroundColor: theme.palette.brand.light,
    color: 'white',
    borderColor: theme.palette.brand.light,
    border: `1px solid ${theme.palette.brand.light}`,
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    color: theme.palette.brand.light,
    borderColor: theme.palette.brand.light,
    border: `1px solid ${theme.palette.brand.light}`,
  }),
}))

export const RoundedOutlineButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.brand.light,
  borderColor: theme.palette.brand.light,
  border: `1px solid ${theme.palette.brand.light}`,
  fontSize: 14,
  fontWeight: '700',
  borderRadius: 50,
  alignItems: 'center',
  padding: '10px 40px',
  '&[aria-selected = true]': {
    border: `1px solid ${colors.themePink}`,
    backgroundColor: colors.themePinkLight,
    color: colors.themePink,
  },
  //   '&:hover': {
  //     backgroundColor: theme.palette.brand.light,
  //     color: 'white',
  //   },
}))
