import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Zoom,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import colors from '@/styles/colors'
import { CBox, RBox } from '@/pages/__styled'
import { RoundedFilledButton, RoundedOutlineButton } from '@/components/StyledComponents'
import { OddlyouNoteType, PrivacyType, TopicType } from '@/utils/constants'
import {
  useAddNoteTopic,
  useGetTopicDetails,
  useGetTopics,
  useUploadMedia,
} from '@/hooks/api/dashboard'
import { CreateNewPatternModal } from '@/components'
import { Topic } from '@/types'
import { getPlainNoteTextFromNote } from '@/utils/helper'
import DoneIcon from '@mui/icons-material/Done'
import { toast } from 'react-hot-toast'
import FullScreenLoadingView from './FullScreenLoadingView'

interface SaveScreenshotModalProps {
  file: File
  open: boolean
  onClose: () => void
}

const CustomBackdrop = styled('div')({
  backdropFilter: 'blur(5px)',
  backgroundColor: 'rgba(0, 0, 30, 0.4)',
})

const SaveScreenshotModal = ({ file, open, onClose }: SaveScreenshotModalProps) => {
  const theme = useTheme()
  const lessThanMedium = useMediaQuery(theme.breakpoints.down('md'))

  const getTopics = useGetTopics()
  const uploadPhoto = useUploadMedia()
  const createTopicNote = useAddNoteTopic()
  const getTopicDetails = useGetTopicDetails()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedTopicID, setSelectedTopicID] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [topics, setTopics] = useState<Topic[]>([])

  useEffect(() => {
    loadTopics()
  }, [])

  useEffect(() => {
    if (getTopics.data) {
      const allData: Topic[] = getTopics.data || []
      let filtered = allData.filter((e) => e.topicType == TopicType.ALBUM.toString())
      setTopics(filtered)
    }
  }, [getTopics.data])

  const selectedTopicTitle = useMemo(() => {
    if (selectedTopicID && topics.length > 0) {
      const selectedTopic = topics.find((item) => item.topicID == selectedTopicID)
      return selectedTopic ? getPlainNoteTextFromNote(selectedTopic.firstNote) : ''
    }
    return ''
  }, [selectedTopicID, topics])

  const blobURL = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file)
    }
    return null
  }, [file])

  const loadTopics = (pageNo: number = 1) => {
    getTopics.mutate({
      pageNo,
      interaction: Number(PrivacyType.PRIVATE),
      topicType: Number(TopicType.ALBUM),
    })
  }

  const showNoResultView = useMemo(() => {
    return getTopics.isSuccess && getTopics.data.length == 0 && currentPage === 1
  }, [getTopics.isSuccess, getTopics.data])

  const onClickSave = async () => {
    setLoading(true)
    const fileInfo = await uploadPhoto.mutateAsync(file)
    const egoList = [
      {
        sequenceID: '1',
        egoID: fileInfo.egoID,
        photoAlbumURL: fileInfo.egoAlbumURL,
        photoFullURL: fileInfo.egoImageURL,
      },
    ]

    const noteText = {
      text: `<p>ScreenShot</p>`,
      attachments: [],
    }

    try {
      await createTopicNote.mutateAsync({
        noteName: 'Screenshot',
        topicID: selectedTopicID!,
        noteText: JSON.stringify(noteText),
        egoItems: egoList,
        noteType: OddlyouNoteType.IMAGE.toString(),
      })
      setLoading(false)
      toast.success('Screenshot Saved.')
      onClose()
    } catch (e: any) {
      setLoading(false)
      toast.error(e.toString())
    }
  }

  const onAlbumCreated = async (topic: Topic) => {
    setShowCreateDialog(false)
    setSelectedTopicID(null)
    const topicDetails = await getTopicDetails.mutateAsync({ topicID: topic.topicID })
    setTopics((prev) => [topicDetails, ...prev])
    setSelectedTopicID(topic.topicID)
  }

  const renderTopicRowItem = (topic: Topic, isSelected: boolean) => (
    <RBox
      key={`${topic.topicID}`}
      sx={{
        justifyContent: 'space-between',
        cursor: 'pointer',
        borderRadius: '8px',
        p: '9px 12px',
        backgroundColor: isSelected ? 'white' : 'transparent',
      }}
      onClick={() => {
        setSelectedTopicID(topic.topicID)
      }}
    >
      <Typography
        fontSize={13}
        fontWeight={'600'}
        color={isSelected ? colors.meshdomBlue : colors.themeBlack}
      >{`${getPlainNoteTextFromNote(topic.firstNote)}`}</Typography>
      {isSelected && <DoneIcon sx={{ color: colors.meshdomBlue }} />}
    </RBox>
  )

  return (
    <Dialog
      open={open}
      TransitionComponent={Zoom}
      onClose={onClose}
      aria-describedby='alert-dialog-slide-description'
      maxWidth='lg'
      fullScreen={lessThanMedium}
      slotProps={{
        backdrop: {
          components: {
            Root: CustomBackdrop,
          },
        },
      }}
      PaperProps={{
        sx: lessThanMedium ? {} : { height: '90%', width: '90%' },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={lessThanMedium ? 'column' : 'row'}
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          backgroundColor: colors.pageBackground,
          gap: lessThanMedium ? '0px' : '6px',
        }}
      >
        <CBox
          sx={{
            display: lessThanMedium ? 'none' : 'flex',
            height: '100%',
            width: '240px',
            px: '15px',
            py: '18px',
            backgroundImage:
              'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 5%, rgba(226, 238, 255, 1) 100%)',
          }}
        >
          {!showNoResultView && <Typography sx={{ ml: '8px', mb: '8px' }}>{'Albums'}</Typography>}

          <Box
            flex={1}
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {(getTopicDetails.isLoading || (getTopics.isLoading && currentPage === 1)) && (
              <Box mt={1} display='flex' justifyContent={'center'}>
                <CircularProgress />
              </Box>
            )}

            {showNoResultView ? (
              <Box p={5} display='flex' justifyContent={'center'}>
                {'No Albums'}
              </Box>
            ) : (
              <>{topics.map((e, i) => renderTopicRowItem(e, e.topicID == selectedTopicID))}</>
            )}
          </Box>
          <RoundedOutlineButton
            sx={{ mx: '16px', mt: '8px', px: '4px', py: '4px' }}
            onClick={() => setShowCreateDialog(true)}
          >
            {'+ Create New Album'}
          </RoundedOutlineButton>
        </CBox>
        <CBox sx={{ flex: 1 }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: lessThanMedium ? 0 : '240px',
              bottom: 0,
              right: 0,
              backgroundImage: blobURL ? `url(${blobURL})` : '',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: 'blur(90px)',
            }}
          />
          <RBox
            sx={{
              display: lessThanMedium ? 'flex' : 'none',
              px: '30px',
              pt: '18px',
              justifyContent: 'space-between',
            }}
          >
            {!showNoResultView && (
              <RBox>
                <Typography sx={{ mr: '4px' }}>{'Select Album:'}</Typography>
                <Select
                  defaultValue='Select'
                  value={selectedTopicTitle || ''}
                  onChange={(event: SelectChangeEvent) => {
                    const value = event.target.value
                    setSelectedTopicID(value)
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>{`-Select-`}</>
                    }
                    return selected
                  }}
                  sx={{
                    height: 34,
                    borderRadius: 100,
                    color: '#525E9C',
                    border: '1px solid #E7EDF3',
                    minWidth: '100px',
                    maxWidth: '150px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {topics.map((item, index) => {
                    const isSelected = item.topicID == selectedTopicID
                    return (
                      <MenuItem
                        value={item.topicID}
                        key={`${index}`}
                        sx={{
                          display: 'flex',
                          flex: 1,
                          color: isSelected ? colors.meshdomBlue : colors.themeBlack,
                        }}
                      >
                        {`${getPlainNoteTextFromNote(item.firstNote)}`}
                      </MenuItem>
                    )
                  })}
                </Select>
              </RBox>
            )}
            <RoundedOutlineButton
              sx={{ px: '12px', py: '4px' }}
              onClick={() => setShowCreateDialog(true)}
            >
              {'+ Create New Album'}
            </RoundedOutlineButton>
          </RBox>
          <Box
            display={'flex'}
            flex={1}
            borderRadius={'10px'}
            sx={{
              backgroundColor: 'black',
              zIndex: 10,
              m: lessThanMedium ? 1 : 4,
            }}
          >
            <Box
              sx={{
                m: lessThanMedium ? 1 : 4,
                flex: 1,
                backgroundImage: blobURL ? `url(${blobURL})` : '',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </Box>

          <RBox sx={{ justifyContent: 'space-between', mb: '18px', mx: 2.5 }}>
            <RoundedOutlineButton
              sx={{
                px: '12px',
                py: '4px',
                border: `1px solid ${colors.darkGrey}`,
                color: colors.darkGrey,
              }}
              onClick={onClose}
            >
              {'Cancel'}
            </RoundedOutlineButton>
            <RoundedFilledButton
              disabled={selectedTopicID == null}
              sx={{ px: '4px', py: '4px' }}
              onClick={onClickSave}
            >
              {'Save'}
            </RoundedFilledButton>
          </RBox>
        </CBox>
      </Box>
      {loading && <FullScreenLoadingView />}
      <CreateNewPatternModal
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        defaultTopicType={TopicType.ALBUM}
        defaultPrivacyType={PrivacyType.PRIVATE}
        onSuccess={onAlbumCreated}
      />
    </Dialog>
  )
}

export default SaveScreenshotModal
