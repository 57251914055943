import {
  Colorize,
  DeveloperBoard,
  DeveloperBoardOff,
  DoneOutline,
  GridOff,
  GridOn,
  MoreVert,
} from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Theme, Tooltip, useMediaQuery } from '@mui/material'
import { APPLICATION_MODES } from './renderer/main'
import { ToolBarContainer, ToolBarIcon, ToolBarWrapper } from './__styled'
import SplitIcon from '@/assets/svgs/split.svg'
import CombineIcon from '@/assets/svgs/combine.svg'
import RotateIcon from '@/assets/svgs/rotate.svg'
import UndoIcon from '@/assets/svgs/undo.svg'
import RedoIcon from '@/assets/svgs/redo.svg'
import SVG from 'react-inlinesvg'
import { useState } from 'react'

interface ToolBarProps {
  mode: number
  bordered: boolean
  debugMode: boolean
  onPressBack: () => void
  onPressForward: () => void
  onPressMerge: () => void
  onPressSplit: () => void
  onPressRotate: () => void
  onPressColorPick: () => void
  onPressGrid: () => void
  onPressInspector: () => void
  onPressFullMode: () => void
}

export default function PatternToolBar({
  mode,
  bordered,
  debugMode,
  onPressBack,
  onPressForward,
  onPressMerge,
  onPressSplit,
  onPressRotate,
  onPressColorPick,
  onPressGrid,
  onPressInspector,
  // onPressFullMode,
}: ToolBarProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {isSmallScreen ? (
        <Box
          sx={{
            position: 'absolute',
            right: 180,
            top: 16,
            display: 'flex',
            zIndex: 10,
          }}
        >
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={handleMenuOpen}
          >
            <MoreVert sx={{ color: 'white' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleMenuClose}
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#474A4F',
                color: '#fff', // Change this to your desired text color
              },
            }}
          >
            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Undo'>
                <ToolBarIcon onClick={onPressBack}>
                  <SVG src={UndoIcon} width={60} height={60} />
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>

            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Redo'>
                <ToolBarIcon onClick={onPressForward}>
                  <SVG src={RedoIcon} width={60} height={60} />
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>

            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Merge'>
                <ToolBarIcon onClick={onPressMerge}>
                  {mode === APPLICATION_MODES.MERGE_SURFACES ? (
                    <DoneOutline color='secondary' />
                  ) : (
                    <SVG src={CombineIcon} width={60} height={60} />
                  )}
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>

            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Split'>
                <ToolBarIcon onClick={onPressSplit}>
                  {mode === APPLICATION_MODES.SPLIT_SURFACES ? (
                    <DoneOutline color='secondary' />
                  ) : (
                    <SVG src={SplitIcon} width={60} height={60} />
                  )}
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>

            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Rotate'>
                <ToolBarIcon onClick={onPressRotate}>
                  <SVG src={RotateIcon} width={60} height={60} />
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>

            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Color Picker'>
                <ToolBarIcon
                  onClick={onPressColorPick}
                  // sx={{ backgroundColor: mode === APPLICATION_MODES.PICKER_PICKED || mode === APPLICATION_MODES.PICKER_PICKING ? "#cccccc" : "none" }}
                >
                  <Colorize
                    color={mode === APPLICATION_MODES.PICKER_PICKED ? 'secondary' : 'inherit'}
                  />
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>

            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Border'>
                <ToolBarIcon onClick={onPressGrid}>
                  {bordered ? <GridOn /> : <GridOff />}
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>
            <MenuItem>
              <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Inspector'>
                <ToolBarIcon onClick={onPressInspector}>
                  {debugMode ? <DeveloperBoard /> : <DeveloperBoardOff />}
                </ToolBarIcon>
              </Tooltip>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <ToolBarWrapper>
          <ToolBarContainer>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Undo'>
              <ToolBarIcon onClick={onPressBack}>
                <SVG src={UndoIcon} width={60} height={60} />
              </ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Redo'>
              <ToolBarIcon onClick={onPressForward}>
                <SVG src={RedoIcon} width={60} height={60} />
              </ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Merge'>
              <ToolBarIcon onClick={onPressMerge}>
                {mode === APPLICATION_MODES.MERGE_SURFACES ? (
                  <DoneOutline color='secondary' />
                ) : (
                  <SVG src={CombineIcon} width={60} height={60} />
                )}
              </ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Split'>
              <ToolBarIcon onClick={onPressSplit}>
                {mode === APPLICATION_MODES.SPLIT_SURFACES ? (
                  <DoneOutline color='secondary' />
                ) : (
                  <SVG src={SplitIcon} width={60} height={60} />
                )}
              </ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Rotate'>
              <ToolBarIcon onClick={onPressRotate}>
                <SVG src={RotateIcon} width={60} height={60} />
              </ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Color Picker'>
              <ToolBarIcon
                onClick={onPressColorPick}
                // sx={{ backgroundColor: mode === APPLICATION_MODES.PICKER_PICKED || mode === APPLICATION_MODES.PICKER_PICKING ? "#cccccc" : "none" }}
              >
                <Colorize
                  color={mode === APPLICATION_MODES.PICKER_PICKED ? 'secondary' : 'inherit'}
                />
              </ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Border'>
              <ToolBarIcon onClick={onPressGrid}>{bordered ? <GridOn /> : <GridOff />}</ToolBarIcon>
            </Tooltip>
            <Tooltip sx={{ height: 40, width: 40, bgcolor: '#ffffff26' }} title='Inspector'>
              <ToolBarIcon onClick={onPressInspector}>
                {debugMode ? <DeveloperBoard /> : <DeveloperBoardOff />}
              </ToolBarIcon>
            </Tooltip>
            {/* <Tooltip sx={{height: 40, width: 40, bgcolor: '#ffffff26'}} title="Asset Selector">
          <ToolBarIcon onClick={onPressFullMode}>
            {mode === APPLICATION_MODES.FULL_SCREEN ? <FullscreenExit /> : <Fullscreen />}
          </ToolBarIcon>
        </Tooltip> */}
          </ToolBarContainer>
        </ToolBarWrapper>
      )}
    </>
  )
}
