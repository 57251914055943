import { Topic } from '@/types'
import { createContext, Context } from 'react'

interface TopicDetailsContextProps {
  topic: Topic
  onClickBack: () => void
  canLoadNextPage: () => boolean
  loadNextPage: () => Promise<Topic>
}

export const TopicDetailsContext: Context<TopicDetailsContextProps> = createContext(
  {} as TopicDetailsContextProps,
)
