import { useConnectionBlock, useConnectionDelete, useGetMyDirectMessages } from "@/hooks/api/dashboard";
import useAppStore from "@/store/useAppStore";
import { DMConnection } from "@/types";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { shallow } from "zustand/shallow";
import ConnectionItem from "./ConnectionItem";
import { LoadingButton } from "@mui/lab";
import { Input, NoResults } from "@/components";
import colors from "@/styles/colors";
import { SearchOutlined } from "@mui/icons-material";
import { ConnectionListHeader } from "../__styled";
import { toast } from "react-hot-toast";
import { useBrowserQuery } from "@/hooks/common";
import { useNavigate } from "react-router-dom";

interface Props {
  onClickTopic: () => void;
}

export default function MyConnections({ onClickTopic }: Props) {
  const [page, setPage] = useState(1);
  const [endReached, setEndReached] = useState(false);
  const [searchText, setSearchText] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null)
  const { setMyDMConnections } = useAppStore()
  const [myDMConnections] = useAppStore((state) => [state.user.myDMConnections], shallow)

  const getAllConnections = useGetMyDirectMessages();
  const blockUser = useConnectionBlock();
  const removeUser = useConnectionDelete();
  const query = useBrowserQuery();
  const dmTopicID = query.get("dmTopicID");
  const navigate = useNavigate();

  useEffect(() => {
    getAllConnections.mutate(page);
  }, [page])

  useEffect(() => {
    if (page === 1 && getAllConnections.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, getAllConnections.isSuccess])

  useEffect(() => {
    if (getAllConnections.isSuccess && getAllConnections.data && !getAllConnections.isError) {
      if (getAllConnections.data.length < 20) {
        setEndReached(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllConnections.isSuccess, getAllConnections.data, getAllConnections.isError]);

  const connections = useMemo(() => {
    return myDMConnections.filter(i => {
      const name = `${i.firstName} ${i.lastName}`;
      return name.toLowerCase().includes(searchText.trim().toLowerCase())
    })
  }, [myDMConnections, searchText])

  useEffect(() => {
    if (myDMConnections.length === 0) return;
    const find = myDMConnections.find(i => i.dmTopicID === dmTopicID);
    if (!find) {
      navigate(`/connections?dmTopicID=${connections[0].dmTopicID}`)
    }
  }, [myDMConnections, dmTopicID])

  const onClickShowMore = () => {
    if (endReached || getAllConnections.isLoading || !myDMConnections.length) {
      return;
    }
    setPage(page + 1);
  }

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }

  const onClickUser = (id: string) => {
    navigate(`/connections?dmTopicID=${id}`)
    onClickTopic();
  }

  const onClickBlockConnect = async (userID: string) => {
    const res = await toast.promise(
      blockUser.mutateAsync(userID),
      {
        loading: 'Blocking user...',
        success: <Typography variant="p">{`The user has been blocked.`}</Typography>,
        error: <Typography variant="p">Failed to block a user.</Typography>
      }
    );
    if (res.hasData) {
      setMyDMConnections(myDMConnections.filter(i => i.userID !== userID));
    }
  }

  const onClickRemoveConnect = (userID: string) => {
    toast.promise(
      removeUser.mutateAsync(userID),
      {
        loading: 'Removing user...',
        success: <Typography variant="p">{`The user has been removed.`}</Typography>,
        error: <Typography variant="p">Failed to remove a user.</Typography>
      }
    )
  }

  if (getAllConnections.isSuccess && getAllConnections.data.length === 0 && page === 1) {
    return (
      <Box p={5} display="flex" justifyContent={'center'}>
        <NoResults type="connection" iconColor={colors.text} />
      </Box>
    )
  }

  return (
    <Box flex={1} overflow='auto' ref={scrollRef} height="100%">
      <ConnectionListHeader>
        <Box display={'flex'} alignItems='center' justifyContent={'space-between'} py={1}>
          <Typography variant="labelSmall">{connections.length} Connection{connections.length === 1 ? '' : 's'}</Typography>
          <Button variant="text">
            <Typography variant="labelSmall" color={colors.blue}>Invite Friends</Typography>
          </Button>
        </Box>
        <Input
          value={searchText}
          placeholder='Search here'
          rightIcon={<SearchOutlined />}
          onChange={onChangeSearchText}
          name="search"
          sx={{ maxWidth: 467 }}
          size="small"
        />
      </ConnectionListHeader>
      {getAllConnections.isLoading && page === 1 && (
        <Box mt={1} display="flex" justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      {connections?.map((item: DMConnection) => (
        <ConnectionItem
          key={item.userID}
          user={item}
          onClick={() => onClickUser(item.dmTopicID)}
          onClickBlockConnect={() => onClickBlockConnect(item.userID)}
          onClickRemoveConnect={() => onClickRemoveConnect(item.userID)}
          selected={dmTopicID === item.dmTopicID}
        />
      ))}
      {(page > 1 || !getAllConnections.isLoading) && (
        <Box m={3} display="flex" justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={onClickShowMore}
            loading={getAllConnections.isLoading && page > 1}
            disabled={endReached}
          >
            {endReached ? 'No more results' : 'Show More'}
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}