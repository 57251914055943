import colors from '@/styles/colors'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ShareSearchContent = styled(Grid)({
  backgroundColor: 'white',
  height: '100%',
  '& .tab': {
    '& button': {
      flex: 1,
      color: colors.darkGrey,
      borderRadius: 0,
      fontSize: 13,
      padding: '10px 0',
      borderBottom: `1px solid ${colors.lightGrey}`,
      '&.active': {
        borderBottom: `1px solid ${colors.blue}`,
        color: colors.blue,
      },
    },
  },
  '& .topic-description': {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  '& .preview-image': {
    minWidth: 130,
    height: 130,
    marginRight: 10,
    borderRadius: 8,
    objectFit: 'cover',
  },
})

export const DetailsContent = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
})

export const InteractionsContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 20,
  },
}))
