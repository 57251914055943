/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { Ego, Note, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'
import { OddlyouNoteType } from '@/utils/constants'

interface Param {
  noteID: string
  topicID: string
  noteText: string
  egoList?: Ego[]
}

export const useUpdateNote = () => {
  const axios = useContext(AxiosContext)
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)

  const updateNoteToTopicNotes = (note: Note, topicID: string) => {
    const topic = topicList[topicID]
    if (topic) {
      const updatedNotes = topic.notes.map((e) => {
        if (e.noteID == note.noteID) {
          return note
        } else {
          return e
        }
      })

      let latestNote: Note = topic.latestNote
      if (latestNote) {
        latestNote = latestNote.noteID == note.noteID ? note : latestNote
      }

      updateTopicList({
        [topicID]: {
          ...topic,
          notes: updatedNotes,
          firstNote: topic.firstNote.noteID == note.noteID ? note : topic.firstNote,
          latestNote: latestNote,
        },
      })
    }
  }

  return useMutation({
    mutationKey: [ReactQueryKeys.NOTE_UPDATE],
    mutationFn: async (data: Param) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any

      const param: any = {
        requestType: 'topicNoteUpdate',
        'noteData[action]': 'update',
        'noteData[noteID]': data.noteID,
        'noteData[topicID]': data.topicID,
        'noteData[noteText]': data.noteText,
        format: 'json',
      }
      const egoList = (data.egoList || []).map((e) => e.egoID)
      param['noteData[egoList][]'] = egoList

      const response = await axios.api.post('ajax/topics', param)

      const topic = topicList[data.topicID]
      const note = topic?.notes.find((e) => e.noteID == data.noteID)
      if (topic && note) {
        const updatedNote: Note = {
          ...note,
          noteText: data.noteText,
          egoList: data.egoList || [],
        }
        updateNoteToTopicNotes(updatedNote, data.topicID)
      }

      return response.data
    },
  })
}
