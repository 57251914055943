import { AssetItem } from '@/pages/pattern/__styled'
import { theme } from '@/styles/theme'
import { Note, PatternDraft, PatternMeshColor } from '@/types'
import { NoteType } from '@/utils/constants'
import { getAssetThumb } from '@/utils/helper'
import { Box, List, Typography } from '@mui/material'

interface PatternNotesGroupListProps {
  title: string
  patternNoteType: NoteType
  data: Note[] // sectioned data, notes inside groups
  patternDetails?: PatternDraft
}

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'hidden',
    overflowX: 'auto',
    gap: 2,
  },

  noteItemDiv: {
    width: '50px',
    height: '50px',
    borderRadius: '8px',
    borderWidth: 1,
    border: '1px solid #e5e5e5',
    overflow: 'hidden',
  },

  colorItemDiv: {
    width: '30px',
    height: '30px',
    borderRadius: '8px',
    borderWidth: 1,
    border: '1px solid #e5e5e5',
    overflow: 'hidden',
  },
})

interface DataValue {
  imagePath?: string
  color?: PatternMeshColor
}

const PatternNotesGroupRowList = (props: PatternNotesGroupListProps) => {
  const styles = useStyles()
  let colors: Set<string> = new Set()
  let imagePaths: Set<string> = new Set()

  if (props.patternNoteType == NoteType.TEMPLATE) {
    imagePaths.add(getAssetThumb(NoteType.TEMPLATE, props.patternDetails?.templateID || 0) || '')
  } else if (props.patternNoteType == NoteType.MATERIAL) {
    Object.values(props.patternDetails?.materialID || []).forEach((e) => {
      if (e) {
        let path = getAssetThumb(NoteType.MATERIAL, e || 0)
        if (path) {
          imagePaths.add(path)
        }
      }
    })
  }
  if (props.patternNoteType == NoteType.ACCENT) {
    imagePaths.add(getAssetThumb(NoteType.ACCENT, props.patternDetails?.accentID || 0) || '')
  } else if (props.patternNoteType == NoteType.PRINT) {
    Object.values(props.patternDetails?.print || [])
      .map((e) => e?.id)
      .forEach((x) => {
        if (x) {
          let path = getAssetThumb(NoteType.PRINT, x)
          if (path) {
            imagePaths.add(path)
          }
        }
      })
  } else if (props.patternNoteType == NoteType.COLOR) {
    Object.values(props.patternDetails?.color || {}).forEach((e) => {
      if (Array.isArray(e) && e.length > 0) {
        e.forEach((color) => {
          colors.add(`rgb(${color!.r * 255},${color!.g * 255},${color!.g * 255})`)
        })
      }
    })
  }

  return (
    <>
      {colors.size > 0 || imagePaths.size > 0 ? (
        <Box
          sx={{
            background: 'white',
            px: '10px',
            borderRadius: '5px',
          }}
        >
          <Typography fontWeight={400} fontSize={11} sx={{ color: '#211E1E', pt: '2px' }}>
            {props.title}
          </Typography>
          {props.data?.length > 0 && (
            <List
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {props.patternNoteType == NoteType.COLOR ? (
                <>
                  {Array.from(colors).map((e, i) => (
                    <Box
                      key={i}
                      sx={{
                        ...styles.colorItemDiv,
                        backgroundColor: e,
                      }}
                    ></Box>
                  ))}
                </>
              ) : (
                <>
                  {Array.from(imagePaths).map((e, i) => (
                    <Box
                      sx={{
                        ...styles.noteItemDiv,
                        backgroundColor:
                          props.patternNoteType == NoteType.TEMPLATE ||
                          props.patternNoteType == NoteType.ACCENT
                            ? theme.palette.brand.light
                            : 'transparent',
                      }}
                      key={i}
                    >
                      <AssetItem key={i}>
                        <img style={{ width: '100%', height: '100%' }} src={e} alt='image' />
                      </AssetItem>
                    </Box>
                  ))}
                </>
              )}
            </List>
          )}
        </Box>
      ) : null}
    </>
  )
}

export default PatternNotesGroupRowList
