import { useMutation } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import { AuthUser, CognitoUser, ReactQueryKeys } from '@/types'
import { useAuth } from '@/hooks/common'

export const useRegisterCognito = (data: AuthUser) => {
  const axios = useContext(AxiosContext)
  const { login } = useAuth()
  return useMutation({
    mutationKey: [ReactQueryKeys.REGISTER_COGNITO, data],
    mutationFn: async (): Promise<boolean> => {
      const signInResult = await Auth.signIn(data.user, data.password)
      const user = await Auth.currentAuthenticatedUser()
      await axios.api.post<CognitoUser>('ajax/registercognito', {
        userEmail: data.user,
        token: user?.attributes?.sub,
        'dob[day]': dayjs(data.dob).date(),
        'dob[month]': dayjs(data.dob).month() + 1,
        'dob[year]': dayjs(data.dob).year(),
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: '',
        format: 'json',
      })
      const response = await axios.api.post<CognitoUser>('ajax/logincognito', {
        token: signInResult.username,
        format: 'json',
      })
      const { requestID, userID, userName } = response.data || {}
      login({ requestID, userID, userName })
      return true
    },
  })
}
