/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { Ego, Note, ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

interface Param {
  topicID: string
  noteName: string
  noteText: string
  noteType?: string
  egoItems: Ego[]
}

export const useAddNoteTopic = () => {
  const axios = useContext(AxiosContext)
  const { updateTopicList } = useAppStore()
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)

  const addNoteToTopicNotes = (note: Note, topicID: string) => {
    const topic = topicList[topicID]

    if (topic) {
      const updatedNotes = [...(topic.notes || []), note]

      updateTopicList({
        [topicID]: {
          ...topic,
          noteCount: `${Number(topic.noteCount) + 1}`,
          notes: updatedNotes,
          latestNote: note,
        },
      })
    }
  }

  return useMutation({
    mutationKey: [ReactQueryKeys.ADD_NOTE_TOPIC],
    mutationFn: async (data: Param) => {
      const egoList = data.egoItems.map((e) => e.egoID)
      const noteType = data.noteType || '2'
      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteUpdate',
        'noteData[topicID]': data.topicID,
        'noteData[noteID]': '',
        'noteData[action]': 'add',
        'noteData[noteName]': data.noteName,
        'noteData[noteText]': data.noteText,
        'noteData[noteType]': noteType,
        'noteData[egoList][]': egoList,
        format: 'json',
      })

      const { noteID, dateCreated, dateUpdated, authProfileName, authorID, authProfileThumbURL } =
        response.data

      const note: Note = {
        authProfileName,
        authProfileThumbURL,
        authorID,
        backgroundID: '',
        color: {
          r: '0',
          g: '0',
          b: '0',
        },
        comments: 0,
        likes: [],
        dateCreated,
        dateUpdated,
        egoList: data.egoItems,
        fontID: '0',
        isThumbsDown: 0,
        isThumbsUp: 0,
        noteID,
        noteName: data.noteName,
        noteText: data.noteText,
        noteType,
        scale: '0',
        thumbsDown: '0',
        thumbsUp: '0',
      }
      addNoteToTopicNotes(note, data.topicID)

      return response.data
    },
  })
}
