import { useAuth } from '@/hooks/common';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import React, { Context, createContext, useState } from 'react';
import { toast } from 'react-hot-toast';

interface ContextProps {
  api: AxiosInstance;
  apiKey: string;
  setAPIKey: (token: string) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const AxiosContext: Context<ContextProps> = createContext(
  {} as ContextProps,
);

export const AxiosProvider = ({ children }: ProviderProps) => {
  const [apiKey, setAPIKey] = useState('');
  const { logout } = useAuth();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
    withCredentials: true,
    timeout: 60000,
  });

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      if (
        response.data.isError
      ) {
        // To-Do: Reset store
        console.error(JSON.stringify(response.data.errorMessage))
        switch (response.data.errorCat) {
          case 1: // session has expired
          case 13: // user is inactive
            toast.error(JSON.stringify(response.data.errorMessage), {
              id: response.data.errorCat
            })
            logout();
            break;
          default:
            break;
        }
      }
      return response;
    },
    async (error: AxiosError) => {
      console.log(error?.request?.status, error);
      if (
        error.response?.status === 400 ||
        error.response?.status === 401 ||
        error.response?.status === 403
      ) {
        window.location.reload();
      }
      return Promise.reject(error);
    },
  );

  return (
    <AxiosContext.Provider value={{ api, apiKey, setAPIKey }}>
      {children}
    </AxiosContext.Provider>
  );
};
