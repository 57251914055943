/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { SVGS } from '@/assets/svgs'
import { DMTopic, IPatternAssetTemplate, Note, Topic, TopicStats } from '@/types'
import Resizer from 'react-image-file-resizer'
import { AVATAR_BG_COLORS, AVATAR_FILE_EXTENSIONS, NoteType, TopicType } from './constants'
import { Assets } from './pattern-assets'
import htmlToDraft from 'html-to-draftjs'
import { number } from 'prop-types'

export const Patterns = {
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  phone: /^\d{10}$/,
  // eslint-disable-next-line no-useless-escape
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  firstName: /^[a-zA-Z]+|[a-zA-Z]+ [a-zA-Z]+$/,
  lastName: /^[a-zA-Z]+$/,
}

export const getCopyRightText = () => {
  return `© ${new Date().getFullYear()} ALL RIGHT RESERVED`
}

export const validateEmail = (emailAdress: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return emailAdress.match(regexEmail)
}

export const validatePhoneNumber = (number: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexPhone = /^\d{10}$/
  return number.match(regexPhone)
}

export const validatePassword = (password: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexPhone = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  return password.match(regexPhone)
}

export const validateFirstName = (name: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexPhone = /^[a-zA-Z]+|[a-zA-Z]+ [a-zA-Z]+$/
  return name.match(regexPhone)
}

export const validateLastName = (name: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexPhone = /^[a-zA-Z]+$/
  return name.match(regexPhone)
}

export const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      'jpg',
      100,
      0,
      (uri: any) => {
        resolve(uri)
      },
      'file',
    )
  })

export const uploadMedia = async function (data: File) {
  try {
    return await resizeFile(data)
  } catch (err) {
    return { isError: true, errorMessage: err }
  }
}

export const getFileUniqueKey = (file: File) => {
  return `${file.lastModified}-${file.size}-${file.name}`
}

export const getTooltipTitleForPrivacyUsers = (topic: Topic | DMTopic) => {
  if (topic.visibility === '4') {
    return topic.connections.map((i) => `${i.firstName} ${i.lastName}`).join(', ')
  }
  if (topic.visibility === '5') {
    return (topic as Topic).cliques?.map((i) => i.cliqueName).join(', ') || ''
  }
  if (topic.visibility === '6') {
    return `${topic.connections[0]?.firstName} ${topic.connections[0]?.lastName}`
  }
  return ''
}

export const convertToGMT = (dateString: string) => {
  return `${dateString} GMT`
}

export const isValidAvatarUrl = (url: string) => {
  if (url.includes('profile_male')) return false
  const lowerCase = url.toLowerCase()
  if (!AVATAR_FILE_EXTENSIONS.some((i) => lowerCase.includes(i))) return false
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export const getTopicStates = (topic: Topic) => {
  const states = [
    {
      key: 'like',
      icon: SVGS.ActionLike,
      value: Number(topic.totalLikes),
    },
    {
      key: 'comment',
      icon: SVGS.ActionComment,
      value: Number(topic.totalComments),
    },
    {
      key: 'visit',
      icon: SVGS.ActionVisit,
      value: Number(topic.visitorVisits) + Number(topic.ownerVisits),
    },
  ]
  let mediaCount = 0
  if (topic.topicType == TopicType.ALBUM.toString()) {
    topic.notes?.forEach((note) => (mediaCount += note.egoList?.length || 0))
  } else {
    mediaCount = Number(topic.noteCount) - 1
  }

  states.push({
    key: 'media',
    icon: SVGS.ActionMedia,
    value: mediaCount || Number(topic.photoCount),
  })
  return states.filter((i) => i.value > 0)
}

export const getDMTopicStates = (state: TopicStats) => {
  const states = [
    {
      key: 'like',
      icon: SVGS.ActionLike,
      value: Number(state.noteThumbsUp),
    },
    {
      key: 'comment',
      icon: SVGS.ActionComment,
      value: Number(state.noteComments),
    },
    {
      key: 'visit',
      icon: SVGS.ActionVisit,
      value: Number(state.visitorVisits) + Number(state.ownerVisits),
    },
    {
      visit: 'media',
      icon: SVGS.ActionMedia,
      value: Number(state.egoCount),
    },
  ]
  return states.filter((i) => i.value > 0)
}

export const getAvatarColorWithName = (name: string) => {
  const [firstName, lastName] = name.split(' ')
  const hash = firstName.charCodeAt(0) + (lastName || 'a').charCodeAt(0) + name.length
  return AVATAR_BG_COLORS[hash % AVATAR_BG_COLORS.length]
}

export const isPatternTopicType = (type: string | number) => {
  return type === '200' || type === 200
}

export const isGalleryTopicType = (type: string | number) => {
  return type === '201' || type === 201
}

export const isWallArtTopicType = (type: string | number) => {
  return type === '202' || type === 202
}

export const isQuiltTopicType = (type: string | number) => {
  return type === '203' || type === 203
}

export const isBabylonType = (type: string | number) => {
  return (
    isPatternTopicType(type) ||
    isQuiltTopicType(type) ||
    isGalleryTopicType(type) ||
    isWallArtTopicType(type)
  )
}

export const getFullImageURL = (imageURL: string) => {
  if (imageURL?.length > 0) {
    let result = imageURL?.replace('/photo.album.', '/photo.full.')
    result = result?.replace('/photo.thumb.', '/photo.full.')
    return result
  }
  return ''
}

export const getThumbImageURL = (imageURL: string) => {
  if (imageURL?.length > 0) {
    let result = imageURL?.replace('/photo.album.', '/photo.thumb.')
    result = result?.replace('/photo.full.', '/photo.thumb.')
    return result
  }
  return ''
}

export const getAssetThumb = (type: NoteType, id: number): string | undefined => {
  let result: string | undefined

  if (type == NoteType.TEMPLATE) {
    result = Assets.templates.find((e) => e.id == id)?.thumb
  } else if (type == NoteType.MATERIAL) {
    result = Assets.materials.find((e) => e.id == id)?.thumb
  } else if (type == NoteType.ACCENT) {
    result = Assets.accents.find((e) => e.id == id)?.thumb
  } else if (type == NoteType.PRINT) {
    result = Assets.prints.standard.find((e) => e.id == id)?.thumb
  }
  return result ? `/assets/patterns/${result}` : undefined
}

export const getPlainNoteTextFromNote = (note: Note) => {
  const { noteText = '' } = note || {}
  try {
    const parsed = JSON.parse(noteText)
    const draft = htmlToDraft(parsed.text)
    const value = draft.contentBlocks.map((block) => block.getText()).join('\n')
    return value
  } catch (error) {
    return noteText
  }
}

export const getNowInUTC = () => {
  return moment().utc().format('yyyy-MM-DD HH:mm:ss')
}

export const scrollToBottom = (scrollRef: any, delay: number = 0) => {
  setTimeout(() => {
    const scrollBox = scrollRef.current
    if (scrollBox) {
      scrollBox.scrollTop = scrollBox?.scrollHeight
      console.log('Scrolling down')
    }
  }, delay)
}
