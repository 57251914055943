import { useSearchUsers } from '@/hooks/api/dashboard'
import useAppStore from '@/store/useAppStore'
import { SearchConnection } from '@/types'
import { Box, Grid, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingButton } from '@mui/lab'
import SVG from 'react-inlinesvg'
import { TopicTypes } from '@/utils/constants'
import colors from '@/styles/colors'
import UserConnectionItem from './ConnectionItem'
import { shallow } from 'zustand/shallow'
import ConnectionGridItem from './ConnectionGridItem'
import ConnectManagement from '../ConnectManagement'
import { Panel4 } from '@/pages/__styled'

interface Props {
  searchKey: string
  onClickUser: (user: SearchConnection) => void
}

export default function SearchConnectionResults({ searchKey, onClickUser }: Props) {
  const [page, setPage] = useState(1)
  const [endReached, setEndReached] = useState(false)
  const [userSearchKeyword, searchConnections] = useAppStore(
    (state) => [state.user.userSearchKeyword, state.user.searchConnections],
    shallow,
  )
  const searchUsers = useSearchUsers(searchKey)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollRef = useRef<any>(null)

  // Search when SearchKeyword is non empty
  // Search when previous result is empty
  // Search when previous result is non empty and search keyword is different

  useEffect(() => {
    if (searchKey.length > 0) {
      searchUsers.mutate(1)
    }
  }, [searchKey])

  useEffect(() => {
    if (page === 1 && searchUsers.isSuccess) {
      setTimeout(() => {
        if (scrollRef?.current) {
          scrollRef?.current.scrollTo({ top: 0, left: 0 })
        }
      }, 1000)
    }
  }, [page, searchUsers.isSuccess])

  useEffect(() => {
    if (searchUsers.isSuccess && searchUsers.data && !searchUsers.isError) {
      if (searchUsers.data.length < 20) {
        setEndReached(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUsers.isSuccess, searchUsers.data, searchUsers.isError])

  const users = useMemo(() => {
    const list = Object.values(searchConnections)
    // sort by date updated
    list.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`
      const nameB = `${b.firstName} ${b.lastName}`
      if (nameA > nameB) return 1
      if (nameA < nameB) return -1
      return 0
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConnections])

  const onClickShowMore = () => {
    if (endReached || searchUsers.isLoading || !users.length) {
      return
    }
    searchUsers.mutate(page + 1)
    setPage(page + 1)
  }
  if (searchUsers.isSuccess && searchUsers.data.length == 0 && page === 1) {
    return (
      <Box p={5} display='flex' flexDirection={'column'} alignItems={'center'}>
        <SVG
          src={TopicTypes[2].icon}
          width={76}
          height={76}
          className={'icon'}
          color={colors.grey}
        />
        <Typography variant='h3' textAlign={'center'} mt={2} mb={1}>
          No user results.
        </Typography>
      </Box>
    )
  }

  return (
    <Box flex={1} overflow='auto' ref={scrollRef}>
      {searchUsers.isLoading && page === 1 && (
        <Box mt={1} display='flex' justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      <Box
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        p={2}
        sx={{ overflow: 'auto' }}
      >
        <Grid container spacing={2} flex={1}>
          {users.map((item, index) => (
            <Grid item xs={'auto'} key={item.userID}>
              <ConnectionGridItem
                key={item.userID}
                user={item}
                onClickViewProfile={onClickUser}
                onClickAccept={(user) => {}}
                onClickFollow={(user) => {}}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* <Panel4 item xs={10} md={6} lg={3} view={false}>
        <Box bgcolor={'white'} p={2} borderRadius={1} height='-webkit-fill-available'>
          <ConnectManagement onClickClose={() => {}} />
        </Box>
      </Panel4> */}

      {/* {false && (
        <Box m={3} display='flex' justifyContent={'center'}>
          <LoadingButton
            variant='text'
            onClick={onClickShowMore}
            loading={searchUsers.isLoading && page > 1}
            disabled={endReached}
          >
            {endReached ? 'No more results' : 'Show More'}
          </LoadingButton>
        </Box>
      )} */}
    </Box>
  )
}
