import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import { AuthFormBox } from './__styled'
import { Input } from '@/components'
import { validateEmail, validatePhoneNumber } from '@/utils/helper'

interface FormProps {
  error: string
  loading: boolean
  onSubmit: (user: string) => void
}

export default function ForgotForm1({ error, loading, onSubmit }: FormProps) {
  const [user, setUser] = useState('') // Email or Phone number
  const [editing, setEditing] = useState(false)

  const formError = useMemo(() => {
    const error: Record<string, string> = {}
    if (!user.length) {
      error.user = 'Email or Phone Number is required'
    } else if (!validateEmail(user) && !validatePhoneNumber(user)) {
      error.user = 'Enter Valid Phone/Email'
    }
    return error
  }, [user])

  const onChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser(e.target.value)
    setEditing(true)
  }

  const onSubmitReset = () => {
    if (!formError.user) onSubmit(user)
  }

  return (
    <AuthFormBox>
      <Typography variant='large' fontSize={38}>
        Forgot your password?
      </Typography>
      <Typography variant='p' fontSize={15} mt={1}>
        Please enter your registered email or phone number. We’ll help you reset it and get back on
        track.
      </Typography>
      <Input
        value={user}
        placeholder='Email or Phone Number*'
        onChange={onChangeUser}
        errorText={formError.user}
        isError={editing && !!formError.user}
        name='email'
      />
      {!loading && !!error && (
        <Box mt={1}>
          <Typography variant='labelLarge' color={'error.main'}>
            {error}
          </Typography>
        </Box>
      )}
      <LoadingButton
        variant='contained'
        size='large'
        fullWidth
        className='loginButton'
        disabled={!!formError.user}
        onClick={onSubmitReset}
        loading={loading}
      >
        Submit
      </LoadingButton>
      <Box mt={2} display='flex' justifyContent='center' alignItems={'center'} gap={'3px'}>
        <Typography
          variant='labelLarge'
          fontSize={14}
          color={'customText.main'}
          fontFamily={'Inter'}
        >
          Got an account?
        </Typography>
        <Link to='/login'>
          <Typography variant='labelLarge' fontSize={14} color={'brand.main'}>
            Sign In
          </Typography>
        </Link>
      </Box>
    </AuthFormBox>
  )
}
