import { SVGS } from '@/assets/svgs'
import {
  Input,
  InteractionComment,
  NoInteractions,
  UserInteraction,
  UserInteractionProps,
} from '@/components'
import {
  useDeleteComment,
  useGetDMTopicDetails,
  useGetTopicDetails,
  useGetTopicNoteLikes,
  useInteractToNote,
  useNoteLoad,
} from '@/hooks/api/dashboard'
import { useBrowserQuery, useLocalStorage } from '@/hooks/common'
import useAppStore from '@/store/useAppStore'
import colors from '@/styles/colors'
import { Comment, Ego, NoteDetail } from '@/types'
import { ArrowBackIos, ChevronLeft, ChevronRight, Close, Favorite, Send } from '@mui/icons-material'
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import SVG from 'react-inlinesvg'
import Slider from 'react-slick'
import { shallow } from 'zustand/shallow'
import { AlbumGalleryPhoto, AlbumSliderWrapper, InteractionsContent } from '../__styled'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  trackKey: string
}

export default function NoteLikes({ trackKey }: Props) {
  const [ewUser] = useLocalStorage('ewUser', null)
  const query = useBrowserQuery()
  const noteID = query.get('noteID') || ''

  const noteLoad = useNoteLoad(noteID)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const getNoteLikes = useGetTopicNoteLikes()
  const [topicSelectedAt] = useAppStore((state) => [state.home.topicSelectedAt], shallow)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>(null)

  useEffect(() => {
    if (noteID) noteLoad.mutate()
  }, [topicSelectedAt])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0)
    }
  }, [sliderRef.current, noteID])

  useEffect(() => {
    if (noteID) {
      getNoteLikes.mutate(noteID)
    }
  }, [noteID])

  if (!getNoteLikes && noteLoad.isLoading) {
    return (
      <InteractionsContent justifyContent={'center'} alignItems='center'>
        <CircularProgress />
      </InteractionsContent>
    )
  }
  const viewData = useMemo(() => {
    if (!getNoteLikes) return []
    return (
      getNoteLikes?.data?.map((i) => ({
        date: i.dateCreated,
        avatar: i.profilePicURL,
        name: i.userName,
      })) || []
    )
  }, [getNoteLikes.isSuccess])

  if (noteLoad.isError) {
    return <Typography>Failed to load</Typography>
  }

  if (!noteID) return null

  const handleBack = () => {
    query.delete('noteID')
    query.delete('showLikes')
    query.delete('step')
    navigate(`${pathname}?${query.toString()}`, { replace: true })
  }

  const renderLikes = () => {
    return (
      <>
        {getNoteLikes.isLoading ? (
          <Box mt={1} display={'flex'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {viewData.length === 0 && <Typography>No Likes</Typography>}
            {viewData.map((data: UserInteractionProps, index: number) => (
              <Box key={index}>
                <UserInteraction {...data} />
              </Box>
            ))}
          </>
        )}
      </>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        px: 2,
      }}
    >
      <Box py={2} mx={'-8px'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <IconButton
          onClick={handleBack}
          sx={{
            backgroundColor: 'transparent',
            px: '0px',
          }}
        >
          <ArrowBackIos sx={{ ml: '8px', color: colors.backArrowColor }} />
        </IconButton>

        <Typography fontSize={'20px'} fontWeight={700} color={colors.themeBlack}>
          {`Liked (${viewData.length})`}
        </Typography>
      </Box>
      <Box flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        {renderLikes()}
      </Box>
    </Box>
  )
}
