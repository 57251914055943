import { SVGS } from "@/assets/svgs";
import { ActionMenu, PreviewNote, TopicStates, UserAvatar } from "@/components";
import colors from "@/styles/colors"
import { DMConnection } from "@/types"
import { MoreVert } from "@mui/icons-material";
import { Box, Typography } from "@mui/material"
import { useMemo } from "react";
import SVG from 'react-inlinesvg';
import { ConnectionListItem } from "../__styled";

interface ItemProps {
  user: DMConnection
  selected: boolean
  onClick: () => void;
  onClickRemoveConnect: () => void;
  onClickBlockConnect: () => void;
}

export default function ConnectionItem({ user, selected, onClick, onClickRemoveConnect, onClickBlockConnect }: ItemProps) {
  const topicActions = useMemo(() => {
    return [
      {
        icon: <SVG src={SVGS.RemoveUser} width={20} height={20} />,
        help: 'Remove',
        onClick: onClickRemoveConnect
      },
      {
        icon: <SVG src={SVGS.BlockUser} width={20} height={20} />,
        help: 'Block',
        onClick: onClickBlockConnect
      },
    ]
  }, [])

  return (
    <ConnectionListItem onClick={onClick} className={selected ? 'active' : ''}>
      <UserAvatar name={`${user.firstName} ${user.lastName}`} url={user.thumbImgURL} size={46} />
      <Box px={2} flex={1} display="flex" flexDirection={'column'} justifyContent='space-between' overflow="hidden">
        <Typography variant="h2" mr={3} textOverflow="ellipsis" whiteSpace={'nowrap'} overflow="hidden">
          {user.firstName} {user.lastName}
        </Typography>
        <PreviewNote note={user.lastNote} />
        <Box display="flex" alignItems="center">
          <TopicStates type='dm' topic={user} />
        </Box>
      </Box>
      <Box mt={1} position="absolute" top={0} right={0}>
        <ActionMenu
          id="topic-item-menu"
          parent={<MoreVert sx={{ color: colors.darkGrey }} />}
          actions={topicActions}
          direction="row"
        />
      </Box>
    </ConnectionListItem>
  )
}