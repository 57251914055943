/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query'
import { ReactQueryKeys } from '@/types'
import { useContext } from 'react'
import { AxiosContext } from '@/context/Axios'
import useAppStore from '@/store/useAppStore'
import { shallow } from 'zustand/shallow'

interface Params {
  topicID: string
  noteID: string
  comment: string
  interactionType: '3' | '2' // 3 for liking // 2 Comments
  interaction: '0' | '1' // 1: like, 0: dislike for interactionType = 3 only
}

export const useInteractToNote = (isDM: boolean = false) => {
  const axios = useContext(AxiosContext)
  const { updateTopicList, setMyDMConnections } = useAppStore()

  const [topicList, myDMConnections] = useAppStore(
    (state) => [state.home.topicList, state.user.myDMConnections],
    shallow,
  )

  const updateLikeStatus = (params: Params) => {
    if (isDM) {
    } else {
      const topic = topicList[params.topicID]
      const isLiked = params.interaction == '1'
      const likesCount = `${Number(topic.totalLikes) + (isLiked ? 1 : -1)}`

      const updatedNotes = topicList[params.topicID]?.notes?.map((i) => {
        if (i.noteID == params.noteID) {
          return {
            ...i,
            isThumbsUp: isLiked ? 1 : 0,
            thumbsUp: `${Number(i.thumbsUp) + (isLiked ? 1 : -1)}`,
          }
        } else {
          return i
        }
      })

      updateTopicList({
        [params.topicID]: {
          ...topicList[params.topicID],
          notes: updatedNotes,
          totalLikes: likesCount,
        },
      })
    }
  }
  const addNoteComment = (params: Params) => {
    if (isDM) {
    } else {
      const topic = topicList[params.topicID]
      const commentsCount = `${Number(topic.totalComments) + 1}`

      const updatedNotes = topicList[params.topicID]?.notes?.map((i) => {
        if (i.noteID == params.noteID) {
          return {
            ...i,
            comments: i.comments + 1,
          }
        } else {
          return i
        }
      })

      updateTopicList({
        [params.topicID]: {
          ...topicList[params.topicID],
          notes: updatedNotes,
          totalComments: commentsCount,
        },
      })
    }
  }

  return useMutation({
    mutationKey: [ReactQueryKeys.INTERACT_ALBUM],
    mutationFn: async (params: Params) => {
      if (params.interactionType == '3') {
        updateLikeStatus(params)
      } else if (params.interactionType == '2') {
        addNoteComment(params)
      }

      const response = await axios.api.post('ajax/topics', {
        requestType: 'topicNoteInteract',
        format: 'json',
        ...params,
      })
      return response.data
    },
  })
}
