import React, { useEffect, createContext, useMemo, useCallback } from 'react'
import { useGetTopicDetails } from '@/hooks/api/dashboard'
import { useBrowserQuery, useAuth } from '@/hooks/common'
import { useLocation, useNavigate } from 'react-router-dom'
import useAppStore from '@/store/useAppStore'
import { HOME_PATH, TopicType, TopicTypes } from '@/utils/constants'
import PatternTopicPreview from './pattern'
import AlbumTopicPreview from './album'
import TopicDetailsView from './common/TopicDetailsView'
import { shallow } from 'zustand/shallow'

import { Topic } from '@/types'
import { TopicDetailsContext } from '@/context/TopicDetails'
import LoadingAnimatedView from '@/components/LoadingAnimatedView'
export const TopicPreviewBase = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const query = useBrowserQuery()
  const [sideTabPath] = useAppStore((state) => [state.app.sideTabPath], shallow)
  const [topicList] = useAppStore((state) => [state.home.topicList], shallow)
  const getTopicDetails = useGetTopicDetails()
  const topicID = query.get('topicID') || ''
  const topicTypeQuery = query.get('topicType') || ''
  const topicType = topicTypeQuery.length > 0 ? TopicTypes[topicTypeQuery].type : undefined

  useEffect(() => {
    if (!user?.userID) {
      navigate('/login')
    } else if (topicID.length == 0 || topicTypeQuery.length == 0 || topicType == undefined) {
      navigate('/')
    }
  }, [user?.userID])

  useEffect(() => {
    if (topicID) {
      getTopicDetails.mutateAsync({ topicID })
    }
  }, [topicID])

  const topic = useMemo(() => {
    return topicList[topicID]
  }, [topicID, topicList])

  const onClickBack = () => {
    // navigate(sideTabPath, { replace: true })
    navigate(-1)
  }

  const canLoadNextPage = (): boolean => {
    if (topic.pagination) {
      return topic.pagination.currentPage < topic.pagination.totalPages
    }
    return false
  }

  const loadNextPage = () => {
    let currentPage = topic.pagination?.currentPage || 1
    return getTopicDetails.mutateAsync({ topicID, pageNo: currentPage + 1 })
  }
  return (
    <>
      {topic ? (
        <TopicDetailsContext.Provider value={{ topic, onClickBack, loadNextPage, canLoadNextPage }}>
          {topicType == TopicType.PATTERN ||
          topicType == TopicType.GALLERY ||
          topicType == TopicType.QUILT ? (
            <PatternTopicPreview />
          ) : topicType == TopicType.ALBUM ? (
            <TopicDetailsView />
          ) : (
            <TopicDetailsView />
          )}
        </TopicDetailsContext.Provider>
      ) : (
        <LoadingAnimatedView showGif />
      )}
    </>
  )
}
