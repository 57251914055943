import { ConnectionItem } from "@/components";
import { useBrowserQuery } from "@/hooks/common";
import useAppStore from "@/store/useAppStore";
import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useMemo } from "react";
import { shallow } from "zustand/shallow";

interface Props {
  onClosePrivacy: () => void
}

export default function ConnectInteraction({ onClosePrivacy }: Props) {
  const [topicList, myDMConnections] = useAppStore(state => [
    state.home.topicList,
    state.user.myDMConnections,
  ], shallow)

  const query = useBrowserQuery();
  const topicID = query.get("topicID") || '';
  const dmTopicID = query.get("dmTopicID") || '';

  const connections = useMemo(() => {
    if (topicID) {
      return topicList[topicID]?.connections || [];
    } else {
      return myDMConnections.find(i => i.topicID === dmTopicID)?.connections || [];
    }
  }, [topicID, dmTopicID, topicList, myDMConnections])

  return (
    <Box p={2}>
      <Box display={'flex'} alignItems="center" justifyContent={'space-between'} mt={-1} mr={-1}>
        <Typography variant='labelLarge'>
          {connections.length} Connection{connections.length > 1 ? 's' : ''} included
        </Typography>
        <IconButton onClick={onClosePrivacy}>
          <Close />
        </IconButton>
      </Box>
      <Box flex={1} overflow="auto">
        {connections.map(c => (
          <ConnectionItem
            url={c.profilePicThumbURL}
            connID={c.connID}
            name={`${c.firstName} ${c.lastName}`}
            key={c.connID}
          />
        ))}
      </Box>
    </Box>
  )
}