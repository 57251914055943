import colors from '@/styles/colors'
import React, { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/system'
import { Button, IconButton, Typography, withStyles } from '@mui/material'
import {
  ArrowBackIosNewRounded as ArrowBack,
  ArrowForwardIosRounded as ArrowForward,
} from '@mui/icons-material'
// import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { RBox } from '@/pages/__styled'

interface ScrollableCapsulesTabBarProps {
  titles: String[]
  selectedIndex: number
  showScrollButtons?: boolean
  onSelectAtIndex: (index: number) => void
}

const CustomTab = styled(Box)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  borderRadius: 100,
  display: 'flex',
  minWidth: '60px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 15px',
  color: colors.themeBlack,
  border: `1px solid #E7EDF3`,
  '&[aria-selected = true]': {
    color: colors.meshdomBlue,
    border: `1px solid ${colors.meshdomBlue}`,
  },
}))

export const CapsuleView = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: colors.themeBlack,
  borderColor: theme.palette.brand.light,
  border: `1px solid #E7EDF3`,
  fontSize: 13,
  fontWeight: '500',
  borderRadius: 50,
  alignItems: 'center',
  padding: '5px 15px',
  '&[aria-selected = true]': {
    border: `1px solid ${colors.meshdomBlue}`,
    backgroundColor: colors.meshdomBlueLight,
    color: colors.meshdomBlue,
  },
  '&:hover': {
    border: `1px solid ${colors.meshdomBlue}`,
    backgroundColor: colors.meshdomBlueLight,
    color: colors.meshdomBlue,
  },
}))

export const ScrollableCapsulesTabBar = ({
  titles,
  selectedIndex,
  onSelectAtIndex,
  showScrollButtons = true,
}: ScrollableCapsulesTabBarProps) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const scrollRef = React.useRef<HTMLDivElement>(null)

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const { current: element } = scrollRef
      const scrollAmount = 200
      const newScrollPosition =
        direction === 'left' ? element.scrollLeft - scrollAmount : element.scrollLeft + scrollAmount

      element.scrollTo({ left: newScrollPosition, behavior: 'smooth' })
      setScrollPosition(newScrollPosition)
    }
  }

  const backArrow = useCallback(() => {
    const disabled = scrollPosition <= 0
    return (
      <RBox
        onClick={() => handleScroll('left')}
        sx={{
          border: '1px solid #E7EDF3',
          borderRadius: 100,
          pl: '6px',
          width: '34px',
          height: '34px',
          zIndex: 10,
          left: 0,
          position: 'absolute',
          backgroundColor: 'white',
        }}
      >
        {}
        <ArrowBack
          fontSize='small'
          sx={{ color: colors.themePurpleTextColor, opacity: disabled ? 0.7 : 1 }}
        />
      </RBox>
    )
  }, [scrollPosition])

  //

  const forwardArrow = useCallback(() => {
    let disabled = false
    if (scrollRef.current) {
      const { current: element } = scrollRef
      disabled = element.scrollLeft + element.clientWidth >= element.scrollWidth
    }

    return (
      <RBox
        onClick={() => handleScroll('right')}
        sx={{
          zIndex: 10,
          border: '1px solid #E7EDF3',
          borderRadius: 100,
          pl: '6px',
          width: '34px',
          height: '34px',
          backgroundColor: 'white',
          right: 0,
          position: 'absolute',
        }}
      >
        <ArrowForward
          fontSize='small'
          sx={{ color: colors.themePurpleTextColor, opacity: disabled ? 0.7 : 1 }}
        />
      </RBox>
    )
  }, [scrollPosition])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: '20px',
        mb: '30px',
        position: 'relative',
      }}
    >
      {showScrollButtons && backArrow()}
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          gap: '15px',
          overflow: 'hidden',
          px: showScrollButtons ? '20px' : '0px',
          position: 'absolute',
          left: showScrollButtons ? 32 : 0,
          right: showScrollButtons ? 32 : 0,
        }}
      >
        {titles.map((e, i) => (
          <CustomTab
            key={`${i}`}
            aria-selected={selectedIndex == i}
            onClick={() => {
              onSelectAtIndex(i)
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: '500',
                flex: 1,
                color: 'inherit',
                textAlign: 'center',
              }}
            >{`${e}`}</Typography>
          </CustomTab>
        ))}
      </Box>
      {showScrollButtons && forwardArrow()}
    </Box>
  )
}
